import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { competitorSortVar } from 'config/apollo/competitor-pricing/variables'
import { showConfirm } from 'components/station/components/competitor-pricing/utils/helpers'
import {
  CompetitorSorts,
  SortDirections,
} from 'components/station/components/competitor-pricing/utils/constants'
import { ReactComponent as DrivingDistance } from 'images/competitor-pricing/driving-distance.svg'
import { ReactComponent as SortUpDark } from 'images/competitor-pricing/sort-up-dark.svg'
import { ReactComponent as SortDownDark } from 'images/competitor-pricing/sort-down-dark.svg'
import { ReactComponent as SortUp } from 'images/competitor-pricing/sort-up-grey.svg'
import classNames from 'classnames/bind'
import { CompetitorStationCell } from './components/competitor-station-cell'
import { MyStationCell } from './components/my-station-cell'
import { ConfirmCell } from './components/confirm-cell'
import styles from './StationsColumn.module.scss'

const cx = classNames.bind(styles)

const { ASC, DESC } = SortDirections

const renderCompetitorStationCells = station => {
  const { id, sortedCompetitors: competitors } = station
  return competitors.map(competitor => {
    return (
      <CompetitorStationCell
        key={competitor.id}
        stationId={id}
        competitor={competitor}
      />
    )
  })
}

const StationsColumn = ({ status, station }) => {
  const { t } = useTranslation()
  const { cashCreditPricing } = station

  const { DRIVING_DISTANCE_SORT } = CompetitorSorts
  const { type, direction } = competitorSortVar()
  const sorted = type === DRIVING_DISTANCE_SORT

  const drivingDistanceSort = () =>
    competitorSortVar({
      type: DRIVING_DISTANCE_SORT,
      direction: sorted ? (direction === ASC ? DESC : ASC) : ASC,
    })

  const renderSortButton = (
    <div onClick={() => drivingDistanceSort()}>
      {sorted ? (
        direction === ASC ? (
          <SortUpDark />
        ) : (
          <SortDownDark />
        )
      ) : (
        <SortUp />
      )}
    </div>
  )

  const confirm = showConfirm(status)
  const content = confirm ? (
    <>
      <MyStationCell station={station} />
      <ConfirmCell cashCreditPricing={cashCreditPricing} />
      {station.competitors.map(({ id }) => (
        <div key={id} className={cx('confirm-cell')} />
      ))}
    </>
  ) : (
    <>
      <div className={cx('header-cell')}>
        <div className={cx('station-column-title')}>
          <div className={cx('driving-distance')}>
            <DrivingDistance />
            <div className={cx('driving-distance-title')}>
              {t('widgets.competitorPricing.drivingDistance')}
            </div>
          </div>
          <div className={cx('competitor-name-title')}>
            {t('widgets.competitorPricing.competitorName')}
          </div>
        </div>
        {renderSortButton}
      </div>
      <MyStationCell station={station} />
      {renderCompetitorStationCells(station)}
    </>
  )

  return <div className={cx('stations-column')}>{content}</div>
}

StationsColumn.propTypes = {
  status: PropTypes.string.isRequired,
  station: PropTypes.shape().isRequired,
}

export { StationsColumn }
