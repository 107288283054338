import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as SortDown } from 'images/common/sort-down.svg'
import { ReactComponent as SortUp } from 'images/common/sort-up.svg'
import {
  SortDirections,
  SortFields,
} from 'components/stations-list/utils/constants'
import { useApolloClient, gql } from '@apollo/client'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import styles from './HeaderCell.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const { ASC, DESC } = SortDirections
const { NAME } = SortFields

const generateTestId = value => {
  return value.toLowerCase().replace(/_/g, '-')
}

const getSortIcon = (sortDirection, active) => {
  if (!active) {
    return SortUp
  }

  switch (sortDirection) {
    case DESC:
      return SortDown
    case ASC:
    default:
      return SortUp
  }
}

const renderSortIcon = (hovered, active, direction, permitted) => {
  const SortIcon = getSortIcon(direction, active)
  const hidden = !hovered && !active
  return (
    permitted && (
      <SortIcon
        height={20}
        width={20}
        className={cx('sort-icon', { hidden })}
      />
    )
  )
}

const handleSortClick = (client, selected, field, direction, permittedSort) => {
  if (!permittedSort) {
    return
  }
  let newDirection = ASC
  if (selected) {
    newDirection = direction === ASC ? DESC : ASC
  }

  amplitudeEvent(UserEvents.StationsListTable(SortFields[field], newDirection))

  const data = {
    stationsList: {
      selectedSort: {
        __typename: 'StationsListSort',
        field,
        direction: newDirection,
      },
    },
  }
  client.writeQuery({
    query: gql`
      {
        stationsList {
          selectedSort {
            field
            direction
          }
        }
      }
    `,
    data,
  })
}

const renderMetric = (
  { metric, comparative, metricSort },
  { field, direction },
  metricHovered,
  setMetricHovered,
  client,
  permitted
) => {
  const selected = !isNullOrUndefined(field) && metricSort === field
  const permittedSort = metricSort === NAME || permitted
  const handleMouseEnter = () => {
    setMetricHovered(true)
  }

  const handleMouseLeave = () => {
    setMetricHovered(false)
  }

  const handleClick = () => {
    handleSortClick(client, selected, metricSort, direction, permittedSort)
  }

  const testId = generateTestId(`metric-comparative-wrapper-${metricSort}`)

  return (
    <div
      className={cx('metric-comparative-wrapper')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      data-testid={testId}
    >
      <div
        className={cx('metric', {
          metricSorted: selected,
          restricted: !permittedSort,
        })}
      >
        {metric}
        {renderSortIcon(metricHovered, selected, direction, permittedSort)}
      </div>
      <div
        className={cx('comparative', {
          restricted: !permitted,
        })}
      >
        {comparative}
      </div>
    </div>
  )
}

const renderTrend = (
  { trendSort },
  { field, direction },
  percentageHovered,
  setPercentageHovered,
  client,
  permitted
) => {
  if (isNullOrUndefined(trendSort)) {
    return <div className={cx('percentage', 'no-sort')} />
  }

  const selected = !isNullOrUndefined(field) && trendSort === field

  const handleMouseEnter = () => {
    setPercentageHovered(true)
  }

  const handleMouseLeave = () => {
    setPercentageHovered(false)
  }

  const handleClick = () => {
    handleSortClick(client, selected, trendSort, direction, permitted)
  }

  const testId = generateTestId(`percentage-${trendSort}`)

  return (
    <div
      className={cx('percentage', { restricted: !permitted })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      data-testid={testId}
    >
      {renderSortIcon(percentageHovered, selected, direction, permitted)}%
    </div>
  )
}

const HeaderCell = ({ header, selectedSort, permitted }) => {
  const client = useApolloClient()
  const [metricHovered, setMetricHovered] = useState()
  const [trendHovered, setTrendHovered] = useState()

  return (
    <div key={header.metric} className={cx('header-cell')}>
      {renderMetric(
        header,
        selectedSort,
        metricHovered,
        setMetricHovered,
        client,
        permitted
      )}
      {renderTrend(
        header,
        selectedSort,
        trendHovered,
        setTrendHovered,
        client,
        permitted
      )}
    </div>
  )
}

HeaderCell.propTypes = {
  header: PropTypes.shape().isRequired,
  selectedSort: PropTypes.shape().isRequired,
  permitted: PropTypes.bool.isRequired,
}

export { HeaderCell }
