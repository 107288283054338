import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import { useDeleteStationStrategy } from '../../hooks/useDeleteStationStrategy'
import { useCreateStationStrategy } from '../../hooks/useCreateStationStrategy'
import styles from './Options.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const isValidNewStrategy = newStrategy => {
  const lengths = newStrategy.split(' ').map(word => word.length)
  const maxLength = Math.max(...lengths)

  return newStrategy.trim() !== '' && maxLength < 50
}

const Options = ({ stationId, stationStrategy, newStrategy, onClose }) => {
  const { t } = useTranslation()
  const [deleteStrategy, { loading: deleteLoading }] = useDeleteStationStrategy(
    stationId,
    onClose
  )
  const [createStrategy, { loading: createLoading }] = useCreateStationStrategy(
    stationId,
    onClose
  )

  const validNewStrategy = !deleteLoading && isValidNewStrategy(newStrategy)
  const existingStrategy = !isNullOrUndefined(stationStrategy?.strategy)

  const handleDeleteClick = () => {
    deleteStrategy({
      variables: {
        stationId,
        stationStrategyId: stationStrategy.id,
      },
    })
  }

  const handleCreateClick = () => {
    if (!createLoading && validNewStrategy) {
      amplitudeEvent(UserEvents.CompetitorPricing.PRICING_STRATEGY_SAVED)
      createStrategy({
        variables: {
          stationId,
          strategy: newStrategy,
        },
      })
    }
  }

  const showDelete = existingStrategy && !createLoading
  return (
    <div className={cx('options')}>
      {showDelete &&
        (deleteLoading ? (
          <div className={cx('delete-spinner')}>
            <Spinner
              animation="border"
              variant="danger"
              data-testid="delete-spinner"
            />
          </div>
        ) : (
          <div
            className={cx('delete')}
            onClick={handleDeleteClick}
            data-testid="delete"
          >
            {t('widgets.competitorPricing.deleteStrategy')}
          </div>
        ))}
      <button
        className={cx('btn', 'save', {
          invalid: !validNewStrategy,
        })}
        onClick={handleCreateClick}
        data-testid="save"
      >
        {createLoading ? (
          <Spinner
            animation="border"
            variant="light"
            data-testid="create-spinner"
          />
        ) : (
          t('widgets.competitorPricing.save')
        )}
      </button>
    </div>
  )
}

Options.propTypes = {
  stationId: PropTypes.string.isRequired,
  stationStrategy: PropTypes.shape({
    id: PropTypes.string.isRequired,
    strategy: PropTypes.string.isRequired,
  }),
  newStrategy: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

Options.defaultProps = {}

export { Options }
