import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { upperFirstOnly } from 'utils/format'
import { ReactComponent as ExportIcon } from 'images/performance-report/export.svg'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { useCSVDownloader } from 'react-papaparse'
import classNames from 'classnames/bind'
import { useFormatExportData } from '../../hooks/useFormatExportData'
import styles from './ExportButton.module.scss'

const cx = classNames.bind(styles)

const parseFilename = (
  t,
  stationData,
  companyTimeZone,
  selectedPricingType
) => {
  const dateInZone = moment.tz(companyTimeZone).format('DD.MMM.YYYY')
  const pricingTypeKey = upperFirstOnly(selectedPricingType)

  if (!stationData) {
    return t(`widgets.performanceReport.fileNamePortfolio${pricingTypeKey}`, {
      dateInZone,
    })
  }

  const { name } = stationData
  return t(`widgets.performanceReport.fileName${pricingTypeKey}`, {
    name,
    dateInZone,
  })
}

const ExportButton = ({
  dataOne,
  dataTwo,
  metrics,
  hasComparative,
  metricType,
  stationData,
  selectedPricingType,
}) => {
  const { t } = useTranslation()
  const { companyTimeZone } = useCompanyTimeZone()
  const { CSVDownloader, Type } = useCSVDownloader()
  const { formatExportData } = useFormatExportData()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let timeout
    if (loading) {
      timeout = setTimeout(() => {
        setLoading(false)
      }, 500)
    }

    return () => clearTimeout(timeout)
  }, [loading])

  const filename = parseFilename(
    t,
    stationData,
    companyTimeZone,
    selectedPricingType
  )

  const handleClick = () => {
    amplitudeEvent(UserEvents.PerformanceReportCSVExport.CLICKED)
    setLoading(true)
  }

  const handleExportCSV = () => {
    if (!dataOne) {
      return []
    }

    return formatExportData(
      dataOne,
      dataTwo,
      metrics,
      hasComparative,
      metricType
    )
  }

  return (
    <div className={cx('export-button', { loading })}>
      <CSVDownloader
        type={Type.Button}
        filename={filename}
        bom
        data={handleExportCSV}
      >
        <div onClick={handleClick} data-testid="wrapper">
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <>
              <ExportIcon />
              {t('widgets.performanceReport.exportToCSV')}
            </>
          )}
        </div>
      </CSVDownloader>
    </div>
  )
}

ExportButton.propTypes = {
  dataOne: PropTypes.shape({
    performanceReport: PropTypes.arrayOf(PropTypes.shape()),
  }),
  dataTwo: PropTypes.shape({
    performanceReport: PropTypes.arrayOf(PropTypes.shape()),
  }),
  hasComparative: PropTypes.bool.isRequired,
  metrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  metricType: PropTypes.string.isRequired,
  stationData: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  selectedPricingType: PropTypes.string.isRequired,
}

ExportButton.defaultProps = {
  dataOne: null,
  dataTwo: null,
}

export { ExportButton, parseFilename }
