const FIRST_PAGE = 1
const PAGE_SIZE = 15
const TOTALS_METRIC_ID = '0'
const RENDER_DELAY = 500

const SortFields = {
  NAME: 'NAME',
  TOTAL_VOLUME: 'TOTAL_VOLUME',
  TOTAL_VOLUME_TREND: 'TOTAL_VOLUME_TREND',
  RETAIL_VOLUME: 'RETAIL_VOLUME',
  RETAIL_VOLUME_TREND: 'RETAIL_VOLUME_TREND',
  NET_MARGIN: 'NET_MARGIN',
  NET_MARGIN_TREND: 'NET_MARGIN_TREND',
  GROSS_MARGIN: 'GROSS_MARGIN',
  GROSS_MARGIN_TREND: 'GROSS_MARGIN_TREND',
  GROSS_PROFIT: 'GROSS_PROFIT',
  GROSS_PROFIT_TREND: 'GROSS_PROFIT_TREND',
}

const SortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
}

const FilterOperators = {
  ABOVE: 'ABOVE',
  BELOW: 'BELOW',
  GREATER_THAN: 'GREATER_THAN',
  IS: 'IS',
  IS_NOT: 'IS_NOT',
  LESS_THAN: 'LESS_THAN',
  UP_BY: 'UP_BY',
  DOWN_BY: 'DOWN_BY',
}

const GroupedPricingActions = {
  SET_OPEN_GROUP: 'SET_OPEN_GROUP',
  SET_LOADING_GROUP: 'SET_LOADING_GROUP',
  SET_GROUP_DATA: 'SET_GROUP_DATA',
}

const StationsListPricingStatus = {
  NONE: 'NONE',
  EDIT: 'EDIT',
  CONFIRM: 'CONFIRM',
  SEND: 'SEND',
  SENT: 'SENT',
  ERROR: 'ERROR',
}

export {
  FIRST_PAGE,
  PAGE_SIZE,
  TOTALS_METRIC_ID,
  RENDER_DELAY,
  SortFields,
  SortDirections,
  FilterOperators,
  GroupedPricingActions,
  StationsListPricingStatus,
}
