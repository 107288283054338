import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { slugify } from 'utils/slug'
import { stationMatches } from 'utils/search'
import { Backdrop } from 'components/common/backdrop'
import classNames from 'classnames/bind'
import { Search } from './components/search'
import { SearchResults } from './components/search-results'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import styles from './StationSearch.module.scss'

const cx = classNames.bind(styles)

const stationIds = stations => stations.map(({ id }) => id)

const StationSearch = ({ stations }) => {
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState(null)
  const [filteredStationIds, setFilteredStationIds] = useState(
    stationIds(stations)
  )
  const searchInput = useRef(null)
  const history = useHistory()

  const [debouncedSearch] = useDebounce(search, 2000)
  useEffect(() => {
    if (debouncedSearch) {
      amplitudeEvent(UserEvents.Search.STRING_ENTERED)
    }
  }, [debouncedSearch])

  const handleStationSearchClick = () => {
    if (!visible) {
      setFilteredStationIds(stationIds(stations))
      setVisible(true)
      amplitudeEvent(UserEvents.Search.CLICKED)
    }
    searchInput.current.focus()
  }

  const handleCloseStationSearch = event => {
    event.stopPropagation()
    setVisible(false)
    setSearch('')
    setFilter(null)
    searchInput.current.blur()
  }

  const handleSearchChange = search => {
    setSearch(search)
    setFilter(null)
    const ids = stations
      .filter(({ name }) => stationMatches(name, search))
      .map(({ id }) => id)
    setFilteredStationIds(ids)
  }

  const handleFilterSelect = filter => {
    setSearch('')
    setFilter(filter)
    const ids = stations
      .filter(({ name }) => name.toLowerCase().startsWith(filter.toLowerCase()))
      .map(({ id }) => id)
    setFilteredStationIds(ids)
  }

  const handleStationSelect = (event, name) => {
    handleCloseStationSearch(event)
    const slug = slugify(name)
    history.push(`/stations/${slug}`)
  }

  return (
    <>
      <Backdrop visible={visible} onClick={handleCloseStationSearch} />
      <div
        className={cx('stations-search')}
        data-testid="stations-search"
        onClick={handleStationSearchClick}
      >
        <Search
          visible={visible}
          search={search}
          searchInput={searchInput}
          onSearchChange={handleSearchChange}
          onClose={handleCloseStationSearch}
        />
        <SearchResults
          visible={visible}
          search={search}
          filter={filter}
          stations={stations}
          filteredStationIds={filteredStationIds}
          onStationSelect={handleStationSelect}
          onFilterSelect={handleFilterSelect}
        />
      </div>
    </>
  )
}

StationSearch.propTypes = {
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { StationSearch }
