import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { upperFirstOnly } from 'utils/format'
import classNames from 'classnames/bind'
import { useTranslations } from 'config/locale/hooks/requireContext'
import { ReactComponent as VolumeIcon } from 'images/analytics-report/volume.svg'
import { ReactComponent as MarginIcon } from 'images/analytics-report/margin.svg'
import { ReactComponent as CostIcon } from 'images/analytics-report/costs.svg'
import { ReactComponent as TransactionIcon } from 'images/analytics-report/transactions.svg'
import { ReactComponent as FillUpIcon } from 'images/analytics-report/fill-up.svg'
import { Dropdown } from 'components/common/dropdown'
import styles from './Metrics.module.scss'
import { metricVar } from 'config/apollo/analytics-report/variables'

const cx = classNames.bind(styles)

const Metrics = ({ activeTab }) => {
  const { t } = useTranslation(['translations'])
  const translations = useTranslations(i18n.language)
  const SmallProfitIcon = translations?.smallProfitIcon
  const [metric, setMetric] = useState(metricVar)

  const setSelectedMetric = (selectedMetric) => {
    setMetric((prevState) => ({
      ...prevState,
      [activeTab]: selectedMetric
    }))
  }

  const setMetricVar = (selectedMetric) =>{
    metricVar({
      ...metricVar(),
      [activeTab]: selectedMetric
    })
  }

  const metricItems = [
    {
      value: 'volume',
      label: upperFirstOnly(t('widgets.analytics.volume')),
      items: [
        { item: 'total_fuel_volume_sales', displayItem: upperFirstOnly(t('widgets.analytics.totalVolume')) },
        { item: 'retail_fuel_volume_sales_for_computing_margin', displayItem: upperFirstOnly(t('widgets.analytics.retailVolume')) },
        { item: 'bunkered_fuel_volume_sales', displayItem: upperFirstOnly(t('widgets.analytics.bunkeredVolume')) },
        { item: 'consumer_fuel_volume_sales', displayItem: upperFirstOnly(t('widgets.analytics.consumerVolume')) },
        { item: 'fuel_card_fuel_volume_sales', displayItem: upperFirstOnly(t('widgets.analytics.fuelCardVolume')) },
      ],
      Icon: VolumeIcon,
    },
    {
      value: 'margin',
      label: upperFirstOnly(t('widgets.analytics.margin')),
      items: [
        { item: 'gross_margin', displayItem: upperFirstOnly(t('widgets.analytics.grossMargin')) },
        { item: 'net_margin', displayItem: upperFirstOnly(t('widgets.analytics.netMargin')) },
      ],
      Icon: MarginIcon,
    },
    {
      value: 'profit',
      label: t('widgets.analytics.salesProfit'),
      items: [
        { item: 'retail_gross_sales_ex_vat_for_computing_margin', displayItem: upperFirstOnly(t('widgets.analytics.grossSales')) },
        { item: 'gross_profit', displayItem: upperFirstOnly(t('widgets.analytics.grossProfit')) },
        { item: 'net_profit', displayItem: upperFirstOnly(t('widgets.analytics.netProfit')) },
        { item: 'business_profit', displayItem: upperFirstOnly(t('widgets.analytics.businessProfit')) },
      ],
      Icon: SmallProfitIcon,
    },
    {
      value: 'cost',
      label: t('widgets.analytics.costFees'),
      items: [
        { item: 'bunkering_commissions', displayItem: upperFirstOnly(t('widgets.analytics.bunkeringComissions')) },
        { item: 'cost_price', displayItem: upperFirstOnly(t('widgets.analytics.replacementCost')) },
        { item: 'total_fees', displayItem: upperFirstOnly(t('widgets.analytics.totalFees')) },
        { item: 'retail_blended_cost', displayItem: upperFirstOnly(t('widgets.analytics.blendedCostSold')) },
      ],
      Icon: CostIcon,
    },
  ]

  const tabs = [
    { value: 'num_retail_transactions', label: upperFirstOnly(t('widgets.analytics.retailTransactions')), Icon: TransactionIcon },
    { value: 'avg_retail_fill_up', label: upperFirstOnly(t('widgets.analytics.retailFillUp')), Icon: FillUpIcon },
  ]

  const handleItemClick = (event) => {
    const target = event.target.querySelector('span')
    const items = metricItems.map((metricItem) => metricItem.items.find((item) => item.displayItem === target?.textContent))
    const item = items.filter((n) => n)

    if (item[0]) {
      setSelectedMetric(item[0].item)
      setMetricVar(item[0].item)
    }
  }

  const handleTabClick = (event) => {
    const target = event.currentTarget.value

    setSelectedMetric(target)
    setMetricVar(target)
  }

  const parseSelectedItem = () => {
    return metricItems.map((metricItem) => {
      const foundItem = metricItem.items.find((item1) => item1.item === metric[activeTab])

      if (foundItem) {
        return metric[activeTab]
      } else {
        return metricItem.label
      }
    })
  }

  const selectedItem = parseSelectedItem()

  return (
    <div className={cx('select-boxes')}>
      {metricItems.map((currentDropdown, i) => (
        <Dropdown
          key={`${i}_metric_dropdown_item_${currentDropdown.label}`}
          selectedItem={selectedItem[i]}
          items={currentDropdown.items}
          leftAccessory={currentDropdown.Icon && <currentDropdown.Icon className={cx('icon')} />}
          onItemClick={handleItemClick}
          selectedDropdownHighlight={true}
        />
      ))}

      {tabs.map((currentTab) => (
        <ToggleButton
          key={currentTab.value}
          type="radio"
          name="tabs"
          className={cx('tab', { disabled: currentTab.disabled })}
          value={currentTab.value}
          disabled={currentTab.disabled}
          checked={metric[activeTab] === currentTab.value}
          onChange={handleTabClick}
          data-testid={currentTab.value}
        >
          {currentTab.Icon && <currentTab.Icon />}
          {currentTab.label}
        </ToggleButton>
      ))}
    </div>
  )
}

Metrics.propTypes = {
  activeTab: PropTypes.string,
}

export { Metrics }
