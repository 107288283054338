import React, { useState, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useQuery } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'
import { useSetupApp } from 'components/common/hooks/useSetupApp'
import { StationsProvider } from 'components/common/hooks/useStations'
import { Sidebar } from 'components/sidebar'
import { Header } from 'components/header'
import { MaintenanceMode } from 'components/maintenance-mode'
import { SettingThingsUp } from './components/setting-things-up'
import { VersionManagement } from './components/version-management'
import { Routes } from './MainRoutes'
import { MAIN_QUERY } from './MainQueries'
import { Copyright } from './components/copyright'
import styles from './Main.module.scss'
import { InactivityLogout } from './components/inactivity-logout'

const maintenanceModeEnabled = LDMaintenanceFlag =>
  // if Launch Darkly is down we can use the ENV var
  process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? true : LDMaintenanceFlag

const cx = classNames.bind(styles)

const Main = () => {
  const { error, data } = useQuery(MAIN_QUERY)
  useErrorHandler(error)

  const [showLoading, setShowLoading] = useState(true)
  const { maintenanceMode } = useFlags()
  const { loading } = useSetupApp(data)
  const isEdgeDataPro = data?.me.company.edgeDataPro

  useEffect(() => {
    const timeout = setTimeout(() => setShowLoading(false), 1000)
    return () => clearTimeout(timeout)
  }, [])

  if (showLoading || loading) {
    return <SettingThingsUp />
  }

  const mainRoutes = Routes(data)

  const isMaintenanceMode = maintenanceModeEnabled(maintenanceMode)
  if (isMaintenanceMode) {
    return <MaintenanceMode />
  }

  return (
    <InactivityLogout>
      <VersionManagement>
        <StationsProvider stations={data?.me?.stations?.items}>
          <div className={cx('container')}>
            { !isEdgeDataPro && <Sidebar mainData={data} /> }
            <main className={cx('main')}>
              <Header mainData={data}/>
              <div className={cx('main-content')}>{mainRoutes}</div>
              <Copyright />
            </main>
          </div>
        </StationsProvider>
      </VersionManagement>
    </InactivityLogout>
  )
}

export { Main }
