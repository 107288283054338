import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { getDaysBetweenByWeek } from 'utils/dateTime'
import { ReactComponent as BackArrow } from 'images/calendar/back-arrow.svg'
import styles from './Calendar.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const parseStateValue = (multiSelect, value, timeZone) => {
  if (multiSelect) {
    return {
      from: value?.from && moment.tz(value.from, timeZone),
      to: value?.to && moment.tz(value.to, timeZone),
    }
  }

  return value && moment.tz(value, timeZone)
}

const getCurrentDate = (multiSelect, value, timeZone) => {
  if (multiSelect && value?.to) {
    return moment.tz(value.to, timeZone)
  }
  return moment.utc(value)
}

/**
 * All input moments should be in UTC. All outputs are also in UTC. Internally the Calendar widget converts moments to the company time zone for display.
 */
const Calendar = ({
  value,
  onValueChange,
  minDate,
  maxDate,
  maxDays,
  multiSelect,
  timeZone,
  header
}) => {
  const [currentDate, setCurrentDate] = useState(() =>
    getCurrentDate(multiSelect, value, timeZone)
  )
  const [stateValue, setStateValue] = useState(() =>
    parseStateValue(multiSelect, value, timeZone)
  )
  const [prevStateValue, setPrevStateValue] = useState(null)

  useEffect(() => {
    setStateValue(parseStateValue(multiSelect, value, timeZone))
  }, [multiSelect, value, timeZone])

  const monthInZone = currentDate.month()
  const yearInZone = currentDate.year()

  const dateStart = moment
    .tz([yearInZone, monthInZone, 1], timeZone)
    .startOf('isoweek')
  const dateEnd = moment
    .tz([yearInZone, monthInZone, 1], timeZone)
    .add(1, 'month')
    .add(-1, 'days')
    .endOf('isoweek')

  // default to half a year
  const maxDaysParsed = maxDays ? maxDays - 1 : 182
  const maxDaysMinDate =
    prevStateValue && moment(prevStateValue.from).add(-maxDaysParsed, 'days')
  const maxDaysMaxDate =
    prevStateValue && moment(prevStateValue.from).add(maxDaysParsed, 'days')

  const weeks = getDaysBetweenByWeek(dateStart, dateEnd)

  const handleClickBack = () => {
    /* istanbul ignore next */
    if (isBackButtonDisabled()) {
      return
    }

    const date = moment(currentDate)
    date.add(-1, 'months')
    setCurrentDate(date)
  }

  const handleClickNext = () => {
    /* istanbul ignore next */
    if (isNextButtonDisabled()) {
      return
    }

    const date = moment(currentDate)
    date.add(1, 'months')
    setCurrentDate(date)
  }

  const handleClickDate = date => {
    if (isDateDisabled(date)) {
      return
    }

    const dateMonth = moment(date).month()
    const dateYear = moment(date).year()

    if (dateYear < yearInZone) {
      handleClickBack()
    } else if (dateYear > yearInZone) {
      handleClickNext()
    } else if (dateMonth < monthInZone) {
      handleClickBack()
    } else if (dateMonth > monthInZone) {
      handleClickNext()
    } else {
      if (multiSelect) {
        if (!prevStateValue) {
          const value = { from: date }
          setStateValue(value)
          setPrevStateValue(value)
        } else {
          let value = { ...prevStateValue, to: date }

          if (value.from > value.to) {
            const from = value.from
            value.from = value.to
            value.to = from
          }

          value = clampValue(value)

          setStateValue(value)
          setPrevStateValue(null)
          /* istanbul ignore else*/
          if (onValueChange) {
            onValueChange({
              from: value.from && moment(value.from).utc(),
              to: value.to && moment(value.to).utc(),
            })
          }
        }
      } else {
        setStateValue(date)
        /* istanbul ignore else*/
        if (onValueChange) {
          const utcDate = moment(date).utc()
          onValueChange(utcDate)
        }
      }
    }
  }

  const clampValue = value => {
    const { to, from } = value
    if (maxDate && to && to.isAfter(maxDate)) {
      value.to = maxDate
    }

    if (minDate && from && from.isBefore(minDate)) {
      value.from = minDate
    }

    if (maxDaysMinDate && from && from.isBefore(maxDaysMinDate)) {
      value.from = maxDaysMinDate
    }

    if (maxDaysMaxDate && to && to.isAfter(maxDaysMaxDate)) {
      value.to = maxDaysMaxDate
    }

    return value
  }

  const handleMouseOver = date => {
    /* istanbul ignore next */
    if (!prevStateValue) {
      return
    }

    let value = { ...prevStateValue, to: date }

    if (value.to < value.from) {
      const from = value.from
      value.from = value.to
      value.to = from
    }

    value = clampValue(value)

    setStateValue(value)
  }

  const handleMouseOut = () => {
    if (prevStateValue) {
      setStateValue(prevStateValue)
    }
  }

  const isBackButtonDisabled = () => {
    if (minDate) {
      const minDateInZone = moment.tz(minDate, timeZone)
      if (
        (yearInZone === minDateInZone.year() &&
          monthInZone <= minDateInZone.month()) ||
        yearInZone < minDateInZone.year()
      ) {
        return true
      }
    }

    if (maxDaysMinDate) {
      return (
        (yearInZone === maxDaysMinDate.year() &&
          monthInZone <= maxDaysMinDate.month()) ||
        yearInZone < maxDaysMinDate.year()
      )
    }

    return false
  }

  const isNextButtonDisabled = () => {
    if (maxDate) {
      const maxDateInZone = moment.tz(maxDate, timeZone)
      if (
        (yearInZone === maxDateInZone.year() &&
          monthInZone >= maxDateInZone.month()) ||
        yearInZone > maxDateInZone.year()
      ) {
        return true
      }
    }

    if (maxDaysMaxDate) {
      return (
        (yearInZone === maxDaysMaxDate.year() &&
          monthInZone >= maxDaysMaxDate.month()) ||
        yearInZone > maxDaysMaxDate.year()
      )
    }

    return false
  }

  const isDateDisabled = date => {
    if (date.isBefore(minDate) || date.isAfter(maxDate)) {
      return true
    }

    if (maxDaysMinDate && maxDaysMinDate.isAfter(date)) {
      return true
    }

    if (maxDaysMaxDate && maxDaysMaxDate.isBefore(date)) {
      return true
    }

    return false
  }

  const isDateActive = date => {
    if (multiSelect) {
      if (stateValue?.from && date.isBefore(stateValue.from)) {
        return false
      }

      if (stateValue?.to && date.isAfter(stateValue.to)) {
        return false
      }

      if (!stateValue?.to && !date.isSame(stateValue.from)) {
        return false
      }

      return true
    } else {
      return stateValue && date.isSame(stateValue, 'day')
    }
  }

  const isDateFirstActive = date => {
    if (multiSelect) {
      return date.isSame(stateValue?.from, 'day')
    } else {
      return stateValue && date.isSame(stateValue, 'day')
    }
  }

  const isDateLastActive = date => {
    if (multiSelect) {
      return stateValue?.to && date.isSame(stateValue?.to, 'day')
    } else {
      return stateValue && date.isSame(stateValue, 'day')
    }
  }

  const disableBackButton = isBackButtonDisabled()
  const disableNextButton = isNextButtonDisabled()

  return (
    <div className={cx('container')} data-testid="calendar">
      {header &&
        <div className={cx('header-label-row')}>
          {header}
        </div>
      }
      <div className={cx('header-row')}>
        <button
          onClick={handleClickBack}
          className={cx('nav-button', 'back-button', {
            disabled: disableBackButton,
          })}
          disabled={disableBackButton}
          data-testid="back"
        >
          <BackArrow />
        </button>
        <span data-testid="month-year" className={cx('month-year')}>
          {currentDate.format('MMMM')} {currentDate.format('YYYY')}
        </span>
        <button
          onClick={handleClickNext}
          className={cx('nav-button', 'next-button', {
            disabled: disableNextButton,
          })}
          disabled={disableNextButton}
          data-testid="next"
        >
          <BackArrow className={cx('arrow')} />
        </button>
      </div>
      <table className={cx('table')}>
        <thead>
          <tr className={cx('weekdays')}>
            {weeks[0].map((date, i) => {
              return (
                <th key={`${i}`} className={cx('weekday')}>
                  {date.format('ddd')}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, i) => (
            <tr key={`${i}`} className={cx('days')}>
              {week.map(date => {
                return (
                  <td
                    key={date.toISOString()}
                    className={cx('day', {
                      'day-in-month': monthInZone === date.month(),
                      disabled: isDateDisabled(date),
                      active: isDateActive(date),
                      'first-active': isDateFirstActive(date),
                      'last-active': isDateLastActive(date),
                    })}
                    data-testid={`day-${moment(date).format('yyyy-MM-DD')}`}
                    onClick={() => handleClickDate(date)}
                    onMouseOver={() => handleMouseOver(date)}
                    onMouseOut={handleMouseOut}
                  >
                    {date.date()}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

Calendar.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.shape({
      from: PropTypes.instanceOf(moment),
      to: PropTypes.instanceOf(moment),
    }),
  ]),
  onValueChange: PropTypes.func,
  maxDate: PropTypes.instanceOf(moment),
  minDate: PropTypes.instanceOf(moment),
  multiSelect: PropTypes.bool,
  maxDays: PropTypes.number,
  timeZone: PropTypes.string.isRequired,
  header: PropTypes.string
}

Calendar.defaultProps = {
  maxDate: null,
  minDate: null,
  multiSelect: false,
  header: null
}

export { Calendar }
