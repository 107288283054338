import React from 'react'
import PropTypes from 'prop-types'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import classNames from 'classnames/bind'
import { getMetric } from '../../../../utils'
import styles from './MetricCell.module.scss'

const cx = classNames.bind(styles)

const MetricCell = ({
  metricData,
  metricType,
  previousColumn,
  value,
  analytics,
}) => {
  const { formatMetricValue } = useFormatMetricValue()
  const metricValue = analytics ? value : metricData[getMetric(metricData.metricToGraph)]

  return (
    <td className={cx('metric-cell')}>
      <p>{formatMetricValue(metricType, metricValue, true, analytics)}</p>

      {previousColumn && ( 
        <p>{formatMetricValue(metricType, metricData.previousValue, true, analytics)}</p> )}
    </td>
  )
}

MetricCell.propTypes = {
  metricData: PropTypes.shape().isRequired,
  previousColumn: PropTypes.bool.isRequired,
  metricType: PropTypes.string.isRequired,
  value: PropTypes.number,
  analytics: PropTypes.bool
}

export { MetricCell }
