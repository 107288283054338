import { withErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'

const withEdgeErrorBoundary = (Component, FallbackComponent) => {
  return withErrorBoundary(Component, {
    FallbackComponent,
    onError(error, errorInfo) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo)
        Sentry.captureException(error)
      })
    },
  })
}

export { withEdgeErrorBoundary }
