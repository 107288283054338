import React from 'react'
import { ReactComponent as NeutralEmojiIcon } from 'images/stations-list/neutral-emoji.svg'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import styles from './NoChangesBody.module.scss'

const cx = classNames.bind(styles)

const NoChangesBody = () => {
  const { t } = useTranslation()

  return (
    <div className={cx('no-changes-body')}>
      <div className={cx('content')}>
        <div className={cx('emoji')}>
          <NeutralEmojiIcon />
        </div>
        <h2>{t('widgets.stationsListPricing.noChangesTitle')}</h2>
        <p>{t('widgets.stationsListPricing.noChangesBody')}</p>
      </div>
    </div>
  )
}

NoChangesBody.propTypes = {}

export { NoChangesBody }
