import EdgeLogo from 'images/edge-logo.svg'
import DefaultBrand from './default-brand.svg'

const images = {
  brandImage: i =>
    `https://edgepetrol-web-assets.s3-eu-west-1.amazonaws.com/brands/${i?.toLowerCase()}-logo.svg`,
  paymentLogo: i =>
    `https://edgepetrol-web-assets.s3-eu-west-1.amazonaws.com/fuel-card-payments/${i?.toLowerCase()}.svg`,
  polePriceShort: i =>
    `https://edgepetrol-web-assets.s3-eu-west-1.amazonaws.com/pole-price-sources/short/${i?.toLowerCase()}.svg`,
  polePriceLong: i =>
    `https://edgepetrol-web-assets.s3-eu-west-1.amazonaws.com/pole-price-sources/long/${i?.toLowerCase()}.svg`,
}

const fallback = {
  default: EdgeLogo,
  defaultBrand: DefaultBrand,
  loader: '',
  unLoader: '',
}

export { images, fallback }
