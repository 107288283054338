import { ApolloLink, createHttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const { REACT_APP_GATEWAY_URI } = process.env

const httpLink = createHttpLink({
  uri: `${REACT_APP_GATEWAY_URI}/graphql`,
  credentials: 'include',
})

export const errorLink = errorResponse => {
  if (errorResponse?.graphQLErrors?.some(e => e.extensions?.code === 'RETRY')) {
    const { forward, operation } = errorResponse

    return forward(operation)
  } else if (
    errorResponse?.graphQLErrors?.some(
      e => e.extensions?.code === 'UNAUTHENTICATED'
    ) &&
    errorResponse?.operation?.operationName !== 'authContextQuery'
  ) {
    window.location.reload()
  }
}

const link = ApolloLink.from([onError(errorLink), httpLink])

export { link }
