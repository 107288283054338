import { useQuery, gql } from '@apollo/client'
import { STATION_STATUS_ATTRIBUTES } from 'config/apollo/fragments'
import { StationStatusPollInterval } from 'utils/constants'

const STATION_STATUS_QUERY = gql`
  query stationStatusQuery(
    $stationId: ID!
    $transactionStatus: Boolean!
    $dipStatus: Boolean!
    $costPriceStatus: Boolean!
  ) {
    stationsStatus(stationIds: [$stationId]) {
      ...StationStatusAttributes
    }
  }
  ${STATION_STATUS_ATTRIBUTES}
`

function useStationStatusQuery(stationId, skip = false) {
  const { error, data } = useQuery(STATION_STATUS_QUERY, {
    variables: {
      stationId,
      transactionStatus: true,
      dipStatus: true,
      costPriceStatus: true,
    },
    skip,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval: StationStatusPollInterval,
  })

  return { error, data }
}

export { useStationStatusQuery, STATION_STATUS_QUERY }
