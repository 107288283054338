import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { TimeFormats } from 'utils/constants'

const currentDayInZone = timeZone => moment.tz(timeZone).format('dddd')

/**
 * Returns dates for next seven days including today in system timezone.
 */
const nextSevenDays = () => {
  const date = new Date()
  const today = () => moment(date)
  return [...Array(7).keys()].map(i => today().add(i, 'days'))
}

/**
 * Returns the difference in calendar days between two moments.
 */
const calendarDaysDifference = (momentOne, momentTwo) => {
  const dayOne = momentOne.clone().startOf('day')
  const dayTwo = momentTwo.clone().startOf('day')

  return dayOne.diff(dayTwo, 'days')
}

/**
 * Returns true if both moments are in the same day in the given time zone.
 */
const isToday = (companyTimeZone, momentOne, momentTwo) => {
  const momentTimeZoneOne = moment.tz(momentOne, companyTimeZone)
  const momentTimeZoneTwo = moment.tz(momentTwo, companyTimeZone)

  return momentTimeZoneOne.isSame(momentTimeZoneTwo, 'day')
}

const isFirstDayOf = (period, companyTimeZone) => {
  const today = moment.tz(moment.utc(), companyTimeZone)

  switch (period) {
    case "month":
      return today.date() === 1
    case "quarter":
      return today.month() % 3 === 0 && today.date() === 1
    case "year":
      return today.month() === 0 && today.date() === 1
    default:
      return false
  }
}

export const cacheKey = (...objects) => {
  let s = []
  for (const o of objects) {
    if (typeof o === 'string') {
      s.push(o)
    } else if (typeof o === 'object') {
      for (const v in o) {
        s.push(`${v}-${o[v]}`)
      }
    }
  }
  return s.join('-')
}

const cacheDateTimeFormat = (() => {
  const cache = {}

  return (locale, options) => {
    const key = cacheKey(locale, options)
    if (!cache[key]) {
      cache[key] = Intl.DateTimeFormat(locale, options)
    }
    return cache[key]
  }
})()

const cachePluralRules = (() => {
  const cache = {}

  return (locale, options) => {
    const key = cacheKey(locale, options)
    if (!cache[key]) {
      cache[key] = new Intl.PluralRules(locale, options)
    }
    return cache[key]
  }
})()

const cacheTranslation = (() => {
  const cache = {}

  return (t, key) => {
    key = cacheKey(key)
    if (!cache[key]) {
      cache[key] = t(key)
    }
    return cache[key]
  }
})()

/**
 * Uses browser built-in Intl.DateTimeFormat to return an i18n date component
 */
const getDatePart = (date, part, locale, options) => {
  return cacheDateTimeFormat(locale, options)
    .formatToParts(date)
    .find(p => p.type === part).value
}

/**
 * Returns all days between and including the dateStart and dateEnd dates partitioned
 * by week
 */
const getDaysBetweenByWeek = (dateStart, dateEnd) => {
  let currentWeek = []
  const weeks = [currentWeek]
  let currentDay = dateStart
  do {
    if (currentWeek.length < 7) {
      currentWeek.push(currentDay)
    } else {
      currentWeek = [currentDay]
      weeks.push(currentWeek)
    }

    currentDay = moment(currentDay).add(1, 'day')
  } while (currentDay <= dateEnd)

  return weeks
}

/**
 * Returns the i18n date format for a particular date
 */
const getTextFromDate = (locale, t, date) => {
  if (!date) {
    return null
  }

  const day = getDatePart(date, 'day', locale, { day: 'numeric' })
  const month = getDatePart(date, 'month', locale, { month: 'short' })
  const year = getDatePart(date, 'year', locale, { year: 'numeric' })

  const pr = cachePluralRules(locale, { type: 'ordinal' })
  const ordinal = cacheTranslation(t, `ordinals.${pr.select(day)}`)

  return `${day}${ordinal} ${month} ${year}`
}

/**
 * Returns the i18n full date format for a particular date without year
 */
const getFullTextWithoutYearFromDate = (locale, t, date) => {
  if (!date) {
    return null
  }

  const options = { weekday: 'long', day: 'numeric', month: 'long' }

  const weekday = getDatePart(date, 'weekday', locale, options)
  const day = getDatePart(date, 'day', locale, options)
  const month = getDatePart(date, 'month', locale, options)

  const pr = cachePluralRules(locale, { type: 'ordinal' })
  const ordinal = cacheTranslation(t, `ordinals.${pr.select(day)}`)

  return `${weekday} ${day}${ordinal} ${month}`
}

/**
 * Returns the i18n day and month for a particular date
 */
const getMediumTextFromDate = (locale, t, date) => {
  if (!date) {
    return null
  }

  const options = { day: 'numeric', month: 'long' }

  const day = getDatePart(date, 'day', locale, options)
  const month = getDatePart(date, 'month', locale, options)

  const pr = cachePluralRules(locale, { type: 'ordinal' })
  const ordinal = cacheTranslation(t, `ordinals.${pr.select(day)}`)

  return `${day}${ordinal} ${month}`
}

/**
 * Returns the i18n shortened month
 */
const getShortMonth = (locale, date, timeZone) => {
  if (!date) {
    return null
  }

  return `${getDatePart(date, 'month', locale, {
    timeZone,
    month: 'short',
  })}`
}

/**
 * Returns the numeric day
 */
const getDay = (locale, date, timeZone) => {
  if (!date) {
    return null
  }

  const options = {
    timeZone,
    day: 'numeric',
  }

  const day = getDatePart(date, 'day', locale, options)

  return day
}

/**
 * Returns the day and i18n shortened month
 */
const getDayShortMonth = (locale, date, timeZone) => {
  if (!date) {
    return null
  }

  const options = {
    timeZone,
    day: 'numeric',
    month: 'short',
  }

  const day = getDatePart(date, 'day', locale, options)
  const month = getDatePart(date, 'month', locale, options)

  return `${day} ${month}`
}

/**
 * Returns the day, i18n shortened month, year
 */
const getDayShortMonthYear = (locale, date, timeZone) => {
  if (!date) {
    return null
  }

  const options = {
    timeZone,
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  const day = getDatePart(date, 'day', locale, options)
  const month = getDatePart(date, 'month', locale, options)
  const year = getDatePart(date, 'year', locale, options)

  return `${day} ${month} ${year}`
}

/**
 * Returns a date range string formatted for performance reporting. This string is in the company time zone.
 */
const dateRangeToString = (companyTimeZone, range) => {
  if (!range?.from || !range?.to) {
    return null
  }

  const format = 'DD/MM/yyyy'
  const from = moment.tz(range.from, companyTimeZone).format(format)
  const to = moment.tz(range.to, companyTimeZone).format(format)

  return `${from}..${to}`
}

const dateToString = (companyTimeZone, date) => {
  if (!date) {
    return null
  }

  const format = 'YYYY-MM-DD'
  return moment.tz(date, companyTimeZone).format(format)
}

/**
 * Returns a new date range from fromDays to toDays inclusive in the company time zone. The range itself is returned in UTC.
 */
const createDateRangeFromNow = (fromDays, toDays, companyTimeZone) => {
  const from = moment()
    .tz(companyTimeZone)
    .startOf('day')
    .add(fromDays, 'days')
    .utc()
  const to = moment
    .tz(companyTimeZone)
    .startOf('day')
    .add(toDays, 'days')
    .utc()

  return {
    from,
    to,
  }
}

/**
 * Calculates the difference between now and the date for both dates in a period.
 */
const getDaysFromDateRange = period => {
  if (!period?.from || !period?.to) {
    return { from: null, to: null }
  }

  return {
    from: period.from.diff(moment.utc(), 'days'),
    to: period.to.diff(moment.utc(), 'days'),
  }
}

/**
 * Returns a date object for now in the given timezone
 */
const getDateObjectNowInLocale = timeZone => {
  if (!timeZone) {
    return null
  }

  const date = Date.now()
  const locale = 'en-GB'

  const options = {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }

  const year = getDatePart(date, 'year', locale, options)
  const month = getDatePart(date, 'month', locale, options)
  const day = getDatePart(date, 'day', locale, options)
  const hour = getDatePart(date, 'hour', locale, options)
  const minute = getDatePart(date, 'minute', locale, options)
  const second = getDatePart(date, 'second', locale, options)

  return moment
    .utc(`${year}-${month}-${day}T${hour}:${minute}:${second}.000`)
    .toDate()
}

/**
 * Returns a date range of this week Monday to this week Sunday in company time zone.
 * The range itself is returned in UTC.
 */
const thisWeekDateRange = timeZone => {
  const mondayOfThisWeek = moment()
    .tz(timeZone)
    .startOf('isoWeek')
    .utc()

  const sundayOfThisWeek = moment()
    .tz(timeZone)
    .endOf('isoWeek')
    .utc()

  return { from: mondayOfThisWeek, to: sundayOfThisWeek }
}

/**
 * Returns a date range of last week Monday to last week Sunday in company time zone.
 * The range itself is returned in UTC.
 */
const lastWeekDateRange = timeZone => {
  const mondayOfLastWeek = moment()
    .tz(timeZone)
    .startOf('isoWeek')
    .add(-7, 'days')
    .utc()

  const sundayOfLastWeek = moment()
    .tz(timeZone)
    .endOf('isoWeek')
    .add(-7, 'days')
    .utc()

  return { from: mondayOfLastWeek, to: sundayOfLastWeek }
}

/**
 * Converts a 24 hour format time string ("14:30") to a 12 hour format string ("2:30pm").
 */
const convert24To12HourTime = twentyFourHourTime => {
  return moment(twentyFourHourTime, TimeFormats.TWENTY_FOUR_HOUR).format(
    TimeFormats.TWELVE_HOUR
  )
}

export {
  currentDayInZone,
  calendarDaysDifference,
  isToday,
  nextSevenDays,
  getDatePart,
  getDaysBetweenByWeek,
  getTextFromDate,
  getFullTextWithoutYearFromDate,
  getMediumTextFromDate,
  getDay,
  getShortMonth,
  getDayShortMonth,
  getDayShortMonthYear,
  dateRangeToString,
  createDateRangeFromNow,
  getDaysFromDateRange,
  getDateObjectNowInLocale,
  thisWeekDateRange,
  lastWeekDateRange,
  convert24To12HourTime,
  dateToString,
  isFirstDayOf,
}
