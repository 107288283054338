import React from 'react'
import { PropTypes } from 'prop-types'
import i18n from 'i18next'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {
  Locales,
  PerformanceProfitTypes,
  PerformanceTypes,
} from 'utils/constants'
import { getComparativePeriod } from 'utils/helpers'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { ReactComponent as GrossMarginIcon } from 'images/company-performance/gross-margin.svg'
import { ReactComponent as NetMarginIcon } from 'images/company-performance/net-margin.svg'
import { ReactComponent as ProfitPoundIcon } from 'images/company-performance/profit-pound.svg'
import { ReactComponent as ProfitEuroIcon } from 'images/company-performance/profit-euro.svg'
import { ReactComponent as ProfitDollarIcon } from 'images/company-performance/profit-dollar.svg'
import { ReactComponent as VolumeIcon } from 'images/company-performance/volume.svg'
import { TrendIndicator } from 'components/common/trend-indicator'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'
import classNames from 'classnames/bind'
import styles from './TabNavMetrics.module.scss'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

const { GROSS_PROFIT, NET_PROFIT, BUSINESS_PROFIT } = PerformanceProfitTypes
const { RETAIL_VOLUME, GROSS_MARGIN, NET_MARGIN } = PerformanceTypes

const determineProfitIcon = () => {
  switch (i18n.language) {
    case Locales.EN_GB:
      return ProfitPoundIcon
    case Locales.EN_IE:
      return ProfitEuroIcon
    case Locales.EN_US:
      return ProfitDollarIcon
    default:
      throw Error(`unsupported locale: ${i18n.language}`)
  }
}

const iconType = performanceType => {
  switch (performanceType) {
    case RETAIL_VOLUME:
      return VolumeIcon
    case GROSS_MARGIN:
      return GrossMarginIcon
    case NET_MARGIN:
      return NetMarginIcon
    case GROSS_PROFIT:
    case NET_PROFIT:
    case BUSINESS_PROFIT:
      return determineProfitIcon()
    /* istanbul ignore next */
    default:
      break
  }
}

const TabNavMetrics = ({
  permittedToView,
  disabledForCash,
  performanceTitle,
  performanceType,
  metricType,
  trend,
  value,
  comparativeValue,
}) => {
  const { formatMetricValue } = useFormatMetricValue()
  const { t } = useTranslation()

  const trendDirection = () => {
    switch (true) {
      case !permittedToView:
      case trend === 0:
        return 'neutral'
      case trend < 0:
        return 'negative'
      default:
        return ''
    }
  }

  const renderIcon = IconComponent => (
    <IconComponent className={cx('metric-icon', trendDirection())} />
  )

  const disabledMask = () => {
    const tooltip = (
      <Tooltip className={cx('disabled-tooltip')}>
        <div>{t('widgets.stationPerformance.disabledTooltip')}</div>
      </Tooltip>
    )

    return (
      <OverlayTrigger placement={'top'} overlay={tooltip}>
        <div className={cx('disabled-mask')}></div>
      </OverlayTrigger>
    )
  }

  const comparativePeriod = getComparativePeriod(metricType)

  const renderValue = (
    <Permissioned
      permission={permittedToView}
      fallback={<Placeholder widthPercentage={100} />}
    >
      {formatMetricValue(metricType, value)}
    </Permissioned>
  )

  const renderTrend = (
    <Permissioned
      permission={permittedToView}
      fallback={<Placeholder widthPercentage={70} />}
    >
      <TrendIndicator
        tooltip={true}
        trend={trend}
        comparative={comparativeValue}
        comparativeType={metricType}
        comparativePeriod={comparativePeriod}
        tooltipPlacement={'top'}
      />
    </Permissioned>
  )

  return (
    <div className={cx('tab-nav-metrics-container-1')}>
      <div className={cx('tab-nav-metrics-container-2')}>
        <div className={cx('tab-nav-metrics-data')}>
          {renderIcon(iconType(performanceType))}
          <div className={cx('tab-nav-metrics')}>
            <div
              className={cx('tab-nav-metric-title')}
              data-testid="tab-nav-title"
            >
              {performanceTitle}
            </div>
            <div className={cx('tab-nav-metrics-value')}>{renderValue}</div>
          </div>
        </div>

        <div className={cx('tab-nav-metrics-trend')}>{renderTrend}</div>
      </div>
      {disabledForCash && disabledMask()}
    </div>
  )
}

TabNavMetrics.propTypes = {
  permittedToView: PropTypes.bool.isRequired,
  disabledForCash: PropTypes.bool.isRequired,
  performanceType: PropTypes.string.isRequired,
  performanceTitle: PropTypes.string.isRequired,
  metricType: PropTypes.string.isRequired,
  trend: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  comparativeValue: PropTypes.number.isRequired,
}

export { TabNavMetrics }
