import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WidgetErrorIcon } from 'images/common/widget-error.svg'
import Button from 'react-bootstrap/Button'
import { refreshPage } from 'utils/helpers'
import classNames from 'classnames/bind'
import styles from './WidgetFallback.module.scss'

const cx = classNames.bind(styles)

const WidgetFallback = ({ title, customClass, isPage }) => {
  const { t } = useTranslation()

  const errorMessage = isPage
    ? t('widgets.widgetFallback.errorMessagePage')
    : t('widgets.widgetFallback.errorMessage')

  return (
    <div className={cx('container', customClass)} data-testid="widget-fallback">
      <h2>{title}</h2>
      <div>
        <WidgetErrorIcon height={42} width={42} />
        <h3>{t('widgets.widgetFallback.whoops')}</h3>
        <p>{errorMessage}</p>
        <Button onClick={refreshPage}>
          {t('widgets.widgetFallback.reload')}
        </Button>
      </div>
    </div>
  )
}

WidgetFallback.propTypes = {
  title: PropTypes.string,
  customClass: PropTypes.string,
  isPage: PropTypes.bool,
}

WidgetFallback.defaultProps = {
  title: null,
  customClass: null,
  isPage: false,
}

export { WidgetFallback }
