import classNames from 'classnames/bind'
import { TOTALS_METRIC_ID } from 'components/stations-list/utils/constants'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { PolePriceRow } from './components/pole-price-row'
import styles from './PolePriceRows.module.scss'

const cx = classNames.bind(styles)

const PolePriceRows = memo(({ items, stations, fuelGrades }) => {
  const totalMetrics = items.find(({ id }) => id === TOTALS_METRIC_ID)?.metrics

  const polePriceRows = items.map(({ id, pricing }, index) => {
    const station = stations.find(station => station.id === id)

    if (!station || !pricing) {
      return null
    }

    return (
      <PolePriceRow
        key={id}
        isFirstRow={index === 0}
        isFiltered={!!totalMetrics}
        stationName={station.name}
        fuelGrades={fuelGrades}
        pricing={pricing}
      />
    )
  })

  if (!totalMetrics) {
    return polePriceRows
  }

  return (
    <>
      <div className={cx('total-row')} />
      {polePriceRows}
    </>
  )
})

PolePriceRows.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { PolePriceRows }
