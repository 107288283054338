import React, { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { UserStillActiveCheckModal } from './components/user-still-active-check-modal'

const timeout = !process.env.REACT_APP_INACTIVITY_LOGOUT_TIME
  ? null
  : parseInt(process.env.REACT_APP_INACTIVITY_LOGOUT_TIME)

const InactivityLogout = ({ children }) => {
  const [idle, isIdle] = useState()

  const handleOnIdle = () => {
    isIdle(true)
  }

  const options = timeout
    ? {
        timeout,
        onIdle: handleOnIdle,
        debounce: 500,
        crossTab: {
          emitOnAllTabs: true,
        },
      }
    : {}

  useIdleTimer(options)

  return (
    <>
      {children}
      <UserStillActiveCheckModal active={idle} />
    </>
  )
}

export { InactivityLogout }
