import React from 'react'
import classNames from 'classnames/bind'
import styles from './SliderArrow.module.scss'
import { PropTypes } from 'prop-types'
import PreviousActive from 'images/previous-active.svg'
import PreviousInactive from 'images/previous-inactive.svg'
import NextActive from 'images/next-active.svg'
import NextInactive from 'images/next-inactive.svg'

const cx = classNames.bind(styles)

const getArrowIcon = (direction, isActive) => {
  switch (direction) {
    case 'right':
      return isActive ? NextActive : NextInactive
    case 'left':
      return isActive ? PreviousActive : PreviousInactive
    default:
      return 'right'
  }
}

const SliderArrow = ({ direction, isActive, handleClick, style }) => {
  const arrowIcon = getArrowIcon(direction, isActive)

  return (
    <div className={cx(style)}>
      <img
        alt={`slider-arrow-icon-${arrowIcon}`}
        onClick={handleClick}
        className={cx('slider-arrow-icon')}
        src={arrowIcon}
        draggable={false}
        data-testid={`slider-arrow-icon-${direction}`}
      />
    </div>
  )
}

SliderArrow.defaultProps = { style: '' }

SliderArrow.propTypes = {
  direction: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  style: PropTypes.string,
}

export { SliderArrow }
