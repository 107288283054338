import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import Tooltip from 'react-bootstrap/Tooltip'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import styles from './WetstockMetric.module.scss'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { MetricTypes } from 'utils/constants'
import { calendarDaysDifference } from 'utils/dateTime'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'

const cx = classNames.bind(styles)
const { MARGIN, COST, REPLACEMENT_COST } = MetricTypes

const WetstockMetric = ({
  title,
  value,
  type,
  replacementCostTimestamp,
  replacementCostError,
}) => {
  const { formatMetricValue } = useFormatMetricValue()
  const {
    metricPermissions: { readMargin },
  } = usePermittedAccess()
  const { t } = useTranslation()

  const renderReplacementCost = () => {
    const replacementCost = formatMetricValue(COST, value, false)

    const daysAgo = calendarDaysDifference(
      moment().utc(),
      moment.utc(replacementCostTimestamp)
    )

    const overlay = (
      <Tooltip className={cx('info-icon-tooltip')}>
        <div>
          {t('widgets.wetstockAnalysis.lastCostPriceTooltip')}
          <span className={cx('days-ago')}>
            {t('widgets.wetstockAnalysis.daysAgo', {
              daysAgo,
            })}
          </span>
        </div>
      </Tooltip>
    )

    const replacementCostWithWarning = (
      <div>
        {replacementCost}{' '}
        {replacementCostError && (
          <OverlayTrigger placement="top" overlay={overlay}>
            <span role="img" aria-label="warning">
              ⚠️
            </span>
          </OverlayTrigger>
        )}
      </div>
    )

    return replacementCostError ? replacementCostWithWarning : replacementCost
  }

  const renderMargin = (
    <Permissioned permission={readMargin} fallback={<Placeholder />}>
      {formatMetricValue(MARGIN, value, false)}
    </Permissioned>
  )

  const renderMetric = () => {
    switch (type) {
      case MARGIN:
        return renderMargin
      case REPLACEMENT_COST:
        return renderReplacementCost()
      default:
        return formatMetricValue(COST, value, false)
    }
  }

  return (
    <div className={cx('wetstock-metric-container')}>
      <div className={cx('wetstock-metric-title')}>{title}</div>
      <div className={cx('wetstock-metric-value')}>{renderMetric()}</div>
    </div>
  )
}

WetstockMetric.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  type: PropTypes.string.isRequired,
  replacementCostTimestamp: PropTypes.string,
  replacementCostError: PropTypes.string,
}

export { WetstockMetric }
