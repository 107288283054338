import React, { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import i18n from 'i18next'
import { useLazyQuery } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import { useLocation } from 'react-router-dom'
import { Locales, MetricTypes } from 'utils/constants'
import { getComparativePeriod } from 'utils/helpers'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { ReactComponent as GrossMarginIcon } from 'images/company-performance/gross-margin.svg'
import { ReactComponent as NetMarginIcon } from 'images/company-performance/net-margin.svg'
import { ReactComponent as ProfitPoundIcon } from 'images/company-performance/profit-pound.svg'
import { ReactComponent as ProfitEuroIcon } from 'images/company-performance/profit-euro.svg'
import { ReactComponent as ProfitDollarIcon } from 'images/company-performance/profit-dollar.svg'
import { ReactComponent as VolumeIcon } from 'images/company-performance/volume.svg'
import { COMPANY_PERFORMANCE_QUERY } from './CompanyPerformanceQueries'
import { CompanyPerformanceMetric } from './components/company-performance-metric'
import { CompanyPerformanceFallback } from './components/company-performance-fallback'
import styles from './CompanyPerformance.module.scss'

const cx = classNames.bind(styles)

const determineProfitIcon = () => {
  switch (i18n.language) {
    case Locales.EN_GB:
      return ProfitPoundIcon
    case Locales.EN_IE:
      return ProfitEuroIcon
    case Locales.EN_US:
      return ProfitDollarIcon
    /* istanbul ignore next */
    default:
      throw Error(`unsupported locale: ${i18n.language}`)
  }
}

const getCompanyPerformanceMetrics = (
  { readMargin, readProfit, readVolume },
  t
) => {
  return [
    {
      title: t('widgets.companyPerformance.totalVolume'),
      name: 'totalVolume',
      icon: VolumeIcon,
      type: MetricTypes.VOLUME,
      comparativePeriod: getComparativePeriod(MetricTypes.VOLUME),
      permitted: readVolume,
      tooltip: t('widgets.companyPerformance.totalVolumeTooltip'),
    },
    {
      title: t('widgets.companyPerformance.retailVolume'),
      name: 'retailVolume',
      icon: VolumeIcon,
      type: MetricTypes.VOLUME,
      comparativePeriod: getComparativePeriod(MetricTypes.VOLUME),
      permitted: readVolume,
      tooltip: t('widgets.companyPerformance.retailVolumeTooltip'),
    },
    {
      title: t('widgets.companyPerformance.grossMargin'),
      name: 'grossMargin',
      icon: GrossMarginIcon,
      type: MetricTypes.MARGIN,
      comparativePeriod: getComparativePeriod(MetricTypes.MARGIN),
      permitted: readMargin,
      tooltip: t('widgets.companyPerformance.grossMarginTooltip'),
    },
    {
      title: t('widgets.companyPerformance.netMargin'),
      name: 'netMargin',
      icon: NetMarginIcon,
      type: MetricTypes.MARGIN,
      comparativePeriod: getComparativePeriod(MetricTypes.MARGIN),
      permitted: readMargin,
      tooltip: t('widgets.companyPerformance.netMarginTooltip'),
    },
    {
      title: t('widgets.companyPerformance.grossProfit'),
      name: 'grossProfit',
      icon: determineProfitIcon(),
      type: MetricTypes.PROFIT,
      comparativePeriod: getComparativePeriod(MetricTypes.PROFIT),
      permitted: readProfit,
      tooltip: t('widgets.companyPerformance.grossProfitTooltip'),
    },
  ]
}

const determineVisibleMetrics = (showTotalVolume, metricPermissions, t) => {
  const [
    TOTAL_VOLUME,
    RETAIL_VOLUME,
    GROSS_MARGIN,
    NET_MARGIN,
    GROSS_PROFIT,
  ] = getCompanyPerformanceMetrics(metricPermissions, t)
  const visibleMetrics = [GROSS_MARGIN, NET_MARGIN, GROSS_PROFIT]
  visibleMetrics.unshift(showTotalVolume ? TOTAL_VOLUME : RETAIL_VOLUME)
  return visibleMetrics
}

const renderMetrics = (showTotalVolume, metrics, metricPermissions, t) => {
  const visibleMetrics = determineVisibleMetrics(
    showTotalVolume,
    metricPermissions,
    t
  )
  return visibleMetrics.map(metric => {
    const { name, permitted } = metric
    const metricData = metrics[name]
    return (
      <CompanyPerformanceMetric
        key={name}
        single={visibleMetrics.length === 1}
        metric={metric}
        metricData={metricData}
        permitted={permitted}
      />
    )
  })
}

const CompanyPerformance = () => {
  const { showTotalVolume } = useFlags()
  const location = useLocation()
  const { metricPermissions } = usePermittedAccess()
  const { selectedPricingType, supportedPricingTypes } = usePricingTypes()
  const { t } = useTranslation()
  const [loadCompanyPerformance, { called, data, error }] = useLazyQuery(
    COMPANY_PERFORMANCE_QUERY,
    {
      variables: {
        showTotalVolume,
        supportedPricingTypes,
      },
      fetchPolicy: 'cache-and-network',
    }
  )
  useErrorHandler(error)

  useEffect(() => {
    loadCompanyPerformance()
  }, [location.pathname, loadCompanyPerformance])

  if (!called || !data) {
    return (
      <div className={cx('company-performance', 'loading')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  }

  const {
    company: { metrics },
  } = data
  const selectedMetrics = metrics.find(
    ({ pricingType }) => pricingType === selectedPricingType
  )

  return (
    <div className={cx('company-performance')}>
      {renderMetrics(showTotalVolume, selectedMetrics, metricPermissions, t)}
    </div>
  )
}

CompanyPerformance.propTypes = {}

const FallbackComponent = () => <CompanyPerformanceFallback />

const CompanyPerformanceWithErrorBoundary = withEdgeErrorBoundary(
  CompanyPerformance,
  FallbackComponent
)

export { CompanyPerformanceWithErrorBoundary as CompanyPerformance }
