import React from 'react'
import { PropTypes } from 'prop-types'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import classNames from 'classnames/bind'
import styles from './AllStationsCheckbox.module.scss'

const cx = classNames.bind(styles)

const AllStationsCheckbox = ({
  allSelected,
  allStationIds,
  showPricingView,
}) => {
  if (!showPricingView) {
    return null
  }

  const handleClick = event => {
    event.stopPropagation()
    if (allSelected) {
      stationsListPricingOperations.deselectStations(allStationIds)
    } else {
      stationsListPricingOperations.selectStations(allStationIds)
    }
  }

  return (
    <div
      className={cx('all-stations-checkbox')}
      onClick={handleClick}
      data-testid="all-stations-checkbox"
    >
      {allSelected ? <ActiveIcon /> : <InactiveIcon />}
    </div>
  )
}

AllStationsCheckbox.propTypes = {
  allSelected: PropTypes.bool,
  allStationIds: PropTypes.arrayOf(PropTypes.string),
  showPricingView: PropTypes.bool.isRequired,
}

AllStationsCheckbox.defaultProps = {
  allSelected: false,
  allStationIds: null,
}

export { AllStationsCheckbox }
