import React from 'react'
import PropTypes from 'prop-types'
import { determinePriceStep } from 'components/station/components/competitor-pricing/utils/helpers'
import { usePriceChangeDispatch } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { PriceChangeActions } from 'components/station/components/competitor-pricing/context/constants'
import { ReactComponent as PlusIcon } from 'images/competitor-pricing/plus.svg'
import { ReactComponent as MinusIcon } from 'images/competitor-pricing/minus.svg'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import styles from './MatchPriceButtons.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const MatchPriceButtons = ({ selectedPricingType, fuelGradeId, polePrice }) => {
  const dispatch = usePriceChangeDispatch()

  if (isNullOrUndefined(polePrice)) {
    return null
  }

  const priceStep = determinePriceStep()
  const setPrice = newPrice => {
    dispatch({
      type: PriceChangeActions.SetPrice,
      payload: {
        fuelGradeId,
        pricingType: selectedPricingType,
        newPrice,
      },
    })
  }

  const handleMinusClick = () => {
    setPrice(polePrice.price - priceStep)
  }

  const handleMatchClick = () => {
    setPrice(polePrice.price)
    amplitudeEvent(UserEvents.CompetitorPricing.MATCH)
  }

  const handlePlusClick = () => {
    setPrice(polePrice.price + priceStep)
  }

  return (
    <div className={cx('match-buttons')}>
      <MinusIcon
        height={16}
        width={16}
        onClick={handleMinusClick}
        data-testid="minus-icon"
      />
      <span
        className={cx('match')}
        onClick={handleMatchClick}
        data-testid="match"
      >
        Match
      </span>
      <PlusIcon
        height={16}
        width={16}
        onClick={handlePlusClick}
        data-testid="plus-icon"
      />
    </div>
  )
}

MatchPriceButtons.propTypes = {
  selectedPricingType: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  polePrice: PropTypes.shape(),
}

MatchPriceButtons.defaultProps = {
  polePrice: null,
}

export { MatchPriceButtons }
