import React from 'react';
import { OverlayTrigger,  Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import styles from './DeliveryDetails.module.scss';
import moment from 'moment'
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

// Each fuel grade color is the first to ensure it appears in the most recent delivery.
// Only the Diesel fuel grade uses the second color in sequence and it appears in the middle delivery.
// This is because Diesel's primary color is quite dark, and finding two additional distinctly darker 
// shades was not feasible.
const fuelColours = [
  // UK
  ['#39c258', '#047c2a', '#164b22'], // Unleaded
  ['#64827d', '#263836', '#120d0d'], // Diesel
  ['#008d4d', '#144835', '#052e22'], // Unleaded Super, 87 Blended, 100, 110
  ['#006c8a', '#063746', '#030607'], // Diesel Super, 89 Blended, 89
  // US
  ['#608580', '#2a3836', '#120d0d'], // Diesel, R99
  ['#2dc24c', '#0c8326', '#174a21'], // 85, 97, E85, 92, 96, Unleaded
  ['#0F9D58', '#1b6441', '#09341c'], // 87
  ['#DB4437', '#94160a', '#481c19'], // 91
  ['#4969E1', '#1c3a9b', '#192d57'], // 90
  ['#9c9c9c', '#6a6868', '#313030'], // Bio-Diesel B20, Dyed Diesel, B5 Diesel
  ['#0f9d58', '#13634b', '#052e23'], // Conventional 87
  ['#1ecc0e', '#18860e', '#13500c'], // E10
];

const DeliveryDetails = ({ level, colour, style, deliveriesCount, details, locale }) => {
  const { t } = useTranslation()

  if (!details) {
    return null
  }

  const paletteIndex = fuelColours.findIndex(palette => palette.includes(colour))
  const selectedPalette = fuelColours[paletteIndex] || ['#cccccc', '#bbbbbb', '#aaaaaa']
  const backgroundColor = selectedPalette[deliveriesCount - level] || '#000000'
  const isFallback = backgroundColor === '#000000'
  const primaryBorderColor = selectedPalette[0]

  const overlay = (
    <Tooltip className={cx('tooltip')}>
      <div className={cx('info-rows')}>
        <div className={cx('info-row')}>
           <div className={cx('info-item', 'left')}>
            <div className={cx('title')}>{t('widgets.wetstockAnalysis.deliveryDate')}</div>
            <div className={cx('value')}>{moment.utc(details.date).format('ll') }</div>
          </div>
          <div className={cx('info-item', 'right')}>
            <div className={cx('title')}>{t('widgets.wetstockAnalysis.unitPrice')}</div>
            <div className={cx('value')}>
              {Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(details.unitPrice)}
              {t('margin.short')}
            </div>

          </div>
        </div>
        <hr/>

        <div className={cx('info-row')}>
          <div className={cx('info-item', 'left')}>
            <div className={cx('title')}>{t('widgets.wetstockAnalysis.deliveredVolume')}</div>
            <div className={cx('value')}>{Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(details.deliveredVolume)} {t('volume.short')}</div>
          </div>
          <div className={cx('info-item', 'right')}>
            <div className={cx('title')}>{t('widgets.wetstockAnalysis.costOfDelivery')}</div>
            <div className={cx('value')}>
              {t('currency.short')}
              {Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(details.costOfDelivery / 100)}
              </div>
          </div>
        </div>
        <div className={cx('info-row')}>
          <div className={cx('info-item', 'left')}>
            <div className={cx('title')}>{t('widgets.wetstockAnalysis.remainingVolume')}</div>
            <div className={cx('value')}>{Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(details.remainingVolume)} {t('volume.short')}</div>
          </div>
          <div className={cx('info-item', 'right')}>
            <div className={cx('title')}>{t('widgets.wetstockAnalysis.remainingValue')}</div>
            <div className={cx('value')}>
              {t('currency.short')}
              {Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(details.remainingValue / 100)}
              </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={overlay}>
      <div
        style={{
          ...style,
          backgroundColor,
          ...(isFallback ? { borderTop: `1px solid ${primaryBorderColor}` } : {}),
        }}
      >
      </div>
    </OverlayTrigger>
  );
};

DeliveryDetails.propTypes = {
  colour: PropTypes.string,
  deliveriesCount: PropTypes.number,
  level: PropTypes.number,
  style: PropTypes.object,
  details: PropTypes.object,
  locale: PropTypes.string,
}

export { DeliveryDetails };
