import PropTypes from 'prop-types'
import { ActionAtPeriods } from 'utils/pricing/constants'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { Calendar } from 'components/common/calendar'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import classNames from 'classnames/bind'
import styles from './ActionAtCalendar.module.scss'

const cx = classNames.bind(styles)

const ActionAtCalendar = ({ period, value, onValueChange }) => {
  const { companyTimeZone } = useCompanyTimeZone()

  const showCalendar = period === ActionAtPeriods.Custom
  if (!showCalendar) {
    return null
  }

  const minDate = moment.utc()
  const maxDate = moment.utc().add(14, 'days')

  return (
    <div className={cx('calendar')}>
      <Calendar
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onValueChange={onValueChange}
        multiSelect={false}
        timeZone={companyTimeZone}
      />
    </div>
  )
}

ActionAtCalendar.propTypes = {
  period: PropTypes.number.isRequired,
  value: PropTypes.instanceOf(moment),
  onValueChange: PropTypes.func.isRequired,
}

ActionAtCalendar.defaultProps = {}

export { ActionAtCalendar }
