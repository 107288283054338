import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import styles from './Placeholder.module.scss'

const cx = classNames.bind(styles)

const Placeholder = ({ widthPercentage, fixedWidth, dataTestID }) => {
  const width = fixedWidth ? `${fixedWidth}px` : `${widthPercentage}%`

  return (
    <div className={cx('placeholder-container')}>
      {dataTestID ? (
        <div
          className={cx('placeholder')}
          style={{ width }}
          data-testid={`${dataTestID}`}
        ></div>
      ) : (
        <div className={cx('placeholder')} style={{ width }}></div>
      )}
    </div>
  )
}

Placeholder.propTypes = {
  widthPercentage: PropTypes.number,
  fixedWidth: PropTypes.number,
  dataTestID: PropTypes.string,
}

Placeholder.defaultProps = {
  widthPercentage: 50,
  fixedWidth: null,
  dataTestID: null,
}

export { Placeholder }
