import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'
import { HoverablePopover } from 'components/common/hoverable-popover'
import classNames from 'classnames/bind'
import styles from './InvalidStationPopover.module.scss'

const cx = classNames.bind(styles)

const InvalidStationPopover = ({ children }) => {
  const { showNewMessages } = useIntercom()
  const { t } = useTranslation()

  const handleClick = () => {
    showNewMessages()
  }

  const popoverContent = (
    <div id="invalid-station-popover" className={cx('invalid-station-popover')}>
      <div className={cx('content')}>
        <div>
          {t('widgets.stationsListPricing.noStationManagerAssignedToolTip')}
        </div>
        <div onClick={handleClick}>
          {t('widgets.stationsListPricing.contactSupport')}
        </div>
      </div>
    </div>
  )

  return (
    <HoverablePopover
      popoverContent={popoverContent}
      delay={200}
      placement="right"
      customClass={cx('popover')}
    >
      {children}
    </HoverablePopover>
  )
}

InvalidStationPopover.propTypes = {
  children: PropTypes.shape().isRequired,
}

export { InvalidStationPopover }
