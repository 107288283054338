import upperFirst from 'lodash/upperFirst'
import { MetricTypes } from './constants'

const upperFirstOnly = value => upperFirst(value.toLowerCase())

const getMetricTypeFromField = field => {
  switch (field) {
    case 'totalVolume':
    case 'fuelCardVolume':
    case 'bunkeredVolume':
    case 'retailVolume':
    case 'bunkeredFuelVolumeSales':
    case 'totalFuelVolumeSales':
    case 'retailFuelVolumeSalesForComputingMargin':
    case 'consumerFuelVolumeSales':
    case 'fuelCardFuelVolumeSales':
      return MetricTypes.VOLUME
    case 'allMargin':
    case 'grossMargin':
    case 'netMargin':
    case 'netMarginAll':
      return MetricTypes.MARGIN
    case 'allProfit':
    case 'grossProfit':
    case 'netProfit':
    case 'businessProfit':
    case 'retailGrossSalesExVatForComputingMargin':
    case 'totalFees':
    case 'retailBlendedCost':
      return MetricTypes.PROFIT
    case 'numRetailTransactions':
      return MetricTypes.NUMBER
    case 'costPrice':
      return MetricTypes.COST
    case 'bunkeringCommissions':
      return MetricTypes.BUNKER_COMMISSION
    case 'avgRetailFillUp':
      return MetricTypes.FILL_UP
    case 'polePrice':
      return MetricTypes.POLE_PRICE
    /* istanbul ignore next */
    default:
      throw new Error(`Unknown field name ${field}`)
  }
}

const yAxisLabel = (metricName) => {
  const labelMap = {
    total_fuel_volume_sales: 'total_volume',
    retail_fuel_volume_sales_for_computing_margin: 'retail_volume',
    bunkered_fuel_volume_sales: 'bunkered_volume',
    consumer_fuel_volume_sales: 'consumer_volume',
    fuel_card_fuel_volume_sales: 'fuel_card_volume',
    gross_margin: 'gross_margin',
    net_margin: 'net_margin',
    retail_gross_sales_ex_vat_for_computing_margin: 'gross_sales',
    gross_profit: 'gross_profit',
    net_profit: 'net_profit',
    business_profit: 'business_profit',
    bunkering_commissions: 'bunkering_commissions',
    cost_price: 'replacement_cost',
    total_fees: 'total_fees',
    retail_blended_cost: 'blended_cost_sold',
    num_retail_transactions: 'retail_transactions',
    avg_retail_fill_up: 'retail_fill_up',
    pole_price: 'pole_price',
  };

  return labelMap[metricName] || '';
};

const typenameTransformer = (key, value) => {
  return key === '__typename' ? undefined : value
}

const removeTypename = data => {
  const json = JSON.stringify(data)
  return JSON.parse(json, typenameTransformer)
}

const addTypenames = (data, typename) => {
  return data.map(entry => ({
    __typename: typename,
    ...entry,
  }))
}

export { upperFirstOnly, getMetricTypeFromField, removeTypename, addTypenames, yAxisLabel }
