import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { PrivateRoute } from 'components/common/private-route'
import { AppErrorBoundaryWithRouter as AppErrorBoundary } from './AppErrorBoundary'
import { Main } from './main'
import {
  Login,
  ForgotPassword,
  ResetPassword,
  SetPassword,
  TermsOfService,
  NotificationUserThankYou,
} from './auth'
import { useAuth } from 'config/context/authContext'
import { HeadTitle } from 'components/head-title'

/* istanbul ignore next */
const clientSideID =
  process.env.REACT_APP_LAUNCH_DARKLY_ENABLED === 'true'
    ? process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID
    : null

export const App = () => {
  const { isNotificationUser, isEdgeDataProUser } = useAuth()

  return (
    <AppErrorBoundary>
      <HeadTitle isEdgeDataProUser={isEdgeDataProUser} >
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>
          <PrivateRoute exact path="/set-password" requiresTermsOfService>
            <SetPassword />
          </PrivateRoute>
          <PrivateRoute exact path="/terms-of-service">
            <TermsOfService />
          </PrivateRoute>

          {!isNotificationUser && (
            <PrivateRoute path="/" requiresTermsOfService requiresPassword>
              <Main />
            </PrivateRoute>
          )}
          {isNotificationUser && (
            <PrivateRoute
              path="/"
              requiresTermsOfService
              requiresHasFinishedAccountSetUp
            >
              <NotificationUserThankYou />
            </PrivateRoute>
          )}
        </Switch>
      </HeadTitle>
    </AppErrorBoundary>
  )
}

const AppWithLDProvider = withLDProvider({
  clientSideID,
  user: { key: 'UNAUTHENTICATED', name: 'unauthenticated-user' },
  deferInitialization: true,
  options: {
    hash: process.env.REACT_APP_LAUNCH_DARKLY_UNAUTHENTICATED_HASH,
    // when unable to connect to LaunchDarkly, bootstrap options are the defaults
    // NOTE: these values should not be committed if changed during development
    bootstrap: {
      'show-total-volume': false,
      'show-stations-list-pricing': false,
      'show-performance-export': false,
      'stations-list-individual-pricing': false,
      'stations-list-estimated-margin': false,
      'show-station-navigation': false,
    },
  },
})(App)

export { AppWithLDProvider }
