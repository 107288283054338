import {
  usePermissions,
  PermissionTypes,
} from 'components/common/hooks/usePermissions'
import {
  WidgetPermissions,
  FeaturePermissions,
  MetricPermissions,
} from 'utils/constants'

const { UPDATE } = PermissionTypes

const {
  STATIONS_LIST_PERMISSION,
  STATION_PERFORMANCE_PERMISSION,
  COMPETITOR_PRICING_PERMISSION,
  DELIVERY_PERMISSION,
  WETSTOCK_PERMISSION,
  BUYSMART_PERMISSION,
  PERFORMANCE_REPORTING_SINGLE_STATION_PERMISSION,
  PERFORMANCE_REPORTING_PORTFOLIO_PERMISSION,
  ANALYTICS
} = WidgetPermissions

const {
  TARGET_MARGIN_PERMISSION,
  PRICING_STRATEGY_PERMISSION,
  EDIT_PRICING_PERMISSION,
} = FeaturePermissions

const {
  MARGIN_PERMISSION,
  PROFIT_PERMISSION,
  VOLUME_PERMISSION,
} = MetricPermissions

function usePermittedAccess() {
  const { hasPermission } = usePermissions()

  const widgetPermissions = {
    // read
    viewStationPerformance: hasPermission(STATION_PERFORMANCE_PERMISSION),
    viewCompetitorPricing: hasPermission(COMPETITOR_PRICING_PERMISSION),
    viewDelivery: hasPermission(DELIVERY_PERMISSION),
    viewWetstock: hasPermission(WETSTOCK_PERMISSION),
    viewBuysmart: hasPermission(BUYSMART_PERMISSION),
    viewPerformanceReportingSingleStation: hasPermission(
      PERFORMANCE_REPORTING_SINGLE_STATION_PERMISSION
    ),
    viewPerformanceReportingPortfolio: hasPermission(
      PERFORMANCE_REPORTING_PORTFOLIO_PERMISSION
    ),
    viewAnalytics: hasPermission(ANALYTICS),

    // update
    updateStationsList: hasPermission(STATIONS_LIST_PERMISSION, [UPDATE]),
    updateCompetitorPricing: hasPermission(COMPETITOR_PRICING_PERMISSION, [
      UPDATE,
    ]),
  }

  const featurePermissions = {
    // target margin
    readTargetMargin: hasPermission(TARGET_MARGIN_PERMISSION),
    updateTargetMargin: hasPermission(TARGET_MARGIN_PERMISSION, [UPDATE]),

    // pricing strategy
    readPricingStrategy: hasPermission(PRICING_STRATEGY_PERMISSION),
    updatePricingStrategy: hasPermission(PRICING_STRATEGY_PERMISSION, [UPDATE]),

    // edit pricing
    updateEditPricing: hasPermission(EDIT_PRICING_PERMISSION, [UPDATE]),
  }

  const metricPermissions = {
    readMargin: hasPermission(MARGIN_PERMISSION),
    readProfit: hasPermission(PROFIT_PERMISSION),
    readVolume: hasPermission(VOLUME_PERMISSION),
  }

  return { widgetPermissions, featurePermissions, metricPermissions }
}

export { usePermittedAccess }
