import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PencilEditIcon } from 'images/stations-list/edit-pricing/pencil-edit.svg'
import classNames from 'classnames/bind'
import styles from './EditPriceButton.module.scss'

const cx = classNames.bind(styles)

const EditPricesButton = ({ inactive }) => {
  const { t } = useTranslation()

  return (
    <div className={cx('edit-pricing-button', { inactive })}>
      <PencilEditIcon width={15} height={15} />
      {t('widgets.stationsListPricing.editPrices')}
    </div>
  )
}

EditPricesButton.propTypes = {
  inactive: PropTypes.bool,
}

EditPricesButton.defaultProps = {
  inactive: false,
}

export { EditPricesButton }
