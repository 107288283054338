import React from 'react'
import { PriceChangeTooltip } from 'components/common/price-change-tooltip'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Price.module.scss'

const cx = classNames.bind(styles)

const Price = ({ polePriceChangeRequest, polePrice, hasPriceChange }) => {
  const { formatPolePrice } = useFormatPolePrice()

  if (polePriceChangeRequest && !hasPriceChange) {
    return (
      <PriceChangeTooltip polePriceChangeRequest={polePriceChangeRequest}>
        <div className={cx('with-pending-request')}>
          <div className={cx('current-price')}>
            {formatPolePrice(polePrice?.price)}
          </div>
          <div className={cx('pending-request')}>
            {formatPolePrice(polePriceChangeRequest.newPrice)}
          </div>
        </div>
      </PriceChangeTooltip>
    )
  }

  return <div className={cx('price')}>{formatPolePrice(polePrice?.price)}</div>
}

Price.propTypes = {
  polePriceChangeRequest: PropTypes.shape(),
  polePrice: PropTypes.shape(),
  hasPriceChange: PropTypes.bool,
}

export { Price }
