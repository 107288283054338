import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import styles from './ManualInput.module.scss'
import { Locales, USPriceHasFractionRegex } from 'utils/constants'
import { PriceChangeActions } from 'components/station/components/competitor-pricing/context/constants'
import { ManualInputValueRegex } from 'components/station/components/competitor-pricing/utils/constants'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'

const cx = classNames.bind(styles)

const polePriceInputValidator = (price, value) => {
  const currentPrice = Number(price)
  const inputValue = Number(value)
  const priceChangeLimitPercentage = 0.5 * currentPrice

  const isWithinMinMaxPriceChange =
    inputValue < currentPrice + priceChangeLimitPercentage &&
    inputValue > currentPrice - priceChangeLimitPercentage

  const validValue =
    i18n.language === Locales.EN_GB || i18n.language === Locales.EN_IE
      ? ManualInputValueRegex.GB.test(value)
      : ManualInputValueRegex.US.test(value)

  return validValue && isWithinMinMaxPriceChange
}

const ManualInput = ({
  newPrice,
  price,
  fuelGradeId,
  pricingType,
  enabled,
  setEnabled,
  inputRef,
  dispatch,
}) => {
  const [manualInputMode, setManualInputMode] = useState(false)
  const [validInput, setValidInput] = useState(true)
  const { formatPolePrice } = useFormatPolePrice()
  const { t } = useTranslation()
  const [value, setValue] = useState()
  const [manualInput, setManualInput] = useState(price)

  const formattedPrice = formatPolePrice(price)
  const formattedNewPrice = formatPolePrice(newPrice)

  useEffect(() => {
    if (manualInputMode) {
      inputRef.current.focus()
    }
  }, [inputRef, manualInputMode])

  useEffect(() => {
    if (newPrice) {
      setValue(formattedNewPrice)
      setValidInput(true)
      return setManualInput(newPrice)
    }
    setManualInput(price)
  }, [price, newPrice, setManualInput])

  const setPrice = input => {
    let inputValue = parseFloat(input)

    if (i18n.language === Locales.EN_US) {
      if (USPriceHasFractionRegex.test(price)) {
        inputValue = (inputValue * 100 + 0.9).toFixed(1)
      } else {
        inputValue = inputValue * 100
      }
    }

    dispatch({
      type: PriceChangeActions.SetPrice,
      payload: {
        fuelGradeId,
        pricingType,
        newPrice: parseFloat(inputValue),
      },
    })
  }

  const setInputValue = () => {
    if (enabled && value !== undefined) return value
    return formattedNewPrice || formattedPrice
  }

  const handleManualInputChange = e => {
    const {
      target: { value },
    } = e

    setValue(value)
    setManualInput(value)
    if (polePriceInputValidator(formattedPrice, value)) {
      amplitudeEvent(UserEvents.EditPricing.MANUAL_INPUT_USED)
      setValidInput(true)
      setPrice(value)
    } else {
      setValidInput(false)
      setPrice(formattedPrice)
    }
  }

  const handleManualInputClick = () => {
    if (manualInputMode) {
      if (!enabled) {
        setEnabled(true)
      }
    } else {
      setEnabled(true)
      setManualInputMode(true)
    }
  }

  const overlay = (
    <Tooltip
      className={cx('manual-input-tooltip', validInput && 'hide')}
      data-testid="manual-input-tooltip"
    >
      {t('widgets.competitorPricing.manualInputInvalidTooltip')}
    </Tooltip>
  )

  const setValidInputClass = () => {
    if (enabled && manualInputMode && manualInput !== formattedPrice) {
      return validInput ? 'valid' : 'invalid'
    }
  }

  const setEnabledClass = enabled && manualInputMode

  return (
    <div className={cx('manual-input')}>
      <OverlayTrigger placement="top" overlay={overlay} delay={1000}>
        <input
          aria-label="manual-input"
          type="text"
          className={cx(
            'manual-input',
            { enabled: setEnabledClass },
            i18n.language === Locales.EN_US && 'us-prices',
            setValidInputClass()
          )}
          data-testid="manual-input"
          value={setInputValue()}
          placeholder={formattedPrice}
          onChange={handleManualInputChange}
          onClick={handleManualInputClick}
          ref={inputRef}
          key={fuelGradeId}
        />
      </OverlayTrigger>
    </div>
  )
}

ManualInput.propTypes = {
  newPrice: PropTypes.number,
  price: PropTypes.number.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  pricingType: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  setEnabled: PropTypes.func.isRequired,
  inputRef: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
}

ManualInput.defaultProps = {
  newPrice: null,
}

export { ManualInput }
