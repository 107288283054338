import i18n from 'i18next'
import {
  fuelGradePriceChangesVar,
  selectedStationIdsVar,
  stationPriceChangesVar,
} from 'config/apollo/stations-list-pricing/variables'
import { numberToFixed } from 'utils/number'
import { Locales } from 'utils/constants'

/**
 * When pricing stations we consider the current price either an existing pole price change request that is active or the current pole price.
 */
const findCurrentPrice = (polePrices, polePriceChangeRequests, fuelGradeId) => {
  const polePriceChangeRequest = polePriceChangeRequests.find(
    polePriceChangeRequest => polePriceChangeRequest.fuelGradeId === fuelGradeId
  )
  if (polePriceChangeRequest) {
    return polePriceChangeRequest.newPrice
  }

  const polePrice = polePrices.find(
    polePrice => polePrice.fuelGradeId === fuelGradeId
  )
  return polePrice.price
}

const calculateDifferentialPriceChange = (
  polePrices,
  polePriceChangeRequests,
  fuelGradeId,
  fuelGradeDifferential,
  leadGradePriceChange
) => {
  const currentPrice = findCurrentPrice(
    polePrices,
    polePriceChangeRequests,
    fuelGradeId
  )

  const currentLeadGradePrice = findCurrentPrice(
    polePrices,
    polePriceChangeRequests,
    fuelGradeDifferential.leadGradeId
  )
  const requiredPrice =
    currentLeadGradePrice +
    leadGradePriceChange.priceChange +
    fuelGradeDifferential.differential

  // non US price changes can have a fractional part
  const digits = i18n.language === Locales.EN_US ? 0 : 1
  return numberToFixed(requiredPrice - currentPrice, digits)
}

/**
 * Maps a fuel grade to a price change.
 *
 * Fuel grades are either priced:
 * - via a grade differential (where this fuel grade is not the lead grade)
 * - individually via station price changes
 * - globally via fuel grade price changes
 *
 * This is in order of preference. Where a differential is in place neither individual or global price changes are considered.
 */
const mapFuelGradeToPriceChange = (
  fuelGradeId,
  stationId,
  stationPriceChanges,
  fuelGradePriceChanges,
  fuelGradeDifferentials,
  polePrices,
  polePriceChangeRequests
) => {
  const fuelGradeDifferential = fuelGradeDifferentials.find(
    differential => differential.fuelGradeId === fuelGradeId
  )

  // station is priced individually
  if (stationPriceChanges) {
    const stationPriceChange = stationPriceChanges.find(
      stationPriceChange => stationPriceChange.fuelGradeId === fuelGradeId
    )
    if (fuelGradeDifferential && !fuelGradeDifferential.leadGrade) {
      const leadGradePriceChange = stationPriceChanges.find(
        stationPriceChange =>
          stationPriceChange.fuelGradeId ===
            fuelGradeDifferential.leadGradeId &&
          stationPriceChange.stationId === stationId &&
          stationPriceChange.priceChange !== 0
      )

      if (leadGradePriceChange) {
        const priceChange = calculateDifferentialPriceChange(
          polePrices,
          polePriceChangeRequests,
          fuelGradeId,
          fuelGradeDifferential,
          leadGradePriceChange
        )
        return {
          stationId,
          fuelGradeId,
          priceChange,
        }
      }
      return {
        stationId,
        fuelGradeId,
        priceChange: 0,
      }
    }

    return stationPriceChange
  }

  // station is priced off global fuel grade price changes
  const gradePriceChange = fuelGradePriceChanges.find(
    fuelGradePriceChange => fuelGradePriceChange.fuelGradeId === fuelGradeId
  )
  if (fuelGradeDifferential && !fuelGradeDifferential.leadGrade) {
    const leadGradePriceChange = fuelGradePriceChanges.find(
      fuelGradePriceChange =>
        fuelGradePriceChange.fuelGradeId ===
          fuelGradeDifferential.leadGradeId &&
        fuelGradePriceChange.priceChange !== 0
    )

    if (leadGradePriceChange) {
      const priceChange = calculateDifferentialPriceChange(
        polePrices,
        polePriceChangeRequests,
        fuelGradeId,
        fuelGradeDifferential,
        leadGradePriceChange
      )
      return {
        fuelGradeId,
        priceChange,
      }
    }

    return {
      fuelGradeId,
      priceChange: 0,
    }
  }

  return gradePriceChange
}

const typePolicy = {
  keyFields: ['stationId'],
  fields: {
    /**
     * @returns if the station is selected for price changes (either at the station or fuel grade level).
     */
    isSelected(_, { readField }) {
      const stationId = readField('stationId')
      return selectedStationIdsVar().includes(stationId)
    },
    /**
     * @returns true if the station is selected and has at least one station price change.
     */
    hasStationPrice(_, { readField }) {
      const isSelected = readField('isSelected')
      if (!isSelected) {
        return false
      }

      const stationId = readField('stationId')
      const stationPriceChanges = stationPriceChangesVar()

      return !!stationPriceChanges[stationId]
    },
    /**
     * @returns the list of pending price changes for the current station
     */
    priceChanges(_, { readField }) {
      const isSelected = readField('isSelected')
      if (!isSelected) {
        return []
      }

      const stationId = readField('stationId')
      const stationPriceChangesValue = stationPriceChangesVar()
      const stationPriceChanges = stationPriceChangesValue[stationId]

      const fuelGradePriceChanges = fuelGradePriceChangesVar()
      const polePrices = readField('polePrices').map(polePriceRef => {
        return {
          fuelGradeId: readField('fuelGradeId', polePriceRef),
          price: readField('price', polePriceRef),
        }
      })
      const polePriceChangeRequests = readField('polePriceChangeRequests').map(
        polePriceRef => {
          return {
            fuelGradeId: readField('fuelGradeId', polePriceRef),
            newPrice: readField('newPrice', polePriceRef),
          }
        }
      )
      const stationFuelGradeIds = polePrices.map(
        ({ fuelGradeId }) => fuelGradeId
      )

      const priceDifferentials = readField('priceDifferentials')
      const fuelGradeDifferentials = priceDifferentials.fuelGradeDifferentials.map(
        differentialRef => {
          return {
            fuelGradeId: readField('fuelGradeId', differentialRef),
            leadGrade: readField('leadGrade', differentialRef),
            leadGradeId: readField('leadGradeId', differentialRef),
            differential: readField('differential', differentialRef),
          }
        }
      )

      return stationFuelGradeIds.map(fuelGradeId =>
        mapFuelGradeToPriceChange(
          fuelGradeId,
          stationId,
          stationPriceChanges,
          fuelGradePriceChanges,
          fuelGradeDifferentials,
          polePrices,
          polePriceChangeRequests
        )
      )
    },
  },
}

export { typePolicy }
