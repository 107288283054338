import { numberToFixed } from 'utils/number'
import { fuelGradePriceChangesVar } from '../variables'

const incrementFuelGradePriceChange = fuelGradeId => {
  const fuelGradePriceChanges = fuelGradePriceChangesVar().map(
    fuelGradePriceChange => {
      if (fuelGradePriceChange.fuelGradeId === fuelGradeId) {
        return {
          fuelGradeId,
          priceChange: numberToFixed(fuelGradePriceChange.priceChange + 1),
        }
      }

      return fuelGradePriceChange
    }
  )
  fuelGradePriceChangesVar(fuelGradePriceChanges)
}

const decrementFuelGradePriceChange = fuelGradeId => {
  const fuelGradePriceChanges = fuelGradePriceChangesVar().map(
    fuelGradePriceChange => {
      if (fuelGradePriceChange.fuelGradeId === fuelGradeId) {
        return {
          fuelGradeId,
          priceChange: numberToFixed(fuelGradePriceChange.priceChange - 1),
        }
      }

      return fuelGradePriceChange
    }
  )
  fuelGradePriceChangesVar(fuelGradePriceChanges)
}

export { incrementFuelGradePriceChange, decrementFuelGradePriceChange }
