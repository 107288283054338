import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Toggle.module.scss'

const cx = classNames.bind(styles)

const Toggle = ({ id, checked, handleToggle, label, disabled }) => {
  return (
    <div className={cx('toggle-container', { disabled })}>
      <input
        className={cx('checkbox')}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={handleToggle}
        data-testid={id}
      />
      <label
        className={cx('toggle', {
          'toggle-selected': checked,
        })}
        htmlFor={id}
      >
        <span className={cx('toggle-button')} />
      </label>
      <span
        className={cx('label', {
          'label-selected': checked,
        })}
        onClick={handleToggle}
        data-testid="label"
      >
        {label}
      </span>
    </div>
  )
}

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

Toggle.defaultProps = {
  disabled: false,
}

export { Toggle }
