import { ApolloClient } from '@apollo/client'
import { link } from './link'
import { cache } from './cache'
import { typeDefs } from './typeDefs'
import { resolvers } from './resolvers'

const client = new ApolloClient({
  link,
  cache,
  typeDefs,
  resolvers,
  assumeImmutableResults: true,
  credentials: 'include',
})

export { client }
