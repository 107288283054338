import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useTranslation } from 'react-i18next'
import { images, fallback } from 'images/image-library'
import { ReactComponent as MyStationIcon } from 'images/competitor-pricing/my-station.svg'
import { ImageAsset } from 'components/common/image-asset'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { stationCashCreditStatus } from 'utils/pricing'
import classNames from 'classnames/bind'
import styles from './MyStationCell.module.scss'

const cx = classNames.bind(styles)

const MyStationCell = ({ station }) => {
  const { cashCreditPricing } = usePricingTypes()
  const { name, brand, cashCreditPricing: stationCashCreditPricing } = station
  const { t } = useTranslation()

  const noCashCreditStation = stationCashCreditStatus(
    cashCreditPricing,
    stationCashCreditPricing
  )

  const myStationCell = (
    <div
      className={cx('my-station-cell', {
        'no-cash-credit': noCashCreditStation,
      })}
      data-testid="my-station-cell"
    >
      <ImageAsset
        src={images.brandImage(brand?.key)}
        width={50}
        height={50}
        alt={`brand-${brand?.key}`}
        fallbackImage={fallback.defaultBrand}
      />
      <span className={cx('station-info')}>
        <div>{t('widgets.competitorPricing.myStation')}</div>
        <div className={cx('station-name')}>{name}</div>
      </span>
      <MyStationIcon />
    </div>
  )

  if (noCashCreditStation) {
    const overlay = (
      <Tooltip className={cx('no-cash-credit-tooltip')}>
        <div>{t('widgets.competitorPricing.noCashDiscountTooltip')}</div>
      </Tooltip>
    )

    return (
      <OverlayTrigger placement="top" overlay={overlay}>
        {myStationCell}
      </OverlayTrigger>
    )
  }

  return myStationCell
}

MyStationCell.propTypes = {
  station: PropTypes.shape().isRequired,
}

export { MyStationCell }
