import { gql, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useShowToast } from 'components/common/hooks/useShowToast'

const CLEAR_NOTIFICATIONS_MUTATION = gql`
  mutation clearNotifications {
    clearNotifications {
      error
    }
  }
`

function useClearNotifications() {
  const { showErrorToast } = useShowToast()
  const [
    clearNotificationsMutation,
    { loading: clearNotificationsLoading },
  ] = useMutation(CLEAR_NOTIFICATIONS_MUTATION, {
    onCompleted({ clearNotifications }) {
      if (clearNotifications.error) {
        showErrorToast()
      }
    },
    onError(error) {
      Sentry.captureException(error)
      showErrorToast()
    },
  })

  return { clearNotificationsMutation, clearNotificationsLoading }
}

export { useClearNotifications, CLEAR_NOTIFICATIONS_MUTATION }
