import { useEffect } from 'react'

/**
 * Disables slider from tracking mouse events when react-bootstrap popover is in use
 */

function useClickablePopover(popoverRef, sliderDispatch) {
  useEffect(() => {
    const handleClick = event => {
      const ref = document.getElementById(popoverRef)

      if (
        event
          .composedPath()
          .flat()
          .includes(ref) ||
        !ref
      ) {
        return
      }
      sliderDispatch({
        type: 'enabled',
        payload: true,
      })
    }
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [popoverRef, sliderDispatch])
}

export { useClickablePopover }
