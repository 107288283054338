import React from 'react'
import PropTypes from 'prop-types'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import classNames from 'classnames/bind'
import styles from './ProgressBar.module.scss'

const cx = classNames.bind(styles)

const { SEND } = StationsListPricingStatus

const ProgressBar = ({ status }) => {
  if (status !== SEND) {
    return null
  }

  return (
    <div className={cx('progress')}>
      <div className={cx('bar')} />
    </div>
  )
}

ProgressBar.propTypes = {
  status: PropTypes.string.isRequired,
}

export { ProgressBar }
