import React, { useState, useEffect, useRef, useCallback } from 'react'
import classNames from 'classnames/bind'
import styles from './Slider.module.scss'
import { PropTypes } from 'prop-types'

const cx = classNames.bind(styles)

const Slider = ({
  children,
  leftClick,
  rightClick,
  singleElementWidth,
  additionalContainerWidth,
  elementCount,
  elementMargin,
  handleLeftArrowActive,
  handleRightArrowActive,
  showArrows,
  customClass,
  enabled,
}) => {
  const [
    {
      dragging,
      translate,
      sliderContainerWidth,
      moveable,
      prevTranslate,
      pastLeftStop,
      atLeftStop,
    },
    setState,
  ] = useState({
    moveLeft: 0,
    moveRight: 0,
    leftActive: false,
    rightActive: false,
    dragging: false,
    translate: 0,
    prevTranslate: 0,
    startXPosition: 0,
    sliderContainerWidth: 0,
    moveable: false,
    pastLeftStop: false,
    atLeftStop: false,
  })

  const sliderRef = useRef('sliderRef')

  const elementContainerWidth =
    (singleElementWidth + elementMargin) * elementCount +
    additionalContainerWidth

  // determine arrow states: active/visible
  useEffect(() => {
    const sliderContainerWidth = sliderRef.current.clientWidth
    const moveable = sliderContainerWidth < elementContainerWidth

    showArrows({ type: 'showSliderArrows', payload: moveable })

    handleLeftArrowActive({
      type: 'setLeftArrowActiveState',
      payload: moveable,
    })

    handleRightArrowActive({
      type: 'setRightArrowActiveState',
      payload: moveable,
    })

    setState(state => ({
      ...state,
      sliderContainerWidth: sliderContainerWidth,
      moveable,
    }))
  }, [
    elementContainerWidth,
    singleElementWidth,
    handleLeftArrowActive,
    handleRightArrowActive,
    showArrows,
    moveable,
  ])

  useEffect(() => {
    const moveable = sliderContainerWidth < elementContainerWidth

    showArrows({ type: 'showSliderArrows', payload: moveable })
    handleLeftArrowActive({
      type: 'setLeftArrowActiveState',
      payload: moveable && !atLeftStop,
    })
    handleRightArrowActive({
      type: 'setRightArrowActiveState',
      payload: translate < -100,
    })

    const update = () => {
      /* istanbul ignore next */
      setState(state => ({
        ...state,
        sliderContainerWidth: sliderRef.current.clientWidth,
        moveable,
        leftActive: moveable,
        translate: moveable ? translate : 0,
      }))
    }
    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('resize', update)
    }
  }, [
    sliderContainerWidth,
    translate,
    handleLeftArrowActive,
    handleRightArrowActive,
    showArrows,
    elementContainerWidth,
    atLeftStop,
  ])

  // handle mouse events
  const handleMouseDown = useCallback(
    ({ clientX }) => {
      if (!enabled) {
        return
      }
      setState(state => ({
        ...state,
        startXPosition: clientX,
        dragging: true,
        prevTranslate: state.translate,
      }))
    },
    [enabled]
  )

  const handleMouseMove = useCallback(
    event => {
      /* istanbul ignore next */
      setState(state => {
        const movement = event.clientX - state.startXPosition
        const atStartPosition = movement + prevTranslate > 0
        return {
          ...state,
          translate: atStartPosition ? 0 : movement + prevTranslate,
        }
      })
    },
    [prevTranslate]
  )

  const handleMouseLeave = () => {
    /* istanbul ignore next */
    setState(state => ({
      ...state,
      dragging: false,
    }))
  }

  const handleMouseUp = () => {
    setState(state => ({
      ...state,
      dragging: false,
    }))
  }

  // handle touch scroll mobile/ipad
  const handleTouchStart = useCallback(
    ({ touches }) => {
      if (!enabled) {
        return
      }
      setState(state => ({
        ...state,
        startXPosition: touches[0].clientX,
        dragging: true,
        prevTranslate: state.translate,
      }))
    },
    [enabled]
  )

  const handleTouchMove = useCallback(
    ({ touches }) => {
      setState(state => {
        const movement = touches[0].clientX - state.startXPosition
        const atStartPosition = movement + prevTranslate >= 0
        return {
          ...state,
          // if slider is at start position prevent sliding to the right
          translate: atStartPosition ? 0 : movement + prevTranslate,
        }
      })
    },
    [prevTranslate]
  )

  const handleTouchEnd = () => {
    setState(state => ({
      ...state,
      dragging: false,
    }))
  }

  // handle arrow clicks
  useEffect(() => {
    if (moveable) {
      setState(state => ({
        ...state,
        translate: state.translate - (singleElementWidth + elementMargin),
      }))
    }
  }, [leftClick, singleElementWidth, moveable, elementMargin])

  useEffect(() => {
    setState(state => ({
      ...state,
      translate:
        state.translate >= 0
          ? 0
          : state.translate + singleElementWidth + elementMargin,
    }))
  }, [rightClick, singleElementWidth, moveable, elementMargin])

  // event listeners
  useEffect(() => {
    if (dragging && moveable) {
      // mouse events
      sliderRef.current.addEventListener('mousemove', handleMouseMove)
      sliderRef.current.addEventListener('mouseup', handleMouseUp)
      sliderRef.current.addEventListener('mouseleave', handleMouseLeave)

      // touch events
      sliderRef.current.addEventListener('touchmove', handleTouchMove)
      sliderRef.current.addEventListener('touchend', handleTouchEnd)
    } else {
      sliderRef.current.removeEventListener('mousemove', handleMouseMove)
      sliderRef.current.removeEventListener('mouseup', handleMouseUp)
      sliderRef.current.removeEventListener('mouseleave', handleMouseLeave)

      sliderRef.current.removeEventListener('touchmove', handleTouchMove)
      sliderRef.current.removeEventListener('touchend', handleTouchEnd)
    }
  }, [dragging, handleMouseDown, handleMouseMove, handleTouchMove, moveable])

  // snap scrolling
  useEffect(() => {
    if (!dragging) {
      const parkedSliderRemainder =
        translate % (singleElementWidth + elementMargin)
      let difference
      Math.abs(parkedSliderRemainder) < singleElementWidth / 2
        ? (difference = parkedSliderRemainder)
        : (difference =
            singleElementWidth + elementMargin + parkedSliderRemainder)

      setState(state => ({
        ...state,
        translate: translate - difference,
      }))
    }
  }, [dragging, singleElementWidth, translate, elementMargin])

  // disable the ability to slide left once the last element is fully visible
  useEffect(() => {
    const elementPlusMarginWidth = singleElementWidth + elementMargin
    const translateLeftCount = translate / elementPlusMarginWidth
    const totalElementCountWidth = elementCount * elementPlusMarginWidth

    const visibleElements =
      totalElementCountWidth + translateLeftCount * elementPlusMarginWidth

    const visibleElementsToShowWidth =
      sliderContainerWidth - (sliderContainerWidth % elementPlusMarginWidth)

    const pastLeft = visibleElements < visibleElementsToShowWidth
    const zeroMinusTotalElementCountWidth = 0 - totalElementCountWidth

    if (pastLeft) {
      setState(state => ({
        ...state,
        pastLeftStop: pastLeft,
        translate: zeroMinusTotalElementCountWidth + visibleElementsToShowWidth,
      }))
    }

    setState(state => ({
      ...state,
      atLeftStop: visibleElements === visibleElementsToShowWidth,
    }))
  }, [
    translate,
    pastLeftStop,
    elementCount,
    sliderContainerWidth,
    atLeftStop,
    elementMargin,
    singleElementWidth,
  ])

  // move slider
  const style = {
    transform: `translateX(${translate}px)`,
    transition: dragging ? `none` : `transform 300ms`,
    width: elementContainerWidth,
  }

  return (
    <div ref={sliderRef} className={cx('slider-container', customClass)}>
      <span className={cx('slider-cover')} />
      <div
        className={cx('slider')}
        style={style}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        {children}
      </div>
      <div className={cx('slider-right-gradient')} />
    </div>
  )
}

Slider.propTypes = {
  leftClick: PropTypes.number.isRequired,
  rightClick: PropTypes.number.isRequired,
  singleElementWidth: PropTypes.number.isRequired,
  elementCount: PropTypes.number.isRequired,
  elementMargin: PropTypes.number.isRequired,
  showArrows: PropTypes.func.isRequired,
  handleLeftArrowActive: PropTypes.func.isRequired,
  handleRightArrowActive: PropTypes.func.isRequired,
  additionalContainerWidth: PropTypes.number,
  enabled: PropTypes.bool,
}

Slider.defaultProps = {
  additionalContainerWidth: 0,
  enabled: true,
}

export { Slider }
