import { gql } from '@apollo/client'

const COMPANY_ENABLED_TABS = gql`
query companyQuery {
  company {
    portfolioBenchmarkEnabled
    periodOverPeriodAnalysisEnabled
    competitorPriceAnalysisEnabled
  }
}
`

export { COMPANY_ENABLED_TABS }
