import classNames from 'classnames/bind'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import PropTypes from 'prop-types'
import React from 'react'
import { PolePriceCell } from './components/pole-price-cell'
import { FuelGradeDifferentials } from './components/fuel-grade-differentials'
import styles from './PolePriceRow.module.scss'

const cx = classNames.bind(styles)

const PolePriceRow = ({
  stationName,
  fuelGrades,
  pricing,
  isFirstRow,
  isFiltered,
}) => {
  const {
    stationId,
    polePrices,
    priceDifferentials: { fuelGradeDifferentials },
    priceChangeEnabled,
  } = pricing

  const handleIncrementClick = fuelGradeId => {
    stationsListPricingOperations.incrementStationPriceChange(
      stationId,
      fuelGradeId,
      polePrices,
      fuelGradeDifferentials
    )
  }

  const handleDecrementClick = fuelGradeId => {
    stationsListPricingOperations.decrementStationPriceChange(
      stationId,
      fuelGradeId,
      polePrices,
      fuelGradeDifferentials
    )
  }

  return (
    <div
      className={cx('pole-prices-row', {
        'first-row': isFirstRow,
        filtered: isFiltered,
      })}
      data-testid="pole-prices-row"
    >
      <FuelGradeDifferentials
        stationName={stationName}
        priceChangeEnabled={priceChangeEnabled}
        fuelGrades={fuelGrades}
        fuelGradeDifferentials={fuelGradeDifferentials}
      />
      {fuelGrades.map(fuelGrade => (
        <PolePriceCell
          key={fuelGrade.id}
          fuelGrade={fuelGrade}
          pricing={pricing}
          handleIncrementClick={handleIncrementClick}
          handleDecrementClick={handleDecrementClick}
        />
      ))}
    </div>
  )
}

PolePriceRow.propTypes = {
  stationName: PropTypes.string.isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pricing: PropTypes.shape().isRequired,
  isFirstRow: PropTypes.bool.isRequired,
  isFiltered: PropTypes.bool.isRequired,
}

export { PolePriceRow }
