import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useTranslation } from 'react-i18next'
import { useFormatPriceDifferential } from 'components/common/hooks/useFormatPriceDifferential'
import classNames from 'classnames/bind'
import styles from './CashDiscountTooltip.module.scss'

const cx = classNames.bind(styles)

const CashDiscountTooltip = ({ cashCreditDifferential, children }) => {
  const { formatPriceDifferential } = useFormatPriceDifferential()
  const { t } = useTranslation()

  const overlay = (
    <Tooltip className={cx('cash-discount-tooltip')}>
      <div>{t('widgets.stationsListPricing.cashDiscount')}</div>
      <div>{formatPriceDifferential(cashCreditDifferential.differential)}</div>
    </Tooltip>
  )

  return (
    <OverlayTrigger placement="bottom" overlay={overlay}>
      {children}
    </OverlayTrigger>
  )
}

CashDiscountTooltip.propTypes = {
  cashCreditDifferential: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired,
}

export { CashDiscountTooltip }
