import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TickIcon } from 'images/stations-list/tick.svg'
import classNames from 'classnames/bind'
import styles from './Buttons.module.scss'

const cx = classNames.bind(styles)

const Buttons = ({ valid, closeModal, handleApply }) => {
  const { t } = useTranslation()
  return (
    <div className={cx('buttons')}>
      <div className={cx('cancel')}>
        <span onClick={closeModal} data-testid="cancel">
          {t('widgets.stationsList.cancel')}
        </span>
      </div>
      <div
        className={cx('apply', { valid })}
        onClick={handleApply}
        data-testid="apply"
      >
        <span>{t('widgets.stationsList.apply')}</span>
        <TickIcon />
      </div>
    </div>
  )
}

Buttons.propTypes = {
  valid: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
}

export { Buttons }
