import { gql, useMutation, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { timestampVar } from 'config/apollo/competitor-pricing/variables'
import { COMPETITOR_PRICING_QUERY } from 'components/station/components/competitor-pricing/CompetitorPricingQueries'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { useShowToast } from 'components/common/hooks/useShowToast'

const CREATE_STATION_STRATEGY_MUTATION = gql`
  mutation createStationStrategyMutation($stationId: ID!, $strategy: String!) {
    createStationStrategy(
      input: { stationId: $stationId, strategy: $strategy }
    ) {
      error
      stationStrategy {
        id
        strategy
      }
    }
  }
`

function useCreateStationStrategy(stationId, onSuccess) {
  const timestamp = useReactiveVar(timestampVar)
  const { supportedPricingTypes } = usePricingTypes()
  const { showErrorToast } = useShowToast()
  const [createStrategy, result] = useMutation(
    CREATE_STATION_STRATEGY_MUTATION,
    {
      update(
        cache,
        {
          data: {
            createStationStrategy: { error, stationStrategy },
          },
        }
      ) {
        if (error) {
          showErrorToast()
          return
        }

        const variables = {
          stationId,
          supportedPricingTypes,
          timestamp,
        }
        const { station } = cache.readQuery({
          query: COMPETITOR_PRICING_QUERY,
          variables,
        })

        const updatedStation = { ...station, stationStrategy }
        cache.writeQuery({
          query: COMPETITOR_PRICING_QUERY,
          variables,
          data: { station: updatedStation },
        })
      },
      onCompleted({ createStationStrategy: { error } }) {
        if (!error) {
          onSuccess()
        }
      },
      onError(error) {
        Sentry.captureException(error)
        showErrorToast()
      },
    }
  )

  return [createStrategy, result]
}

export { useCreateStationStrategy, CREATE_STATION_STRATEGY_MUTATION }
