import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { Backdrop } from 'components/common/backdrop'
import { useReactiveVar } from '@apollo/client'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { overlayStationVar } from 'config/apollo/stations-list-pricing/variables'
import { OverlayBody } from './components/overlay-body'
import { OverlayHeader } from './components/overlay-header'
import styles from './StationOverlay.module.scss'

const cx = classNames.bind(styles)

const StationOverlay = ({ items, stations }) => {
  const overlayStation = useReactiveVar(overlayStationVar)

  if (!overlayStation || !items.some(({ id }) => id === overlayStation)) {
    return null
  }

  const handleClose = () => {
    stationsListPricingOperations.setOverlayStation(null)
  }

  return (
    <>
      <Backdrop visible zIndex={5} onClick={handleClose} />
      <div className={cx('station-overlay')}>
        <div className={cx('header')}>
          <OverlayHeader
            overlayStation={overlayStation}
            stations={stations}
            items={items}
            handleClose={handleClose}
          />
        </div>
        <div className={cx('body')}>
          <OverlayBody stationId={overlayStation} stations={stations} />
        </div>
      </div>
    </>
  )
}

StationOverlay.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { StationOverlay }
