import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  TrendDirection,
  MetricTypes,
  ComparativePeriods,
} from 'utils/constants'
import { isNullOrUndefined, getTrendDirection } from 'utils/helpers'
import { ReactComponent as TrendUp } from 'images/common/up.svg'
import { ReactComponent as TrendDown } from 'images/common/down.svg'
import { ReactComponent as TrendNeutral } from 'images/common/neutral.svg'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { useCurrentDayTranslated } from 'components/common/hooks/useCurrentDayTranslated'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import classNames from 'classnames/bind'
import styles from './TrendIndicator.module.scss'

const cx = classNames.bind(styles)

const { POSITIVE, NEGATIVE } = TrendDirection
const { PERCENTAGE } = MetricTypes
const {
  AVERAGE_DAY,
  THIS_TIME_YESTERDAY,
  THIS_DAY_LAST_WEEK,
} = ComparativePeriods

const getTrendIcon = trendDirection => {
  switch (trendDirection) {
    case POSITIVE:
      return TrendUp
    case NEGATIVE:
      return TrendDown
    default:
      return TrendNeutral
  }
}

const renderTrend = (trend, formatMetricValue, day) => {
  const trendDirection = getTrendDirection(trend)
  const TrendIcon = getTrendIcon(trendDirection)
  const trendClass = trendDirection.toLowerCase()

  return (
    <div className={cx('trend-indicator')} data-testid="trend-indicator">
      <div className={cx('trend-icon')}>
        <TrendIcon className={cx(trendClass)} />
      </div>

      <div className={cx(trendClass)}>
        {formatMetricValue(PERCENTAGE, trend)}
      </div>
    </div>
  )
}

const renderComparativePeriodText = (comparativePeriod, day, t) => {
  switch (comparativePeriod) {
    case AVERAGE_DAY:
      return t('widgets.trendIndicator.averageDay', { day })
    case THIS_TIME_YESTERDAY:
      return t('widgets.trendIndicator.thisTimeYesterday')
    case THIS_DAY_LAST_WEEK:
      return t('widgets.trendIndicator.thisTimeLastWeek')
    default:
      return null
  }
}

const renderTooltip = (
  formatMetricValue,
  comparativeType,
  comparative,
  comparativePeriod,
  day,
  t
) => {
  return (
    <Tooltip
      className={cx('comparative-tooltip')}
      data-testid="comparative-tooltip"
    >
      <div>
        {t('widgets.trendIndicator.comparedWith')}
        <span className={cx('comparative-value')}>
          {formatMetricValue(comparativeType, comparative)}
        </span>{' '}
        {renderComparativePeriodText(comparativePeriod, day, t)}
      </div>
    </Tooltip>
  )
}

const TrendIndicator = ({
  tooltip,
  trend,
  comparative,
  comparativeType,
  comparativePeriod,
  tooltipPlacement,
}) => {
  const { formatMetricValue } = useFormatMetricValue()
  const { currentDayTranslated } = useCurrentDayTranslated()
  const { t } = useTranslation()

  if (isNullOrUndefined(trend)) {
    return null
  }

  const day = currentDayTranslated()

  const trendElement = renderTrend(trend, formatMetricValue)

  if (!tooltip) {
    return trendElement
  }

  const overlay = renderTooltip(
    formatMetricValue,
    comparativeType,
    comparative,
    comparativePeriod,
    day,
    t
  )
  return (
    <OverlayTrigger placement={tooltipPlacement} overlay={overlay}>
      {trendElement}
    </OverlayTrigger>
  )
}

TrendIndicator.propTypes = {
  tooltip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  trend: PropTypes.number,
  comparative: PropTypes.number,
  comparativeType: PropTypes.string,
  comparativePeriod: PropTypes.string,
}

TrendIndicator.defaultProps = {
  tooltip: false,
  tooltipPlacement: 'bottom',
  trend: null,
  comparative: null,
  comparativeType: null,
  comparativePeriod: null,
}

export { TrendIndicator }
