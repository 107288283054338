import { gql } from '@apollo/client'
import { USER_PERMISSION_ATTRIBUTES } from 'config/apollo/fragments'

const STATION_ITEM_METRIC_ATTRIBUTES = gql`
  fragment StationItemMetricAttributes on StationItemMetricsSet {
    totalVolume {
      today
      comparative
      trend
      trendComparative
    }
    retailVolume {
      today
      comparative
      trend
      trendComparative
    }
    netMargin {
      today
      comparative
      trend
      trendComparative
    }
    grossMargin {
      today
      comparative
      trend
      trendComparative
    }
    grossProfit {
      today
      comparative
      trend
      trendComparative
    }
  }
`

const STATION_ITEM_PRICING_ATTRIBUTES = gql`
  fragment StationItemPricingAttributes on StationItemPricingSet {
    stationId
    isSelected @client
    hasStationPrice @client
    priceChanges @client
    priceChangeEnabled
    cashCreditPricing
    polePrices {
      id
      fuelGradeId
      price
    }
    polePriceChangeRequests {
      id
      fuelGradeId
      newPrice
      pricingType
      toActionAt
    }
    priceDifferentials {
      fuelGradeDifferentials {
        id
        fuelGradeId
        leadGrade
        leadGradeId
        differential
      }
      cashCreditDifferentials {
        id
        fuelGradeId
        differential
      }
    }
  }
`

const STATION_GROUP_METRIC_ATTRIBUTES = gql`
  fragment StationGroupMetricAttributes on StationGroupMetricsSet {
    totalVolume {
      today
      comparative
      trend
      trendComparative
    }
    retailVolume {
      today
      comparative
      trend
      trendComparative
    }
    netMargin {
      today
      comparative
      trend
      trendComparative
    }
    grossMargin {
      today
      comparative
      trend
      trendComparative
    }
    grossProfit {
      today
      comparative
      trend
      trendComparative
    }
  }
`

const STATIONS_LIST_MAIN_QUERY = gql`
  query stationsListMainQuery {
    me {
      id
      ...UserPermissionAttributes
      fuelGrades {
        id
        title
        shortTitle
        primary
        priceChange @client
      }
      stations {
        groupBys {
          name
        }
        filters {
          name
          operators
          values
          requiresValue
        }
        items {
          id
          name
          brand {
            id
            name
            key
          }
          targetMargin {
            id
            target
          }
        }
      }
    }
  }
  ${USER_PERMISSION_ATTRIBUTES}
`

const STATIONS_LIST_PAGE_QUERY = gql`
  query stationsListPageQuery(
    $timestamp: String!
    $number: Int!
    $size: Int!
    $pricingType: PricingType!
    $sort: StationSortInput!
    $filters: [StationFilterInput!]
    $pricing: Boolean!
  ) {
    stations(
      input: {
        timestamp: $timestamp
        pricingType: $pricingType
        number: $number
        size: $size
        sort: $sort
        filters: $filters
      }
    ) {
      pageInfo {
        nextPage
        pageNumber
        nextPageNumber
        isSelected @client @include(if: $pricing)
        pricingStationIds @include(if: $pricing)
      }
      items {
        id
        pricing @include(if: $pricing) {
          ...StationItemPricingAttributes
        }
        metrics {
          ...StationItemMetricAttributes
        }
      }
    }
  }
  ${STATION_ITEM_METRIC_ATTRIBUTES}
  ${STATION_ITEM_PRICING_ATTRIBUTES}
`

const STATIONS_LIST_GROUPS_QUERY = gql`
  query stationsListGroupsQuery(
    $timestamp: String!
    $groupBy: String!
    $selectedPricingType: PricingType!
    $sort: StationSortInput!
    $filters: [StationFilterInput!]
    $pricing: Boolean!
  ) {
    stationGroups(
      input: {
        timestamp: $timestamp
        groupBy: $groupBy
        pricingType: $selectedPricingType
        sort: $sort
        filters: $filters
      }
    ) {
      groupBy
      groups {
        name
        stationsCount
        isSelected @client @include(if: $pricing)
        pricingStationIds @include(if: $pricing)
        metrics {
          ...StationGroupMetricAttributes
        }
      }
    }
  }
  ${STATION_GROUP_METRIC_ATTRIBUTES}
`

const STATIONS_LIST_GROUP_PAGE_QUERY = gql`
  query stationsListGroupPageQuery(
    $timestamp: String!
    $number: Int!
    $size: Int!
    $pricingType: PricingType!
    $sort: StationSortInput!
    $filters: [StationFilterInput!]
    $group: StationGroupInput!
    $pricing: Boolean!
  ) {
    stations(
      input: {
        timestamp: $timestamp
        pricingType: $pricingType
        number: $number
        size: $size
        sort: $sort
        filters: $filters
        group: $group
      }
    ) {
      pageInfo {
        nextPage
        pageNumber
        nextPageNumber
      }
      items {
        id
        pricing @include(if: $pricing) {
          ...StationItemPricingAttributes
        }
        metrics {
          ...StationItemMetricAttributes
        }
      }
    }
  }
  ${STATION_ITEM_METRIC_ATTRIBUTES}
  ${STATION_ITEM_PRICING_ATTRIBUTES}
`

export {
  STATIONS_LIST_MAIN_QUERY,
  STATIONS_LIST_PAGE_QUERY,
  STATIONS_LIST_GROUPS_QUERY,
  STATIONS_LIST_GROUP_PAGE_QUERY,
}
