import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Resizable } from 're-resizable'
import classNames from 'classnames/bind'
import { Handle } from './components/handle'
import { HeaderRow } from './components/header-row'
import { PolePriceRows } from './components/pole-price-rows'
import { GroupRows } from './components/group-rows'
import { EditPricingFooter } from '../edit-pricing-footer'
import styles from './PricesPanel.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const renderContent = (groups, items, stations, fuelGrades) => {
  if (groups) {
    return (
      <GroupRows groups={groups} stations={stations} fuelGrades={fuelGrades} />
    )
  }

  return (
    <PolePriceRows items={items} stations={stations} fuelGrades={fuelGrades} />
  )
}

const PricesPanel = ({
  loading,
  fuelGrades,
  items,
  stations,
  groups,
  showPricingView,
}) => {
  const [width, setWidth] = useState()
  let moved = false

  useEffect(() => {
    if (!showPricingView) {
      setWidth(null)
    }
  }, [showPricingView])

  if (!showPricingView) {
    return null
  }

  const enable = {
    top: false,
    right: false,
    bottom: false,
    left: !loading,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  }

  const handleComponent = {
    left: <Handle />,
  }

  /* istanbul ignore next */
  const handleResize = (_event, _direction, elementRef) => {
    setWidth(elementRef.clientWidth)
  }

  const handleResizeStart = () => {
    !moved && amplitudeEvent(UserEvents.GroupEditPricing.SLIDE_MOVED)
    moved = true
  }

  return (
    <>
      <Resizable
        className={cx('resizable', { loading })}
        maxWidth="80%"
        minWidth="40%"
        enable={enable}
        handleComponent={handleComponent}
        onResize={handleResize}
        onResizeStart={handleResizeStart}
      >
        <div className={cx('wrapper')}>
          <div className={cx('first-row')} />
          <HeaderRow fuelGrades={fuelGrades} />
          {!loading && (
            <div data-testid="wrapper">
              {renderContent(groups, items, stations, fuelGrades)}
            </div>
          )}
        </div>
      </Resizable>
      <EditPricingFooter width={width} fuelGrades={fuelGrades} />
    </>
  )
}

PricesPanel.propTypes = {
  loading: PropTypes.bool,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()),
  stations: PropTypes.arrayOf(PropTypes.shape()),
  groups: PropTypes.arrayOf(PropTypes.shape()),
  showPricingView: PropTypes.bool.isRequired,
}

PricesPanel.defaultProps = {
  loading: false,
  stations: null,
  items: null,
  groups: null,
}

export { PricesPanel }
