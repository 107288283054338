import React, { useState, useEffect } from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'
import PropTypes from 'prop-types'
import { MultiSelect } from 'components/common/multi-select'
import { USER_CATEGORIES_QUERY } from '../../UserCategories'
import { groupByVar, stationsVar } from 'config/apollo/analytics-report/variables'
import { useQuery } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { useTranslation } from 'react-i18next'
import { WidgetFallback } from 'components/common/widget-fallback'
import { upperFirstOnly } from 'utils/format'
import styles from './GroupBy.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const PERIOD_ANALYSIS = 'PERIOD_ANALYSIS'
export const PORTFOLIO_BENCHMARK = 'PORTFOLIO_BENCHMARK'
export const ITEMS_LIMIT = 3

const GroupBy = ({ activeTab }) => {
  const { t } = useTranslation()
  const { error: categoriesError, data: categories } = useQuery(USER_CATEGORIES_QUERY)
  useErrorHandler(categoriesError)

  const userCategories = categories?.userCategories.map((uc) => uc.parent) || []
  const [selectedItem, setSelectedItem] = useState(groupByVar)
  const [tooltipTarget, setTooltipTarget] = useState(null)

  useEffect(() => {
    if (tooltipTarget) {
      const timeout = setTimeout(() => {
        setTooltipTarget(null)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [tooltipTarget])

  useEffect(() => {
    if (activeTab === PORTFOLIO_BENCHMARK) {
      setTooltipTarget(null)
    }
  }, activeTab)

  const handleItemClick = (event) => {
    groupByVar({
      ...groupByVar(),
      [activeTab]: event
    })

    setSelectedItem((prevState) => ({
      ...prevState,
      [activeTab]: event
    }))
  }

  const handleDropdownClick = open => {
    setTooltipTarget(null)
  }

  const handleTooltipClick = () => {
    setTooltipTarget(null)
  }

  const handleMoreThanMaxSelected = event => {
    setTooltipTarget(event.target)
  }

  const maxItemsSelected = ITEMS_LIMIT - (stationsVar()['PERIOD_ANALYSIS'].length)

  return (
    <>
      <MultiSelect
        placeholder="All groups"
        items={userCategories}
        value={selectedItem[activeTab]}
        onValueSelect={handleItemClick}
        onDropdownClick={handleDropdownClick}
        maxSelected={maxItemsSelected}
        onMoreThanMaxSelected={handleMoreThanMaxSelected}
      />
      <Overlay target={tooltipTarget} show={!!tooltipTarget} position="top">
        <Tooltip className={cx('tooltip')} onClick={handleTooltipClick}>
          <div className={cx('tooltip-content')} data-testid="tooltip">
          {t('widgets.stationSelector.itemsLimit', {
            limit: ITEMS_LIMIT
          })}
          </div>
        </Tooltip>
      </Overlay>
    </>
  )
}

const FallbackComponent = () => {
  const { t } = useTranslation(['translations'])

  return (
    <WidgetFallback
      title={upperFirstOnly(t('widgets.analytics.title'))}
    />
  )
}

const GroupByWithErrorBoundary = withEdgeErrorBoundary(GroupBy, FallbackComponent)

GroupBy.propTypes = {
  activeTab: PropTypes.string
}

export { GroupByWithErrorBoundary as GroupBy }
