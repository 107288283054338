import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Input.module.scss'

const cx = classNames.bind(styles)

const Input = ({ value, onValueChange, customClass, placeholder }) => {
  const handleChange = event => {
    const {
      target: { value },
    } = event
    onValueChange(value)
  }

  return (
    <div className={cx('value-input', customClass)}>
      <input
        type="text"
        value={value}
        onChange={event => handleChange(event)}
        placeholder={placeholder}
      />
    </div>
  )
}

Input.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  customClass: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Input.defaultProps = {
  value: '',
  customClass: null,
  placeholder: null,
}

export { Input }
