import { useEffect } from 'react'

const useLockBodyScroll = lock => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  if (lock) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'auto'
  }
}

export { useLockBodyScroll }
