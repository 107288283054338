import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import { statusVar } from 'config/apollo/stations-list-pricing/variables'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useFlags } from 'launchdarkly-react-client-sdk'
import classNames from 'classnames/bind'
import { ReactComponent as EditPricingIcon } from 'images/competitor-pricing/edit-pricing.svg'
import { ReactComponent as EditCancelIcon } from 'images/competitor-pricing/edit-cancel.svg'
import { ReactComponent as InfoIcon } from 'images/common/info.svg'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { usePriceChange } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import {
  PriceChangeStatus,
  PriceChangeActions,
} from 'components/station/components/competitor-pricing/context/constants'
import { Permissioned } from 'components/common/permissioned'
import { PricingTypes } from 'utils/constants'
import styles from './EditPricingButton.module.scss'

const cx = classNames.bind(styles)

const getPricingTypeCopy = (stationCashCreditPricing, selectedPricingType) => {
  if (selectedPricingType === PricingTypes.ALL || !stationCashCreditPricing) {
    return ''
  }
  return `${selectedPricingType.toLowerCase()} `
}

const EditPricingButton = ({ station }) => {
  const { selectedPricingType } = usePricingTypes()
  const [{ status }, dispatch] = usePriceChange()
  const stationsListPricingStatus = useReactiveVar(statusVar)
  const { showStationsListPricing } = useFlags()
  const {
    featurePermissions: { updateEditPricing },
  } = usePermittedAccess()
  const { t } = useTranslation()

  const {
    priceChangeEnabled,
    cashCreditPricing: stationCashCreditPricing,
    dateTimeSelectorInUse,
    setDateTimeSelectorInUse,
  } = station

  if (!priceChangeEnabled) {
    const overlay = (
      <Tooltip className={cx('price-change-disabled-tooltip')}>
        <div>
          {t('widgets.competitorPricing.noStationManagerAssignedToolTip')}
        </div>
      </Tooltip>
    )

    return (
      <OverlayTrigger placement="top" overlay={overlay}>
        <div
          className={cx('edit-pricing-button', 'disabled')}
          data-testid="edit-pricing-button"
        >
          <EditPricingIcon height={20} width={20} />
          <span>
            {t('widgets.competitorPricing.sharedContent', {
              pricingTypeCopy: getPricingTypeCopy(
                stationCashCreditPricing,
                selectedPricingType
              ),
            })}
          </span>
        </div>
      </OverlayTrigger>
    )
  }

  if (
    showStationsListPricing &&
    stationsListPricingStatus !== StationsListPricingStatus.NONE
  ) {
    const overlay = (
      <Tooltip className={cx('price-change-disabled-tooltip')}>
        <div>
          {t('widgets.competitorPricing.currentlyInMultiplePriceChangeTooltip')}
        </div>
      </Tooltip>
    )

    return (
      <OverlayTrigger placement="top" overlay={overlay}>
        <div
          className={cx('edit-pricing-button', 'disabled')}
          data-testid="edit-pricing-button"
        >
          <InfoIcon height={20} width={20} />
          <span>{t('widgets.competitorPricing.groupEditing')}</span>
        </div>
      </OverlayTrigger>
    )
  }

  if (priceChangeEnabled && status !== PriceChangeStatus.None) {
    const handleCancelClick = () => {
      dispatch({
        type: PriceChangeActions.SetCurrentStatus,
        payload: { status: PriceChangeStatus.None },
      })
      setDateTimeSelectorInUse(false)
    }

    return (
      <div
        className={cx('edit-pricing-button', 'cancel')}
        data-testid="edit-pricing-button"
        onClick={handleCancelClick}
      >
        <EditCancelIcon height={25} width={25} />
        <span>{t('widgets.competitorPricing.cancelEdits')}</span>
      </div>
    )
  }

  const handleEditClick = () => {
    amplitudeEvent(UserEvents.EditPricing.OPENED)
    dispatch({
      type: PriceChangeActions.SetCurrentStatus,
      payload: {
        status: PriceChangeStatus.Edit,
      },
    })
    setDateTimeSelectorInUse(false)
  }

  const sharedContent = (
    <>
      <EditPricingIcon height={20} width={20} />
      <span>
        {t('widgets.competitorPricing.sharedContent', {
          pricingTypeCopy: getPricingTypeCopy(
            stationCashCreditPricing,
            selectedPricingType
          ),
        })}
      </span>
    </>
  )

  const disabledEditPricing = (
    <div
      className={cx('edit-pricing-button', 'disabled')}
      data-testid="edit-pricing-button"
    >
      {sharedContent}
    </div>
  )

  const editPricing = (
    <div
      className={cx('edit-pricing-button')}
      onClick={handleEditClick}
      data-testid="edit-pricing-button"
    >
      {sharedContent}
    </div>
  )

  return (
    <Permissioned permission={updateEditPricing} fallback={disabledEditPricing}>
      {dateTimeSelectorInUse ? disabledEditPricing : editPricing}
    </Permissioned>
  )
}

EditPricingButton.propTypes = {
  station: PropTypes.shape().isRequired,
}

export { EditPricingButton }
