import { gql } from '@apollo/client'
import { POLE_PRICE_CHANGE_REQUEST_ATTRIBUTES } from 'config/apollo/fragments'

const CREATE_POLE_PRICE_CHANGE_REQUESTS_MUTATION = gql`
  mutation createPolePriceChangeRequestsMutation(
    $stationPolePriceChangeRequests: [StationPolePriceChangeRequestInput!]!
    $reminders: RemindersInput!
  ) {
    createPolePriceChangeRequests(
      stationPolePriceChangeRequests: $stationPolePriceChangeRequests
      reminders: $reminders
    ) {
      error
      stationId
      polePriceChangeRequests {
        ...PolePriceChangeRequestAttributes
      }
    }
  }
  ${POLE_PRICE_CHANGE_REQUEST_ATTRIBUTES}
`

export { CREATE_POLE_PRICE_CHANGE_REQUESTS_MUTATION }
