import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as DropdownIcon } from 'images/common/dropdown.svg'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import { Items } from './components/items'
import styles from './Dropdown.module.scss'

const cx = classNames.bind(styles)

const Dropdown = ({
  selectedItem,
  selectedItemDisplay,
  items,
  onValueSelect,
  customClass,
  placeholder,
  maxHeight,
  disabled,
  leftAccessory,
  onItemClick,
  onOpen,
  onClose,
  itemsRef,
  selectedDropdownHighlight,
  enableMultiSelect
}) => {
  const [open, setOpen] = useState()

  const getDisplayItem = () => {
    // override the header display value (separately from the items in the dropdown)
    if (selectedItemDisplay) {
      return selectedItemDisplay
    }
    const item = items.find(item => item.item === selectedItem)
    return item?.displayItem ?? selectedItem
  }

  const handleDropdownClick = () => {
    if (!disabled) {
      setOpen(true)
      if (onOpen) {
        onOpen(() => {
          /* istanbul ignore next */
          if (onClose) {
            onClose()
          }
          setOpen(false)
        })
      }
    }
  }

  const handleItemClick = event => {
    event.stopPropagation()
    if (onItemClick) {
      onItemClick(event, () => {
        /* istanbul ignore next */
        if (onClose) {
          onClose()
        }
        setOpen(false)
      })
      if (!event.defaultPrevented) {
        /* istanbul ignore next */
        if (onClose) {
          onClose()
        }
        setOpen(false)
      }
    } else {
      /* istanbul ignore next */
      if (onClose) {
        onClose()
      }
      setOpen(false)
    }
  }

  const showPlaceholder = isNullOrUndefined(selectedItem)
  const displayedValue = showPlaceholder ? placeholder : getDisplayItem()
  const selectedDropdown = selectedDropdownHighlight && items.some((item) => item.item === selectedItem)
  return (
    <div
      className={cx('dropdown', customClass, {
        disabled,
        open,
        selected_dropdown: selectedDropdown,
      })}
      onClick={handleDropdownClick}
      data-testid="dropdown"
    >
      <span className={cx({ placeholder: showPlaceholder, 'selected-dropdown': selectedDropdown })}>
        {leftAccessory}
        {displayedValue}
      </span>
      {!disabled && <DropdownIcon className={cx('dropdown-icon')} />}
      {open && (
        <div className={cx('items')} onClick={handleItemClick} ref={itemsRef}>
          <div className={cx('selected-item')}>
            <span className={cx({ placeholder: showPlaceholder })}>
              {leftAccessory} {displayedValue}
            </span>
            <DropdownIcon className={cx('dropdown-icon')} />
          </div>
          <div
            className={cx('items-list')}
            data-testid="items-list"
            style={{
              maxHeight,
            }}
          >
            <Items
              items={items}
              selectedItem={selectedItem}
              onValueSelect={onValueSelect}
              enableMultiSelect={enableMultiSelect}
            />
          </div>
        </div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedItemDisplayHeader: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      displayItem: PropTypes.string,
    })
  ),
  onValueSelect: PropTypes.func,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  maxHeight: PropTypes.string,
  disabled: PropTypes.bool,
  leftAccessory: PropTypes.node,
  onItemClick: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  itemsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  selectedDropdownHighlight: PropTypes.bool,
  enableMultiSelect: PropTypes.bool
}

Dropdown.defaultProps = {
  selectedItem: null,
  selectedItemDisplayHeader: null,
  items: [],
  onValueSelect: null,
  customClass: null,
  placeholder: null,
  maxHeight: null,
  disabled: false,
  leftAccessory: null,
  onItemClick: null,
  onOpen: null,
  onClose: null,
  itemsRef: null,
  selectedDropdownHighlight: false,
  enableMultiSelect: true
}

export { Dropdown }
