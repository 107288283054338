import React from 'react'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import Nav from 'react-bootstrap/Nav'
import styles from './TabNavs.module.scss'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly } from 'utils/format'
import { showPortfolioBenchmark } from 'utils/helpers'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAuth } from 'config/context/authContext'
import { PortfolioBenchmark, PeriodAnalysis, CompetitorAnalysis } from 'utils/constants'
import { COMPANY_ENABLED_TABS } from '../../CompanyEnabledTabsQueries'
import { useQuery } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'

const cx = classNames.bind(styles)

const TabNavs = ({ activeTab, setActiveTab, mainData }) => {
  const { t } = useTranslation()
  const { showPortfolioBenchmarkTab } = useFlags()
  const { userLocale } = useAuth()
  const { error: error, data: data } = useQuery(COMPANY_ENABLED_TABS)
  useErrorHandler(error)

  const tabs = [
    {
      tabType: PeriodAnalysis,
      tabTitle: upperFirstOnly(t('widgets.analytics.periodAnalysis')),
      enabled: data?.company.periodOverPeriodAnalysisEnabled,
    },
    {
      tabType: PortfolioBenchmark,
      tabTitle: upperFirstOnly(t('widgets.analytics.portfolioBenchmark')),
      enabled: (data?.company.portfolioBenchmarkEnabled && showPortfolioBenchmark(showPortfolioBenchmarkTab, userLocale)),
    },
    {
      tabType: CompetitorAnalysis,
      tabTitle: upperFirstOnly(t('widgets.analytics.competitorAnalysis')),
      enabled: data?.company.competitorPriceAnalysisEnabled,
    },
  ]

  const setTabActiveState = (tabType) => {
    setActiveTab(tabType)
  }

  const enabledTabs = () => {
    if (!data?.company) {
      return []
    }

    return mainData?.me.isEdgeSuperuser ? tabs : tabs.filter(tab => tab.enabled)
  }

  return (
    <Nav className="flex-row" activeKey={0}>
      {enabledTabs().map(({ tabType, tabTitle }, i ) => {
          const currentTabActive = activeTab === tabType
          const firstTab = i === 0

          const tabNavClass = cx({
            'nav-tab': !currentTabActive && !firstTab,
            'nav-tab-first-tab': !currentTabActive && firstTab,
            'nav-tab-active': currentTabActive && !firstTab,
            'nav-tab-active-first-tab': currentTabActive && firstTab,
          })

          return (
            <Nav.Item
              key={`${tabTitle}-${i}`}
              className={tabNavClass}
              onClick={() => setTabActiveState(tabType)}
              data-testid={`${tabType}-tab${currentTabActive ? '-active' : ''}`}
            >
              <div className={cx('tab-nav-metric-title')}>{tabTitle}</div>
            </Nav.Item>
          )
        }
      )}
    </Nav>
  )
}

TabNavs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
}

export { TabNavs }
