import React from 'react'
import PropTypes from 'prop-types'
import { PricingTypes } from 'utils/constants'
import classNames from 'classnames/bind'
import { PendingChangeRequest } from './components/pending-change-request'
import { PricingTypeBadge } from '../pricing-type-badge'
import styles from './PendingChangeRequests.module.scss'

const cx = classNames.bind(styles)

const { ALL, CASH, CREDIT } = PricingTypes

const renderPendingChangeRequests = (
  fuelGrades,
  pendingRequests,
  pricingType
) => {
  const pricingTypeRequests = pendingRequests.filter(
    pendingRequest => pendingRequest.pricingType === pricingType
  )

  return (
    <div className={cx({ 'no-cc': pricingType === ALL })}>
      <PricingTypeBadge pricingType={pricingType} />
      {fuelGrades.map(({ id }) => {
        const pendingRequest = pricingTypeRequests.find(
          pricingTypeRequest => pricingTypeRequest.fuelGradeId === id
        )
        return <PendingChangeRequest key={id} pendingRequest={pendingRequest} />
      })}
    </div>
  )
}

const PendingChangeRequests = ({
  cashCreditPricing,
  fuelGrades,
  pendingRequests,
}) => {
  const content = cashCreditPricing ? (
    <>
      {renderPendingChangeRequests(fuelGrades, pendingRequests, CASH)}
      {renderPendingChangeRequests(fuelGrades, pendingRequests, CREDIT)}
    </>
  ) : (
    renderPendingChangeRequests(fuelGrades, pendingRequests, ALL)
  )

  return <div className={cx('pending-change-requests')}>{content}</div>
}

PendingChangeRequests.propTypes = {
  cashCreditPricing: PropTypes.bool.isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pendingRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { PendingChangeRequests }
