import moment from 'moment'

const xTicks = (min, max) => {
  let ticks = []
  const diff = max - min
  const day = 24 * 3600_000

  if (diff === 0) {
    ticks.push(min)
    return ticks
  } else if (diff < 31 * day) {
    const days = diff / day
    const divisors = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    let tickCount
    for (let i = divisors.length - 1; i >= 0; i--) {
      if (days % divisors[i] === 0) {
        tickCount = divisors[i]
        break
      }
    }

    const incr = Math.min(day * 3, diff / tickCount)

    for (let j = min; j <= max; j += incr) {
      ticks.push(j)
    }
    ticks.push(max)
    return Array.from(new Set(ticks))
  } else {
    const incr = diff < 5 * 31 * day ? 7 : 14
    const start = moment
      .utc(min)
      .startOf('month')
      .toDate()
      .getTime()

    for (let i = start; i <= max; i += incr * day) {
      if (
        !moment
          .utc(i)
          .add(incr, 'day')
          .isSame(i, 'month')
      ) {
        i = moment
          .utc(i)
          .add(1, 'month')
          .startOf('month')
          .toDate()
          .getTime()
      }
      /* istanbul ignore else */
      if (i >= min && i <= max) {
        ticks.push(i)
      }
    }
    return ticks
  }
}

const yTicks = (minY, maxY, incrY) => {
  let ticks = []

  for (let i = minY + incrY; i <= maxY; i += incrY) {
    ticks.push(i)
  }
  return Array.from(ticks)
}

const dateData = (date, lineData) =>
  lineData
    .map(data => {
      return data.values.filter(graphMetricData => {
        const dDate = new Date(graphMetricData.date)
        return (
          dDate.getFullYear() === date.getFullYear() &&
          dDate.getMonth() === date.getMonth() &&
          dDate.getDate() === date.getDate()
        )
      })
    })
    .flat()

const buildLineData = (metrics, data, previousLine) =>
  metrics.length === data.length
    ? metrics
        .map((m, i) => {
          const currentPeriodLineData = {
            metricToGraph: m,
            previousLine: false,
            values: data[i].map((d, i) => ({
              index: i + 1,
              ...d,
              previousLine: false,
            })),
          }

          const previousPeriodLineData = {
            metricToGraph: m,
            previousLine,
            values: data[i].map((d, i) => ({
              index: i + 1,
              ...d,
              previousLine,
            })),
          }

          return previousLine
            ? [currentPeriodLineData, previousPeriodLineData]
            : currentPeriodLineData
        })
        .flat()
    : []

const getIncrement = (maxY, minY) => {
  const scaleY = Math.floor(Math.log10(Math.max(1, maxY - minY)))
  // increment-y is 10^scaleY
  let incrY = Math.pow(10, scaleY)
  // but it might be better to have 0.25 or 0.5 increments, so check which of
  // these best aligns to 10 ticks
  const distanceY = incrY => Math.abs(10 - (maxY - minY) / incrY)
  const tickYCandidates = [
    { incr: incrY, distance: distanceY(incrY) },
    { incr: incrY / 2, distance: distanceY(incrY / 2) },
    { incr: incrY / 4, distance: distanceY(incrY / 4) },
  ]
  tickYCandidates.sort((a, b) => a.distance - b.distance)
  return tickYCandidates[0].incr
}

export { xTicks, yTicks, dateData, buildLineData, getIncrement }
