import React from 'react'
import classNames from 'classnames/bind'
import { Copyright } from 'components/main/components/copyright'
import { ReactComponent as Logo } from 'images/common/edge-logo-dark.svg'
import { ReactComponent as Warning } from 'images/common/warning.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './InvalidResetLink.module.scss'

const cx = classNames.bind(styles)

const InvalidResetLink = () => {
  const { t } = useTranslation('auth')

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>

        <Logo className={cx('logo')} />
        <Warning className={cx('warning-icon')} />

        <p className={cx('help-text')}>{t('invalidLinkHeader')}</p>
        <p className={cx('help-text')}>{t('invalidLinkText')}</p>

        <div className={cx('links-wrapper')}>
          <Link to={"/forgot-password"} className={cx('link')} >{t('forgotPassword')}</Link>
          <Link to={"/login"} className={cx('link')}>{t('login')}</Link>
        </div>
      </div>
      <Copyright className={cx('copyright')} />
    </div>
  )
}

export { InvalidResetLink }
