import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useTranslation } from 'react-i18next'
import { StationStatus, StationDataTypes } from 'utils/constants'
import { StatusInformation } from 'components/common/status-information'
import styles from './StationStatusItem.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { useFlags } from 'launchdarkly-react-client-sdk'

const cx = classNames.bind(styles)

const { CLOSED } = StationStatus
const { COST_PRICE, DIP, TRANSACTION } = StationDataTypes

const StationStatusItemTranslations = {
  COST_PRICE: {
    title: 'widgets.stationStatus.costPrice',
  },
  DIP: {
    title: 'widgets.stationStatus.dip',
  },
  TRANSACTION: {
    title: 'widgets.stationStatus.transaction',
  },
}

const parseStatusAndTimestamp = (type, data, showCostPriceStatus) => {
  const { stationsStatus } = data
  const {
    stationStatus,
    dipStatus: { status: dipStatus, lastDipAt },
    costPriceStatus: { status: costPriceStatus, lastCostPriceAt },
    transactionStatus: { status: transactionStatus, lastTransactionAt },
  } = stationsStatus[0]

  switch (type) {
    case DIP:
      return { stationStatus, status: dipStatus, timestamp: lastDipAt }
    case COST_PRICE:
      return {
        stationStatus,
        status: showCostPriceStatus && costPriceStatus,
        timestamp: lastCostPriceAt,
      }
    case TRANSACTION:
      return {
        stationStatus,
        status: transactionStatus,
        timestamp: lastTransactionAt,
      }
    /* istanbul ignore next */
    default:
      throw new Error(`Unhandled status type: ${type}`)
  }
}

const StationStatusItem = ({ data, error, type }) => {
  const { t } = useTranslation()
  const { showCostPriceStatus } = useFlags()

  const translations = StationStatusItemTranslations[type]

  if (!data || error) {
    return (
      <div className={cx('station-status-item')}>
        <div className={cx('status-indicator-wrapper')}>
          <div className={cx('status-indicator')} />
        </div>
        {t(translations.title)}
      </div>
    )
  }

  const { stationStatus, status, timestamp } = parseStatusAndTimestamp(
    type,
    data,
    showCostPriceStatus
  )

  const statusClass =
    type === TRANSACTION && stationStatus === CLOSED ? 'black' : status

  const overlay = (
    <Tooltip className={cx('status-tooltip')}>
      <div className={cx('content')}>
        <h3>{t('widgets.stationStatus.heading')}</h3>
        <StatusInformation
          type={type}
          stationStatus={stationStatus}
          status={status}
          timestamp={timestamp}
        />
      </div>
    </Tooltip>
  )

  const onEnter = () => {
    amplitudeEvent(UserEvents.StationStatusIndicator[type])
  }

  // TODO: update once EDG-3570 completed
  return (
    <OverlayTrigger placement="bottom" overlay={overlay} onEnter={onEnter}>
      <div className={cx('station-status-item')}>
        <div className={cx('status-indicator-wrapper')}>
          <div className={cx('status-indicator', statusClass)} />
        </div>
        {t(translations.title)}
      </div>
    </OverlayTrigger>
  )
}

StationStatusItem.propTypes = {
  error: PropTypes.shape(),
  data: PropTypes.shape(),
  type: PropTypes.oneOf([TRANSACTION, COST_PRICE, DIP]).isRequired,
}

export { StationStatusItem }
