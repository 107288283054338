import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import classNames from 'classnames/bind'
import styles from './ChangeRequest.module.scss'

const cx = classNames.bind(styles)

const ChangeRequest = ({ changeRequest }) => {
  const { t } = useTranslation()
  const { formatPolePrice } = useFormatPolePrice()

  return (
    <div className={cx('change-request')}>
      <div>{formatPolePrice(changeRequest?.newPrice)}</div>
      <div>{changeRequest && t('widgets.stationsListPricing.pending')}</div>
    </div>
  )
}

ChangeRequest.propTypes = {
  changeRequest: PropTypes.shape(),
}

ChangeRequest.defaultProps = {
  changeRequest: null,
}

export { ChangeRequest }
