import { useQuery, gql } from '@apollo/client'

export const COMPANY_TIME_ZONE_QUERY = gql`
  query companyTimeZoneQuery {
    companyTimeZone @client
  }
`

function useCompanyTimeZone() {
  const { data, error } = useQuery(COMPANY_TIME_ZONE_QUERY)

  if (error || !data?.companyTimeZone) {
    throw Error('company time zone should always be set')
  }

  const { companyTimeZone } = data
  return { companyTimeZone }
}

export { useCompanyTimeZone }
