import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { usePriceChange } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import {
  PriceChangeStatus,
  PriceChangeActions,
} from 'components/station/components/competitor-pricing/context/constants'
import { ActionAtSelector } from 'components/common/price-change-footer/components/action-at-selector'
import { Reminders } from 'components/common/price-change-footer/components/reminders'
import { PriceChangeTimeout } from 'utils/pricing/constants'
import classNames from 'classnames/bind'
import { useCreatePolePriceChangeRequests } from './hooks/useCreatePolePriceChangeRequests'
import { ProgressBar } from './components/progress-bar'
import { Cover } from './components/cover'
import { StatusIndicator } from './components/status-indicator'
import styles from './PriceChangeFooter.module.scss'

const cx = classNames.bind(styles)

const onError = dispatch => () => {
  dispatch({
    type: PriceChangeActions.SetCurrentStatus,
    payload: { status: PriceChangeStatus.Error },
  })
}

const onCompleted = dispatch => ({ createPolePriceChangeRequests }) => {
  const status = createPolePriceChangeRequests.some(({ error }) => error)
    ? PriceChangeStatus.Error
    : PriceChangeStatus.Sent
  dispatch({
    type: PriceChangeActions.SetCurrentStatus,
    payload: { status },
  })
}

const PriceChangeFooter = ({
  stationId,
  stationName,
  setDateTimeSelectorInUse,
}) => {
  const [state, dispatch] = usePriceChange()
  const { t } = useTranslation()
  const [
    createPolePriceChangeRequests,
    loading,
    called,
  ] = useCreatePolePriceChangeRequests(
    stationId,
    onError(dispatch),
    onCompleted(dispatch)
  )

  const {
    status,
    reminders,
    actionAt: { selectedPeriod, selectedDate, selectedTime },
  } = state

  useEffect(() => {
    let timer

    if (status === PriceChangeStatus.Send && !called) {
      timer = setTimeout(() => {
        createPolePriceChangeRequests()
      }, PriceChangeTimeout)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [status, called, createPolePriceChangeRequests])

  const handleReminderToggle = reminder => {
    /* istanbul ignore next */
    if (!loading) {
      dispatch({
        type: PriceChangeActions.ToggleReminder,
        payload: { reminder },
      })
    }
  }

  const handleConfirmClick = (selectedPeriod, selectedDate, selectedTime) => {
    dispatch({
      type: PriceChangeActions.SetActionAt,
      payload: { selectedPeriod, selectedDate, selectedTime },
    })
  }

  return (
    <div className={cx('price-change-footer')}>
      <ProgressBar status={status} />
      <Cover status={status} />
      <div className={cx('main-content')}>
        <ActionAtSelector
          selectedPeriod={selectedPeriod}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          onConfirmClick={handleConfirmClick}
        />
        <Reminders
          reminders={reminders}
          onReminderToggle={handleReminderToggle}
        />
      </div>
      <div className={cx('secondary-content')}>
        <div className={cx('station')}>
          <span>{t('widgets.competitorPricing.station')}</span>
          <span className={cx('station-name')}>{stationName}</span>
        </div>
        <StatusIndicator
          loading={loading}
          setDateTimeSelectorInUse={setDateTimeSelectorInUse}
        />
      </div>
    </div>
  )
}

PriceChangeFooter.propTypes = {
  stationId: PropTypes.string.isRequired,
  stationName: PropTypes.string.isRequired,
  setDateTimeSelectorInUse: PropTypes.func.isRequired,
}

export { PriceChangeFooter }
