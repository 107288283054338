import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Info } from 'components/common/info'
import { Toggle } from 'components/common/toggle'
import classNames from 'classnames/bind'
import styles from './Reminders.module.scss'

const cx = classNames.bind(styles)

const Reminders = ({ reminders, onReminderToggle }) => {
  const { t } = useTranslation()

  const { threeHoursAfter, oneHourAfter } = reminders

  return (
    <div className={cx('reminders')}>
      <div className={cx('title')}>
        <span>
          {t('widgets.competitorPricing.sendPolePriceChangeReminders')}
        </span>
        <Info
          tooltipText={t(
            'widgets.competitorPricing.sendPolePriceChangeRemindersTooltip'
          )}
        />
      </div>
      <div className={cx('toggles')}>
        <Toggle
          id="one-hour-after-toggle"
          label={t('widgets.competitorPricing.oneAfterActioned')}
          checked={oneHourAfter}
          handleToggle={() => onReminderToggle('oneHourAfter')}
        />
        <Toggle
          id="three-hours-after-toggle"
          label={t(
            'widgets.competitorPricing.threeHoursAfterChangesShouldBeMade'
          )}
          checked={threeHoursAfter}
          handleToggle={() => onReminderToggle('threeHoursAfter')}
        />
      </div>
    </div>
  )
}

Reminders.propTypes = {
  reminders: PropTypes.shape({
    oneHourAfter: PropTypes.bool.isRequired,
    threeHoursAfter: PropTypes.bool.isRequired,
  }).isRequired,
  onReminderToggle: PropTypes.func.isRequired,
}

export { Reminders }
