import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import { useReactiveVar } from '@apollo/client'
import { isNullOrUndefined } from 'utils/helpers'
import { PricingTypes } from 'utils/constants'
import { Dropdown } from 'components/common/dropdown'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { useCreateCompetitorPrices } from 'components/station/components/competitor-pricing/hooks/useCreateCompetitorPrices'
import {
  determineTimeIntervals,
  parsePolePriceTimestamps,
} from 'components/station/components/competitor-pricing/utils/helpers'
import { ReactComponent as EditCancelIcon } from 'images/common/cancel.svg'
import { timestampVar } from 'config/apollo/competitor-pricing/variables'
import classNames from 'classnames/bind'
import { SinglePriceInput } from './components/single-price-input'
import { CashCreditPriceInputs } from './components/cash-credit-price-inputs'
import { SinglePriceSelector } from './components/single-price-selector'
import styles from './EditPricesForm.module.scss'

const { ALL, CASH, CREDIT } = PricingTypes

const cx = classNames.bind(styles)

const validPrice = (
  time,
  isSinglePrice,
  newPrice,
  newCashPrice,
  newCreditPrice,
  validatePolePrice
) => {
  if (isNullOrUndefined(time)) {
    return false
  }

  if (isSinglePrice) {
    return validatePolePrice(newPrice)
  }

  return validatePolePrice(newCashPrice) || validatePolePrice(newCreditPrice)
}

const newPolePrice = (pricingType, price, data) => ({
  pricingType,
  price: parseFloat(price),
  ...data,
})

const parseNewPolePrices = (
  isSinglePrice,
  newPrice,
  newCashPrice,
  newCreditPrice,
  sharedPolePriceData,
  validatePolePrice
) => {
  if (isSinglePrice) {
    return [newPolePrice(ALL, newPrice, sharedPolePriceData)]
  }

  const polePrices = []
  if (validatePolePrice(newCashPrice)) {
    polePrices.push(newPolePrice(CASH, newCashPrice, sharedPolePriceData))
  }
  if (validatePolePrice(newCreditPrice)) {
    polePrices.push(newPolePrice(CREDIT, newCreditPrice, sharedPolePriceData))
  }
  return polePrices
}

const EditPricesForm = ({
  stationId,
  competitorId,
  fuelGradeId,
  allPrice,
  cashPrice,
  creditPrice,
  onClosePopover,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { companyTimeZone } = useCompanyTimeZone()
  const { validatePolePrice } = useFormatPolePrice()
  const [time, setTime] = useState()
  const [isSinglePrice, setIsSinglePrice] = useState(false)
  const [newPrice, setNewPrice] = useState('')
  const [newCashPrice, setNewCashPrice] = useState('')
  const [newCreditPrice, setNewCreditPrice] = useState('')
  const [createPrices, { loading }] = useCreateCompetitorPrices(
    stationId,
    competitorId,
    onClosePopover
  )
  const timestamp = useReactiveVar(timestampVar)

  const hasValidPrice = validPrice(
    time,
    isSinglePrice,
    newPrice,
    newCashPrice,
    newCreditPrice,
    validatePolePrice
  )

  const handleSinglePriceClick = () => {
    setIsSinglePrice(!isSinglePrice)
  }

  const handleSaveClick = () => {
    if (loading | !hasValidPrice) {
      return
    }

    const [priceAt, priceUpdatedAt] = parsePolePriceTimestamps(
      companyTimeZone,
      time,
      timestamp
    )

    const sharedPolePriceData = {
      fuelGradeId,
      priceAt,
      priceUpdatedAt,
      stationId: competitorId,
    }

    const polePrices = parseNewPolePrices(
      isSinglePrice,
      newPrice,
      newCashPrice,
      newCreditPrice,
      sharedPolePriceData,
      validatePolePrice
    )
    createPrices(polePrices)
  }

  const items = determineTimeIntervals(timestamp, companyTimeZone, t, language)

  return (
    <div className={cx('prices-form')}>
      <EditCancelIcon
        className={cx('cancel-icon')}
        onClick={onClosePopover}
        data-testid="cancel-icon"
      />
      <Dropdown
        selectedItem={time}
        items={items}
        onValueSelect={setTime}
        customClass={cx('time-dropdown')}
        placeholder={t('widgets.competitorPricing.from')}
        maxHeight="310px"
      />
      {isSinglePrice ? (
        <SinglePriceInput
          allPrice={allPrice}
          newPrice={newPrice}
          onValueChange={setNewPrice}
        />
      ) : (
        <CashCreditPriceInputs
          cashPrice={cashPrice}
          creditPrice={creditPrice}
          newCashPrice={newCashPrice}
          newCreditPrice={newCreditPrice}
          onCashValueChange={setNewCashPrice}
          onCreditValueChange={setNewCreditPrice}
        />
      )}
      <SinglePriceSelector
        isSinglePrice={isSinglePrice}
        onClick={handleSinglePriceClick}
      />
      <button
        className={cx('btn', { disabled: loading || !hasValidPrice })}
        onClick={handleSaveClick}
      >
        {loading ? (
          <Spinner animation="border" variant="light" />
        ) : (
          t('widgets.competitorPricing.save')
        )}
      </button>
    </div>
  )
}

EditPricesForm.propTypes = {
  stationId: PropTypes.string.isRequired,
  competitorId: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  allPrice: PropTypes.shape(),
  cashPrice: PropTypes.shape(),
  creditPrice: PropTypes.shape(),
  onClosePopover: PropTypes.func.isRequired,
}

EditPricesForm.defaultProps = {
  allPrice: null,
  cashPrice: null,
  creditPrice: null,
}

export { EditPricesForm }
