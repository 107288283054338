import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NotificationsIcon } from 'images/header/notifications.svg'
import classNames from 'classnames/bind'
import styles from './NoNewNotifications.module.scss'

const cx = classNames.bind(styles)

const NoNewNotifications = () => {
  const { t } = useTranslation()
  return (
    <div className={cx('container')}>
      <span>
        <NotificationsIcon height={20} width={20} />
      </span>
      <h2>{t('widgets.notifications.noNewNotifications')}</h2>
      <p>{t('widgets.notifications.whenYouReceiveNotifications')}</p>
    </div>
  )
}

export { NoNewNotifications }
