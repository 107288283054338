import React from 'react'
import PropTypes from 'prop-types'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import styles from './Prices.module.scss'

const cx = classNames.bind(styles)

const renderPrice = (
  pricingTypeCopy,
  priceChangeRequest,
  pricingTypePrice,
  formatPolePrice
) => {
  if (
    isNullOrUndefined(priceChangeRequest) ||
    isNullOrUndefined(pricingTypePrice)
  ) {
    return (
      <>
        <div className={cx('pricing-type')}>{pricingTypeCopy}</div>
        <div className={cx('price')}>
          {formatPolePrice(pricingTypePrice?.price)}
        </div>
      </>
    )
  }

  return (
    <>
      <div className={cx('current-price')}>
        {formatPolePrice(pricingTypePrice.price)}
      </div>
      <div className={cx('new-price')}>
        {formatPolePrice(priceChangeRequest.newPrice)}
      </div>
    </>
  )
}

const Prices = ({
  cashPrice,
  creditPrice,
  cashPriceChangeRequest,
  creditPriceChangeRequest,
}) => {
  const { formatPolePrice } = useFormatPolePrice()

  return (
    <>
      <span className={cx('cash-price')}>
        {renderPrice(
          'Cash',
          cashPriceChangeRequest,
          cashPrice,
          formatPolePrice
        )}
      </span>
      <span className={cx('divider')} />
      <span className={cx('credit-price')}>
        {renderPrice(
          'Credit',
          creditPriceChangeRequest,
          creditPrice,
          formatPolePrice
        )}
      </span>
    </>
  )
}

Prices.propTypes = {
  cashPrice: PropTypes.shape(),
  creditPrice: PropTypes.shape(),
  cashPriceChangeRequest: PropTypes.shape(),
  creditPriceChangeRequest: PropTypes.shape(),
}

Prices.defaultProps = {
  cashPrice: null,
  creditPrice: null,
  cashPriceChangeRequest: null,
  creditPriceChangeRequest: null,
}

export { Prices }
