import React from 'react'
import { PropTypes } from 'prop-types'
import { StationDataTypes } from 'utils/constants'
import { useStationStatusQuery } from 'components/common/hooks/useStationStatusQuery'
import classNames from 'classnames/bind'
import { StationStatusItem } from './components/station-status-item'
import styles from './StationStatus.module.scss'

const cx = classNames.bind(styles)

const { COST_PRICE, DIP, TRANSACTION } = StationDataTypes

const StationStatus = ({ stationId }) => {
  const { error, data } = useStationStatusQuery(stationId)

  return (
    <div className={cx('station-status')}>
      <StationStatusItem type={TRANSACTION} data={data} error={error} />
      <StationStatusItem type={DIP} data={data} error={error} />
      <StationStatusItem type={COST_PRICE} data={data} error={error} />
    </div>
  )
}

StationStatus.propTypes = {
  stationId: PropTypes.string.isRequired,
}

export { StationStatus }
