import { useMutation, useReactiveVar } from '@apollo/client'
import { COMPETITOR_PRICING_QUERY } from 'components/station/components/competitor-pricing/CompetitorPricingQueries'
import { usePriceChangeState } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { timestampVar } from 'config/apollo/competitor-pricing/variables'
import { CREATE_POLE_PRICE_CHANGE_REQUESTS_MUTATION } from 'config/apollo/mutations'
import { parseToActionAt } from 'utils/pricing'

const update = (stationId, supportedPricingTypes, timestamp) => (
  cache,
  { data: { createPolePriceChangeRequests } }
) => {
  const [stationResponse] = createPolePriceChangeRequests
  const { error, polePriceChangeRequests } = stationResponse
  /* istanbul ignore next */
  if (error) {
    return
  }

  const variables = {
    stationId,
    supportedPricingTypes,
    timestamp,
  }
  const { station } = cache.readQuery({
    query: COMPETITOR_PRICING_QUERY,
    variables,
  })

  let updatedPolePriceChangeRequests = [...station.polePriceChangeRequests]
  for (const polePriceChangeRequest of polePriceChangeRequests) {
    // remove a price change request if the same pricing type/grade
    const filteredRequests = updatedPolePriceChangeRequests.filter(
      ({ pricingType, fuelGradeId }) =>
        pricingType !== polePriceChangeRequest.pricingType ||
        fuelGradeId !== polePriceChangeRequest.fuelGradeId
    )
    updatedPolePriceChangeRequests = [
      ...filteredRequests,
      polePriceChangeRequest,
    ]
  }

  cache.writeQuery({
    query: COMPETITOR_PRICING_QUERY,
    variables,
    data: {
      station: {
        ...station,
        polePriceChangeRequests: updatedPolePriceChangeRequests,
      },
    },
  })
}

function useCreatePolePriceChangeRequests(stationId, onError, onCompleted) {
  const { companyTimeZone } = useCompanyTimeZone()
  const { supportedPricingTypes } = usePricingTypes()
  const { pendingRequests, reminders, actionAt } = usePriceChangeState()
  const timestamp = useReactiveVar(timestampVar)
  const [mutation, { loading, called }] = useMutation(
    CREATE_POLE_PRICE_CHANGE_REQUESTS_MUTATION,
    {
      update: update(stationId, supportedPricingTypes, timestamp),
      onError,
      onCompleted,
    }
  )

  const createPolePriceChangeRequests = () => {
    const polePriceChangeRequests = pendingRequests
      .filter(({ price, newPrice }) => price !== newPrice)
      .map(({ newPrice, price, fuelGradeId, pricingType }) => ({
        newPrice,
        price,
        fuelGradeId,
        pricingType,
      }))

    const toActionAt = parseToActionAt(actionAt, companyTimeZone)

    const stationPolePriceChangeRequests = [
      {
        toActionAt,
        stationId,
        polePriceChangeRequests,
      },
    ]

    return mutation({
      variables: {
        stationPolePriceChangeRequests,
        reminders,
      },
    })
  }

  return [createPolePriceChangeRequests, loading, called]
}

export { useCreatePolePriceChangeRequests }
