import React from 'react'
import classNames from 'classnames/bind'
import styles from './MonthIcon.module.scss'
import { PropTypes } from 'prop-types'

const cx = classNames.bind(styles)

const MonthIcon = ({ month, day }) => {
  return (
    <div className={cx('month-icon-container')}>
      <div className={cx('month-icon-month')}>{month}</div>
      <div className={cx('month-icon-day')}>{day}</div>
    </div>
  )
}

MonthIcon.propTypes = {
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
}

export { MonthIcon }
