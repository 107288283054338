import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { useTranslation } from 'react-i18next'
import { createDateRangeFromNow, getDaysFromDateRange, isFirstDayOf } from 'utils/dateTime'
import { upperFirstOnly } from 'utils/format'
import { Dropdown } from 'components/common/dropdown'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { PeriodSelector } from '../../../../common/performance-report/components/period-selector'
import styles from './PeriodDropdown.module.scss'
import classNames from 'classnames/bind'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { currentPeriodDateVar, previousPeriodDateVar } from 'config/apollo/analytics-report/variables'
import { PortfolioBenchmark, PeriodAnalysis, CompetitorAnalysis } from 'utils/constants'

const cx = classNames.bind(styles)

export const LAST_7_DAYS = 0
export const LAST_7_DAYS_VS_PREVIOUS_7_DAYS = 1
export const LAST_30_DAYS = 2
export const LAST_30_DAYS_VS_PREVIOUS_30_DAYS = 3
export const CUSTOM_PERIOD = 4
export const CURRENT_MONTH_VS_LAST_MONTH = 5
export const CURRENT_QUARTER_VS_LAST_QUARTER = 6
export const CURRENT_YEAR_VS_LAST_YEAR = 7
export const CURRENT_MONTH = 8
export const CURRENT_QUARTER = 9
export const CURRENT_YEAR = 10
export const PREVIOUS_MONTH = 11
export const PREVIOUS_QUARTER = 12
export const PREVIOUS_YEAR = 13

const itemsConfig = (tab, t, companyTimeZone) => {
  return tab === PeriodAnalysis ? 
    [
      { item: LAST_7_DAYS_VS_PREVIOUS_7_DAYS, displayItem: upperFirstOnly(t('widgets.performanceReport.last7DaysVsPrevious7Days')) },
      { item: LAST_30_DAYS_VS_PREVIOUS_30_DAYS, displayItem: upperFirstOnly(t('widgets.performanceReport.last30DaysVsPrevious30Days')) },
      { item: CURRENT_MONTH_VS_LAST_MONTH, displayItem: upperFirstOnly(t('widgets.performanceReport.currentMonthVslastMonth')), disabled: isFirstDayOf('month', companyTimeZone) },
      { item: CURRENT_QUARTER_VS_LAST_QUARTER, displayItem: upperFirstOnly(t('widgets.performanceReport.currentQuarterVsLastQuarter')), disabled: isFirstDayOf('quarter', companyTimeZone) },
      { item: CURRENT_YEAR_VS_LAST_YEAR, displayItem: upperFirstOnly(t('widgets.performanceReport.currentYearVslastYear')), disabled: isFirstDayOf('year', companyTimeZone) },
      { item: CUSTOM_PERIOD, displayItem: upperFirstOnly(t('widgets.performanceReport.customPeriod')) },
    ]
  : [
      { item: LAST_7_DAYS, displayItem: upperFirstOnly(t('widgets.performanceReport.last7Days')) },
      { item: LAST_30_DAYS, displayItem: upperFirstOnly(t('widgets.performanceReport.last30Days')) },
      { item: CURRENT_MONTH, displayItem: upperFirstOnly(t('widgets.performanceReport.currentMonth')), disabled: isFirstDayOf('month', companyTimeZone) },
      { item: CURRENT_QUARTER, displayItem: upperFirstOnly(t('widgets.performanceReport.currentQuarter')), disabled: isFirstDayOf('quarter', companyTimeZone) },
      { item: CURRENT_YEAR, displayItem: upperFirstOnly(t('widgets.performanceReport.currentYear')), disabled: isFirstDayOf('year', companyTimeZone) },
      { item: PREVIOUS_MONTH, displayItem: upperFirstOnly(t('widgets.performanceReport.previousMonth')) },
      { item: PREVIOUS_QUARTER, displayItem: upperFirstOnly(t('widgets.performanceReport.previousQuarter')) },
      { item: PREVIOUS_YEAR, displayItem: upperFirstOnly(t('widgets.performanceReport.previousYear')) },
      { item: CUSTOM_PERIOD, displayItem: upperFirstOnly(t('widgets.performanceReport.customPeriod')) },
    ]
}

const getDateDifferences = (periodType) => {
  const today = new Date();
  const yesterday = new Date(today);

  let startDate, endDate, prevStartDate, prevEndDate;

  switch (periodType) {
    case "month":
      const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      yesterday.setDate(yesterday.getDate() - 1);
      startDate = firstDayOfCurrentMonth;
      endDate = yesterday;
      
      const previousMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
      const previousYear = today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear();
      const firstDayOfPreviousMonth = new Date(previousYear, previousMonth, 1);
      const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      prevStartDate = firstDayOfPreviousMonth;
      prevEndDate = lastDayOfPreviousMonth;
      break;
    
    case "quarter":
      const currentMonth = today.getMonth();
      let currentQuarter = Math.floor(currentMonth / 3) + 1;
      let year = today.getFullYear();
      let previousQuarter = currentQuarter - 1;
      if (previousQuarter === 0) {
        previousQuarter = 4;
        year--;
      }
      
      startDate = new Date(today.getFullYear(), (currentQuarter - 1) * 3, 1);
      yesterday.setDate(yesterday.getDate() - 1);
      endDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
      
      prevStartDate = new Date(year, (previousQuarter - 1) * 3, 1);
      prevEndDate = new Date(year, previousQuarter * 3, 0);
      break;
    
    case "year":
      startDate = new Date(today.getFullYear(), 0, 1);
      yesterday.setDate(yesterday.getDate() - 1);
      endDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
      
      prevStartDate = new Date(today.getFullYear() - 1, 0, 1);
      prevEndDate = new Date(today.getFullYear() - 1, 11, 31);
      break;
    
    default:
      startDate = today;
      endDate = today;
      prevStartDate = today;
      prevEndDate = today;
  }

  const currentStartDiff = Math.floor((today - startDate) / (1000 * 60 * 60 * 24));
  const currentEndDiff = Math.floor((today - endDate) / (1000 * 60 * 60 * 24));
  const prevStartDiff = Math.floor((today - prevStartDate) / (1000 * 60 * 60 * 24));
  const prevEndDiff = Math.floor((today - prevEndDate) / (1000 * 60 * 60 * 24));

  return [-currentStartDiff, -currentEndDiff, -prevStartDiff, -prevEndDiff];
}

const resolveCurrentPeriod = (currentPeriodDate, previousPeriodDate, tab, currentPeriodDropdownLabel) => {
  const currentPeriodDateDays = getDaysFromDateRange(currentPeriodDate[tab])
  const previousPeriodDateDays = getDaysFromDateRange(previousPeriodDate[tab])

  const isLast7Days = currentPeriodDateDays.from === -7 && currentPeriodDateDays.to === -1
  const isPrevious7Days = previousPeriodDateDays.from === -14 && previousPeriodDateDays.to === -8

  const isLast30Days = currentPeriodDateDays.from === -30 && currentPeriodDateDays.to === -1
  const isPrevious30Days = previousPeriodDateDays.from === -60 && previousPeriodDateDays.to === -31

  const monthDays = getDateDifferences("month")
  const quarterDays = getDateDifferences("quarter")
  const yearDays = getDateDifferences("year")

  const isMonthVsMonth = currentPeriodDateDays.from === monthDays[0] && currentPeriodDateDays.to === monthDays[1] &&
                         previousPeriodDateDays?.from === monthDays[2] && previousPeriodDateDays?.to === monthDays[3]
  const isQuarterVsQuarter = currentPeriodDateDays.from === quarterDays[0] && currentPeriodDateDays.to === quarterDays[1] &&
                             previousPeriodDateDays?.from === quarterDays[2] && previousPeriodDateDays?.to === quarterDays[3]
  const isYearVsYear = currentPeriodDateDays.from === yearDays[0] && currentPeriodDateDays.to === yearDays[1] &&
                       previousPeriodDateDays?.from === yearDays[2] && previousPeriodDateDays?.to === yearDays[3]
  const isMonth = currentPeriodDateDays.from === monthDays[0] && currentPeriodDateDays.to === monthDays[1] &&
                  currentPeriodDropdownLabel === "month"
  const isQuarter = currentPeriodDateDays.from === quarterDays[0] && currentPeriodDateDays.to === quarterDays[1] &&
                    currentPeriodDropdownLabel === "quarter"
  const isYear = currentPeriodDateDays.from === yearDays[0] && currentPeriodDateDays.to === yearDays[1] &&
                 currentPeriodDropdownLabel === "year"
  const isPreviousMonth = currentPeriodDateDays.from === monthDays[2] && currentPeriodDateDays.to === monthDays[3]
  const isPreviousQuarter = currentPeriodDateDays.from === quarterDays[2] && currentPeriodDateDays.to === quarterDays[3]
  const isPreviousYear = currentPeriodDateDays.from === yearDays[2] && currentPeriodDateDays.to === yearDays[3]

  switch (tab) {
    case PeriodAnalysis:
      if (isPrevious7Days) {
        return LAST_7_DAYS_VS_PREVIOUS_7_DAYS
      } else if (isPrevious30Days) {
        return LAST_30_DAYS_VS_PREVIOUS_30_DAYS
      } else if (isMonthVsMonth) {
        return CURRENT_MONTH_VS_LAST_MONTH
      } else if (isQuarterVsQuarter) {
        return CURRENT_QUARTER_VS_LAST_QUARTER
      } else if (isYearVsYear) {
        return CURRENT_YEAR_VS_LAST_YEAR
      } else {
        return CUSTOM_PERIOD
      }
    case PortfolioBenchmark:
    case CompetitorAnalysis:
      if (isLast7Days) {
        return LAST_7_DAYS
      } else if (isLast30Days) {
        return LAST_30_DAYS
      } else if (isMonth) {
        return CURRENT_MONTH
      } else if (isQuarter) {
        return CURRENT_QUARTER
      } else if (isYear) {
        return CURRENT_YEAR
      } else if (isPreviousMonth) {
        return PREVIOUS_MONTH
      } else if (isPreviousQuarter) {
        return PREVIOUS_QUARTER
      } else if (isPreviousYear) {
        return PREVIOUS_YEAR
      } else {
        return CUSTOM_PERIOD
      }
    default:
      return
  }
}

const PeriodDropdown = ({ activeTab }) => {
  const { t } = useTranslation(['translations'])
  const { companyTimeZone } = useCompanyTimeZone()
  const selectBox = useRef(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const [closeDropDown, setCloseDropDown] = useState(null)
  const [currentPeriodDate, setCurrentPeriodDate] = useState(currentPeriodDateVar)
  const [previousPeriodDate, setPreviousPeriodDate] = useState(previousPeriodDateVar)

  const items = itemsConfig(activeTab, t, companyTimeZone)
  const dropdownLabel = localStorage.getItem('AnalyticsSelectedPeriodFilter');
  const currentPeriod = resolveCurrentPeriod(currentPeriodDate, previousPeriodDate, activeTab, dropdownLabel)

  useEffect(() => {
    if (currentPeriodDate[activeTab].from) {
      setSelectedCurrentPeriodDate(currentPeriodDate[activeTab])
    } else if (previousPeriodDate[activeTab].from) {
      setSelectedPreviousPeriodDate(previousPeriodDate[activeTab])
    } else {
    currentPeriodDateVar({
      ...currentPeriodDateVar(),
      [activeTab]: createDateRangeFromNow(-7, -1, companyTimeZone)
    })

    setCurrentPeriodDate((prevState) => ({
      ...prevState,
      [activeTab]: createDateRangeFromNow(-7, -1, companyTimeZone)
    }))

    previousPeriodDateVar({
      ...previousPeriodDateVar(),
      [activeTab]: createDateRangeFromNow(-14, -8, companyTimeZone)
    })

    setPreviousPeriodDate((prevState) => ({
      ...prevState,
      [activeTab]: createDateRangeFromNow(-14, -8, companyTimeZone)
    }))
  }
  }, [activeTab])

  const setSelectedCurrentPeriodDate = (selectedPeriod) => {
    currentPeriodDateVar({
      ...currentPeriodDateVar(),
      [activeTab]: selectedPeriod
    })

    setCurrentPeriodDate((prevState) => ({
      ...prevState,
      [activeTab]: selectedPeriod
    }))
  }

  const setSelectedPreviousPeriodDate = (selectedPeriod) => {
    previousPeriodDateVar({
      ...previousPeriodDateVar(),
      [activeTab]: selectedPeriod
    })

    setPreviousPeriodDate((prevState) => ({
      ...prevState,
      [activeTab]: selectedPeriod
    }))
  }

  const handleDropDownClose = () => {
    setShowCalendar(false)
    setCloseDropDown(null)
  }

  const handleItemClick = (event, close) => {
    const target = event.target.querySelector('span')
    const item = items.find(item => item.displayItem === target?.textContent)

    if (!item) return
    if (item.disabled) return

    switch (item.item) {
      case CUSTOM_PERIOD:
        event.preventDefault()
        setShowCalendar(true)
        setCloseDropDown(() => close)
        break
      case LAST_7_DAYS:
      case LAST_7_DAYS_VS_PREVIOUS_7_DAYS:
        setSelectedCurrentPeriodDate(createDateRangeFromNow(-7, -1, companyTimeZone))
        setSelectedPreviousPeriodDate(createDateRangeFromNow(-14, -8, companyTimeZone))
        break
      case LAST_30_DAYS:
      case LAST_30_DAYS_VS_PREVIOUS_30_DAYS:
        setSelectedCurrentPeriodDate(createDateRangeFromNow(-30, -1, companyTimeZone))
        setSelectedPreviousPeriodDate(createDateRangeFromNow(-60, -31, companyTimeZone))
        break
      case CURRENT_MONTH:
      case CURRENT_MONTH_VS_LAST_MONTH:
        const monthDays = getDateDifferences('month')

        localStorage.setItem('AnalyticsSelectedPeriodFilter', 'month')
        setSelectedCurrentPeriodDate(createDateRangeFromNow(monthDays[0], monthDays[1], companyTimeZone))
        setSelectedPreviousPeriodDate(createDateRangeFromNow(monthDays[2], monthDays[3], companyTimeZone))
        break
      case CURRENT_QUARTER:
      case CURRENT_QUARTER_VS_LAST_QUARTER:
        const quarterDays = getDateDifferences('quarter')

        localStorage.setItem('AnalyticsSelectedPeriodFilter', 'quarter')
        setSelectedCurrentPeriodDate(createDateRangeFromNow(quarterDays[0], quarterDays[1], companyTimeZone))
        setSelectedPreviousPeriodDate(createDateRangeFromNow(quarterDays[2], quarterDays[3], companyTimeZone))
        break
      case CURRENT_YEAR:
      case CURRENT_YEAR_VS_LAST_YEAR:
        const yearDays = getDateDifferences('year')

        localStorage.setItem('AnalyticsSelectedPeriodFilter', 'year')
        setSelectedCurrentPeriodDate(createDateRangeFromNow(yearDays[0], yearDays[1], companyTimeZone))
        setSelectedPreviousPeriodDate(createDateRangeFromNow(yearDays[2], yearDays[3], companyTimeZone))
        break
      case PREVIOUS_MONTH:
        const previousMonthDays = getDateDifferences('month')
        setSelectedCurrentPeriodDate(createDateRangeFromNow(previousMonthDays[2], previousMonthDays[3], companyTimeZone))
        break
      case PREVIOUS_QUARTER:
        const previousQuarterDays = getDateDifferences('quarter')
        setSelectedCurrentPeriodDate(createDateRangeFromNow(previousQuarterDays[2], previousQuarterDays[3], companyTimeZone))
        break
      case PREVIOUS_YEAR:
        const previousYearDays = getDateDifferences('year')
        setSelectedCurrentPeriodDate(createDateRangeFromNow(previousYearDays[2], previousYearDays[3], companyTimeZone))
        break
      default:
        break
    }
  }

  const handlePeriodSelectorClose = (currValue, prevValue) => {
    currValue && setSelectedCurrentPeriodDate(currValue)
    prevValue && setSelectedPreviousPeriodDate(prevValue)
    closeDropDown()
  }

  const calculateDaysSince2018 = () => {
    const startDate = new Date('2018-01-01')
    const currentDate = new Date()
    const differenceInTime = currentDate.getTime() - startDate.getTime()
    const differenceInDays = differenceInTime / (1000 * 3600 * 24)
    return Math.floor(differenceInDays)
  }

  return (
    <>
      <Dropdown
        selectedItem={currentPeriod}
        items={items}
        selectedItemDisplay={null}
        onItemClick={handleItemClick}
        onClose={handleDropDownClose}
        itemsRef={selectBox}
        maxHeight={'350px'}
        customClass={cx('period-dropdown')}
      />
      <Overlay
        target={selectBox.current}
        show={showCalendar}
        placement="left-start"
      >
        <Popover className={cx('popover')}>
          <div className={cx('calendar')}>
            <PeriodSelector
              onClose={handlePeriodSelectorClose}
              multi={activeTab === PeriodAnalysis}
              prevValue={previousPeriodDate[activeTab]}
              currValue={currentPeriodDate[activeTab]}
              companyTimeZone={companyTimeZone}
              maxDate={moment.utc().subtract(1, 'days')}
              maxDays={calculateDaysSince2018()}
            />
          </div>
        </Popover>
      </Overlay>
    </>
  )
}

PeriodDropdown.propTypes = {
  activeTab: PropTypes.string.isRequired,
}

export { PeriodDropdown }
