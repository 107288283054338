import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { TrendIndicator } from 'components/common/trend-indicator'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import styles from './Metric.module.scss'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'

const cx = classNames.bind(styles)

const Metric = ({ metric, hasMetricPermission }) => {
  const { formatMetricValue } = useFormatMetricValue()
  const {
    comparativeValue,
    title,
    trend,
    value,
    metricType,
    trendIndicator,
  } = metric
  return (
    <div className={cx('metric-container')}>
      <div className={cx('metric-stretch')}>
        <div>
          <div className={cx('metric-title')}>{title}</div>

          <div
            className={cx('metric-value', {
              'placeholder-margin': !hasMetricPermission,
            })}
          >
            <Permissioned
              permission={hasMetricPermission}
              fallback={<Placeholder fixedWidth={50} />}
            >
              {formatMetricValue(metricType, value)}
            </Permissioned>
          </div>
        </div>
        <div className={cx('metric-trend')}>
          <Permissioned
            permission={hasMetricPermission}
            fallback={<Placeholder fixedWidth={20} />}
          >
            {trendIndicator && (
              <TrendIndicator
                trend={trend}
                tooltip={true}
                comparativeType={metricType}
                tooltipPlacement={'top'}
                comparative={comparativeValue}
                comparativePeriod={trendIndicator}
              />
            )}
          </Permissioned>
        </div>
      </div>
    </div>
  )
}

Metric.propTypes = {
  metric: PropTypes.shape().isRequired,
  hasMetricPermission: PropTypes.bool.isRequired,
}

export { Metric }
