import { gql } from '@apollo/client'

const PERFORMANCE_REPORT_QUERY = gql`
  query performanceReport(
    $stationIds: [ID!]!
    $fuelGradeIds: [ID!]!
    $dateRange: String!
    $pricingTypes: [PricingType!]!
    $margin: Boolean!
    $profit: Boolean!
  ) {
    performanceReport(
      input: {
        stationIds: $stationIds
        fuelGradeIds: $fuelGradeIds
        dateRange: $dateRange
        pricingTypes: $pricingTypes
      }
    ) {
      date
      performanceReports {
        pricingType
        totalVolume
        fuelCardVolume
        bunkeredVolume
        retailVolume
        grossMargin @include(if: $margin)
        netMargin @include(if: $margin)
        grossProfit @include(if: $profit)
        netProfit @include(if: $profit)
        businessProfit @include(if: $profit)
        bunkerCommission @include(if: $profit)
      }
    }
  }
`

export { PERFORMANCE_REPORT_QUERY }
