import React from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import numeral from 'numeral'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { MetricTypes, BuysmartEventColours } from 'utils/constants'
import styles from './Tank.module.scss'

const cx = classNames.bind(styles)

const Tank = ({
  daysLeft,
  colour,
  max,
  buysmartEnabled,
  predictionsReady,
  tankNumber,
  shortTitle,
  tankVolume,
  ullage,
}) => {
  let tankColour = colour
  let tankVolumeColour = colour
  let daysLeftInfoColour

  const { formatMetricValue } = useFormatMetricValue()
  const { t } = useTranslation()

  if (buysmartEnabled && predictionsReady) {
    switch (true) {
      case daysLeft < 4 && daysLeft >= 1:
        tankVolumeColour = BuysmartEventColours.WARNING
        daysLeftInfoColour = 'warning'
        break
      case daysLeft < 1:
        tankVolumeColour = BuysmartEventColours.STOCKOUT
        tankColour = BuysmartEventColours.STOCKOUT
        daysLeftInfoColour = 'stockout'
        break
      default:
        break
    }
  }

  const daysLeftToDisplay = daysLeft > 14 ? '14+' : daysLeft
  const buysmartDaysLeftInfo = (
    <div className={cx('days-left-info', daysLeftInfoColour)}>
      <div className={cx('days')} data-testid="days-left">
        {daysLeftToDisplay}
      </div>
      <div className={cx('info')}>{t('widgets.buysmartAnalysis.days')}</div>
    </div>
  )

  const volume = (
    <div className={cx('tank-volume-container')}>
      <div className={cx('ullage')}>
        {t('widgets.buysmartAnalysis.ullage')}:{' '}
        {formatMetricValue(MetricTypes.VOLUME, ullage)}
      </div>
      <div className={cx('volume')}>
        {formatMetricValue(MetricTypes.VOLUME, tankVolume)}
      </div>
    </div>
  )

  const fillLevel = 100 - (tankVolume / max) * 100
  const gradeTankInfoColour = fillLevel > 90 ? 'tank-low' : ''

  const tankOutLine = {
    stroke: `${tankColour}`,
  }

  const tankId = `tank-fill-tank-${shortTitle.replaceAll(' ', '-') + tankNumber}`

  const tankDisplay = (
    <svg className={cx('tank')}>
      <g className={cx('group')}>
        <linearGradient id={tankId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={'#fff'}></stop>
          <stop offset={`${fillLevel}%`} stopColor={'#fff'}></stop>
          <stop offset="0%" stopColor={tankVolumeColour}></stop>
          <stop offset="100%" stopColor={tankVolumeColour}></stop>
        </linearGradient>
        <rect
          strokeWidth="2"
          x={1}
          y={1}
          rx={20}
          className={cx('tank-outline')}
          fill={`url(#${tankId})`}
          style={tankOutLine}
        />
        <text className={cx('max-volume')} x="50%" y="37" textAnchor="middle">
          {numeral(max).format('0.0a')}
        </text>
        <text className={cx('max-volume-label')} x={'38'} y={'53'}>
          {t('widgets.buysmartAnalysis.max')}
        </text>
        <text
          className={cx('grade-tank-info', gradeTankInfoColour)}
          x={'25'}
          y={'224'}
        >
          {`${shortTitle} (T${tankNumber})`}
        </text>
      </g>
    </svg>
  )

  return (
    <div className={cx('tank-container')}>
      {buysmartEnabled && predictionsReady && buysmartDaysLeftInfo}
      {tankDisplay}
      {volume}
    </div>
  )
}

Tank.propTypes = {
  daysLeft: PropTypes.number,
  colour: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  buysmartEnabled: PropTypes.bool.isRequired,
  predictionsReady: PropTypes.bool.isRequired,
  tankNumber: PropTypes.number.isRequired,
  shortTitle: PropTypes.string.isRequired,
  tankVolume: PropTypes.number.isRequired,
  ullage: PropTypes.number.isRequired,
}

Tank.defaultProps = {
  daysLeft: null,
}

export { Tank }
