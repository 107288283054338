import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { Tank } from './components/tank'
import styles from './Tanks.module.scss'

const cx = classNames.bind(styles)

const Tanks = ({ tanksData, buysmartEnabled, predictionsReady }) => {
  const tanks = tanksData[0].sort((tankA, tankB) =>
    !buysmartEnabled || !predictionsReady
      ? tankA.position - tankB.position
      : tankA.daysLeft - tankB.daysLeft
  )

  const renderTanks = tanks.map(
    (
      { daysLeft, primary, maxVolume, tankNumber, shortTitle, volume, ullage },
      i
    ) => {
      return (
        <Tank
          key={i}
          daysLeft={daysLeft}
          colour={primary}
          max={maxVolume}
          buysmartEnabled={buysmartEnabled}
          predictionsReady={predictionsReady}
          tankNumber={tankNumber}
          shortTitle={shortTitle}
          tankVolume={volume}
          ullage={ullage}
        />
      )
    }
  )

  return <div className={cx('tanks-container')}>{renderTanks}</div>
}

Tanks.propTypes = {
  tanksData: PropTypes.array.isRequired,
  buysmartEnabled: PropTypes.bool.isRequired,
  predictionsReady: PropTypes.bool.isRequired,
}

export { Tanks }
