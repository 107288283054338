import React from 'react'
import { PropTypes } from 'prop-types'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import classNames from 'classnames/bind'
import { getTrendDirection } from 'utils/helpers'
import { TrendIndicator } from 'components/common/trend-indicator'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'
import styles from './CompanyPerformanceMetric.module.scss'

const cx = classNames.bind(styles)

const renderIcon = (trendDirection, IconComponent, permitted, tooltip) => {
  const trendClass = permitted ? trendDirection.toLowerCase() : 'neutral'

  const overlay = <Tooltip className={cx('metric-tooltip')}>{tooltip}</Tooltip>

  return (
    <OverlayTrigger placement="bottom" overlay={overlay}>
      <IconComponent
        className={cx('metric-icon', trendClass)}
        data-testid="metric-icon"
      />
    </OverlayTrigger>
  )
}

const renderData = (
  formatMetricValue,
  type,
  comparativePeriod,
  value,
  comparativeValue,
  trend,
  permitted,
  title
) => {
  const restrictedComponent = (
    <span className={cx('metric-data')}>
      <div>
        <span className={cx('name')}>{title}</span>
        <div className={cx('performance-trend')}>
          <Placeholder widthPercentage={100} />
        </div>
      </div>
      <Placeholder widthPercentage={30} />
    </span>
  )

  return (
    <Permissioned permission={permitted} fallback={restrictedComponent}>
      <span className={cx('metric-data')}>
        <div>
          <span className={cx('name')}>{title}</span>
          <div className={cx('performance-trend')}>
            <TrendIndicator
              tooltip
              tooltipPlacement={'bottom'}
              trend={trend}
              comparative={comparativeValue}
              comparativeType={type}
              comparativePeriod={comparativePeriod}
            />
          </div>
        </div>
        <div>{formatMetricValue(type, value)}</div>
      </span>
    </Permissioned>
  )
}

const CompanyPerformanceMetric = ({
  single,
  metric,
  metricData,
  permitted,
}) => {
  const { formatMetricValue } = useFormatMetricValue()
  const { title, icon, type, comparativePeriod, tooltip } = metric
  const { value, comparativeValue, trend } = metricData

  const trendDirection = getTrendDirection(trend)

  return (
    <div className={cx('company-metric', { single })}>
      {renderIcon(trendDirection, icon, permitted, tooltip)}
      {renderData(
        formatMetricValue,
        type,
        comparativePeriod,
        value,
        comparativeValue,
        trend,
        permitted,
        title
      )}
    </div>
  )
}

CompanyPerformanceMetric.propTypes = {
  single: PropTypes.bool,
  metric: PropTypes.shape().isRequired,
  metricData: PropTypes.shape().isRequired,
  permitted: PropTypes.bool.isRequired,
}

CompanyPerformanceMetric.defaultProps = {
  single: false,
}

export { CompanyPerformanceMetric }
