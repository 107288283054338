import React from 'react'
import PropTypes from 'prop-types'
import { LeadGradeIndicator } from 'components/common/lead-grade-indicator'
import { useFormatPriceDifferential } from 'components/common/hooks/useFormatPriceDifferential'
import classNames from 'classnames/bind'
import styles from './FuelGradeDifferential.module.scss'

const cx = classNames.bind(styles)

const FuelGradeDifferential = ({ fuelGradeDifferential, fuelGrades }) => {
  const { formatPriceDifferential } = useFormatPriceDifferential()

  const { leadGrade, differential, fuelGradeId } = fuelGradeDifferential
  const fuelGrade = fuelGrades.find(({ id }) => id === fuelGradeId)

  const gradeStyles = {
    backgroundColor: `${fuelGrade.primary}`,
  }

  if (!leadGrade && (!differential || differential === 0)) {
    return null
  }

  return (
    <div className={cx('fuel-grade-differential')} style={gradeStyles}>
      <LeadGradeIndicator leadGrade={leadGrade} fuelGrade={fuelGrade} />
      {formatPriceDifferential(differential, 0)}
    </div>
  )
}

FuelGradeDifferential.propTypes = {
  fuelGradeDifferential: PropTypes.shape().isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { FuelGradeDifferential }
