import { gql } from '@apollo/client'

/**
 * Fragment to load a user's permissions on mainQuery and to query the cache in usePermissions hook.
 */
const USER_PERMISSION_ATTRIBUTES = gql`
  fragment UserPermissionAttributes on User {
    permissions {
      id
      title
      create
      read
      update
      delete
    }
  }
`

const COMPETITOR_POLE_PRICE_ATTRIBUTES = gql`
  fragment CompetitorPolePriceAttributes on PolePrice {
    id
    pricingType
    fuelGradeId
    stationId
    price
    priceAt
    priceUpdatedAt
    source
    previousPrice {
      id
      price
    }
    creator
  }
`
const POLE_PRICE_CHANGE_REQUEST_ATTRIBUTES = gql`
  fragment PolePriceChangeRequestAttributes on PolePriceChangeRequest {
    id
    newPrice
    price
    fuelGradeId
    pricingType
    stationId
    toActionAt
  }
`

const COMPETITORS_ATTRIBUTES = gql`
  fragment CompetitorsAttributes on StationCompetitor {
    id
    name
    influencer
    cashCreditPricing
    brand {
      id
      name
      key
    }
    distances {
      drivingDistanceKilometres
      drivingDistanceMiles
    }
  }
`

const STATION_STATUS_ATTRIBUTES = gql`
  fragment StationStatusAttributes on StationStatus {
    stationId
    stationStatus
    transactionStatus @include(if: $transactionStatus) {
      status
      lastTransactionAt
    }
    dipStatus @include(if: $dipStatus) {
      status
      lastDipAt
    }
    costPriceStatus @include(if: $costPriceStatus) {
      status
      lastCostPriceAt
    }
  }
`

export {
  USER_PERMISSION_ATTRIBUTES,
  COMPETITOR_POLE_PRICE_ATTRIBUTES,
  POLE_PRICE_CHANGE_REQUEST_ATTRIBUTES,
  COMPETITORS_ATTRIBUTES,
  STATION_STATUS_ATTRIBUTES,
}
