import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { upperFirstOnly } from 'utils/format'
import { getFirstPartOfURLPath } from 'utils/helpers'
import classNames from 'classnames/bind'
import { useTranslations } from 'config/locale/hooks/requireContext'
import { ReactComponent as VolumeIcon } from 'images/performance-report/volume.svg'
import { ReactComponent as MarginIcon } from 'images/performance-report/margin.svg'
import { ReactComponent as CheckIcon } from 'images/common/check.svg'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import styles from './MetricSelector.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const MetricSelector = ({
  value,
  onValueChange,
  showVolumeSecondRow,
  hasBunkered,
  metricPermissions,
}) => {
  const { t } = useTranslation(['translations'])
  const translations = useTranslations(i18n.language)
  const SmallProfitIcon = translations?.smallProfitIcon

  const { readMargin, readProfit, readVolume } = metricPermissions

  const tabs = [
    {
      value: 'volume',
      label: upperFirstOnly(t('widgets.performanceReport.volume')),
      Icon: VolumeIcon,
      disabled: !readVolume,
    },
    {
      value: 'margin',
      label: upperFirstOnly(t('widgets.performanceReport.margin')),
      Icon: MarginIcon,
      disabled: !readMargin,
    },
    {
      value: 'profit',
      label: upperFirstOnly(t('widgets.performanceReport.profit')),
      Icon: SmallProfitIcon,
      disabled: !readProfit,
    },
  ]

  const [tab, setTab] = useState(null)
  const [showFuelCards, setShowFuelCards] = useState(false)
  const [volumeType, setVolumeType] = useState('total')
  const [profitType, setProfitType] = useState('all-profit')
  const [marginType, setMarginType] = useState('all-margin')

  useEffect(() => {
    switch (value) {
      case 'retailVolume':
        setTab('volume')
        setShowFuelCards(false)
        break
      case 'totalVolume':
        setTab('volume')
        setShowFuelCards(true)
        setVolumeType('total')
        break
      case 'fuelCardVolume':
        setTab('volume')
        setShowFuelCards(true)
        setVolumeType('fuel-card')
        break
      case 'bunkeredVolume':
        setTab('volume')
        setShowFuelCards(true)
        setVolumeType('bunkered')
        break
      case 'allMargin':
        setTab('margin')
        setMarginType('all-margin')
        break
      case 'grossMargin':
        setTab('margin')
        setMarginType('gross-margin')
        break
      case 'netMargin':
        setTab('margin')
        setMarginType('net-margin')
        break
      case 'allProfit':
        setTab('profit')
        setProfitType('all-profit')
        break
      case 'grossProfit':
        setTab('profit')
        setProfitType('gross-profit')
        break
      case 'netProfit':
        setTab('profit')
        setProfitType('net-profit')
        break
      case 'businessProfit':
        setTab('profit')
        setProfitType('business-profit')
        break
      case 'noPermission':
        break
      /* istanbul ignore next */
      default:
        throw new Error(`Invalid metric: ${value}`)
    }
  }, [value])

  useEffect(() => {
    if (tab === null) {
      return
    }

    let newValue
    switch (tab) {
      case 'volume':
        if (showFuelCards) {
          switch (volumeType) {
            case 'fuel-card':
              newValue = 'fuelCardVolume'
              break
            case 'bunkered':
              newValue = 'bunkeredVolume'
              break
            case 'total':
              newValue = 'totalVolume'
              break
            /* istanbul ignore next */
            default:
              throw new Error(`Invalid volume type: ${volumeType}`)
          }
        } else {
          newValue = 'retailVolume'
        }
        break
      case 'margin':
        switch (marginType) {
          case 'all-margin':
            newValue = 'allMargin'
            break
          case 'gross-margin':
            newValue = 'grossMargin'
            break
          case 'net-margin':
            newValue = 'netMargin'
            break
          /* istanbul ignore next */
          default:
            throw new Error(`Invalid margin type: ${marginType}`)
        }
        break
      case 'profit':
        switch (profitType) {
          case 'all-profit':
            newValue = 'allProfit'
            break
          case 'gross-profit':
            newValue = 'grossProfit'
            break
          case 'net-profit':
            newValue = 'netProfit'
            break
          case 'business-profit':
            newValue = 'businessProfit'
            break
          /* istanbul ignore next */
          default:
            throw new Error(`Invalid profit type: ${profitType}`)
        }
        break
      /* istanbul ignore next */
      default:
        throw new Error(`Invalid metric: ${tab}`)
    }
    if (newValue !== value) {
      onValueChange(newValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, showFuelCards, volumeType, profitType, marginType, onValueChange])

  const page = getFirstPartOfURLPath(window.location.pathname)

  return (
    <div className={cx('types-row')}>
      <div className={cx('tabs')}>
        {tabs.map(currentTab => (
          <ToggleButton
            key={currentTab.value}
            type="radio"
            name="tabs"
            className={cx('tab', { disabled: currentTab.disabled })}
            value={currentTab.value}
            disabled={currentTab.disabled}
            checked={tab === currentTab.value}
            onChange={event => setTab(event.currentTarget.value)}
            data-testid={currentTab.value}
          >
            {currentTab.Icon && <currentTab.Icon />}
            {currentTab.label}
          </ToggleButton>
        ))}
      </div>
      {tab === 'volume' && (
        <table className={cx('type-options')}>
          <tbody>
            <tr>
              <td colSpan={2} />
              <td>
                <ToggleButton
                  type="checkbox"
                  name="fuel-cards"
                  className={cx('checkbox')}
                  checked={showFuelCards}
                  onChange={event => {
                    !showFuelCards &&
                      amplitudeEvent(
                        UserEvents.PerformanceReport.VOLUME_TAB(
                          page,
                          'show fuel cards'
                        )
                      )
                    setShowFuelCards(event.currentTarget.checked)
                  }}
                  data-testid="show-fuel-cards"
                >
                  {showFuelCards ? <CheckIcon /> : <InactiveIcon />}
                  {upperFirstOnly(t('widgets.performanceReport.showFuelCards'))}
                </ToggleButton>
              </td>
            </tr>
            {showFuelCards && showVolumeSecondRow && (
              <tr>
                {!hasBunkered && <td />}
                <td>
                  <ToggleButton
                    type="radio"
                    name="volume-type"
                    className={cx('radio')}
                    value={'fuel-card'}
                    checked={volumeType === 'fuel-card'}
                    onChange={event => {
                      volumeType !== 'fuel-card' &&
                        amplitudeEvent(
                          UserEvents.PerformanceReport.VOLUME_TAB(
                            page,
                            'fuel cards only'
                          )
                        )
                      setVolumeType(event.currentTarget.value)
                    }}
                    data-testid="fuel-card"
                  >
                    {volumeType === 'fuel-card' ? (
                      <ActiveIcon />
                    ) : (
                      <InactiveIcon />
                    )}
                    {upperFirstOnly(
                      t('widgets.performanceReport.fuelCardVolOnly')
                    )}
                  </ToggleButton>
                </td>
                {hasBunkered && (
                  <td>
                    <ToggleButton
                      type="radio"
                      name="volume-type"
                      className={cx('radio')}
                      value={'bunkered'}
                      checked={volumeType === 'bunkered'}
                      onChange={event => {
                        volumeType !== 'bunkered' &&
                          amplitudeEvent(
                            UserEvents.PerformanceReport.VOLUME_TAB(
                              page,
                              'bunkered'
                            )
                          )
                        setVolumeType(event.currentTarget.value)
                      }}
                      data-testid="bunkered"
                    >
                      {volumeType === 'bunkered' ? (
                        <ActiveIcon />
                      ) : (
                        <InactiveIcon />
                      )}
                      {upperFirstOnly(
                        t('widgets.performanceReport.bunkeredVolOnly')
                      )}
                    </ToggleButton>
                  </td>
                )}
                <td>
                  <ToggleButton
                    type="radio"
                    name="volume-type"
                    className={cx('radio')}
                    value={'total'}
                    checked={volumeType === 'total'}
                    onChange={event => {
                      volumeType !== 'total' &&
                        amplitudeEvent(
                          UserEvents.PerformanceReport.VOLUME_TAB(page, 'total')
                        )
                      setVolumeType(event.currentTarget.value)
                    }}
                    data-testid="total"
                  >
                    {volumeType === 'total' ? <ActiveIcon /> : <InactiveIcon />}
                    {upperFirstOnly(
                      t('widgets.performanceReport.totalVolOnly')
                    )}
                  </ToggleButton>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {tab === 'margin' && (
        <table className={cx('type-options')}>
          <tbody>
            <tr>
              <td>
                <ToggleButton
                  type="radio"
                  name="margin-type"
                  className={cx('radio')}
                  value={'all-margin'}
                  checked={marginType === 'all-margin'}
                  onChange={event => {
                    marginType !== 'all-margin' &&
                      amplitudeEvent(
                        UserEvents.PerformanceReport.MARGIN_TAB(page, 'all')
                      )
                    setMarginType(event.currentTarget.value)
                  }}
                  data-testid="all-margin"
                >
                  {marginType === 'all-margin' ? (
                    <ActiveIcon />
                  ) : (
                    <InactiveIcon />
                  )}
                  {upperFirstOnly(t('widgets.performanceReport.all'))}
                </ToggleButton>
              </td>
              <td>
                <ToggleButton
                  type="radio"
                  name="margin-type"
                  className={cx('radio')}
                  value={'gross-margin'}
                  checked={marginType === 'gross-margin'}
                  onChange={event => {
                    marginType !== 'gross-margin' &&
                      amplitudeEvent(
                        UserEvents.PerformanceReport.MARGIN_TAB(page, 'gross')
                      )
                    setMarginType(event.currentTarget.value)
                  }}
                  data-testid="gross-margin"
                >
                  {marginType === 'gross-margin' ? (
                    <ActiveIcon />
                  ) : (
                    <InactiveIcon />
                  )}
                  {upperFirstOnly(t('widgets.performanceReport.grossMargin'))}
                </ToggleButton>
              </td>
              <td>
                <ToggleButton
                  type="radio"
                  name="margin-type"
                  className={cx('radio')}
                  value={'net-margin'}
                  checked={marginType === 'net-margin'}
                  onChange={event => {
                    marginType !== 'net-margin' &&
                      amplitudeEvent(
                        UserEvents.PerformanceReport.MARGIN_TAB(page, 'net')
                      )
                    setMarginType(event.currentTarget.value)
                  }}
                  data-testid="net-margin"
                >
                  {marginType === 'net-margin' ? (
                    <ActiveIcon />
                  ) : (
                    <InactiveIcon />
                  )}
                  {upperFirstOnly(t('widgets.performanceReport.netMargin'))}
                </ToggleButton>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {tab === 'profit' && (
        <table className={cx('type-options')}>
          <tbody>
            <tr>
              <td>
                <ToggleButton
                  type="radio"
                  name="profit-type"
                  className={cx('radio')}
                  value={'all-profit'}
                  checked={profitType === 'all-profit'}
                  onChange={event => {
                    profitType !== 'all-profit' &&
                      amplitudeEvent(
                        UserEvents.PerformanceReport.PROFIT_TAB(page, 'all')
                      )
                    setProfitType(event.currentTarget.value)
                  }}
                  data-testid="all-profit"
                >
                  {profitType === 'all-profit' ? (
                    <ActiveIcon />
                  ) : (
                    <InactiveIcon />
                  )}
                  {upperFirstOnly(t('widgets.performanceReport.all'))}
                </ToggleButton>
              </td>
              <td>
                <ToggleButton
                  type="radio"
                  name="profit-type"
                  className={cx('radio')}
                  value={'gross-profit'}
                  checked={profitType === 'gross-profit'}
                  onChange={event => {
                    profitType !== 'gross-profit' &&
                      amplitudeEvent(
                        UserEvents.PerformanceReport.PROFIT_TAB(page, 'gross')
                      )
                    setProfitType(event.currentTarget.value)
                  }}
                  data-testid="gross-profit"
                >
                  {profitType === 'gross-profit' ? (
                    <ActiveIcon />
                  ) : (
                    <InactiveIcon />
                  )}
                  {upperFirstOnly(t('widgets.performanceReport.grossProfit'))}
                </ToggleButton>
              </td>
              <td>
                <ToggleButton
                  type="radio"
                  name="profit-type"
                  className={cx('radio')}
                  value={'net-profit'}
                  checked={profitType === 'net-profit'}
                  onChange={event => {
                    profitType !== 'net-profit' &&
                      amplitudeEvent(
                        UserEvents.PerformanceReport.PROFIT_TAB(page, 'net')
                      )
                    setProfitType(event.currentTarget.value)
                  }}
                  data-testid="net-profit"
                >
                  {profitType === 'net-profit' ? (
                    <ActiveIcon />
                  ) : (
                    <InactiveIcon />
                  )}
                  {upperFirstOnly(t('widgets.performanceReport.netProfit'))}
                </ToggleButton>
              </td>
              {hasBunkered && (
                <td>
                  <ToggleButton
                    type="radio"
                    name="profit-type"
                    className={cx('radio')}
                    value={'business-profit'}
                    checked={profitType === 'business-profit'}
                    onChange={event => {
                      profitType !== 'business-profit' &&
                        amplitudeEvent(
                          UserEvents.PerformanceReport.PROFIT_TAB(
                            page,
                            'business'
                          )
                        )
                      setProfitType(event.currentTarget.value)
                    }}
                    data-testid="business-profit"
                  >
                    {profitType === 'business-profit' ? (
                      <ActiveIcon />
                    ) : (
                      <InactiveIcon />
                    )}
                    {upperFirstOnly(
                      t('widgets.performanceReport.businessProfit')
                    )}
                  </ToggleButton>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}

MetricSelector.propTypes = {
  value: PropTypes.oneOf([
    'retailVolume',
    'fuelCardVolume',
    'bunkeredVolume',
    'totalVolume',
    'grossMargin',
    'netMargin',
    'netMarginAll',
    'grossProfit',
    'netProfit',
    'businessProfit',
    'allMargin',
    'allProfit',
    'noPermission',
  ]),
  onValueChange: PropTypes.func,
  hasBunkered: PropTypes.bool.isRequired,
  metricPermissions: PropTypes.shape({
    readMargin: PropTypes.bool.isRequired,
    readProfit: PropTypes.bool.isRequired,
  }).isRequired,
}

MetricSelector.defaultProps = {
  value: 'retailVolume',
  onValueChange: null,
}

export { MetricSelector }
