import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import styles from './TimelineItem.module.scss'
import { TimestampTypes } from 'utils/constants'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { useFormatTimestamp } from 'components/common/hooks/useFormatTimestamp'
import { Events } from './components/events'
import { DayCircle } from './components/day-circle'

const cx = classNames.bind(styles)
const { DAY_MONTH } = TimestampTypes

const TimelineItem = ({
  date,
  warnings,
  stockOuts,
  day,
  selectedDay,
  setSelectedDay,
  predictionsReady,
}) => {
  const { formatTimestamp } = useFormatTimestamp()

  const formattedDate = formatTimestamp(DAY_MONTH, date)
  const selectedDayStyle = day === selectedDay

  const [hovered, setHovered] = useState(false)
  const hover = useRef()
  const selected = day === selectedDay

  const handleMouseOver = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  useEffect(() => {
    if (predictionsReady) {
      const { current } = hover
      current.addEventListener('mouseenter', handleMouseOver)
      current.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        current.removeEventListener('mouseenter', handleMouseOver)
        current.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [predictionsReady])

  const handleClick = () => {
    amplitudeEvent(UserEvents.BuySmartDay.CLICKED(day))
    setSelectedDay(day)
  }

  return (
    <div
      className={cx('timeline-item-container')}
      onClick={handleClick}
      ref={hover}
    >
      <Events
        warnings={warnings}
        stockOuts={stockOuts}
        date={formattedDate}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        day={day}
      />
      <DayCircle selected={selected} hovered={hovered} />
      <div className={cx('timeline-date', { selected: selectedDayStyle })}>
        {formattedDate}
      </div>
    </div>
  )
}

TimelineItem.propTypes = {
  day: PropTypes.number,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  warnings: PropTypes.array,
  stockOuts: PropTypes.array,
  selectedDay: PropTypes.number,
  setSelectedDay: PropTypes.func,
  predictionsReady: PropTypes.bool,
}

TimelineItem.defaultProps = {
  day: 0,
  warnings: [],
  stockOuts: [],
  selectedDay: 0,
  setSelectedDay: () => {},
  predictionsReady: false,
}

export { TimelineItem }
