import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { Locales, TimestampTypes } from 'utils/constants'
import { isNullOrUndefined } from 'utils/helpers'
import {
  getShortMonth,
  getDayShortMonth,
  getDayShortMonthYear,
} from 'utils/dateTime'
import { useTranslation } from 'react-i18next'
import { useCompanyTimeZone } from './useCompanyTimeZone'

const { TIME, DAY, MONTH, YEAR, DAY_MONTH, DAY_MONTH_YEAR } = TimestampTypes

const formatTime = (locale, value) => {
  if (locale === Locales.EN_US) {
    return value.format('h:mma')
  }

  return value.format('HH:mm')
}

const formatDay = value => {
  return value.format('DD')
}

const formatMonth = (locale, value, timezone) => {
  return getShortMonth(locale, value, timezone)
}

const formatYear = value => {
  return value.format('yyyy')
}

const formatDayMonth = (locale, value, timezone) => {
  return getDayShortMonth(locale, value, timezone)
}

const formatDayMonthYear = (locale, value, timezone) => {
  return getDayShortMonthYear(locale, value, timezone)
}

function useFormatTimestamp() {
  const { companyTimeZone } = useCompanyTimeZone()
  const {
    i18n: { language: locale },
  } = useTranslation()

  function formatTimestamp(type, ts) {
    if (isNullOrUndefined(ts)) {
      return '-'
    }

    const utc = moment.tz(ts, 'UTC').format('YYYY-MM-DD HH:mmZ')
    const value = moment.tz(utc, companyTimeZone)

    switch (type) {
      case TIME:
        return formatTime(locale, value)
      case DAY:
        return formatDay(value)
      case MONTH:
        return formatMonth(locale, value, companyTimeZone)
      case YEAR:
        return formatYear(value)
      case DAY_MONTH:
        return formatDayMonth(locale, value, companyTimeZone)
      case DAY_MONTH_YEAR:
        return formatDayMonthYear(locale, value, companyTimeZone)
      default:
        return '-'
    }
  }

  return { formatTimestamp }
}

export { useFormatTimestamp }
