import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SortFields } from 'components/stations-list/utils/constants'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { HeaderCell } from './components/header-cell'
import styles from './HeaderRow.module.scss'

const {
  NAME,
  TOTAL_VOLUME,
  TOTAL_VOLUME_TREND,
  RETAIL_VOLUME,
  RETAIL_VOLUME_TREND,
  GROSS_MARGIN,
  GROSS_MARGIN_TREND,
  NET_MARGIN,
  NET_MARGIN_TREND,
  GROSS_PROFIT,
  GROSS_PROFIT_TREND,
} = SortFields

const cx = classNames.bind(styles)

const getHeaders = (
  showPricingView,
  showTotalVolume,
  { readProfit, readMargin, readVolume },
  readTargetMargin,
  t
) => {
  const defaultHeaders = [
    {
      metric: t('widgets.stationsList.station'),
      comparative: t('widgets.stationsList.targetMargin'),
      metricSort: NAME,
      permitted: readTargetMargin,
    },
    {
      metric: showTotalVolume
        ? t('widgets.stationsList.totalVolume')
        : t('widgets.stationsList.retailVolume'),
      comparative: t('widgets.stationsList.yesterdayEOD'),
      metricSort: showTotalVolume ? TOTAL_VOLUME : RETAIL_VOLUME,
      trendSort: showTotalVolume ? TOTAL_VOLUME_TREND : RETAIL_VOLUME_TREND,
      permitted: readVolume,
    },
    {
      metric: t('widgets.stationsList.grossMargin'),
      comparative: t('widgets.stationsList.yesterdayEOD'),
      metricSort: GROSS_MARGIN,
      trendSort: GROSS_MARGIN_TREND,
      permitted: readMargin,
    },
  ]

  if (showPricingView) {
    return defaultHeaders
  }

  return [
    ...defaultHeaders,
    {
      metric: t('widgets.stationsList.netMargin'),
      comparative: t('widgets.stationsList.yesterdayEOD'),
      metricSort: NET_MARGIN,
      trendSort: NET_MARGIN_TREND,
      permitted: readMargin,
    },
    {
      metric: t('widgets.stationsList.grossProfit'),
      comparative: t('widgets.stationsList.yesterdayEOD'),
      metricSort: GROSS_PROFIT,
      trendSort: GROSS_PROFIT_TREND,
      permitted: readProfit,
    },
  ]
}

const HeaderRow = ({ options, showPricingView }) => {
  const { showTotalVolume } = useFlags()
  const {
    metricPermissions,
    featurePermissions: { readTargetMargin },
  } = usePermittedAccess()
  const { t } = useTranslation()

  const headers = getHeaders(
    showPricingView,
    showTotalVolume,
    metricPermissions,
    readTargetMargin,
    t
  )

  const { selectedSort } = options

  return (
    <div
      className={cx('table-header', {
        'editing-pricing': showPricingView,
      })}
    >
      {headers.map(header => (
        <HeaderCell
          key={header.metric}
          header={header}
          selectedSort={selectedSort}
          permitted={header.permitted}
        />
      ))}
    </div>
  )
}

HeaderRow.propTypes = {
  options: PropTypes.shape({
    selectedFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedGroupBy: PropTypes.string,
    selectedSort: PropTypes.shape().isRequired,
  }),
  showPricingView: PropTypes.bool.isRequired,
}

HeaderRow.defaultProps = {}

export { HeaderRow }
