import { gql } from '@apollo/client'

const PORTFOLIO_BENCHMARK_QUERY = gql`
  query portfolioBenchmark(
    $stationIds: [ID!]!
    $fuelGradeIds: [ID!]!
    $currentPeriodStartDate: String!
    $currentPeriodEndDate: String!
    $timeResolution: String
    $stationGroupNames: [String]
    $activeTab: String
    $metricName: String
    $benchmarkRegions: [String]
  ) {
    portfolioBenchmark(
      input: {
        stationIds: $stationIds
        fuelGradeIds: $fuelGradeIds
        currentPeriodStartDate: $currentPeriodStartDate
        currentPeriodEndDate: $currentPeriodEndDate
        timeResolution: $timeResolution
        stationGroupNames: $stationGroupNames
        activeTab: $activeTab
        metricName: $metricName
        benchmarkRegions: $benchmarkRegions
      }
    ) {
      dates
      yourPortfolio
      companiesSimilarToYou
      averageEdgepetrolCompany
    }
  }
`

export { PORTFOLIO_BENCHMARK_QUERY }
