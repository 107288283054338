import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment'
import PerformanceChart from './PerformanceChart'
import classNames from 'classnames/bind'
import styles from './Chart.module.scss'
import PropTypes from 'prop-types'

const cx = classNames.bind(styles)

const ChartWrapper = ({ width, chartData }) => {
  const chartArea = useRef(null)
  const [chart, setChart] = useState(null)
  const { tooltipsActive } = chartData

  useEffect(() => {
    if (!chart) {
      setChart(new PerformanceChart(chartArea.current, chartData, width))
      return
    }
    if (!tooltipsActive && chart) {
      chart.update(chartData, width)
    }
  }, [chart, width, chartData, tooltipsActive])

  return <div ref={chartArea} className={cx('chart-area')}></div>
}

ChartWrapper.propTypes = {
  chartData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.array),
    area: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    metric: PropTypes.string,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    previousLine: PropTypes.bool,
    metrics: PropTypes.arrayOf(PropTypes.string),
    period1: PropTypes.shape({
      from: PropTypes.instanceOf(moment),
      to: PropTypes.instanceOf(moment),
    }),
    formatMetricValue: PropTypes.func,
    tooltipsActive: PropTypes.bool,
    setTooltipsActive: PropTypes.func,
    tooltipData: PropTypes.array,
    setTooltipTarget: PropTypes.func,
    setTooltipData: PropTypes.func,
    t: PropTypes.func,
  }),
  width: PropTypes.number,
}

export default ChartWrapper
