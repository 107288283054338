import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'
import { ReactComponent as LoadingIcon } from 'images/stations-list/loading.svg'
import { STATIONS_LIST_GROUPS_QUERY } from 'components/stations-list/StationsListQueries'
import { useGroupedPricingDispatch } from 'components/stations-list/context/groupedPricingContext'
import { GroupedPricingActions } from 'components/stations-list/utils/constants'
import classNames from 'classnames/bind'
import { GroupRows } from './components/group-rows'
import { PricesPanel } from '../edit-pricing/prices-panel'
import { NoStationsBody } from '../no-stations-body'
import styles from './GroupedTableBody.module.scss'

const cx = classNames.bind(styles)

const GroupedTableBody = ({
  mainData,
  timestamp,
  options,
  showPricingView,
  selectedPricingType,
  cashCreditPricing,
}) => {
  const { selectedGroupBy, selectedSort, selectedFilters } = options
  const dispatch = useGroupedPricingDispatch()
  const [loadGroups, { error, loading, data }] = useLazyQuery(
    STATIONS_LIST_GROUPS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        timestamp,
        groupBy: selectedGroupBy,
        selectedPricingType,
        sort: selectedSort,
        filters: selectedFilters,
        pricing: showPricingView,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      onCompleted: () => {
        dispatch({
          type: GroupedPricingActions.RESET,
        })
      },
    }
  )
  useErrorHandler(error)

  useEffect(() => {
    /* istanbul ignore next */
    if (selectedGroupBy) {
      loadGroups()
    }
  }, [selectedGroupBy, loadGroups])

  const {
    me: {
      fuelGrades,
      stations: { items },
    },
  } = mainData

  if (loading || !data) {
    return (
      <>
        <div className={cx('grouped-table-body', 'loading')}>
          <LoadingIcon height={100} width={100} />
        </div>
        <PricesPanel
          loading
          fuelGrades={fuelGrades}
          showPricingView={showPricingView}
        />
      </>
    )
  }

  const {
    stationGroups: { groups },
  } = data

  const validGroups = groups.filter(group => group.stationsCount > 0)

  if (validGroups.length === 0) {
    return (
      <>
        <NoStationsBody />
        <PricesPanel
          loading
          fuelGrades={fuelGrades}
          showPricingView={showPricingView}
        />
      </>
    )
  }

  return (
    <>
      <div
        className={cx('grouped-table-body', {
          'editing-pricing': showPricingView,
        })}
      >
        <GroupRows
          timestamp={timestamp}
          mainData={mainData}
          data={data}
          options={options}
          selectedPricingType={selectedPricingType}
          showPricingView={showPricingView}
          cashCreditPricing={cashCreditPricing}
        />
      </div>
      <PricesPanel
        fuelGrades={fuelGrades}
        groups={validGroups}
        stations={items}
        showPricingView={showPricingView}
      />
    </>
  )
}

GroupedTableBody.propTypes = {
  timestamp: PropTypes.string.isRequired,
  mainData: PropTypes.shape().isRequired,
  selectedPricingType: PropTypes.string.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    selectedFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedGroupBy: PropTypes.string,
    selectedSort: PropTypes.shape().isRequired,
  }),
}

GroupedTableBody.defaultProps = {
  selectedGroupBy: null,
}

export { GroupedTableBody }
