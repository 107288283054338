import React from 'react'
import PropTypes from 'prop-types'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import {
  findPrice,
  findPriceChangeRequest,
} from 'components/station/components/competitor-pricing/utils/helpers'
import { stationCashCreditStatus } from 'utils/pricing'
import { PricingTypes } from 'utils/constants'
import { PriceCell } from './components/price-cell'
import { PricesCell } from './components/prices-cell'

const { ALL, CASH, CREDIT } = PricingTypes

const MyStationPriceCell = ({
  stationId,
  fuelGradeId,
  stationCashCreditPricing,
  polePrices,
  polePriceChangeRequests,
  priceDifferentials: { fuelGradeDifferentials, cashCreditDifferentials },
  sliderDispatch,
}) => {
  const { selectedPricingType, cashCreditPricing } = usePricingTypes()

  const cashCreditDifferential = cashCreditDifferentials.find(
    cashCreditDifferential => cashCreditDifferential.fuelGradeId === fuelGradeId
  )
  const fuelGradeDifferential = fuelGradeDifferentials.find(
    fuelGradeDifferential => fuelGradeDifferential.fuelGradeId === fuelGradeId
  )

  if (
    selectedPricingType === ALL &&
    cashCreditPricing &&
    stationCashCreditPricing
  ) {
    const cashPrice = findPrice(polePrices, CASH, stationCashCreditPricing)
    const creditPrice = findPrice(polePrices, CREDIT, stationCashCreditPricing)

    const cashPriceChangeRequest = findPriceChangeRequest(
      polePriceChangeRequests,
      CASH,
      stationCashCreditPricing
    )
    const creditPriceChangeRequest = findPriceChangeRequest(
      polePriceChangeRequests,
      CREDIT,
      stationCashCreditPricing
    )
    return (
      <PricesCell
        stationId={stationId}
        fuelGradeId={fuelGradeId}
        cashPrice={cashPrice}
        creditPrice={creditPrice}
        cashPriceChangeRequest={cashPriceChangeRequest}
        creditPriceChangeRequest={creditPriceChangeRequest}
        fuelGradeDifferential={fuelGradeDifferential}
        cashCreditDifferential={cashCreditDifferential}
        stationCashCreditPricing={stationCashCreditPricing}
        sliderDispatch={sliderDispatch}
      />
    )
  }

  const polePrice = findPrice(
    polePrices,
    selectedPricingType,
    stationCashCreditPricing
  )
  const polePriceChangeRequest = findPriceChangeRequest(
    polePriceChangeRequests,
    selectedPricingType,
    stationCashCreditPricing
  )

  const hasNoCashCreditPrice = stationCashCreditStatus(
    cashCreditPricing,
    stationCashCreditPricing
  )

  return (
    <PriceCell
      stationId={stationId}
      polePrice={polePrice}
      polePriceChangeRequest={polePriceChangeRequest}
      stationCashCreditPricing={stationCashCreditPricing}
      fuelGradeDifferential={fuelGradeDifferential}
      cashCreditDifferential={cashCreditDifferential}
      hasNoCashCreditPrice={hasNoCashCreditPrice}
      sliderDispatch={sliderDispatch}
    />
  )
}

MyStationPriceCell.propTypes = {
  stationId: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  stationCashCreditPricing: PropTypes.bool.isRequired,
  polePrices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  polePriceChangeRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  priceDifferentials: PropTypes.shape().isRequired,
  sliderDispatch: PropTypes.func.isRequired,
}

export { MyStationPriceCell }
