import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Textarea.module.scss'

const cx = classNames.bind(styles)

const Textarea = ({
  value,
  onValueChange,
  customClass,
  placeholder,
  testId,
}) => {
  const handleChange = event => {
    const {
      target: { value: newValue },
    } = event
    onValueChange(newValue)
  }

  return (
    <textarea
      data-testid={testId}
      className={cx('textarea', customClass)}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
    />
  )
}

Textarea.propTypes = {
  value: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
}

Textarea.defaultProps = {
  value: '',
  placeholder: null,
}

export { Textarea }
