import React, { useState, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'config/context/authContext'
import styles from './ResetPassword.module.scss'
import { AuthDialog } from '../components/auth-dialog'
import { MinPasswordComplexity } from 'utils/constants'
import { getPasswordComplexity } from 'utils/password'
import { InvalidResetLink } from './invalid-reset-link'
import Spinner from 'react-bootstrap/Spinner'

const cx = classNames.bind(styles)

const ResetPassword = () => {
  const { t } = useTranslation('auth')
  const { resetPassword } = useAuth()
  const { validateResetToken } = useAuth()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isPasswordFocused, setPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setConfirmPasswordFocused] = useState(false)
  const [error, setError] = useState(null)
  const [isPasswordInvalid, setPasswordInvalid] = useState(null)
  const [isConfirmPasswordInvalid, setConfirmPasswordInvalid] = useState(null)
  const [isInvalidResetLink, setIsInvalidResetLink] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const checkResetToken = async () => {
      try {
        await validateResetToken()
        setIsInvalidResetLink(false)
      } catch (err) {
        if (err.message === 'Expired reset token') {
          setIsInvalidResetLink(true)
        } else {
          setError(t('genericErrorMessage'))
        }
      } finally {
        setLoading(false)
      }
    }

    checkResetToken()
  }, [validateResetToken])

  const handleSubmit = async () => {
    if (!password) {
      setError(null)
      return
    }

    const complexity = getPasswordComplexity(password)
    if (complexity < MinPasswordComplexity) {
      setError(t('passwordComplexityErrorText'))
      return
    }

    const matching = password === confirmPassword
    if (!matching) {
      setError(t('passwordsDoNotMatch'))
      return
    }

    setError(null)

    try {
      await resetPassword(password)
    } catch (err) {
      setError(err.message)
    }
  }

  const handlePasswordFocus = () => setPasswordFocused(true)
  const handlePasswordBlur = () => setPasswordFocused(false)

  const handleConfirmPasswordFocus = () => setConfirmPasswordFocused(true)
  const handleConfirmPasswordBlur = () => setConfirmPasswordFocused(false)

  const handleChangePassword = event => {
    const password = event.target.value
    const complexity = getPasswordComplexity(password)

    // fix bug with focus/blur and webkit's handling of autofill
    setPasswordFocused(true)
    setPasswordInvalid(
      password.length === 0 ? null : complexity < MinPasswordComplexity
    )
    setConfirmPasswordInvalid(
      confirmPassword.length === 0 ? null : confirmPassword !== password
    )
    setPassword(password)
  }

  const handleChangeConfirmPassword = event => {
    const confirmPassword = event.target.value

    // fix bug with focus/blur and webkit's handling of autofill
    setConfirmPasswordFocused(true)
    setConfirmPasswordInvalid(
      confirmPassword.length === 0 ? null : confirmPassword !== password
    )
    setConfirmPassword(confirmPassword)
  }

  if (loading) {
    return <Spinner animation="grow" variant="primary" />
  }

  if (isInvalidResetLink) {
    return <InvalidResetLink />
  }

  return (
    <AuthDialog
      title={t('resetPassword')}
      onSubmit={handleSubmit}
      buttonText={t('resetPassword')}
      helpText={t('resetPasswordHelpText')}
      error={error}
    >
      <div
        className={cx('input', {
          focused: isPasswordFocused,
          invalid: !isPasswordFocused && isPasswordInvalid === true,
          valid: !isPasswordFocused && isPasswordInvalid === false,
        })}
      >
        <input
          type="password"
          placeholder={t('password')}
          name="password"
          data-testid="password"
          onChange={handleChangePassword}
          onFocus={handlePasswordFocus}
          onBlur={handlePasswordBlur}
          value={password}
        />
      </div>
      <div
        className={cx('input', {
          focused: isConfirmPasswordFocused,
          invalid:
            !isConfirmPasswordFocused && isConfirmPasswordInvalid === true,
          valid:
            !isConfirmPasswordFocused && isConfirmPasswordInvalid === false,
        })}
      >
        <input
          type="password"
          placeholder={t('confirmPassword')}
          name="confirm"
          data-testid="confirm"
          onChange={handleChangeConfirmPassword}
          onFocus={handleConfirmPasswordFocus}
          onBlur={handleConfirmPasswordBlur}
          value={confirmPassword}
        />
      </div>
      <div className={cx('help-text')}>{t('passwordHelpText')}</div>
    </AuthDialog>
  )
}

export { ResetPassword }
