import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import styles from './CompanyPerformanceFallback.module.scss'

const cx = classNames.bind(styles)

const CompanyPerformanceFallback = () => {
  const { t } = useTranslation()

  return (
    <div
      className={cx('company-performance-fallback')}
      data-testid="company-performance-fallback"
    >
      {t('widgets.companyPerformance.fallback')}
    </div>
  )
}

export { CompanyPerformanceFallback }
