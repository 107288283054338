import React from 'react'
import PropTypes from 'prop-types'
import { SliderArrow } from 'components/common/slider-arrow'
import classNames from 'classnames/bind'
import styles from './SliderArrows.module.scss'

const cx = classNames.bind(styles)

const SliderArrows = ({ sliderState, dispatch }) => {
  const { leftArrowActive, rightArrowActive, showArrowsStyle } = sliderState

  const handleLeftClick = () => dispatch({ type: 'handleLeftClick' })

  const handleRightClick = () => dispatch({ type: 'handleRightClick' })

  return (
    <div className={cx('slider-arrow-container')} style={showArrowsStyle}>
      <SliderArrow
        direction="left"
        handleClick={handleLeftClick}
        isActive={leftArrowActive}
      />
      <SliderArrow
        style={cx('slider-arrow')}
        direction="right"
        handleClick={handleRightClick}
        isActive={rightArrowActive}
      />
    </div>
  )
}

SliderArrows.propTypes = {
  sliderState: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
}

export { SliderArrows }
