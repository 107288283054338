import { TrendDirection } from './constants'
import { MetricTypes, ComparativePeriods } from 'utils/constants'
import { LocalesCountries } from 'utils/constants'
import moment from 'moment'

const isNullOrUndefined = value => value === null || value === undefined

const refreshPage = () => window.location.reload(false)

const getTrendDirection = trend => {
  // consistently round trend whether positive or negative
  const sanitisedTrend = (trend >= 0 || -1) * Math.round(Math.abs(trend))

  if (sanitisedTrend === 0) {
    return TrendDirection.NEUTRAL
  }

  return sanitisedTrend > 0 ? TrendDirection.POSITIVE : TrendDirection.NEGATIVE
}

const getComparativePeriod = metricType => {
  switch (metricType) {
    case MetricTypes.VOLUME:
      return ComparativePeriods.AVERAGE_DAY
    case MetricTypes.PROFIT:
      return ComparativePeriods.THIS_DAY_LAST_WEEK
    default:
      return ComparativePeriods.THIS_TIME_YESTERDAY
  }
}

const getFirstPartOfURLPath = pathname => {
  return pathname.split('/')[1]
}

const showPortfolioBenchmark = (showPortfolioBenchmarkTab, userLocale) => {
  let showPortfolioBenchmark = false

  if (showPortfolioBenchmarkTab) {
    const tabValues = showPortfolioBenchmarkTab.split(',')
    showPortfolioBenchmark = tabValues.includes(LocalesCountries[userLocale])
  }

  return showPortfolioBenchmark
}

const showRegionsDropdown = (showRegionsDropdownFlag, userLocale) => {
  let showRegionsDropdown = false

  if (showRegionsDropdownFlag) {
    const tabValues = showRegionsDropdownFlag.split(',')
    showRegionsDropdown = tabValues.includes(LocalesCountries[userLocale])
  }

  return showRegionsDropdown
}


/**
 * Return the fuel grade ids based on their titles
 * @param {Array} grades - list containing the titles of fuel grades in ISO string format
 * @return {Array}
 */
const fuelGradeTitlesToIds = (grades, allFuelGrades) => {
  if (!allFuelGrades) {
    return []
  }

  return grades.reduce((ids, grade) => {
    const fuelGrade = allFuelGrades.find((fuelGrade) => fuelGrade.title === grade)

    if (fuelGrade) {
      ids.push(fuelGrade.id)
    }
    return ids
  }, [])
}

/**
 * Check if an array contains dates spanning multiple years
 * @param {Array} dates - list of dates in ISO string format
 * @return {boolean}
 */
const hasDatesOfDifferentYears = (dates) =>{
  const uniqueYears = new Set(dates.map(date => new Date(date).getFullYear()))
  return uniqueYears.size > 1
}

/**
 * Format the date based on timeResolution
 * @param {string} date
 * @param {Array} dates - list of dates in ISO string format
 * @param {string} timeResolution - Valid values: 'Monthly', 'Quarterly', 'Yearly', 'Daily'
 * @return {string}
 */
const timePeriodFormat = (date, dates, timeResolution) => {
  const quarter = Math.ceil((moment.utc(date).month() + 1) / 3)
  const year = moment.utc(date).format('YYYY')

  switch (timeResolution) {
    case 'Monthly':
      return moment.utc(date).format('MMM YY')
    case 'Quarterly':
      return `Q${quarter} ${year}`
    case 'Yearly':
      return year
    default:
      const differentYears = hasDatesOfDifferentYears(dates)
      const dateFormat = differentYears ? 'Do MMM YY' : 'Do MMM'

      return moment.utc(date).format(dateFormat)
  }
}

/**
 * Its ensures that if the number is not already rounded
 * to the nearest power of 10, it rounds it up to the next power of 10.
 * If it is already rounded, it increases it by 10%
 * @param {number} number
 * @return {number}
 */
const nextRoundedNumber = (number) => {
  const exponent = Math.floor(Math.log10(number)) - 1
  const roundedNumber = Math.ceil(number / Math.pow(10, exponent)) * Math.pow(10, exponent)

  if (roundedNumber === number) {
    const increasedNumber = number + (number * 0.1)
    return increasedNumber
  }

  return roundedNumber
}

/**
 * Its ensures that if the number is not already rounded
 * to the nearest power of 10, it rounds it up to the previous power of 10.
 * If it is already rounded, it decreases it by 10%
 * @param {number} number
 * @return {number}
 */
const previousRoundedNumber = (number) => {
  const exponent = Math.floor(Math.log10(number)) - 1;
  const roundedNumber = Math.floor(number / Math.pow(10, exponent)) * Math.pow(10, exponent);
  
  if (roundedNumber === number) {
    const decreasedNumber = number - (number * 0.1);
    return decreasedNumber;
  }

  return roundedNumber;
}

/**
 * Calculates the maximum value for the Y-axis in a chart based on provided data.
 * @param {Object} data - The main data object.
 * @param {Object} lineData - An object representing specific lines in the chart, with keys corresponding to data keys.
 * @returns {number|null} - The maximum Y-axis value. Returns null if no valid data is provided.
 */
const maxYaxisValue = (data, lineData) => {
  if (!data) {
    return null
  }

  const convertToNumbers = (arr) => arr.map(val => parseFloat(val))
  let highestValue = -Infinity

  Object.keys(lineData).forEach(key => {
    const numbersArray = convertToNumbers(data[key]).filter((value) => !isNaN(value))
    const max = Math.max(...numbersArray)
    highestValue = Math.max(highestValue, max)
  })

  // Return the maximum value with fallback to 4
  return (highestValue === null || highestValue === -Infinity || highestValue === 0) ? 4 : highestValue
}

/**
 * Calculates the minimun value for the Y-axis in a chart based on provided data.
 * @param {Object} data - The main data object.
 * @param {Object} lineData - An object representing specific lines in the chart, with keys corresponding to data keys.
 * @returns {number|null} - The minimun Y-axis value. Returns null if no valid data is provided.
 */
const minYaxisValue = (data, lineData) => {
  if (!data) {
    return null;
  }

  const convertToNumbers = (arr) => arr.map(val => parseFloat(val));
  let lowestValue = Infinity;

  Object.keys(lineData).forEach(key => {
    const numbersArray = convertToNumbers(data[key]).filter(value => !isNaN(value));
    const min = Math.min(...numbersArray);
    lowestValue = Math.min(lowestValue, min);
  });

  // Return the minimum value with fallback to 0
  return (lowestValue === null || lowestValue === Infinity || isNaN(lowestValue)) ? 0 : lowestValue;
};

export {
  isNullOrUndefined,
  refreshPage,
  getTrendDirection,
  getComparativePeriod,
  getFirstPartOfURLPath,
  showPortfolioBenchmark,
  fuelGradeTitlesToIds,
  hasDatesOfDifferentYears,
  nextRoundedNumber,
  previousRoundedNumber,
  maxYaxisValue,
  minYaxisValue,
  timePeriodFormat,
  showRegionsDropdown,
}
