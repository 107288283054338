const PriceChangeStatus = {
  None: 'NONE',
  Edit: 'EDIT',
  Confirm: 'CONFIRM',
  Send: 'SEND',
  Sent: 'SENT',
  Error: 'ERROR',
}

const PriceChangeActions = {
  SetCurrentStatus: 'SET_CURRENT_STATUS',
  SetActionAt: 'SET_ACTION_AT',
  SetPrice: 'SET_PRICE',
  IncrementPrice: 'INCREMENT_PRICE',
  DecrementPrice: 'DECREMENT_PRICE',
  ChangedFuelGradeDifferential: 'CHANGED_FUEL_GRADE_DIFFERENTIAL',
  ToggleReminder: 'TOGGLE_REMINDER',
}

export { PriceChangeStatus, PriceChangeActions }
