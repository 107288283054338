import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'config/context/authContext'
import { LocalesCountries, MinPasswordComplexity } from 'utils/constants'
import { getPasswordComplexity } from 'utils/password'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { AuthDialog } from '../components/auth-dialog'
import styles from './SetPassword.module.scss'

const cx = classNames.bind(styles)

const getUserCountry = userLocale => {
  return LocalesCountries[userLocale] ?? LocalesCountries['en-GB']
}

const SetPassword = () => {
  const { t } = useTranslation('auth')
  const { setInitialPassword, isNotificationUser, userLocale, isEdgeDataProUser } = useAuth()
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isPhoneFocused, setPhoneFocused] = useState(false)
  const [isPasswordFocused, setPasswordFocused] = useState(false)
  const [isConfirmPasswordFocused, setConfirmPasswordFocused] = useState(false)
  const [error, setError] = useState(null)
  const [isPhoneValid, setPhoneInvalid] = useState(null)
  const [isPasswordInvalid, setPasswordInvalid] = useState(null)
  const [isConfirmPasswordInvalid, setConfirmPasswordInvalid] = useState(null)
  const [optedInToSMS, setOptedInToSMS] = useState(false)

  useEffect(() => {
    if (!optedInToSMS) {
      setPhone('')
    }

    if(isEdgeDataProUser) {
      setOptedInToSMS(true)
    }
  }, [optedInToSMS, setPhone])

  const handlePhoneFocus = () => setPhoneFocused(true)
  const handlePhoneBlur = () => setPhoneFocused(false)

  const handlePasswordFocus = () => setPasswordFocused(true)
  const handlePasswordBlur = () => setPasswordFocused(false)

  const handleConfirmPasswordFocus = () => setConfirmPasswordFocused(true)
  const handleConfirmPasswordBlur = () => setConfirmPasswordFocused(false)

  const handleSubmit = async () => {
    if (!isNotificationUser) {
      if (!password) {
        setError(null)
        return
      }

      const complexity = getPasswordComplexity(password)
      if (complexity < MinPasswordComplexity) {
        setError(t('passwordComplexityErrorText'))
        return
      }

      const matching = password === confirmPassword
      if (!matching) {
        setError(t('passwordsDoNotMatch'))
        return
      }
    }

    if (optedInToSMS && !isPhoneValid) {
      setError(t('providePhoneNumber'))
      return
    }

    setError(null)

    try {
      const finishedAccountSetupAt = new Date().toISOString()

      await setInitialPassword({
        mobileNumber: optedInToSMS && isPhoneValid ? phone : '',
        password,
        finishedAccountSetupAt: finishedAccountSetupAt,
      })
    } catch (err) {
      setError(t('genericErrorMessage'))
    }
  }

  const handleChangePhone = eventInput => {
    if (optedInToSMS && typeof eventInput === 'string') {
      setPhoneInvalid(isValidPhoneNumber(eventInput))
    }

    setPhone(eventInput)
  }

  const handleChangePassword = event => {
    const password = event.target.value
    const complexity = getPasswordComplexity(password)

    // fix bug with focus/blur and webkit's handling of autofill
    setPasswordFocused(true)
    setPasswordInvalid(
      password.length === 0 ? null : complexity < MinPasswordComplexity
    )
    setConfirmPasswordInvalid(
      confirmPassword.length === 0 ? null : confirmPassword !== password
    )
    setPassword(password)
  }

  const handleChangeConfirmPassword = event => {
    const confirmPassword = event.target.value

    // fix bug with focus/blur and webkit's handling of autofill
    setConfirmPasswordFocused(true)
    setConfirmPasswordInvalid(
      confirmPassword.length === 0 ? null : confirmPassword !== password
    )
    setConfirmPassword(confirmPassword)
  }

  const handleSMSOptIn = () => setOptedInToSMS(!optedInToSMS)

  return (
    <div className={cx('wrapper')}>
      <AuthDialog
        showLogo={false}
        headerClassName={cx('header')}
        title={t('finishAccountSetup')}
        buttonText={isEdgeDataProUser ? t('startUsingEdgeDataPro') : t('startUsingEdgePetrol')}
        error={error}
        onSubmit={handleSubmit}
      >
        <div className={cx('container')}>
          {!isNotificationUser && (
            <>
              <div
                className={cx('input', {
                  focused: isPasswordFocused,
                  invalid: !isPasswordFocused && isPasswordInvalid === true,
                  valid: !isPasswordFocused && isPasswordInvalid === false,
                })}
              >
                <input
                  type="password"
                  placeholder={t('password')}
                  name="password"
                  data-testid="password"
                  onChange={handleChangePassword}
                  onFocus={handlePasswordFocus}
                  onBlur={handlePasswordBlur}
                  value={password}
                />
              </div>
              <div
                className={cx('input', {
                  focused: isConfirmPasswordFocused,
                  invalid:
                    !isConfirmPasswordFocused &&
                    isConfirmPasswordInvalid === true,
                  valid:
                    !isConfirmPasswordFocused &&
                    isConfirmPasswordInvalid === false,
                })}
              >
                <input
                  type="password"
                  placeholder={t('passwordAgain')}
                  name="confirm"
                  data-testid="confirm"
                  onChange={handleChangeConfirmPassword}
                  onFocus={handleConfirmPasswordFocus}
                  onBlur={handleConfirmPasswordBlur}
                  value={confirmPassword}
                />
              </div>
              <div className={cx('help-text')}>{t('passwordHelpText')}</div>
            </>
          )}
          {!isEdgeDataProUser &&
            <>
              <div className={cx('opted-in-text')}>{t('optedInText')}</div>
              <label className={cx('sms-opt-in')}>
                <span>{t('smsCheckboxLabel')}</span>
                <input
                  type="checkbox"
                  data-testid="sms-opt-in-checkbox"
                  onChange={handleSMSOptIn}
                  defaultValue={optedInToSMS}
                />
              </label>
            </>
          }
          <PhoneInput
            placeholder={t('telephoneNumber')}
            name="phone"
            data-testid="phone"
            onChange={handleChangePhone}
            onFocus={handlePhoneFocus}
            onBlur={handlePhoneBlur}
            value={phone}
            disabled={!optedInToSMS}
            autoComplete="false"
            defaultCountry={getUserCountry(userLocale)}
            countryOptionsOrder={['GB', 'US', 'IE', '|']}
            className={cx('input', {
              focused: isPhoneFocused,
              invalid:
                !isPhoneFocused && optedInToSMS && isPhoneValid === false,
              valid: !isPhoneFocused && optedInToSMS && isPhoneValid === true,
              enabled: optedInToSMS,
              disabled: !optedInToSMS,
            })}
          />
          <div className={cx('opted-in-text')}>
            <a
              target="_blank"
              href="https://www.edgepetrol.com/terms-conditions"
              rel="noreferrer"
              referrerPolicy="no-referrer"
            >
              {t('termsAndConditions')}
            </a>
            {' | '}
            <a
              target="_blank"
              href="https://www.edgepetrol.com/data-policy"
              rel="noreferrer"
              referrerPolicy="no-referrer"
            >
              {t('privacyPolicy')}
            </a>
            {' | '}
            <a
              target="_blank"
              href="https://www.edgepetrol.com/sms-notification-policy"
              rel="noreferrer"
              referrerPolicy="no-referrer"
            >
              {t('smsNotificationPolicy')}
            </a>
          </div>
        </div>
      </AuthDialog>
    </div>
  )
}

export { SetPassword }
