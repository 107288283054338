import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './FuelGradeIcon.module.scss'
const cx = classNames.bind(styles)

const FuelGradeIcon = ({ fuelGrade }) => {
  const { primary, shortTitle } = fuelGrade

  return (
    <div className={cx('fuel-grade-icon')} style={{ background: `${primary}` }}>
      {shortTitle}
    </div>
  )
}

FuelGradeIcon.propTypes = {
  fuelGrade: PropTypes.shape().isRequired,
}

export { FuelGradeIcon }
