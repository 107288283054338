import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useStations } from 'components/common/hooks/useStations'
import styles from './StationNavigation.module.scss'
import { StationLink } from './components/station-link'

const cx = classNames.bind(styles)

const getNextAndPreviousIndexes = (stationId, stations) => {
  const stationCount = stations.length
  const currentIndex = stations.findIndex(({ id }) => id === stationId)

  let previousStationIndex = currentIndex - 1
  if (previousStationIndex < 0) {
    previousStationIndex = stationCount - 1
  }

  let nextStationIndex = currentIndex + 1
  if (nextStationIndex >= stationCount) {
    nextStationIndex = 0
  }

  return [previousStationIndex, nextStationIndex]
}

const StationNavigation = ({ stationId }) => {
  const stations = useStations()

  const [previousStationIndex, nextStationIndex] = getNextAndPreviousIndexes(
    stationId,
    stations
  )
  const previousStation = stations[previousStationIndex]
  const nextStation = stations[nextStationIndex]

  return (
    <div className={cx('station-navigation')}>
      <StationLink station={previousStation} />
      <span className={cx('divider')}></span>
      <StationLink station={nextStation} isNext />
    </div>
  )
}

StationNavigation.propTypes = {
  stationId: PropTypes.string.isRequired,
}

export { StationNavigation, getNextAndPreviousIndexes }
