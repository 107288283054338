import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { ReactComponent as Back } from 'images/arrows/arrow-left.svg'
import { ReactComponent as BackDisabled } from 'images/arrows/arrow-left-light.svg'
import { ReactComponent as Forward } from 'images/arrows/arrow-right.svg'
import { ReactComponent as ForwardDisabled } from 'images/arrows/arrow-right-light.svg'
import styles from './Arrows.module.scss'

const cx = classNames.bind(styles)

const Arrows = ({
  editPricingInUse,
  onBackClick,
  onForwardClick,
  backArrowActive,
  forwardArrowActive,
}) => {
  if (editPricingInUse) {
    return (
      <div className={cx('arrows')}>
        <div>
          <BackDisabled className={cx('disabled')} />
        </div>
        <div>
          <ForwardDisabled className={cx('disabled')} />
        </div>
      </div>
    )
  }

  return (
    <div className={cx('arrows')}>
      <div onClick={onBackClick}>
        {backArrowActive ? (
          <Back />
        ) : (
          <BackDisabled className={cx('disabled')} />
        )}
      </div>
      <div onClick={onForwardClick}>
        {forwardArrowActive ? (
          <Forward />
        ) : (
          <ForwardDisabled className={cx('disabled')} />
        )}
      </div>
    </div>
  )
}

Arrows.propTypes = {
  editPricingInUse: PropTypes.bool.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onForwardClick: PropTypes.func.isRequired,
  backArrowActive: PropTypes.bool.isRequired,
  forwardArrowActive: PropTypes.bool.isRequired,
}

export { Arrows }
