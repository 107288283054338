import i18n from 'i18next'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { Locales, PricingTypes } from 'utils/constants'
import { isNullOrUndefined } from 'utils/helpers'
import { convert24To12HourTime, isToday } from 'utils/dateTime'
import { timeIntervals, filteredTimeIntervals } from 'utils/timeIntervals'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import { PolePriceSources } from 'components/station/components/competitor-pricing/utils/constants'

const { EN, EN_GB, EN_IE, EN_US } = Locales

/**
 * Returns the date to display to a user for the given pole price.
 */
const determinePolePriceDate = polePrice => {
  return polePrice?.source === PolePriceSources.USER_DEFINED
    ? polePrice.priceAt
    : polePrice?.priceUpdatedAt
}

/**
 * Returns the price step between pole prices given the user's portfolio locale.
 */
const determinePriceStep = () => {
  switch (i18n.language) {
    case EN:
    case EN_GB:
    case EN_IE:
    case EN_US:
      return 1
    default:
      throw Error(`unsupported locale: ${i18n.language}`)
  }
}

/**
 * Returns the step between differentials given the user's portfolio locale.
 */
const determineDifferentialStep = () => {
  switch (i18n.language) {
    case EN:
    case EN_IE:
    case EN_GB:
      return 0.1
    case EN_US:
      return 1
    default:
      throw Error(`unsupported locale: ${i18n.language}`)
  }
}

/**
 * Returns true if the station supports cash/credit pricing but only has a single price available.
 */
const singlePrice = (noCashCreditStation, polePrice) => {
  if (noCashCreditStation) {
    return false
  }

  return polePrice?.pricingType === PricingTypes.ALL
}

/**
 * Returns the price for a given set of prices, selected pricing type and station cash/credit pricing status.
 */
const findPrice = (polePrices, pricingType, stationCashCreditPricing) => {
  const requiredPricingType = stationCashCreditPricing
    ? pricingType
    : PricingTypes.ALL
  return polePrices.find(
    polePrice => polePrice.pricingType === requiredPricingType
  )
}

/**
 * Returns the price change request for a given set of requests, selected pricing type and station cash/credit pricing status.
 */
const findPriceChangeRequest = (
  polePriceChangeRequests,
  pricingType,
  stationCashCreditPricing
) => {
  const requiredPricingType = stationCashCreditPricing
    ? pricingType
    : PricingTypes.ALL
  return polePriceChangeRequests.find(
    polePriceChangeRequest =>
      polePriceChangeRequest.pricingType === requiredPricingType
  )
}

/**
 * Returns the pending price change request for a given set of requests, selected pricing type and fuel grade.
 */
const findPendingRequest = (pendingRequests, pricingType, fuelGradeId) => {
  return pendingRequests.find(
    request =>
      request.pricingType === pricingType && request.fuelGradeId === fuelGradeId
  )
}

/**
 * Returns if the given pending price change request is for the given fuel grade and pricing type.
 */
const isMatchingPendingRequest = (pendingRequest, fuelGradeId, pricingType) => {
  return (
    pendingRequest.fuelGradeId === fuelGradeId &&
    pendingRequest.pricingType === pricingType &&
    !isNullOrUndefined(pendingRequest.price)
  )
}

/**
 * Returns the pending price change with a changed price for a given set of requests, selected pricing type and fuel grade.
 *
 * Returns null if no changed request found.
 */
const findChangedPendingRequest = (
  pendingRequests,
  pricingType,
  fuelGradeId
) => {
  const pendingRequest = findPendingRequest(
    pendingRequests,
    pricingType,
    fuelGradeId
  )
  return pendingRequest.price !== pendingRequest.newPrice
    ? pendingRequest
    : null
}

/**
 * Returns if the given grade has at least one changed pending request.
 */
const changedPendingRequest = (pendingRequests, fuelGradeId) => {
  return pendingRequests.some(
    request =>
      request.price !== request.newPrice && request.fuelGradeId === fuelGradeId
  )
}

/**
 * Generates a UTC price/price updated at from the selected hh:mm in the company's time zone.
 *
 * timestamp: optional argument that represents the current date shown to the user.
 */
const parsePolePriceTimestamps = (companyTimeZone, time, timestamp) => {
  const [hours, minutes] = time.split(':')

  const dayInZone = timestamp
    ? moment.utc(timestamp).tz(companyTimeZone)
    : moment.tz(companyTimeZone)

  const priceAt = dayInZone
    .set('hour', hours)
    .set('minute', minutes)
    .set('seconds', 0)
    .set('milliseconds', 0)
    .toISOString()

  const priceUpdatedAt = priceAt
  return [priceAt, priceUpdatedAt]
}

/**
 * Returns if the current status results in the confirm state of the CP widget.
 */
const showConfirm = status => {
  return ![PriceChangeStatus.None, PriceChangeStatus.Edit].includes(status)
}

const mapTimeInterval = (language, interval) => {
  const isUS = language === Locales.EN_US
  return {
    item: interval,
    displayItem: isUS ? convert24To12HourTime(interval) : interval,
  }
}

/**
 * Returns an array of time intervals, determined by the given timestamp.
 */
const determineTimeIntervals = (timestamp, companyTimeZone, t, language) => {
  const isCurrentDate =
    isNullOrUndefined(timestamp) ||
    isToday(companyTimeZone, timestamp, moment.utc())

  const intervals = isCurrentDate
    ? timeIntervalsWithNowFirst(companyTimeZone, t, language)
    : timeIntervals().map(interval => mapTimeInterval(language, interval))

  return intervals
}

/**
 * Returns an array of time intervals with the latest interval at the beginning,
 * determined by the given timestamp.
 */
const timeIntervalsWithNowFirst = (companyTimeZone, t, language) => {
  const filteredIntervals = filteredTimeIntervals(companyTimeZone, true)
  const intervals = filteredIntervals.map(interval =>
    mapTimeInterval(language, interval)
  )

  const { item, displayItem } = intervals[intervals.length - 1]
  const nowWithDisplayItem = {
    item,
    displayItem: `${t('widgets.competitorPricing.now')} (${displayItem})`,
  }

  intervals.pop()
  intervals.unshift(nowWithDisplayItem)

  return intervals
}

export {
  determinePolePriceDate,
  determinePriceStep,
  determineDifferentialStep,
  singlePrice,
  findPrice,
  findPriceChangeRequest,
  findPendingRequest,
  isMatchingPendingRequest,
  findChangedPendingRequest,
  changedPendingRequest,
  parsePolePriceTimestamps,
  showConfirm,
  determineTimeIntervals,
  timeIntervalsWithNowFirst,
}
