import React from 'react'
import PropTypes from 'prop-types'
import { StationRow } from './components/station-row'
import { ChangeRequests } from './components/change-requests'
import { PendingChangeRequests } from './components/pending-change-requests'
import { NoChangesBody } from './components/no-changes-body'

const StationRows = ({ pricesSent, mutationData, data, fuelGrades }) => {
  const { stationChangeRequests } = data

  if (pricesSent && mutationData) {
    const { createPolePriceChangeRequests } = mutationData

    return createPolePriceChangeRequests.map(
      ({ stationId, error, polePriceChangeRequests }) => {
        const {
          brand,
          name,
          cashCreditPricing,
          pendingRequests,
        } = stationChangeRequests.find(
          station => station.stationId === stationId
        )

        return (
          <StationRow
            key={stationId}
            stationId={stationId}
            cashCreditPricing={cashCreditPricing}
            brand={brand}
            name={name}
            error={error}
          >
            <ChangeRequests
              cashCreditPricing={cashCreditPricing}
              fuelGrades={fuelGrades}
              pendingRequests={pendingRequests}
              polePriceChangeRequests={polePriceChangeRequests}
              error={error}
            />
          </StationRow>
        )
      }
    )
  }

  const noChanges = stationChangeRequests.length === 0
  if (noChanges) {
    return <NoChangesBody />
  }

  return stationChangeRequests.map(
    ({ stationId, brand, name, pendingRequests, cashCreditPricing }) => {
      return (
        <StationRow
          key={stationId}
          stationId={stationId}
          cashCreditPricing={cashCreditPricing}
          brand={brand}
          name={name}
        >
          <PendingChangeRequests
            cashCreditPricing={cashCreditPricing}
            fuelGrades={fuelGrades}
            pendingRequests={pendingRequests}
          />
        </StationRow>
      )
    }
  )
}

StationRows.propTypes = {
  pricesSent: PropTypes.bool.isRequired,
  mutationData: PropTypes.shape(),
  data: PropTypes.shape().isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { StationRows }
