import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Info } from 'components/common/info'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import classNames from 'classnames/bind'
import styles from './SinglePriceSelector.module.scss'

const cx = classNames.bind(styles)

const SinglePriceSelector = ({ isSinglePrice, onClick }) => {
  const { t } = useTranslation()
  const Icon = isSinglePrice ? ActiveIcon : InactiveIcon

  return (
    <div className={cx('single-price-selector')}>
      <Icon
        height={20}
        width={20}
        onClick={onClick}
        data-testid="single-price-selector-icon"
      />
      <span>{t('widgets.competitorPricing.enterSinglePrice')}</span>
      <Info
        tooltipText={t('widgets.competitorPricing.singlePriceSelectorTooltip')}
      />
    </div>
  )
}

SinglePriceSelector.propTypes = {
  isSinglePrice: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export { SinglePriceSelector }
