import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { useTranslation } from 'react-i18next'
import { ImageAsset } from 'components/common/image-asset'
import { images } from 'images/image-library'
import styles from './Payment.module.scss'
import { MetricTypes } from 'utils/constants'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { PaymentFallback } from '../payment-fallback'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'

const cx = classNames.bind(styles)

const Payment = ({ payment, hasMetricPermission }) => {
  const { formatMetricValue } = useFormatMetricValue()
  const { t } = useTranslation()

  const { key: paymentKey, value, name } = payment

  const otherPayment = paymentKey === 'other'

  const tooltipText = otherPayment
    ? t('widgets.stationPerformance.unknownPaymentTooltip')
    : name

  const tooltip = (
    <Tooltip className={cx('payment-tooltip', { capitalise: !otherPayment })}>
      <div>{tooltipText}</div>
    </Tooltip>
  )

  const fallbackElement = <PaymentFallback paymentKey={paymentKey} />

  return (
    <div className={cx('payment-container')}>
      <OverlayTrigger placement={'top'} overlay={tooltip}>
        <div className={cx('payment-logo')}>
          <ImageAsset
            src={images.paymentLogo(paymentKey)}
            height={30}
            width={57}
            alt={`payment-logo-${paymentKey}`}
            fallbackElement={fallbackElement}
          />
        </div>
      </OverlayTrigger>

      <div className={cx('payment-value')}>
        <Permissioned
          permission={hasMetricPermission}
          fallback={<Placeholder fixedWidth={30} />}
        >
          {formatMetricValue(MetricTypes.VOLUME, value)}
        </Permissioned>
      </div>
    </div>
  )
}

Payment.propTypes = {
  payment: PropTypes.shape().isRequired,
  hasMetricPermission: PropTypes.bool.isRequired,
}

export { Payment }
