import React from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import styles from './WetstockData.module.scss'
import { PropTypes } from 'prop-types'
import { MetricTypes } from 'utils/constants'
import { WetstockMetric } from './wetstock-metric'

const cx = classNames.bind(styles)
const { MARGIN, COST, REPLACEMENT_COST } = MetricTypes

const WetstockData = ({
  blendedCost,
  replacementCost,
  margin,
  replacementCostTimestamp,
  replacementCostError,
}) => {
  const { t } = useTranslation()

  const blendedCostTitle = `${t('widgets.wetstockAnalysis.blendedCost')} ${t(
    'cost.short'
  )}`
  const replacementCostTitle = `${t(
    'widgets.wetstockAnalysis.replacementCost'
  )} ${t('cost.short')}`
  const marginTitle = `${t('widgets.wetstockAnalysis.margin')} ${t(
    'margin.short'
  )}`

  return (
    <div className={cx('wetstock-data-container')}>
      <WetstockMetric
        title={blendedCostTitle}
        value={blendedCost}
        type={COST}
      />
      <WetstockMetric
        title={replacementCostTitle}
        value={replacementCost}
        type={REPLACEMENT_COST}
        replacementCostTimestamp={replacementCostTimestamp}
        replacementCostError={replacementCostError}
      />
      <WetstockMetric title={marginTitle} value={margin} type={MARGIN} />
    </div>
  )
}

WetstockData.propTypes = {
  blendedCost: PropTypes.number.isRequired,
  replacementCost: PropTypes.number,
  margin: PropTypes.number.isRequired,
  replacementCostTimestamp: PropTypes.string,
  replacementCostError: PropTypes.string,
}

export { WetstockData }
