import { useState, useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'

function useLaunchDarkly(me) {
  const ldDisabled =
    process.env.REACT_APP_LAUNCH_DARKLY_ENABLED === 'false' ? true : false
  const [ldInitialised, setLdInitialised] = useState(ldDisabled)
  const ldClient = useLDClient()

  useEffect(() => {
    if (!ldClient || !me) {
      return
    }

    const { id, company, launchDarklyHash } = me

    const initialised = ldClient.getUser().key === id
    if (!initialised) {
      // enable app to work if LaunchDarkly is unavailable
      /* istanbul ignore next */
      ldClient.on('error', () => {
        setLdInitialised(true)
      })

      ldClient
        .identify(
          {
            key: id,
            custom: {
              company: company.id,
            },
          },
          launchDarklyHash
        )
        .finally(() => {
          setLdInitialised(true)
        })
    } else if (initialised && !ldInitialised) {
      setLdInitialised(true)
    }
  }, [ldClient, me, ldInitialised])

  return { ldInitialised }
}

export { useLaunchDarkly }
