import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation, useReactiveVar } from '@apollo/client'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { numberToFixed } from 'utils/number'
import { PricingTypes } from 'utils/constants'
import { timestampVar } from 'config/apollo/competitor-pricing/variables'
import { ReactComponent as PlusIcon } from 'images/competitor-pricing/cash-credit-differential/plus.svg'
import { ReactComponent as MinusIcon } from 'images/competitor-pricing/cash-credit-differential/minus.svg'
import { determineDifferentialStep } from 'components/station/components/competitor-pricing/utils/helpers'
import { useFormatPriceDifferential } from 'components/common/hooks/useFormatPriceDifferential'
import { useClickablePopover } from 'components/common/slider/hooks/useClickablePopover'
import { CREATE_CASH_CREDIT_DIFFERENTIAL_MUTATION } from 'components/station/components/competitor-pricing/CompetitorPricingMutations'
import { COMPETITOR_PRICING_QUERY } from 'components/station/components/competitor-pricing/CompetitorPricingQueries'
import classNames from 'classnames/bind'
import styles from './CashCreditDifferentialPopover.module.scss'

const { ALL, CASH, CREDIT } = PricingTypes

const cx = classNames.bind(styles)

const validDifferential = (value, cashDifferential) => {
  if (value === cashDifferential || value === 0) {
    return false
  }
  return true
}

const CashCreditDifferentialPopover = ({
  children,
  stationId,
  fuelGradeId,
  cashCreditDifferential,
  sliderDispatch,
}) => {
  const timestamp = useReactiveVar(timestampVar)
  const { t } = useTranslation()
  const { formatPriceDifferential } = useFormatPriceDifferential()
  const [value, setValue] = useState(cashCreditDifferential?.differential || 1)
  const popoverRef = 'cash-credit-differential-popover-ref'
  useClickablePopover(popoverRef, sliderDispatch)
  const [createCashCreditDifferential] = useMutation(
    CREATE_CASH_CREDIT_DIFFERENTIAL_MUTATION,
    {
      update(
        cache,
        {
          data: {
            createCashCreditDifferential: { error, cashCreditDifferential },
          },
        }
      ) {
        /* istanbul ignore next */
        if (error) {
          return
        }

        const variables = {
          stationId,
          supportedPricingTypes: [ALL, CASH, CREDIT],
          timestamp,
        }
        const {
          station,
          station: {
            priceDifferentials: {
              fuelGradeDifferentials,
              cashCreditDifferentials,
            },
          },
        } = cache.readQuery({
          query: COMPETITOR_PRICING_QUERY,
          variables,
        })

        const filteredCashCreditDifferentials = cashCreditDifferentials.filter(
          ({ fuelGradeId }) =>
            cashCreditDifferential.fuelGradeId !== fuelGradeId
        )

        const updatedPriceDifferentials = {
          fuelGradeDifferentials,
          cashCreditDifferentials: [
            ...filteredCashCreditDifferentials,
            cashCreditDifferential,
          ],
          __typename: 'PriceDifferentialsSet',
        }

        const updatedStation = {
          ...station,
          priceDifferentials: updatedPriceDifferentials,
        }
        cache.writeQuery({
          query: COMPETITOR_PRICING_QUERY,
          variables,
          data: { station: updatedStation },
        })
      },
      onCompleted() {
        document.body.click()
      },
    }
  )

  const differentialStep = determineDifferentialStep()

  const handleMinusClick = () => {
    if (value > 1) {
      setValue(numberToFixed(value - differentialStep))
    }
  }

  const handlePlusClick = () => {
    setValue(numberToFixed(value + differentialStep))
  }

  const handleSubmitClick = () => {
    if (!disabled) {
      sliderDispatch({
        type: 'enabled',
        payload: true,
      })
      createCashCreditDifferential({
        variables: {
          stationId,
          fuelGradeId,
          differential: value,
        },
      })
    }
  }

  const disabled = !validDifferential(
    value,
    cashCreditDifferential?.differential
  )

  const overlay = (
    <Popover id={popoverRef} className={cx('cash-credit-differential-popover')}>
      <Popover.Content className={cx('content')}>
        <h3>{t('widgets.competitorPricing.cashDiscountDifferential')}</h3>
        <div className={cx('value')}>{formatPriceDifferential(value, 0)}</div>
        <div className={cx('buttons')}>
          <div
            className={cx('minus')}
            onClick={handleMinusClick}
            data-testid="minus"
          >
            <MinusIcon />
          </div>
          <div
            className={cx('plus')}
            onClick={handlePlusClick}
            data-testid="plus"
          >
            <PlusIcon />
          </div>
        </div>
        <button className={cx('btn', { disabled })} onClick={handleSubmitClick}>
          Save
        </button>
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={overlay}
      rootClose
    >
      {children}
    </OverlayTrigger>
  )
}

CashCreditDifferentialPopover.propTypes = {
  children: PropTypes.shape().isRequired,
  stationId: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  cashCreditDifferential: PropTypes.shape(),
  sliderDispatch: PropTypes.func.isRequired,
}

CashCreditDifferentialPopover.defaultProps = {
  cashCreditDifferential: null,
}

export { CashCreditDifferentialPopover }
