import { gql } from '@apollo/client'

const PERIOD_ANALYSIS_QUERY = gql`
  query periodAnalysis(
    $stationIds: [ID!]!
    $fuelGradeIds: [ID!]!
    $currentPeriodStartDate: String!
    $currentPeriodEndDate: String!
    $previousPeriodStartDate: String
    $previousPeriodEndDate: String
    $timeResolution: String
    $stationGroupNames: [String]
    $activeTab: String
    $metricName: String
  ) {
    periodAnalysis(
      input: {
        stationIds: $stationIds
        fuelGradeIds: $fuelGradeIds
        currentPeriodStartDate: $currentPeriodStartDate
        currentPeriodEndDate: $currentPeriodEndDate
        previousPeriodStartDate: $previousPeriodStartDate
        previousPeriodEndDate: $previousPeriodEndDate
        timeResolution: $timeResolution
        stationGroupNames: $stationGroupNames
        activeTab: $activeTab
        metricName: $metricName
      }
    ) {
      data {
        label
        values
      }
      datesCurrentPeriod
      datesPreviousPeriod
    }
  }
`

export { PERIOD_ANALYSIS_QUERY }
