const Locales = {
  EN: 'en',
  EN_GB: 'en-GB',
  EN_US: 'en-US',
  EN_IE: 'en-IE',
}

const LocalesCountries = {
  'en-GB': 'GB',
  'en-IE': 'IE',
  'en-US': 'US',
}

const Widgets = {
  DEFAULT: 'DEFAULT',
  DELIVERY: 'DELIVERY',
  WETSTOCK: 'WETSTOCK',
  STATION_PERFORMANCE: 'STATION_PERFORMANCE',
  BUYSMART: 'BUYSMART',
  COMPETITOR_PRICING: 'COMPETITOR_PRICING',
  PERFORMANCE_REPORT: 'PERFORMANCE_REPORT',
}

const WidgetIds = {
  BUYSMART: 'buysmart-container',
}

const ElementMargins = {
  DEFAULT_MARGIN: 10,
  DELIVERY_MARGIN: 10,
  WETSTOCK_MARGIN: 10,
  BUYSMART_MARGIN: 10,
}

const ElementWidths = {
  DEFAULT: 200,
  DELIVERY: 190,
  WETSTOCK: 288,
  BUYSMART: 150,
}

const TimestampTypes = {
  TIME: 'TIME',
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  DAY_MONTH: 'DAY_MONTH',
  DAY_MONTH_YEAR: 'DAY_MONTH_YEAR',
}

const PerformanceProfitTypes = {
  GROSS_PROFIT: 'GROSS_PROFIT',
  NET_PROFIT: 'NET_PROFIT',
  BUSINESS_PROFIT: 'BUSINESS_PROFIT',
}

const PerformanceTypes = {
  RETAIL_VOLUME: 'RETAIL_VOLUME',
  GROSS_MARGIN: 'GROSS_MARGIN',
  NET_MARGIN: 'NET_MARGIN',
}

const PricingTypes = {
  CASH: 'CASH',
  CREDIT: 'CREDIT',
  ALL: 'ALL',
}

const MetricTypes = {
  VOLUME: 'VOLUME',
  MARGIN: 'MARGIN',
  PROFIT: 'PROFIT',
  PERCENTAGE: 'PERCENTAGE',
  NUMBER: 'NUMBER',
  COST: 'COST',
  REPLACEMENT_COST: 'REPLACEMENT_COST',
  BUNKER_COMMISSION: 'BUNKER_COMMISSION',
  FILL_UP: 'FILL_UP',
  POLE_PRICE: 'POLE_PRICE',
}

const TrendDirection = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NEUTRAL: 'NEUTRAL',
}

const ComparativePeriods = {
  AVERAGE_DAY: 'AVERAGE_DAY',
  THIS_TIME_YESTERDAY: 'THIS_TIME_YESTERDAY',
  THIS_DAY_LAST_WEEK: 'THIS_TIME_LAST_WEEK',
}

const FuelGradeNames = {
  TOTAL: 'Total',
  UNLEADED: 'Unleaded',
  UNLEADED_SUPER: 'Unleaded Super',
  DIESEL: 'Diesel',
  DIESEL_SUPER: 'Diesel Super',
  GASOIL: 'Gasoil',
  ADBLUE: 'Adblue',
}

const BuysmartEventColours = {
  WARNING: '#ff7756',
  STOCKOUT: '#ff5676',
}

const MetricPermissions = {
  MARGIN_PERMISSION: 'margin',
  PROFIT_PERMISSION: 'profit',
  VOLUME_PERMISSION: 'volume',
}

const FeaturePermissions = {
  TARGET_MARGIN_PERMISSION: 'target-margin',
  EDIT_PRICING_PERMISSION: 'edit-pricing',
  PRICING_STRATEGY_PERMISSION: 'pricing-strategy',
}

const WidgetPermissions = {
  STATIONS_LIST_PERMISSION: 'stations-list-widget',
  STATION_PERFORMANCE_PERMISSION: 'station-performance-widget',
  COMPETITOR_PRICING_PERMISSION: 'competitor-pricing-widget',
  DELIVERY_PERMISSION: 'delivery-widget',
  WETSTOCK_PERMISSION: 'wetstock-widget',
  BUYSMART_PERMISSION: 'buysmart-widget',
  PERFORMANCE_REPORTING_SINGLE_STATION_PERMISSION:
    'performance-reporting-single-station-widget',
  PERFORMANCE_REPORTING_PORTFOLIO_PERMISSION:
    'performance-reporting-portfolio-widget',
  ANALYTICS: 'analytics'
}

const StationStatus = {
  CLOSED: 'closed',
}

const StationDataStatus = {
  GREEN: 'green',
  AMBER: 'amber',
  RED: 'red',
}

const StationDataTypes = {
  TRANSACTION: 'TRANSACTION',
  COST_PRICE: 'COST_PRICE',
  DIP: 'DIP',
}

const NotificationTypes = {
  EXCEPTIONAL_VOLUME_DOWN: 'volume_change_down',
  EXCEPTIONAL_VOLUME_UP: 'volume_change_up',
  LOW_TANK_VOLUME: 'tank_threshold',
  PREDICTED_STOCKOUT: 'stock_out_prediction',
}

const TimeFormats = {
  TWELVE_HOUR: 'h:mma',
  TWENTY_FOUR_HOUR: 'HH:mm',
}

const PortfolioBenchmark = 'PORTFOLIO_BENCHMARK'
const PeriodAnalysis = 'PERIOD_ANALYSIS'
const CompetitorAnalysis = 'COMPETITOR_ANALYSIS'

// 15 minutes
const StationStatusPollInterval = 60_000 * 15

const EstimatedMarginDebounce = 1000

const MarginRegex = /^\d+(\.\d{1,2})?$/

const DefaultPolePriceRegex = /^\d{1,3}(\.\d)?$/
const USPolePriceRegex = /^\d{1,2}(\.\d{1,2})?$/
const USPriceHasFractionRegex = /.*\.9$/

// https://emailregex.com
// eslint-disable-next-line no-control-regex
const EmailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

const MinPasswordComplexity = 16

export {
  EmailRegex,
  Locales,
  LocalesCountries,
  PerformanceProfitTypes,
  PerformanceTypes,
  PricingTypes,
  MetricTypes,
  TrendDirection,
  TimestampTypes,
  ElementWidths,
  ElementMargins,
  Widgets,
  WidgetIds,
  ComparativePeriods,
  FuelGradeNames,
  MarginRegex,
  DefaultPolePriceRegex,
  USPolePriceRegex,
  USPriceHasFractionRegex,
  BuysmartEventColours,
  MetricPermissions,
  FeaturePermissions,
  WidgetPermissions,
  StationStatus,
  StationDataStatus,
  StationDataTypes,
  NotificationTypes,
  StationStatusPollInterval,
  EstimatedMarginDebounce,
  MinPasswordComplexity,
  TimeFormats,
  PortfolioBenchmark,
  PeriodAnalysis,
  CompetitorAnalysis,
}
