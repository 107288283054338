import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { findPrice } from 'components/station/components/competitor-pricing/utils/helpers'
import { stationCashCreditStatus } from 'utils/pricing'
import { PricingTypes } from 'utils/constants'
import { PriceCell } from './components/price-cell'
import { PricesCell } from './components/prices-cell'

const { ALL, CASH, CREDIT } = PricingTypes

const CompetitorPriceCell = ({
  station,
  fuelGrade,
  competitor,
  polePrices,
  status,
}) => {
  const { selectedPricingType, cashCreditPricing } = usePricingTypes()
  const [editPriceMode, setEditPriceMode] = useState(false)

  const { cashCreditPricing: competitorCashCreditPricing } = competitor
  const { id: stationId } = station

  const allPrice = findPrice(polePrices, ALL, competitorCashCreditPricing)

  if (
    selectedPricingType === ALL &&
    cashCreditPricing &&
    competitorCashCreditPricing
  ) {
    const cashPrice = findPrice(polePrices, CASH, competitorCashCreditPricing)
    const creditPrice = findPrice(
      polePrices,
      CREDIT,
      competitorCashCreditPricing
    )

    // show two prices if either cash/credit price available or no all price
    if (cashPrice || creditPrice || !allPrice) {
      return (
        <PricesCell
          status={status}
          stationId={stationId}
          fuelGrade={fuelGrade}
          competitor={competitor}
          cashPrice={cashPrice}
          creditPrice={creditPrice}
          editPriceMode={editPriceMode}
          setEditPriceMode={setEditPriceMode}
        />
      )
    }
  }

  // lookup pole price for the current pricing type, else default to the ALL price
  const pricingTypePolePrice = findPrice(
    polePrices,
    selectedPricingType,
    competitorCashCreditPricing
  )
  const polePrice = pricingTypePolePrice || allPrice

  const hasNoCashCreditPrice =
    stationCashCreditStatus(cashCreditPricing, competitorCashCreditPricing) ||
    (cashCreditPricing && polePrice?.pricingType === ALL)

  return (
    <PriceCell
      status={status}
      selectedPricingType={selectedPricingType}
      cashCreditPricing={cashCreditPricing}
      fuelGrade={fuelGrade}
      station={station}
      competitor={competitor}
      polePrice={polePrice}
      hasNoCashCreditPrice={hasNoCashCreditPrice}
      editPriceMode={editPriceMode}
      setEditPriceMode={setEditPriceMode}
    />
  )
}

CompetitorPriceCell.propTypes = {
  status: PropTypes.string.isRequired,
  station: PropTypes.shape().isRequired,
  fuelGrade: PropTypes.shape().isRequired,
  competitor: PropTypes.shape().isRequired,
  polePrices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { CompetitorPriceCell }
