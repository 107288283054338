import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import {
  createMarker,
  checkMarkerClass,
  checkPriceClass,
  filterPrices,
} from './utils'

const MapMarkers = ({ station, selectedFuelGrade }) => {
  const { t } = useTranslation(['translations'])
  const { formatPolePrice } = useFormatPolePrice()
  const { selectedPricingType } = usePricingTypes()
  const competitors = station.competitors
  const {allPrice, cashPrice, creditPrice} = filterPrices(station, selectedFuelGrade)

  const formattedStationPrices = {
    allPrice: formatPolePrice(allPrice),
    cashPrice: formatPolePrice(cashPrice),
    creditPrice: formatPolePrice(creditPrice)
  }

  const stationSelectedPrice = selectedPricingType => {
    if (!station.cashCreditPricing || selectedPricingType === 'ALL') {
      return formattedStationPrices?.allPrice
    }

    switch (selectedPricingType) {
      case 'CASH':
        return formattedStationPrices?.cashPrice || formattedStationPrices?.allPrice;
      case 'CREDIT':
        return formattedStationPrices?.creditPrice || formattedStationPrices?.allPrice;
      default:
        return formattedStationPrices?.allPrice;
    }
  }

  const competitorSelectedPrice = (competitor, competitorSelectedPrices) => {
    if (!competitor.cashCreditPricing) {
      return competitorSelectedPrices?.allPrice
    }

    if (selectedPricingType === 'CASH') {
      return (
        competitorSelectedPrices?.cashPrice ||
        competitorSelectedPrices?.allPrice
      )
    }

    if (selectedPricingType === 'CREDIT') {
      return (
        competitorSelectedPrices?.creditPrice ||
        competitorSelectedPrices?.allPrice
      )
    }
  }

  const validFuelGrades = useMemo(
    () =>
      station.fuelGrades.map(({ fuelGrade }) => ({
        id: fuelGrade.id,
        shortTitle: fuelGrade.shortTitle,
        color: fuelGrade.primary,
      })),
    [station]
  )

  const cashCreditPricingEnabled =
    station.cashCreditPricing ||
    competitors.some(station => station.cashCreditPricing)

  const isCashCreditMarker =
    cashCreditPricingEnabled && selectedPricingType === 'ALL'

  const stationPriceClasses = {
    allPriceClass: 'my-station-price',
    cashPriceClass: 'my-station-price',
    creditPriceClass: 'my-station-price'
  }

  const markers = [
    createMarker(
      station,
      validFuelGrades,
      selectedFuelGrade,
      stationSelectedPrice(selectedPricingType),
      formattedStationPrices,
      'default',
      stationPriceClasses,
      true,
      isCashCreditMarker,
      t
    ),
    competitors.map(station => {
      const {allPrice, cashPrice, creditPrice} = filterPrices(station, selectedFuelGrade)

      const formattedCompetitorPrices = {
        allPrice: formatPolePrice(allPrice),
        cashPrice: formatPolePrice(cashPrice),
        creditPrice: formatPolePrice(creditPrice)
      }

      const competitorPriceClasses = {
        allPriceClass: checkPriceClass(
          formattedStationPrices?.allPrice || formattedStationPrices?.cashPrice,
          formattedCompetitorPrices?.allPrice
        ),
        cashPriceClass: checkPriceClass(
          formattedStationPrices?.cashPrice || formattedStationPrices?.allPrice,
          formattedCompetitorPrices?.cashPrice
        ),
        creditPriceClass: checkPriceClass(
          formattedStationPrices?.creditPrice || formattedStationPrices?.allPrice,
          formattedCompetitorPrices?.creditPrice
        )
      }

      const markerClass = checkMarkerClass(
        stationSelectedPrice(selectedPricingType),
        competitorSelectedPrice(station, formattedCompetitorPrices),
        isCashCreditMarker
      )

      return createMarker(
        station,
        validFuelGrades,
        selectedFuelGrade,
        competitorSelectedPrice(station, formattedCompetitorPrices),
        formattedCompetitorPrices,
        markerClass,
        competitorPriceClasses,
        false,
        isCashCreditMarker,
        t
      )
    }),
  ]
  return markers
}

MapMarkers.propTypes = {
  station: PropTypes.shape().isRequired,
  selectedFuelGrade: PropTypes.string.isRequired,
}

export { MapMarkers }
