import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './HeaderRow.module.scss'

const cx = classNames.bind(styles)

const HeaderRow = ({ fuelGrades }) => {
  return (
    <div className={cx('header-row')}>
      {fuelGrades.map(({ id, shortTitle }) => {
        return (
          <div key={id} className={cx('fuel-grade-cell')}>
            {shortTitle}
          </div>
        )
      })}
    </div>
  )
}

HeaderRow.propTypes = {
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { HeaderRow }
