import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from './resources'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  returnObjects: true,
  ns: ['translations', 'auth', 'features'],
  defaultNS: ['translations'],
  resources,
  interpolation: {
    escapeValue: false, // react already safe from xss
  },
})

export default i18n
