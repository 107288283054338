import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { images } from 'images/image-library'
import { ImageAsset } from 'components/common/image-asset'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { determinePolePriceDate } from 'components/station/components/competitor-pricing/utils/helpers'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import { PricingTypes } from 'utils/constants'
import classNames from 'classnames/bind'
import { MatchPriceButtons } from './components/match-price-buttons'
import { PricePopover } from '../../../popovers'
import { PreviousPrice } from '../../../popovers/components/previous-price'
import styles from './PriceCell.module.scss'

const cx = classNames.bind(styles)

const PriceCell = ({
  status,
  selectedPricingType,
  cashCreditPricing,
  station,
  fuelGrade,
  competitor,
  polePrice,
  hasNoCashCreditPrice,
  editPriceMode,
  setEditPriceMode,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const { formatPolePrice, formatPolePriceDate } = useFormatPolePrice()

  const handleMouseEnter = () => {
    if (status === PriceChangeStatus.Edit) {
      setIsHovered(true)
    } /* istanbul ignore next */ else if (isHovered) {
      setIsHovered(false)
    }
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const { influencer } = competitor
  const {
    id: stationId,
    cashCreditPricing: stationCashCreditPricing,
    priceDifferentials: { fuelGradeDifferentials },
  } = station

  const polePriceDate = determinePolePriceDate(polePrice)
  const showPopover = status === PriceChangeStatus.None

  const isChildGrade = fuelGradeDifferentials.some(
    ({ fuelGradeId, leadGrade }) => {
      return fuelGradeId === polePrice?.fuelGradeId && !leadGrade
    }
  )
  const showMatchPriceButtons =
    isHovered &&
    !isChildGrade &&
    (!stationCashCreditPricing || selectedPricingType !== PricingTypes.ALL)

  const priceCell = (
    <div
      className={cx('price-cell', {
        influencer,
        'no-cash-credit': hasNoCashCreditPrice,
        popover: showPopover,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="price-cell"
    >
      <div className={cx('price-date')}>
        {formatPolePriceDate(polePriceDate)}
      </div>
      <div className={cx('price-value')}>
        <div data-testid="price">{formatPolePrice(polePrice?.price)}</div>
        {!showMatchPriceButtons && <PreviousPrice polePrice={polePrice} />}
        {polePrice?.source && !showMatchPriceButtons && (
          <ImageAsset
            src={images.polePriceShort(polePrice.source)}
            width={18}
            height={18}
            alt={polePrice.source}
            fallbackImage={null}
          />
        )}
        {showMatchPriceButtons && (
          <MatchPriceButtons
            selectedPricingType={
              stationCashCreditPricing ? selectedPricingType : PricingTypes.ALL
            }
            fuelGradeId={fuelGrade.id}
            polePrice={polePrice}
            className={cx('match-price-buttons')}
          />
        )}
      </div>
    </div>
  )

  if (!showPopover) {
    return priceCell
  }

  return (
    <PricePopover
      stationId={stationId}
      fuelGrade={fuelGrade}
      competitor={competitor}
      polePrice={polePrice}
      selectedPricingType={selectedPricingType}
      cashCreditPricing={cashCreditPricing}
      editPriceMode={editPriceMode}
      setEditPriceMode={setEditPriceMode}
    >
      {priceCell}
    </PricePopover>
  )
}

PriceCell.propTypes = {
  status: PropTypes.string.isRequired,
  selectedPricingType: PropTypes.string.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
  station: PropTypes.shape().isRequired,
  fuelGrade: PropTypes.shape().isRequired,
  competitor: PropTypes.shape().isRequired,
  polePrice: PropTypes.shape(),
  hasNoCashCreditPrice: PropTypes.bool.isRequired,
  editPriceMode: PropTypes.bool.isRequired,
  setEditPriceMode: PropTypes.func.isRequired,
}

PriceCell.defaultProps = {
  polePrice: null,
}

export { PriceCell }
