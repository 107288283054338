import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SubmitIcon } from 'images/pole-prices/submit.svg'
import { ReactComponent as ConfirmErrorIcon } from 'images/pole-prices/confirm-error.svg'
import { ReactComponent as ConfirmIcon } from 'images/pole-prices/confirm.svg'
import { ReactComponent as LoadingIcon } from 'images/common/loading.svg'
import { ReactComponent as CancelIcon } from 'images/common/cancel.svg'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import classNames from 'classnames/bind'
import { SentModal } from '../sent-modal'
import { ErrorModal } from '../error-modal'
import styles from './StatusIndicator.module.scss'

const cx = classNames.bind(styles)

const { CONFIRM, SEND, SENT, ERROR } = StationsListPricingStatus

const StatusIndicator = ({ loading, status }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(true)

  const handleHide = () => {
    amplitudeEvent(UserEvents.GroupEditPricing.COMPLETED)
    setShowModal(false)
  }

  const handleSubmit = () => {
    stationsListPricingOperations.setStatus(SEND)
  }

  const handleCancel = () => {
    if (!loading) {
      stationsListPricingOperations.setStatus(CONFIRM)
    }
  }

  const handleConfirm = () => {
    stationsListPricingOperations.setNoneStatus()
  }

  switch (status) {
    case CONFIRM:
      return (
        <SubmitIcon
          className={cx('submit-icon')}
          height={42}
          width={42}
          onClick={handleSubmit}
          data-testid="submit-icon"
        />
      )
    case SEND:
      return (
        <>
          <div className={cx('sending-text')}>
            {t('widgets.stationsListPricing.sendingChanges')}
          </div>
          <div
            className={cx('sending-icon-wrapper', { loading })}
            onClick={handleCancel}
            data-testid="sending-icon-wrapper"
          >
            <LoadingIcon height={50} width={50} />
            <CancelIcon className={cx('cancel-icon')} height={42} width={42} />
          </div>
        </>
      )
    case SENT:
      return (
        <>
          <div className={cx('sent-text')}>
            {t('widgets.stationsListPricing.changesSent')}
          </div>
          <ConfirmIcon
            className={cx('confirm-icon')}
            height={42}
            width={42}
            onClick={handleConfirm}
          />
          <SentModal show={showModal} onHide={handleHide} />
        </>
      )
    case ERROR:
      return (
        <>
          <div className={cx('error-text')}>
            {t('widgets.stationsListPricing.changesError')}
          </div>
          <ConfirmErrorIcon
            className={cx('error-icon')}
            height={42}
            width={42}
            onClick={handleConfirm}
            data-testid="error-icon"
          />
          <ErrorModal show={showModal} onHide={handleHide} />
        </>
      )
    default:
      return null
  }
}

StatusIndicator.propTypes = {
  loading: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
}

export { StatusIndicator }
