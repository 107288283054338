/* istanbul ignore file */

import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { IntercomProvider } from 'react-use-intercom'
import { ToastContainer } from 'react-toastify'
import 'config/locale/i18n'
import { AppWithLDProvider } from './components/App'
import { AuthProvider } from './config/context/authContext'
import { sentryInit } from './config/sentry'
import { client } from './config/apollo'
import * as serviceWorker from './serviceWorker'
import { useHeap } from './components/common/hooks/useHeap'
import 'react-toastify/dist/ReactToastify.min.css'
import './index.scss'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_KEY
const INTERCOM_ENABLED = process.env.REACT_APP_INTERCOM_ENABLED === 'true'

if (process.env.NODE_ENV !== 'development') {
  sentryInit()
}

function ScrollToTop() {
  const { pathname } = useLocation()
  const { useLoadHeap } = useHeap()

  useLoadHeap()

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 0)
  }, [pathname])

  return null
}

const element = (
  <Router>
    <ScrollToTop />
    <ApolloProvider client={client}>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        shouldInitialize={INTERCOM_ENABLED}
      >
        <AuthProvider>
          <AppWithLDProvider />
        </AuthProvider>
      </IntercomProvider>
    </ApolloProvider>
    <ToastContainer />
  </Router>
)

const container = document.getElementById('root')

ReactDOM.render(element, container)

serviceWorker.unregister()
