import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CancelEditIcon } from 'images/stations-list/edit-pricing/edit-cancel.svg'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { ExitPricingModal } from 'components/stations-list/components/stations-table/components/edit-pricing/exit-pricing-modal'
import { EditPricesButton } from 'components/stations-list/components/stations-table/components/edit-pricing/edit-prices-button'
import classNames from 'classnames/bind'
import styles from './StatusSelector.module.scss'

const cx = classNames.bind(styles)

const { NONE, SEND, SENT, ERROR } = StationsListPricingStatus

const StatusSelector = ({ status, currentStatus }) => {
  const [showModal, setShowModal] = useState(false)

  if (currentStatus === SEND) {
    return null
  }

  const isNone = status === NONE

  const handleClick = () => {
    if ([SENT, ERROR].includes(currentStatus)) {
      stationsListPricingOperations.setNoneStatus()
    } else if (isNone) {
      setShowModal(true)
    } else {
      stationsListPricingOperations.setStatus(status)
    }
  }

  const handleConfirm = event => {
    event.stopPropagation()
    stationsListPricingOperations.setNoneStatus()
    setShowModal(false)
  }

  const handleCancel = event => {
    event.stopPropagation()
    setShowModal(false)
  }

  const icon = isNone ? (
    <>
      <CancelEditIcon width={40} height={40} />
      <ExitPricingModal
        show={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  ) : (
    <EditPricesButton />
  )

  return (
    <div
      className={cx('status-selector', { cancel: isNone })}
      onClick={handleClick}
      data-testid="status-selector"
    >
      {icon}
    </div>
  )
}

StatusSelector.propTypes = {
  currentStatus: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

export { StatusSelector }
