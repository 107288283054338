import { gql } from '@apollo/client'
import {
  COMPETITOR_POLE_PRICE_ATTRIBUTES,
  POLE_PRICE_CHANGE_REQUEST_ATTRIBUTES,
  COMPETITORS_ATTRIBUTES,
} from 'config/apollo/fragments'

const COMPETITOR_PRICING_QUERY = gql`
  query competitorPricingQuery(
    $supportedPricingTypes: [PricingType!]!
    $stationId: ID!
    $timestamp: String
  ) {
    station(id: $stationId) {
      id
      longitude
      latitude
      cashCreditPricing
      priceChangeEnabled
      priceDifferentials {
        fuelGradeDifferentials {
          id
          fuelGradeId
          leadGrade
          leadGradeId
          differential
        }
        cashCreditDifferentials {
          id
          fuelGradeId
          differential
        }
      }
      stationStrategy {
        id
        strategy
      }
      polePrices(pricingTypes: $supportedPricingTypes, timestamp: $timestamp) {
        id
        fuelGradeId
        price
        priceUpdatedAt
        source
        pricingType
      }
      polePriceChangeRequests(pricingTypes: $supportedPricingTypes) {
        ...PolePriceChangeRequestAttributes
      }
      sortedCompetitors @client {
        ...CompetitorsAttributes
        polePrices(
          pricingTypes: $supportedPricingTypes
          timestamp: $timestamp
        ) {
          ...CompetitorPolePriceAttributes
        }
      }
      competitors {
        ...CompetitorsAttributes
        longitude
        latitude
        polePrices(
          pricingTypes: $supportedPricingTypes
          timestamp: $timestamp
        ) {
          ...CompetitorPolePriceAttributes
        }
      }
    }
  }
  ${COMPETITOR_POLE_PRICE_ATTRIBUTES}
  ${POLE_PRICE_CHANGE_REQUEST_ATTRIBUTES}
  ${COMPETITORS_ATTRIBUTES}
`
export { COMPETITOR_PRICING_QUERY }
