import React from 'react'
import PropTypes from 'prop-types'
import { useApolloClient, gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { getFilterAndGroupDropdownName } from 'config/amplitude/utils'
import { upperFirstOnly } from 'utils/format'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import classNames from 'classnames/bind'
import styles from './GroupByPopover.module.scss'

const cx = classNames.bind(styles)

const renderGroupBys = (groupBys, selectedGroupBy, client) => {
  return groupBys.map(({ name }) => {
    const Icon = name === selectedGroupBy ? ActiveIcon : InactiveIcon

    const handleClick = () => {
      name !== selectedGroupBy &&
        amplitudeEvent(
          UserEvents.GroupedBy.SELECTED(getFilterAndGroupDropdownName(name))
        )

      const newSelectedGroupBy = selectedGroupBy === name ? null : name

      const data = {
        stationsList: {
          selectedGroupBy: newSelectedGroupBy,
        },
      }
      client.writeQuery({
        query: gql`
          {
            stationsList {
              selectedGroupBy
            }
          }
        `,
        data,
      })

      document.body.click()
    }

    return (
      <div
        key={name}
        className={cx('group-by')}
        onClick={handleClick}
        data-testid="group-by"
      >
        <span>{upperFirstOnly(name)}</span>
        <Icon />
      </div>
    )
  })
}

const GroupByPopover = ({ children, groupBys, selectedGroupBy }) => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const overlay = (
    <Popover id="group-by-popover" className={cx('group-by-popover')}>
      <Popover.Content className={cx('content')}>
        <h3 className={cx('title')}>{t('widgets.stationsList.groupBy')}</h3>
        {renderGroupBys(groupBys, selectedGroupBy, client)}
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-start"
      overlay={overlay}
      rootClose
    >
      {children}
    </OverlayTrigger>
  )
}

GroupByPopover.propTypes = {
  children: PropTypes.shape().isRequired,
  groupBys: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  groupBy: PropTypes.string,
}

GroupByPopover.defaultProps = {
  groupBy: null,
}

export { GroupByPopover }
