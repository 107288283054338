import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Tab from 'react-bootstrap/Tab'
import Spinner from 'react-bootstrap/Spinner'
import { useErrorHandler } from 'react-error-boundary'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { PropTypes } from 'prop-types'
import {
  MetricTypes,
  PerformanceProfitTypes,
  PerformanceTypes,
} from 'utils/constants'
import styles from './StationPerformance.module.scss'
import { TabNavs } from './components/tab-navs'
import { TabContent } from './components/tab-content'
import { STATION_PERFORMANCE_QUERY } from './StationPerformanceQueries'

const cx = classNames.bind(styles)

const { GROSS_PROFIT, NET_PROFIT, BUSINESS_PROFIT } = PerformanceProfitTypes
const { RETAIL_VOLUME, GROSS_MARGIN, NET_MARGIN } = PerformanceTypes
const { VOLUME, MARGIN, PROFIT } = MetricTypes

const getFuelGradeSelectorProfitMetric = (
  selectedProfit,
  netProfit,
  businessProfit,
  grossProfit,
  t
) => {
  switch (selectedProfit) {
    case NET_PROFIT:
      return {
        performanceType: NET_PROFIT,
        performanceTitle: t('widgets.stationPerformance.netProfit'),
        metricType: PROFIT,
        performance: netProfit,
      }
    case BUSINESS_PROFIT:
      return {
        performanceType: BUSINESS_PROFIT,
        performanceTitle: t('widgets.stationPerformance.busProfit'),
        metricType: PROFIT,
        performance: businessProfit,
      }
    default:
      return {
        performanceType: GROSS_PROFIT,
        performanceTitle: t('widgets.stationPerformance.grossProfit'),
        metricType: PROFIT,
        performance: grossProfit,
      }
  }
}

const getPerformanceType = (
  selectedProfit,
  activeTab,
  retailVolume,
  grossMargin,
  netMargin,
  netProfit,
  businessProfit,
  grossProfit,
  t
) => {
  switch (activeTab) {
    case GROSS_MARGIN:
      return {
        performanceType: GROSS_MARGIN,
        performanceTitle: t('widgets.stationPerformance.grossMargin'),
        metricType: MARGIN,
        performance: grossMargin,
      }
    case NET_MARGIN:
      return {
        performanceType: NET_MARGIN,
        performanceTitle: t('widgets.stationPerformance.netMargin'),
        metricType: MARGIN,
        performance: netMargin,
      }
    case GROSS_PROFIT:
    case NET_PROFIT:
    case BUSINESS_PROFIT:
      return getFuelGradeSelectorProfitMetric(
        selectedProfit,
        netProfit,
        businessProfit,
        grossProfit,
        t
      )
    default:
      return {
        performanceType: RETAIL_VOLUME,
        performanceTitle: t('widgets.stationPerformance.retailVolume'),
        metricType: VOLUME,
        performance: retailVolume,
      }
  }
}

const getTabNavsProfitMetric = (
  selectedProfit,
  grossProfit,
  netProfit,
  businessProfit,
  t
) => {
  switch (selectedProfit) {
    case NET_PROFIT:
      return {
        performanceType: NET_PROFIT,
        performanceTitle: t('widgets.stationPerformance.netProfit'),
        metricType: PROFIT,
        ...netProfit.today,
      }
    case BUSINESS_PROFIT:
      return {
        performanceType: BUSINESS_PROFIT,
        performanceTitle: t('widgets.stationPerformance.busProfit'),
        metricType: PROFIT,
        ...businessProfit.today,
      }
    default:
      return {
        performanceType: GROSS_PROFIT,
        performanceTitle: t('widgets.stationPerformance.grossProfit'),
        metricType: PROFIT,
        ...grossProfit.today,
      }
  }
}

const getKeyMetrics = (
  activeTab,
  retailVolume,
  grossMargin,
  netMargin,
  netProfit,
  businessProfit,
  grossProfit
) => {
  switch (activeTab) {
    case RETAIL_VOLUME:
      return retailVolume
    case GROSS_MARGIN:
      return grossMargin
    case NET_MARGIN:
      return netMargin
    case NET_PROFIT:
      return netProfit
    case BUSINESS_PROFIT:
      return businessProfit
    case GROSS_PROFIT:
      return grossProfit
    default:
      break
  }
}

const setInitialActiveTab = metricPermissions => {
  switch (true) {
    case metricPermissions.readVolume:
      return RETAIL_VOLUME
    case metricPermissions.readMargin:
      return GROSS_MARGIN
    case metricPermissions.readProfit:
      return GROSS_PROFIT
    default:
      return 'without-permission'
  }
}

const StationPerformance = ({ stationId, isOverlayMode }) => {
  const { metricPermissions } = usePermittedAccess()
  const [selectedGradeId, setSelectedGradeId] = useState('0')
  const [activeTab, setActiveTab] = useState(
    setInitialActiveTab(metricPermissions)
  )

  const [selectedProfit, setSelectedProfit] = useState(GROSS_PROFIT)
  const { selectedPricingType, supportedPricingTypes } = usePricingTypes()
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(STATION_PERFORMANCE_QUERY, {
    variables: {
      stationId,
      supportedPricingTypes,
      includeTotal: true,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  useErrorHandler(error)

  if (loading || !data) {
    return (
      <div className={cx('station-performance-spinner')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  }

  const handleSelectedGrade = gradeType => {
    setSelectedGradeId(gradeType)
  }

  const handleSelectedProfit = selectedProfit => {
    setSelectedProfit(selectedProfit)
    setActiveTab(selectedProfit)
  }

  const {
    station: {
      fuelGrades,
      name: stationName,
      metrics,
      targetMargin: { target: targetMargin },
      hasBunkered,
    },
  } = data

  const selectedPricingGradesMetrics = metrics.filter(
    metric => metric.pricingType === selectedPricingType
  )

  const fuelGradeSelectorData = selectedPricingGradesMetrics.map(
    ({
      fuelGradeId,
      retailVolume,
      grossMargin,
      netMargin,
      grossProfit,
      netProfit,
      businessProfit,
    }) => {
      const {
        performanceType,
        performanceTitle,
        metricType,
        performance: { today },
      } = getPerformanceType(
        selectedProfit,
        activeTab,
        retailVolume,
        grossMargin,
        netMargin,
        netProfit,
        businessProfit,
        grossProfit,
        t
      )
      const getFuelGrade = fuelGrades.filter(
        ({ fuelGrade: { id } }) => id === fuelGradeId
      )

      const keyMetrics = getKeyMetrics(
        activeTab,
        retailVolume,
        grossMargin,
        netMargin,
        netProfit,
        businessProfit,
        grossProfit,
        t
      )

      return {
        ...getFuelGrade[0].fuelGrade,
        performanceType,
        performanceTitle,
        metricType,
        ...today,
        keyMetrics: {
          ...keyMetrics,
        },
      }
    }
  )

  const selectedGradeData = selectedPricingGradesMetrics.filter(
    metric => metric.fuelGradeId === selectedGradeId
  )

  const tabNavData = selectedGradeData.map(
    ({
      retailVolume,
      grossMargin,
      netMargin,
      grossProfit,
      netProfit,
      businessProfit,
    }) => {
      return [
        {
          performanceType: RETAIL_VOLUME,
          performanceTitle: t('widgets.stationPerformance.retailVolume'),
          metricType: VOLUME,
          ...retailVolume.today,
        },
        {
          performanceType: GROSS_MARGIN,
          performanceTitle: t('widgets.stationPerformance.grossMargin'),
          metricType: MARGIN,
          ...grossMargin.today,
        },
        {
          performanceType: NET_MARGIN,
          performanceTitle: t('widgets.stationPerformance.netMargin'),
          metricType: MARGIN,
          ...netMargin.today,
        },
        getTabNavsProfitMetric(
          selectedProfit,
          grossProfit,
          netProfit,
          businessProfit,
          t
        ),
      ]
    }
  )

  const [{ value: todaysGrossMargin }] = tabNavData[0].filter(
    ({ performanceType }) => performanceType === GROSS_MARGIN
  )

  return (
    <Tab.Container defaultActiveKey={1}>
      <TabNavs
        selectedPricing={selectedPricingType}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabNavData={tabNavData[0]}
        metricPermissions={metricPermissions}
        isOverlayMode={isOverlayMode}
      />
      <TabContent
        stationId={stationId}
        todaysGrossMargin={todaysGrossMargin}
        targetMargin={targetMargin}
        tabContentData={fuelGradeSelectorData}
        activeTab={activeTab}
        selectedGrade={selectedGradeId}
        selectedProfit={selectedProfit}
        hasBunkered={hasBunkered}
        handleSelectedGrade={handleSelectedGrade}
        handleSelectedProfit={handleSelectedProfit}
        metricPermissions={metricPermissions}
        isOverlayMode={isOverlayMode}
      />
    </Tab.Container>
  )
}

StationPerformance.propTypes = {
  stationId: PropTypes.string.isRequired,
  isOverlayMode: PropTypes.bool,
}

StationPerformance.defaultProps = { isOverlayMode: false }

const FallbackComponent = () => <WidgetFallback />

const StationPerformanceWithErrorBoundary = withEdgeErrorBoundary(
  StationPerformance,
  FallbackComponent
)

export { StationPerformanceWithErrorBoundary as StationPerformance }
