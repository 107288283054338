import React from 'react'
import PropTypes from 'prop-types'
import { useErrorHandler } from 'react-error-boundary'
import { ReactComponent as LoadingIcon } from 'images/stations-list/loading.svg'
import { STATIONS_LIST_PAGE_QUERY } from 'components/stations-list/StationsListQueries'
import { useStationsListPagination } from 'components/stations-list/hooks/useStationsListPagination'
import { FIRST_PAGE, PAGE_SIZE } from 'components/stations-list/utils/constants'
import { useQuery, NetworkStatus } from '@apollo/client'
import classNames from 'classnames/bind'
import { PricesPanel } from '../edit-pricing/prices-panel'
import { StationOverlay } from '../edit-pricing/station-overlay'
import { NoStationsBody } from '../no-stations-body'
import { StationRows } from '../station-rows'
import { TotalRow } from '../total-row'
import styles from './TableBody.module.scss'

const cx = classNames.bind(styles)

const renderPaginationTrigger = (
  loading,
  networkStatus,
  nextPage,
  paginationRef
) => {
  // render the trigger if we're not loading a page and there is a next page available
  if (loading || networkStatus !== NetworkStatus.ready || !nextPage) {
    return null
  }
  return <div ref={paginationRef} />
}

const renderLoadingPageIcon = loadingPage => {
  /* istanbul ignore next */
  const icon = loadingPage ? <LoadingIcon /> : null
  return <div className={cx('loading-page')}>{icon}</div>
}

const TableBody = ({
  mainData,
  timestamp,
  selectedPricingType,
  cashCreditPricing,
  options,
  showPricingView,
}) => {
  const { selectedSort, selectedFilters } = options
  const { loading, error, data, fetchMore, networkStatus } = useQuery(
    STATIONS_LIST_PAGE_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        timestamp,
        number: FIRST_PAGE,
        size: PAGE_SIZE,
        pricingType: selectedPricingType,
        sort: selectedSort,
        filters: selectedFilters,
        pricing: showPricingView,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  )
  const [paginationRef] = useStationsListPagination(fetchMore, data)
  useErrorHandler(error)

  const {
    me: {
      fuelGrades,
      stations: { items: stations },
    },
  } = mainData

  const loadingPage = loading && networkStatus === NetworkStatus.fetchMore
  if (loading && !loadingPage) {
    return (
      <>
        <div className={cx('table-body', 'loading')}>
          <LoadingIcon height={100} width={100} />
        </div>
        <PricesPanel
          loading
          fuelGrades={fuelGrades}
          showPricingView={showPricingView}
        />
      </>
    )
  }

  const {
    stations: {
      pageInfo: { nextPage, pricingStationIds, isSelected },
      items,
    },
  } = data

  if (items.length === 0) {
    return (
      <>
        <NoStationsBody />
        <PricesPanel
          loading
          fuelGrades={fuelGrades}
          showPricingView={showPricingView}
        />
      </>
    )
  }

  return (
    <>
      <StationOverlay items={items} stations={stations} />
      <div className={cx('table-body', { 'editing-pricing': showPricingView })}>
        <TotalRow
          items={items}
          showPricingView={showPricingView}
          pricingStationIds={pricingStationIds}
          isSelected={isSelected}
        />
        <StationRows
          items={items}
          stations={stations}
          showPricingView={showPricingView}
          cashCreditPricing={cashCreditPricing}
        />
      </div>
      <PricesPanel
        fuelGrades={fuelGrades}
        items={items}
        stations={stations}
        showPricingView={showPricingView}
      />
      {renderPaginationTrigger(loading, networkStatus, nextPage, paginationRef)}
      {renderLoadingPageIcon(loadingPage)}
    </>
  )
}

TableBody.propTypes = {
  timestamp: PropTypes.string.isRequired,
  mainData: PropTypes.shape(),
  selectedPricingType: PropTypes.string.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    selectedFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedGroupBy: PropTypes.string,
    selectedSort: PropTypes.shape().isRequired,
  }),
  showPricingView: PropTypes.bool.isRequired,
}

TableBody.defaultProps = {
  mainData: null,
}

export { TableBody }
