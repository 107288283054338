import { useEffect } from 'react'

function useHotjar(me) {
  const HOTJAR_KEY = process.env.REACT_APP_HOTJAR_KEY

  useEffect(() => {
    const HOTJAR_ENABLED = process.env.REACT_APP_HOTJAR_ENABLED === 'true'
    if (!me || me.isEdgeSuperuser || !HOTJAR_ENABLED) {
      return
    }

    // Hotjar script
    ;(function(h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function() {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: HOTJAR_KEY, hjsv: 6 }
      a = o.getElementsByTagName('head')[0]
      r = o.createElement('script')
      r.async = 1
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
      a.appendChild(r)
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
  }, [me, HOTJAR_KEY])
}

export { useHotjar }
