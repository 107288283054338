import React from 'react'
import { ReactComponent as LoadingIcon } from 'images/stations-list/loading.svg'
import { ReactComponent as EdgeLogoIcon } from 'images/common/edge-logo-dark.svg'
import classNames from 'classnames/bind'
import styles from './SettingThingsUp.module.scss'
import { Copyright } from '../copyright'

const cx = classNames.bind(styles)

const SettingThingsUp = () => {
  return (
    <div className={cx('background')}>
      <div className={cx('content')}>
        <LoadingIcon height={200} width={200} />
        <EdgeLogoIcon className={cx('edge-logo')} height={80} width={80} />
      </div>
      <Copyright className={cx('copyright')} />
    </div>
  )
}

SettingThingsUp.propTypes = {}

export { SettingThingsUp }
