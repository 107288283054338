import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlusIcon } from 'images/pole-prices/plus.svg'
import { ReactComponent as MinusIcon } from 'images/pole-prices/minus.svg'
import classNames from 'classnames/bind'
import styles from '../../PolePriceCell.module.scss'

const cx = classNames.bind(styles)

const StationPriceButtons = ({
  show,
  fuelGrade,
  handleIncrementClick,
  handleDecrementClick,
}) => {
  if (!show) {
    return null
  }

  return (
    <div className={cx('buttons')}>
      <MinusIcon
        data-testid="minus"
        height={14}
        width={14}
        onClick={() => handleDecrementClick(fuelGrade.id)}
      />
      <PlusIcon
        data-testid="plus"
        height={14}
        width={14}
        onClick={() => handleIncrementClick(fuelGrade.id)}
      />
    </div>
  )
}

StationPriceButtons.propTypes = {
  show: PropTypes.bool,
  fuelGrade: PropTypes.shape().isRequired,
  handleIncrementClick: PropTypes.func.isRequired,
  handleDecrementClick: PropTypes.func.isRequired,
}

StationPriceButtons.defaultProps = {
  show: false,
}

export { StationPriceButtons }
