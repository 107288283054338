import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { images, fallback } from 'images/image-library'
import { useHistory } from 'react-router-dom'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { ImageAsset } from 'components/common/image-asset'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'
import { StationCheckbox } from 'components/stations-list/components/stations-table/components/edit-pricing/station-checkbox'
import { slugify } from 'utils/slug'
import { stationCashCreditStatus } from 'utils/pricing'
import classNames from 'classnames/bind'
import { MetricCells } from '../metric-cells'
import styles from './StationRow.module.scss'

const cx = classNames.bind(styles)

const renderStation = (
  station,
  metrics,
  isSelected,
  hasStationPrice,
  readTargetMargin,
  showPricingView,
  isInvalidStation
) => {
  const { id, name, brand, targetMargin } = station
  const {
    grossMargin: { today },
  } = metrics

  const targetToNumber = parseFloat(targetMargin.target).toFixed(2)
  const target = isNaN(targetToNumber) ? null : targetToNumber

  const permissionedTargetMargin = (
    <Permissioned
      permission={readTargetMargin}
      fallback={<Placeholder fixedWidth={40} />}
    >
      {target}
    </Permissioned>
  )

  return (
    <div
      className={cx('station-cell', { 'edit-pricing': showPricingView })}
      onClick={() =>
        showPricingView &&
        amplitudeEvent(UserEvents.GroupEditPricing.STATION_CLICKED)
      }
    >
      <StationCheckbox
        showPricingView={showPricingView}
        stationId={id}
        isSelected={isSelected}
        hasStationPrice={hasStationPrice}
        isInvalidStation={isInvalidStation}
      />
      <ImageAsset
        height={50}
        width={50}
        src={images.brandImage(brand?.key)}
        alt={`station-cell-brand-${brand?.name}`}
        fallbackImage={fallback.defaultBrand}
      />
      <div className={cx('station-info-wrapper')}>
        <div className={cx('station-name')}>{name}</div>
        {targetMargin && (
          <div
            className={cx('target-margin', {
              negative: targetMargin.target > today,
              restricted: !readTargetMargin,
            })}
          >
            {permissionedTargetMargin}
          </div>
        )}
      </div>
    </div>
  )
}

const StationRow = memo(
  ({
    station,
    priceChangeEnabled,
    stationCashCreditPricing,
    isSelected,
    hasStationPrice,
    metrics,
    showPricingView,
    cashCreditPricing,
    loopStations
  }) => {
    const history = useHistory()
    const {
      featurePermissions: { readTargetMargin },
    } = usePermittedAccess()

    const handleClick = () => {
      if (showPricingView || loopStations) {
        stationsListPricingOperations.setOverlayStation(station.id)
      } else {
        const slug = slugify(station.name)
        history.push(`/stations/${slug}`)
      }
    }

    const isInvalidStation = showPricingView && !priceChangeEnabled
    const isNoCashCreditStation =
      showPricingView &&
      stationCashCreditStatus(cashCreditPricing, stationCashCreditPricing)

    return (
      <div
        className={cx('station-row', {
          invalid: isInvalidStation,
          'no-cc': isNoCashCreditStation,
        })}
        onClick={handleClick}
        data-testid="station-row"
      >
        {renderStation(
          station,
          metrics,
          isSelected,
          hasStationPrice,
          readTargetMargin,
          showPricingView,
          isInvalidStation
        )}
        <MetricCells metrics={metrics} showPricingView={showPricingView} />
      </div>
    )
  }
)

StationRow.propTypes = {
  station: PropTypes.shape().isRequired,
  metrics: PropTypes.shape().isRequired,
  priceChangeEnabled: PropTypes.bool,
  stationCashCreditPricing: PropTypes.bool,
  isSelected: PropTypes.bool,
  hasStationPrice: PropTypes.bool,
  showPricingView: PropTypes.bool.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
  loopStations: PropTypes.bool,
}

StationRow.defaultProps = {
  pricing: null,
  priceChangeEnabled: null,
  stationCashCreditPricing: null,
  isSelected: null,
  hasStationPrice: null,
  loopStations: false
}

export { StationRow }
