import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useCSVDownloader } from 'react-papaparse'
import { ReactComponent as ExportIcon } from 'images/performance-report/export.svg'
import classNames from 'classnames/bind'
import styles from './ExportButton.module.scss'
import Spinner from 'react-bootstrap/Spinner'

const cx = classNames.bind(styles)

const ExportButton =({ data, filename, buttonName }) => {
  const { CSVDownloader, Type } = useCSVDownloader()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let timeout
    if (loading) {
      timeout = setTimeout(() => {
        setLoading(false)
      }, 500)
    }

    return () => clearTimeout(timeout)
  }, [loading])

  const handleClick = () => {
    setLoading(true)
  }

  return(
    <div className={cx('export-button')}>
      <CSVDownloader
        type={Type.Button}
        filename={filename}
        bom
        data={data}
      >
        <div onClick={handleClick} data-testid="wrapper">
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <>
              <ExportIcon />
              {buttonName}
            </>
          )}
        </div>
      </CSVDownloader>
    </div>
  )
}

ExportButton.propTypes = {
  data: PropTypes.any,
  activeTab: PropTypes.string,
  filename: PropTypes.string,
  buttonName: PropTypes.string,
}

export { ExportButton }
