import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DropdownIcon } from 'images/stations-list/dropdown.svg'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import classNames from 'classnames/bind'
import styles from './ValueDropdown.module.scss'

const cx = classNames.bind(styles)

const renderValues = (filter, value, onValueSelect) => {
  return filter.values.map(currentValue => {
    const handleClick = () => {
      onValueSelect(currentValue)
    }

    return (
      <div
        key={currentValue}
        className={cx('value')}
        onClick={handleClick}
        data-testid="value"
      >
        <span>{currentValue}</span>
        {value === currentValue ? <ActiveIcon /> : <InactiveIcon />}
      </div>
    )
  })
}

const ValueDropdown = ({ open, onClick, newFilter, onValueSelect }) => {
  const { t } = useTranslation()

  const { filter, value } = newFilter

  const handleValueDropdownClick = () => {
    onClick(true)
  }

  const handleValuesClick = event => {
    event.stopPropagation()
    onClick(false)
  }

  return (
    <div
      className={cx('value-dropdown')}
      onClick={handleValueDropdownClick}
      data-testid="value-dropdown"
    >
      <span>{value}</span>
      <DropdownIcon />
      {open && (
        <div
          className={cx('values')}
          onClick={handleValuesClick}
          data-testid="values"
        >
          <div className={cx('value')}>
            <span>{t('widgets.stationsList.value')}</span>
            <DropdownIcon />
          </div>
          {renderValues(filter, value, onValueSelect)}
        </div>
      )}
    </div>
  )
}

ValueDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  newFilter: PropTypes.shape(),
  filters: PropTypes.arrayOf(PropTypes.shape()),
  onValueSelect: PropTypes.func.isRequired,
}

ValueDropdown.defaultProps = {
  newFilter: undefined,
}

export { ValueDropdown }
