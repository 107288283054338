import { gql, useMutation } from '@apollo/client'
import { useShowToast } from 'components/common/hooks/useShowToast'

const UPDATE_STATION_INFLUENCER_MUTATION = gql`
  mutation updateStationInfluencerMutation(
    $stationId: ID!
    $competitorId: ID!
    $influencer: Boolean!
  ) {
    updateStationInfluencer(
      input: {
        stationId: $stationId
        competitorId: $competitorId
        influencer: $influencer
      }
    ) {
      error
      competitor {
        id
        influencer
      }
    }
  }
`

function useUpdateStationInfluencer() {
  const { showErrorToast } = useShowToast()
  const [updateInfluencer, result] = useMutation(
    UPDATE_STATION_INFLUENCER_MUTATION,
    {
      onCompleted: ({ updateStationInfluencer: { error } }) => {
        if (error) {
          // TODO: log to Sentry
          showErrorToast()
        }
      },
      onError: () => {
        // TODO: log to Sentry
        showErrorToast()
      },
    }
  )

  return [updateInfluencer, result]
}

export { useUpdateStationInfluencer, UPDATE_STATION_INFLUENCER_MUTATION }
