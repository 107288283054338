import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { StationsList } from 'components/stations-list'
import { Station } from 'components/station'
import { Performance } from 'components/performance'
import { Analytics } from 'components/analytics'
import { NotFoundError } from 'components/common/not-found-error'
import { PermissionTypes } from 'components/common/hooks/usePermissions'
import { slugify } from 'utils/slug'
import { WidgetPermissions } from 'utils/constants'

/* istanbul ignore next */
const ThrowNotFoundError = () => {
  throw new NotFoundError()
}

const canAccessFeature = (permissions, featureTitle) => {
  const permission = permissions.find(({ title }) => title === featureTitle)

  if (!permission) {
    return false
  }

  return permission[PermissionTypes.READ]
}

const Routes = (mainData) => {
  const {
    me: {
      isSingleSite,
      stations,
      permissions,
    },
  } = mainData

  const analyticsAccess = canAccessFeature(permissions, WidgetPermissions.ANALYTICS)
  const performanceReportAccess = canAccessFeature(permissions, WidgetPermissions.PERFORMANCE_REPORTING_PORTFOLIO_PERMISSION)
  const isEdgeDataPro = mainData?.me.company?.edgeDataPro
  const items = isEdgeDataPro ? [] : stations?.items || [];

  if (isSingleSite) {
    const [station] = items
    const slug = slugify(station.name)
    const stationLocation = `/${slug}`

    return (
      <Switch>
        { analyticsAccess && (
          <Route path="/analytics" exact>
            <Analytics mainData={mainData}/>
          </Route>
        )}
        <Route path="/:station" exact>
          <Station mainData={mainData} />
        </Route>
        <Redirect from="/" to={stationLocation} exact />
        <Route path="*">
          <ThrowNotFoundError />
        </Route>
      </Switch>
    )
  }

  else if (isEdgeDataPro) {
    return (
      <Switch>
        <Route path="/analytics" exact>
          <Analytics mainData={mainData} />
        </Route>
        <Redirect from="/" to="/analytics" exact />
        <Route path="*">
          <ThrowNotFoundError />
        </Route>
      </Switch>
    )
  }

  return (
    <Switch>
      {performanceReportAccess && (
        <Route path="/performance" exact>
          <Performance mainData={mainData}/>
        </Route>
      )}
      { analyticsAccess && (
        <Route path="/analytics" exact>
          <Analytics mainData={mainData} />
        </Route>
      )}
      <Route path="/stations/:station" exact>
        <Station mainData={mainData} />
      </Route>
      <Route path="/stations" exact>
        <StationsList />
      </Route>
      <Redirect from="/" to="/stations" exact />
      <Redirect from="/:station" to="/stations" exact />
      <Route path="*">
        <ThrowNotFoundError />
      </Route>
    </Switch>
  )
}

export { Routes }
