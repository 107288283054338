import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { ReactComponent as DrivingDistanceIcon } from 'images/competitor-pricing/driving-distance.svg'
import classNames from 'classnames/bind'
import styles from './MarkerPopup.module.scss'
import { PreviousPrice } from '../../../../../prices-table/components/fuel-grade-column/components/popovers/components/previous-price'
import { parseDrivingDistance } from '../../utils'
import { upperFirstOnly } from 'utils/format'
import { images, fallback } from 'images/image-library'
import { ImageAsset } from 'components/common/image-asset'
import { Popup } from 'react-leaflet'

const cx = classNames.bind(styles)

const CompetitorDistance = ({ station }) => {
  const { t } = useTranslation(['translations'])
  const drivingDistance = parseDrivingDistance(t, station.distances)

  return (
    <div className={cx('distance')}>
      <DrivingDistanceIcon width="20" height="12" />
      {drivingDistance} <br />
    </div>
  )
}

const MarkerPopup = ({
  station,
  validFuelGrades,
  selectedFuelGrade,
  markerClass,
  priceClasses,
  isMyStation,
}) => {
  const { formatPolePrice, formatPolePriceDate } = useFormatPolePrice()
  const { selectedPricingType } = usePricingTypes()
  const filteredPolePrices = useMemo(
    () =>
      station.polePrices
        .map(price => {
          const priceType = price.pricingType
          if (
            (priceType !== 'ALL' &&
              selectedPricingType !== 'ALL' &&
              selectedPricingType !== priceType) ||
            (station.cashCreditPricing && priceType === 'ALL')
          ) {
            return null
          }

          const selectedPriceClass = priceType => {
            if (isMyStation) {
              return 'default'
            }

            switch (priceType) {
              case 'ALL':
                return priceClasses.allPriceClass;
              case 'CASH':
                return priceClasses.cashPriceClass;
              default:
                return priceClasses.creditPriceClass;
            }
          }
          const priceClass = markerClass || selectedPriceClass(priceType)
          const priceFuelGrade = validFuelGrades?.find(
            fuelGrade => fuelGrade.id === price.fuelGradeId
          )
          const priceRowClass = cx(station.cashCreditPricing ?
            'cash-credit-price-row' : 'price-row',
            price.fuelGradeId === selectedFuelGrade &&
              cx('selected-fg-row', `${priceClass}-fg`)
          )

          return {
            price,
            priceFuelGrade,
            priceRowClass,
            priceType,
          }
        })
        .filter(polePrice => polePrice !== null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPricingType, station.polePrices, selectedFuelGrade]
  )

  return (
    <Popup className={cx('popup-wrapper')}>
      <div className={cx('popup')}>
        <div
          className={
            station.influencer ? cx('influencer-title-row') : cx('title-row')
          }
        >
          <ImageAsset
            src={images.brandImage(station.brand?.key)}
            width={50}
            height={50}
            alt={`${station.brand?.name} fuel brand logo`}
            fallbackImage={fallback.defaultBrand}
          />
          <div className={cx('station-info')}>
            {station.distances && <CompetitorDistance station={station} />}
            <div className={cx('station-name')}>{station.name}</div>
          </div>
        </div>
        <div>
          {filteredPolePrices.map(
            polePrice => {
              if (!polePrice.priceFuelGrade) return null;

              const { priceType, price, priceFuelGrade, priceRowClass } = polePrice;
              const { source, priceUpdatedAt, fuelGradeId } = price
              const isSelectedFuelGrade = fuelGradeId === selectedFuelGrade;
            
              return (
                <div
                  key={`${priceType}-${fuelGradeId}`}
                  className={priceRowClass}
                >
                  <div
                    className={cx('fuel-grade-img')}
                    style={{ backgroundColor: priceFuelGrade.color }}
                  >
                    {priceFuelGrade.shortTitle}
                  </div>
                  <div className={cx('price-info')}>
                    <div
                      className={
                        isSelectedFuelGrade
                          ? cx('selected-fg-date')
                          : cx('date')
                      }
                    >
                      {`${formatPolePriceDate(priceUpdatedAt)}${
                        priceType === 'ALL'
                          ? ''
                          : ` (${upperFirstOnly(priceType)})`
                      }`}
                    </div>
                    <div className={cx('price')}>
                      {formatPolePrice(price.price)}
                      <span className={cx('previous-price-indicator')}>
                        <PreviousPrice polePrice={price} />
                      </span>
                    </div>
                  </div>
                  <div className={cx('source-img')}>
                    <ImageAsset
                      src={images.polePriceShort(source)}
                      width={18}
                      height={18}
                      alt={`${source} logo`}
                      fallbackImage={null}
                    />
                  </div>
                </div>
              )}
          )}
        </div>
      </div>
    </Popup>
  )
}

MarkerPopup.propTypes = {
  station: PropTypes.shape().isRequired,
  validFuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedFuelGrade: PropTypes.string.isRequired,
  markerClass: PropTypes.string.isRequired,
  priceClasses: PropTypes.shape().isRequired,
  isMyStation: PropTypes.bool.isRequired,
}

export { MarkerPopup }
