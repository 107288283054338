import { isNullOrUndefined } from 'utils/helpers'
import upperFirst from 'lodash/upperFirst'
import { renderToString } from 'react-dom/server'
import { Marker } from 'react-leaflet'
import leaflet from 'leaflet'
import { images } from 'images/image-library'
import { MarkerPopup } from './components/marker-popup'
import classNames from 'classnames/bind'
import styles from './MapMarkers.module.scss'
import { ReactComponent as StarIcon } from 'images/competitor-pricing/star.svg'

const cx = classNames.bind(styles)

const filterPrices = (station, selectedFuelGrade) => {
  const stationSelectedPrices = []
  station.polePrices
    .filter(price => price.fuelGradeId === selectedFuelGrade)
    .map(price =>
      stationSelectedPrices.push({
        pricingType: price?.pricingType,
        price: price?.price,
      })
    )

  const allPrice = stationSelectedPrices.find(
    price => price.pricingType === 'ALL'
  )?.price
  const cashPrice = stationSelectedPrices.find(
    price => price.pricingType === 'CASH'
  )?.price
  const creditPrice = stationSelectedPrices.find(
    price => price.pricingType === 'CREDIT'
  )?.price

  return { allPrice, cashPrice, creditPrice }
}

const checkMarkerClass = (
  myStationSelectedPrice,
  competitorSelectedPrice,
  cashCreditPricingEnabled
) => cashCreditPricingEnabled
    ? 'default'
    : checkPriceClass(myStationSelectedPrice, competitorSelectedPrice)

const checkPriceClass = (myStationSelectedPrice, competitorSelectedPrice) => {
  if (!myStationSelectedPrice) {
    return
  }

  if (!competitorSelectedPrice) {
    return 'no-price-data'
  }
  if (myStationSelectedPrice === competitorSelectedPrice) {
    return 'match'
  }

  if (myStationSelectedPrice > competitorSelectedPrice) {
    return 'cheaper'
  }

  if (myStationSelectedPrice < competitorSelectedPrice) {
    return 'expensive'
  }
}

const parseDrivingDistance = (t, distances) => {
  const distance =
    distances[`drivingDistance${upperFirst(t('distance.title'))}`]
  if (isNullOrUndefined(distance)) {
    return null
  }
  return `${parseFloat(distance).toFixed(1)} ${t('distance.short')} ${t(
    'widgets.competitorPricing.away'
  )}`
}

const CashCreditPrices = ({
  station, 
  isMyStation, 
  priceClasses, 
  prices
}) => 
  <div className={station.cashCreditPricing ? cx('selected-prices') : cx('selected-price')}
  data-testid={isMyStation ? 'station-marker-price' : 'competitor-marker-price'}>
    {station.cashCreditPricing ? (
      <div>
        <div className={cx('selected-cash-price', `${priceClasses.cashPriceClass}`)}>
          {prices?.cashPrice || '-'}
        </div>
        <div
          className={cx('selected-credit-price', `${priceClasses.creditPriceClass}`)}
        >
          {prices?.creditPrice || '-'}
        </div>
      </div>
    ) : (
      <div>
        <div className={cx('selected-all-price', `${priceClasses.allPriceClass}`)}>
          {prices?.allPrice || '-'}
        </div>
      </div>
    )}
  </div>

const SinglePrice = ({isMyStation, selectedPrice}) =>
  <div className={cx('selected-price')} 
  data-testid={isMyStation ? 'station-marker-price' : 'competitor-marker-price'}>
    {selectedPrice || '-'}
  </div>

const createMarker = (
  station,
  validFuelGrades,
  selectedFuelGrade,
  selectedPrice,
  prices,
  markerClass,
  priceClasses,
  isMyStation,
  isCashCreditMarker,
  t
) => {
  const iconSettings = station.cashCreditPricing && isCashCreditMarker
    ? {
        iconSize: [124.05, 48],
        iconAnchor: [62.025, 49],
      }
    : {
        iconSize: [79.05, 48],
        iconAnchor: [39.525, 49],
      }

  const icon = new leaflet.divIcon({
    html: renderToString(
      <div data-testid="map-markers">
        <div className={`${cx('main', markerClass)}`}>
          {(station.influencer || isMyStation) && (
            <div
              className={cx('label', isMyStation ? 'my-station' : 'influencer')}
            >
              {isMyStation ? (
                t('widgets.competitorPricing.myStation')
              ) : (
                <StarIcon />
              )}
            </div>
          )}
          <img
            src={images.brandImage(station.brand?.key)}
            alt={`${station.brand?.name} fuel brand logo`}
            className={cx('brand_img')}
          />
          <div>
            {isCashCreditMarker ? 
            <CashCreditPrices 
              station={station} 
              isMyStation={isMyStation} 
              priceClasses={priceClasses}
              prices={prices}
            /> : 
            <SinglePrice 
              isMyStation={isMyStation}
              selectedPrice={selectedPrice}
            />}
          </div>
        </div>
        <span className={cx('marker-tip-border')}></span>
        <div className={`${cx('marker-tip', `${markerClass}-tip`)}`}></div>
      </div>
    ),
    ...iconSettings,
    popupAnchor: [0.5, 2],
    className: cx('map-marker'),
  })

  return (
    <Marker
      icon={icon}
      key={station.id}
      position={[station.latitude, station.longitude]}
    >
      <MarkerPopup
        station={station}
        validFuelGrades={validFuelGrades}
        selectedFuelGrade={selectedFuelGrade}
        markerClass={!isCashCreditMarker && markerClass}
        priceClasses={isCashCreditMarker && priceClasses}
        isMyStation={isMyStation}
      />
    </Marker>
  )
}

export {
  filterPrices,
  checkMarkerClass,
  checkPriceClass,
  parseDrivingDistance,
  createMarker,
}
