import React from 'react'
import PropTypes from 'prop-types'
import { isNullOrUndefined } from 'utils/helpers'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import { changedPendingRequest } from 'components/station/components/competitor-pricing/utils/helpers'
import { usePriceChangeState } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import classNames from 'classnames/bind'
import { ConfirmChanges } from '../confirm-changes'
import { PriceChangeTooltip } from 'components/common/price-change-tooltip'
import { Price } from './components/price'
import { EditPrice } from './components/edit-price'
import styles from './PriceCell.module.scss'

const cx = classNames.bind(styles)

const PriceCell = ({
  stationId,
  polePrice,
  polePriceChangeRequest,
  stationCashCreditPricing,
  fuelGradeDifferential,
  cashCreditDifferential,
  hasNoCashCreditPrice,
  sliderDispatch,
}) => {
  const { status, pendingRequests } = usePriceChangeState()

  if (isNullOrUndefined(polePrice)) {
    return (
      <div
        className={cx('price-cell', {
          'no-cash-credit': hasNoCashCreditPrice,
        })}
      ></div>
    )
  }

  switch (status) {
    case PriceChangeStatus.None:
      const activeRequest = !isNullOrUndefined(polePriceChangeRequest)

      const content = (
        <div
          className={cx('price-cell', {
            'active-request': activeRequest,
            'no-cash-credit': hasNoCashCreditPrice,
          })}
        >
          <Price
            polePrice={polePrice}
            polePriceChangeRequest={polePriceChangeRequest}
          />
        </div>
      )

      if (!activeRequest) {
        return content
      }

      return (
        <PriceChangeTooltip polePriceChangeRequest={polePriceChangeRequest}>
          {content}
        </PriceChangeTooltip>
      )
    case PriceChangeStatus.Edit:
      return (
        <div className={cx('price-cell')}>
          <EditPrice
            stationId={stationId}
            polePrice={polePrice}
            stationCashCreditPricing={stationCashCreditPricing}
            fuelGradeDifferential={fuelGradeDifferential}
            cashCreditDifferential={cashCreditDifferential}
            sliderDispatch={sliderDispatch}
          />
        </div>
      )
    default:
      return (
        <div
          className={cx('price-cell', {
            'pending-request': changedPendingRequest(
              pendingRequests,
              polePrice.fuelGradeId
            ),
          })}
        >
          <ConfirmChanges
            stationId={stationId}
            fuelGradeId={polePrice.fuelGradeId}
            cashCreditDifferential={cashCreditDifferential}
            pendingRequests={pendingRequests}
            stationCashCreditPricing={stationCashCreditPricing}
            sliderDispatch={sliderDispatch}
          />
        </div>
      )
  }
}

PriceCell.propTypes = {
  stationId: PropTypes.string.isRequired,
  polePrice: PropTypes.shape(),
  polePriceChangeRequest: PropTypes.shape(),
  stationCashCreditPricing: PropTypes.bool.isRequired,
  fuelGradeDifferential: PropTypes.shape(),
  cashCreditDifferential: PropTypes.shape(),
  hasNoCashCreditPrice: PropTypes.bool.isRequired,
  sliderDispatch: PropTypes.func.isRequired,
}

PriceCell.defaultProps = {
  polePrice: null,
  fuelGradeDifferential: null,
  cashCreditDifferential: null,
}

export { PriceCell }
