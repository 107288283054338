import { gql } from '@apollo/client'

const WETSTOCK_QUERY = gql`
  query wetstockQuery($stationId: ID!, $supportedPricingTypes: [PricingType!]) {
    station(id: $stationId) {
      id
      fuelGrades(pricingTypes: $supportedPricingTypes) {
        fuelGrade {
          id
          primary
          secondary
          title
          blended
          shortTitle
        }
        metrics {
          pricingType
          totalVolume
          blendedCost
          replacementCost
          margin
          replacementCostTimestamp
          error
        }
      }
    }
  }
`

export { WETSTOCK_QUERY }
