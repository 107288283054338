import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useApolloClient } from '@apollo/client'
import { isNullOrUndefined } from 'utils/helpers'
import { upperFirstOnly } from 'utils/format'
import { ReactComponent as CloseIcon } from 'images/stations-list/close.svg'
import classNames from 'classnames/bind'
import styles from './SelectedGroupBy.module.scss'

const cx = classNames.bind(styles)

const SelectedGroupBy = ({ selectedGroupBy }) => {
  const client = useApolloClient()
  const [showRemove, setShowRemove] = useState()

  if (isNullOrUndefined(selectedGroupBy)) {
    return null
  }

  const handleMouseEnter = () => {
    setShowRemove(true)
  }

  const handleMouseLeave = () => {
    setShowRemove(false)
  }

  const handleClick = () => {
    const data = {
      stationsList: {
        selectedGroupBy: null,
      },
    }
    client.writeQuery({
      query: gql`
        {
          stationsList {
            selectedGroupBy
          }
        }
      `,
      data,
    })
  }

  return (
    <div
      className={cx('selected-group-by')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={e => e.stopPropagation()}
      data-testid="selected-group-by"
    >
      {upperFirstOnly(selectedGroupBy)}
      {showRemove && (
        <div
          className={cx('remove-button')}
          onClick={handleClick}
          data-testid="remove-button"
        >
          <CloseIcon />
        </div>
      )}
    </div>
  )
}

SelectedGroupBy.propTypes = {
  selectedGroupBy: PropTypes.string,
}

SelectedGroupBy.defaultProps = {
  selectedGroupBy: null,
}

export { SelectedGroupBy }
