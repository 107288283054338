import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as QuestionMarkIcon } from 'images/question-mark.svg'
import Button from 'react-bootstrap/Button'
import { useAuth } from 'config/context/authContext'
import { useInactivityMessageChannel } from '../../hooks/useInactivityMessageChannel'
import classNames from 'classnames/bind'
import styles from './UserStillActiveCheckModal.module.scss'

const cx = classNames.bind(styles)

const UserStillActiveCheckModal = ({ active }) => {
  const countDown = 60

  const inactivityMessageChannel = useInactivityMessageChannel()
  const [secondsLeft, setSecondsLeft] = useState(countDown)
  const { t } = useTranslation()
  const { logout } = useAuth()

  const handleRefresh = () => {
    window.location.reload()
    inactivityMessageChannel.postMessage('refreshed')
  }

  const forceLogout = () => {
    logout()
    inactivityMessageChannel.postMessage('logged_out')
  }

  useEffect(() => {
    if (!active) {
      return
    }
    if (secondsLeft === 0) {
      logout()
      inactivityMessageChannel.postMessage('logged_out')
    } else {
      const timeout = setTimeout(function() {
        setSecondsLeft(secondsLeft - 1)
      }, 1000)
      return () => clearTimeout(timeout)
    }
  }, [secondsLeft, active, logout, inactivityMessageChannel])

  return (
    <Modal
      className={cx('modal')}
      aria-labelledby="contained-modal-title-vcenter"
      show={active}
      centered
      backdrop="static"
    >
      <Modal.Body>
        <QuestionMarkIcon className={cx('icon')} height={42} width={42} />
        <div className={cx('body')}>
          <h4>{t('widgets.inactivityLogout.areYouStillHere')}</h4>
          <div className={cx('message')}>
            {t('widgets.inactivityLogout.messageP1')}
            <div className={cx('count-down')}>
              {t('widgets.inactivityLogout.messageP2', {
                countDown: secondsLeft,
              })}
            </div>
          </div>
        </div>

        <div className={cx('buttons')}>
          <Button
            className={cx('button')}
            data-testid="button-refresh"
            onClick={handleRefresh}
          >
            {t('widgets.inactivityLogout.remainLoggedIn')}
          </Button>
          <Button
            className={cx('button', 'logout')}
            variant="light"
            data-testid="button-logout"
            onClick={forceLogout}
          >
            {t('widgets.inactivityLogout.logout')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

UserStillActiveCheckModal.propTypes = {
  active: PropTypes.bool.isRequired,
}

UserStillActiveCheckModal.defaultProps = {
  active: false,
}

export { UserStillActiveCheckModal }
