import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DropdownIcon } from 'images/stations-list/dropdown.svg'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import { FilterOperators } from 'components/stations-list/utils/constants'
import classNames from 'classnames/bind'
import styles from './OperatorDropdown.module.scss'

const cx = classNames.bind(styles)

const { GREATER_THAN, LESS_THAN, IS, IS_NOT, ABOVE, BELOW, UP_BY, DOWN_BY } = FilterOperators

const renderOperator = (operator, t) => {
  switch (operator) {
    case GREATER_THAN:
      return t('widgets.stationsList.isGreaterThan')
    case LESS_THAN:
      return t('widgets.stationsList.isLessThan')
    case IS:
      return t('widgets.stationsList.is')
    case IS_NOT:
      return t('widgets.stationsList.isNot')
    case ABOVE:
      return t('widgets.stationsList.above')
    case BELOW:
      return t('widgets.stationsList.below')
      case UP_BY:
        return `${t('widgets.stationsList.upBy')}`
      case DOWN_BY:
        return `${t('widgets.stationsList.downBy')}`
    default:
      return null
  }
}

const renderOperators = (filter, operator, onOperatorSelect, t) =>
  filter.operators.map(currentOperator => {
    const handleClick = () => {
      onOperatorSelect(currentOperator)
    }

    return (
      <div
        key={currentOperator}
        className={cx('operator')}
        onClick={handleClick}
        data-testid="operator"
      >
        <span>{renderOperator(currentOperator, t)}</span>
        {operator === currentOperator ? <ActiveIcon /> : <InactiveIcon />}
      </div>
    )
  })

const OperatorDropdown = ({ open, onClick, newFilter, onOperatorSelect }) => {
  const { t } = useTranslation()

  const { filter, operator } = newFilter

  const handleOperatorDropdownClick = () => {
    onClick(true)
  }

  const handleOperatorsClick = event => {
    event.stopPropagation()
    onClick(false)
  }

  return (
    <div
      className={cx('operator-dropdown')}
      onClick={handleOperatorDropdownClick}
      data-testid="operator-dropdown"
    >
      <span>{renderOperator(operator, t)}</span>
      <DropdownIcon />
      {open && (
        <div
          className={cx('operators')}
          onClick={handleOperatorsClick}
          data-testid="operators"
        >
          <div className={cx('operator')}>
            <span>{t('widgets.stationsList.operator')}</span>
            <DropdownIcon />
          </div>
          {renderOperators(filter, operator, onOperatorSelect, t)}
        </div>
      )}
    </div>
  )
}

OperatorDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  newFilter: PropTypes.shape().isRequired,
  onOperatorSelect: PropTypes.func.isRequired,
}

OperatorDropdown.defaultProps = {}

export { OperatorDropdown }
