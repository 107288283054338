import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { ReactComponent as ErrorIcon } from 'images/common/error.svg'
import classNames from 'classnames/bind'
import styles from './Error.module.scss'

const cx = classNames.bind(styles)

const Error = ({ tooltipText }) => {
  const overlay = (
    <Tooltip className={cx('error-tooltip')}>
      <div>{tooltipText}</div>
    </Tooltip>
  )

  return (
    <OverlayTrigger placement="top" overlay={overlay}>
      <ErrorIcon height={20} width={20} data-testid="error-icon" />
    </OverlayTrigger>
  )
}

Error.propTypes = {
  tooltipText: PropTypes.string.isRequired,
}

export { Error }
