import { useQuery, gql } from '@apollo/client'
import { isNullOrUndefined } from 'utils/helpers'
import { removeTypename } from 'utils/format'

const STATIONS_LIST_OPTIONS_QUERY = gql`
  query stationsListOptionsQuery {
    stationsList @client {
      selectedSort {
        field
        direction
      }
      selectedFilters {
        name
        operator
        value
      }
      selectedGroupBy
    }
  }
`

function useStationsListOptions() {
  const { data } = useQuery(STATIONS_LIST_OPTIONS_QUERY)

  if (isNullOrUndefined(data)) {
    return {
      selectedFilters: null,
      selectedGroupBy: null,
      selectedSort: null,
    }
  }

  const parsedData = removeTypename(data)

  const {
    stationsList: { selectedFilters, selectedGroupBy, selectedSort },
  } = parsedData

  return { selectedFilters, selectedGroupBy, selectedSort }
}

export { useStationsListOptions }
