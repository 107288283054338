import React from 'react'
import PropTypes from 'prop-types'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import styles from './Price.module.scss'

const cx = classNames.bind(styles)

const Price = ({ polePrice, polePriceChangeRequest }) => {
  const { formatPolePrice, formatPolePriceDate } = useFormatPolePrice()

  if (isNullOrUndefined(polePriceChangeRequest)) {
    const { price, priceUpdatedAt } = polePrice
    return (
      <>
        <div className={cx('price-date')}>
          {formatPolePriceDate(priceUpdatedAt)}
        </div>
        <div className={cx('price-value')}>{formatPolePrice(price)}</div>
      </>
    )
  }

  return (
    <>
      <div className={cx('current-price')}>
        {formatPolePrice(polePrice.price)}
      </div>
      <div className={cx('new-price')}>
        {formatPolePrice(polePriceChangeRequest.newPrice)}
      </div>
    </>
  )
}

Price.propTypes = {
  polePrice: PropTypes.shape(),
  polePriceChangeRequest: PropTypes.shape(),
}

Price.defaultProps = {
  polePrice: null,
  polePriceChangeRequest: null,
}

export { Price }
