import { selectedStationIdsVar, stationPriceChangesVar } from '../variables'

const selectStations = stationIds => {
  const selectedStationIds = selectedStationIdsVar()
  selectedStationIdsVar([...new Set([...selectedStationIds, ...stationIds])])
}

/**
 * Deselects stations with the given IDs.
 *
 * Resets the station's station price changes.
 */
const deselectStations = stationIds => {
  const selectedStationIds = selectedStationIdsVar()
  selectedStationIdsVar(
    selectedStationIds.filter(stationId => !stationIds.includes(stationId))
  )

  const stationPriceChanges = stationPriceChangesVar()
  stationPriceChangesVar({
    ...stationPriceChanges,
    ...stationIds.reduce((object, item) => ({ ...object, [item]: null }), {}),
  })
}

export { selectStations, deselectStations }
