import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly } from 'utils/format'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { getFilterAndGroupDropdownName } from 'config/amplitude/utils'
import { ReactComponent as DropdownIcon } from 'images/stations-list/dropdown.svg'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import classNames from 'classnames/bind'
import styles from './FilterDropdown.module.scss'

const cx = classNames.bind(styles)

const renderFilters = (selectedFilter, onFilterSelect, filters) => {
  return filters.map(filter => {
    const { name } = filter

    const handleClick = () => {
      name !== selectedFilter?.name &&
        amplitudeEvent(
          UserEvents.FilterBy.SELECTED(getFilterAndGroupDropdownName(name))
        )
      onFilterSelect(filter)
    }

    return (
      <div
        key={name}
        className={cx('filter')}
        onClick={handleClick}
        data-testid="filter"
      >
        <span>{upperFirstOnly(name)}</span>
        {selectedFilter?.name === name ? <ActiveIcon /> : <InactiveIcon />}
      </div>
    )
  })
}

const FilterDropdown = ({
  open,
  onClick,
  selectedFilter,
  onFilterSelect,
  filters,
}) => {
  const { t } = useTranslation()

  const handleFilterDropdownClick = () => {
    onClick(true)
  }

  const handleFiltersClick = event => {
    event.stopPropagation()
    onClick(false)
  }

  return (
    <div
      className={cx('filter-dropdown')}
      onClick={handleFilterDropdownClick}
      data-testid="filter-dropdown"
    >
      <span>
        {selectedFilter?.name ?? t('widgets.stationsList.filterStations')}
      </span>
      <DropdownIcon />
      {open && (
        <div
          className={cx('filters')}
          onClick={handleFiltersClick}
          data-testid="filters"
        >
          <div className={cx('filter')}>
            <span>{t('widgets.stationsList.filterBy')}</span>
            <DropdownIcon />
          </div>
          {renderFilters(selectedFilter, onFilterSelect, filters)}
        </div>
      )}
    </div>
  )
}

FilterDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedFilter: PropTypes.shape(),
  onFilterSelect: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

FilterDropdown.defaultProps = {
  selectedFilter: undefined,
}

export { FilterDropdown }
