import React from 'react'
import { PropTypes } from 'prop-types'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import { ReactComponent as InvalidIcon } from 'images/checkbox/invalid.svg'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import classNames from 'classnames/bind'
import styles from './GroupStationsCheckbox.module.scss'

const cx = classNames.bind(styles)

const GroupStationsCheckbox = ({
  groupSelected,
  groupStationIds,
  showPricingView,
}) => {
  if (!showPricingView) {
    return null
  }

  const isValidGroup = groupStationIds?.length > 0

  if (!isValidGroup) {
    return (
      <div className={cx('group-stations-checkbox')}>
        <InvalidIcon />
      </div>
    )
  }

  const handleClick = event => {
    event.stopPropagation()

    if (groupSelected) {
      stationsListPricingOperations.deselectStations(groupStationIds)
    } else {
      stationsListPricingOperations.selectStations(groupStationIds)
    }
  }

  return (
    <div
      className={cx('group-stations-checkbox')}
      onClick={handleClick}
      data-testid="group-stations-checkbox"
    >
      {groupSelected ? <ActiveIcon /> : <InactiveIcon />}
    </div>
  )
}

GroupStationsCheckbox.propTypes = {
  groupSelected: PropTypes.bool,
  groupStationIds: PropTypes.arrayOf(PropTypes.string),
  showPricingView: PropTypes.bool.isRequired,
}

GroupStationsCheckbox.defaultProps = {
  groupStationIds: null,
  groupSelected: false,
}

export { GroupStationsCheckbox }
