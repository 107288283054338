import React from 'react'
import PropTypes from 'prop-types'
import { determinePolePriceDate } from 'components/station/components/competitor-pricing/utils/helpers'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import classNames from 'classnames/bind'
import { StatusIcon } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/status-icon/'
import { EditPriceButton } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/edit-price-button'
import { PreviousPrice } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/previous-price'
import { FuelGradeIcon } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/fuel-grade-icon'
import { PriceSource } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/price-source'
import styles from './PricesInformation.module.scss'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

const renderPrice = (
  cash,
  polePrice,
  fuelGrade,
  formatPolePrice,
  formatPolePriceDate,
  onEditPrices,
  t
) => {
  const polePriceDate = determinePolePriceDate(polePrice)

  return (
    <div className={cx('price-info')}>
      <div className={cx('date')}>
        <StatusIcon priceUpdatedAt={polePrice?.priceUpdatedAt} />
        {formatPolePriceDate(polePriceDate)} (
        {cash
          ? t('widgets.competitorPricing.cash')
          : t('widgets.competitorPricing.credit')}
        )
      </div>
      <div className={cx('price')}>
        <div>
          {formatPolePrice(
            polePrice?.price,
            t('widgets.competitorPricing.noPrice')
          )}
        </div>
        <EditPriceButton onClick={onEditPrices} />
      </div>
      <div className={cx('grade-source')}>
        <FuelGradeIcon fuelGrade={fuelGrade} />
        <PriceSource polePrice={polePrice} />
        <div className={cx('previous-price')}>
          <PreviousPrice polePrice={polePrice} />
        </div>
      </div>
    </div>
  )
}

const PricesInformation = ({
  fuelGrade,
  cashPrice,
  creditPrice,
  onEditPrices,
}) => {
  const { formatPolePrice, formatPolePriceDate } = useFormatPolePrice()
  const { t } = useTranslation()

  return (
    <div className={cx('prices-information')}>
      {renderPrice(
        true,
        cashPrice,
        fuelGrade,
        formatPolePrice,
        formatPolePriceDate,
        onEditPrices,
        t
      )}
      {renderPrice(
        false,
        creditPrice,
        fuelGrade,
        formatPolePrice,
        formatPolePriceDate,
        onEditPrices,
        t
      )}
    </div>
  )
}

PricesInformation.propTypes = {
  fuelGrade: PropTypes.shape().isRequired,
  cashPrice: PropTypes.shape(),
  creditPrice: PropTypes.shape(),
  onEditPrices: PropTypes.func.isRequired,
}

PricesInformation.defaultProps = {
  cashPrice: null,
  creditPrice: null,
}

export { PricesInformation }
