import React from 'react'
import { upperFirstOnly } from 'utils/format'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './PricingTypeBadge.module.scss'

const cx = classNames.bind(styles)

const PricingTypeBadge = ({ pricingType }) => {
  return (
    <div className={cx('pricing-type-badge')}>
      {upperFirstOnly(pricingType)}
    </div>
  )
}

PricingTypeBadge.propTypes = {
  pricingType: PropTypes.string.isRequired,
}

export { PricingTypeBadge }
