import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import styles from './DayCircle.module.scss'

const cx = classNames.bind(styles)

const DayCircle = ({ selected, hovered }) => {
  const selectedCircle = (
    <g>
      <circle className={cx('selected-circle-outer')} cx="10" cy="10" r="8" />
      <circle className={cx('selected-circle-inner')} cx="10" cy="10" r="5" />
    </g>
  )
  const hoveredCircle = (
    <circle className={cx('hovered-circle')} cx="10" cy="10" r="8" />
  )

  const renderCircle = () => {
    if (selected) {
      return selectedCircle
    }
    if (hovered) {
      return hoveredCircle
    }
  }

  return (
    <div className={cx('svg-hover')} data-testid="day-circle">
      <svg width="20" height="20">
        <circle cx="10" cy="10" r="4" />
        {renderCircle()}
      </svg>
    </div>
  )
}

DayCircle.propTypes = {
  hovered: PropTypes.bool,
  selected: PropTypes.bool,
}

export { DayCircle }
