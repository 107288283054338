import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'components/common/input'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import classNames from 'classnames/bind'
import styles from './SinglePriceInput.module.scss'

const cx = classNames.bind(styles)

const SinglePriceInput = ({ allPrice, newPrice, onValueChange }) => {
  const { formatPolePrice } = useFormatPolePrice()

  return (
    <div className={cx('single-price-input')}>
      <Input
        value={newPrice}
        onValueChange={onValueChange}
        customClass={cx('price-input')}
        placeholder={formatPolePrice(allPrice?.price)}
      />
    </div>
  )
}

SinglePriceInput.propTypes = {
  allPrice: PropTypes.shape(),
  newPrice: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
}

SinglePriceInput.defaultProps = {
  allPrice: null,
}

export { SinglePriceInput }
