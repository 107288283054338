import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { images } from 'images/image-library'
import { ImageAsset } from 'components/common/image-asset'
import { PolePriceSources } from 'components/station/components/competitor-pricing/utils/constants'
import { ReactComponent as PersonIcon } from 'images/competitor-pricing/person.svg'
import styles from './PriceSource.module.scss'

const cx = classNames.bind(styles)

const PriceSource = ({ polePrice }) => {
  if (!polePrice) {
    return null
  }

  if (polePrice.source === PolePriceSources.USER_DEFINED) {
    // this is the case for old user defined prices
    if (!polePrice.creator) {
      return null
    }

    return (
      <div className={cx('creator')}>
        <PersonIcon />
        <span>{polePrice.creator}</span>
      </div>
    )
  }

  return (
    <ImageAsset
      src={images.polePriceLong(polePrice.source)}
      width={96}
      height={30}
      alt={polePrice.source}
      fallbackImage={null}
    />
  )
}

PriceSource.propTypes = {
  polePrice: PropTypes.shape(),
}

PriceSource.defaultProps = {
  polePrice: null,
}

export { PriceSource }
