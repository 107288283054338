import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useApolloClient, gql } from '@apollo/client'
import { upperFirstOnly, addTypenames } from 'utils/format'
import { FilterOperators } from 'components/stations-list/utils/constants'
import { ReactComponent as CloseIcon } from 'images/stations-list/close.svg'
import classNames from 'classnames/bind'
import { FilterPopover } from '../../../filter-popover'
import styles from './SelectedFilter.module.scss'

const { GREATER_THAN, LESS_THAN, IS, IS_NOT, ABOVE, BELOW, UP_BY, DOWN_BY } = FilterOperators

const cx = classNames.bind(styles)

const renderOperator = (operator, t) => {
  switch (operator) {
    case GREATER_THAN:
      return '>'
    case LESS_THAN:
      return '<'
    case IS:
      return '='
    case IS_NOT:
      return '!='
    case ABOVE:
      return `(${t('widgets.stationsList.above')})`
    case BELOW:
      return `(${t('widgets.stationsList.below')})`
    case UP_BY:
      return `(${t('widgets.stationsList.upBy')})`
    case DOWN_BY:
      return `(${t('widgets.stationsList.downBy')})`
    default:
      return ''
  }
}

const removeFilter = (client, selectedFilter, selectedFilters) => {
  const newSelectedFilters = selectedFilters.filter(
    ({ field, operator, value }) =>
      field !== selectedFilter.field ||
      operator !== selectedFilter.operator ||
      value !== selectedFilter.value
  )

  const data = {
    stationsList: {
      selectedFilters: addTypenames(newSelectedFilters, 'StationsListFilter'),
    },
  }
  client.writeQuery({
    query: gql`
      {
        stationsList {
          selectedFilters
        }
      }
    `,
    data,
  })
}

const SelectedFilter = ({ filters, selectedFilter, selectedFilters }) => {
  const client = useApolloClient()
  const [showRemove, setShowRemove] = useState()
  const [showFilterPopover, setShowFilterPopover] = useState(false)
  const target = useRef(null)
  const { t } = useTranslation()

  const { name, operator, value } = selectedFilter

  const handleClick = event => {
    event.stopPropagation()
    setShowFilterPopover(!showFilterPopover)
  }

  const handleMouseEnter = () => {
    setShowRemove(true)
  }

  const handleMouseLeave = () => {
    setShowRemove(false)
  }

  const handleRemoveClick = event => {
    event.stopPropagation()
    removeFilter(client, selectedFilter, selectedFilters)
  }

  return (
    <>
      <div
        className={cx('selected-filter')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        ref={target}
        data-testid="selected-filter"
      >
        {upperFirstOnly(name)} {renderOperator(operator, t)} {value}
        {showRemove && (
          <div
            className={cx('remove-button')}
            onClick={handleRemoveClick}
            data-testid="remove-button"
          >
            <CloseIcon />
          </div>
        )}
      </div>
      <FilterPopover
        filters={filters}
        selectedFilters={selectedFilters}
        existingFilter={selectedFilter}
        target={target}
        show={showFilterPopover}
        setShow={setShowFilterPopover}
        placement="bottom"
      />
    </>
  )
}

SelectedFilter.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedFilter: PropTypes.shape().isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.shape()),
}

export { SelectedFilter }
