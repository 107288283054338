import React from 'react'
import PropTypes from 'prop-types'
import { ImageAsset } from 'components/common/image-asset'
import { images, fallback } from 'images/image-library'
import classNames from 'classnames/bind'
import styles from './StationCell.module.scss'

const cx = classNames.bind(styles)

const StationCell = ({ brand, name, error }) => {
  return (
    <div className={cx('station-cell', { error })}>
      <div>
        <ImageAsset
          src={images.brandImage(brand?.key)}
          width={50}
          height={50}
          alt={`brand-${brand?.key}`}
          fallbackImage={fallback.defaultBrand}
        />
        <span>{name}</span>
      </div>
    </div>
  )
}

StationCell.propTypes = {
  brand: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
}

export { StationCell }
