import React from 'react'
import PropTypes from 'prop-types'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import classNames from 'classnames/bind'
import styles from './NewPrice.module.scss'

const cx = classNames.bind(styles)

const NewPrice = ({
  polePrice,
  priceChange,
  fuelGradeDifferential,
  leadGradePolePrice,
  leadGradePriceChange,
}) => {
  const { formatPolePrice } = useFormatPolePrice()

  if (!polePrice) {
    return null
  }

  const newPrice =
    fuelGradeDifferential && fuelGradeDifferential?.leadGrade === false
      ? leadGradePriceChange?.priceChange +
        leadGradePolePrice?.price +
        fuelGradeDifferential?.differential
      : polePrice.price + priceChange.priceChange

  return (
    <div className={cx('new-price')}>
      <div className={cx('current-price')}>
        {formatPolePrice(polePrice?.price)}
      </div>
      <div className={cx('price')}>{formatPolePrice(newPrice)}</div>
    </div>
  )
}

NewPrice.propTypes = {
  polePrice: PropTypes.shape(),
  polePriceChangeRequest: PropTypes.shape(),
  priceChange: PropTypes.shape().isRequired,
}

export { NewPrice }
