import React, { useState, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterIcon } from 'images/stations-list/filter.svg'
import classNames from 'classnames/bind'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { SelectedFilters } from './components/selected-filters'
import { FilterPopover } from './components/filter-popover'
import { Permissioned } from 'components/common/permissioned'
import styles from './Filters.module.scss'

const cx = classNames.bind(styles)

const Filters = ({ filters, selectedFilters }) => {
  const { t } = useTranslation()

  const {
    widgetPermissions: { updateStationsList },
  } = usePermittedAccess()
  const [showFilterPopover, setShowFilterPopover] = useState(false)
  const target = useRef(null)

  const handleTitleClick = () => {
    setShowFilterPopover(!showFilterPopover)
  }

  const restricted = (
    <div className={cx('filter', 'restricted')}>
      <div className={cx('title', 'restricted')}>
        <FilterIcon className={cx('filter-icon')} cursor={'not-allowed'} />
        <span className={cx('filter-text', 'restricted')}>
          {t('widgets.stationsList.filter')}
        </span>
      </div>
    </div>
  )

  return (
    <Permissioned permission={updateStationsList} fallback={restricted}>
      <div className={cx('filter')}>
        <div
          className={cx('title')}
          ref={target}
          onClick={handleTitleClick}
          data-testid="title"
        >
          <FilterIcon />
          <span className={cx('filter-text')}>
            {t('widgets.stationsList.filter')}
          </span>
        </div>
        <FilterPopover
          filters={filters}
          selectedFilters={selectedFilters}
          target={target}
          show={showFilterPopover}
          setShow={setShowFilterPopover}
          placement="bottom-start"
        />
        <SelectedFilters filters={filters} selectedFilters={selectedFilters} />
      </div>
    </Permissioned>
  )
}

Filters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.shape),
}

Filters.defaultProps = {
  selectedFilters: [],
}

export { Filters }
