import { gql } from '@apollo/client'

const BUYSMART_QUERY = gql`
  query buysmartQuery($stationId: ID!) {
    station(id: $stationId) {
      id
      buysmartEnabled
      tanks {
        id
        tankNumber
        maxVolume
        ullage
        currentVolume
        fuelGrade {
          id
          shortTitle
          title
          primary
          blended
          position
        }
        predictedVolumes {
          date
          volume
          daysLeft
          ullage
        }
      }
    }
  }
`

export { BUYSMART_QUERY }
