import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { ReactComponent as InfoIcon } from 'images/common/info.svg'
import classNames from 'classnames/bind'
import styles from './Info.module.scss'

const cx = classNames.bind(styles)

const Info = ({ tooltipText }) => {
  const overlay = (
    <Tooltip className={cx('info-tooltip')}>
      <div>{tooltipText}</div>
    </Tooltip>
  )

  return (
    <OverlayTrigger placement="top" overlay={overlay}>
      <InfoIcon height={17} width={17} data-testid="info-icon" />
    </OverlayTrigger>
  )
}

Info.propTypes = {
  tooltipText: PropTypes.string.isRequired,
}

export { Info }
