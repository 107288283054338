import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import { useErrorHandler } from 'react-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import classNames from 'classnames/bind'
import { useSlider } from 'components/common/slider/hooks/useSlider'
import { Widgets } from 'utils/constants'
import { Slider } from 'components/common/slider'
import { SliderArrow } from 'components/common/slider-arrow'
import { Grade } from 'components/common/grade'
import { LastDelivery } from './components/last-delivery/components/last-delivery'
import { DELIVERY_QUERY } from './DeliveryQueries'
import styles from './Delivery.module.scss'

const cx = classNames.bind(styles)

const { DELIVERY } = Widgets

const Delivery = ({ stationId }) => {
  const [state, dispatch] = useSlider(DELIVERY)
  const { loading, error, data } = useQuery(DELIVERY_QUERY, {
    variables: { stationId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  useErrorHandler(error)
  const { t } = useTranslation()

  if (loading || !data) {
    return (
      <div className={cx('delivery-spinner')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  }

  const {
    leftCount,
    rightCount,
    leftArrowActive,
    rightArrowActive,
    showArrowsStyle,
    elementWidth,
    elementMargin,
  } = state

  const {
    station: {
      latestDeliveries: {
        latestDeliveredAt,
        fuelGradeDeliveries: [...deliveries],
      },
    },
  } = data

  const widget = deliveries.map(
    ({ fuelGrade: { id, title, primary }, volume }, i) => (
      <div className={cx('widget-container')} key={i}>
        <Grade
          key={id}
          title={title}
          colour={primary}
          width={elementWidth}
          volume={volume}
        />
      </div>
    )
  )

  return (
    <div className={cx('delivery-container')}>
      <div className={cx('delivery-header')}>
        <div className={cx('delivery-title')}>
          {t('widgets.delivery.lastDeliveryDate')}
        </div>
        <div className={cx('slider-arrow-container')} style={showArrowsStyle}>
          <SliderArrow
            direction={'left'}
            handleClick={() => dispatch({ type: 'handleLeftClick' })}
            isActive={leftArrowActive}
          />
          <SliderArrow
            style={cx('slider-arrow')}
            direction={'right'}
            handleClick={() => dispatch({ type: 'handleRightClick' })}
            isActive={rightArrowActive}
          />
        </div>
      </div>
      <div className={cx('delivery-body')}>
        <LastDelivery date={latestDeliveredAt} />
        <Slider
          leftClick={leftCount}
          rightClick={rightCount}
          handleLeftArrowActive={dispatch}
          handleRightArrowActive={dispatch}
          showArrows={dispatch}
          singleElementWidth={elementWidth}
          elementCount={deliveries.length}
          elementMargin={elementMargin}
          additionalContainerWidth={20}
        >
          {widget}
        </Slider>
      </div>
    </div>
  )
}

Delivery.propTypes = {
  stationId: PropTypes.string.isRequired,
}

const FallbackComponent = () => <WidgetFallback title="Last delivery date" />

const DeliveryWithErrorBoundary = withEdgeErrorBoundary(
  Delivery,
  FallbackComponent
)

export { DeliveryWithErrorBoundary as Delivery }
