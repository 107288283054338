import { useTranslation } from 'react-i18next'
import { useMutation, useReactiveVar } from '@apollo/client'
import { Locales } from 'utils/constants'
import { timestampVar } from 'config/apollo/competitor-pricing/variables'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { CREATE_FUEL_GRADE_DIFFERENTIAL_MUTATION } from 'components/station/components/competitor-pricing/CompetitorPricingMutations'
import { COMPETITOR_PRICING_QUERY } from 'components/station/components/competitor-pricing/CompetitorPricingQueries'
import { usePriceChangeDispatch } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { PriceChangeActions } from 'components/station/components/competitor-pricing/context/constants'
import { useShowToast } from 'components/common/hooks/useShowToast'

const validLocales = [Locales.EN_GB, Locales.EN_IE]

function useCreateFuelGradeDifferential(stationId) {
  const { i18n } = useTranslation()
  const dispatch = usePriceChangeDispatch()
  const timestamp = useReactiveVar(timestampVar)
  const { showErrorToast } = useShowToast()
  const { supportedPricingTypes } = usePricingTypes()
  const [createFuelGradeDifferential, result] = useMutation(
    CREATE_FUEL_GRADE_DIFFERENTIAL_MUTATION,
    {
      update(
        cache,
        {
          data: {
            createFuelGradeDifferential: { error, fuelGradeDifferential },
          },
        }
      ) {
        if (error) {
          showErrorToast()
          return
        }

        const variables = {
          stationId,
          supportedPricingTypes,
          timestamp,
        }

        const {
          station,
          station: {
            priceDifferentials: {
              fuelGradeDifferentials,
              cashCreditDifferentials,
            },
          },
        } = cache.readQuery({
          query: COMPETITOR_PRICING_QUERY,
          variables,
        })

        const filteredFuelGradeDifferentials = fuelGradeDifferentials.filter(
          ({ fuelGradeId }) => fuelGradeDifferential.fuelGradeId !== fuelGradeId
        )

        const updatedPriceDifferentials = {
          cashCreditDifferentials,
          fuelGradeDifferentials: [
            ...filteredFuelGradeDifferentials,
            fuelGradeDifferential,
          ],
          __typename: 'PriceDifferentialsSet',
        }

        const updatedStation = {
          ...station,
          priceDifferentials: updatedPriceDifferentials,
        }
        cache.writeQuery({
          query: COMPETITOR_PRICING_QUERY,
          variables,
          data: { station: updatedStation },
        })
      },
      onCompleted(data) {
        const {
          createFuelGradeDifferential: { error, fuelGradeDifferential },
        } = data

        if (!error) {
          document.body.click()

          if (validLocales.includes(i18n.language)) {
            dispatch({
              type: PriceChangeActions.ChangedFuelGradeDifferential,
              payload: {
                leadGradeId: fuelGradeDifferential.leadGradeId,
              },
            })
          }
        }
      },
      onError: () => {
        // TODO: log to Sentry
        showErrorToast()
      },
    }
  )

  return [createFuelGradeDifferential, result]
}

export { useCreateFuelGradeDifferential }
