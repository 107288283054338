import React from 'react'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { MetricTypes } from 'utils/constants'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './MetricFooter.module.scss'

const { MARGIN, PROFIT } = MetricTypes

const cx = classNames.bind(styles)

export const calculateFooterValue = (
  formatMetricValue,
  metricType,
  data,
  metric = 'value'
) => {
  const isMargin = metricType === MARGIN
  const isProfit = metricType === PROFIT
  const isFillup = metricType === MetricTypes.FILL_UP
  const isPolePrice = metricType === MetricTypes.POLE_PRICE
  const isCostPrice = metricType === MetricTypes.COST

  const sanitisedData = data
    // remove data with metric = null as this skews averages
    .filter(element => element[metric] !== null)
    // then convert the value to the same numbers shown on the UI after formatting to ensure consistency
    .map(element => {
      const formatString = isMargin ? '0.00' : '0'

      if (isProfit) {
        // profit requires extra work as it is converted from the minor currency unit for display
        const valueInMinorUnit = +numeral(element[metric] / 100).format(
          formatString
        )
        return {
          ...element,
          [metric]: valueInMinorUnit * 100, // convert back to minor unit
        }
      }

      return {
        ...element,
        [metric]: +numeral(element[metric]).format(formatString),
      }
    })

  const total = sanitisedData.reduce(
    (prev, current) => prev + current[metric],
    0
  )

  if (isMargin || isFillup || isPolePrice || isCostPrice) {
    return formatMetricValue(metricType, total / sanitisedData.length)
  }

  return formatMetricValue(metricType, total)
}

const MetricFooter = ({ metricType, previousColumn, metricData }) => {
  const { formatMetricValue } = useFormatMetricValue()

  return (
    <th className={cx('metric-footer')}>
      <div>
        <p>{calculateFooterValue(formatMetricValue, metricType, metricData)}</p>
        {previousColumn && (
          <p>
            {calculateFooterValue(
              formatMetricValue,
              metricType,
              metricData,
              'previousValue'
            )}
          </p>
        )}
      </div>
    </th>
  )
}

MetricFooter.propTypes = {
  metricData: PropTypes.array.isRequired,
  metricType: PropTypes.string.isRequired,
  previousColumn: PropTypes.bool.isRequired,
}

export { MetricFooter }
