import { gql } from '@apollo/client'
import { USER_PERMISSION_ATTRIBUTES } from 'config/apollo/fragments'

export const MAIN_QUERY = gql`
  query mainQuery {
    cacheInitialised @client
    me {
      id
      locale
      firstName
      lastName
      email
      isAdmin
      isCustomer
      isDemoCustomer
      isEdgeSuperuser
      isSingleSite
      hmac
      launchDarklyHash
      stationsCount
      createdAt
      invitationSentAt
      invitationAcceptedAt
      signInCount
      roles {
        id
        title
      }
      ...UserPermissionAttributes
      company {
        id
        name
        timeZone
        edgeDataPro
      }
      stations {
        items {
          id
          name
          hasBunkered
          enabled
          brand {
            id
            name
            key
          }
        }
      }
    }
  }
  ${USER_PERMISSION_ATTRIBUTES}
`
