import React from 'react'
import { ReactComponent as PlusIcon } from 'images/competitor-pricing/plus.svg'
import { ReactComponent as MinusIcon } from 'images/competitor-pricing/minus.svg'
import { PriceChangeActions } from 'components/station/components/competitor-pricing/context/constants'
import PropTypes from 'prop-types'

const PriceButton = ({
  setManualInputEnabled,
  dispatch,
  minus,
  fuelGradeId,
  pricingType,
}) => {
  const Icon = minus ? MinusIcon : PlusIcon
  const type = minus
    ? PriceChangeActions.DecrementPrice
    : PriceChangeActions.IncrementPrice

  const handleClick = () => {
    setManualInputEnabled(false)
    dispatch({
      type,
      payload: {
        fuelGradeId,
        pricingType,
      },
    })
  }

  return (
    <Icon
      height={16}
      width={16}
      onClick={handleClick}
      data-testid="price-button"
    />
  )
}

PriceButton.propTypes = {
  setManualInputEnabled: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  minus: PropTypes.bool,
  fuelGradeId: PropTypes.string.isRequired,
  pricingType: PropTypes.string.isRequired,
}

PriceButton.defaultProps = {
  setManualInputEnabled: () => {},
  minus: false,
}

export { PriceButton }
