import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EditPricingIcon } from 'images/competitor-pricing/edit-pricing.svg'
import { usePriceChangeDispatch } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import {
  PriceChangeStatus,
  PriceChangeActions,
} from 'components/station/components/competitor-pricing/context/constants'
import classNames from 'classnames/bind'
import styles from './EditChangesButton.module.scss'

const cx = classNames.bind(styles)

const EditChangesButton = () => {
  const dispatch = usePriceChangeDispatch()
  const { t } = useTranslation()

  const handleEditClick = () => {
    dispatch({
      type: PriceChangeActions.SetCurrentStatus,
      payload: {
        status: PriceChangeStatus.Edit,
      },
    })
  }

  return (
    <div
      className={cx('edit-changes-button')}
      onClick={handleEditClick}
      data-testid="edit-changes-button"
    >
      <EditPricingIcon height={20} width={20} />
      <span>{t('widgets.competitorPricing.editChanges')}</span>
    </div>
  )
}

EditChangesButton.propTypes = {}

export { EditChangesButton }
