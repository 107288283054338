import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames/bind'
import { useErrorHandler } from 'react-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { useParams, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { unslugify } from 'utils/slug'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { LazyLoader } from '../common/lazy-loader'
import { StationTitle } from './components/station-title'
import { PerformanceReport } from 'components/common/performance-report'
import { Delivery } from './components/delivery'
import { Wetstock } from './components/wetstock'
import { StationPerformance } from './components/station-performance'
import { CompetitorPricing } from './components/competitor-pricing'
import { Buysmart } from './components/buysmart'
import { STATION_QUERY } from './StationQueries'
import styles from './Station.module.scss'

const cx = classNames.bind(styles)

const parseStation = (mainData, stationParam) => {
  const {
    me: {
      stations: { items: stations },
    },
  } = mainData
  return stations.find(
    ({ name }) => name.toUpperCase() === unslugify(stationParam).toUpperCase()
  )
}

/**
 * Combines data from the main and station query for the station widgets.
 */
const parseStationData = (station, data) => {
  const {
    station: { fuelGrades },
  } = data
  return { ...station, fuelGrades }
}

const Station = ({ mainData }) => {
  const { station: stationParam } = useParams()
  const { widgetPermissions } = usePermittedAccess()
  const station = parseStation(mainData, stationParam)
  const stationId = station?.id
  const isValidStationName = !!stationId

  const { loading, error, data } = useQuery(STATION_QUERY, {
    variables: { stationId },
    skip: !isValidStationName,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  useErrorHandler(error)

  if (loading) {
    return null
  }

  const stationData = stationId ? parseStationData(station, data) : null

  const {
    viewStationPerformance,
    viewCompetitorPricing,
    viewDelivery,
    viewWetstock,
    viewBuysmart,
    viewPerformanceReportingSingleStation,
  } = widgetPermissions

  const {
    me: { isSingleSite },
  } = mainData

  return isValidStationName ? (
    <div className={cx('station-container')}>
      {!isSingleSite && <StationTitle stationData={station} />}
      {viewStationPerformance && <StationPerformance stationId={stationId} />}
      {viewCompetitorPricing && (
        <LazyLoader testID={'competitor-pricing'}>
          <CompetitorPricing stationId={stationId} stationData={stationData} />
        </LazyLoader>
      )}
      {viewDelivery && (
        <LazyLoader testID={'delivery'}>
          <Delivery stationId={stationId} />
        </LazyLoader>
      )}
      {viewWetstock && (
        <LazyLoader>
          <Wetstock stationId={stationId} mainData={mainData} />
        </LazyLoader>
      )}
      {viewBuysmart && (
        <LazyLoader>
          <Buysmart stationId={stationId} />
        </LazyLoader>
      )}
      {viewPerformanceReportingSingleStation && (
        <LazyLoader>
          <PerformanceReport
            stationId={stationId}
            stationData={stationData}
            hasBunkered={stationData?.hasBunkered}
          />
        </LazyLoader>
      )}
    </div>
  ) : (
    <Redirect to={{ pathname: '/' }} />
  )
}

Station.propTypes = {
  mainData: PropTypes.shape().isRequired,
}

const FallbackComponent = () => (
  <WidgetFallback customClass={cx('fallback')} isPage />
)

const StationWithErrorBoundary = withEdgeErrorBoundary(
  Station,
  FallbackComponent
)

export { StationWithErrorBoundary as Station }
