import React from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from 'images/common/edge-logo-dark.svg'
import styles from './MaintenanceMode.module.scss'

const cx = classNames.bind(styles)

const MaintenanceMode = () => {
  const { t } = useTranslation()
  return (
    <div className={cx('background')}>
      <div className={cx('logo')}>
        <Logo />
      </div>
      <div className={cx('content')}>
        <div className={cx('title')}>{t('widgets.maintenance.title')}</div>
        <div className={cx('text')}>{t('widgets.maintenance.text')}</div>
      </div>
      <div className={cx('background-text')}>
        {t('widgets.maintenance.backgroundText')}
      </div>
    </div>
  )
}

export { MaintenanceMode }
