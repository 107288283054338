import { gql } from '@apollo/client'

const DELIVERY_QUERY = gql`
  query deliveryQuery($stationId: ID!) {
    station(id: $stationId) {
      id
      latestDeliveries {
        latestDeliveredAt
        fuelGradeDeliveries {
          fuelGrade {
            id
            primary
            title
            blended
          }
          volume
        }
      }
    }
  }
`

export { DELIVERY_QUERY }
