import React from 'react'
import classNames from 'classnames/bind'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useApolloClient, gql, useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { PricingTypes } from 'utils/constants'
import { statusVar } from 'config/apollo/stations-list-pricing/variables'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { Toggle } from 'components/common/toggle'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { useLocation } from 'react-router-dom'
import styles from './PricingTypeSelector.module.scss'

const { ALL, CASH, CREDIT } = PricingTypes
const cx = classNames.bind(styles)

const updateSelectedType = (client, selected) => {
  const data = {
    pricingTypes: {
      selected,
    },
  }
  client.writeQuery({
    query: gql`
      {
        pricingTypes {
          selected
        }
      }
    `,
    data,
  })
}

const PricingTypeSelector = () => {
  const { selectedPricingType, cashCreditPricing } = usePricingTypes()
  const client = useApolloClient()
  const status = useReactiveVar(statusVar)
  const { t } = useTranslation()
  const location = useLocation()
  const currentPage = location.pathname

  if (!cashCreditPricing || currentPage === "/analytics") {
    return <div className={cx('pricing-type-selector')} />
  }

  const disabled = status !== StationsListPricingStatus.NONE

  const handleCashChange = (client, currentSelectedType) => {
    if (!disabled) {
      const selectedType = currentSelectedType === CREDIT ? ALL : CREDIT
      updateSelectedType(client, selectedType)
    }
  }

  const handleCreditChange = (client, currentSelectedType) => {
    if (!disabled) {
      const selectedType = currentSelectedType === CASH ? ALL : CASH
      updateSelectedType(client, selectedType)
    }
  }

  const cashChecked = [ALL, CASH].includes(selectedPricingType)
  const creditChecked = [ALL, CREDIT].includes(selectedPricingType)

  const overlay = (
    <Tooltip className={cx('cash-credit-tooltip')}>
      <div>{t('widgets.header.cashCreditTooltip')}</div>
    </Tooltip>
  )

  const toggles = (
    <div className={cx('cash-credit-toggles')}>
      <Toggle
        id="cash-toggle"
        label={t('cash')}
        checked={cashChecked}
        handleToggle={() => handleCashChange(client, selectedPricingType)}
        disabled={disabled}
      />
      <Toggle
        id="credit-toggle"
        label={t('credit')}
        checked={creditChecked}
        handleToggle={() => handleCreditChange(client, selectedPricingType)}
        disabled={disabled}
      />
    </div>
  )

  if (disabled) {
    return (
      <div className={cx('pricing-type-selector')}>
        <OverlayTrigger placement="bottom" overlay={overlay}>
          {toggles}
        </OverlayTrigger>
      </div>
    )
  }

  return <div className={cx('pricing-type-selector')}>{toggles}</div>
}

PricingTypeSelector.propTypes = {}

export { PricingTypeSelector }
