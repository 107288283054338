import { useTranslation } from 'react-i18next'
import { isNullOrUndefined } from 'utils/helpers'
import { Locales, StationDataStatus } from 'utils/constants'
import { calendarDaysDifference } from 'utils/dateTime'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'

const { AMBER, RED } = StationDataStatus

function useFormatStatus() {
  const { companyTimeZone } = useCompanyTimeZone()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  function formatStatusDate(date) {
    if (isNullOrUndefined(date)) {
      return null
    }

    const dateInZone = moment.tz(date, companyTimeZone)
    if (!dateInZone.isValid()) {
      return null
    }

    const daysAgo = calendarDaysDifference(
      moment().tz(companyTimeZone),
      dateInZone
    )

    const timeValue =
      language === Locales.EN_US
        ? dateInZone.format('h:mma')
        : dateInZone.format('HH:mm')

    if (daysAgo === 0) {
      return t('widgets.stationStatus.daysAgoZeroText', {
        timeValue,
      })
    } else if (daysAgo === 1) {
      return t('widgets.stationStatus.daysAgoOneText', {
        timeValue,
      })
    }

    return t('widgets.stationStatus.daysAgoText', {
      daysAgo: `${daysAgo}`,
      timeValue,
    })
  }

  function formatCostPriceStatusDate(date) {
    if (isNullOrUndefined(date)) {
      return null
    }

    const dateInZone = moment.tz(date, companyTimeZone)
    if (!dateInZone.isValid()) {
      return null
    }

    if (language === Locales.EN_US) {
      return dateInZone.format('Do MMM @ h:mma')
    }

    return dateInZone.format('Do MMM')
  }

  function formatStatus(status) {
    if (status === AMBER) {
      return t('widgets.stationStatus.idle')
    } else if (status === RED) {
      return t('widgets.stationStatus.offline')
    }
    return t('widgets.stationStatus.online')
  }

  function formatTransactionStatus(isStationClosed, transactionStatus) {
    if (isStationClosed) {
      return t('widgets.stationStatus.closed')
    }

    return formatStatus(transactionStatus)
  }

  return {
    formatStatusDate,
    formatCostPriceStatusDate,
    formatTransactionStatus,
    formatStatus,
  }
}

export { useFormatStatus }
