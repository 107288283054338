import React from 'react'
import { ReactComponent as HandleIcon } from 'images/stations-list/edit-pricing/handle.svg'
import classNames from 'classnames/bind'
import styles from './Handle.module.scss'

const cx = classNames.bind(styles)

const Handle = () => {
  return (
    <div className={cx('handle')} data-testid="handle">
      <HandleIcon />
    </div>
  )
}

Handle.propTypes = {}

export { Handle }
