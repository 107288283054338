import { useEffect } from 'react'

function useChurnZero(me) {
  const CHURN_ZERO_KEY = process.env.REACT_APP_CHURN_ZERO_APP_KEY

  useEffect(() => {
    const CHURN_ZERO_ENABLED = process.env.REACT_APP_CHURN_ZERO_ENABLED === 'true'

    if (!me || me.isEdgeSuperuser || !CHURN_ZERO_ENABLED) {
      return
    }

    const {
      email: email,
      company: { id: companyId }
    } = me

    // ChurnZero script
    window.ChurnZero = window.ChurnZero || [];
    const cz = document.createElement('script');
    cz.type = 'text/javascript';
    cz.async = true;
    cz.src = 'https://edgepetrol.eu1app.churnzero.net/churnzero.js';

    document.getElementsByTagName('head')[0].appendChild(cz);

    window.ChurnZero.push(['setAppKey', CHURN_ZERO_KEY]);
    window.ChurnZero.push(['setContact', companyId, email]);

    return () => {
        delete window.ChurnZero;
        document.getElementsByTagName('head')[0].removeChild(cz);
      };
  }, [me])
}

export { useChurnZero }
