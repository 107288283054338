import { gql, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useShowToast } from 'components/common/hooks/useShowToast'

const CLEAR_NOTIFICATION_MUTATION = gql`
  mutation clearNotification($id: ID!) {
    clearNotification(id: $id) {
      error
    }
  }
`

function useClearNotification() {
  const { showErrorToast } = useShowToast()
  const [clearNotification] = useMutation(CLEAR_NOTIFICATION_MUTATION, {
    onCompleted({ clearNotification }) {
      if (clearNotification.error) {
        showErrorToast()
      }
    },
    onError(e) {
      Sentry.captureException(e)
      showErrorToast()
    },
  })

  return [clearNotification]
}

export { useClearNotification, CLEAR_NOTIFICATION_MUTATION }
