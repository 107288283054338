import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'
import classNames from 'classnames/bind'
import { CompetitorPricing } from 'components/station/components/competitor-pricing'
import { StationPerformance } from 'components/station/components/station-performance'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { Wetstock } from 'components/station/components/wetstock'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { STATION_WIDGETS_QUERY } from './StationWidgetsQueries'
import styles from './StationWidgets.module.scss'

const cx = classNames.bind(styles)

/**
 * Combines data from the main and station query for the station widgets.
 */
const parseStationData = (station, data) => {
  const {
    station: { fuelGrades },
  } = data
  return { ...station, fuelGrades }
}

const StationWidgets = ({ stationId, stations }) => {
  const { widgetPermissions } = usePermittedAccess()
  const { loading, error, data } = useQuery(STATION_WIDGETS_QUERY, {
    variables: { stationId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  useErrorHandler(error)

  if (loading || !data) {
    return null
  }

  const station = stations.find(({ id }) => id === stationId)
  const stationData = parseStationData(station, data)

  const {
    viewStationPerformance,
    viewCompetitorPricing,
    viewWetstock,
  } = widgetPermissions

  return (
    <div className={cx('station-widgets')} data-testid="station-widgets">
      {viewStationPerformance && (
        <StationPerformance stationId={stationId} isOverlayMode />
      )}
      {viewCompetitorPricing && (
        <CompetitorPricing
          stationId={stationId}
          stationData={stationData}
          isOverlayMode
        />
      )}
      {viewWetstock && <Wetstock stationId={stationId} />}
    </div>
  )
}

StationWidgets.propTypes = {
  stationId: PropTypes.string.isRequired,
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

const FallbackComponent = () => <WidgetFallback customClass={cx('fallback')} />

const StationWidgetsWithErrorBoundary = withEdgeErrorBoundary(
  StationWidgets,
  FallbackComponent
)

export { StationWidgetsWithErrorBoundary as StationWidgets }
