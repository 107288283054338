import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { useTranslation } from 'react-i18next'
import styles from './Period.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

const formatPeriod = (companyTimeZone, utcMoment) => {
  return moment.tz(utcMoment, companyTimeZone).format('Do MMM YYYY')
}

const Period = ({ companyTimeZone, period }) => {
  const { t } = useTranslation(['translations'])

  if (!period) {
    return <div className={cx('container')} data-testid="period" />
  }

  const { from, to } = period
  const toText = formatPeriod(companyTimeZone, to)

  if (from?.isSame(to, 'day')) {
    return (
      <div className={cx('container')} data-testid="period">
        <div className={cx('to-text')}>{toText}</div>
      </div>
    )
  }

  return (
    <div className={cx('container')} data-testid="period">
      <div className={cx('from-text')}>
        {period?.from && formatPeriod(companyTimeZone, period.from)}
      </div>
      <div className={cx('to-label')}>{t('widgets.performanceReport.to')}</div>
      <div className={cx('to-text')}>{toText}</div>
    </div>
  )
}

Period.propTypes = {
  companyTimeZone: PropTypes.string.isRequired,
  period: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
}

Period.defaultProps = {
  period: null,
}

export { Period }
