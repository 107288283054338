import { fuelGradePriceChangesVar } from 'config/apollo/stations-list-pricing/variables'

const typePolicy = {
  fields: {
    priceChange(_, { readField }) {
      const id = readField('id')
      const fuelGradePriceChange = fuelGradePriceChangesVar().find(
        ({ fuelGradeId }) => fuelGradeId === id
      )
      return fuelGradePriceChange?.priceChange || 0
    },
  },
}

export { typePolicy }
