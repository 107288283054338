import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'components/common/input'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import classNames from 'classnames/bind'
import styles from './CashCreditPriceInputs.module.scss'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

const CashCreditPriceInputs = ({
  cashPrice,
  creditPrice,
  newCashPrice,
  newCreditPrice,
  onCashValueChange,
  onCreditValueChange,
}) => {
  const { t } = useTranslation()
  const { formatPolePrice } = useFormatPolePrice()

  return (
    <div className={cx('price-inputs')}>
      <div className={cx('price-input-wrapper')}>
        <h4>{t('widgets.competitorPricing.cash')}</h4>
        <Input
          value={newCashPrice}
          onValueChange={onCashValueChange}
          customClass={cx('price-input')}
          placeholder={formatPolePrice(cashPrice?.price)}
        />
      </div>
      <div className={cx('price-input-wrapper')}>
        <h4>{t('widgets.competitorPricing.credit')}</h4>
        <Input
          value={newCreditPrice}
          onValueChange={onCreditValueChange}
          customClass={cx('price-input')}
          placeholder={formatPolePrice(creditPrice?.price)}
        />
      </div>
    </div>
  )
}

CashCreditPriceInputs.propTypes = {
  cashPrice: PropTypes.shape(),
  creditPrice: PropTypes.shape(),
  newCashPrice: PropTypes.string.isRequired,
  newCreditPrice: PropTypes.string.isRequired,
  onCashValueChange: PropTypes.func.isRequired,
  onCreditValueChange: PropTypes.func.isRequired,
}

CashCreditPriceInputs.defaultProps = {
  cashPrice: null,
  creditPrice: null,
}

export { CashCreditPriceInputs }
