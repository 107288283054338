import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly } from 'utils/format'
import classNames from 'classnames/bind'
import styles from './MetricHeader.module.scss'

const cx = classNames.bind(styles)

const MetricHeader = ({
  metric,
  periodString,
  previousColumn,
  analytics,
  headerColour
}) => {
  const { t } = useTranslation()
  const colour = analytics ? headerColour : metric

  return (
    <th className={cx('metric-header', 'green', colour)}>
      <div>
        {analytics ? metric : upperFirstOnly(t(`widgets.performanceReport.${metric}`))}
        <p>
          {periodString &&
            `${t('widgets.performanceReport.last')} ${periodString}`}
        </p>
        <p>
          {previousColumn && periodString &&
            `${t('widgets.performanceReport.previous')} ${periodString}`}
        </p>
      </div>
    </th>
  )
}

MetricHeader.propTypes = {
  metric: PropTypes.string.isRequired,
  periodString: PropTypes.string,
  previousColumn: PropTypes.bool.isRequired,
  analytics: PropTypes.bool,
  headerColour: PropTypes.string
}

export { MetricHeader }
