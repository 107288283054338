import React, { createContext, useContext } from 'react'

const StationsContext = createContext(null)

const StationsProvider = ({ stations, children }) => {
  return (
    <StationsContext.Provider value={stations}>
      {children}
    </StationsContext.Provider>
  )
}

const useStations = () => {
  const stations = useContext(StationsContext)
  return stations
}

export { StationsProvider, useStations }
