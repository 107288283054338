import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { RENDER_DELAY } from 'components/stations-list/utils/constants'
import { StationWidgets } from './components/station-widgets'
import styles from './OverlayBody.module.scss'

const cx = classNames.bind(styles)

const OverlayBody = ({ stationId, stations }) => {
  const [renderedStation, setRenderedStation] = useState(null)

  useEffect(() => {
    const timeout = setTimeout(
      () => setRenderedStation(stationId),
      RENDER_DELAY
    )
    return () => clearTimeout(timeout)
  }, [stationId])

  if (renderedStation !== stationId) {
    return <div className={cx('overlay-body')} />
  }

  return (
    <div className={cx('overlay-body')}>
      <StationWidgets stationId={stationId} stations={stations} />
    </div>
  )
}

OverlayBody.propTypes = {
  stationId: PropTypes.string.isRequired,
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { OverlayBody }
