import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { convert24To12HourTime } from 'utils/dateTime'
import { Locales } from 'utils/constants'
import { ActionAtPeriods } from 'utils/pricing/constants'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { Info } from 'components/common/info'
import { timeIntervals, filteredTimeIntervals } from 'utils/timeIntervals'
import classNames from 'classnames/bind'
import { ActionAtPopover } from './components/action-at-popover'
import styles from './ActionAtSelector.module.scss'

const { Immediately, LaterToday, Tomorrow, Custom } = ActionAtPeriods

const cx = classNames.bind(styles)

const renderSelectedPeriod = (
  selectedPeriod,
  selectedDate,
  selectedTime,
  companyTimeZone,
  t,
  language
) => {
  const formattedSelectedTime =
    language === Locales.EN_US
      ? convert24To12HourTime(selectedTime)
      : selectedTime

  if (selectedPeriod === Custom) {
    const dateInZone = moment.tz(selectedDate, companyTimeZone)
    return `${dateInZone.format('Do MMM YYYY')} @ ${formattedSelectedTime}`
  }

  const translations = [
    t('widgets.competitorPricing.immediately'),
    t('widgets.competitorPricing.laterToday'),
    t('widgets.competitorPricing.tomorrow'),
  ]

  if (selectedPeriod === Immediately) {
    return translations[Immediately]
  }

  return `${translations[selectedPeriod]} @ ${formattedSelectedTime}`
}

const parseOptions = (
  selectedPeriod,
  selectedDate,
  selectedTime,
  companyTimeZone
) => {
  switch (selectedPeriod) {
    case Immediately: {
      return {
        period: selectedPeriod,
        date: null,
        time: null,
        timeIntervals: null,
      }
    }
    case LaterToday: {
      const laterTimeIntervals = filteredTimeIntervals(
        companyTimeZone,
        false,
        15
      )

      return {
        period: selectedPeriod,
        date: null,
        time: selectedTime ?? laterTimeIntervals[0],
        timeIntervals: laterTimeIntervals,
      }
    }
    case Tomorrow: {
      const allTimeIntervals = timeIntervals()

      return {
        period: selectedPeriod,
        date: null,
        time: selectedTime ?? allTimeIntervals[0],
        timeIntervals: allTimeIntervals,
      }
    }
    case Custom: {
      const tomorrow = moment
        .tz(companyTimeZone)
        .add(1, 'days')
        .startOf('day')
        .utc()
      const allTimeIntervals = timeIntervals()

      return {
        period: selectedPeriod,
        date: selectedDate ?? tomorrow,
        time: selectedTime ?? allTimeIntervals[0],
        timeIntervals: allTimeIntervals,
      }
    }
    /* istanbul ignore next */
    default: {
      throw new Error(`unknown period ${selectedPeriod}`)
    }
  }
}

const ActionAtSelector = ({
  selectedPeriod,
  selectedDate,
  selectedTime,
  onConfirmClick,
}) => {
  const { companyTimeZone } = useCompanyTimeZone()
  const {
    t,
    i18n: { language },
  } = useTranslation(['translations'])
  const [options, setOptions] = useState(() =>
    parseOptions(selectedPeriod, selectedDate, selectedTime, companyTimeZone)
  )
  const [showActionAtPopover, setShowActionAtPopover] = useState(false)
  const target = useRef(null)

  const handleActionAtClick = () => {
    setShowActionAtPopover(!showActionAtPopover)
  }

  const handlePeriodSelect = newPeriod => {
    const options = parseOptions(newPeriod, null, null, companyTimeZone)
    setOptions(options)
  }

  const handleTimeSelect = newTime => {
    setOptions(options => ({ ...options, time: newTime }))
  }

  const handleCalendarDateChange = newDate => {
    setOptions(options => ({ ...options, date: newDate }))
  }

  const handleCancel = () => {
    setShowActionAtPopover(false)
    setTimeout(() => {
      // reset the options to the user's current selection
      const options = parseOptions(
        selectedPeriod,
        selectedDate,
        selectedTime,
        companyTimeZone
      )
      setOptions(options)
    }, 200)
  }

  const handleConfirm = () => {
    const { period, date, time } = options

    onConfirmClick(period, date, time)
    setShowActionAtPopover(false)
  }

  return (
    <div className={cx('action-at')}>
      <div className={cx('title')}>
        <span>{t('widgets.competitorPricing.priceChangesToBeMadeAt')}</span>
        <Info
          tooltipText={t(
            'widgets.competitorPricing.priceChangesToBeMadeAtTooltip'
          )}
        />
      </div>
      <div
        className={cx('selected-action-at')}
        ref={target}
        onClick={handleActionAtClick}
        data-testid="selected-action-at"
      >
        {renderSelectedPeriod(
          selectedPeriod,
          selectedDate,
          selectedTime,
          companyTimeZone,
          t,
          language
        )}
      </div>
      <ActionAtPopover
        options={options}
        target={target}
        show={showActionAtPopover}
        onCalendarDateChange={handleCalendarDateChange}
        onHide={handleCancel}
        onPeriodSelect={handlePeriodSelect}
        onTimeSelect={handleTimeSelect}
        onConfirm={handleConfirm}
      />
    </div>
  )
}

ActionAtSelector.propTypes = {
  selectedPeriod: PropTypes.number.isRequired,
  selectedDate: PropTypes.instanceOf(moment),
  selectedTime: PropTypes.string,
  onConfirmClick: PropTypes.func.isRequired,
}

export { ActionAtSelector }
