import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Spinner from 'react-bootstrap/Spinner'
import { numberToFixed } from 'utils/number'
import { ReactComponent as PlusIcon } from 'images/competitor-pricing/fuel-grade-differential/plus.svg'
import { ReactComponent as MinusIcon } from 'images/competitor-pricing/fuel-grade-differential/minus.svg'
import { determineDifferentialStep } from 'components/station/components/competitor-pricing/utils/helpers'
import { useFormatPriceDifferential } from 'components/common/hooks/useFormatPriceDifferential'
import { useClickablePopover } from 'components/common/slider/hooks/useClickablePopover'
import classNames from 'classnames/bind'
import { useCreateFuelGradeDifferential } from '../../hooks/useCreateFuelGradeDifferential'
import styles from './FuelGradeDifferentialPopover.module.scss'

const cx = classNames.bind(styles)

const validDifferential = (value, fuelGradeDifferential) => {
  if (value === fuelGradeDifferential || value === 0) {
    return false
  }
  return true
}

const FuelGradeDifferentialPopover = ({
  children,
  stationId,
  fuelGrade,
  fuelGradeDifferential,
  sliderDispatch,
}) => {
  const { formatPriceDifferential } = useFormatPriceDifferential()
  const [value, setValue] = useState(fuelGradeDifferential?.differential || 0)
  const popoverRef = 'fuel-grade-differential-popover-ref'
  const [
    createFuelGradeDifferential,
    { loading },
  ] = useCreateFuelGradeDifferential(stationId)
  useClickablePopover(popoverRef, sliderDispatch)

  if (fuelGradeDifferential?.leadGrade) {
    return children
  }

  const disabled =
    loading || !validDifferential(value, fuelGradeDifferential.differential)
  const gradeStyles = { color: fuelGrade.primary }
  const differentialStep = determineDifferentialStep()

  const handleMinusClick = () => {
    setValue(numberToFixed(value - differentialStep))
  }

  const handlePlusClick = () => {
    setValue(numberToFixed(value + differentialStep))
  }

  const handleSubmitClick = () => {
    if (!disabled) {
      sliderDispatch({
        type: 'enabled',
        payload: true,
      })
      createFuelGradeDifferential({
        variables: {
          stationId,
          leadGradeId: fuelGradeDifferential.leadGradeId,
          fuelGradeId: fuelGrade.id,
          differential: value,
        },
      })
    }
  }

  const overlay = (
    <Popover id={popoverRef} className={cx('fuel-grade-differential-popover')}>
      <Popover.Content className={cx('content')}>
        <h3>
          Grade differential for{' '}
          <span style={gradeStyles}>{fuelGrade.shortTitle}</span>
        </h3>
        <div className={cx('value')}>{formatPriceDifferential(value, 0)}</div>
        <div className={cx('buttons')}>
          <div
            className={cx('minus')}
            onClick={handleMinusClick}
            data-testid="minus"
          >
            <MinusIcon />
          </div>
          <div
            className={cx('plus')}
            onClick={handlePlusClick}
            data-testid="plus"
          >
            <PlusIcon />
          </div>
        </div>
        <button className={cx('btn', { disabled })} onClick={handleSubmitClick}>
          {loading ? <Spinner animation="border" variant="light" /> : 'Save'}
        </button>
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={overlay}
      rootClose
    >
      {children}
    </OverlayTrigger>
  )
}

FuelGradeDifferentialPopover.propTypes = {
  children: PropTypes.shape().isRequired,
  stationId: PropTypes.string.isRequired,
  fuelGrade: PropTypes.shape().isRequired,
  fuelGradeDifferential: PropTypes.shape().isRequired,
  sliderDispatch: PropTypes.func.isRequired,
}

FuelGradeDifferentialPopover.defaultProps = {}

export { FuelGradeDifferentialPopover }
