import { useEffect } from 'react'

function useHeap() {
  const HEAP_ENABLED = process.env.REACT_APP_HEAP_ENABLED === 'true'

  function useLoadHeap() {
    useEffect(() => {
      if (!HEAP_ENABLED) {
        return
      }
      // Heap script
      window.heap = window.heap || []
      window.heap.load = function(appId, heapConfig) {
        window.heap.appid = appId
        window.heap.config = heapConfig || {}

        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://cdn.heapanalytics.com/js/heap-' + appId + '.js'

        const firstScript = document.getElementsByTagName('script')[0]
        firstScript?.parentNode.insertBefore(script, firstScript)

        const cloneArray = arrayLike => Array.prototype.slice.call(arrayLike, 0)

        const createMethod = function(method) {
          return function() {
            window.heap.push([method, ...cloneArray(arguments)])
          }
        }

        const methods = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty',
        ]

        for (let method of methods) {
          window.heap[method] = createMethod(method)
        }
      }

      window.heap.load(process.env.REACT_APP_HEAP_KEY, {
        secureCookie: true,
      })
    }, [])
  }

  function useHeapIdentify(me) {
    useEffect(() => {
      if (!me || !HEAP_ENABLED) {
        return
      }

      const {
        id: userId,
        firstName,
        lastName,
        email,
        company: { id: companyId, name: companyName },
        isAdmin,
        isDemoCustomer,
        isCustomer,
        stationsCount,
        createdAt,
        invitationSentAt,
        invitationAcceptedAt,
        signInCount,
      } = me

      const heap = window.heap

      if (heap) {
        heap.identify(userId)
        heap.addUserProperties({
          userId,
          firstName,
          lastName,
          email,
          companyId,
          companyName,
          admin: isAdmin,
          customer: isCustomer,
          demoCustomer: isDemoCustomer,
          createdAt,
          stations: stationsCount,
          invitationSentAt,
          invitationAcceptedAt,
          signInCount,
        })
      }
    }, [me])
  }

  return { useLoadHeap, useHeapIdentify }
}

export { useHeap }
