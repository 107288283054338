import React from 'react'
import PropTypes from 'prop-types'
import { FIRST_PAGE, PAGE_SIZE } from 'components/stations-list/utils/constants'
import { slugify } from 'utils/slug'
import { GroupRow } from '../group-row'

const GroupRows = ({
  timestamp,
  mainData,
  data,
  options,
  selectedPricingType,
  showPricingView,
  cashCreditPricing,
}) => {
  const { selectedSort, selectedFilters, selectedGroupBy } = options
  const {
    stationGroups: { groups },
  } = data

  const sharedVariables = {
    timestamp,
    number: FIRST_PAGE,
    size: PAGE_SIZE,
    sort: selectedSort,
    filters: selectedFilters,
    pricingType: selectedPricingType,
    pricing: showPricingView,
  }

  return groups
    .filter(group => group.stationsCount > 0)
    .map(group => {
      const key = slugify(`${selectedGroupBy}-${group.name}`)

      const variables = {
        ...sharedVariables,
        group: {
          groupBy: selectedGroupBy,
          currentGroup: group.name,
        },
      }

      return (
        <GroupRow
          key={key}
          selectedGroupBy={selectedGroupBy}
          group={group}
          variables={variables}
          mainData={mainData}
          showPricingView={showPricingView}
          cashCreditPricing={cashCreditPricing}
        />
      )
    })
}

GroupRows.propTypes = {
  timestamp: PropTypes.string.isRequired,
  mainData: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  options: PropTypes.shape({
    selectedFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedGroupBy: PropTypes.string,
    selectedSort: PropTypes.shape().isRequired,
  }),
  showPricingView: PropTypes.bool.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
}

GroupRows.defaultProps = {
  selectedGroupBy: null,
}

export { GroupRows }
