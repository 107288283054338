import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Spinner from 'react-bootstrap/Spinner'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import classNames from 'classnames/bind'
import styles from './Buttons.module.scss'

const cx = classNames.bind(styles)

const Buttons = ({
  disabledSave,
  updateLoading,
  deleteLoading,
  targetMargin,
  handleSave,
  handleDelete,
  isOverlayMode,
}) => {
  const { t } = useTranslation()

  const isSaveDisabled = disabledSave || updateLoading || deleteLoading
  const isDeleteHidden = updateLoading || !targetMargin

  const handleSaveClick = () => {
    handleSave()
    amplitudeEvent(
      UserEvents.TargetMargin.NEW_TARGET_MARGIN_SAVED(isOverlayMode)
    )
  }

  return (
    <div className={cx('buttons')}>
      {deleteLoading ? (
        <div className={cx('delete-spinner')}>
          <Spinner
            animation="border"
            variant="danger"
            data-testid="delete-spinner"
          />
        </div>
      ) : (
        <button
          className={cx('delete', {
            hidden: isDeleteHidden,
          })}
          onClick={handleDelete}
        >
          {t('widgets.stationPerformance.deleteTarget')}
        </button>
      )}
      <button
        className={cx('save', {
          'disabled-save': isSaveDisabled,
        })}
        disabled={isSaveDisabled}
        onClick={handleSaveClick}
      >
        {updateLoading ? (
          <Spinner
            animation="border"
            variant="light"
            data-testid="create-spinner"
          />
        ) : (
          t('widgets.stationPerformance.save')
        )}
      </button>
    </div>
  )
}

Buttons.propTypes = {
  disabledSave: PropTypes.bool.isRequired,
  updateLoading: PropTypes.bool.isRequired,
  deleteLoading: PropTypes.bool.isRequired,
  targetMargin: PropTypes.number,
  handleSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isOverlayMode: PropTypes.bool,
}

Buttons.defaultProps = { isOverlayMode: false }

export { Buttons }
