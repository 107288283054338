import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { images, fallback } from 'images/image-library'
import { ImageAsset } from 'components/common/image-asset'
import { getFirstPartOfURLPath } from 'utils/helpers'
import { ReactComponent as DropdownIcon } from 'images/common/dropdown.svg'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import { ReactComponent as SearchIcon } from 'images/stations-selector/search.svg'
import { useStations } from 'components/common/hooks/useStations'
import { stationMatches } from 'utils/search'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import classNames from 'classnames/bind'
import styles from './StationSelector.module.scss'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'
import { groupByVar } from 'config/apollo/analytics-report/variables'


const cx = classNames.bind(styles)


const renderStations = (
  stations,
  value,
  onValueChange,
  search,
  setTooltipTarget,
  itemsLimit) => {

  stations = stations.slice()
  if (search) {
    stations = stations.filter(s => stationMatches(s.name, search))
  }

  return stations.map(station => {
    const handleClick = (event) => {
      let newValue = value.slice()
      if (newValue.includes(station.id)) {
        const index = newValue.indexOf(station.id)
        newValue.splice(index, 1)
      } else {
        newValue = [...newValue, station.id]
      }
      newValue.sort((a, b) => {
        const ai = stations.findIndex(s => s.id === a)
        const bi = stations.findIndex(s => s.id === b)
        return ai - bi
      })

      const maxItemsSelected = itemsLimit - ( groupByVar()['PERIOD_ANALYSIS'].length)
      if (!itemsLimit || (itemsLimit && newValue.length <= maxItemsSelected)) {
        setTooltipTarget(null)
        onValueChange(newValue.slice())
      }
      else {
        setTooltipTarget(event.target)
      }
    }

    return (
      <div
        key={station.id}
        className={cx('item')}
        onClick={handleClick}
        data-testid="item"
      >
        <div className={cx('station-name')}>
          <ImageAsset
            height={20}
            width={20}
            src={images.brandImage(station.brand?.key)}
            alt={`station-cell-brand-${station.brand?.key}`}
            fallbackImage={fallback.defaultBrand}
          />
          <span>{station.name}</span>
        </div>
        {value.includes(station.id) ? <ActiveIcon /> : <InactiveIcon />}
      </div>
    )
  })
}

const StationSelector = ({
  value,
  customClass,
  leftAccessory,
  placeholder,
  allStationsText,
  maxHeight,
  onValueChange,
  disabled,
  selectButtons,
  itemsLimit
}) => {
  const { t } = useTranslation()
  const stations = useStations()
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const [tooltipTarget, setTooltipTarget] = useState(null)

  useEffect(() => {
    if (!itemsLimit) {
      setTooltipTarget(null)
    }
  }, [itemsLimit, setTooltipTarget])

  const handleDropdownClick = () => {
    if (!disabled) {
      !open &&
        amplitudeEvent(
          UserEvents.PerformanceReport.STATIONS_FILTER_SELECTED(
            getFirstPartOfURLPath(window.location.pathname)
          )
        )
      setOpen(!open)
    }
  }

  const handleItemClick = event => {
    event.stopPropagation()
  }

  const showPlaceholder = value.length === 0
  const valueText = value
    .slice()
    .sort((a, b) => {
      const ai = stations.findIndex(s => s.id === a)
      const bi = stations.findIndex(s => s.id === b)
      return ai - bi
    })
    .map(id => stations.find(s => s.id === id)?.name)
    .join(', ')
  const displayedValue = showPlaceholder
    ? placeholder
    : value.length === stations.length
    ? allStationsText ?? valueText
    : valueText

  const handleSelectAll = () => {
    const value = stations.map(s => s.id)
    onValueChange(value)
    setSearch('')
  }

  const handleDeselectAll = () => {
    onValueChange([])
    setSearch('')
  }

  const handleSearchChange = event => {
    const search = event.target.value.replace(/\s+/g, ' ')
    setSearch(search)
  }

  const handleTooltipClick = () => {
    setTooltipTarget(null)
  }

  return (
    <div
      className={cx('dropdown', customClass, {
        disabled,
        open,
      })}
      onClick={handleDropdownClick}
      data-testid="station-select"
    >
      <span className={cx({ placeholder: showPlaceholder })}>
        {leftAccessory} {displayedValue}
      </span>
      {!disabled && <DropdownIcon className={cx('dropdown-icon')} />}
      {open && (
        <div className={cx('items')} onClick={handleItemClick}>
          <div className={cx('item')} onClick={handleDropdownClick}>
            <span className={cx({ placeholder: showPlaceholder })}>
              {leftAccessory} {displayedValue}
            </span>
            <DropdownIcon className={cx('dropdown-icon')} />
          </div>
          <div className={cx('search')}>
            <input
              type="text"
              value={search}
              onChange={handleSearchChange}
              placeholder="Search for a station"
              data-testid="search"
            />
            <SearchIcon />
          </div>
          <div
            className={cx('items-list')}
            data-testid="items-list"
            style={{
              maxHeight,
            }}
          >
            {renderStations(stations, value, onValueChange, search, setTooltipTarget, itemsLimit)}
             <Overlay target={tooltipTarget} show={!!tooltipTarget} position="top">
              <Tooltip className={cx('tooltip')} onClick={handleTooltipClick}>
                <div className={cx('tooltip-content')} data-testid="tooltip"> 
                  {t('widgets.stationSelector.itemsLimit', {
                    limit: itemsLimit
                  })}
                </div>
              </Tooltip>
            </Overlay>
          </div>
          {selectButtons && (
            <div className={cx('selection-helpers')}>
              <button
                onClick={handleSelectAll}
                className={cx('select-all')}
                data-testid="select-all"
              >
                {t('widgets.stationSelector.selectAll')}
              </button>
              <button
                onClick={handleDeselectAll}
                className={cx('deselect-all')}
                data-testid="deselect-all"
              >
                {t('widgets.stationSelector.deselectAll')}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

StationSelector.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onValueChange: PropTypes.func,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  maxHeight: PropTypes.string,
  allStationsText: PropTypes.string,
  disabled: PropTypes.bool,
  leftAccessory: PropTypes.node,
  removeButtons: PropTypes.bool,
  itemsLimit: PropTypes.number
}

StationSelector.defaultProps = {
  value: [],
  onValueChange: null,
  customClass: null,
  placeholder: null,
  maxHeight: null,
  allStationsText: null,
  disabled: false,
  leftAccessory: null,
  selectButtons: true,
  itemsLimit: null
}

export { StationSelector }
