import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as PlusIcon } from 'images/pole-prices/plus.svg'
import { ReactComponent as MinusIcon } from 'images/pole-prices/minus.svg'
import { useFormatPriceChange } from 'components/common/hooks/useFormatPriceChange'
import classNames from 'classnames/bind'
import styles from './FuelGradePrices.module.scss'

const cx = classNames.bind(styles)

const FuelGradePrices = memo(({ fuelGrades, onIncrement, onDecrement }) => {
  const { formatPriceChange } = useFormatPriceChange()

  return fuelGrades.map(fuelGrade => {
    const { id, priceChange } = fuelGrade

    return (
      <div key={id} className={cx('fuel-grade-price')}>
        <div>{formatPriceChange(priceChange, '0')}</div>
        <div className={cx('buttons')}>
          <MinusIcon onClick={() => onDecrement(id)} data-testid="minus-icon" />
          <PlusIcon onClick={() => onIncrement(id)} data-testid="plus-icon" />
        </div>
      </div>
    )
  })
})

FuelGradePrices.propTypes = {
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
}

export { FuelGradePrices }
