import { useState, useEffect } from 'react'
import i18n from 'i18next'
import { Locales } from 'utils/constants'

export const requireContext = async locale => {
  const translationImports = require.context(
    './translations',
    false,
    /.*/,
    'lazy'
  )

  if (locale.substring(0, 2) === Locales.EN) {
    const _region = translationImports.keys().filter(k => k === `./${locale}`)
    const _en = translationImports.keys().filter(k => k === `./en`)

    const { translations } = await translationImports(_region[0])
    const { translations: en } = await translationImports(_en[0])

    /**
     * we have disabled lazy loading because it was causing issues with loading the translation files in time,
     * we will revisit this issue when we have more locales or if it affects the performance of the app.
     */

    //i18n.addResourceBundle(locale, 'translations', translations)
    //i18n.addResourceBundle('en', 'translations', en)

    return
  }

  const key = translationImports.keys().filter(k => k === `./${locale}`)
  const { translations } = await translationImports(key[0])

  // i18n.addResourceBundle(locale, 'translations', translations)
}

export const useTranslations = locale => {
  const [module, setModule] = useState(null)

  useEffect(() => {
    let cancel = false
    const promise = (async () => {
      const translationImports = require.context(
        './translations',
        false,
        /.*/,
        'lazy'
      )
      const key = translationImports.keys().filter(k => k === `./${locale}`)

      const module = await translationImports(key[0])

      if (!cancel) {
        setModule(() => module)
      }
    })()
    promise.catch(err => console.error(err))
    return () => (cancel = true)
  }, [locale])

  return module
}
