const typePolicy = {
  fields: {
    polePrices: {
      keyArgs: false,
      merge(existing, incoming) {
        return incoming
      },
    },
  },
}
export { typePolicy }
