import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

export const FUEL_GRADES_QUERY = gql`
  query fuelGrades($stationIds: [ID!]!) {
    fuelGrades(stationIds: $stationIds) {
      id
      shortTitle
      title
      position
    }
  }
`

const useFuelGrades = (stationIds, stationData = null, isEdgeDataPro = false) => {
  const { loading, data, error } = useQuery(FUEL_GRADES_QUERY, {
    variables: {
      stationIds,
    },
    skip: !stationIds?.length || !!stationData,
  })

  if (stationData) {
    return {
      fuelGradesLoading: false,
      fuelGrades: stationData.fuelGrades.map(fg => fg.fuelGrade),
    }
  }

  if (isEdgeDataPro) {
    const edgeDataProFuelGrades = {
      fuelGrades: [
        { id: "4", title: "Diesel Super" },
        { id: "3", title: "Unleaded Super" },
        { id: "2", title: "Diesel" },
        { id: "1", title: "Unleaded" }
      ]
    };
    return {
      fuelGradesLoading: false,
      fuelGrades: edgeDataProFuelGrades.fuelGrades,
    };
  }

  return {
    fuelGradesLoading: loading,
    fuelGrades: data?.fuelGrades,
    fuelGradesError: error,
  }
}

export { useFuelGrades }
