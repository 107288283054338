import { useState, useEffect } from 'react'

const humanizeDate = date => {
  if (!date) {
    return ''
  }

  date = new Date(date)
  const now = new Date(Date.now())

  // TODO: i18n
  if (date - now > -15_000) {
    return 'Just now'
  } else if (date - now > -60_000) {
    return '1m ago'
  } else if (date - now > -300_000) {
    return '5m ago'
  } else if (date - now > -900_000) {
    return '15m ago'
  } else if (date - now > -1800_000) {
    return '30m ago'
  } else if (date - now > -3600_000) {
    return '1h ago'
  } else if (date - now > 24 * -3600_000) {
    return `${Math.ceil((now - date) / 3600_000)}h ago`
  } else {
    return `${Math.ceil((now - date) / (24 * 3600_000))}d ago`
  }
}

const HumanizedDate = ({ date }) => {
  const [humanizedDate, setHumanizedDate] = useState(null)

  useEffect(() => {
    setHumanizedDate(humanizeDate(date))
  }, [date])

  useEffect(() => {
    const interval = setInterval(
      () => setHumanizedDate(humanizeDate(date)),
      5000
    )
    return () => clearInterval(interval)
  })

  return humanizedDate
}

export { HumanizedDate, humanizeDate }
