import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import Nav from 'react-bootstrap/Nav'
import styles from './TabNavs.module.scss'
import {
  PricingTypes,
  PerformanceTypes,
  PerformanceProfitTypes,
} from 'utils/constants'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { TabNavMetrics } from './components/tab-nav-metrics'

const cx = classNames.bind(styles)

const { GROSS_MARGIN, NET_MARGIN, RETAIL_VOLUME } = PerformanceTypes
const { GROSS_PROFIT, NET_PROFIT, BUSINESS_PROFIT } = PerformanceProfitTypes

const permissionedTabs = [
  GROSS_PROFIT,
  NET_PROFIT,
  BUSINESS_PROFIT,
  GROSS_MARGIN,
  NET_MARGIN,
  RETAIL_VOLUME,
]

const permissionType = (
  { readProfit, readMargin, readVolume },
  performanceType
) => {
  switch (performanceType) {
    case GROSS_MARGIN:
    case NET_MARGIN:
      return readMargin
    case GROSS_PROFIT:
    case NET_PROFIT:
    case BUSINESS_PROFIT:
      return readProfit
    case RETAIL_VOLUME:
      return readVolume
    default:
      throw Error(`unknown performanceType ${performanceType}`)
  }
}

const checkPermissionsForTabs = (metricPermissions, performanceType) => {
  if (permissionedTabs.includes(performanceType)) {
    return permissionType(metricPermissions, performanceType)
  }
  return true
}

const TabNavs = ({
  selectedPricing,
  activeTab,
  setActiveTab,
  tabNavData,
  metricPermissions,
  isOverlayMode,
}) => {
  return (
    <Nav className="flex-row" activeKey={0}>
      {/*<Nav.Item className={cx('nav-station-overview')}>
        <StationOverview />
      </Nav.Item>*/}
      {tabNavData.map(
        (
          {
            performanceType,
            performanceTitle,
            metricType,
            trend,
            value,
            comparativeValue,
          },
          i
        ) => {
          const permittedToView = checkPermissionsForTabs(
            metricPermissions,
            performanceType
          )
          const currentTabActive = activeTab === performanceType
          const disabledForCash =
            selectedPricing === PricingTypes.CASH &&
            performanceType === NET_MARGIN

          const setTabActiveState = () => {
            amplitudeEvent(
              UserEvents.StationPerformanceTab.CLICKED(
                isOverlayMode,
                performanceType
              )
            )
            !disabledForCash && permittedToView && setActiveTab(performanceType)
          }

          if (
            selectedPricing === PricingTypes.CASH &&
            activeTab === NET_MARGIN
          ) {
            setActiveTab(GROSS_MARGIN)
          }

          const firstTab = i === 0
          const lastTab = i === tabNavData?.length - 1

          const tabNavClass = () => {
            const activeTabNavClass = firstTab
              ? 'nav-tab-active-first-tab'
              : 'nav-tab-active'
            const tabNavClass = firstTab ? 'nav-tab-first-tab' : 'nav-tab'

            return currentTabActive ? activeTabNavClass : tabNavClass
          }

          return (
            <Nav.Item
              key={i}
              className={cx(tabNavClass())}
              onClick={() => setTabActiveState()}
              data-testid={
                currentTabActive
                  ? `${performanceType}-tab-active`
                  : `${performanceType}-tab`
              }
            >
              <TabNavMetrics
                permittedToView={permittedToView}
                disabledForCash={disabledForCash}
                performanceTitle={performanceTitle}
                performanceType={performanceType}
                metricType={metricType}
                trend={trend}
                value={value}
                comparativeValue={comparativeValue}
              />
              <div className={cx('nav-metrics-inner')}>
                {currentTabActive && (
                  <div className={cx('external-corner-container')}>
                    <div className={cx('external-corner-left')}>
                      {!firstTab && (
                        <svg className={cx('svg-left')}>
                          <rect className={cx('svg-rect-left')}></rect>
                          <circle className={cx('svg-circle-left')}></circle>
                        </svg>
                      )}
                    </div>
                    {!lastTab && (
                      <div className={cx('external-corner-right')}>
                        <svg className={cx('svg-right')}>
                          <rect className={cx('svg-rect-right')}></rect>
                          <circle className={cx('svg-circle-right')}></circle>
                        </svg>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Nav.Item>
          )
        }
      )}
    </Nav>
  )
}

TabNavs.propTypes = {
  selectedPricing: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  tabNavData: PropTypes.array.isRequired,
  metricPermissions: PropTypes.shape({
    readMargin: PropTypes.bool,
    readProfit: PropTypes.bool,
    readVolume: PropTypes.bool,
  }).isRequired,
  isOverlayMode: PropTypes.bool,
}

TabNavs.defaultProps = { isOverlayMode: false }

export { TabNavs }
