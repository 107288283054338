import { useQuery, gql } from '@apollo/client'
import { USER_PERMISSION_ATTRIBUTES } from 'config/apollo/fragments'

const PermissionTypes = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
}
const { READ } = PermissionTypes

export const USE_PERMISSIONS_QUERY = gql`
  query usePermissionQuery {
    me {
      ...UserPermissionAttributes
    }
  }
  ${USER_PERMISSION_ATTRIBUTES}
`

function usePermissions() {
  const { error, data } = useQuery(USE_PERMISSIONS_QUERY, {
    fetchPolicy: 'cache-only',
  })

  if (error || !data) {
    throw Error('user permissions not found')
  }

  const {
    me: { permissions },
  } = data

  function hasPermission(permissionName, permissionTypes = [READ]) {
    const permissionsForName = permissions.find(
      ({ title }) => title === permissionName
    )
    if (!permissionsForName) {
      return false
    }
    return permissionTypes.every(
      permissionType => permissionsForName[permissionType]
    )
  }

  return { hasPermission }
}

export { usePermissions, PermissionTypes }
