import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { refreshPage } from 'utils/helpers'
import { NotFoundMessage } from 'components/common/not-found-error'
import { Copyright } from 'components/main/components/copyright'
import * as Sentry from '@sentry/react'
import classNames from 'classnames/bind'
import styles from './AppErrorBoundary.module.scss'

const cx = classNames.bind(styles)

class AppErrorBoundary extends Component {
  state = {
    error: false,
    notFoundError: false,
  }

  static getDerivedStateFromError(error) {
    return error.message === NotFoundMessage
      ? { notFoundError: true }
      : { error: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      /* istanbul ignore next */
      if (this.state.notFoundError) {
        this.setState({ notFoundError: false })
      }
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  renderError(error, notFoundError) {
    const options = error
      ? {
          headingText: <Trans i18nKey="widgets.errorBoundary.errorHeading" />,
          buttonText: <Trans i18nKey="widgets.errorBoundary.errorButton" />,
          handleButtonClick: refreshPage,
          backgroundText: (
            <Trans i18nKey="widgets.errorBoundary.errorBackgroundText" />
          ),
        }
      : {
          headingText: (
            <Trans i18nKey="widgets.errorBoundary.notFoundHeading" />
          ),
          buttonText: <Trans i18nKey="widgets.errorBoundary.notFoundButton" />,
          handleButtonClick: () => this.props.history.push('/'),
          backgroundText: (
            <Trans i18nKey="widgets.errorBoundary.notFoundBackgroundText" />
          ),
        }

    const {
      headingText,
      handleButtonClick,
      buttonText,
      backgroundText,
    } = options
    return (
      <div className={cx('background')}>
        <div className={cx('content')}>
          <h1>{headingText}</h1>
          <h3>
            <Trans i18nKey="widgets.errorBoundary.subtitle" />
          </h3>
          <Button
            data-testid="button"
            variant="light"
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </div>
        <div className={cx('background-text', { 'not-found': notFoundError })}>
          {backgroundText}
        </div>
        <Copyright className={cx('copyright')} />
      </div>
    )
  }

  render() {
    const { error, notFoundError } = this.state
    if (error || notFoundError) {
      return this.renderError(error, notFoundError)
    }

    return this.props.children
  }
}

AppErrorBoundary.propTypes = {
  history: PropTypes.shape().isRequired,
}

const AppErrorBoundaryWithRouter = withRouter(AppErrorBoundary)

export { AppErrorBoundaryWithRouter }
