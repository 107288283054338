import React, { useRef, useEffect, useState, Fragment } from 'react'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames/bind'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'
import { Permissioned } from 'components/common/permissioned'
import { Placeholder } from 'components/common/placeholder'
import { useTranslation } from 'react-i18next'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { upperFirstOnly } from 'utils/format'
import { calculatePeriod } from '../data-table'
import styles from './Chart.module.scss'
import { ReactComponent as MetricUpArrow } from 'images/performance-report/metric-up.svg'
import { ReactComponent as MetricDownArrow } from 'images/performance-report/metric-down.svg'
import ChartWrapper from './ChartWrapper'

const cx = classNames.bind(styles)

const Chart = ({
  data,
  metric,
  onMouseOver,
  onMouseOut,
  previousLine,
  area,
  metrics,
  period1,
}) => {
  const locale = i18n.language
  const { t } = useTranslation(['translations'])
  const { formatMetricValue } = useFormatMetricValue()
  const [width, setWidth] = useState(1000)
  const [tooltipTarget, setTooltipTarget] = useState(null)
  const [tooltipData, setTooltipData] = useState(null)
  const [tooltipsActive, setTooltipsActive] = useState(null)

  const container = useRef()

  const metricsForLineGraph =
    area || (previousLine ? ['previousValue', 'value'] : ['value'])

  useEffect(() => {
    const onResize = () => {
      setWidth(container.current?.getBoundingClientRect().width)
    }
    window.addEventListener('resize', onResize, false)
    return () => window.removeEventListener('resize', onResize, false)
  }, [])

  const resizeSVGRef = ref => {
    setWidth(ref?.getBoundingClientRect().width)
    container.current = ref
  }

  const tooltipDataForSelectedValue = tooltipData?.filter(td => {
    return td.metric === 'value'
  })

  const tooltipDataForPreviousValue = tooltipData?.filter(td => {
    return td.metric === 'previousValue'
  })

  const chartData = {
    data,
    metric,
    previousLine,
    area,
    metrics,
    period1,
    metricsForLineGraph,
    locale,
    t,
    tooltipsActive,
    setTooltipsActive,
    tooltipData,
    setTooltipTarget,
    setTooltipData,
    formatMetricValue,
    onMouseOver,
    onMouseOut,
  }

  const overlay = (
    <Tooltip className={cx('tooltip')}>
      <div className={cx('tooltip-content')}>
        <div className={cx('tooltip-date')}>
          {moment.utc(tooltipData?.[0]?.date).format('dddd Do MMMM')}
        </div>

        <table className={cx('tooltip-periods')}>
          <thead>
            <tr>
              {!!area &&
                area?.map(m => (
                  <th key={m}>
                    {upperFirstOnly(t(`widgets.performanceReport.${m}`))}
                  </th>
                ))}

              {!area &&
                tooltipDataForSelectedValue?.map((td, i) => {
                  let metricChange = 0
                  if (
                    previousLine &&
                    tooltipData &&
                    tooltipDataForSelectedValue[i] &&
                    tooltipDataForPreviousValue[i]
                  ) {
                    metricChange =
                      (tooltipDataForSelectedValue[i].value /
                        tooltipDataForPreviousValue[i].value -
                        1) *
                      100
                  }
                  return (
                    <Fragment key={i}>
                      <th>
                        {upperFirstOnly(
                          t('widgets.performanceReport.thisPeriod')
                        )}
                        {previousLine && metricChange > 0 && (
                          <>
                            <MetricUpArrow />
                            <span className={cx('metric-change-up')}>
                              {metricChange.toFixed(0)}%
                            </span>
                          </>
                        )}
                        {previousLine && metricChange < 0 && (
                          <>
                            <MetricDownArrow />
                            <span className={cx('metric-change-down')}>
                              {(-metricChange).toFixed(0)}%
                            </span>
                          </>
                        )}
                      </th>
                      {previousLine && (
                        <th>
                          {upperFirstOnly(
                            t('widgets.performanceReport.lastPeriod')
                          )}
                        </th>
                      )}
                    </Fragment>
                  )
                })}
            </tr>
          </thead>

          <tbody>
            <tr>
              {!!area &&
                tooltipData?.map(({ metricToGraph, value }) => (
                  <td key={metricToGraph} className={cx('line', metricToGraph)}>
                    {formatMetricValue(metric, value)}
                  </td>
                ))}

              {!area &&
                tooltipDataForSelectedValue?.map((td, i) => {
                  let metricChange = 0
                  if (
                    previousLine &&
                    tooltipData &&
                    tooltipDataForSelectedValue[i] &&
                    tooltipDataForPreviousValue[i]
                  ) {
                    metricChange =
                      (tooltipDataForSelectedValue[i].value /
                        tooltipDataForPreviousValue[i].value -
                        1) *
                      100
                  }
                  return (
                    <Fragment key={i}>
                      <td
                        className={cx('line', td.metricToGraph, {
                          'metric-change-down': metricChange < 0,
                        })}
                      >
                        {formatMetricValue(
                          metric,
                          tooltipDataForSelectedValue?.[i]?.value
                        )}
                      </td>
                      {previousLine && (
                        <td className={cx('previous-line', td.metricToGraph)}>
                          {formatMetricValue(
                            metric,
                            tooltipDataForPreviousValue?.[i]?.value
                          )}
                        </td>
                      )}
                    </Fragment>
                  )
                })}
            </tr>
          </tbody>
        </table>
      </div>
    </Tooltip>
  )

  return (
    <>
      <div ref={resizeSVGRef} className={cx('container')}>
        <ChartWrapper width={width} chartData={chartData} />

        <Overlay target={tooltipTarget} show={!!tooltipTarget} placement="top">
          {overlay}
        </Overlay>
      </div>
      <div className={cx('legend')} data-testid="legend">
        <Permissioned
          permission={!metrics.includes('noPermission')}
          fallback={
            <Placeholder
              fixedWidth={100}
              dataTestID={'chart-legend-placeholder'}
            />
          }
        >
          {!!area &&
            area?.map(metric => {
              return (
                <div key={metric} className={cx('legend-line', metric)}>
                  {upperFirstOnly(t(`widgets.performanceReport.${metric}`))}
                </div>
              )
            })}
          {!area &&
            metrics?.map((m, i) => (
              <Fragment key={m}>
                <div key={m} className={cx('legend-line', m)}>
                  {upperFirstOnly(t(`widgets.performanceReport.${metrics[i]}`))}
                </div>
                {previousLine && (
                  <div
                    key={`previous-${m}`}
                    className={cx('legend-line', 'previous-line', m)}
                  >
                    {upperFirstOnly(
                      t(`widgets.performanceReport.${metrics[i]}`)
                    )}{' '}
                    {t('widgets.performanceReport.previous')}{' '}
                    {calculatePeriod(t, period1)}
                  </div>
                )}
              </Fragment>
            ))}
        </Permissioned>
      </div>
    </>
  )
}

Chart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        previousValue: PropTypes.number,
      })
    )
  ),
  metric: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  previousLine: PropTypes.bool,
  lineClass: PropTypes.string,
  area: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  metrics: PropTypes.arrayOf(PropTypes.string),
  period1: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
  period2: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
}

Chart.defaultProps = {
  data: null,
  metric: null,
  onMouseOver: null,
  onMouseOut: null,
  previousLine: false,
  lineClass: '',
  area: false,
}

export { Chart }
