import React from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { Info } from 'components/common/info'
import { EstimatedMargin } from './components/estimated-margin'
import { GrossMargin } from './components/gross-margin'
import styles from './MarginInfo.module.scss'
import { useQuery } from '@apollo/client'
import { ESTIMATED_MARGIN_QUERY } from '../EditPricingQueries'
import { Spinner } from 'react-bootstrap'
import { useErrorHandler } from 'react-error-boundary'

const cx = classNames.bind(styles)

const MarginInfo = () => {
  const { t } = useTranslation()
  const { loading, data, error } = useQuery(ESTIMATED_MARGIN_QUERY, {
    variables: {
      stationIds: [],
      fuelGradePriceChanges: [],
      stationPriceChanges: [],
    },
  })

  useErrorHandler(error)

  /* istanbul ignore next */
  if (error) {
    throw new Error(error)
  }

  return (
    <div className={cx('margin-info')} data-testid="margin-info">
      <div className={cx('todays-margin')}>
        <div className={cx('title')}>
          {t('widgets.stationsList.currentPortfolioMargin')}
        </div>
        <div className={cx('value')}>
          <GrossMargin />
        </div>
      </div>
      <div className={cx('estimated-margin')}>
        <div className={cx('title')}>
          <div>{t('widgets.stationsList.tomorrowMiddayEstimate')}</div>
          <div className={cx('info-tooltip')}>
            <Info
              tooltipText={t(
                'widgets.stationsList.tomorrowMiddayEstimateTooltip'
              )}
            />
          </div>
        </div>
        <div className={cx('value')}>
          {loading && (
            <div className={cx('spinner')}>
              <Spinner animation="grow" variant="primary" />
            </div>
          )}
          {data?.estimatedMargin && (
            <EstimatedMargin
              firstRenderEstimatedMargin={data.estimatedMargin.estimatedMargin}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export { MarginInfo }
