import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useApolloClient, gql } from '@apollo/client'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { useTranslation } from 'react-i18next'
import { isNullOrUndefined } from 'utils/helpers'
import { addTypenames } from 'utils/format'
import classNames from 'classnames/bind'
import { FilterDropdown } from './components/filter-dropdown'
import { OperatorDropdown } from './components/operator-dropdown'
import { ValueDropdown } from './components/value-dropdown'
import { ValueInput } from './components/value-input'
import { Buttons } from './components/buttons'
import styles from './FilterPopover.module.scss'

const cx = classNames.bind(styles)

const isValid = ({ filter, operator, value }, selectedFilters) => {
  if (
    isNullOrUndefined(filter) ||
    isNullOrUndefined(operator) ||
    (filter.requiresValue && isNullOrUndefined(value))
  ) {
    return false
  }
  // check the new filter doesn't match any existing filters
  return selectedFilters.every(
    selectedFilter =>
      selectedFilter.field !== filter.field ||
      selectedFilter.operator !== operator ||
      selectedFilter.value !== value
  )
}

const initialNewFilter = (existingFilter, filters) => {
  if (!isNullOrUndefined(existingFilter)) {
    const { operator, value, name } = existingFilter
    const filter = filters.find(filter => filter.name === name)
    return {
      filter,
      operator,
      value,
    }
  }
  return { filter: null, operator: null, value: null }
}

const parseNewFilters = (
  updateFilter,
  currentSelectedFilters,
  existingFilter,
  newFilter
) => {
  let newFilters
  if (updateFilter) {
    const { name, operator, value } = existingFilter
    newFilters = currentSelectedFilters.map(currentSelectedFilter => {
      if (
        name === currentSelectedFilter.name &&
        operator === currentSelectedFilter.operator &&
        value === currentSelectedFilter.value
      ) {
        return newFilter
      }
      return currentSelectedFilter
    })
  } else {
    newFilters = [...currentSelectedFilters, newFilter]
  }

  return addTypenames(newFilters, 'StationsListFilter')
}

/**
 * Can be used to add a new filter or edit an existing one (based off if the existingFilter prop is populated).
 */
const FilterPopover = ({
  filters,
  selectedFilters,
  existingFilter,
  target,
  show,
  setShow,
  placement,
}) => {
  const client = useApolloClient()
  const [newFilter, setNewFilter] = useState(() =>
    initialNewFilter(existingFilter, filters)
  )
  const { filter, operator, value } = newFilter
  const [dropdowns, setDropdowns] = useState({
    filterOpen: false,
    operatorOpen: false,
    valueOpen: false,
  })
  const { t } = useTranslation()
  const { filterOpen, operatorOpen, valueOpen } = dropdowns

  /* istanbul ignore next */
  const handleCancel = (reset = true) => {
    setShow(false)

    if (reset) {
      setTimeout(() => {
        setNewFilter(initialNewFilter(existingFilter, filters))
      }, 200)
    }
  }

  const handleFilterDropdownClick = open => {
    setDropdowns({
      filterOpen: open,
      operatorOpen: false,
      valueOpen: false,
    })
  }

  const handleOperatorDropdownClick = open => {
    setDropdowns({
      filterOpen: false,
      operatorOpen: open,
      valueOpen: false,
    })
  }

  const handleValueDropdownClick = open => {
    setDropdowns({
      filterOpen: false,
      operatorOpen: false,
      valueOpen: open,
    })
  }

  const handleFilterChange = filter => {
    const operator = filter.operators[0]
    let value = null
    if (filter.values) {
      value = filter.values[0]
    }
    setNewFilter({ filter, operator, value })
  }

  const handleOperatorChange = operator => {
    setNewFilter(prev => ({ ...prev, operator }))
  }

  const handleValueChange = value => {
    setNewFilter(prev => ({ ...prev, value }))
  }

  const handleApply = () => {
    if (isValid(newFilter, selectedFilters)) {
      const currentSelectedFilters = [...selectedFilters]

      const newFilter = {
        name: filter.name,
        operator,
        value,
      }

      // either update the existing filter or add a new one
      const updateFilter = !isNullOrUndefined(existingFilter)
      const newFilters = parseNewFilters(
        updateFilter,
        currentSelectedFilters,
        existingFilter,
        newFilter
      )

      const data = {
        stationsList: {
          selectedFilters: newFilters,
        },
      }
      client.writeQuery({
        query: gql`
          {
            stationsList {
              selectedFilters
            }
          }
        `,
        data,
      })

      handleCancel(!updateFilter)
    }
  }

  const title = !isNullOrUndefined(existingFilter)
    ? t('widgets.stationsList.editFilter')
    : t('widgets.stationsList.filterStations')

  return (
    <Overlay
      target={target.current}
      show={show}
      placement={placement}
      onHide={handleCancel}
      rootClose
    >
      {props => (
        <Popover
          id="filter-popover"
          className={cx('filter-popover')}
          {...props}
        >
          <Popover.Content className={cx('content')}>
            <h3 className={cx('title')}>{title}</h3>
            <FilterDropdown
              open={filterOpen}
              filters={filters}
              selectedFilter={filter}
              onClick={handleFilterDropdownClick}
              onFilterSelect={handleFilterChange}
            />
            {filter?.operators && (
              <OperatorDropdown
                open={operatorOpen}
                newFilter={newFilter}
                onClick={handleOperatorDropdownClick}
                onOperatorSelect={handleOperatorChange}
              />
            )}
            {filter?.requiresValue && (
              <>
                {filter?.values ? (
                  <ValueDropdown
                    open={valueOpen}
                    newFilter={newFilter}
                    onClick={handleValueDropdownClick}
                    onValueSelect={handleValueChange}
                  />
                ) : (
                  <ValueInput
                    initialValue={newFilter.value}
                    onValueSet={handleValueChange}
                  />
                )}
              </>
            )}
            <Buttons
              handleApply={handleApply}
              closeModal={handleCancel}
              valid={isValid(newFilter, selectedFilters)}
            />
          </Popover.Content>
        </Popover>
      )}
    </Overlay>
  )
}

FilterPopover.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  existingFilter: PropTypes.shape(),
  target: PropTypes.shape().isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  placement: PropTypes.string.isRequired,
}

export { FilterPopover }
