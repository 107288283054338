import i18n from 'i18next'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { timestampVar } from 'config/apollo/competitor-pricing/variables'
import { COMPETITOR_POLE_PRICE_ATTRIBUTES } from 'config/apollo/fragments'
import { COMPETITOR_PRICING_QUERY } from 'components/station/components/competitor-pricing/CompetitorPricingQueries'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { useShowToast } from 'components/common/hooks/useShowToast'
import { Locales, PricingTypes } from 'utils/constants'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const { ALL, CASH, CREDIT } = PricingTypes

const CREATE_POLE_PRICES_MUTATION = gql`
  mutation createPolePricesMutation($polePrices: [PolePriceInput!]!) {
    createPolePrices(input: { polePrices: $polePrices }) {
      error
      polePrices {
        ...CompetitorPolePriceAttributes
      }
    }
  }
  ${COMPETITOR_POLE_PRICE_ATTRIBUTES}
`

const updatePolePrices = (prices, polePrice) => {
  // holds which pole prices to remove if in the cache (for same grade)
  const invalidPricingTypes =
    polePrice.pricingType === ALL
      ? [ALL, CASH, CREDIT]
      : [ALL, polePrice.pricingType]

  // filter out prices that are now invalid
  const validPrices = prices.filter(
    ({ pricingType, fuelGradeId }) =>
      !invalidPricingTypes.includes(pricingType) ||
      fuelGradeId !== polePrice.fuelGradeId
  )

  return [...validPrices, polePrice]
}

function useCreateCompetitorPrices(stationId, competitorId, onSuccess) {
  const timestamp = useReactiveVar(timestampVar)
  const { supportedPricingTypes } = usePricingTypes()
  const { showErrorToast } = useShowToast()
  const [createPricesMutation, result] = useMutation(
    CREATE_POLE_PRICES_MUTATION,
    {
      update(
        cache,
        {
          data: {
            createPolePrices: { error, polePrices },
          },
        }
      ) {
        if (error) {
          showErrorToast()
          return
        }

        const variables = {
          stationId,
          supportedPricingTypes,
          timestamp,
        }
        const {
          station,
          station: { competitors },
        } = cache.readQuery({
          query: COMPETITOR_PRICING_QUERY,
          variables,
        })

        // find the competitor to update
        const competitorIndex = competitors.findIndex(
          ({ id }) => competitorId === id
        )
        const competitor = competitors.find(({ id }) => competitorId === id)

        // update the competitor's prices
        let updatedPrices = competitor.polePrices
        for (const polePrice of polePrices) {
          updatedPrices = updatePolePrices(updatedPrices, polePrice)
        }
        const updatedCompetitor = { ...competitor, polePrices: updatedPrices }

        // update the station
        const updatedCompetitors = competitors.filter(
          ({ id }) => competitorId !== id
        )
        updatedCompetitors.splice(competitorIndex, 0, updatedCompetitor)

        cache.writeQuery({
          query: COMPETITOR_PRICING_QUERY,
          variables,
          data: {
            station: {
              ...station,
              competitors: updatedCompetitors,
            },
          },
        })
      },
      onCompleted: ({ createPolePrices: { error } }) => {
        if (!error) {
          amplitudeEvent(UserEvents.CompetitorPricing.UPDATED)
          onSuccess()
        }
      },
      onError: error => {
        Sentry.captureException(error)
        showErrorToast()
      },
    }
  )

  function createPrices(polePrices) {
    if (i18n.language === Locales.EN_US) {
      // convert price to cents and add fraction (9/10)
      const mappedPolePrices = polePrices.map(polePrice => ({
        ...polePrice,
        price: polePrice.price * 100 + 0.9,
      }))
      return createPricesMutation({
        variables: { polePrices: mappedPolePrices },
      })
    } else {
      return createPricesMutation({ variables: { polePrices } })
    }
  }

  return [createPrices, result]
}

export { useCreateCompetitorPrices, CREATE_POLE_PRICES_MUTATION }
