import { gql } from '@apollo/client'

const NOTIFICATIONS_QUERY = gql`
  query getNotifications($page: Int!, $pageSize: Int!) {
    notifications(page: $page, pageSize: $pageSize) {
      id
      type
      createdAt
      title
      companyId
      count
      notifications {
        id
        text
        stationId
        createdAt
      }
    }
  }
`

const NOTIFICATIONS_COUNT_QUERY = gql`
  query getNotificationsCount {
    notificationsCount {
      count
    }
  }
`

export { NOTIFICATIONS_QUERY, NOTIFICATIONS_COUNT_QUERY }
