import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useApolloClient } from '@apollo/client'
import { PricingTypes } from 'utils/constants'
import { useLaunchDarkly } from './useLaunchDarkly'
import { useAmplitude } from 'config/amplitude/useAmplitude'
import { useHotjar } from './useHotjar'
import { useHeap } from './useHeap'
import { useIntercomBoot } from './useIntercomBoot'
import { useChurnZero } from './useChurnZero'

const { ALL, CASH, CREDIT } = PricingTypes

const initialiseCache = (t, queryData, client) => {
  const {
    me: {
      company: { timeZone },
    },
  } = queryData
  const cashCreditPricing = t('cashCreditPricing', { ns: 'features' })
  const types = cashCreditPricing ? [ALL, CASH, CREDIT] : [ALL]
  const data = {
    cacheInitialised: true,
    companyTimeZone: timeZone,
    pricingTypes: {
      types,
    },
  }

  client.writeQuery({
    query: gql`
      {
        cacheInitialised
        companyTimeZone
        pricingTypes {
          types
        }
      }
    `,
    data,
  })
}

function useSetupApp(data) {
  const client = useApolloClient()
  const { ready, i18n } = useTranslation(null, {
    useSuspense: false,
  })
  const { ldInitialised } = useLaunchDarkly(data?.me)
  const { useHeapIdentify } = useHeap()

  useIntercomBoot(data?.me)
  useAmplitude(data?.me)
  useHotjar(data?.me)
  useHeapIdentify(data?.me)
  useChurnZero(data?.me)

  /**
   * Initialise the user's language using their locale. Then use this language to initialise
   * the Apollo Cache.
   */
  useEffect(() => {
    const locale = data?.me?.locale
    if (locale && locale !== i18n.language) {
      i18n.changeLanguage(locale).then(t => {
        initialiseCache(t, data, client)
      })
    }
  }, [client, data, i18n])

  /**
   * Don't show the main app until:
   * - translations loaded
   * - cache is initialised
   * - launch darkly initialised
   */
  const loading = !ready || !data?.cacheInitialised || !ldInitialised

  return { loading }
}

export { useSetupApp }
