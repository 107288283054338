import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import styles from './PendingChangeRequest.module.scss'

const cx = classNames.bind(styles)

const PendingChangeRequest = ({ pendingRequest }) => {
  const { formatPolePrice } = useFormatPolePrice()

  return (
    <div className={cx('pending-change-request')}>
      <div>{formatPolePrice(pendingRequest?.price)}</div>
      <div>{formatPolePrice(pendingRequest?.newPrice)}</div>
    </div>
  )
}

PendingChangeRequest.propTypes = {
  pendingRequest: PropTypes.shape(),
}

PendingChangeRequest.defaultProps = {
  pendingRequest: null,
}

export { PendingChangeRequest }
