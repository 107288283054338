import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'config/context/authContext'
import styles from './ForgotPassword.module.scss'
import { EmailRegex } from 'utils/constants'
import { AuthDialog } from '../components/auth-dialog'

const cx = classNames.bind(styles)

const ForgotPassword = () => {
  const { forgotPassword } = useAuth()
  const [email, setEmail] = useState('')
  const [isEmailFocused, setEmailFocused] = useState(false)
  const [isEmailInvalid, setEmailInvalid] = useState(null)
  const [hasSentLink, setSentLink] = useState(false)
  const { t } = useTranslation('auth')

  const validateEmail = email => {
    const valid = EmailRegex.test(email)
    setEmailInvalid(email.length === 0 ? null : !valid)
  }

  const handleChangeEmail = event => {
    const email = event.target.value.trim()
    // fix bug with focus/blur and webkit's handling of autofill
    setEmailFocused(true)
    validateEmail(email)
    setEmail(email)
  }

  const handleEmailFocus = () => setEmailFocused(true)
  const handleEmailBlur = () => setEmailFocused(false)

  const handleSubmit = async () => {
    setSentLink(false)
    if (!(isEmailInvalid ?? true)) {
      await forgotPassword(email)
      setSentLink(true)
    }
  }

  return (
    <AuthDialog
      title={t('forgotPassword')}
      onSubmit={handleSubmit}
      link="/login"
      linkText={t('login')}
      buttonText={t('sendPasswordResetEmail')}
      helpText={t('forgotPasswordHelpText')}
      footerText={hasSentLink ? t('weveSentALink') : null}
    >
      <div
        className={cx('input', {
          focused: isEmailFocused,
          invalid: !isEmailFocused && isEmailInvalid === true,
          valid: !isEmailFocused && isEmailInvalid === false,
        })}
      >
        <input
          type="email"
          placeholder={t('emailAddress')}
          name="email"
          data-testid="email"
          onChange={handleChangeEmail}
          onFocus={handleEmailFocus}
          onBlur={handleEmailBlur}
          value={email}
        />
      </div>
    </AuthDialog>
  )
}

export { ForgotPassword }
