import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { NOTIFICATIONS_COUNT_QUERY } from './components/notifications/NotificationsQueries'
import NotificationsIcon from 'images/header/notifications.svg'
import { Backdrop } from 'components/common/backdrop'
import classNames from 'classnames/bind'
import { Notifications } from './components/notifications'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import styles from './NotificationsToggle.module.scss'

const cx = classNames.bind(styles)

const REFETCH_COUNT_INTERVAL = 60_000

const parseNotificationsCount = data => {
  if (!data?.notificationsCount) {
    return ''
  }

  const {
    notificationsCount: { count },
  } = data

  if (count === 0) {
    return ''
  }

  return count > 99 ? '99+' : count
}

const NotificationsToggle = ({ stations, isSingleSite }) => {
  const [notificationCount, { data, refetch }] = useLazyQuery(
    NOTIFICATIONS_COUNT_QUERY
  )
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    notificationCount()
  }, [notificationCount])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
    }, REFETCH_COUNT_INTERVAL)
    return () => clearInterval(interval)
  }, [refetch])

  const handleClose = () => {
    setVisible(false)
  }

  const handleNotificationsClick = () => {
    amplitudeEvent(UserEvents.Notifications.OPENED)
    refetch()
    setVisible(true)
  }

  const count = parseNotificationsCount(data)
  return (
    <>
      <div
        className={cx('notifications')}
        onClick={handleNotificationsClick}
        data-testid="notifications"
      >
        <img src={NotificationsIcon} alt="notifications" />
        {data?.notificationsCount?.count > 0 && (
          <div className={cx('nubbin')} />
        )}
        <span className={cx('count')}>{count}</span>
      </div>
      <Notifications
        stations={stations}
        visible={visible}
        onClick={handleClose}
        count={count}
        isSingleSite={isSingleSite}
      />
      <Backdrop visible={visible} onClick={handleClose} zIndex={35} />
    </>
  )
}

NotificationsToggle.propTypes = {
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isSingleSite: PropTypes.bool.isRequired,
}

export { NotificationsToggle }
