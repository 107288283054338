import { ReactComponent as smallProfitIcon } from './icons/dollar-symbol.svg'

const translations = {
  petrol: 'Gas',
  cash: 'Cash',
  credit: 'Credit',
  currency: {
    title: 'USD',
    short: '$',
  },
  volume: {
    title: 'Cents Per Gallon',
    short: 'gal',
  },
  margin: {
    title: 'Cents Per Gallon',
    short: 'cpg',
  },
  distance: {
    title: 'miles',
    short: 'mi',
  },
  price: {
    short: 'CPG',
  },
  cost: {
    short: '$/gal',
  },
  favourite: 'Favorite',
  ordinals: {
    one: 'st',
    two: 'nd',
    few: 'rd',
    many: 'th',
    zero: 'th',
    other: 'th',
  },
  widgets: {
    companyPerformance: {
      grossMarginTooltip:
        'Gross margin = Gross profit / total volume. Cost of fuel sold is derived via FIFO blended methodology.',
      grossProfitTooltip:
        'Gross profit = total fuel sales - total cost of fuel sold. Cost of fuel sold is derived via a FIFO blended methodology.',
      netMarginTooltip:
        'Net margin = Net profit / total volume. Cost of fuel sold is derived via a FIFO blended methodology.',
      totalVolumeTooltip: 'Total volume of fuel dispensed.',
    },
    competitorPricing: {
      manualInputInvalidTooltip:
        'Price must include two digits after the decimal point and must not be greater or smaller than 50% of the current price.',
    },
    performanceReport: {
      fuelCardVolume: 'card volume',
      showFuelCards: 'show fleet cards',
      yAxisLabels: {
        volume: 'Fuel volume / gal',
        margin: 'Margin / cpg',
        profit: 'Profit / $',
        number: 'Transactions',
      },
    },
    settings: {
      customerPortal: 'united-states',
    },
    stationStatus: {
      costPriceAtMessage: 'Last cost price effective from {{date}}',
    },
    analytics: {
      yAxisLabels: {
        total_volume: 'Total volume / gal',
        retail_volume: 'Retail volume / gal',
        bunkered_volume: 'Bunkered volume / gal',
        consumer_volume: 'Consumer volume / gal',
        fuel_card_volume: 'Fuel card volume / gal',
        gross_margin: 'Gross margin / cpg',
        net_margin: 'Net margin / cpg',
        gross_sales: 'Gross sales / $',
        gross_profit: 'Gross profit / $',
        net_profit: 'Net profit / $',
        business_profit: 'Business profit / $',
        bunkering_commissions: 'Bunkering commissions / $',
        replacement_cost: 'Replacement cost / cpg',
        total_fees: 'Total fees / $',
        blended_cost_sold: 'Blended cost sold / $',
        retail_transactions: 'Transactions',
        retail_fill_up: 'Fill up / gal',
        pole_price: 'Pole price / $/gal'
      },
    },
  },
}

export { translations, smallProfitIcon }
