import React from 'react'
import PropTypes from 'prop-types'
import { AnalyticsReport } from 'components/common/analytics-report'

const Analytics = ({ mainData }) => {
  return <AnalyticsReport mainData={mainData}/>
}

Analytics.propTypes = {
  mainData: PropTypes.shape(),
}

export { Analytics }
