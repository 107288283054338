import React from 'react'
import PropTypes from 'prop-types'
import { PerformanceReport } from 'components/common/performance-report'

const Performance = ({ mainData }) => {
  const hasBunkered = mainData.me.stations.items.some(
    ({ hasBunkered }) => hasBunkered
  )

  return <PerformanceReport hasBunkered={hasBunkered} />
}

Performance.propTypes = {
  mainData: PropTypes.shape().isRequired,
}

export { Performance }
