import React from 'react'
import PropTypes from 'prop-types'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly, yAxisLabel } from 'utils/format'
import classNames from 'classnames/bind'
import styles from './CompetitorAnalysisChart.module.scss'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { timePeriodFormat, nextRoundedNumber, maxYaxisValue } from 'utils/helpers'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
    ResponsiveContainer,
    Label,
    Bar,
    ComposedChart,
  } from 'recharts'

const cx = classNames.bind(styles)

const getLineConfig = (dataKey, color) => ({
  type: 'monotone',
  dataKey,
  stroke: color,
  strokeWidth: 2,
  dot: { stroke: color, strokeWidth: 1, fill: color },
})

const CompetitorAnalysisChart = ({ data, metricType, metricName, timeResolution }) => {
  const { t } = useTranslation(['translations'])
  const { formatMetricValue } = useFormatMetricValue()

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      const timePeriodLabel = timeResolution == 'Weekly' ? 'Week of ' + label : label

      return (
       <div className={cx('tooltip')}>
          <p className={cx('tooltip-date')}>{timePeriodLabel}</p>
          <table className={cx('tooltip-periods')}>
            <thead>
              <tr>
                {Array.from({ length: payload.length }).map((_, index) => (
                <th key={index}>{upperFirstOnly(t('widgets.performanceReport.thisPeriod'))}</th>
              ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {payload.map(({ value, color }) => 
                  <td key={color} style={{ color }}>{formatMetricValue(metricType, value, true, true)}</td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  
    return null
  }

  const CustomYAxisTick = ({ x, y, payload }) => {    
    return (
      <g transform={`translate(${x},${y})`}>
        <text textAnchor="end" fill="#b2bfca" >
          {formatMetricValue(metricType, payload.value, true, true)}
        </text>
      </g>
    )
  }

  const formatData = () => {
    if (!data?.competitorAnalysis || !data.competitorAnalysis.dates) {
      return null
    }
  
    const { competitorAnalysis } = data
    const dates = competitorAnalysis.dates

    return dates.map((date, index) => {
      const formatDataPoint = (key) => {
        const value = parseFloat(competitorAnalysis[key][index])
        const formattedValue = isNaN(value) ? null : value
  
        return {
          [key]: formattedValue,
        }
      };
  
      const isWeekend = (date) => {
        const day = new Date(date).getDay();
        return day === 0 || day === 6;
      };
  
      return {
        name: timePeriodFormat(date, dates, timeResolution),
        ...formatDataPoint('yourAveragePolePrice'),
        ...formatDataPoint('averageCompetitorPolePrice'),
        ...formatDataPoint('minCompetitorPolePrice'),
        ...formatDataPoint('maxCompetitorPolePrice'),
        ...(isWeekend(date) && { weekend: nextRoundedNumber(maxDataPoint) }),
      }
    })
  }

  const legendFormatter = (value) => {
    let legend = LineData[value] ? LineData[value].legend : value
    
    return (
      <span style={{ color: '#b2bfca' }}>
        {legend}
      </span>
    )
  }

  const LineData = {
    yourAveragePolePrice: {
      legend: 'Your average pole price',
      color: '#39c258'
    },
    averageCompetitorPolePrice: {
      legend: 'Average competitor pole price',
      color: '#7c44d2'
    },
    minCompetitorPolePrice: {
      legend: 'Min competitor pole price',
      color: '#4fc4d1'
    },
    maxCompetitorPolePrice: {
      legend: 'Max competitor pole price',
      color: '#b2bfca'
    }
  }

  const maxDataPoint = maxYaxisValue(data?.competitorAnalysis, LineData)
  const maxAxisYValue = timeResolution === 'Daily' ? nextRoundedNumber(maxDataPoint) : 'auto'
  const filteredLineData = Object.keys(LineData).map(key => ({
    value: key,
    color: LineData[key].color
  }))

  return (
    <div className={cx('container')}>
      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart 
          className={cx('chart')}
          data={formatData()}
          margin={{ left: 48 }}
        >
          <CartesianGrid strokeDasharray="3 3" className={cx('grid')} />
          <XAxis
            dataKey="name"
            tick={{ fill: "#b2bfca" }}
            stroke="#eee"
            fontSize={13}
          />
          <YAxis tick={<CustomYAxisTick />} stroke="none" domain={[maxAxisYValue]}>
            <Label
              value={t(`widgets.analytics.yAxisLabels.${yAxisLabel(metricName)}`)}
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: 'middle', fontSize: '15px' }}
              dx={-47}
            />
          </YAxis>
          <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip metricType={metricType}/>} />
          { timeResolution === 'Daily' && 
            <Bar dataKey="weekend" stroke="none" fill="#f6f8fa" barSize={600} legendType="none" />}
          <Line {...getLineConfig('yourAveragePolePrice', LineData.yourAveragePolePrice.color)} />
          <Line {...getLineConfig('averageCompetitorPolePrice', LineData.averageCompetitorPolePrice.color)} />
          <Line {...getLineConfig('minCompetitorPolePrice', LineData.minCompetitorPolePrice.color)} />
          <Line {...getLineConfig('maxCompetitorPolePrice', LineData.maxCompetitorPolePrice.color)} />
          <Legend formatter={legendFormatter} payload={filteredLineData} />          
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

const FallbackComponent = () => <WidgetFallback />

const ChartWithErrorBoundary = withEdgeErrorBoundary(
CompetitorAnalysisChart,
  FallbackComponent
)

CompetitorAnalysisChart.propTypes = {
  metricType: PropTypes.string,
  metricName: PropTypes.string,
  timeResolution: PropTypes.string,
  data: PropTypes.shape({
    competitorAnalysis: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      yourAveragePolePrice: PropTypes.arrayOf(PropTypes.string),
      averageCompetitorPolePrice: PropTypes.arrayOf(PropTypes.string),
      minCompetitorPolePrice: PropTypes.arrayOf(PropTypes.string),
      maxCompetitorPolePrice: PropTypes.arrayOf(PropTypes.string),
    })
  })
}

export { ChartWithErrorBoundary as CompetitorAnalysisChart }
