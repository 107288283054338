import React, { useEffect, useState } from 'react'
import { RefreshModal } from './components/refresh-modal'

const VersionManagement = ({ children }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    let version

    const checkVersion = () => {
      const promise = (async () => {
        const response = await fetch('/version.txt', { cache: 'no-store' })

        /* istanbul ignore next */
        if (!response.ok) {
          return
        }

        let newVersion = await response.text()
        newVersion = newVersion.trim()

        if (typeof version === 'undefined') {
          version = newVersion
        } else if (version !== newVersion) {
          setShow(true)
          version = newVersion
        }
      })()
      /* istanbul ignore next */
      promise.catch(err => console.error(err))
    }

    checkVersion()
    const interval = setInterval(checkVersion, 5000)
    return () => clearInterval(interval)
  }, [])

  /* istanbul ignore next */
  const handleOnDismiss = () => {
    setShow(false)
  }

  return (
    <>
      {children}
      <RefreshModal show={show} onDismiss={handleOnDismiss} />
    </>
  )
}

export { VersionManagement }
