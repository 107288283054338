import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { Input } from 'components/common/input'
import { ReactComponent as EditCancelIcon } from 'images/common/cancel.svg'
import { MarginRegex } from 'utils/constants'
import { useUpdateTargetMargin } from '../../hooks/useUpdateTargetMargin'
import { Buttons } from './components/buttons'
import styles from './EditTargetMargin.module.scss'

const cx = classNames.bind(styles)

const EditTargetMargin = ({
  stationId,
  setTargetMarginSelected,
  targetMargin,
  isOverlayMode,
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState(targetMargin)
  const [disabledSave, setDisabledSave] = useState(true)
  const [
    updateTargetMargin,
    { loading: updateLoading },
    deleteTargetMargin,
    { loading: deleteLoading },
  ] = useUpdateTargetMargin(
    /* istanbul ignore next */ () => {
      setTargetMarginSelected(false)
    }
  )

  const handleInputValueChange = targetValue => {
    setInputValue(targetValue)
    if (
      targetValue === '' ||
      targetValue === '.' ||
      !MarginRegex.test(targetValue)
    ) {
      setDisabledSave(true)
    }

    if (MarginRegex.test(targetValue)) {
      setDisabledSave(false)
    }
  }

  const handleCancel = () => {
    setTargetMarginSelected(false)
  }

  const handleSave = async () => {
    updateTargetMargin({
      variables: { stationId: stationId, target: parseFloat(inputValue) },
    })
  }

  const handleDelete = async () => {
    deleteTargetMargin({
      variables: { stationId: stationId },
    })
  }

  return (
    <div className={cx('edit-target-margin-container')}>
      {!updateLoading && !deleteLoading && (
        <div className={cx('edit-cancel-container')} onClick={handleCancel}>
          <EditCancelIcon className={cx('edit-cancel')} />{' '}
        </div>
      )}
      <div className={cx('edit-target-margin-title')}>
        {t('widgets.stationPerformance.targetMargin')}
      </div>
      <div className={cx('edit-target-margin-value')}>
        <Input
          value={inputValue?.toString()}
          onValueChange={handleInputValueChange}
        />
      </div>
      <Buttons
        disabledSave={disabledSave}
        updateLoading={updateLoading}
        deleteLoading={deleteLoading}
        targetMargin={targetMargin}
        handleSave={handleSave}
        handleDelete={handleDelete}
        isOverlayMode={isOverlayMode}
      />
    </div>
  )
}

EditTargetMargin.propTypes = {
  stationId: PropTypes.string.isRequired,
  targetMargin: PropTypes.number,
  setTargetMarginSelected: PropTypes.func.isRequired,
  isOverlayMode: PropTypes.bool,
}

EditTargetMargin.defaultProps = { isOverlayMode: false }

export { EditTargetMargin }
