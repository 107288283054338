import React, { useReducer } from 'react'
import { GroupedPricingActions } from '../utils/constants'

const GroupedPricingStateContext = React.createContext()
const GroupedPricingDispatchContext = React.createContext()

const defaultState = {
  openGroups: [],
  loadingGroups: [],
  groupItems: {},
}

function groupedPricingReducer(state, action) {
  switch (action.type) {
    case GroupedPricingActions.RESET: {
      return { ...defaultState }
    }
    case GroupedPricingActions.SET_OPEN_GROUP: {
      const { isOpen, group } = action.payload

      if (isOpen) {
        return { ...state, openGroups: [...state.openGroups, group] }
      }

      return {
        ...state,
        openGroups: state.openGroups.filter(openGroup => openGroup !== group),
      }
    }
    case GroupedPricingActions.SET_LOADING_GROUP: {
      const { isLoading, group } = action.payload

      if (isLoading) {
        return {
          ...state,
          loadingGroups: [...state.loadingGroups, group],
        }
      }

      return {
        ...state,
        loadingGroups: state.loadingGroups.filter(
          loadingGroup => loadingGroup !== group
        ),
      }
    }
    case GroupedPricingActions.SET_GROUP_DATA: {
      const { group, data } = action.payload
      return {
        ...state,
        groupItems: {
          ...state.groupItems,
          [group]: data,
        },
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

/**
 * Context for sharing data between the main grouped table and the edit pricing panel.
 */
const GroupedPricingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(groupedPricingReducer, defaultState)

  return (
    <GroupedPricingStateContext.Provider value={state}>
      <GroupedPricingDispatchContext.Provider value={dispatch}>
        {children}
      </GroupedPricingDispatchContext.Provider>
    </GroupedPricingStateContext.Provider>
  )
}

function useGroupedPricingState() {
  const context = React.useContext(GroupedPricingStateContext)
  if (context === undefined) {
    throw new Error(
      'useGroupedPricingState must be used within a GroupedPricingProvider'
    )
  }
  return context
}

function useGroupedPricingDispatch() {
  const context = React.useContext(GroupedPricingDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useGroupedPricingDispatch must be used within a GroupedPricingProvider'
    )
  }
  return context
}

function useGroupedPricing() {
  return [useGroupedPricingState(), useGroupedPricingDispatch()]
}

export {
  GroupedPricingProvider,
  useGroupedPricingState,
  useGroupedPricingDispatch,
  useGroupedPricing,
}
