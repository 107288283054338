import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as GradeSort } from 'images/competitor-pricing/grade-sort.svg'
import { ReactComponent as GradeSortDown } from 'images/competitor-pricing/grade-sort-down.svg'
import { ReactComponent as GradeSortUp } from 'images/competitor-pricing/grade-sort-up.svg'
import { competitorSortVar } from 'config/apollo/competitor-pricing/variables'
import {
  CompetitorSorts,
  SortDirections,
} from 'components/station/components/competitor-pricing/utils/constants'
import { useFormatPriceDifferential } from 'components/common/hooks/useFormatPriceDifferential'
import { usePriceChangeState } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import { LeadGradeIndicator } from 'components/common/lead-grade-indicator'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { FuelGradeDifferentialPopover } from './components/fuel-grade-differential-popover'
import styles from './FuelGradeCell.module.scss'

const cx = classNames.bind(styles)

const { ASC, DESC } = SortDirections

const FuelGradeCell = ({
  stationId,
  fuelGrade,
  priceDifferentials: { fuelGradeDifferentials },
  sliderDispatch,
}) => {
  const { t } = useTranslation()
  const { status } = usePriceChangeState()
  const { formatPriceDifferential } = useFormatPriceDifferential()

  const { id: fuelGradeId, shortTitle, primary } = fuelGrade
  const gradeStyles = { backgroundColor: primary }

  const fuelGradeDifferential = fuelGradeDifferentials.find(
    ({ fuelGradeId }) => fuelGradeId === fuelGrade.id
  )
  const showLeadGradeIcon =
    status !== PriceChangeStatus.None && fuelGradeDifferential?.leadGrade

  const { FUEL_GRADE_SORT } = CompetitorSorts
  const { type, grade, direction } = competitorSortVar()
  const sorted = type === FUEL_GRADE_SORT && grade === fuelGradeId

  const disableSlider = () => {
    sliderDispatch({
      type: 'enabled',
      payload: false,
    })
  }

  const fuelGradeSort = () => {
    competitorSortVar({
      type: FUEL_GRADE_SORT,
      grade: fuelGradeId,
      direction: sorted ? (direction === ASC ? DESC : ASC) : ASC,
    })
  }

  const renderSortButton = (
    <div className={cx('fuel-grade-sort')} onClick={fuelGradeSort}>
      {sorted ? (
        direction === ASC ? (
          <GradeSortUp />
        ) : (
          <GradeSortDown />
        )
      ) : (
        <GradeSort />
      )}
    </div>
  )

  return (
    <div className={cx('fuel-grade-cell')} style={gradeStyles}>
      <LeadGradeIndicator leadGrade={showLeadGradeIcon} fuelGrade={fuelGrade} />
      <div className={cx('text')}>
        <span>{t('widgets.competitorPricing.grade')}</span>
        <span className={cx('title')}>{shortTitle}</span>
      </div>
      <div className={cx('differential-sort')}>
        {status !== PriceChangeStatus.None && fuelGradeDifferential && (
          <div
            className={cx('fuel-grade-differential', {
              lead: showLeadGradeIcon,
            })}
          >
            {status === PriceChangeStatus.Edit && (
              <FuelGradeDifferentialPopover
                stationId={stationId}
                fuelGrade={fuelGrade}
                fuelGradeDifferential={fuelGradeDifferential}
                sliderDispatch={sliderDispatch}
              >
                <span onClick={disableSlider}>
                  {formatPriceDifferential(
                    fuelGradeDifferential.differential,
                    showLeadGradeIcon ? 0 : null
                  )}
                </span>
              </FuelGradeDifferentialPopover>
            )}
          </div>
        )}
      </div>
      {renderSortButton}
    </div>
  )
}

FuelGradeCell.propTypes = {
  stationId: PropTypes.string.isRequired,
  fuelGrade: PropTypes.shape().isRequired,
  fuelGradeDifferential: PropTypes.shape(),
  sliderDispatch: PropTypes.func.isRequired,
}

FuelGradeCell.defaultProps = {
  fuelGradeDifferential: null,
}

export { FuelGradeCell }
