import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames/bind'
import styles from './ConfirmCell.module.scss'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

const ConfirmCell = ({ cashCreditPricing }) => {
  const { t } = useTranslation()

  const content = cashCreditPricing ? (
    <>
      <div className={cx('cash-pricing-type')}>
        <span className={cx('title')}>
          {t('widgets.competitorPricing.current')} (Cash)
        </span>
        <span className={cx('new-message')}>
          {t('widgets.competitorPricing.new')}
        </span>
      </div>
      <span className={cx('divider')} />
      <div className={cx('credit-pricing-type')}>
        <span className={cx('title')}>
          {t('widgets.competitorPricing.current')} (Credit)
        </span>
        <span className={cx('new-message')}>
          {t('widgets.competitorPricing.new')}
        </span>
      </div>
    </>
  ) : (
    <div className={cx('all-pricing-type')}>
      <span className={cx('title')}>
        {t('widgets.competitorPricing.current')}
      </span>
      <span className={cx('new-message')}>
        {t('widgets.competitorPricing.new')}
      </span>
    </div>
  )

  return <div className={cx('confirm-cell')}>{content}</div>
}

ConfirmCell.propTypes = {
  cashCreditPricing: PropTypes.bool.isRequired,
}

export { ConfirmCell }
