import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'components/common/dropdown'
import { ReactComponent as FuelGradeIcon } from 'images/performance-report/fuel-grades.svg'
import classNames from 'classnames/bind'
import styles from './FuelGradeSelector.module.scss'

const cx = classNames.bind(styles)

const FuelGradeSelector = ({ fuelGrades, onSelected, selectedFuelGrade }) => {
  const items = useMemo(() => fuelGrades, [fuelGrades])

  const displayItem = useMemo(() => items.find(i => i.item === selectedFuelGrade)?.displayItem, [items, selectedFuelGrade])

  return (
    <Dropdown
      enableMultiSelect={false}
      items={items}
      selectedItem={selectedFuelGrade}
      selectedItemDisplay={
        <span className={cx('selected-item')}>
          <FuelGradeIcon className={cx('fuel-grade-icon')} />
          {displayItem}
        </span>
      }
      customClass={cx('wrapper')}
      onValueSelect={val => {
        onSelected(val)
      }}
    />
  )
}

FuelGradeSelector.propTypes = {
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelected: PropTypes.func.isRequired,
}

export { FuelGradeSelector }
