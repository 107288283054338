import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames/bind'
import { Navbar } from './components/navbar'
import { CompanyPerformance } from './components/company-performance'
import styles from './Header.module.scss'

const cx = classNames.bind(styles)

const Header = ({ mainData }) => {
  const isEdgeDataPro = mainData?.me?.company?.edgeDataPro

  return (
    <header className={cx('header')}>
      <Navbar mainData={mainData} />
      {!isEdgeDataPro && <CompanyPerformance />}
    </header>
  )
}

Header.propTypes = {
  mainData: PropTypes.shape().isRequired,
}

export { Header }
