import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './FuelGradesRow.module.scss'

const cx = classNames.bind(styles)

const FuelGradesRow = ({ fuelGrades }) => {
  return (
    <div className={cx('fuel-grades-row')}>
      <div />
      <div>
        {fuelGrades.map(fuelGrade => (
          <div key={fuelGrade.id} className={cx('fuel-grade')}>
            {fuelGrade.title}
          </div>
        ))}
      </div>
    </div>
  )
}

FuelGradesRow.propTypes = {
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { FuelGradesRow }
