import React, { useState, useCallback } from 'react'
import classNames from 'classnames/bind'
import styles from './FuelGradeSelector.module.scss'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Selector } from './components/selector'
import { KeyMetrics } from './components/key-metrics'
import { ProfitSelector } from './components/profit-selector'
import { Payments } from './components/payments'
import { PerformanceProfitTypes, PerformanceTypes } from 'utils/constants'
import { TargetMargin } from './components/target-margin'

const cx = classNames.bind(styles)

const { GROSS_MARGIN, NET_MARGIN } = PerformanceTypes
const { GROSS_PROFIT, NET_PROFIT, BUSINESS_PROFIT } = PerformanceProfitTypes

const getKeyMetrics = (tabContentData, selectedGrade) => {
  return tabContentData
    .filter(grade => grade.id === selectedGrade)
    .map(({ performanceType, keyMetrics }) => {
      return {
        performanceType,
        ...keyMetrics,
      }
    })
}

const getMetricPermission = (metricPermissions, activeTab) => {
  switch (activeTab) {
    case GROSS_MARGIN:
    case NET_MARGIN:
      return !!metricPermissions.readMargin
    case GROSS_PROFIT:
    case NET_PROFIT:
    case BUSINESS_PROFIT:
      return !!metricPermissions.readProfit
    default:
      return !!metricPermissions.readVolume
  }
}

const FuelGradeSelector = ({
  stationId,
  todaysGrossMargin,
  targetMargin,
  activeTab,
  tabContentData,
  selectedGrade,
  hasBunkered,
  handleSelectedGrade,
  handleSelectedProfit,
  selectedProfit,
  metricPermissions,
  isOverlayMode,
}) => {
  const [hoveredGrade, setHoveredGrade] = useState(null)
  const [selectedGradeColour, setSelectedGradeColour] = useState('#d5d9dd')
  const [selectedGradeIndex, setSelectedGradeIndex] = useState(0)
  const { t } = useTranslation()

  const hasMetricPermission = getMetricPermission(metricPermissions, activeTab)

  const handleHoveredGrade = useCallback(hoveredGrade => {
    setHoveredGrade(hoveredGrade)
  }, [])

  const handleSelectedGradeColour = (colour, selectorIndex) => {
    setSelectedGradeColour(colour)
    setSelectedGradeIndex(selectorIndex)
  }

  const hoveredOrSelected = hoveredGrade || selectedGrade

  const selectors = tabContentData.map(
    (
      {
        id,
        metricType,
        shortTitle,
        value,
        trend,
        primary,
        secondary,
        comparativeValue,
      },
      i
    ) => (
      <Selector
        key={i}
        selectedIndex={i}
        comparativeValue={comparativeValue}
        id={id}
        metricType={metricType}
        selectedGrade={selectedGrade}
        handleHoveredGrade={handleHoveredGrade}
        handleSelectedGrade={handleSelectedGrade}
        handleSelectedGradeColour={handleSelectedGradeColour}
        shortTitle={shortTitle}
        value={value}
        trend={trend}
        primary={primary}
        secondary={secondary}
        hasMetricPermission={hasMetricPermission}
        isOverlayMode={isOverlayMode}
      />
    )
  )

  const profitTabSelected = Object.values(PerformanceProfitTypes).includes(
    activeTab
  )

  const keyMetrics = getKeyMetrics(tabContentData, hoveredOrSelected)
  const { payment: payments } = keyMetrics[0]
  const displayPayments = payments ? (
    <Payments payments={payments} hasMetricPermission={hasMetricPermission} />
  ) : null
  const showTargetMargin = [GROSS_MARGIN, NET_MARGIN].includes(activeTab)
  const displayTargetMargin = showTargetMargin ? (
    <TargetMargin
      todaysGrossMargin={todaysGrossMargin}
      targetMargin={targetMargin}
      stationId={stationId}
      isOverlayMode={isOverlayMode}
    />
  ) : null

  // TODO reconsider approach to creating curve when we have time
  const fancyCurveHeight = tabContentData.length * 80 + 90
  const fancyCurveStyle = {
    minHeight: `${fancyCurveHeight}px`,
    borderColor: `${selectedGradeColour}`,
  }

  const fancyCurveMaskHeight = selectedGradeIndex * 80 + 145
  const fancyCurveMaskStyle = {
    height: `${fancyCurveMaskHeight}px`,
  }

  return (
    <div className={cx('fuel-grade-selector-container')}>
      <div className={cx('fuel-grade-selector-widget-container')}>
        <div className={cx('grades-title')}>
          {t('widgets.stationPerformance.gradesTitle')}
        </div>
        <div className={cx('data-container')}>{selectors}</div>
        {profitTabSelected && (
          <ProfitSelector
            hasBunkered={hasBunkered}
            handleSelected={handleSelectedProfit}
            selectedProfit={selectedProfit}
          />
        )}
      </div>
      <div className={cx('fuel-grade-metrics-container')}>
        <div
          className={cx('fancy-fuel-grade-selector-curve')}
          style={fancyCurveStyle}
        >
          <div
            className={cx('fancy-curve-mask')}
            style={fancyCurveMaskStyle}
          ></div>
          <div className={cx('fuel-grade-metrics')}>
            <div className={cx('metrics-1')}>
              <KeyMetrics
                metricsData={keyMetrics}
                hasBunkered={hasBunkered}
                hasMetricPermission={hasMetricPermission}
              />
            </div>
            <div className={cx('vertical-spacer')}>
              <div className={cx('line')}></div>
            </div>
            <div className={cx('metrics-2')}>
              {displayPayments}
              {displayTargetMargin}
            </div>
          </div>
          <div className={cx('metric-3')}></div>
        </div>
      </div>
    </div>
  )
}

FuelGradeSelector.propTypes = {
  stationId: PropTypes.string.isRequired,
  todaysGrossMargin: PropTypes.number.isRequired,
  targetMargin: PropTypes.number,
  tabContentData: PropTypes.array.isRequired,
  selectedGrade: PropTypes.string.isRequired,
  hasBunkered: PropTypes.bool.isRequired,
  handleSelectedGrade: PropTypes.func.isRequired,
  metricPermissions: PropTypes.shape({
    readMargin: PropTypes.bool,
    readProfit: PropTypes.bool,
    readVolume: PropTypes.bool,
  }).isRequired,
  isOverlayMode: PropTypes.bool,
}

FuelGradeSelector.defaultProps = { isOverlayMode: false }

export { FuelGradeSelector }
