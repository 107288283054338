import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames/bind'
import { StationSearch } from './components/station-search'
import { SingleSiteTitle } from './components/single-site-title'
import { PricingTypeSelector } from './components/pricing-type-selector'
import { Settings } from './components/settings'
import { NotificationsToggle } from './components/notifications-toggle'
import { ReactComponent as ProLogo } from 'images/analytics-report/pro-logo.svg'
import styles from './Navbar.module.scss'

const cx = classNames.bind(styles)

const Navbar = ({ mainData }) => {
  const {
    me: {
      isSingleSite,
      stations,
    },
  } = mainData
  const isEdgeDataPro = mainData?.me?.company?.edgeDataPro
  const items = isEdgeDataPro ? [] : stations?.items || [];

  return (
    <div className={cx('navbar')}>
      {isEdgeDataPro && (
        <div className={cx('logo-container')}>
          <ProLogo className={cx('logo')} />
          <div className={cx('navbar-line')} />
        </div>
      )}
      {(!isEdgeDataPro && !isSingleSite) && <StationSearch stations={items} />}
      {isSingleSite && <SingleSiteTitle station={items[0]} />}
      <PricingTypeSelector />
      <Settings />
      <NotificationsToggle stations={items} isSingleSite={isSingleSite} />
    </div>
  )
}

Navbar.propTypes = {
  mainData: PropTypes.shape().isRequired,
}

export { Navbar }
