import { setStatus, setNoneStatus, setEditStatus, resetStatus } from './status'
import { selectStations, deselectStations } from './selectedStations'
import {
  incrementFuelGradePriceChange,
  decrementFuelGradePriceChange,
} from './fuelGradePriceChanges'
import {
  incrementStationPriceChange,
  decrementStationPriceChange,
} from './stationPriceChanges'
import { toggleReminder, setActionAt } from './actionAt'
import { setOverlayStation } from './overlayStation'

const stationsListPricingOperations = {
  setStatus,
  setNoneStatus,
  setEditStatus,
  resetStatus,
  selectStations,
  deselectStations,
  incrementFuelGradePriceChange,
  decrementFuelGradePriceChange,
  incrementStationPriceChange,
  decrementStationPriceChange,
  toggleReminder,
  setActionAt,
  setOverlayStation,
}

export { stationsListPricingOperations }
