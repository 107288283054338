import React from 'react'
import PropTypes from 'prop-types'
import { ImageAsset } from 'components/common/image-asset'
import { images, fallback } from 'images/image-library'
import { useStationStatusQuery } from 'components/common/hooks/useStationStatusQuery'
import { useFlags } from 'launchdarkly-react-client-sdk'
import classNames from 'classnames/bind'
import { SingleSiteStatus } from './components/single-site-status'
import styles from './SingleSiteTitle.module.scss'

const cx = classNames.bind(styles)

const SingleSiteTitle = ({ station }) => {
  const { showStatusWidget } = useFlags()
  const { id, name, brand } = station
  const { error, data } = useStationStatusQuery(id, !showStatusWidget)

  return (
    <div className={cx('single-site')}>
      <ImageAsset
        src={images.brandImage(brand?.key)}
        width={50}
        height={50}
        alt={`brand-${brand?.key}`}
        fallbackImage={fallback.defaultBrand}
      />
      <div>{name}</div>
      {showStatusWidget && <SingleSiteStatus data={data} error={error} />}
    </div>
  )
}

SingleSiteTitle.propTypes = {
  station: PropTypes.shape().isRequired,
}

export { SingleSiteTitle }
