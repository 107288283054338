import React from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ProfileIcon } from 'images/header/settings/profile.svg'
import { ReactComponent as Arrow } from 'images/header/settings/arrow.svg'
import { ReactComponent as RedArrow } from 'images/header/settings/red-arrow.svg'
import { ReactComponent as SettingsIcon } from 'images/header/settings/settings.svg'
import { ReactComponent as HelpCentreIcon } from 'images/header/settings/help-centre.svg'
import { ReactComponent as ChatIcon } from 'images/header/settings/chat.svg'
import { ReactComponent as SignOutIcon } from 'images/header/settings/sign-out.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { useAuth } from 'config/context/authContext'
import { useIntercom } from 'react-use-intercom'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import styles from './Settings.module.scss'

const cx = classNames.bind(styles)

const renderRows = rows => {
  return rows.map(({ Icon, title, onClick, customClass }) => {
    return (
      <div
        className={cx('row', customClass)}
        onClick={onClick}
        key={title}
        data-testid="row"
      >
        <Icon />
        <div className={cx('title')}>{title}</div>
        {customClass === 'sign-out' ? <RedArrow /> : <Arrow />}
      </div>
    )
  })
}

const Settings = () => {
  const { logout, name, companyName } = useAuth()
  const { t } = useTranslation()
  const { showNewMessages } = useIntercom()

  const rows = [
    {
      Icon: HelpCentreIcon,
      title: t('widgets.settings.successCenter'),
      onClick: () => {
        const url = `https://www.edgepetrol.com/customer-portal/${t(
          'widgets.settings.customerPortal'
        )}`
        window.open(url, '_blank')
        amplitudeEvent(UserEvents.Settings.SUCCESS_CENTER)
      },
      customClass: null,
    },
    {
      Icon: ChatIcon,
      title: t('widgets.settings.chatToSupport'),
      onClick: () => {
        showNewMessages()
        amplitudeEvent(UserEvents.Settings.CHAT)
      },
      customClass: null,
    },
    {
      Icon: SignOutIcon,
      title: t('widgets.settings.signOut'),
      onClick: () => logout(),
      customClass: 'sign-out',
    },
  ]

  const overlay = (
    <Popover id="settings-popover" className={cx('settings-popover')}>
      <Popover.Content className={cx('content')}>
        <div className={cx('row', 'user-row')} data-testid="user-name">
          <div>
            <ProfileIcon />
          </div>

          <div className={cx('user-name', 'title')}>
            <div className={cx('name')}>
              {name.first} {name.last}
            </div>
            <div className={cx('company-name')}>{companyName}</div>
          </div>
        </div>
        {renderRows(rows)}
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={overlay}
      rootClose
    >
      <div className={cx('settings')} data-testid="settings">
        <SettingsIcon height={20} width={20} />
      </div>
    </OverlayTrigger>
  )
}

Settings.propTypes = {}

export { Settings }
