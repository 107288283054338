import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

const traceSampleRate = process.env.REACT_APP_SENTRY_SAMPLE_RATE || 0.1

export const sentryInit = () =>
  Sentry.init({
    release: process.env.REACT_APP_SENTRY_RELEASE_ID,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    environment: process.env.NODE_ENV,
    /*
     * Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
     * We recommend adjusting this value in production
     */
    tracesSampleRate: traceSampleRate,
  })
