import React from 'react'
import PropTypes from 'prop-types'
import { useFormatPriceDifferential } from 'components/common/hooks/useFormatPriceDifferential'
import classNames from 'classnames/bind'
import { CashCreditDifferentialPopover } from './components/cash-credit-differential-popover'
import styles from './CashCreditDifferential.module.scss'

const cx = classNames.bind(styles)

const CashCreditDifferential = ({
  cashCreditDifferential,
  stationId,
  fuelGradeId,
  sliderDispatch,
}) => {
  const { formatPriceDifferential } = useFormatPriceDifferential()

  const disableSlider = () => {
    sliderDispatch({
      type: 'enabled',
      payload: false,
    })
  }

  return (
    <CashCreditDifferentialPopover
      stationId={stationId}
      fuelGradeId={fuelGradeId}
      cashCreditDifferential={cashCreditDifferential}
      sliderDispatch={sliderDispatch}
    >
      <div className={cx('cash-credit-differential')} onClick={disableSlider}>
        {formatPriceDifferential(cashCreditDifferential?.differential, '-')}
      </div>
    </CashCreditDifferentialPopover>
  )
}

CashCreditDifferential.propTypes = {
  cashCreditDifferential: PropTypes.shape(),
  stationId: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  sliderDispatch: PropTypes.func.isRequired,
}

CashCreditDifferential.defaultProps = {
  cashCreditDifferential: null,
}

export { CashCreditDifferential }
