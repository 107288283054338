import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import i18n from 'i18next'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Trans } from 'react-i18next'
import { Locales } from 'utils/constants'
import classNames from 'classnames/bind'
import styles from './PriceChangeTooltip.module.scss'
import { useCompanyTimeZone } from '../hooks/useCompanyTimeZone'

const cx = classNames.bind(styles)

const showToActionAt = polePriceChangeRequest => {
  if (!polePriceChangeRequest?.toActionAt) {
    return false
  }

  const toActionAt = moment.utc(polePriceChangeRequest.toActionAt)
  const now = moment.utc()

  return toActionAt.isAfter(now)
}

const PriceChangeTooltip = ({ children, polePriceChangeRequest }) => {
  const { companyTimeZone } = useCompanyTimeZone()

  let tooltipText = (
    <Trans
      i18nKey="widgets.stationsListPricing.priceChangeTooltip"
      components={<span />}
    />
  )

  if (showToActionAt(polePriceChangeRequest)) {
    const toActionAt = moment.tz(
      polePriceChangeRequest.toActionAt,
      companyTimeZone
    )

    const date =
      i18n.language === Locales.EN_US
        ? toActionAt.format('MM/DD/YY h:mma')
        : toActionAt.format('DD/MM/YY HH:mm')

    tooltipText = (
      <Trans
        i18nKey="widgets.stationsListPricing.priceChangeTooltipActionAt"
        components={<span />}
        values={{ date }}
      />
    )
  }

  const overlay = (
    <Tooltip className={cx('price-change-tooltip')}>
      <div>{tooltipText}</div>
    </Tooltip>
  )
  return (
    <OverlayTrigger placement="top" overlay={overlay}>
      {children}
    </OverlayTrigger>
  )
}

PriceChangeTooltip.propTypes = {
  children: PropTypes.object.isRequired,
  polePriceChangeRequest: PropTypes.shape({
    toActionAt: PropTypes.string,
  }),
}

PriceChangeTooltip.defaultProps = {
  polePriceChangeRequest: null,
}

export { PriceChangeTooltip }
