import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import { Locales, MetricTypes } from 'utils/constants'
import { isNullOrUndefined } from 'utils/helpers'

const {
  VOLUME,
  MARGIN,
  PROFIT,
  PERCENTAGE,
  NUMBER,
  COST,
  BUNKER_COMMISSION,
  FILL_UP,
  POLE_PRICE
} = MetricTypes

const cacheTranslation = (() => {
  const cache = {}

  return (t, key) => {
    if (!cache[key]) {
      cache[key] = t(key)
    }
    return cache[key]
  }
})()

const formatVolume = (t, value, short) => {
  const formatString = value >= 1_000_000 ? '0.00a' : '0,0'
  return short
    ? `${numeral(value).format(formatString)} ${cacheTranslation(
        t,
        'volume.short'
      )}`
    : `${numeral(value).format(formatString)}`
}

const formatMargin = (t, value, short) => {
  const formatString = '0.00'
  return short
    ? `${numeral(value).format(formatString)} ${cacheTranslation(
        t,
        'margin.short'
      )}`
    : `${numeral(value).format(formatString)}`
}

const formatProfit = (t, value, short) => {
  const parsedValue = value / 100
  const formatString = parsedValue >= 1_000_000 ? '0.00a' : '0,0'
  return short
    ? `${cacheTranslation(t, 'currency.short')}${numeral(parsedValue).format(
        formatString
      )}`
    : `${numeral(parsedValue).format(formatString)}`
}

const formatPercentage = value => {
  const formatString = '0'
  return `${numeral(Math.abs(value)).format(formatString)}%`
}

const formatCost = (t, value, short) => {
  const isUS = i18n.language === Locales.EN_US
  const parsedValue = isUS ? value / 100 : value
  const formatString = isUS ? '0.0000' : '0.00'

  return short
    ? `${numeral(parsedValue).format(formatString)} ${cacheTranslation(
        t,
        'cost.short'
      )}`
    : `${numeral(parsedValue).format(formatString)}`
}

const formatPolePrice = (t, value, short) => {
  const isUS = i18n.language === Locales.EN_US
  const parsedValue = isUS ? value / 100 : value
  let formattedValue = numeral(parsedValue).format('0.00')

  return short
  ? `${formattedValue} ${cacheTranslation(t, 'cost.short')}`
  : formattedValue
}

const formatBunkerCommission = (t, value, short) => {
  const parsedValue = value / 100
  let formattedValue = numeral(parsedValue).format('0.00')

  return short
    ? `${cacheTranslation(t, 'currency.short')}${formattedValue}`
    : formattedValue;
}

const formatFillUp = (t, value, short) => {
  let formattedValue = numeral(value).format('0.0')

  return short
  ? `${formattedValue} ${cacheTranslation(t, 'volume.short')}`
  : formattedValue
}

function useFormatMetricValue() {
  const { t } = useTranslation(['translations'])

  /**
   * Formats a value to a string.
   *
   * @param {*} type VOLUME, MARGIN, PROFIT, PERCENTAGE, COST, BUNKER_COMMISSION or NUMBER
   * @param {*} value the value to format
   * @param {*} short whether to add a short form symbol to the returned string
   * @returns the formatted value as a string
   */
  function formatMetricValue(type, value, short = true, noData = false) {
    if (noData && (isNullOrUndefined(value) || isNaN(value))) {
      return 'no data'
    }

    if (isNullOrUndefined(value)) {
      return '-'
    }

    switch (type) {
      case VOLUME:
        return formatVolume(t, value, short)
      case MARGIN:
        return formatMargin(t, value, short)
      case PROFIT:
        return formatProfit(t, value, short)
      case PERCENTAGE:
        return formatPercentage(value)
      case COST:
        return formatCost(t, value, short)
      case BUNKER_COMMISSION:
        return formatBunkerCommission(t, value, short)
      case FILL_UP:
        return formatFillUp(t, value, short)
      case NUMBER:
        return value
      case POLE_PRICE:
        return formatPolePrice(t, value, short)
      default:
        return '-'
    }
  }

  return { formatMetricValue }
}

function useMetricSymbol() {
  const { t } = useTranslation(['translations'])

  function formatMetricSymbol(type) {
    switch (type) {
      case VOLUME:
        return cacheTranslation(t, 'volume.short')
      case MARGIN:
        return cacheTranslation(t, 'margin.short')
      case PROFIT:
        return cacheTranslation(t, 'currency.short')
      case COST:
        return cacheTranslation(t, 'cost.short')
      case BUNKER_COMMISSION:
        return cacheTranslation(t, 'currency.short')
      case FILL_UP:
        return cacheTranslation(t, 'volume.short')
      case POLE_PRICE:
        return cacheTranslation(t, 'cost.short')
      default:
        return ''
    }
  }

  return { formatMetricSymbol }
}

export { useFormatMetricValue, useMetricSymbol }
