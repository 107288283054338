import React from 'react'
import PropTypes from 'prop-types'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import {
  findChangedPendingRequest,
  changedPendingRequest,
} from 'components/station/components/competitor-pricing/utils/helpers'
import { PricingTypes } from 'utils/constants'
import { isNullOrUndefined } from 'utils/helpers'
import { CashCreditDifferential } from '../cash-credit-differential'
import classNames from 'classnames/bind'
import styles from './ConfirmChanges.module.scss'

const cx = classNames.bind(styles)

const { ALL, CASH, CREDIT } = PricingTypes

const ConfirmChanges = ({
  stationId,
  fuelGradeId,
  cashCreditDifferential,
  pendingRequests,
  stationCashCreditPricing,
  sliderDispatch,
}) => {
  const { formatPolePrice } = useFormatPolePrice()

  if (!changedPendingRequest(pendingRequests, fuelGradeId)) {
    return null
  }

  if (!stationCashCreditPricing) {
    const pendingRequest = findChangedPendingRequest(
      pendingRequests,
      ALL,
      fuelGradeId
    )

    return (
      <div className={cx('confirm-changes')}>
        <span className={cx('all-price')}>
          {!isNullOrUndefined(pendingRequest) && (
            <div className={cx('price-info')}>
              <div className={cx('price')}>
                {formatPolePrice(pendingRequest?.price)}
              </div>
              <div className={cx('pending-price')}>
                {formatPolePrice(pendingRequest?.newPrice)}
              </div>
            </div>
          )}
        </span>
      </div>
    )
  }

  const pendingCashRequest = findChangedPendingRequest(
    pendingRequests,
    CASH,
    fuelGradeId
  )
  const pendingCreditRequest = findChangedPendingRequest(
    pendingRequests,
    CREDIT,
    fuelGradeId
  )

  const pendingRequest =
    !isNullOrUndefined(pendingCreditRequest) ||
    !isNullOrUndefined(pendingCashRequest)

  return (
    <div className={cx('confirm-changes')}>
      {pendingRequest ?? (
        <CashCreditDifferential
          stationId={stationId}
          fuelGradeId={fuelGradeId}
          cashCreditDifferential={cashCreditDifferential}
          sliderDispatch={sliderDispatch}
        />
      )}
      <>
        <span className={cx('cash-price')}>
          {!isNullOrUndefined(pendingCashRequest) && (
            <div className={cx('price-info')}>
              <div className={cx('price')}>
                {formatPolePrice(pendingCashRequest?.price)}
              </div>
              <div className={cx('pending-price')}>
                {formatPolePrice(pendingCashRequest?.newPrice)}
              </div>
            </div>
          )}
        </span>
        <span className={cx('divider')} />
        <span className={cx('credit-price')}>
          {!isNullOrUndefined(pendingCreditRequest) && (
            <div className={cx('price-info')}>
              <div className={cx('price')}>
                {formatPolePrice(pendingCreditRequest?.price)}
              </div>
              <div className={cx('pending-price')}>
                {formatPolePrice(pendingCreditRequest?.newPrice)}
              </div>
            </div>
          )}
        </span>
      </>
    </div>
  )
}

ConfirmChanges.propTypes = {
  stationId: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  cashCreditDifferential: PropTypes.shape(),
  pendingRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  stationCashCreditPricing: PropTypes.bool.isRequired,
  sliderDispatch: PropTypes.func.isRequired,
}

ConfirmChanges.defaultProps = {
  cashCreditDifferential: null,
}

export { ConfirmChanges }
