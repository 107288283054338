import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ReactComponent as NextDarkIcon } from 'images/stations-list/station-overlay/next-dark.svg'
import { ReactComponent as NextLightIcon } from 'images/stations-list/station-overlay/next-light.svg'
import { ReactComponent as PreviousDarkIcon } from 'images/stations-list/station-overlay/previous-dark.svg'
import { ReactComponent as PreviousLightIcon } from 'images/stations-list/station-overlay/previous-light.svg'
import { useReactiveVar } from '@apollo/client'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { ImageAsset } from 'components/common/image-asset'
import { TOTALS_METRIC_ID } from 'components/stations-list/utils/constants'
import { selectedStationIdsVar } from 'config/apollo/stations-list-pricing/variables'
import { ReactComponent as CloseIcon } from 'images/stations-list/station-overlay/cancel.svg'
import { images, fallback } from 'images/image-library'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import classNames from 'classnames/bind'
import styles from './OverlayHeader.module.scss'

const cx = classNames.bind(styles)

/**
 * The station overlay displays a different set of stations based off the current state and the selected station.
 * * if no selected stations
 *   * click station -> all stations
 * * if selected stations
 *   * click selected station -> selected stations only
 *   * click unselected station -> only that station
 */
const filterItems = (items, overlayStation, selectedStationIds) => {
  const hasSelectedStations = selectedStationIds.length > 0
  const overlayStationIsSelected = selectedStationIds.includes(overlayStation)

  const itemsWithoutTotal = items.filter(({ id }) => id !== TOTALS_METRIC_ID)

  if (hasSelectedStations && overlayStationIsSelected) {
    return itemsWithoutTotal.filter(({ id }) => selectedStationIds.includes(id))
  } else if (hasSelectedStations) {
    return [itemsWithoutTotal.find(({ id }) => id === overlayStation)]
  }

  return itemsWithoutTotal
}

const OverlayHeader = ({ overlayStation, stations, items, handleClose }) => {
  const { t } = useTranslation()
  const selectedStationIds = useReactiveVar(selectedStationIdsVar)

  const filteredItems = filterItems(items, overlayStation, selectedStationIds)
  const index = filteredItems.findIndex(({ id }) => id === overlayStation)
  const station = stations.find(({ id }) => id === overlayStation)

  const { brand, name } = station

  const handlePreviousClick = () => {
    handleSelectStation(index - 1)
    amplitudeEvent(UserEvents.PricingPopoverPage.PREVIOUS)
  }

  const handleNextClick = () => {
    handleSelectStation(index + 1)
    amplitudeEvent(UserEvents.PricingPopoverPage.NEXT)
  }

  const previousIcon =
    index === 0 ? (
      <PreviousLightIcon className={cx('disabled')} />
    ) : (
      <PreviousDarkIcon onClick={handlePreviousClick} data-testid="previous" />
    )

  const nextIcon =
    index === filteredItems.length - 1 ? (
      <NextLightIcon className={cx('disabled')} />
    ) : (
      <NextDarkIcon onClick={handleNextClick} data-testid="next" />
    )

  const handleSelectStation = index => {
    const stationId = filteredItems[index].id
    stationsListPricingOperations.setOverlayStation(stationId)
  }

  return (
    <div className={cx('overlay-header')}>
      <div className={cx('station')}>
        <ImageAsset
          height={65}
          width={65}
          src={images.brandImage(brand?.key)}
          alt={`station-title-brand-${brand?.name}`}
          fallbackImage={fallback.defaultBrand}
        />
        <div>{name}</div>
      </div>
      <div className={cx('station-selector')}>
        <div>
          {t('widgets.stationOverlay.stationSelector', {
            index: index + 1,
            total: filteredItems.length,
          })}
        </div>
        {previousIcon}
        {nextIcon}
      </div>
      <div className={cx('exit')}>
        <CloseIcon onClick={handleClose} data-testid="close" />
      </div>
    </div>
  )
}

OverlayHeader.propTypes = {
  overlayStation: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleClose: PropTypes.func.isRequired,
}

export { OverlayHeader }
