import { makeVar } from '@apollo/client'

/**
 * Performance Report selections are global for both the Performance page and
 * the station page Performance Widget.
 *
 * @example
 * ['87', '89', 'Diesel']
 */

/**
 * Holds the fuel grade selections as an array of grade titles.
 *
 * @example
 * ['87', '89', 'Diesel']
 */
const fuelGradesVar = makeVar(null)

/**
 * Holds the period selections as an object of two Moment objects.
 *
 * @example
 *  { from: Moment, to: Moment }
 */
const currentPeriodOneVar = makeVar(null)
const currentPeriodTwoVar = makeVar(null)

/**
 * Holds the metric selection as a single string.
 */
const metricVar = makeVar('retailVolume')

export { fuelGradesVar, currentPeriodOneVar, currentPeriodTwoVar, metricVar }
