import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as PricesSentIcon } from 'images/pole-prices/modal-sent.svg'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames/bind'
import styles from './SentModal.module.scss'

const cx = classNames.bind(styles)

const SentModal = ({ show, onHide }) => {
  const { t } = useTranslation(['translations'])

  return (
    <Modal
      className={cx('modal')}
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      centered
      backdrop="static"
    >
      <Modal.Body>
        <PricesSentIcon className={cx('icon')} height={42} width={42} />
        <div className={cx('body')}>
          <h4>{t('widgets.competitorPricing.priceChangesSent')}</h4>
          <p>{t('widgets.competitorPricing.priceChangesSentMessage')}</p>
        </div>
        <Button className={cx('button')} onClick={onHide}>
          {t('widgets.competitorPricing.done')}
        </Button>
      </Modal.Body>
    </Modal>
  )
}

SentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export { SentModal }
