import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  DefaultPolePriceRegex,
  USPolePriceRegex,
  Locales,
} from 'utils/constants'
import { isNullOrUndefined } from 'utils/helpers'
import { calendarDaysDifference } from 'utils/dateTime'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'

function useFormatPolePrice() {
  const { companyTimeZone } = useCompanyTimeZone()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  function formatPolePrice(value, fallback = null) {
    if (isNullOrUndefined(value)) {
      return fallback
    }

    if (i18n.language === Locales.EN_US) {
      // US prices can have a fraction on the end
      const valueMinusFraction = parseInt(value)
      return (valueMinusFraction / 100).toFixed(2)
    }
    return parseFloat(value).toFixed(1)
  }

  function formatPolePriceDate(date) {
    if (isNullOrUndefined(date)) {
      return null
    }

    const dateInZone = moment.tz(date, companyTimeZone)
    if (!dateInZone.isValid()) {
      return null
    }

    const daysAgo = calendarDaysDifference(
      moment().tz(companyTimeZone),
      dateInZone
    )

    const timeValue =
      language === Locales.EN_US
        ? dateInZone.format('h:mma')
        : dateInZone.format('HH:mm')

    if (daysAgo === 0) {
      return t('widgets.competitorPricing.daysAgoZeroText', {
        timeValue: `${timeValue}`,
      })
    } else if (daysAgo === 1) {
      return t('widgets.competitorPricing.daysAgoOneText', {
        timeValue: `${timeValue}`,
      })
    }

    return t('widgets.competitorPricing.daysAgoText', {
      daysAgo: `${daysAgo}`,
      timeValue: `${timeValue}`,
    })
  }

  function validatePolePrice(polePrice) {
    if (i18n.language === Locales.EN_US) {
      return USPolePriceRegex.test(polePrice)
    }
    return DefaultPolePriceRegex.test(polePrice)
  }

  return { formatPolePrice, formatPolePriceDate, validatePolePrice }
}

export { useFormatPolePrice }
