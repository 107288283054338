import React from 'react'
import classNames from 'classnames/bind'
import styles from './Payments.module.scss'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { Payment } from './components/payment'

const cx = classNames.bind(styles)

const compare = (paymentOne, paymentTwo) => {
  const valueA = paymentOne.value
  const valueB = paymentTwo.value

  let comparison = 0
  if (valueA < valueB) {
    comparison = 1
  } else if (valueA > valueB) {
    comparison = -1
  }
  return comparison
}

const Payments = ({ payments, hasMetricPermission }) => {
  const { t } = useTranslation()
  return (
    <div className={cx('payments-container')}>
      <div className={cx('payments-title')}>
        {t('widgets.stationPerformance.volumeByPaymentType')}
      </div>
      <div className={cx('payments-body')}>
        {[...payments].sort(compare).map((payment, i) => {
          return (
            <Payment
              key={i}
              payment={payment}
              hasMetricPermission={hasMetricPermission}
            />
          )
        })}
      </div>
      <div className={cx('payments-bottom-gradient')}></div>
    </div>
  )
}

Payments.propTypes = {
  payments: PropTypes.array.isRequired,
  hasMetricPermission: PropTypes.bool.isRequired,
}

export { Payments }
