import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { useTranslation } from 'react-i18next'
import { EditTargetMargin } from './components/edit-target-margin'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'
import { ReactComponent as EditIcon } from 'images/common/edit.svg'
import { ReactComponent as EditDisabledIcon } from 'images/common/edit-disabled.svg'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { MetricTypes } from 'utils/constants'
import styles from './TargetMargin.module.scss'

const cx = classNames.bind(styles)

const TargetMargin = ({
  todaysGrossMargin,
  stationId,
  targetMargin,
  isOverlayMode,
}) => {
  const [editTargetMarginSelected, setEditTargetMarginSelected] = useState(
    false
  )
  const { formatMetricValue } = useFormatMetricValue()
  const { t } = useTranslation()

  const {
    featurePermissions: { readTargetMargin, updateTargetMargin },
  } = usePermittedAccess()

  const handleClick = () => {
    setEditTargetMarginSelected(true)
    amplitudeEvent(UserEvents.TargetMargin.OPENED(isOverlayMode))
  }

  const renderTargetMargin = (
    <Permissioned permission={readTargetMargin} fallback={<Placeholder />}>
      <div
        className={cx('target-margin-value', {
          negative: targetMargin > todaysGrossMargin,
          empty: targetMargin === null,
        })}
      >
        {formatMetricValue(MetricTypes.MARGIN, targetMargin)}
      </div>
    </Permissioned>
  )

  const renderEditButton = (
    <Permissioned
      permission={updateTargetMargin}
      fallback={<EditDisabledIcon cursor={'not-allowed'} />}
    >
      <EditIcon onClick={handleClick} />
    </Permissioned>
  )

  const displayTargetMargin = (
    <div className={cx('target-margin')}>
      <div className={cx('target-margin-info')}>
        <div className={cx('target-margin-description')}>
          {t('widgets.stationPerformance.currentGrossMarginTarget')}
        </div>
        {renderTargetMargin}
      </div>
      <div className={cx('target-margin-edit')}>{renderEditButton}</div>
    </div>
  )

  return (
    <div className={cx('target-margin-container')}>
      <div className={cx('target-margin-title')}>
        {t('widgets.stationPerformance.grossMarginTargetTitle')}
      </div>

      {editTargetMarginSelected ? (
        <EditTargetMargin
          stationId={stationId}
          targetMargin={targetMargin}
          setTargetMarginSelected={setEditTargetMarginSelected}
          isOverlayMode={isOverlayMode}
        />
      ) : (
        displayTargetMargin
      )}
    </div>
  )
}

TargetMargin.propTypes = {
  todaysGrossMargin: PropTypes.number.isRequired,
  stationId: PropTypes.string.isRequired,
  targetMargin: PropTypes.number,
  isOverlayMode: PropTypes.bool,
}

TargetMargin.defaultProps = { isOverlayMode: false }

export { TargetMargin }
