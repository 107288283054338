import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

const timeFormat = 'HH:mm'

const intervals = () => {
  // create times from 00:00 to 23:30 every 30 minutes
  const start = moment('00:00', timeFormat)
  return Array.apply(null, Array(48)).map((_, index) => {
    if (index !== 0) {
      start.add(30, 'm')
    }
    return start.format(timeFormat)
  })
}

const thirtyMinuteIntervals = intervals()

/**
 * Returns time intervals in thirty minute increments (00:00, 00:30 etc).
 */
const timeIntervals = () => {
  return thirtyMinuteIntervals
}

const timeToMinutes = (hours, minutes) => hours * 60 + Number(minutes)

/**
 * Returns filtered time intervals in thirty minute increments (00:00, 00:30 etc).
 *
 * The intervals are all earlier or later than now in the portfolio time zone (based off earlier flag). An optional buffer can be provided
 * in minutes to remove intervals nearest the current time.
 */
const filteredTimeIntervals = (companyTimeZone, earlier, buffer = 0) => {
  const [hours, minutes] = moment()
    .tz(companyTimeZone)
    .format(timeFormat)
    .split(':')
  const currentMinutes = timeToMinutes(hours, minutes)

  // filter intervals based off earlier flag and buffer
  return thirtyMinuteIntervals.filter(time => {
    const timeSplit = time.split(':')
    const timeMinutes = timeToMinutes(timeSplit[0], timeSplit[1])

    const currentMinutesWithBuffer = earlier
      ? currentMinutes - buffer
      : currentMinutes + buffer
    return earlier
      ? timeMinutes < currentMinutesWithBuffer
      : timeMinutes > currentMinutesWithBuffer
  })
}

export { timeIntervals, filteredTimeIntervals }
