import React from 'react'
import PropTypes from 'prop-types'
import { StationRow } from '../station-row'

const StationRows = ({
  items,
  stations,
  showPricingView,
  cashCreditPricing,
  loopStations
}) => {
  const stationRows = items.map(({ id, pricing, metrics }) => {
    const station = stations.find(station => station.id === id)

    if (!station) {
      return null
    }

    return (
      <StationRow
        key={id}
        station={station}
        metrics={metrics}
        priceChangeEnabled={pricing?.priceChangeEnabled}
        stationCashCreditPricing={pricing?.cashCreditPricing}
        isSelected={pricing?.isSelected}
        hasStationPrice={pricing?.hasStationPrice}
        showPricingView={showPricingView}
        cashCreditPricing={cashCreditPricing}
        loopStations={loopStations}
      />
    )
  })
  return stationRows
}

StationRows.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showPricingView: PropTypes.bool.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
  loopStations: PropTypes.bool,
}

export { StationRows }
