import { slugify } from 'utils/slug'
import { FIRST_PAGE } from 'components/stations-list/utils/constants'

const stationsMerge = (existing, incoming) => {
  if (!existing) {
    return incoming
  }

  const { pageInfo, items } = incoming
  if (pageInfo.pageNumber === FIRST_PAGE) {
    return {
      pageInfo,
      items,
    }
  }

  return {
    pageInfo,
    items: [...existing.items, ...items],
  }
}

const stationsKeyArgs = ({ input }) => {
  const { group } = input
  if (group) {
    const key = slugify(`${group.groupBy}-${group.currentGroup}`)
    return `stations:${key}`
  }
  return false
}

const typePolicy = {
  fields: {
    pricingTypes: {
      merge: true,
    },
    stationsList: {
      merge: true,
    },
    stations: {
      keyArgs: stationsKeyArgs,
      merge: stationsMerge,
    },
    stationGroups: {
      keyArgs: false,
    },
    notifications: {
      merge: false,
    },
  },
}

export { typePolicy, stationsMerge, stationsKeyArgs }
