import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly } from 'utils/format'
import { GradeDropdown } from '../../../performance-report/components/grade-dropdown'
import { StationSelector } from '../../../performance-report/components/station-selector'
import { GroupBy } from '../groups-dropdown'
import { RegionsDropdown } from '../regions-dropdown'
import { TimeResolutionDropdown } from '../time-resolution-dropdown'
import { PeriodDropdown } from '../period-dropdown'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { showRegionsDropdown } from 'utils/helpers'
import { useAuth } from 'config/context/authContext'
import styles from './Filters.module.scss'


const cx = classNames.bind(styles)

export const PORTFOLIO_BENCHMARK = 'PORTFOLIO_BENCHMARK'
export const PERIOD_ANALYSIS = 'PERIOD_ANALYSIS'
export const ITEMS_LIMIT = 3

const Filters = ({
  activeTab,
  stationIds,
  setSelectedStation,
  fuelGradesItems,
  fuelGrades,
  setSelectedGrades,
  isEdgeDataPro
}) => {
  const { t } = useTranslation(['translations'])
  const { showRegionDropdown } = useFlags()
  const { userLocale } = useAuth()

  return (
    <div className={cx('select-boxes')}>
      { !isEdgeDataPro &&
        <StationSelector
          value={stationIds}
          onValueChange={setSelectedStation}
          placeholder={upperFirstOnly(
            t('widgets.performanceReport.noStationsSelected')
          )}
          allStationsText={upperFirstOnly(
            t('widgets.performanceReport.allStationsSelected')
          )}
          maxHeight="228px"
          selectButtons={activeTab === PERIOD_ANALYSIS ? false : true}
          itemsLimit={activeTab === PERIOD_ANALYSIS ? 3 : null}
          activeTab={activeTab}
        />
      }
      {showRegionsDropdown(showRegionDropdown, userLocale) && activeTab === PORTFOLIO_BENCHMARK && <RegionsDropdown />}
      {/* {activeTab === PERIOD_ANALYSIS && <GroupBy activeTab={activeTab} />} */}
      <GradeDropdown
        options={fuelGradesItems}
        value={fuelGrades}
        onValueChange={setSelectedGrades}
        enableIcon={false}
      />
      <PeriodDropdown activeTab={activeTab} />
      <TimeResolutionDropdown activeTab={activeTab} />
    </div>
  )
}

Filters.propTypes = {
  activeTab: PropTypes.string,
  setSelectedStation: PropTypes.func,
  stationIds: PropTypes.arrayOf(PropTypes.string),
  fuelGradesItems: PropTypes.arrayOf(PropTypes.string),
  fuelGrades: PropTypes.arrayOf(PropTypes.string),
  setSelectedGrades: PropTypes.func,
  isEdgeDataPro: PropTypes.bool,
}

Filters.defaultProps = {
  fuelGradesItems: [],
  fuelGrades: [],
}

export { Filters }
