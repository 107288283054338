import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const loadPage = (nextPage, fetchPage) => {
  fetchPage({
    variables: {
      number: nextPage,
    },
  }).catch(error => {
    // TODO: this is a known issue with unmounting during a fetchMore call (https://github.com/apollographql/apollo-client/issues/4114#issuecomment-502111099)
    /* istanbul ignore next */
    if (error.name !== 'Invariant Violation') {
      throw error
    }
  })
}

/**
 * Hook for handling the pagination of the stations list. If the pagination ref is intersected loads the next page of data.
 */
function useStationsListPagination(fetchPage, data) {
  const [ref, inView] = useInView()

  const nextPageNumber = data?.stations?.pageInfo?.nextPageNumber

  useEffect(() => {
    if (inView && nextPageNumber) {
      loadPage(nextPageNumber, fetchPage)
    }
  }, [inView, nextPageNumber, fetchPage])

  return [ref]
}

export { useStationsListPagination }
