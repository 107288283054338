import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { AllStationsCheckbox } from 'components/stations-list/components/stations-table/components/edit-pricing/all-stations-checkbox'
import { TOTALS_METRIC_ID } from 'components/stations-list/utils/constants'
import { MetricCells } from '../metric-cells'
import styles from './TotalRow.module.scss'

const cx = classNames.bind(styles)

const TotalRow = ({
  items,
  showPricingView,
  pricingStationIds,
  isSelected,
}) => {
  const { t } = useTranslation()
  const totalMetrics = items.find(({ id }) => id === TOTALS_METRIC_ID)?.metrics

  if (!totalMetrics) {
    return null
  }

  return (
    <div className={cx('total-row', { 'edit-pricing': showPricingView })}>
      <div className={cx('total-cell')}>
        <AllStationsCheckbox
          allSelected={isSelected}
          allStationIds={pricingStationIds}
          showPricingView={showPricingView}
        />
        <span>{t('widgets.stationsList.total')}</span>
      </div>
      <MetricCells metrics={totalMetrics} showPricingView={showPricingView} />
    </div>
  )
}

TotalRow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showPricingView: PropTypes.bool.isRequired,
  pricingStationIds: PropTypes.arrayOf(PropTypes.string),
  isSelected: PropTypes.bool,
}

TotalRow.defaultProps = {
  pricingStationIds: null,
  isSelected: false,
}

export { TotalRow }
