const UserEvents = {
  GroupEditPricing: {
    OPENED: 'Group edit pricing opened',
    COMPLETED: 'Group edit pricing completed',
    SLIDE_MOVED: 'Group edit pricing slide moved',
    STATION_CLICKED: 'Group edit pricing station clicked',
  },
  EditPricing: {
    OPENED: 'Edit pricing opened',
    COMPLETED: 'Edit pricing completed',
    MANUAL_INPUT_USED: 'Edit pricing manual input used',
  },
  PerformanceReportCSVExport: {
    CLICKED: 'Performance report CSV export clicked',
  },
  Search: {
    STRING_ENTERED: 'Search string entered',
    STATION_SELECTED: 'Search station selected',
    CLICKED: 'Search clicked',
  },
  GroupedBy: {
    SELECTED: group => `Grouped by ${group} selected`,
  },
  FilterBy: {
    SELECTED: filter => `Filter by ${filter} selected`,
  },
  BuySmartDay: {
    CLICKED: day => `BuySmart day ${day} clicked`,
  },
  StationPerformanceTab: {
    CLICKED: (isOverlayMode, tab) =>
      `${
        isOverlayMode ? 'POP-OVER PAGE ' : ''
      }Station Performance tab ${tab} clicked`,
  },
  Notifications: {
    OPENED: 'Notifications opened',
    CLEARED: 'Notifications cleared',
    HYPERLINK: 'Notifications hyperlink clicked',
    DROPDOWN: 'Notifications dropdown clicked',
  },
  Settings: {
    SUCCESS_CENTER: 'Settings success center clicked',
    CHAT: 'Settings chat clicked',
  },
  PerformanceReport: {
    STATIONS_FILTER_SELECTED: page =>
      `Performance report ${page} page stations filter selected`,
    GRADES_FILTER_SELECTED: page =>
      `Performance report ${page} page grades filter selected`,
    TIME_PERIOD_SELECTED: page =>
      `Performance report ${page} page time period selected`,
    VOLUME_TAB: (page, metric) =>
      `Performance report ${page} page volume tab ${metric} selected`,
    MARGIN_TAB: (page, metric) =>
      `Performance report ${page} page margin tab ${metric} selected`,
    PROFIT_TAB: (page, metric) =>
      `Performance report ${page} page profit tab ${metric} selected`,
  },
  StationsListTable: (metricSort, direction) =>
    `Stations list table sort ${metricSort}: ${direction}`,
  StationStatusIndicator: {
    DIP: `Station status liveness indicator hovered: dip`,
    COST_PRICE: `Station status liveness indicator hovered: cost price`,
    TRANSACTION: `Station status liveness indicator hovered: transaction`,
    SINGLE_STATION: `Single Station status liveness indicator hovered`,
  },
  Sidebar: {
    performance: 'Sidebar Performance clicked',
    stations: 'Sidebar Stations clicked',
    analytics: 'Sidebar Analytics clicked',
    INTERCOM: 'Sidebar Intercom icon clicked',
  },
  StationPerformanceFuelGrade: {
    SELECTED: (isOverlayMode, fuelGrade) =>
      `${
        isOverlayMode ? 'POP-OVER PAGE ' : ''
      }Station performance grade: ${fuelGrade} selected`,
  },
  TargetMargin: {
    NEW_TARGET_MARGIN_SAVED: isOverlayMode =>
      `${isOverlayMode ? 'POP-OVER PAGE ' : ''}New target margin saved`,
    OPENED: isOverlayMode =>
      `${isOverlayMode ? 'POP-OVER PAGE ' : ''}Target margin opened`,
  },
  CompetitorPricing: {
    FAVOURITED: `Competitor station favourited`,
    DATE: `Competitor date clicked`,
    MATCH: `Competitor price match clicked`,
    UPDATED: `Competitor price updated`,
    PRICING_STRATEGY_ENTERED: `Competitor Pricing strategy entered`,
    PRICING_STRATEGY_SAVED: 'Competitor Pricing strategy saved',
  },
  PricingPopoverPage: {
    PREVIOUS: 'POP-OVER PAGE previous navigation clicked',
    NEXT: 'POP-OVER PAGE next navigation clicked',
  },
}

export { UserEvents }
