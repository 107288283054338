import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { ActionAtSelector } from 'components/common/price-change-footer/components/action-at-selector'
import { Reminders } from 'components/common/price-change-footer/components/reminders'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { PriceChangeTimeout } from 'utils/pricing/constants'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'
import classNames from 'classnames/bind'
import { StatusIndicator } from './components/status-indicator'
import { ProgressBar } from './components/progress-bar'
import { Cover } from './components/cover'
import styles from './PriceChangeFooter.module.scss'
import { MarginInfo } from 'components/stations-list/components/stations-table/components/edit-pricing/margin-info'

const cx = classNames.bind(styles)

const { SEND, SENT, ERROR } = StationsListPricingStatus

const PriceChangeFooter = ({
  status,
  stationChangeRequests,
  reminders,
  actionAt,
  createPolePriceChangeRequests,
}) => {
  const { t } = useTranslation()
  const { stationsListEstimatedMargin } = useFlags()

  const {
    createPolePriceChangeRequestsMutation,
    loading,
    called,
  } = createPolePriceChangeRequests

  const handleReminderToggle = reminder => {
    /* istanbul ignore next */
    if (!loading) {
      stationsListPricingOperations.toggleReminder(reminder)
    }
  }

  const handleConfirmClick = (selectedPeriod, selectedDate, selectedTime) => {
    stationsListPricingOperations.setActionAt(
      selectedPeriod,
      selectedDate,
      selectedTime
    )
  }

  useEffect(() => {
    let timer

    if (status === SEND && !called) {
      timer = setTimeout(() => {
        createPolePriceChangeRequestsMutation()
      }, PriceChangeTimeout)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [status, called, createPolePriceChangeRequestsMutation])

  const pricesSent = [SENT, ERROR].includes(status)

  const { selectedPeriod, selectedDate, selectedTime } = actionAt

  return (
    <>
      <div className={cx('price-change-footer', { 'prices-sent': pricesSent })}>
        <ProgressBar status={status} />
        <Cover status={status} />
        {!pricesSent && (
          <div className={cx('main-content')}>
            <ActionAtSelector
              selectedPeriod={selectedPeriod}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              onConfirmClick={handleConfirmClick}
            />
            <Reminders
              reminders={reminders}
              onReminderToggle={handleReminderToggle}
            />
          </div>
        )}
        <div className={cx('secondary-content')}>
          <div className={cx('stations')}>
            <span>{t('widgets.stationsListPricing.stationsSelected')}</span>
            <span className={cx('stations-count')}>
              {stationChangeRequests?.length}
            </span>
          </div>

          <div className={cx('margin-info-container')}>
            {stationsListEstimatedMargin && (
              <div className={cx('margin-info')}>
                <MarginInfo />
              </div>
            )}
          </div>

          {stationChangeRequests?.length > 0 && (
            <StatusIndicator loading={loading} status={status} />
          )}
        </div>
      </div>
    </>
  )
}

PriceChangeFooter.propTypes = {
  status: PropTypes.string.isRequired,
  stationChangeRequests: PropTypes.arrayOf(PropTypes.shape()),
  reminders: PropTypes.shape({
    oneHourAfter: PropTypes.bool.isRequired,
    threeHoursAfter: PropTypes.bool.isRequired,
  }).isRequired,
  actionAt: PropTypes.shape({
    selectedPeriod: PropTypes.number.isRequired,
    selectedDate: PropTypes.shape(),
    selectedTime: PropTypes.string,
  }).isRequired,
  createPolePriceChangeRequests: PropTypes.shape({
    createPolePriceChangeRequestsMutation: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    called: PropTypes.bool.isRequired,
  }).isRequired,
}

PriceChangeFooter.defaultProps = {
  stationChangeRequests: null,
}

export { PriceChangeFooter }
