import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import classNames from 'classnames/bind'
import { STATIONS_LIST_MAIN_QUERY } from './StationsListQueries'
import { StationsTable } from './components/stations-table'
import styles from './StationsList.module.scss'

const cx = classNames.bind(styles)

const StationsList = () => {
  const [timestamp] = useState(new Date().toISOString())
  const { loading, error, data } = useQuery(STATIONS_LIST_MAIN_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  useErrorHandler(error)

  return (
    <div className={cx('stations-list')}>
      <StationsTable
        timestamp={timestamp}
        mainLoading={loading}
        mainData={data}
      />
    </div>
  )
}

const FallbackComponent = () => <WidgetFallback customClass={cx('fallback')} />

const StationsListWithErrorBoundary = withEdgeErrorBoundary(
  StationsList,
  FallbackComponent
)

export { StationsListWithErrorBoundary as StationsList }
