import { useTranslation } from 'react-i18next'
import { isNullOrUndefined } from 'utils/helpers'
import { Locales } from 'utils/constants'

function useFormatPriceChange() {
  const { i18n } = useTranslation()

  function formatPriceChange(value, fallback = null) {
    if (isNullOrUndefined(value) || value === 0) {
      return fallback
    }

    if (i18n.language === Locales.EN_US) {
      return value >= 0
        ? `+${(value / 100).toFixed(2)}`
        : `${(value / 100).toFixed(2)}`
    }

    return value >= 0 ? `+${value}` : `${value}`
  }

  return { formatPriceChange }
}

export { useFormatPriceChange }
