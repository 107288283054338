import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as SubmitIcon } from 'images/pole-prices/submit.svg'
import { ReactComponent as ConfirmIcon } from 'images/pole-prices/confirm.svg'
import { ReactComponent as SentIcon } from 'images/pole-prices/sent.svg'
import { ReactComponent as ErrorIcon } from 'images/pole-prices/modal-error.svg'
import { ReactComponent as LoadingIcon } from 'images/common/loading.svg'
import { ReactComponent as CancelIcon } from 'images/common/cancel.svg'
import { usePriceChange } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import {
  PriceChangeStatus,
  PriceChangeActions,
} from 'components/station/components/competitor-pricing/context/constants'
import classNames from 'classnames/bind'
import { SentModal } from '../sent-modal'
import { ErrorModal } from '../error-modal'
import styles from './StatusIndicator.module.scss'
import { useTranslation } from 'react-i18next'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const StatusIndicator = ({ loading, setDateTimeSelectorInUse }) => {
  const [{ status, pendingRequests }, dispatch] = usePriceChange()
  const [showModal, setShowModal] = useState(true)
  const { t } = useTranslation(['translations'])

  const handleConfirm = () => {
    dispatch({
      type: PriceChangeActions.SetCurrentStatus,
      payload: { status: PriceChangeStatus.Confirm },
    })
  }

  const handleSubmit = () => {
    dispatch({
      type: PriceChangeActions.SetCurrentStatus,
      payload: { status: PriceChangeStatus.Send },
    })
  }

  const handleCancel = () => {
    if (!loading) {
      dispatch({
        type: PriceChangeActions.SetCurrentStatus,
        payload: { status: PriceChangeStatus.Edit },
      })
    }
  }

  const handleHide = () => {
    amplitudeEvent(UserEvents.EditPricing.COMPLETED)
    dispatch({
      type: PriceChangeActions.SetCurrentStatus,
      payload: { status: PriceChangeStatus.None },
    })
    setDateTimeSelectorInUse(false)
    setShowModal(false)
  }

  switch (status) {
    case PriceChangeStatus.Edit:
      const requestsPending = pendingRequests.some(
        ({ price, newPrice }) => price !== newPrice
      )
      if (requestsPending) {
        return (
          <ConfirmIcon
            className={cx('submit-icon')}
            onClick={handleConfirm}
            data-testid="confirm-icon"
          />
        )
      }
      return null
    case PriceChangeStatus.Confirm:
      return (
        <SubmitIcon
          className={cx('submit-icon')}
          onClick={handleSubmit}
          data-testid="submit-icon"
        />
      )
    case PriceChangeStatus.Send:
      return (
        <>
          <div className={cx('sending-text')}>
            {t('widgets.competitorPricing.sendingPolePriceChanges')}
          </div>
          <div
            className={cx('sending-icon-wrapper', { loading })}
            onClick={handleCancel}
            data-testid="sending-icon-wrapper"
          >
            <LoadingIcon height={50} width={50} />
            <CancelIcon className={cx('cancel-icon')} />
          </div>
        </>
      )
    case PriceChangeStatus.Sent:
      return (
        <>
          <div className={cx('sent-text')}>
            {t('widgets.competitorPricing.polePriceChangesSent')}
          </div>

          <SentIcon className={cx('sent-icon')} />
          <SentModal show={showModal} onHide={handleHide} />
        </>
      )
    case PriceChangeStatus.Error:
      return (
        <>
          <div className={cx('error-text')}>
            {t('widgets.competitorPricing.priceChangesErrors')}
          </div>
          <ErrorIcon />
          <ErrorModal show={showModal} onHide={handleHide} />
        </>
      )
    default:
      return null
  }
}

StatusIndicator.propTypes = {
  loading: PropTypes.bool.isRequired,
  setDateTimeSelectorInUse: PropTypes.func.isRequired,
}

export { StatusIndicator }
