import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { Calendar } from 'components/common/calendar'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly } from 'utils/format'
import classNames from 'classnames/bind'
import { Period } from './components/period'
import styles from './PeriodSelector.module.scss'
import Tooltip from 'react-bootstrap/Tooltip'
import Overlay from 'react-bootstrap/Overlay'

const cx = classNames.bind(styles)

const PeriodSelector = ({
  onClose,
  currValue,
  companyTimeZone,
  calendar_confirm_button,
  multi,
  prevValue,
  maxDate,
  maxDays
}) => {
  const { t } = useTranslation(['translations'])
  const [currPeriod, setCurrPeriod] = useState(currValue)
  const [prevPeriod, setPrevPeriod] = useState(prevValue)
  const [showTooltip, setShowTooltip] = useState(false)
  const buttonRef = useRef(null)

  useEffect(() => {
    setCurrPeriod(currValue)
    setPrevPeriod(prevValue)
  }, [currValue, prevValue])

  const handleButtonClick = (event) => {
    if (onClose) {
      multi ? onClose(currPeriod, prevPeriod) : onClose(currPeriod)
    }
  }

  const handleButtonMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleButtonMouseLeave = () => {
    setShowTooltip(false)
  }

  const calendarMaxDate = maxDate || moment.utc()
  const isPrevGreaterThanCurr = multi && (prevPeriod.from > currPeriod.from)

  const handleTooltipClick = () => {
    setShowTooltip(null)
  }

  return (
    <div className={cx('calendars')}>
      {multi && (
        <div data-testid="period-selector" className={cx('calendar')}>
          <Calendar
            maxDate={calendarMaxDate}
            value={prevPeriod}
            onValueChange={setPrevPeriod}
            multiSelect
            timeZone={companyTimeZone}
            maxDays={maxDays}
            header={multi ? 'Previous period' : null}
          />
          <div className={cx('footer')}>
            <Period period={prevPeriod} companyTimeZone={companyTimeZone} />
          </div>
        </div>
      )}
      <div data-testid="period-selector">
        <Calendar
          maxDate={calendarMaxDate}
          value={currPeriod}
          onValueChange={setCurrPeriod}
          multiSelect
          timeZone={companyTimeZone}
          maxDays={maxDays}
          header={multi ? 'Current period' : null}
        />
        <div className={cx('footer')}>
          <Period period={currPeriod} companyTimeZone={companyTimeZone} />
          {calendar_confirm_button && (
            <>
              <div
                onMouseEnter={handleButtonMouseEnter}
                onMouseLeave={handleButtonMouseLeave}
              >
                <Button
                  className={cx('confirm-button')}
                  onClick={handleButtonClick}
                  data-testid="confirm"
                  disabled={isPrevGreaterThanCurr}
                  ref={buttonRef}
                >
                  {upperFirstOnly(t('widgets.performanceReport.confirm'))}
                </Button>
              </div>
              <Overlay show={multi && isPrevGreaterThanCurr && showTooltip} target={buttonRef.current} position="top">
                <Tooltip className={cx('tooltip')} onClick={handleTooltipClick}>
                  <div className={cx('tooltip-content')} data-testid="tooltip">
                    {t('widgets.analytics.calendarWarning')}
                  </div>
                </Tooltip>
              </Overlay>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

PeriodSelector.propTypes = {
  onClose: PropTypes.func,
  companyTimeZone: PropTypes.string.isRequired,
  calendar_confirm_button: PropTypes.bool,
  prevValue: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
  currValue: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
  maxDays: PropTypes.number,
  maxDate: PropTypes.instanceOf(moment)
}

PeriodSelector.defaultProps = {
  onClose: null,
  multi: false,
  prevValue: null,
  currValue: null,
  calendar_confirm_button: true,
  maxDate: null
}

export { PeriodSelector }
