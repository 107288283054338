import { Locales } from 'utils/constants'
import { enAuth } from './enAuth'
import { translations as en } from './hooks/translations/en'
import { translations as US } from './hooks/translations/en-US'
import { translations as GB } from './hooks/translations/en-GB'
import { translations as IE } from './hooks/translations/en-IE'

const { EN, EN_US, EN_IE, EN_GB } = Locales

const resources = {
  [EN]: {
    features: {
      cashCreditPricing: false,
    },
    auth: enAuth,
    headTitle: {
      portfolio: 'Portfolio',
    },
    translations: en,
  },

  [EN_GB]: {
    features: {
      cashCreditPricing: false,
    },
    auth: enAuth,
    headTitle: {
      portfolio: 'Portfolio',
    },
    translations: GB,
  },

  [EN_US]: {
    features: {
      cashCreditPricing: true,
    },
    auth: enAuth,
    headTitle: {
      portfolio: 'Portfolio',
    },
    translations: US,
  },

  [EN_IE]: {
    features: {
      cashCreditPricing: false,
    },
    auth: enAuth,
    headTitle: {
      portfolio: 'Portfolio',
    },
    translations: IE,
  },
}

export default resources
