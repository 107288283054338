import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ErrorIcon } from 'images/toast/error.svg'
import { ReactComponent as CloseIcon } from 'images/toast/close.svg'

function useShowToast() {
  const { t } = useTranslation()

  const showErrorToast = () => {
    toast.error(t('widgets.toast.genericError'), {
      position: toast.POSITION.BOTTOM_RIGHT,
      hideProgressBar: true,
      icon: <ErrorIcon height={34} width={30} data-testid="error-icon" />,
      closeButton: ({ closeToast }) => (
        <CloseIcon height={20} width={20} onClick={closeToast} />
      ),
    })
  }

  return { showErrorToast }
}

export { useShowToast }
