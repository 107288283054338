import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { cache } from 'config/apollo/cache'
import { HashLink } from 'react-router-hash-link'
import { HumanizedDate } from 'components/common/humanized-date'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { useClearNotification } from '../../hooks/useClearNotification'
import { ReactComponent as ExceptionalVolumeDownIcon } from 'images/notifications/exceptional-volume-down.svg'
import { ReactComponent as ExceptionalVolumeUpIcon } from 'images/notifications/exceptional-volume-up.svg'
import { ReactComponent as LowTankVolumeIcon } from 'images/notifications/low-tank-volume.svg'
import { ReactComponent as StockoutIcon } from 'images/notifications/stockout.svg'
import { ReactComponent as ChevronIcon } from 'images/notifications/chevron.svg'
import { ReactComponent as CloseIcon } from 'images/notifications/close.svg'
import { slugify } from 'utils/slug'
import { NotificationTypes, WidgetIds } from 'utils/constants'
import classNames from 'classnames/bind'
import styles from './Notification.module.scss'

const {
  LOW_TANK_VOLUME,
  PREDICTED_STOCKOUT,
  EXCEPTIONAL_VOLUME_DOWN,
  EXCEPTIONAL_VOLUME_UP,
} = NotificationTypes

const cx = classNames.bind(styles)

const scrollWithOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
  const yOffset = -200
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
}

const getNotificationText = (
  type,
  notification,
  stations,
  onClick,
  isSingleSite
) => {
  const { stationId, text } = notification
  const stationNodeRegex = new RegExp(/(<station.*<\/station>)/, 'g')
  const parts = text.split(stationNodeRegex)

  const handleClick = () => {
    amplitudeEvent(UserEvents.Notifications.HYPERLINK)
    onClick()
  }

  return (
    <div>
      {parts.map(part => {
        if (part.match(stationNodeRegex)) {
          const stationTextRegex = new RegExp(/<station.*>(.*?)<\/station/)
          const stationText = stationTextRegex.exec(part)[1]

          if (isSingleSite) {
            return stationText
          }

          const link = `/stations/${slugify(
            stations.find(station => station.id === stationId)?.name
          )}${getHashForType(type)}`

          return (
            <HashLink
              data-testid="notification-hyperlink"
              key={stationId}
              to={link}
              scroll={scrollWithOffset}
              onClick={handleClick}
            >
              {stationText}
            </HashLink>
          )
        }

        return part
      })}
    </div>
  )
}

const getHashForType = type => {
  switch (type) {
    case LOW_TANK_VOLUME:
    case PREDICTED_STOCKOUT:
      return `#${WidgetIds.BUYSMART}`
    default:
      return ''
  }
}

const getIconForType = type => {
  switch (type) {
    case LOW_TANK_VOLUME:
      return <LowTankVolumeIcon />
    case EXCEPTIONAL_VOLUME_UP:
      return <ExceptionalVolumeUpIcon />
    case EXCEPTIONAL_VOLUME_DOWN:
      return <ExceptionalVolumeDownIcon />
    case PREDICTED_STOCKOUT:
      return <StockoutIcon />
    default:
      return null
  }
}

const Notification = ({ stations, notification, onClick, isSingleSite }) => {
  const [clearNotification] = useClearNotification()
  const [collapsed, setCollapsed] = useState(false)

  const handleGroupHeadClick = () => {
    amplitudeEvent(UserEvents.Notifications.DROPDOWN)
    setCollapsed(!collapsed)
  }

  const handleClickClear = async (parent, notification) => {
    const { data } = await clearNotification({
      variables: {
        id: notification.id,
      },
    })
    if (!data?.clearNotification?.error) {
      cache.modify({
        id: cache.identify(parent),
        fields: {
          count() {
            return parent.notifications.length - 1
          },
          notifications(notifications, { readField }) {
            return notifications.filter(
              notificationRef =>
                readField('id', notification) !==
                readField('id', notificationRef)
            )
          },
          createdAt(_, { readField }) {
            return readField('notifications', parent).filter(
              notificationRef =>
                readField('id', notification) !==
                readField('id', notificationRef)
            )[0]?.createdAt
          },
        },
      })
      cache.modify({
        fields: {
          notificationsCount(notificationsCount) {
            return {
              ...notificationsCount,
              count: notificationsCount.count - 1,
            }
          },
        },
      })
      cache.gc()

      if (parent.notifications.length === 1) {
        const id = cache.identify(parent)
        cache.evict({ id })
        cache.gc()
      }
    }
  }

  if (notification.count === 1) {
    return (
      <div className={cx('container')}>
        <button
          className={cx('close')}
          onClick={() =>
            handleClickClear(notification, notification.notifications[0])
          }
        >
          <CloseIcon />
        </button>
        {getIconForType(notification.type)}
        <div className={cx('content')}>
          <div className={cx('title')}>{notification.title}</div>
          <div className={cx('timestamp')}>
            <HumanizedDate date={notification.createdAt} />
          </div>
          <div className={cx('body')}>
            {getNotificationText(
              notification.type,
              notification.notifications[0],
              stations,
              onClick,
              isSingleSite
            )}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <div
          className={cx('container', 'group-head', { collapsed })}
          onClick={handleGroupHeadClick}
        >
          {getIconForType(notification.type)}
          <div className={cx('content')}>
            <div className={cx('title')}>
              {notification.title}
              <span className={cx('group-count')}>
                {notification.notifications.length}
              </span>
            </div>
            <div className={cx('timestamp')}>
              <HumanizedDate date={notification.createdAt} />
            </div>
          </div>
          <ChevronIcon
            className={cx('chevron')}
            data-testid="dropdown-chevron"
          />
        </div>
        {!collapsed &&
          notification.notifications.map(n => (
            <div className={cx('container', 'group-child')} key={n.id}>
              <button
                className={cx('close')}
                onClick={() => handleClickClear(notification, n)}
              >
                <CloseIcon />
              </button>
              {getIconForType(notification.type)}
              <div className={cx('content')}>
                <div className={cx('title')}>{notification.title}</div>
                <div className={cx('timestamp')}>
                  <HumanizedDate date={n.createdAt} />
                </div>
                <div className={cx('body')}>
                  {getNotificationText(
                    notification.type,
                    n,
                    stations,
                    onClick,
                    isSingleSite
                  )}
                </div>
              </div>
            </div>
          ))}
      </>
    )
  }
}

Notification.propTypes = {
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  notification: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  isSingleSite: PropTypes.bool.isRequired,
}

export { Notification }
