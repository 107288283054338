import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { selectedStationIdsVar } from 'config/apollo/stations-list-pricing/variables'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { ReactComponent as ConfirmInvalidIcon } from 'images/pole-prices/confirm-invalid.svg'
import { ReactComponent as ConfirmIcon } from 'images/pole-prices/confirm.svg'
import { FuelGradePrices } from './components/fuel-grade-prices'
import { MarginInfo } from '../margin-info'
import classNames from 'classnames/bind'
import styles from './EditPricingFooter.module.scss'

const cx = classNames.bind(styles)

const EditPricingFooter = ({ width, fuelGrades }) => {
  const { t } = useTranslation()
  const selectedStationIds = useReactiveVar(selectedStationIdsVar)

  const hasSelectedStations = selectedStationIds.length > 0

  const handleIncrement = useCallback(fuelGradeId => {
    stationsListPricingOperations.incrementFuelGradePriceChange(fuelGradeId)
  }, [])

  const handleDecrement = useCallback(fuelGradeId => {
    stationsListPricingOperations.decrementFuelGradePriceChange(fuelGradeId)
  }, [])

  const { stationsListEstimatedMargin } = useFlags()

  const handleClick = () => {
    if (hasSelectedStations) {
      stationsListPricingOperations.setStatus(StationsListPricingStatus.CONFIRM)
    }
  }

  return (
    <div className={cx('edit-pricing-footer')}>
      <div className={cx({ 'estimated-margin': stationsListEstimatedMargin })}>
        <div>{t('widgets.stationsListPricing.stationsSelected')}</div>
        <div className={cx('value')}>{selectedStationIds.length}</div>
      </div>

      {stationsListEstimatedMargin && (
        <div className={cx('footer-margin')}>
          <MarginInfo />
        </div>
      )}

      <div className={cx('fuel-grade-prices')} style={{ width }}>
        <FuelGradePrices
          fuelGrades={fuelGrades}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
        />
      </div>
      <div
        className={cx('confirm', { invalid: !hasSelectedStations })}
        data-testid="confirm"
        onClick={handleClick}
      >
        {!hasSelectedStations ? <ConfirmInvalidIcon /> : <ConfirmIcon />}
      </div>
    </div>
  )
}

EditPricingFooter.propTypes = {
  width: PropTypes.number,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

EditPricingFooter.defaultProps = {
  width: null,
}

export { EditPricingFooter }
