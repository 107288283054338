import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as PricesErrorIcon } from 'images/pole-prices/modal-error.svg'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames/bind'
import styles from './ErrorModal.module.scss'

const cx = classNames.bind(styles)

const ErrorModal = ({ show, onHide }) => {
  const { t } = useTranslation()

  return (
    <Modal
      className={cx('modal')}
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      centered
      backdrop="static"
    >
      <Modal.Body>
        <PricesErrorIcon className={cx('icon')} height={42} width={42} />
        <div className={cx('body')}>
          <h4>{t('widgets.stationsListPricing.pricesErrorTitle')}</h4>
          <p>{t('widgets.stationsListPricing.pricesErrorBody')}</p>
        </div>
        <Button className={cx('button')} onClick={onHide}>
          {t('widgets.stationsListPricing.pricesErrorConfirm')}
        </Button>
      </Modal.Body>
    </Modal>
  )
}

ErrorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export { ErrorModal }
