import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import classNames from 'classnames/bind'
import styles from './ChangeRequestError.module.scss'

const cx = classNames.bind(styles)

const ChangeRequestError = ({ pendingRequest }) => {
  const { t } = useTranslation()
  const { formatPolePrice } = useFormatPolePrice()

  return (
    <div className={cx('change-request-error')}>
      <div>{formatPolePrice(pendingRequest?.newPrice)}</div>
      <div>{pendingRequest && t('widgets.stationsListPricing.cantSend')}</div>
    </div>
  )
}

ChangeRequestError.propTypes = {
  pendingRequest: PropTypes.shape(),
}

ChangeRequestError.defaultProps = {
  pendingRequest: null,
}

export { ChangeRequestError }
