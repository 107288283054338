import { useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useShowToast } from 'components/common/hooks/useShowToast'

const UPDATE_TARGET_MARGIN_MUTATION = gql`
  mutation updateTargetMarginMutation($stationId: ID!, $target: Float) {
    updateTargetMargin(input: { stationId: $stationId, target: $target }) {
      error
      targetMargin {
        id
        target
      }
    }
  }
`

function useUpdateTargetMargin(onSuccess) {
  const { showErrorToast } = useShowToast()

  const onCompleted = ({ updateTargetMargin: { error } }) => {
    if (error) {
      showErrorToast()
    } else {
      onSuccess()
    }
  }

  const onError = error => {
    Sentry.captureException(error)
    showErrorToast()
  }

  const [updateTargetMargin, updateResult] = useMutation(
    UPDATE_TARGET_MARGIN_MUTATION,
    {
      onCompleted,
      onError,
    }
  )
  const [deleteTargetMarginMutation, deleteResult] = useMutation(
    UPDATE_TARGET_MARGIN_MUTATION,
    {
      onCompleted,
      onError,
    }
  )

  /**
   * Wrapper for the mutation to ensure it can only be used to delete.
   */
  const deleteTargetMargin = async ({ variables: { stationId } }) => {
    await deleteTargetMarginMutation({
      variables: { stationId, target: null },
    })
  }

  return [updateTargetMargin, updateResult, deleteTargetMargin, deleteResult]
}

export { useUpdateTargetMargin, UPDATE_TARGET_MARGIN_MUTATION }
