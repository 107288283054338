import { makeVar } from '@apollo/client'

const stationsVar = makeVar({
  'PORTFOLIO_BENCHMARK': [],
  'PERIOD_ANALYSIS': [],
  'COMPETITOR_ANALYSIS': [],
})

const fuelGradesVar = makeVar({
  'PORTFOLIO_BENCHMARK': [],
  'PERIOD_ANALYSIS': [],
  'COMPETITOR_ANALYSIS': [],
})

const currentPeriodDateVar = makeVar({
  'PORTFOLIO_BENCHMARK': { from: null, to: null },
  'PERIOD_ANALYSIS': { from: null, to: null },
  'COMPETITOR_ANALYSIS': { from: null, to: null },
})
const previousPeriodDateVar = makeVar({
  'PORTFOLIO_BENCHMARK': { from: null, to: null },
  'PERIOD_ANALYSIS': { from: null, to: null },
  'COMPETITOR_ANALYSIS': { from: null, to: null },
})

const metricVar = makeVar({
  'PORTFOLIO_BENCHMARK': 'retail_fuel_volume_sales_for_computing_margin',
  'PERIOD_ANALYSIS': 'retail_fuel_volume_sales_for_computing_margin',
  'COMPETITOR_ANALYSIS': 'pole_price',
})

const timeResolutionVar = makeVar({
  'PORTFOLIO_BENCHMARK': 'Daily',
  'PERIOD_ANALYSIS': 'Daily',
  'COMPETITOR_ANALYSIS': 'Daily',
})

const groupByVar = makeVar({
  'PORTFOLIO_BENCHMARK': [],
  'PERIOD_ANALYSIS': [],
  'COMPETITOR_ANALYSIS': [],
})

const regionsVar = makeVar([
  'East Midlands',
  'East of England',
  'London',
  'North East',
  'North West',
  'South East',
  'South West',
  'West Midlands',
  'Yorkshire and The Humber',
  'Scotland',
  'Wales'
])
const activeTabVar = makeVar('PERIOD_ANALYSIS')

export {
  stationsVar,
  fuelGradesVar,
  currentPeriodDateVar,
  previousPeriodDateVar,
  metricVar,
  timeResolutionVar,
  groupByVar,
  activeTabVar,
  regionsVar,
}
