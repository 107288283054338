import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'

/**
 * Sets the current user in Sentry when the AUTH_CONTEXT_QUERY has returned with the user's details.
 */
function useSentry(data) {
  useEffect(() => {
    if (data) {
      const {
        me: { email },
      } = data
      Sentry.setUser({ email })
    }
  }, [data])

  const sentryLogout = () => {
    Sentry.configureScope(scope => scope.setUser(null))
  }

  return { sentryLogout }
}

export { useSentry }
