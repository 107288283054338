import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useReactiveVar } from '@apollo/client'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames/bind'
import { Calendar } from 'components/common/calendar'
import { Dropdown } from 'components/common/dropdown'
import { useTranslation } from 'react-i18next'
import { timestampVar } from 'config/apollo/competitor-pricing/variables'
import { useFormatTimestamp } from 'components/common/hooks/useFormatTimestamp'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { usePriceChange } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { calendarDaysDifference, isToday } from 'utils/dateTime'
import { TimestampTypes, Locales } from 'utils/constants'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import { ReactComponent as ClockIcon } from 'images/competitor-pricing/clock.svg'
import { Arrows } from './components/arrows'
import { DownArrow } from './components/down-arrow'
import styles from './DateTimeSelector.module.scss'

const { TIME, DAY_MONTH_YEAR } = TimestampTypes

const cx = classNames.bind(styles)

/**
 * @returns an array of objects:
 * - time: the time in the company time zone (between 00:00 and 23:00 in hourly increments). Formatted according the locale of the user.
 * - dateTimeString: UTC ISO date time value for that time.
 */
const intervalsWithTimeAndUTCISOString = (
  date,
  companyTimeZone,
  locale,
  now
) => {
  const dateTimeFormat = 'YYYY-MM-DDTHH:mm:sssZ'

  const intervalsFromMidnight = isToday(companyTimeZone, date, now)
    ? moment(now)
        .tz(companyTimeZone)
        .hours() + 1
    : 24

  const startInUTC = moment
    .tz(date, dateTimeFormat, companyTimeZone)
    .startOf('day')

  return Array(intervalsFromMidnight)
    .fill()
    .map((_, index) => {
      if (index !== 0) {
        startInUTC.add(60, 'm')
      }

      const time =
        locale === Locales.EN_US
          ? moment(`${index.toString()}:00`, 'HH:mm').format('h:mma')
          : `${index.toString().padStart(2, '0')}:00`

      return {
        time,
        dateTimeString: startInUTC.toISOString(),
      }
    })
}

const DateTimeSelector = ({ station, isOverlayMode }) => {
  const { setDateTimeSelectorInUse } = station
  const timestamp = useReactiveVar(timestampVar)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { formatTimestamp } = useFormatTimestamp()
  const now = moment.utc()
  const [selectedDateTime, setSelectedDateTime] = useState(timestamp ?? now)
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(
    selectedDateTime
  )
  const [showCalendar, setShowCalendar] = useState(false)
  const calendarRef = useRef()
  const { companyTimeZone } = useCompanyTimeZone()
  const [selectedTime, setSelectedTime] = useState(
    formatTimestamp(TIME, selectedDateTime)
  )
  const [{ status }] = usePriceChange()

  const editPricingInUse = status !== PriceChangeStatus.None

  const daysBetweenTodayAndSelected = calendarDaysDifference(
    moment.tz(now, companyTimeZone),
    moment.tz(selectedDateTime, companyTimeZone)
  )

  useEffect(() => {
    setSelectedTime(formatTimestamp(TIME, selectedDateTime))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateTime])

  useEffect(() => {
    daysBetweenTodayAndSelected > 0
      ? setDateTimeSelectorInUse(true)
      : setDateTimeSelectorInUse(false)
  }, [daysBetweenTodayAndSelected, setDateTimeSelectorInUse])

  const intervals = intervalsWithTimeAndUTCISOString(
    selectedCalendarDate,
    companyTimeZone,
    language,
    now
  ).map(({ time }) => ({ item: time, displayItem: time }))

  const addDays = operator => {
    let date = moment(selectedDateTime).add(operator, 'day')
    const now = moment.utc()
    const currentHours = moment.tz(now, companyTimeZone).hours()
    const selectedHours = moment.tz(date, companyTimeZone).hours()

    const isCurrentDay = isToday(companyTimeZone, date, moment.utc())

    if (isCurrentDay) {
      setDateTimeSelectorInUse(false)

      // don't allow dates in the future
      if (currentHours < selectedHours) {
        date = now
      }
    }

    setSelectedDateTime(date)
    timestampVar(date)
  }

  const displaySelectedDate = () => {
    const today = t('widgets.competitorPricing.priceTodayAt', {
      timeValue: formatTimestamp(TIME, selectedDateTime),
    })
    const yesterday = t('widgets.competitorPricing.priceYesterdayAt', {
      timeValue: formatTimestamp(TIME, selectedDateTime),
    })
    const selectedDay = t('widgets.competitorPricing.priceOnAt', {
      dateValue: formatTimestamp(DAY_MONTH_YEAR, selectedDateTime),
      timeValue: formatTimestamp(TIME, selectedDateTime),
    })

    switch (true) {
      case daysBetweenTodayAndSelected === 0:
        return today
      case daysBetweenTodayAndSelected === 1:
        return yesterday
      default:
        return selectedDay
    }
  }

  const forwardArrowActive = daysBetweenTodayAndSelected !== 0
  const backArrowActive = daysBetweenTodayAndSelected < 30

  const handleBackClick = () => {
    if (!backArrowActive) {
      return
    }
    setShowCalendar(false)
    addDays(-1)
    setDateTimeSelectorInUse(true)
  }

  const handleForwardClick = () => {
    if (!forwardArrowActive) {
      return
    }
    setShowCalendar(false)
    addDays(1)
  }

  const handleCalendarConfirm = () => {
    const interval = intervalsWithTimeAndUTCISOString(
      selectedCalendarDate,
      companyTimeZone,
      language,
      moment.utc()
    ).find(({ time }) => selectedTime === time)
    const dropdownTime = interval?.dateTimeString

    if (dropdownTime) {
      const utcTimestamp = moment.utc(dropdownTime)
      const timestamp = timestampVar(utcTimestamp)
      setSelectedDateTime(timestamp)
      setShowCalendar(false)
    } else {
      // the calendar gives us a date only, set the time to now in the company time zone
      const nowInZone = moment.tz(companyTimeZone)
      const selectedCalendarDateInZone = moment.tz(
        selectedCalendarDate,
        companyTimeZone
      )
      selectedCalendarDateInZone.set({
        hour: nowInZone.get('hour'),
        minute: nowInZone.get('minute'),
      })
      // convert this back to UTC as this is what the BE expects
      const timestamp = moment(selectedCalendarDateInZone).utc()
      timestampVar(timestamp)
      setSelectedDateTime(selectedCalendarDateInZone)
      setShowCalendar(false)
    }
  }

  const handleCalendarSelect = date => {
    const now = moment.utc()
    if (isToday(companyTimeZone, date, now)) {
      const currentHours = moment.tz(now, companyTimeZone).hours()
      const format = language === Locales.EN_US ? 'h:mma' : 'HH:mm'
      const selectedHours = moment(selectedTime, [format]).hours()

      // if time is in the future then set time to now
      if (currentHours < selectedHours) {
        setSelectedTime(formatTimestamp(TIME, now))
        setSelectedCalendarDate(now)
        return
      }
    }

    setSelectedCalendarDate(date)
  }

  return (
    <div ref={calendarRef} className={cx('date-time-selector')}>
      <div className={cx('date-time')}>
        <ClockIcon className={cx('clock-icon')} />
        {displaySelectedDate()}
      </div>
      <div className={cx('selectors')}>
        <DownArrow
          editPricingInUse={editPricingInUse}
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
        />
        <div className={cx('vertical-spacer')} />
        <div className={cx('arrow-selector')}>
          <Arrows
            editPricingInUse={editPricingInUse}
            onBackClick={handleBackClick}
            onForwardClick={handleForwardClick}
            backArrowActive={backArrowActive}
            forwardArrowActive={forwardArrowActive}
          />
        </div>
      </div>
      <Overlay
        target={calendarRef}
        show={showCalendar}
        placement="bottom-start"
      >
        <Popover
          className={cx('calendar-popover', {
            'station-overlay': isOverlayMode,
          })}
        >
          <div data-testid="period-selector" className={cx('period-selector')}>
            <Calendar
              maxDate={now}
              value={selectedCalendarDate}
              onValueChange={handleCalendarSelect}
              multiSelect={false}
              timeZone={companyTimeZone}
            />
            <div className={cx('confirm')}>
              <Dropdown
                selectedItem={selectedTime}
                items={intervals}
                maxHeight="225px"
                onValueSelect={setSelectedTime}
                data-test-id={'dropdown'}
              />
              <div className={cx('date')}>
                {formatTimestamp(DAY_MONTH_YEAR, selectedCalendarDate)} @{' '}
                {selectedTime}
              </div>
              <Button
                className={cx('confirm-button')}
                onClick={handleCalendarConfirm}
                data-testid="confirm"
              >
                {t('widgets.competitorPricing.confirm')}
              </Button>
            </div>
          </div>
        </Popover>
      </Overlay>
    </div>
  )
}

DateTimeSelector.propTypes = {
  station: PropTypes.shape(),
  isOverlayMode: PropTypes.bool.isRequired,
}

DateTimeSelector.defaultProps = {
  isOverlayMode: false,
}

export { DateTimeSelector, intervalsWithTimeAndUTCISOString }
