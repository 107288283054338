import React from 'react'
import PropTypes from 'prop-types'
import { Filters } from './components/filters'
import { GroupBy } from './components/group-by'
import classNames from 'classnames/bind'
import styles from './OptionsRow.module.scss'

const cx = classNames.bind(styles)

const OptionsRow = ({ filters, groupBys, options }) => {
  const { selectedFilters, selectedGroupBy } = options

  return (
    <div className={cx('table-options')}>
      <Filters filters={filters} selectedFilters={selectedFilters} />
      <GroupBy groupBys={groupBys} selectedGroupBy={selectedGroupBy} />
    </div>
  )
}

OptionsRow.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  groupBys: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.shape({
    selectedFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedGroupBy: PropTypes.string,
    selectedSort: PropTypes.shape().isRequired,
  }),
}

OptionsRow.defaultProps = {}

export { OptionsRow }
