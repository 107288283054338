import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import { upperFirstOnly } from 'utils/format'
import { MetricTypes } from 'utils/constants'
import { formatDateToISO, getMetric } from '../utils'

const formatValue = (metricType, value) => {
  switch (metricType) {
    case MetricTypes.VOLUME:
      return numeral(value).format('0')
    case MetricTypes.PROFIT:
      const parsedValue = value / 100
      return numeral(parsedValue).format('0')
    case MetricTypes.MARGIN:
      return numeral(value).format('0.00')
    /* istanbul ignore next */
    default:
      throw new Error(`Unknown metric type ${metricType}`)
  }
}

/**
 * Handles converting the data for one date.
 */
const mapDateData = (t, data, metrics, metricType) => {
  const { date, performanceReports } = data
  const [performanceReport] = performanceReports

  const parsedMetrics = metrics
    .map(metric => {
      const parsedMetric = getMetric(metric)
      const value = formatValue(metricType, performanceReport[parsedMetric])

      return {
        metric: parsedMetric,
        value,
      }
    })
    .reduce((parsedMetrics, { metric, value }) => {
      const metricHeader = upperFirstOnly(
        t(`widgets.performanceReport.${metric}`)
      )
      parsedMetrics[metricHeader] = value
      return parsedMetrics
    }, {})

  const dateHeader = upperFirstOnly(t('widgets.performanceReport.date'))
  return {
    [dateHeader]: formatDateToISO(date),
    ...parsedMetrics,
  }
}

function useFormatExportData() {
  const { t } = useTranslation()

  /**
   * Takes in the raw query data set(s) for the performance reporting queries. Converts these to a format suitable for export.
   *
   * @param dataOne current period data
   * @param dataTwo comparative period data (optional)
   * @param metrics list of metrics selected by the user
   * @param hasComparative if there is a comparative data set available
   * @param metricType the current metric type
   * @returns the formatted data, ready for export
   */
  const formatExportData = (
    dataOne,
    dataTwo,
    metrics,
    hasComparative,
    metricType
  ) => {
    const parsedDataOne = dataOne.performanceReport.map(data =>
      mapDateData(t, data, metrics, metricType)
    )

    if (hasComparative) {
      const parsedDataTwo = dataTwo.performanceReport.map(data =>
        mapDateData(t, data, metrics, metricType)
      )

      return [...parsedDataTwo, ...parsedDataOne]
    }

    return parsedDataOne
  }

  return { formatExportData }
}

export { useFormatExportData }
