import { useReducer, useEffect } from 'react'
import { ElementWidths, ElementMargins, Widgets } from 'utils/constants'

const { COMPETITOR_PRICING, DELIVERY, WETSTOCK, BUYSMART } = Widgets

const initialState = {
  leftCount: 0,
  rightCount: 0,
  leftArrowActive: true,
  rightArrowActive: true,
  showArrows: false,
  showArrowsStyle: null,
  elementWidth: 0,
  combinedElementsWidth: 0,
  elementMargin: 0,
  enabled: true,
}

function sliderReducer(state, action) {
  switch (action.type) {
    case 'handleRightClick':
      return {
        ...state,
        leftCount: state.leftCount + 1,
      }
    case 'handleLeftClick':
      return {
        ...state,
        rightCount: state.rightCount + 1,
      }
    case 'setRightArrowActiveState':
      return {
        ...state,
        leftArrowActive: action.payload,
      }
    case 'setLeftArrowActiveState':
      return {
        ...state,
        rightArrowActive: action.payload,
      }
    case 'showSliderArrows':
      return {
        ...state,
        showArrows: action.payload,
      }
    case 'showArrowsStyle':
      return {
        ...state,
        showArrowsStyle: action.payload,
      }
    case 'elementWidth':
      return {
        ...state,
        elementWidth: action.payload,
      }
    case 'elementMargin':
      return {
        ...state,
        elementMargin: action.payload,
      }
    case 'enabled':
      return {
        ...state,
        enabled: action.payload,
      }
    default:
      return initialState
  }
}

const useSlider = widget => {
  const [state, dispatch] = useReducer(sliderReducer, initialState)

  let elementWidth
  switch (widget) {
    case COMPETITOR_PRICING:
      elementWidth = ElementWidths.DEFAULT
      break
    case DELIVERY:
      elementWidth = ElementWidths.DELIVERY
      break
    case WETSTOCK:
      elementWidth = ElementWidths.WETSTOCK
      break
    case BUYSMART:
      elementWidth = ElementWidths.BUYSMART
      break
    default:
      elementWidth = ElementWidths.DEFAULT
  }

  let elementMargin
  switch (widget) {
    case COMPETITOR_PRICING:
      elementMargin = ElementMargins.DEFAULT_MARGIN
      break
    case WETSTOCK:
      elementMargin = ElementMargins.WETSTOCK_MARGIN
      break
    case DELIVERY:
      elementMargin = ElementMargins.DELIVERY_MARGIN
      break
    case BUYSMART:
      elementMargin = ElementMargins.BUYSMART_MARGIN
      break
    default:
      elementMargin = ElementMargins.DEFAULT_MARGIN
  }

  useEffect(() => {
    const showArrowsStyle = {
      visibility: state.showArrows ? 'visible' : 'hidden',
    }
    dispatch({ type: 'showArrowsStyle', payload: showArrowsStyle })
  }, [state.showArrows])

  useEffect(() => {
    dispatch({ type: 'elementWidth', payload: elementWidth })
  }, [elementWidth])

  useEffect(() => {
    dispatch({ type: 'elementMargin', payload: elementMargin })
  }, [elementMargin])

  return [state, dispatch]
}

export { useSlider }
