const getPasswordComplexity = password => {
  const length = password.length

  let sets = 0
  if (/[a-z]/.test(password)) {
    sets++
  }
  if (/[A-Z]/.test(password)) {
    sets++
  }
  if (/[0-9]/.test(password)) {
    sets++
  }
  if (/[^a-zA-Z0-9]/.test(password)) {
    sets++
  }

  return length * sets
}

export { getPasswordComplexity }
