import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GroupByIcon } from 'images/stations-list/group-by.svg'
import classNames from 'classnames/bind'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { GroupByPopover } from './components/group-by-popover'
import { SelectedGroupBy } from './components/selected-group-by'
import { Permissioned } from 'components/common/permissioned'
import styles from './GroupBy.module.scss'

const cx = classNames.bind(styles)

const GroupBy = ({ groupBys, selectedGroupBy }) => {
  const { t } = useTranslation()

  const {
    widgetPermissions: { updateStationsList },
  } = usePermittedAccess()

  const restricted = (
    <div className={cx('group-by', 'restricted')}>
      <GroupByIcon className={cx('group-by-icon')} cursor={'not-allowed'} />
      <span className={cx('group-by-text', 'restricted')}>
        {t('widgets.stationsList.group')}
      </span>
    </div>
  )

  return (
    <Permissioned permission={updateStationsList} fallback={restricted}>
      <GroupByPopover groupBys={groupBys} selectedGroupBy={selectedGroupBy}>
        <div className={cx('group-by')}>
          <GroupByIcon />
          <span className={cx('group-by-text')}>
            {t('widgets.stationsList.group')}
          </span>
          <SelectedGroupBy selectedGroupBy={selectedGroupBy} />
        </div>
      </GroupByPopover>
    </Permissioned>
  )
}

GroupBy.propTypes = {
  groupBys: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedGroupBy: PropTypes.string,
}

GroupBy.defaultProps = {
  selectedGroupBy: undefined,
}

export { GroupBy }
