import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { DeliveryDetails } from './components/delivery-details';
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue';
import { MetricTypes } from 'utils/constants';
import classNames from 'classnames/bind';
import styles from './Deliveries.module.scss';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import infoIcon from 'images/info-icon.svg'

const cx = classNames.bind(styles);
const { MARGIN, COST } = MetricTypes

const Deliveries = ({
  gradeTitle,
  gradeShortTitle,
  colour,
  width,
  totalVolume,
  blendedCost,
  replacementCost,
  margin,
  data,
  blended,
  locale,
}) => {
  const { t } = useTranslation();
  const { formatMetricValue } = useFormatMetricValue()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    if (!blended) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const gradeStyle = {
    backgroundColor: colour,
    width: `${width}px`,
  }

  const tanks = data?.station.tanks.filter(tank => tank.fuelGrade.title == gradeTitle)
  const userLocale = locale || 'en-GB'
  const blendedCostTitle = `${t('widgets.wetstockAnalysis.blendedCost')} ${t('cost.short')}`
  const replacementCostTitle = `${t('widgets.wetstockAnalysis.replacementCost')} ${t('cost.short')}`
  const marginTitle = `${t('widgets.wetstockAnalysis.margin')} ${t('margin.short')}`

  const DisabledTooltip = (
    <Tooltip className={cx('info-icon-tooltip')}>
      <div>{t('widgets.wetstockAnalysis.disabledViewDeliveries')}</div>
    </Tooltip>
  );

  return (
    <div className={cx('deliveries-container')}>
      {blended ? (
        <OverlayTrigger
          placement="top"
          overlay={DisabledTooltip}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                boundariesElement: 'offsetParent',
              },
            },
          }}
        >
          <span className={cx('button-wrapper')}>
            <button
              style={gradeStyle}
              disabled
              className={cx('disabled-button')}
            >
              {t('widgets.wetstockAnalysis.viewDeliveries')}
              <img src={infoIcon} alt={'info-icon'} className={cx('info-icon')} />
              </button>
          </span>
        </OverlayTrigger>
      ) : (
        <button
          style={gradeStyle}
          onClick={handleOpenModal}
          className={cx('active-button')}
        >
          {t('widgets.wetstockAnalysis.viewDeliveries')}
        </button>
      )}

      {isModalOpen && createPortal(
        <div className={cx('modal-overlay-style')} onClick={handleCloseModal}>
          <div className={cx('modal-content-style')} onClick={(e) => e.stopPropagation()}>
            <h2 className={cx('modal-title')}>{gradeTitle}: {Intl.NumberFormat(userLocale, { maximumFractionDigits: 0 }).format(totalVolume)} {t('volume.short')}</h2>
            <hr />

            <div className={cx('wetstock-analysis-content')}>
              <div className={cx('wetstock-analysis-container')}>
                <div className={cx('wetstock-analysis-column-container')}>
                  <div className={cx('wetstock-analysis-column')}>
                    <div className={cx('wetstock-analysis-title')}>{blendedCostTitle}</div>
                    <div className={cx('wetstock-analysis-metric')}>{formatMetricValue(COST, blendedCost, false)}</div>
                  </div>
                  <div className={cx('wetstock-analysis-column')}>
                    <div className={cx('wetstock-analysis-title')}>{replacementCostTitle}</div>
                    <div className={cx('wetstock-analysis-metric')}>{formatMetricValue(COST, replacementCost, false)}</div>
                  </div>
                  <div className={cx('wetstock-analysis-column')}>
                    <div className={cx('wetstock-analysis-title')}>{marginTitle}</div>
                    <div className={cx('wetstock-analysis-metric')}>{formatMetricValue(MARGIN, margin, false)}</div>
                  </div>
                </div>
              </div>

              <div className={cx('tanks-containers')}>
                <div className={cx('tanks-content')}>
                  {tanks?.map((tank) => {
                    const sortedDeliveries = [...tank.deliveries]?.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
                    return (
                      <div className={cx('tank-container')} key={tank.id}>
                        <div className={cx('tank-wrapper')}>
                          <div className={cx('tank-title')}>
                            <div>{gradeShortTitle} ({t('widgets.wetstockAnalysis.tankAbbreviation')}{tank.tankNumber})</div>
                            <div>{Intl.NumberFormat(userLocale).format(tank.maxVolume)} {t('volume.short')}</div>
                          </div>
                          <div className={cx('tank-content')} style={{ borderColor: colour }}>
                            {sortedDeliveries.map((delivery, index) => {
                              const heightPercentage = (delivery.remainingVolume / tank.maxVolume) * 100

                              return (
                                <DeliveryDetails
                                  key={index}
                                  level={index + 1}
                                  colour={colour}
                                  style={{ height: `${heightPercentage}%` }}
                                  deliveriesCount={tank.deliveries.length}
                                  locale={userLocale}
                                  details={{
                                    date: delivery.datetime,
                                    unitPrice: delivery.unitPrice,
                                    remainingVolume: delivery.remainingVolume,
                                    remainingValue: delivery.remainingValueOfFuel,
                                    deliveredVolume: delivery.deliveredVolume,
                                    costOfDelivery: delivery.costOfDelivery,
                                  }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <button onClick={handleCloseModal} className={cx('close-button-style')}>
              &times;
            </button>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

Deliveries.propTypes = {
  gradeTitle: PropTypes.string,
  gradeShortTitle: PropTypes.string,
  colour: PropTypes.string,
  width: PropTypes.number,
  tanks: PropTypes.array,
  totalVolume: PropTypes.number,
  blendedCost: PropTypes.number,
  replacementCost: PropTypes.number,
  margin: PropTypes.number,
  stationId: PropTypes.string,
  locale: PropTypes.string,
}

export { Deliveries }
