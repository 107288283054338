const slugify = string => {
  return (
    string &&
    string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/&/g, '-and-')
      .replace(/\s+/g, '-')
      .replace(/[^\w'()-]/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
  )
}

const unslugify = string => {
  return (
    string &&
    string
      .toString()
      .trim()
      .replace(/-and-/g, '&')
      .replace(/-/g, ' ')
  )
}

export { slugify, unslugify }
