import React from 'react'
import classNames from 'classnames/bind'
import { useAuth } from 'config/context/authContext'
import { useTranslation } from 'react-i18next'

import { AuthDialog } from '../components/auth-dialog'
import styles from './NotificationUserThankYou.module.scss'

import { ReactComponent as SmileIcon } from 'images/forms/smile.svg'

const cx = classNames.bind(styles)

const NotificationUserThankYou = () => {
  const { t } = useTranslation('auth')
  const { logout } = useAuth()

  const handleSubmit = async () => {
    await logout()
  }

  return (
    <div className={cx('wrapper')}>
      <AuthDialog
        showLogo={false}
        headerClassName={cx('header')}
        buttonText={t('done')}
        onSubmit={handleSubmit}
      >
        <div className={cx('container')}>
          <SmileIcon />
          <h1>{t('thankYou')}</h1>
          <p>{t('thankYouText')}</p>
        </div>
      </AuthDialog>
    </div>
  )
}

export { NotificationUserThankYou }
