import React from 'react'
import PropTypes from 'prop-types'
import { TrendIndicator } from 'components/common/trend-indicator'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { MetricTypes } from 'utils/constants'
import { getComparativePeriod } from 'utils/helpers'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'
import classNames from 'classnames/bind'
import styles from './MetricCells.module.scss'

const cx = classNames.bind(styles)

const getRowMetrics = ({ readMargin, readProfit, readVolume }) => {
  return [
    {
      name: 'totalVolume',
      type: MetricTypes.VOLUME,
      comparativePeriod: getComparativePeriod(MetricTypes.VOLUME),
      permitted: readVolume,
    },
    {
      name: 'retailVolume',
      type: MetricTypes.VOLUME,
      comparativePeriod: getComparativePeriod(MetricTypes.VOLUME),
      permitted: readVolume,
    },
    {
      name: 'grossMargin',
      type: MetricTypes.MARGIN,
      comparativePeriod: getComparativePeriod(MetricTypes.MARGIN),
      permitted: readMargin,
    },
    {
      name: 'netMargin',
      type: MetricTypes.MARGIN,
      comparativePeriod: getComparativePeriod(MetricTypes.MARGIN),
      permitted: readMargin,
    },
    {
      name: 'grossProfit',
      type: MetricTypes.PROFIT,
      comparativePeriod: getComparativePeriod(MetricTypes.PROFIT),
      permitted: readProfit,
    },
  ]
}

const determineVisibleMetrics = (
  showTotalVolume,
  metricPermissions,
  showPricingView
) => {
  const [
    TOTAL_VOLUME,
    RETAIL_VOLUME,
    GROSS_MARGIN,
    NET_MARGIN,
    GROSS_PROFIT,
  ] = getRowMetrics(metricPermissions)
  const visibleMetrics = []

  visibleMetrics.push(showTotalVolume ? TOTAL_VOLUME : RETAIL_VOLUME)
  if (showPricingView) {
    visibleMetrics.push(GROSS_MARGIN)
  } else {
    visibleMetrics.push(GROSS_MARGIN, NET_MARGIN, GROSS_PROFIT)
  }

  return visibleMetrics
}

const MetricCells = ({ metrics, showPricingView }) => {
  const { showTotalVolume } = useFlags()
  const { formatMetricValue } = useFormatMetricValue()
  const { metricPermissions } = usePermittedAccess()

  const visibleMetrics = determineVisibleMetrics(
    showTotalVolume,
    metricPermissions,
    showPricingView
  )

  return visibleMetrics.map(({ name, type, comparativePeriod, permitted }) => {
    const metric = metrics[name]
    const { today, comparative, trend, trendComparative } = metric

    const restricted = (
      <div className={cx('metric-cell')}>
        <div className={cx('today')}>
          <span className={cx('value')}>
            <Placeholder widthPercentage={70} />
          </span>
          <Placeholder fixedWidth={40} />
        </div>
        <div className={cx('comparative')}>
          <Placeholder widthPercentage={20} />
        </div>
      </div>
    )

    return (
      <Permissioned key={name} permission={permitted} fallback={restricted}>
        <div className={cx('metric-cell')}>
          <div className={cx('today')}>
            <span className={cx('value')}>
              {formatMetricValue(type, today, false)}
            </span>
            <TrendIndicator
              tooltip
              tooltipPlacement={'bottom'}
              trend={trend}
              comparative={trendComparative}
              comparativeType={type}
              comparativePeriod={comparativePeriod}
            />
          </div>
          <div className={cx('comparative')}>
            {formatMetricValue(type, comparative, false)}
          </div>
        </div>
      </Permissioned>
    )
  })
}

MetricCells.propTypes = {
  metrics: PropTypes.shape().isRequired,
  showPricingView: PropTypes.bool.isRequired,
}

export { MetricCells }
