import * as d3 from 'utils/d3Modules'
import moment from 'moment'
import classNames from 'classnames/bind'
import {
  xTicks,
  yTicks,
  dateData,
  buildLineData,
  getIncrement,
} from './utils/utils'
import styles from './Chart.module.scss'
const cx = classNames.bind(styles)

const HEIGHT = 600
const MAX_DATE_RANGE = 61
const X_PADDING_S = 37.5
const X_PADDING_L = 100
const Y_PADDING = 75
const Y_AXIS_PADDING = 10
const X_AXIS_PADDING = 10

export default class PerformanceChart {
  constructor(element, chartData, width) {
    this.element = element

    this.update(chartData, width)
  }

  update(chartData, width) {
    d3.select('#performance-chart').remove()

    const vis = this
    vis.width = width
    vis.maxYAxisTick = 0

    const {
      data,
      metric,
      previousLine,
      area,
      metricsForLineGraph,
      t,
      formatMetricValue,
      metrics,
    } = chartData

    vis.g = d3
      .select(this.element)
      .append('svg')
      .attr('id', 'performance-chart')
      .attr('viewBox', `0 0 ${vis.width} 600`)
      .attr('data-testid', 'chart-area')
      .append('g')
      .attr('class', cx('graphic'))

    if (data.length === 0) {
      return
    }

    vis.dateRange = data[0]
    vis.lineData = buildLineData(metrics, data, previousLine)

    const metricValues = item => metricsForLineGraph.map(m => item[m])
    const minMetric = item => Math.min(...metricValues(item))
    const maxMetric = item => Math.max(...metricValues(item))

    const minX = Math.min(...data.flat().map(item => new Date(item.date)))
    const maxX = Math.max(...data.flat().map(item => new Date(item.date)))
    let minY = Math.min(...data.flat().map(item => minMetric(item)))
    let maxY = Math.max(...data.flat().map(item => maxMetric(item)))

    const ticks = xTicks(minX, maxX)
    const incrY = getIncrement(maxY, minY)

    // adjust minY and maxY to be at a round tick value
    minY = Math.floor(minY / incrY) * incrY - incrY
    maxY = Math.ceil(maxY / incrY) * incrY

    vis.xPadding = ticks.length < 12 ? X_PADDING_S : X_PADDING_L
    vis.yScale = d3
      .scaleLinear()
      .domain([minY, Math.max(1, maxY)])
      .range([HEIGHT - vis.xPadding, 10 /* adjust for centred text */])

    vis.drawYAxis(minY, maxY, incrY, formatMetricValue, metric, t)

    vis.xScale = d3
      .scaleLinear()
      .domain([minX, maxX])
      .range([Y_PADDING / 2 + vis.maxYAxisTick, vis.width - Y_PADDING])
    vis.day = vis.xScale(minX + 24 * 3600_000) - vis.xScale(minX)

    vis.drawWeekends(minX, data)
    vis.drawGraph(area)
    vis.drawCircles(area, metrics, data)
    vis.drawXAxis(chartData, ticks)
  }

  drawYAxis(minY, maxY, incrY, formatMetricValue, metric, t) {
    const vis = this
    let maxYLabelWidth = 0
    const yAxis = d3
      .axisLeft(vis.yScale)
      .tickValues(yTicks(minY, maxY, incrY))
      .tickFormat(d => formatMetricValue(metric, d))
      .tickSize(-vis.width)
      .tickPadding(Y_AXIS_PADDING)

    const yAxisGroup = vis.g
      .append('g')
      .call(yAxis)
      .call(g => g.select('.domain').remove())
      .call(g => g.selectAll('.tick line').attr('class', cx('y-tick')))
      .call(g => g.selectAll('.tick text').attr('class', cx('y-axis-text')))

    // Calculate the maximum width of the y-axis tick text so we can transform the
    // axis and correctly pad the x-axis on the left.
    yAxisGroup.selectAll('text').each(function() {
      if (this.getBBox().width > vis.maxYAxisTick)
        vis.maxYAxisTick = this.getBBox().width
    })
    // fudge factor for the font in use
    vis.maxYAxisTick += 2

    const yAxisLabel = vis.g.append('g')

    yAxisLabel
      .append('text')
      .attr('class', cx('y-axis-label'))
      .text(t(`widgets.performanceReport.yAxisLabels.${metric.toLowerCase()}`))

    yAxisLabel.selectAll('text').each(function() {
      if (100 > maxYLabelWidth) maxYLabelWidth = this.getBBox().height
    })

    vis.maxYAxisTick = vis.maxYAxisTick += maxYLabelWidth + 30

    yAxisLabel.attr(
      'transform',
      `translate(${maxYLabelWidth},${(HEIGHT - vis.xPadding) / 2}) rotate(-90)`
    )
    yAxisGroup.attr(
      'transform',
      `translate(${vis.maxYAxisTick + Y_AXIS_PADDING},0)`
    )
  }

  drawWeekends(minX, data) {
    const vis = this
    if (vis.dateRange.length < MAX_DATE_RANGE) {
      const weekends = vis.g
        .insert('g', ':first-child')
        .attr('class', cx('weekends'))
        .selectAll('rect')
        .data(
          data
            .flat()
            .filter(
              d =>
                moment.utc(new Date(d.date)).weekday() === 0 ||
                moment.utc(new Date(d.date)).weekday() === 6
            )
            .map(x => new Date(x.date))
        )

      const dayCheck = d =>
        moment.utc(d).weekday() === 0
          ? vis.xScale(d) + vis.day / 2
          : vis.xScale(d) - vis.day / 2

      weekends
        .enter()
        .append('rect')
        .attr('x', d => Math.max(vis.xScale(minX), vis.xScale(d) - vis.day / 2))
        .attr(
          'width',
          d =>
            vis.xScale(d) +
            vis.day / 2 -
            Math.max(vis.xScale(minX), vis.xScale(d) - vis.day / 2)
        )
        .attr('y', 10)
        .attr('height', HEIGHT - vis.xPadding - 10)
        .attr('class', d =>
          moment.utc(d).weekday() === 0 ? cx('sunday') : cx('saturday')
        )

      weekends
        .enter()
        .append('line')
        .attr('x1', d => dayCheck(d))
        .attr('x2', d => dayCheck(d))
        .attr('y1', 10)
        .attr('y2', HEIGHT - vis.xPadding)
        .attr('class', d =>
          moment.utc(d).weekday() === 0 ? cx('sunday') : cx('saturday')
        )
    }
  }

  drawGraph(area) {
    const vis = this
    const lineForEachLineGraph = d3
      .line()
      .x(d => vis.xScale(new Date(d.date)))
      .y(d => vis.yScale(d.previousLine ? d.previousValue : d.value))
      .curve(d3.curveMonotoneX)

    const closedLineForEachAreaGraph = d3
      .area()
      .x(d => vis.xScale(new Date(d.date)))
      .y0(vis.yScale(0))
      .y1(d => vis.yScale(d.previousLine ? d.previousValue : d.value))
      .curve(d3.curveMonotoneX)

    vis.g
      .selectAll('path')
      .data(vis.lineData)
      .join('path')
      .attr(
        'class',
        d =>
          `${cx(d.previousLine ? 'previous-line' : 'line')} ${cx(
            d.metricToGraph
          )} ${cx(area && 'area')}`
      )
      .attr('d', d =>
        area
          ? closedLineForEachAreaGraph(d.values)
          : lineForEachLineGraph(d.values)
      )
  }

  drawCircles(area) {
    const vis = this
    vis.g
      .selectAll(`.line`)
      .data(vis.lineData.map(d => d.values).flat())
      .enter()
      .append('circle')
      .attr(
        'class',
        d =>
          `${cx(d.previousLine ? 'previous-line' : 'line')} ${cx(
            d.metricToGraph
          )} ${cx(area && 'area')}`
      )
      .attr('r', 4)
      .attr('cx', d => vis.xScale(new Date(d.date)))
      .attr('cy', d => vis.yScale(d.previousLine ? d.previousValue : d.value))
      .attr('opacity', vis.dateRange.length < MAX_DATE_RANGE ? 1 : 0)
      .attr('data-testid', d => `circle-${String(d.index)}`)
  }

  drawXAxis(chartData, ticks) {
    const vis = this
    const {
      setTooltipsActive,
      setTooltipTarget,
      setTooltipData,
      onMouseOver,
      onMouseOut,
    } = chartData
    const dateHoverAreas = d3
      .axisTop(vis.xScale)
      .tickValues(vis.dateRange.map(item => new Date(item.date)))
      .tickSize(-HEIGHT + vis.xPadding + 15)

    const onHoverOverDate = (selectedLine, d) => {
      setTooltipsActive(true)
      d3.select(selectedLine).attr('opacity', 1.0)
      let tooltipData = []

      vis.g.selectAll(`circle.${cx('line')}`).each(({ date }) => {
        if (d.getTime() === new Date(date).getTime()) {
          dateData(d, vis.lineData).forEach(dd => {
            if (!tooltipData.find(td => td.metricToGraph === dd.metricToGraph))
              tooltipData.push({
                date: dd.date,
                value: dd.value,
                metric: 'value',
                metricToGraph: dd.metricToGraph,
              })
            dd.previousLine &&
              tooltipData.push({
                date: dd.date,
                value: dd.previousValue,
                metric: 'previousValue',
                metricToGraph: dd.metricToGraph,
              })
          })
        }
      })
      setTooltipTarget(selectedLine)
      setTooltipData(tooltipData)
      onMouseOver(tooltipData[0])
    }
    const onHoverOutDate = selectedLine => {
      d3.select(selectedLine).attr('opacity', 0)
      onMouseOut()
    }
    const onHoverOutHoverArea = () => {
      setTooltipTarget(null)
      setTooltipsActive(null)
    }
    const onDateHoverAction = g => {
      let dateIndex = 0
      g.selectAll('.tick line')
        .attr('style', `transform: translateY(15px)`)
        .attr('class', cx('x-grid'))
        .attr('opacity', 0.0)
        .each(function(d) {
          dateIndex++
          d3.select(this).attr('data-testid', `gridline-${dateIndex}`)
          d3.select(this.parentNode)
            .append('rect')
            .attr('fill', 'transparent')
            .attr('opacity', 1)
            .attr('data-testid', `gridline-rect-${dateIndex}`)
            .attr('x', -vis.day / 2)
            .attr('width', vis.day)
            .attr('height', HEIGHT - vis.xPadding)
            .attr('style', d3.select(this).attr('style'))
            .on('mouseenter', () => onHoverOverDate(this, d))
            .on('mouseleave', () => onHoverOutDate(this))
        })
    }

    vis.g
      .append('g')
      .call(dateHoverAreas)
      .call(g => {
        return g.selectAll('.domain, .tick text').remove()
      })
      .call(g => onDateHoverAction(g))
      .on('mouseleave', () => onHoverOutHoverArea())

    let lastDate
    const formatXAxisDate = date => {
      let value
      lastDate
        ? moment(lastDate).isSame(date, 'month')
          ? (value = moment.utc(date).format('ddd Do'))
          : (value = moment.utc(date).format('ddd Do MMM'))
        : (value = moment.utc(date).format('ddd Do MMM'))
      lastDate = date
      return value
    }

    const xAxis = d3
      .axisBottom(vis.xScale)
      .tickValues(ticks)
      .tickFormat(d => formatXAxisDate(d))
      .tickPadding(X_AXIS_PADDING)

    const xAxisGroup = vis.g
      .append('g')
      .attr('transform', `translate(0,${HEIGHT - vis.xPadding})`)
      .call(xAxis)
      .call(g =>
        g
          .select('.domain')
          .attr('d', `M0,0.5V0.5H${vis.width}`)
          .attr('class', cx('x-axis'))
      )
      .call(g => g.selectAll('.tick line').attr('class', cx('x-axis')))

    xAxisGroup
      .selectAll('text')
      .attr(
        'class',
        cx('x-axis-text', { 'x-axis-text-rotated': ticks.length > 12 })
      )
  }
}
