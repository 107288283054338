import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { upperFirstOnly } from 'utils/format'
import { MetricTypes } from 'utils/constants'
import styles from './DateFooter.module.scss'

const cx = classNames.bind(styles)

const DateFooter = ({ metricType }) => {
  const { t } = useTranslation()

  const isMargin = metricType === MetricTypes.MARGIN
  const isFillup = metricType === MetricTypes.FILL_UP
  const isPolePrice = metricType === MetricTypes.POLE_PRICE
  const isCostPrice = metricType === MetricTypes.COST
  const average = isMargin || isFillup || isPolePrice || isCostPrice

  const footerTitle = upperFirstOnly(
    t(`widgets.performanceReport.${ average ? 'average' : 'total'}`)
  )

  return (
    <th className={cx('date-footer')}>
      <div>{footerTitle}</div>
    </th>
  )
}

DateFooter.propTypes = {
  metricType: PropTypes.string.isRequired,
}

export { DateFooter }
