import React from 'react'
import PropTypes from 'prop-types'
import { showConfirm } from 'components/station/components/competitor-pricing/utils/helpers'
import classNames from 'classnames/bind'
import { FuelGradeCell } from './components/fuel-grade-cell'
import { MyStationPriceCell } from './components/my-station-price-cell'
import { CompetitorPriceCell } from './components/competitor-price-cell'
import styles from './FuelGradeColumn.module.scss'

const cx = classNames.bind(styles)

const renderCompetitorPriceCells = (
  station,
  fuelGrade,
  sortedCompetitors,
  status
) => {
  return sortedCompetitors.map(competitor => {
    const polePrices = competitor.polePrices.filter(
      ({ fuelGradeId }) => fuelGradeId === fuelGrade.id
    )
    return (
      <CompetitorPriceCell
        key={competitor.id}
        status={status}
        station={station}
        fuelGrade={fuelGrade}
        competitor={competitor}
        polePrices={polePrices}
      />
    )
  })
}

const FuelGradeColumn = ({ status, fuelGrade, station, sliderDispatch }) => {
  const {
    id: stationId,
    polePrices,
    polePriceChangeRequests,
    sortedCompetitors,
    cashCreditPricing,
    priceDifferentials,
  } = station

  const parseData = data => {
    return data.filter(({ fuelGradeId }) => fuelGradeId === fuelGrade.id)
  }

  const fuelGradePrices = parseData(polePrices)
  const fuelGradeChangeRequests = parseData(polePriceChangeRequests)

  const confirm = showConfirm(status)

  const competitorCells = confirm
    ? station.competitors.map(({ id }) => (
        <div key={id} className={cx('confirm-cell')} />
      ))
    : renderCompetitorPriceCells(station, fuelGrade, sortedCompetitors, status)

  return (
    <div className={cx('fuel-grade-column')}>
      <FuelGradeCell
        stationId={stationId}
        fuelGrade={fuelGrade}
        priceDifferentials={priceDifferentials}
        sliderDispatch={sliderDispatch}
      />
      <MyStationPriceCell
        stationId={stationId}
        fuelGradeId={fuelGrade.id}
        stationCashCreditPricing={cashCreditPricing}
        polePrices={fuelGradePrices}
        polePriceChangeRequests={fuelGradeChangeRequests}
        priceDifferentials={priceDifferentials}
        sliderDispatch={sliderDispatch}
      />
      {competitorCells}
    </div>
  )
}

FuelGradeColumn.propTypes = {
  status: PropTypes.string.isRequired,
  fuelGrade: PropTypes.shape().isRequired,
  station: PropTypes.shape().isRequired,
  sliderDispatch: PropTypes.func.isRequired,
}

export { FuelGradeColumn }
