import React from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import styles from './LazyLoader.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

/**
 * Renders its children when it becomes visible in the viewport.
 */
const LazyLoader = ({ testID, children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    fallbackInView: true,
    rootMargin: '0% 0% 50% 0%',
  })

  if (typeof window.IntersectionObserver === 'undefined') {
    return children
  }

  return (
    <div
      ref={ref}
      data-testid={testID ? `${testID}-lazy-loader` : 'lazy-loader'}
    >
      {inView ? (
        children
      ) : (
        <div className={cx('lazy-loader-placeholder')}></div>
      )}
    </div>
  )
}

LazyLoader.propTypes = {
  children: PropTypes.any.isRequired,
  testID: PropTypes.string,
}

LazyLoader.defaultProps = {
  testID: '',
}

export { LazyLoader }
