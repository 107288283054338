import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as LoadingIcon } from 'images/stations-list/loading.svg'
import { useGroupedPricingState } from 'components/stations-list/context/groupedPricingContext'
import { TOTALS_METRIC_ID } from 'components/stations-list/utils/constants'
import classNames from 'classnames/bind'
import { PolePriceRows } from '../pole-price-rows'
import styles from './GroupRows.module.scss'

const cx = classNames.bind(styles)

const GroupRows = memo(({ groups, stations, fuelGrades }) => {
  const { openGroups, loadingGroups, groupItems } = useGroupedPricingState()

  if (!groups) {
    return null
  }

  return groups.map(({ name }) => {
    const isOpen = openGroups.includes(name)
    const isLoading = loadingGroups.includes(name)
    const items = groupItems[name]?.filter(({ id }) => id !== TOTALS_METRIC_ID)

    return (
      <div key={name}>
        <div className={cx('group-row')} />
        {isOpen && items && (
          <PolePriceRows
            items={items}
            stations={stations}
            fuelGrades={fuelGrades}
          />
        )}
        {isLoading && (
          <div className={cx('loading-row')}>
            <LoadingIcon />
          </div>
        )}
      </div>
    )
  })
})

GroupRows.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape()),
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

GroupRows.defaultProps = {
  groups: null,
}

export { GroupRows }
