import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { upperFirstOnly } from 'utils/format'
import styles from './DateHeader.module.scss'

const cx = classNames.bind(styles)

const DateHeader = () => {
  const { t } = useTranslation()

  return (
    <th className={cx('date-header')}>
      <div>{upperFirstOnly(t('widgets.performanceReport.date'))}</div>
    </th>
  )
}

export { DateHeader }
