import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { findChangedPendingRequest } from 'components/station/components/competitor-pricing/utils/helpers'
import { usePriceChange } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { PricingTypes } from 'utils/constants'
import classNames from 'classnames/bind'
import { CashCreditDifferential } from '../../../cash-credit-differential'
import { PriceButton } from '../../../price-button'
import styles from './EditPrices.module.scss'
import { ManualInput } from '../../../manual-input'

const cx = classNames.bind(styles)

const { CASH, CREDIT } = PricingTypes

const renderButtons = (
  show,
  dispatch,
  fuelGradeId,
  pricingType,
  setManualInputEnabled
) => {
  if (!show) {
    return null
  }

  return (
    <div className={cx('buttons')}>
      <PriceButton
        setManualInputEnabled={setManualInputEnabled}
        dispatch={dispatch}
        fuelGradeId={fuelGradeId}
        pricingType={pricingType}
      />
      <PriceButton
        setManualInputEnabled={setManualInputEnabled}
        dispatch={dispatch}
        minus
        fuelGradeId={fuelGradeId}
        pricingType={pricingType}
      />
    </div>
  )
}

const EditPrices = ({
  stationId,
  fuelGradeId,
  cashPrice,
  creditPrice,
  fuelGradeDifferential,
  cashCreditDifferential,
  sliderDispatch,
}) => {
  const [{ pendingRequests }, dispatch] = usePriceChange()
  const [manualInputEnabled, setManualInputEnabled] = useState(true)
  const { formatPolePrice } = useFormatPolePrice()
  const inputRefCash = useRef()
  const inputRefCredit = useRef()

  const pendingCashRequest = findChangedPendingRequest(
    pendingRequests,
    CASH,
    fuelGradeId
  )
  const pendingCreditRequest = findChangedPendingRequest(
    pendingRequests,
    CREDIT,
    fuelGradeId
  )

  const showPriceButtonsAndManualInput =
    !fuelGradeDifferential || fuelGradeDifferential.leadGrade
  const showCashPriceButtons = cashPrice && showPriceButtonsAndManualInput
  const showCreditPriceButtons = creditPrice && showPriceButtonsAndManualInput

  const newCashPrice = pendingCashRequest?.newPrice
  const newCreditPrice = pendingCreditRequest?.newPrice

  return (
    <>
      <CashCreditDifferential
        stationId={stationId}
        fuelGradeId={fuelGradeId}
        cashCreditDifferential={cashCreditDifferential}
        sliderDispatch={sliderDispatch}
      />
      <span className={cx('cash-price')}>
        <div className={cx('price-info')}>
          <div className={cx('current-price')}>
            {formatPolePrice(newCashPrice && cashPrice?.price, 'Cash')}
          </div>
          <div className={cx('price')}>
            {showPriceButtonsAndManualInput ? (
              <ManualInput
                newPrice={newCashPrice}
                enabled={manualInputEnabled}
                setEnabled={setManualInputEnabled}
                price={cashPrice?.price}
                fuelGradeId={fuelGradeId}
                pricingType={CASH}
                inputRef={inputRefCash}
                dispatch={dispatch}
              />
            ) : newCashPrice ? (
              formatPolePrice(newCashPrice)
            ) : (
              formatPolePrice(cashPrice?.price)
            )}
          </div>
        </div>
        {renderButtons(
          showCashPriceButtons,
          dispatch,
          fuelGradeId,
          CASH,
          setManualInputEnabled
        )}
      </span>
      <span className={cx('divider')} />
      <span className={cx('credit-price')}>
        <div className={cx('price-info')}>
          <div className={cx('current-price')}>
            {formatPolePrice(newCreditPrice && creditPrice?.price, 'Credit')}
          </div>
          <div className={cx('price')}>
            {showPriceButtonsAndManualInput ? (
              <ManualInput
                newPrice={newCreditPrice}
                enabled={manualInputEnabled}
                setEnabled={setManualInputEnabled}
                price={creditPrice?.price}
                fuelGradeId={fuelGradeId}
                pricingType={CREDIT}
                inputRef={inputRefCredit}
                dispatch={dispatch}
              />
            ) : newCreditPrice ? (
              formatPolePrice(newCreditPrice)
            ) : (
              formatPolePrice(creditPrice?.price)
            )}
          </div>
        </div>
        {renderButtons(
          showCreditPriceButtons,
          dispatch,
          fuelGradeId,
          CREDIT,
          setManualInputEnabled
        )}
      </span>
    </>
  )
}

EditPrices.propTypes = {
  stationId: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  cashPrice: PropTypes.shape(),
  creditPrice: PropTypes.shape(),
  fuelGradeDifferential: PropTypes.shape(),
  cashCreditDifferential: PropTypes.shape(),
  sliderDispatch: PropTypes.func.isRequired,
}

EditPrices.defaultProps = {
  cashPrice: null,
  creditPrice: null,
  fuelGradeDifferential: null,
  cashCreditDifferential: null,
}

export { EditPrices }
