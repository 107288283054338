import React from 'react'
import PropTypes from 'prop-types'
import { PricingTypes } from 'utils/constants'
import classNames from 'classnames/bind'
import { PricingTypeBadge } from '../pricing-type-badge'
import { ChangeRequestError } from './components/change-request-error'
import { ChangeRequest } from './components/change-request'
import styles from './ChangeRequests.module.scss'

const cx = classNames.bind(styles)

const { ALL, CASH, CREDIT } = PricingTypes

const renderChangeRequestError = (id, pricingTypePendingRequests) => {
  const pendingRequest = pricingTypePendingRequests.find(
    pricingTypeRequest => pricingTypeRequest.fuelGradeId === id
  )
  return <ChangeRequestError key={id} pendingRequest={pendingRequest} />
}

const renderChangeRequest = (id, pricingTypeRequests) => {
  const changeRequest = pricingTypeRequests.find(
    pricingTypeRequest => pricingTypeRequest.fuelGradeId === id
  )
  return <ChangeRequest key={id} changeRequest={changeRequest} />
}

const renderChangeRequests = (
  fuelGrades,
  pendingRequests,
  requests,
  pricingType,
  error
) => {
  const pricingTypePendingRequests = pendingRequests.filter(
    polePriceChangeRequest => polePriceChangeRequest.pricingType === pricingType
  )
  const pricingTypeRequests = requests.filter(
    polePriceChangeRequest => polePriceChangeRequest.pricingType === pricingType
  )

  return (
    <div className={cx({ 'no-cc': pricingType === ALL })}>
      <PricingTypeBadge pricingType={pricingType} />
      {fuelGrades.map(({ id }) => {
        if (error) {
          return renderChangeRequestError(id, pricingTypePendingRequests)
        }
        return renderChangeRequest(id, pricingTypeRequests)
      })}
    </div>
  )
}

const ChangeRequests = ({
  cashCreditPricing,
  fuelGrades,
  pendingRequests,
  polePriceChangeRequests,
  error,
}) => {
  const content = cashCreditPricing ? (
    <>
      {renderChangeRequests(
        fuelGrades,
        pendingRequests,
        polePriceChangeRequests,
        CASH,
        error
      )}
      {renderChangeRequests(
        fuelGrades,
        pendingRequests,
        polePriceChangeRequests,
        CREDIT,
        error
      )}
    </>
  ) : (
    renderChangeRequests(
      fuelGrades,
      pendingRequests,
      polePriceChangeRequests,
      ALL,
      error
    )
  )

  return <div className={cx('change-requests')}>{content}</div>
}

ChangeRequests.propTypes = {
  cashCreditPricing: PropTypes.bool.isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pendingRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  polePriceChangeRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  error: PropTypes.bool.isRequired,
}

export { ChangeRequests }
