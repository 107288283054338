import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import {
  createDateRangeFromNow,
  getDaysFromDateRange,
  thisWeekDateRange,
  lastWeekDateRange,
} from 'utils/dateTime'
import { upperFirstOnly } from 'utils/format'
import { getFirstPartOfURLPath } from 'utils/helpers'
import { Dropdown } from 'components/common/dropdown'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import { PeriodSelector } from '../period-selector'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { ReactComponent as ClockIcon } from 'images/performance-report/clock-icon.svg'
import styles from './PeriodDropdown.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const LAST_7_DAYS = 0
export const LAST_7_DAYS_VS_PREVIOUS_7_DAYS = 1
export const THIS_WEEK = 2
export const THIS_WEEK_VS_LAST_WEEK = 3
export const LAST_4_WEEKS = 4
export const LAST_4_WEEKS_VS_PREVIOUS_4_WEEKS = 5
export const CUSTOM_PERIOD = 6

const resolveCurrentPeriod = (period1, period2, timezone) => {
  const days1 = getDaysFromDateRange(period1)
  const days2 = getDaysFromDateRange(period2)
  const thisWeek = getDaysFromDateRange(thisWeekDateRange(timezone))
  const lastWeek = getDaysFromDateRange(lastWeekDateRange(timezone))

  switch (true) {
    case days1.from === -7 && days1.to === -1: {
      if (days2.from === -14 && days2.to === -8) {
        return LAST_7_DAYS_VS_PREVIOUS_7_DAYS
      } else {
        return LAST_7_DAYS
      }
    }
    case days1.from === thisWeek.from && days1.to === thisWeek.to: {
      if (days2.from === lastWeek.from && days2.to === lastWeek.to) {
        return THIS_WEEK_VS_LAST_WEEK
      } else {
        return THIS_WEEK
      }
    }
    case days1.from === -28 && days1.to === -1:
      if (days2.from === -56 && days2.to === -29) {
        return LAST_4_WEEKS_VS_PREVIOUS_4_WEEKS
      } else {
        return LAST_4_WEEKS
      }
    default:
      return CUSTOM_PERIOD
  }
}

const PeriodDropdown = ({
  period1: period1Prop,
  period2,
  onPeriod1Change,
  onPeriod2Change,
  companyTimeZone,
}) => {
  const { showThisWeekLastWeek } = useFlags()

  const { t } = useTranslation(['translations'])
  const selectBox = useRef(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const [closeDropDown, setCloseDropDown] = useState(null)

  const period1 = period1Prop ?? createDateRangeFromNow(-7, -1, companyTimeZone)
  const items = showThisWeekLastWeek
    ? [
        {
          item: LAST_7_DAYS,
          displayItem: upperFirstOnly(t('widgets.performanceReport.last7Days')),
        },
        {
          item: LAST_7_DAYS_VS_PREVIOUS_7_DAYS,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.last7DaysVsPrevious7Days')
          ),
        },
        {
          item: THIS_WEEK,
          displayItem: upperFirstOnly(t('widgets.performanceReport.thisWeek')),
        },
        {
          item: THIS_WEEK_VS_LAST_WEEK,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.thisWeekVsLastWeek')
          ),
        },
        {
          item: LAST_4_WEEKS,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.last4Weeks')
          ),
        },
        {
          item: LAST_4_WEEKS_VS_PREVIOUS_4_WEEKS,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.last4WeeksVsPrevious4Weeks')
          ),
        },
        {
          item: CUSTOM_PERIOD,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.customPeriod')
          ),
        },
      ]
    : [
        {
          item: LAST_7_DAYS,
          displayItem: upperFirstOnly(t('widgets.performanceReport.last7Days')),
        },
        {
          item: LAST_7_DAYS_VS_PREVIOUS_7_DAYS,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.last7DaysVsPrevious7Days')
          ),
        },
        {
          item: LAST_4_WEEKS,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.last4Weeks')
          ),
        },
        {
          item: LAST_4_WEEKS_VS_PREVIOUS_4_WEEKS,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.last4WeeksVsPrevious4Weeks')
          ),
        },
        {
          item: CUSTOM_PERIOD,
          displayItem: upperFirstOnly(
            t('widgets.performanceReport.customPeriod')
          ),
        },
      ]
  const currentPeriod = resolveCurrentPeriod(period1, period2, companyTimeZone)

  const handleDropDownOpen = close => {
    amplitudeEvent(
      UserEvents.PerformanceReport.TIME_PERIOD_SELECTED(
        getFirstPartOfURLPath(window.location.pathname)
      )
    )
    if (currentPeriod === CUSTOM_PERIOD) {
      setImmediate(() => {
        setShowCalendar(true)
        setCloseDropDown(() => close)
      })
    }
  }

  const handleDropDownClose = () => {
    setShowCalendar(false)
    setCloseDropDown(null)
  }

  const handleItemClick = (event, close) => {
    const target = event.target.querySelector('span')
    const item = items.find(item => item.displayItem === target?.textContent)

    switch (item?.item) {
      case CUSTOM_PERIOD:
        event.preventDefault()
        setShowCalendar(true)
        setCloseDropDown(() => close)
        break
      case LAST_7_DAYS:
        onPeriod1Change(createDateRangeFromNow(-7, -1, companyTimeZone))
        onPeriod2Change(null)
        break
      case LAST_7_DAYS_VS_PREVIOUS_7_DAYS:
        onPeriod1Change(createDateRangeFromNow(-7, -1, companyTimeZone))
        onPeriod2Change(createDateRangeFromNow(-14, -8, companyTimeZone))
        break
      case THIS_WEEK:
        onPeriod1Change(thisWeekDateRange(companyTimeZone))
        onPeriod2Change(null)
        break
      case THIS_WEEK_VS_LAST_WEEK:
        onPeriod1Change(thisWeekDateRange(companyTimeZone))
        onPeriod2Change(lastWeekDateRange(companyTimeZone))
        break
      case LAST_4_WEEKS:
        onPeriod1Change(createDateRangeFromNow(-28, -1, companyTimeZone))
        onPeriod2Change(null)
        break
      case LAST_4_WEEKS_VS_PREVIOUS_4_WEEKS:
        onPeriod1Change(createDateRangeFromNow(-28, -1, companyTimeZone))
        onPeriod2Change(createDateRangeFromNow(-56, -29, companyTimeZone))
        break
      /* istanbul ignore next */
      default:
        return
    }
  }

  const handlePeriodSelectorClose = period => {
    onPeriod1Change(period)
    onPeriod2Change(null)
    closeDropDown()
  }

  const parseSelectedItem = () => {
    if (currentPeriod === CUSTOM_PERIOD) {
      const getDisplayValue = value => {
        return moment.tz(value, companyTimeZone).format('D MMM YYYY')
      }

      const { from, to } = period1
      const fromDisplayValue = getDisplayValue(from)
      const toDisplayValue = getDisplayValue(to)

      return {
        selectedItem: currentPeriod,
        selectedItemDisplay: `${fromDisplayValue} - ${toDisplayValue}`,
      }
    }
    return { selectedItem: currentPeriod, selectedItemDisplay: null }
  }

  const { selectedItem, selectedItemDisplay } = parseSelectedItem()

  return (
    <>
      <Dropdown
        selectedItem={selectedItem}
        items={items}
        selectedItemDisplay={selectedItemDisplay}
        leftAccessory={<ClockIcon className={cx('icon')} />}
        onItemClick={handleItemClick}
        onOpen={handleDropDownOpen}
        onClose={handleDropDownClose}
        itemsRef={selectBox}
      />
      <Overlay
        target={selectBox.current}
        show={showCalendar}
        placement="left-start"
      >
        <Popover className={cx('popover')}>
          <PeriodSelector
            onClose={handlePeriodSelectorClose}
            currValue={period1}
            companyTimeZone={companyTimeZone}
          />
        </Popover>
      </Overlay>
    </>
  )
}

PeriodDropdown.propTypes = {
  period1: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
  period2: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
  onPeriod1Change: PropTypes.func,
  onPeriod2Change: PropTypes.func,
  companyTimeZone: PropTypes.string.isRequired,
}

PeriodDropdown.defaultProps = {
  period1: null,
  period2: null,
  onPeriod1Change: null,
  onPeriod2Change: null,
}

export { PeriodDropdown }
