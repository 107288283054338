import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './Cover.module.scss'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'

const cx = classNames.bind(styles)

const { CONFIRM, SENT, ERROR } = StationsListPricingStatus

const Cover = ({ status }) => {
  if ([CONFIRM, SENT, ERROR].includes(status)) {
    return null
  }

  return <div className={cx('cover')} />
}

Cover.propTypes = {
  status: PropTypes.string.isRequired,
}

export { Cover }
