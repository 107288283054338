import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { SliderArrow } from 'components/common/slider-arrow'
import styles from './SliderArrows.module.scss'

const cx = classNames.bind(styles)

const SliderArrows = ({
  leftArrowActive,
  rightArrowActive,
  dispatch,
  showArrowsStyle,
}) => {
  return (
    <div className={cx('slider-arrow-container')} style={showArrowsStyle}>
      <SliderArrow
        direction={'left'}
        handleClick={() => dispatch({ type: 'handleLeftClick' })}
        isActive={leftArrowActive}
      />
      <SliderArrow
        style={cx('right-arrow')}
        direction={'right'}
        handleClick={() => dispatch({ type: 'handleRightClick' })}
        isActive={rightArrowActive}
      />
    </div>
  )
}

SliderArrows.propTypes = {
  leftArrowActive: PropTypes.bool.isRequired,
  rightArrowActive: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  showArrowsStyle: PropTypes.object.isRequired,
}

export { SliderArrows }
