import React from 'react'
import PropTypes from 'prop-types'
import { usePriceChangeState } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import { changedPendingRequest } from 'components/station/components/competitor-pricing/utils/helpers'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import { ConfirmChanges } from '../confirm-changes'
import { PriceChangeTooltip } from 'components/common/price-change-tooltip'
import { EditPrices } from './components/edit-prices'
import { Prices } from './components/prices'
import styles from './PricesCell.module.scss'

const cx = classNames.bind(styles)

const PricesCell = ({
  stationId,
  fuelGradeId,
  cashPrice,
  creditPrice,
  cashPriceChangeRequest,
  creditPriceChangeRequest,
  stationCashCreditPricing,
  fuelGradeDifferential,
  cashCreditDifferential,
  sliderDispatch,
}) => {
  const { status, pendingRequests } = usePriceChangeState()

  switch (status) {
    case PriceChangeStatus.None:
      const activeRequest =
        !isNullOrUndefined(cashPriceChangeRequest) ||
        !isNullOrUndefined(creditPriceChangeRequest)

      const content = (
        <div className={cx('prices-cell', { 'active-request': activeRequest })}>
          <Prices
            cashPrice={cashPrice}
            creditPrice={creditPrice}
            cashPriceChangeRequest={cashPriceChangeRequest}
            creditPriceChangeRequest={creditPriceChangeRequest}
          />
        </div>
      )

      if (!activeRequest) {
        return content
      }

      const polePriceChangeRequest =
        cashPriceChangeRequest ?? creditPriceChangeRequest
      return (
        <PriceChangeTooltip polePriceChangeRequest={polePriceChangeRequest}>
          {content}
        </PriceChangeTooltip>
      )
    case PriceChangeStatus.Edit:
      return (
        <div className={cx('prices-cell')}>
          <EditPrices
            stationId={stationId}
            fuelGradeId={fuelGradeId}
            cashPrice={cashPrice}
            creditPrice={creditPrice}
            fuelGradeDifferential={fuelGradeDifferential}
            cashCreditDifferential={cashCreditDifferential}
            sliderDispatch={sliderDispatch}
          />
        </div>
      )
    default:
      return (
        <div
          className={cx('prices-cell', {
            'pending-request': changedPendingRequest(
              pendingRequests,
              fuelGradeId
            ),
          })}
        >
          <ConfirmChanges
            stationId={stationId}
            fuelGradeId={fuelGradeId}
            cashCreditDifferential={cashCreditDifferential}
            pendingRequests={pendingRequests}
            stationCashCreditPricing={stationCashCreditPricing}
            sliderDispatch={sliderDispatch}
          />
        </div>
      )
  }
}

PricesCell.propTypes = {
  stationId: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  cashPrice: PropTypes.shape(),
  creditPrice: PropTypes.shape(),
  cashPriceChangeRequest: PropTypes.shape(),
  creditPriceChangeRequest: PropTypes.shape(),
  fuelGradeDifferential: PropTypes.shape(),
  cashCreditDifferential: PropTypes.shape(),
  sliderDispatch: PropTypes.func.isRequired,
}

PricesCell.defaultProps = {
  cashPrice: null,
  creditPrice: null,
  cashPriceChangeRequest: null,
  creditPriceChangeRequest: null,
  cashCreditDifferential: null,
}

export { PricesCell }
