import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { useQuery } from '@apollo/client'
import sortBy from 'lodash/sortBy'
import Spinner from 'react-bootstrap/Spinner'
import { useErrorHandler } from 'react-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSlider } from 'components/common/slider/hooks/useSlider'
import { Slider } from 'components/common/slider'
import { WidgetIds, Widgets } from 'utils/constants'
import { nextSevenDays } from 'utils/dateTime'
import { Timeline } from './components/timeline'
import { SliderArrows } from './components/slider-arrows'
import { Tanks } from './components/tanks'
import styles from './Buysmart.module.scss'
import { BUYSMART_QUERY } from './BuysmartQueries'

const cx = classNames.bind(styles)

const { BUYSMART } = Widgets

const Buysmart = ({ stationId }) => {
  const [selectedDay, setSelectedDay] = useState(0)
  const [state, dispatch] = useSlider(BUYSMART)
  const { loading, error, data } = useQuery(BUYSMART_QUERY, {
    variables: { stationId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })
  const { t } = useTranslation()

  useErrorHandler(error)

  const {
    leftCount,
    rightCount,
    leftArrowActive,
    rightArrowActive,
    showArrowsStyle,
    elementWidth,
    elementMargin,
  } = state

  if (loading || !data) {
    return (
      <div className={cx('buysmart-widget-spinner')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  }

  const {
    station: { buysmartEnabled, tanks },
  } = data

  const nextSevenDaysArray = Array.from(Array(7).keys())

  const stockOutsRecorded = []
  const tanksData = []
  const predictionsReady = !tanks.some(tank => tank.predictedVolumes.length < 7)

  const nextSevenDaysData = nextSevenDaysArray.map((_day, i) => {
    const isToday = i === 0
    const timelineEvents = { warnings: [], stockOuts: [] }
    const tanksForDay = { tanks: [] }
    tanks.forEach(
      ({
        tankNumber,
        currentVolume,
        predictedVolumes,
        ullage: currentUllage,
        maxVolume,
        fuelGrade: { shortTitle, primary, position },
      }) => {
        if (!buysmartEnabled || !predictionsReady) {
          if (isToday) {
            tanksForDay.tanks.push({
              shortTitle,
              tankNumber,
              maxVolume,
              ullage: currentUllage,
              volume: currentVolume,
              primary,
              position,
              daysLeft: null,
            })
          }
          return
        }

        const eventCheck = prediction => {
          const { daysLeft, date, ullage, volume } = prediction
          const warning = { warning: shortTitle, tankNumber }
          const stockOut = { stockout: shortTitle, tankNumber }

          timelineEvents.date = date
          tanksForDay.tanks.push({
            shortTitle,
            tankNumber,
            date,
            maxVolume,
            ullage: isToday ? currentUllage : ullage,
            volume: isToday ? currentVolume : volume,
            primary,
            daysLeft,
            position,
          })

          switch (true) {
            case stockOutsRecorded.includes(tankNumber):
              break
            case daysLeft < 4 && daysLeft >= 3:
              timelineEvents.warnings.push(warning)
              break
            case daysLeft < 1:
              stockOutsRecorded.push(tankNumber)
              timelineEvents.stockOuts.push(stockOut)
              break
            default:
              break
          }
        }

        eventCheck(predictedVolumes[i])
      }
    )
    tanksData.push(sortBy(tanksForDay, ['daysLeft']))
    return timelineEvents
  })

  const renderBuysmartTimeline = predictionsReady ? (
    <Timeline
      timelineData={nextSevenDaysData}
      selectedDay={selectedDay}
      setSelectedDay={setSelectedDay}
      predictionsReady={predictionsReady}
    />
  ) : (
    <Timeline
      timelineData={nextSevenDays().map(date => {
        return { date }
      })}
    />
  )

  const renderSliderWithTanks = (
    <>
      <div className={cx('spacer')}>{''}</div>
      <div className={cx('slider')}>
        <Slider
          leftClick={leftCount}
          rightClick={rightCount}
          handleLeftArrowActive={dispatch}
          handleRightArrowActive={dispatch}
          showArrows={dispatch}
          singleElementWidth={elementWidth}
          elementCount={tanks.length}
          elementMargin={elementMargin}
        >
          <Tanks
            tanksData={tanksData[selectedDay]}
            buysmartEnabled={buysmartEnabled}
            predictionsReady={predictionsReady}
            selectedDay={selectedDay}
          />
        </Slider>
      </div>
    </>
  )

  const renderSliderArrows = (
    <div className={cx('arrows')}>
      <SliderArrows
        leftArrowActive={leftArrowActive}
        rightArrowActive={rightArrowActive}
        dispatch={dispatch}
        showArrowsStyle={showArrowsStyle}
      />
    </div>
  )

  return (
    <div className={cx('buysmart-container')} id={WidgetIds.BUYSMART}>
      <div className={cx('buysmart-title')}>
        {t('widgets.buysmartAnalysis.title')}
      </div>
      <div>
        {buysmartEnabled && renderBuysmartTimeline}
        <div className={cx('tanks')}>
          {renderSliderWithTanks}
          {renderSliderArrows}
        </div>
      </div>
    </div>
  )
}

Buysmart.propTypes = {
  stationId: PropTypes.string.isRequired,
}

const FallbackComponent = () => <WidgetFallback title="BuySmart analysis" />

const BuysmartWithErrorBoundary = withEdgeErrorBoundary(
  Buysmart,
  FallbackComponent
)

export { BuysmartWithErrorBoundary as Buysmart }
