import { gql } from '@apollo/client'

const STATION_WIDGETS_QUERY = gql`
  query stationWidgetsQuery($stationId: ID!) {
    station(id: $stationId) {
      id
      fuelGrades {
        fuelGrade {
          id
          shortTitle
          title
          primary
          secondary
        }
      }
    }
  }
`

export { STATION_WIDGETS_QUERY }
