import { ActionAtPeriods } from 'utils/pricing/constants'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'

const { Immediately, LaterToday, Tomorrow, Custom } = ActionAtPeriods

/**
 * Based on the user entered options generate a UTC ISO string.
 */
const parseToActionAt = (actionAt, timeZone) => {
  const { selectedPeriod, selectedDate, selectedTime } = actionAt
  switch (selectedPeriod) {
    case Immediately:
      return null
    case LaterToday:
    case Tomorrow: {
      const [hours, minutes] = selectedTime.split(':')
      const date = moment.tz(timeZone)

      if (selectedPeriod === Tomorrow) {
        date.add(1, 'days')
      }

      return date
        .hours(hours)
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0)
        .toISOString()
    }
    case Custom: {
      const [hours, minutes] = selectedTime.split(':')

      return moment
        .tz(selectedDate, timeZone)
        .hours(hours)
        .minutes(minutes)
        .toISOString()
    }
    /* istanbul ignore next */
    default: {
      throw new Error(`unknown period ${selectedPeriod}`)
    }
  }
}

/**
 * Returns true if the given values indicate a station that is in a cash/credit portfolio but does not offer cash credit itself.
 */
const stationCashCreditStatus = (
  cashCreditPricing,
  stationCashCreditPricing
) => {
  return cashCreditPricing && !stationCashCreditPricing
}

export { parseToActionAt, stationCashCreditStatus }
