import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { ReactComponent as EditIcon } from 'images/common/edit.svg'
import { ReactComponent as EditDisabledIcon } from 'images/common/edit-disabled.svg'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import { Permissioned } from 'components/common/permissioned'
import { Placeholder } from 'components/common/placeholder'
import styles from './CurrentStrategy.module.scss'

const cx = classNames.bind(styles)

const CurrentStrategy = ({ onClick, stationStrategy }) => {
  const {
    featurePermissions: { readPricingStrategy, updatePricingStrategy },
  } = usePermittedAccess()
  const { t } = useTranslation()

  const existingStrategy = !isNullOrUndefined(stationStrategy?.strategy)

  const restrictedReadPricingStrategy = (
    <span className={cx('restricted')}>
      {t('widgets.competitorPricing.pricingStrategy')}:
      <Placeholder widthPercentage={80} />
    </span>
  )

  const handleClick = () => onClick(updatePricingStrategy)

  return (
    <div
      className={cx('current-station-strategy', {
        restricted: !updatePricingStrategy,
      })}
      onClick={handleClick}
      data-testid="current-station-strategy"
    >
      <div className={cx('strategy-wrapper')}>
        <div className={cx('strategy')}>
          <Permissioned
            permission={readPricingStrategy}
            fallback={restrictedReadPricingStrategy}
          >
            <span>{t('widgets.competitorPricing.pricingStrategy')}:</span>
            {stationStrategy?.strategy}
          </Permissioned>
        </div>
        <div className={cx('edit-icon', { 'no-strategy': !existingStrategy })}>
          <Permissioned
            permission={updatePricingStrategy}
            fallback={<EditDisabledIcon cursor={'not-allowed'} />}
          >
            <EditIcon />
          </Permissioned>
        </div>
      </div>
    </div>
  )
}

CurrentStrategy.propTypes = {
  onClick: PropTypes.func.isRequired,
  stationStrategy: PropTypes.shape(),
}

CurrentStrategy.defaultProps = {
  stationStrategy: null,
}

export { CurrentStrategy }
