import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { Permissioned } from 'components/common/permissioned'
import styles from './EditPriceButton.module.scss'

const cx = classNames.bind(styles)

const EditPriceButton = ({ onClick }) => {
  const {
    widgetPermissions: { updateCompetitorPricing },
  } = usePermittedAccess()
  const { t } = useTranslation()

  const restrictedEditing = (
    <div className={cx('edit-price-button')} data-testid="edit-price-button">
      <div className={cx('restricted')}>
        {t('widgets.competitorPricing.editPrice')}
      </div>
    </div>
  )

  return (
    <Permissioned
      permission={updateCompetitorPricing}
      fallback={restrictedEditing}
    >
      <div
        className={cx('edit-price-button')}
        onClick={onClick}
        data-testid="edit-price-button"
      >
        <div>{t('widgets.competitorPricing.editPrice')}</div>
      </div>
    </Permissioned>
  )
}

EditPriceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export { EditPriceButton }
