import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useReactiveVar } from '@apollo/client'
import Spinner from 'react-bootstrap/Spinner'
import { useErrorHandler } from 'react-error-boundary'
import {
  timestampVar,
  resetVariables,
} from 'config/apollo/competitor-pricing/variables'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import classNames from 'classnames/bind'
import { PriceChangeProvider } from './context/priceChangeContext'
import { CompetitorPricingContainer } from './components/competitor-pricing-container'
import { COMPETITOR_PRICING_QUERY } from './CompetitorPricingQueries'
import styles from './CompetitorPricing.module.scss'

const cx = classNames.bind(styles)

const CompetitorPricing = ({ stationId, stationData, isOverlayMode }) => {
  const { supportedPricingTypes } = usePricingTypes()
  const timestamp = useReactiveVar(timestampVar)
  const [dateTimeSelectorInUse, setDateTimeSelectorInUse] = useState(false)
  const { loading, error, data: pricingData } = useQuery(
    COMPETITOR_PRICING_QUERY,
    {
      variables: { stationId, supportedPricingTypes, timestamp },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  )
  useErrorHandler(error)

  useEffect(() => {
    return () => {
      resetVariables()
    }
  }, [])

  /* Purpose of Checking for First Load
  With each loading instance without the first load check, the widget renders the Spinner component until loading is false. This resets all selections within the widget.
  After the first load of the competitor pricing widget, any additional loading instances are caused by calling the COMPETITOR_PRICING_QUERY again, therefore should retain the selections and show the Spinner as an overlay.
  */
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
    }
  }, [loading, isFirstLoad])

  if ((isFirstLoad && loading) || !pricingData) {
    return (
      <div className={cx('first-load')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  }

  const station = {
    ...pricingData.station,
    ...stationData,
    dateTimeSelectorInUse,
    setDateTimeSelectorInUse,
  }

  return (
    <div className={cx('container')}>
      <PriceChangeProvider station={station}>
        {loading && (
          <div className={cx('loading')}>
            <Spinner animation="grow" variant="primary" />
          </div>
        )}
        <CompetitorPricingContainer
          station={station}
          isOverlayMode={isOverlayMode}
        />
      </PriceChangeProvider>
    </div>
  )
}

CompetitorPricing.propTypes = {
  stationId: PropTypes.string.isRequired,
  stationData: PropTypes.shape().isRequired,
  isOverlayMode: PropTypes.bool,
}

CompetitorPricing.defaultProps = {
  isOverlayMode: false,
}

const FallbackComponent = () => (
  <WidgetFallback title="Local competitor prices" />
)

const CompetitorPricingWithErrorBoundary = withEdgeErrorBoundary(
  CompetitorPricing,
  FallbackComponent
)

export { CompetitorPricingWithErrorBoundary as CompetitorPricing }
