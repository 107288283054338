import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useLockBodyScroll } from 'components/common/hooks/useLockBodyScroll'
import styles from './Backdrop.module.scss'

const cx = classNames.bind(styles)

const Backdrop = ({ visible, onClick, zIndex }) => {
  useLockBodyScroll(visible)

  return (
    <div
      className={cx('backdrop', { visible })}
      onClick={onClick}
      style={{ zIndex }}
      data-testid="backdrop"
    />
  )
}

Backdrop.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
}

Backdrop.defaultProps = {
  zIndex: 10,
}

export { Backdrop }
