import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import classNames from 'classnames/bind'
import styles from './Items.module.scss'

const cx = classNames.bind(styles)

const Items = ({ selectedItem, items, onValueSelect, enableMultiSelect }) => {
  return items.map(currentItem => {
    const { item, displayItem, disabled } = currentItem

    const handleClick = () => {
      if (onValueSelect) {
        onValueSelect(item)
      }
    }

    const isActive = item === selectedItem
    return (
      <div
        key={displayItem}
        className={cx('item', { 'active-item': isActive }, { disabled })}
        onClick={handleClick}
        data-testid="item"
      >
        <span>{displayItem}</span>
        {enableMultiSelect && (isActive ? <ActiveIcon /> : <InactiveIcon />)}
      </div>
    )
  })
}

Items.propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      displayItem: PropTypes.string,
    })
  ).isRequired,
  onValueSelect: PropTypes.func,
  enableMultiSelect: PropTypes.bool
}

export { Items }
