import { useEffect, useMemo } from 'react'
import { useAuth } from 'config/context/authContext'
import { BroadcastChannel } from 'broadcast-channel'

function useInactivityMessageChannel() {
  const { logout } = useAuth()
  const inactivityMessageChannel = useMemo(
    () => new BroadcastChannel('inactivity'),
    []
  )

  inactivityMessageChannel.onmessage = function(message) {
    if (message === 'refreshed') {
      window.location.reload()
    }

    if (message === 'logged_out') {
      logout()
    }
  }

  useEffect(() => {
    return () => {
      inactivityMessageChannel.close()
    }
  }, [inactivityMessageChannel])

  return inactivityMessageChannel
}

export { useInactivityMessageChannel }
