import React from 'react'
import PropTypes from 'prop-types'
import { HoverablePopover } from 'components/common/hoverable-popover'
import { singlePrice } from 'components/station/components/competitor-pricing/utils/helpers'
import { stationCashCreditStatus } from 'utils/pricing'
import classNames from 'classnames/bind'
import { EditPriceForm } from './components/edit-price-form'
import { EditPricesForm } from '../prices-popover/components/edit-prices-form'
import { PriceInformation } from './components/price-information'
import styles from './PricePopover.module.scss'

const cx = classNames.bind(styles)

const PricePopover = ({
  children,
  stationId,
  fuelGrade,
  competitor,
  polePrice,
  selectedPricingType,
  cashCreditPricing,
  editPriceMode,
  setEditPriceMode,
}) => {
  const {
    id: competitorId,
    name,
    cashCreditPricing: stationCashCreditPricing,
  } = competitor
  const { id: fuelGradeId } = fuelGrade

  const noCashCreditStation = stationCashCreditStatus(
    cashCreditPricing,
    stationCashCreditPricing
  )
  const hasSinglePrice = singlePrice(
    !stationCashCreditPricing || noCashCreditStation,
    polePrice
  )

  const handleClosePopover = () => {
    setEditPriceMode(false)
    document.body.click()
  }

  const handleEditPrice = () => {
    setEditPriceMode(true)
  }

  const editForm = hasSinglePrice ? (
    <EditPricesForm
      allPrice={polePrice}
      stationId={stationId}
      competitorId={competitorId}
      fuelGradeId={fuelGradeId}
      onClosePopover={handleClosePopover}
    />
  ) : (
    <EditPriceForm
      polePrice={polePrice}
      stationId={stationId}
      competitorId={competitorId}
      fuelGradeId={fuelGradeId}
      selectedPricingType={selectedPricingType}
      noCashCreditStation={noCashCreditStation}
      onClosePopover={handleClosePopover}
    />
  )

  const content = editPriceMode ? (
    editForm
  ) : (
    <PriceInformation
      polePrice={polePrice}
      fuelGrade={fuelGrade}
      noCashCreditStation={noCashCreditStation}
      hasSinglePrice={hasSinglePrice}
      onEditPrice={handleEditPrice}
    />
  )

  const popoverContent = (
    <div
      id="pole-price-popover"
      className={cx('pole-price-popover', {
        'edit-price': editPriceMode,
      })}
    >
      <div className={cx('content')}>
        <div className={cx('title')}>{name}</div>
        {content}
      </div>
    </div>
  )

  return (
    <HoverablePopover popoverContent={popoverContent} delay={200}>
      {children}
    </HoverablePopover>
  )
}

PricePopover.propTypes = {
  children: PropTypes.shape().isRequired,
  stationId: PropTypes.string.isRequired,
  fuelGrade: PropTypes.shape().isRequired,
  competitor: PropTypes.shape().isRequired,
  selectedPricingType: PropTypes.string.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
  polePrice: PropTypes.shape(),
  editPriceMode: PropTypes.bool.isRequired,
  setEditPriceMode: PropTypes.func.isRequired,
}

export { PricePopover }
