import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

/**
 * If Intercom enabled and the current user is not an Edge Superuser boots Intercom with the current user's details.
 */
function useIntercomBoot(me) {
  const isIntercomEnabled = process.env.REACT_APP_INTERCOM_ENABLED === 'true'

  const [locationKey, setLocationKey] = useState(null)
  const location = useLocation()

  const { boot, trackEvent } = useIntercom()

  useEffect(() => {
    if (!me || !isIntercomEnabled) {
      return
    }

    const {
      id: userId,
      firstName,
      lastName,
      email,
      company: { id: companyId, name: companyName },
      isAdmin,
      isDemoCustomer,
      isEdgeSuperuser,
      stationsCount,
      createdAt,
      invitationSentAt,
      invitationAcceptedAt,
      signInCount,
      hmac,
    } = me

    const isCustomer = !isEdgeSuperuser

    if (isCustomer) {
      boot({
        userId,
        name: `${firstName} ${lastName}`,
        email,
        createdAt,
        userHash: hmac,
        signInCount,
        company: {
          companyId,
          name: companyName,
        },
        invitationSentAt,
        invitationAcceptedAt,
        stations: stationsCount,
        customer: isCustomer,
        admin: isAdmin,
        demoCustomer: isDemoCustomer,
        customLauncherSelector: '#edge-intercom-widget',
      })
    }
  }, [me, boot, isIntercomEnabled])

  useEffect(() => {
    const isChurnZeroEnabled =
      process.env.REACT_APP_CHURN_ZERO_ENABLED === 'true'

    if (
      !me ||
      locationKey === location.key ||
      !isChurnZeroEnabled ||
      !isIntercomEnabled
    ) {
      return
    }

    setLocationKey(location.key)

    /**
     * Send Screen viewed events to Intercom for ChurnZero if
     * ChurnZero enabled
     */
    trackEvent('Screen viewed', {
      app_key: process.env.REACT_APP_CHURN_ZERO_APP_KEY,
      account_external_id: me.company.id,
      contact_external_id: me.email,
      page: location.pathname,
      quantity: 1,
    })
  }, [me, locationKey, isIntercomEnabled, setLocationKey, trackEvent, location])
}

export { useIntercomBoot }
