import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useQuery, useReactiveVar } from '@apollo/client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useErrorHandler } from 'react-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { StationSelector } from './components/station-selector'
import { useStations } from 'components/common/hooks/useStations'
import { useFuelGrades } from 'components/common/hooks/useFuelGrades'
import { getMetricTypeFromField, upperFirstOnly } from 'utils/format'
import { dateRangeToString, createDateRangeFromNow } from 'utils/dateTime'
import { ReactComponent as StationsIcon } from 'images/stations-selector/stations.svg'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner'
import classNames from 'classnames/bind'
import { useCompanyTimeZone } from '../hooks/useCompanyTimeZone'
import { usePermittedAccess } from '../hooks/usePermittedAccess'
import { getMetric } from './utils'
import { PeriodDropdown } from './components/period-dropdown'
import { DataTable } from './components/data-table'
import { GradeDropdown } from './components/grade-dropdown'
import { MetricSelector } from './components/metric-selector'
import { Chart } from './components/chart'
import {
  fuelGradesVar,
  currentPeriodOneVar,
  currentPeriodTwoVar,
  metricVar,
} from 'config/apollo/perfomance-report/variables'
import { ExportButton } from './components/export-button'
import { PERFORMANCE_REPORT_QUERY } from './PerformanceReportQueries'
import styles from './PerformanceReport.module.scss'

const cx = classNames.bind(styles)

const getMetricsToLineGraph = (period2, metric, hasBunkered) => {
  if (
    !period2 &&
    ['totalVolume', 'fuelCardVolume', 'bunkeredVolume'].includes(metric)
  ) {
    return hasBunkered
      ? ['totalVolume', 'fuelCardVolume', 'bunkeredVolume']
      : ['totalVolume', 'fuelCardVolume']
  }
  if (metric === 'allMargin') {
    return ['grossMargin', 'netMarginAll']
  }
  if (metric === 'allProfit') {
    return hasBunkered
      ? ['grossProfit', 'netProfitAll', 'businessProfitAll']
      : ['grossProfit', 'netProfitAll']
  }

  return [metric]
}

const performanceReportToData = (performanceReport, metric) => {
  return performanceReport.performanceReport.map(report => ({
    date: moment
      .utc(report.date, 'yyyy-MM-DD')
      .startOf('day')
      .toDate()
      .toISOString(),
    value: report.performanceReports[0][getMetric(metric)],
    ...report.performanceReports[0],
    metricToGraph: metric,
  }))
}

const PerformanceReport = ({ stationId, stationData, hasBunkered }) => {
  const { t } = useTranslation(['translations'])
  const { metricPermissions } = usePermittedAccess()
  const stations = useStations()
  const [stationIds, setStationIds] = useState([])
  const { companyTimeZone } = useCompanyTimeZone()
  const { showPerformanceExport } = useFlags()

  useEffect(() => {
    if (stationId) {
      setStationIds([stationId])
    } else {
      const stationIds = stations.map(station => station.id)
      setStationIds(stationIds)
    }
  }, [stationId, stations])

  const fuelGradesFilter = useReactiveVar(fuelGradesVar)
  const setSelectedGrades = grades => {
    setFuelGrades(grades)
    fuelGradesVar(grades)
  }

  const { fuelGrades: allFuelGrades, fuelGradesError } = useFuelGrades(
    stationIds,
    stationData
  )

  useErrorHandler(fuelGradesError)
  const allFuelGradesTitles = allFuelGrades && allFuelGrades.map(fg => fg.title)
  const [fuelGrades, setFuelGrades] = useState(
    () =>
      fuelGradesFilter || (allFuelGradesTitles && allFuelGradesTitles.slice())
  )
  const selectedFuelGrades =
    allFuelGrades &&
    allFuelGrades
      .filter(fg => fuelGrades && fuelGrades.includes(fg.title))
      .map(fg => fg.id)

  useEffect(() => {
    if (allFuelGrades && allFuelGrades.length !== 0 && !fuelGrades) {
      setFuelGrades(allFuelGradesTitles.slice())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFuelGrades])

  const currentPeriodOneFilter = useReactiveVar(currentPeriodOneVar)
  const currentPeriodTwoFilter = useReactiveVar(currentPeriodTwoVar)

  const [period1, setPeriod1] = useState(
    () =>
      currentPeriodOneFilter ?? createDateRangeFromNow(-7, -1, companyTimeZone)
  )
  const [period2, setPeriod2] = useState(() => currentPeriodTwoFilter ?? null)

  const setSelectedPeriodOne = SelectedPeriod => {
    setPeriod1(SelectedPeriod)
    currentPeriodOneVar(SelectedPeriod)
  }
  const setSelectedPeriodTwo = SelectedPeriod => {
    setPeriod2(SelectedPeriod)
    currentPeriodTwoVar(SelectedPeriod)
  }

  const setInitialMetricFilter = (metricPermissions, metricFilter) => {
    if (metricPermissions.readVolume) {
      return metricFilter
    }

    switch (true) {
      case metricPermissions.readMargin:
        return 'allMargin'
      case metricPermissions.readProfit:
        return 'allProfit'
      default:
        return 'noPermission'
    }
  }

  const hasMetricPermission =
    !!metricPermissions.readMargin ||
    !!metricPermissions.readProfit ||
    !!metricPermissions.readVolume

  const metricFilter = useReactiveVar(metricVar)
  const [metric, setMetric] = useState(
    setInitialMetricFilter(metricPermissions, metricFilter)
  )
  const metricType = hasMetricPermission ? getMetricTypeFromField(metric) : null

  const setSelectedMetric = selectedMetric => {
    setMetric(selectedMetric)
    metricVar(selectedMetric)
  }

  useEffect(() => {
    if (!hasBunkered && metricFilter === 'businessProfit') {
      setMetric('allProfit')
    }
  })

  const [highlight, setHighlight] = useState(null)

  /* istanbul ignore next */
  const handleChartMouseOver = highlight => {
    if (data.length < 30) {
      setHighlight(highlight)
    }
  }
  /* istanbul ignore next */
  const handleChartMouseOut = () => {
    setHighlight(null)
  }

  const { cashCreditPricing, selectedPricingType } = usePricingTypes()

  const { error: error1, data: data1, loading: loadingData1 } = useQuery(
    PERFORMANCE_REPORT_QUERY,
    {
      variables: {
        stationIds: stationIds,
        fuelGradeIds: selectedFuelGrades,
        dateRange: dateRangeToString(companyTimeZone, period1),
        pricingTypes: [selectedPricingType],
        margin: metricPermissions.readMargin,
        profit: metricPermissions.readProfit,
      },
      skip: !stationIds?.length || !selectedFuelGrades?.length,
    }
  )
  useErrorHandler(error1)

  const { error: error2, data: data2, loading: loadingData2 } = useQuery(
    PERFORMANCE_REPORT_QUERY,
    {
      variables: {
        stationIds: stationIds,
        fuelGradeIds: selectedFuelGrades,
        dateRange: dateRangeToString(companyTimeZone, period2),
        pricingTypes: [selectedPricingType],
        margin: metricPermissions.readMargin,
        profit: metricPermissions.readProfit,
      },
      skip: !stationIds?.length || !selectedFuelGrades?.length || !period2,
    }
  )
  useErrorHandler(error2)

  const [data, setData] = useState([])
  const [previousLine, setPreviousLine] = useState(false)

  useEffect(() => {
    if (!hasMetricPermission) {
      return
    }

    if (data1) {
      let dataArray = []
      const metricsToGraph = getMetricsToLineGraph(period2, metric, hasBunkered)
      metricsToGraph.forEach(metric => {
        const data = performanceReportToData(data1, metric)
        setPreviousLine(false)
        if (
          data2?.performanceReport.length === data1.performanceReport.length
        ) {
          const previousData = performanceReportToData(data2, metric)
          for (let i = 0; i < data.length; i++) {
            data[i].previousDate = previousData[i].date
            data[i].previousValue = previousData[i].value
          }
          setPreviousLine(true)
        }
        dataArray.push(data)
      })

      setData(dataArray)
    } else {
      setData([])
    }
  }, [data1, data2, metric, period2, hasMetricPermission])

  /* istanbul ignore next */
  const handleStationsChange = stationIds => {
    setStationIds(stationIds)
  }

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <span className={cx('title')}>
          {upperFirstOnly(t('widgets.performanceReport.title'))}
        </span>
        {cashCreditPricing && (
          <span className={cx('cash-credit-pricing')}>
            ({upperFirstOnly(selectedPricingType)})
          </span>
        )}
      </div>
      <div className={cx('select-boxes')}>
        {!stationId && (
          <StationSelector
            value={stationIds}
            onValueChange={handleStationsChange}
            leftAccessory={<StationsIcon className={cx('stations-icon')} />}
            placeholder={upperFirstOnly(
              t('widgets.performanceReport.noStationsSelected')
            )}
            allStationsText={upperFirstOnly(
              t('widgets.performanceReport.allStationsSelected')
            )}
            maxHeight="228px"
          />
        )}
        <GradeDropdown
          options={allFuelGradesTitles || []}
          value={fuelGrades || []}
          onValueChange={setSelectedGrades}
        />
        <PeriodDropdown
          period1={period1}
          period2={period2}
          onPeriod1Change={setSelectedPeriodOne}
          onPeriod2Change={setSelectedPeriodTwo}
          companyTimeZone={companyTimeZone}
        />
        {!!stationId && <div className={cx('select-boxes-blank')} />}
      </div>
      <MetricSelector
        value={metric}
        onValueChange={setSelectedMetric}
        showVolumeSecondRow={!!period2}
        hasBunkered={hasBunkered}
        metricPermissions={metricPermissions}
      />
      {loadingData1 || loadingData2 ? (
        <div className={cx('performance-widget-spinner')}>
          <Spinner animation="grow" variant="primary" />
        </div>
      ) : (
        <Chart
          data={data}
          metric={metricType}
          onMouseOver={handleChartMouseOver}
          onMouseOut={handleChartMouseOut}
          previousLine={previousLine}
          lineClass={metric}
          metrics={getMetricsToLineGraph(period2, metric, hasBunkered)}
          area={
            !period2 &&
            ['totalVolume', 'fuelCardVolume', 'bunkeredVolume'].includes(
              metric
            ) &&
            [
              'totalVolume',
              'fuelCardVolume',
              hasBunkered && 'bunkeredVolume',
            ].filter(Boolean)
          }
          period1={period1}
          period2={period2}
        />
      )}
      {showPerformanceExport && hasMetricPermission && (
        <ExportButton
          dataOne={data1}
          dataTwo={data2}
          metrics={getMetricsToLineGraph(period2, metric, hasBunkered)}
          hasComparative={previousLine}
          metricType={metricType}
          stationData={stationData}
          selectedPricingType={selectedPricingType}
        />
      )}

      {hasMetricPermission && (
        <DataTable
          data={data}
          metrics={getMetricsToLineGraph(period2, metric, hasBunkered)}
          period1={period1}
          metricType={metricType}
          highlight={highlight}
          previousColumn={previousLine}
        />
      )}
    </div>
  )
}

PerformanceReport.propTypes = {
  stationId: PropTypes.string,
  stationData: PropTypes.shape({
    fuelGrades: PropTypes.arrayOf(
      PropTypes.shape({
        fuelGrade: PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
        }),
      })
    ),
  }),
  hasBunkered: PropTypes.bool.isRequired,
}

PerformanceReport.defaultProps = {
  stationId: null,
  stationData: null,
}

const FallbackComponent = () => {
  const { t } = useTranslation(['translations'])

  return (
    <WidgetFallback
      title={upperFirstOnly(t('widgets.performanceReport.title'))}
    />
  )
}

const PerformanceReportWithErrorBoundary = withEdgeErrorBoundary(
  PerformanceReport,
  FallbackComponent
)

export { PerformanceReportWithErrorBoundary as PerformanceReport }
