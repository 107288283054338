import { gql } from '@apollo/client'

const COMPETITOR_ANALYSIS_QUERY = gql`
  query competitorAnalysis(
    $stationIds: [ID!]!
    $fuelGradeIds: [ID!]!
    $currentPeriodStartDate: String!
    $currentPeriodEndDate: String!
    $timeResolution: String
    $stationGroupNames: [String]
    $activeTab: String
  ) {
    competitorAnalysis(
      input: {
        stationIds: $stationIds
        fuelGradeIds: $fuelGradeIds
        currentPeriodStartDate: $currentPeriodStartDate
        currentPeriodEndDate: $currentPeriodEndDate
        timeResolution: $timeResolution
        stationGroupNames: $stationGroupNames
        activeTab: $activeTab
      }
    ) {
      dates
      yourAveragePolePrice
      averageCompetitorPolePrice
      minCompetitorPolePrice
      maxCompetitorPolePrice
    }
  }
`

export { COMPETITOR_ANALYSIS_QUERY }
