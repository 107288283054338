import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import {
  fuelGradePriceChangesVar,
  resetVariables,
  stationPriceChangesVar,
  statusVar,
} from '../variables'

const { NONE, EDIT, CONFIRM, SEND, SENT, ERROR } = StationsListPricingStatus

const setStatus = status => {
  statusVar(status)
}

const setNoneStatus = () => {
  statusVar(NONE)
  resetVariables()
}

/**
 * Runs when the user selects to enter stations list pricing.
 *
 * Sets default price changes for both fuel grades and stations:
 * - fuel grade price changes set to 0 for all portfolio grades
 * - station price changes set to null for each of the user's stations
 */
const setEditStatus = (fuelGrades, stations) => {
  const fuelGradePriceChanges = fuelGrades.map(({ id }) => ({
    fuelGradeId: id,
    priceChange: 0,
  }))
  fuelGradePriceChangesVar(fuelGradePriceChanges)

  const stationPriceChanges = stations.reduce(
    (object, { id }) => ({ ...object, [id]: null }),
    {}
  )
  stationPriceChangesVar(stationPriceChanges)

  statusVar(EDIT)
}

/**
 * Run when the user leaves the stations list while pricing. Ensures when the user returns the pricing status is valid.
 */
const resetStatus = () => {
  const status = statusVar()
  if (status === SEND) {
    statusVar(CONFIRM)
  } else if ([SENT, ERROR].includes(status)) {
    setNoneStatus()
  }
}

export { setStatus, setNoneStatus, setEditStatus, resetStatus }
