import { gql } from '@apollo/client'

const STATION_CHANGE_REQUESTS_QUERY = gql`
  query stationChangeRequestsQuery(
    $pricingType: PricingType!
    $stationIds: [ID!]!
    $fuelGradePriceChanges: [FuelGradePriceChangeInput!]!
    $stationPriceChanges: [StationPriceChangeInput!]!
  ) {
    stationChangeRequests(
      input: {
        pricingType: $pricingType
        stationIds: $stationIds
        fuelGradePriceChanges: $fuelGradePriceChanges
        stationPriceChanges: $stationPriceChanges
      }
    ) {
      stationId
      name
      brand {
        id
        name
      }
      cashCreditPricing
      pendingRequests {
        newPrice
        price
        fuelGradeId
        pricingType
      }
    }
  }
`

const CURRENT_MARGIN_QUERY = gql`
  query currentMargin($metricsPricingTypes: [PricingType!]!) {
    company {
      metrics(pricingTypes: $metricsPricingTypes) {
        grossMargin {
          value
        }
      }
    }
  }
`

const ESTIMATED_MARGIN_QUERY = gql`
  query estimatedMargin(
    $stationIds: [ID!]
    $fuelGradePriceChanges: [FuelGradePriceChangeInput!]
    $stationPriceChanges: [StationPriceChangeInput!]
  ) {
    estimatedMargin(
      input: {
        stationIds: $stationIds
        fuelGradePriceChanges: $fuelGradePriceChanges
        stationPriceChanges: $stationPriceChanges
      }
    ) {
      estimatedMargin
    }
  }
`

export {
  STATION_CHANGE_REQUESTS_QUERY,
  CURRENT_MARGIN_QUERY,
  ESTIMATED_MARGIN_QUERY,
}
