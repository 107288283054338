import { InMemoryCache, gql } from '@apollo/client'
import { PricingTypes } from 'utils/constants'
import {
  SortDirections,
  SortFields,
} from 'components/stations-list/utils/constants'
import { sortedCompetitors } from './sorted-competitors'
import {
  queryTypePolicy,
  stationsPageInfoTypePolicy,
  stationItemPricingSetTypePolicy,
  fuelGradeTypePolicy,
  stationGroupSetTypePolicy,
  stationCompetitorTypePolicy,
} from './type-policies'

const { ASC } = SortDirections
const { NAME } = SortFields
const { ALL } = PricingTypes

const cache = new InMemoryCache({
  typePolicies: {
    Station: {
      fields: {
        sortedCompetitors,
      },
    },
    StationCompetitor: stationCompetitorTypePolicy,
    Query: queryTypePolicy,
    FuelGrade: fuelGradeTypePolicy,
    StationsPageInfo: stationsPageInfoTypePolicy,
    StationItemPricingSet: stationItemPricingSetTypePolicy,
    StationGroupSet: stationGroupSetTypePolicy,
  },
})

const CACHE_QUERY = gql`
  query cacheQuery {
    cacheInitialised
    companyTimeZone
    pricingTypes {
      types
      selected
    }
    stationsList {
      selectedSort {
        field
        direction
      }
      selectedFilters
      selectedGroupBy
    }
  }
`

const initialState = {
  cacheInitialised: false,
  companyTimeZone: null,
  pricingTypes: {
    types: [ALL],
    selected: ALL,
    __typename: 'PricingTypesSet',
  },
  stationsList: {
    selectedSort: {
      field: NAME,
      direction: ASC,
      __typename: 'StationsListSort',
    },
    selectedFilters: [],
    selectedGroupBy: null,
    __typename: 'StationsListSet',
  },
}

/**
 * Function to reset the cache to it's initial state.
 */
const resetCache = async client => {
  client.stop()
  await client.clearStore()
  client.writeQuery({
    query: CACHE_QUERY,
    data: initialState,
  })
}

// initialise the cache
cache.writeQuery({
  query: CACHE_QUERY,
  data: initialState,
})

export { cache, resetCache, initialState, CACHE_QUERY }
