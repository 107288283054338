import React, { useState, useEffect, useRef, useCallback } from 'react'
import { PropTypes } from 'prop-types'
import { getComparativePeriod } from 'utils/helpers'
import { TrendIndicator } from 'components/common/trend-indicator'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import classNames from 'classnames/bind'
import styles from './Selector.module.scss'
import { Placeholder } from 'components/common/placeholder'
import { Permissioned } from 'components/common/permissioned'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const Selector = ({
  selectedIndex,
  id,
  metricType,
  handleHoveredGrade,
  selectedGrade,
  handleSelectedGrade,
  handleSelectedGradeColour,
  shortTitle,
  value,
  comparativeValue,
  trend,
  primary,
  secondary,
  hasMetricPermission,
  isOverlayMode,
}) => {
  const { formatMetricValue } = useFormatMetricValue()
  const [hovered, setHovered] = useState(false)
  const selector = useRef()

  const handleMouseOver = useCallback(() => {
    setHovered(true)
    handleHoveredGrade(id)
  }, [handleHoveredGrade, id])

  const handleMouseLeave = useCallback(() => {
    setHovered(false)
    handleHoveredGrade(null)
  }, [handleHoveredGrade])

  useEffect(() => {
    const { current } = selector
    current.addEventListener('mouseenter', handleMouseOver)
    current.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      current.removeEventListener('mouseenter', handleMouseOver)
      current.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [handleMouseLeave, handleMouseOver])

  const isSelected = selectedGrade === id

  const selectedColourStyle = () => {
    if (isSelected) {
      return { background: `${secondary}` }
    } else {
      return hovered ? { background: `${secondary}` } : { background: `` }
    }
  }

  const handleClick = () => {
    handleSelectedGrade(id)
    handleSelectedGradeColour(secondary, selectedIndex)
    amplitudeEvent(
      UserEvents.StationPerformanceFuelGrade.SELECTED(isOverlayMode, shortTitle)
    )
  }

  const comparativePeriod = getComparativePeriod(metricType)

  return (
    <div
      ref={selector}
      className={cx('selector-container', isSelected ? 'selected' : '')}
      style={selectedColourStyle()}
      onClick={() => handleClick()}
      data-testid="selector-container"
    >
      <div className={cx('grade-title')} style={{ background: `${primary}` }}>
        {shortTitle}
      </div>

      <Permissioned
        permission={hasMetricPermission}
        fallback={<Placeholder fixedWidth={60} />}
      >
        <div className={cx('grade-total')}>
          {formatMetricValue(metricType, value)}
        </div>
        <TrendIndicator
          tooltip={true}
          trend={trend}
          comparative={comparativeValue}
          comparativeType={metricType}
          comparativePeriod={comparativePeriod}
          tooltipPlacement={'top'}
        />
      </Permissioned>
      {isSelected && (
        <div className={cx('external-corner')}>
          <div className={cx('external-corner-container')}>
            <svg className={cx('svg')}>
              <rect
                className={cx('svg-rect')}
                style={{ fill: `${secondary}` }}
              ></rect>
              <circle className={cx('svg-circle')}></circle>
            </svg>
          </div>
        </div>
      )}
    </div>
  )
}

Selector.propTypes = {
  metricType: PropTypes.string.isRequired,
  selectedGrade: PropTypes.string.isRequired,
  handleSelectedGrade: PropTypes.func.isRequired,
  shortTitle: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  trend: PropTypes.number.isRequired,
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  handleHoveredGrade: PropTypes.func.isRequired,
  handleSelectedGradeColour: PropTypes.func.isRequired,
  comparativeValue: PropTypes.number.isRequired,
  hasMetricPermission: PropTypes.bool.isRequired,
  isOverlayMode: PropTypes.bool,
}

Selector.defaultProps = { isOverlayMode: false }

export { Selector }
