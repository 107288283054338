import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EditCancelIcon } from 'images/common/cancel.svg'
import { Textarea } from 'components/common/textarea'
import classNames from 'classnames/bind'
import styles from './EditStrategy.module.scss'
import { Options } from './components/options'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const EditStrategy = ({ stationId, stationStrategy, onClose }) => {
  const [newStrategy, setNewStrategy] = useState(
    stationStrategy?.strategy || ''
  )

  let eventStatus = React.useRef('initialLoad')

  useEffect(() => {
    if (stationStrategy?.strategy !== newStrategy) {
      if (eventStatus.current === 'initialLoad') {
        eventStatus.current = 'loaded'
        return
      }

      if (eventStatus.current === 'loaded') {
        amplitudeEvent(UserEvents.CompetitorPricing.PRICING_STRATEGY_ENTERED)
        eventStatus.current = 'sentEvent'
      }
    }
  }, [newStrategy, stationStrategy?.strategy])

  const { t } = useTranslation()

  return (
    <div className={cx('edit-station-strategy')}>
      <EditCancelIcon
        className={cx('cancel')}
        onClick={onClose}
        data-testid="cancel"
      />
      <div className={cx('title')}>
        {t('widgets.competitorPricing.pricingStrategy')}
      </div>
      <div className={cx('value')}>
        <Textarea
          testId="edit-strategy-textarea"
          value={newStrategy}
          onValueChange={setNewStrategy}
        />
      </div>
      <Options
        stationId={stationId}
        stationStrategy={stationStrategy}
        newStrategy={newStrategy}
        onClose={onClose}
      />
    </div>
  )
}

EditStrategy.propTypes = {
  stationId: PropTypes.string.isRequired,
  stationStrategy: PropTypes.shape({
    strategy: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
}

EditStrategy.defaultProps = {
  stationStrategy: null,
}

export { EditStrategy }
