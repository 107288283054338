import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames/bind'
import { ReactComponent as LoadingIcon } from 'images/stations-list/loading.svg'
import { isNullOrUndefined } from 'utils/helpers'
import { statusVar } from 'config/apollo/stations-list-pricing/variables'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { GroupedPricingProvider } from 'components/stations-list/context/groupedPricingContext'
import { useStationsListOptions } from 'components/stations-list/hooks/useStationsListOptions'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { EditPricingSelector } from './components/edit-pricing/edit-pricing-selector'
import { ConfirmPriceChanges } from './components/edit-pricing/confirm-price-changes'
import { HeaderRow } from './components/header-row/index'
import { OptionsRow } from './components/options-row'
import { TableBody } from './components/table-body'
import { GroupedTableBody } from './components/grouped-table-body'
import styles from './StationsTable.module.scss'

const cx = classNames.bind(styles)

const { NONE, CONFIRM, SEND, SENT, ERROR } = StationsListPricingStatus

const StationsTable = ({ timestamp, mainLoading, mainData }) => {
  const { selectedPricingType, cashCreditPricing } = usePricingTypes()
  const options = useStationsListOptions()
  const status = useReactiveVar(statusVar)
  const stationsListPricingStatus = useReactiveVar(statusVar)
  const { showStationsListPricing } = useFlags()

  useEffect(() => {
    return () => {
      stationsListPricingOperations.resetStatus()
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [status])

  if (mainLoading) {
    return (
      <div className={cx('loading')}>
        <LoadingIcon height={100} width={100} />
      </div>
    )
  }

  const {
    me: {
      fuelGrades,
      stations: { groupBys, filters, items },
    },
  } = mainData
  const { selectedGroupBy } = options

  const showPricingView = showStationsListPricing && status !== NONE
  const showConfirm = [CONFIRM, SEND, SENT, ERROR].includes(status)

  if (showPricingView && showConfirm) {
    return (
      <div className={cx('table-container')}>
        <ConfirmPriceChanges
          status={status}
          selectedPricingType={selectedPricingType}
          fuelGrades={fuelGrades}
        />
      </div>
    )
  }

  const body = !isNullOrUndefined(selectedGroupBy) ? (
    <GroupedPricingProvider>
      <GroupedTableBody
        mainData={mainData}
        timestamp={timestamp}
        options={options}
        showPricingView={showPricingView}
        selectedPricingType={selectedPricingType}
        cashCreditPricing={cashCreditPricing}
      />
    </GroupedPricingProvider>
  ) : (
    <TableBody
      mainData={mainData}
      timestamp={timestamp}
      options={options}
      showPricingView={showPricingView}
      selectedPricingType={selectedPricingType}
      cashCreditPricing={cashCreditPricing}
    />
  )

  return (
    <div className={cx('table-container')}>
      <EditPricingSelector
        status={stationsListPricingStatus}
        showStationsListPricing={showStationsListPricing}
        showPricingView={showPricingView}
        fuelGrades={fuelGrades}
        stations={items}
        selectedPricingType={selectedPricingType}
        cashCreditPricing={cashCreditPricing}
      />
      <OptionsRow filters={filters} groupBys={groupBys} options={options} />
      <HeaderRow options={options} showPricingView={showPricingView} />
      {body}
    </div>
  )
}

StationsTable.propTypes = {
  timestamp: PropTypes.string.isRequired,
  mainLoading: PropTypes.bool.isRequired,
  mainData: PropTypes.shape(),
}

StationsTable.defaultProps = {
  mainData: null,
}

export { StationsTable }
