import { gql } from '@apollo/client'

const USER_CATEGORIES_QUERY = gql`
  query userCategories {
    userCategories{
        parent
    }
  }
`

export { USER_CATEGORIES_QUERY }
