const PolePriceSources = {
  TRANSACTION: 'transaction',
  USER_DEFINED: 'user_defined',
}

const SortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
}

const CompetitorSorts = {
  DRIVING_DISTANCE_SORT: 'DRIVING_DISTANCE_SORT',
  FUEL_GRADE_SORT: 'FUEL_GRADE_SORT',
}

const ManualInputValueRegex = {
  GB: /^(?:[1-9]\d{0,2}|0)\.\d$/,
  US: /^(?:[1-9]\d{0}|0)\.\d{2}$/,
}

export {
  PolePriceSources,
  SortDirections,
  CompetitorSorts,
  ManualInputValueRegex,
}
