import { gql } from '@apollo/client'
import { USER_PERMISSION_ATTRIBUTES } from 'config/apollo/fragments'

const COMPANY_METRICS_ATTRIBUTES = gql`
  fragment CompanyMetricsAttributes on CompanyMetrics {
    value
    comparativeValue
    trend
  }
`

const COMPANY_PERFORMANCE_QUERY = gql`
  query companyPerformanceQuery(
    $supportedPricingTypes: [PricingType!]!
    $showTotalVolume: Boolean!
  ) {
    me {
      id
      ...UserPermissionAttributes
    }
    company {
      id
      metrics(pricingTypes: $supportedPricingTypes) {
        pricingType
        totalVolume @include(if: $showTotalVolume) {
          ...CompanyMetricsAttributes
        }
        retailVolume @skip(if: $showTotalVolume) {
          ...CompanyMetricsAttributes
        }
        grossMargin {
          ...CompanyMetricsAttributes
        }
        netMargin {
          ...CompanyMetricsAttributes
        }
        grossProfit {
          ...CompanyMetricsAttributes
        }
      }
    }
  }
  ${USER_PERMISSION_ATTRIBUTES}
  ${COMPANY_METRICS_ATTRIBUTES}
`

export { COMPANY_PERFORMANCE_QUERY }
