import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TimelineItem } from './components/timeline-item'
import styles from './Timeline.module.scss'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const cx = classNames.bind(styles)

const Timeline = ({
  timelineData,
  selectedDay,
  setSelectedDay,
  predictionsReady,
}) => {
  const { t } = useTranslation()
  const timelineItems = timelineData.map(({ date, warnings, stockOuts }, i) => {
    return predictionsReady ? (
      <TimelineItem
        key={i}
        day={i}
        date={date}
        warnings={warnings}
        stockOuts={stockOuts}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        predictionsReady={predictionsReady}
      />
    ) : (
      <TimelineItem key={i} date={date} day={i} />
    )
  })

  const tooltip = (
    <Tooltip className={cx('timeline-tooltip')}>
      <div className={cx('timeline-tooltip-text')}>
        {t('widgets.buysmartAnalysis.notReadyTooltip')}
      </div>
    </Tooltip>
  )

  return (
    <div className={cx('timeline-container')}>
      <div className={cx('timeline')}>
        {predictionsReady ? (
          <div className={cx('timeline-items')}>{timelineItems}</div>
        ) : (
          <OverlayTrigger placement={'top'} overlay={tooltip}>
            <div className={cx('timeline-items')}>{timelineItems}</div>
          </OverlayTrigger>
        )}
      </div>
    </div>
  )
}

Timeline.propTypes = {
  timelineData: PropTypes.array.isRequired,
  selectedDay: PropTypes.number.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
  predictionsReady: PropTypes.bool,
}

Timeline.defaultProps = {
  selectedDay: 0,
  setSelectedDay: () => {},
  predictionsReady: false,
}

export { Timeline }
