import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { findChangedPendingRequest } from 'components/station/components/competitor-pricing/utils/helpers'
import { usePriceChange } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { CashCreditDifferential } from '../../../cash-credit-differential'
import { PriceButton } from '../../../price-button'
import { ManualInput } from '../../../manual-input'
import styles from './EditPrice.module.scss'

const cx = classNames.bind(styles)

const EditPrice = ({
  stationId,
  polePrice,
  stationCashCreditPricing,
  fuelGradeDifferential,
  cashCreditDifferential,
  sliderDispatch,
}) => {
  const { fuelGradeId, price, pricingType } = polePrice
  const [manualInputEnabled, setManualInputEnabled] = useState(true)
  const [{ pendingRequests }, dispatch] = usePriceChange()
  const { formatPolePrice } = useFormatPolePrice()
  const inputRef = useRef()

  const pendingRequest = findChangedPendingRequest(
    pendingRequests,
    pricingType,
    fuelGradeId
  )

  const showPriceButtonsAndManualInput =
    !fuelGradeDifferential || fuelGradeDifferential?.leadGrade

  const newPrice = pendingRequest?.newPrice

  return (
    <>
      {stationCashCreditPricing && (
        <CashCreditDifferential
          stationId={stationId}
          fuelGradeId={fuelGradeId}
          cashCreditDifferential={cashCreditDifferential}
          sliderDispatch={sliderDispatch}
        />
      )}
      <div className={cx('current-price')}>
        {newPrice && formatPolePrice(price)}
      </div>
      <div className={cx('price-info')}>
        <div className={cx('price-value')}>
          {showPriceButtonsAndManualInput ? (
            <ManualInput
              newPrice={newPrice}
              enabled={manualInputEnabled}
              setEnabled={setManualInputEnabled}
              price={price}
              fuelGradeId={fuelGradeId}
              pricingType={pricingType}
              inputRef={inputRef}
              dispatch={dispatch}
            />
          ) : newPrice ? (
            formatPolePrice(newPrice)
          ) : (
            formatPolePrice(price)
          )}
        </div>
        {showPriceButtonsAndManualInput && (
          <div className={cx('buttons')}>
            <PriceButton
              setManualInputEnabled={setManualInputEnabled}
              dispatch={dispatch}
              minus
              fuelGradeId={fuelGradeId}
              pricingType={pricingType}
            />
            <PriceButton
              setManualInputEnabled={setManualInputEnabled}
              dispatch={dispatch}
              fuelGradeId={fuelGradeId}
              pricingType={pricingType}
            />
          </div>
        )}
      </div>
    </>
  )
}

EditPrice.propTypes = {
  stationId: PropTypes.string.isRequired,
  polePrice: PropTypes.shape().isRequired,
  stationCashCreditPricing: PropTypes.bool.isRequired,
  fuelGradeDifferential: PropTypes.shape(),
  cashCreditDifferential: PropTypes.shape(),
  sliderDispatch: PropTypes.func.isRequired,
}

EditPrice.defaultProps = {
  fuelGradeDifferential: null,
  cashCreditDifferential: null,
}

export { EditPrice }
