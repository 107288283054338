import { gql } from '@apollo/client'

const typeDefs = gql`
  type PricingTypesSet {
    selected: String
    types: [PricingType!]
  }

  type StationsListSort {
    field: StationSortField!
    direction: SortDirection!
  }

  type StationsListFilter {
    name: String!
    operator: StationFilterOperator!
    value: String
  }

  type StationsListSet {
    selectedSort: StationsListSort!
    selectedFilters: [StationsListFilter!]
    selectedGroupBy: String!
  }

  extend type Query {
    """
    Marks that the cache has been initialised using the user's locale.
    """
    cacheInitialised: Boolean!

    """
    Holds the company level time zone for the current user.
    """
    companyTimeZone: String!

    """
    Holds the available and selected pricing types for the current user.
    """
    pricingTypes: PricingTypesSet!

    """
    Holds options for the stations list page
    """
    stationsList: StationsListSet!
  }
`

export { typeDefs }
