import { useMutation } from '@apollo/client'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { CREATE_POLE_PRICE_CHANGE_REQUESTS_MUTATION } from 'config/apollo/mutations'
import { useErrorHandler } from 'react-error-boundary'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { removeTypename } from 'utils/format'
import { parseToActionAt } from 'utils/pricing'

const { ERROR, SENT } = StationsListPricingStatus

const onCompleted = data => {
  const hasError = data.createPolePriceChangeRequests.some(({ error }) => error)
  stationsListPricingOperations.setStatus(hasError ? ERROR : SENT)
}

function useCreatePolePriceChangeRequests(
  stationChangeRequests,
  reminders,
  actionAt
) {
  const { companyTimeZone } = useCompanyTimeZone()
  const [mutation, { loading, called, data, error }] = useMutation(
    CREATE_POLE_PRICE_CHANGE_REQUESTS_MUTATION,
    {
      onCompleted,
    }
  )
  useErrorHandler(error)

  const createPolePriceChangeRequestsMutation = () => {
    const toActionAt = parseToActionAt(actionAt, companyTimeZone)

    const stationPolePriceChangeRequests = stationChangeRequests.map(
      ({ stationId, pendingRequests }) => {
        const polePriceChangeRequests = pendingRequests.map(pendingRequest =>
          removeTypename(pendingRequest)
        )
        return {
          toActionAt,
          stationId,
          polePriceChangeRequests,
        }
      }
    )

    return mutation({
      variables: {
        stationPolePriceChangeRequests,
        reminders,
      },
    })
  }

  return { createPolePriceChangeRequestsMutation, loading, called, data }
}

export { useCreatePolePriceChangeRequests }
