import React from 'react'
import PropTypes from 'prop-types'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly, yAxisLabel } from 'utils/format'
import classNames from 'classnames/bind'
import styles from './PortfolioBenchmarkChart.module.scss'
import { ReactComponent as MetricUpArrow } from 'images/performance-report/metric-up.svg'
import { ReactComponent as MetricDownArrow } from 'images/performance-report/metric-down.svg'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { timePeriodFormat, nextRoundedNumber, previousRoundedNumber, maxYaxisValue, minYaxisValue } from 'utils/helpers'
import moment from 'moment'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
    ResponsiveContainer,
    Label,
    Bar,
    ComposedChart,
  } from 'recharts'

const cx = classNames.bind(styles)

const PortfolioBenchmarkChart = ({ 
  data,
  metricType,
  metricName,
  timeResolution,
  isEdgeDataPro
}) => {
  const { t } = useTranslation(['translations'])
  const { formatMetricValue } = useFormatMetricValue()

  const CustomTooltip = ({ active, payload, label }) => {  
    if (active && payload?.length) {
      const hideWeekendDataset = payload.filter(item => item.dataKey !== 'weekend')
      const timePeriodLabel = () => {
        if (timeResolution === 'Weekly') {
          return 'Week of ' + label
        } else if (timeResolution === 'Daily') {
          const parsedDate = moment(label, "Do MMM")
          const dayOfWeek = parsedDate.format("dddd")
          return `${dayOfWeek} ${label}`
        } else {
          return label
        }
      }

      const calculateMetricChange = () => {
        if (isEdgeDataPro) {
          return hideWeekendDataset
        }

        const payload = hideWeekendDataset
    
        return payload.reduce((result, item, index) => {
          if (index % 2 === 0) {
            const { value: newValue } = item
            const { value: oldValue } = payload[index + 1]
    
            const percentageChange =
            oldValue === "0.0" ? '' : (((newValue - oldValue) / oldValue) * 100).toFixed(0)
    
          result.push([percentageChange])
            
          }
          return result
        }, [])
      }
  
      return (
       <div className={cx('tooltip')}>
          <p className={cx('tooltip-date')}>{timePeriodLabel()}</p>
          <table className={cx('tooltip-periods')}>
            <tbody>
              <tr>
                {calculateMetricChange().map((metric, index) => (
                  <React.Fragment key={index}>
                    <th>{upperFirstOnly(t('widgets.performanceReport.thisPeriod'))}
                      {(metric) > 0 &&
                        <>
                          <MetricUpArrow />
                          <span className={cx('metric-change-up')}>
                            {metric}%
                          </span>
                        </>
                      }
                      {(metric) < 0 &&
                        <>
                          <MetricDownArrow />
                          <span className={cx('metric-change-down')}>
                            {-metric}%
                          </span>
                        </>
                      }
                    </th>
                    {!isEdgeDataPro && <th>{upperFirstOnly(t('widgets.performanceReport.thisPeriod'))}</th>}
                  </React.Fragment>
                ))}
              </tr>
              <tr>
                {hideWeekendDataset.map(({ value, color }) =>
                  <td key={color} style={{ color }}>{formatMetricValue(metricType, value, true, true)}</td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  
    return null
  }

  const CustomYAxisTick = ({ x, y, payload }) => {    
    return (
      <g transform={`translate(${x},${y})`}>
        <text textAnchor="end" fill="#b2bfca" >
          {formatMetricValue(metricType, payload.value, true, true)}
        </text>
      </g>
    )
  }

  const formatData = () => {
    if (!data?.portfolioBenchmark || !data.portfolioBenchmark.dates) {
      return null
    }
  
    const { portfolioBenchmark } = data
    const dates = portfolioBenchmark.dates
  
    return dates.map((date, index) => {
      const formatDataPoint = (key) => {
        const value = parseFloat(portfolioBenchmark[key][index])
        const formattedValue = isNaN(value) ? null : value
  
        return {
          [key]: formattedValue,
        }
      };
  
      const isWeekend = (date) => {
        const day = new Date(date).getDay();
        return day === 0 || day === 6;
      };
      return {
        name: timePeriodFormat(date, dates, timeResolution),
        ...(!isEdgeDataPro && formatDataPoint('yourPortfolio')),
        ...formatDataPoint('averageEdgepetrolCompany'),
        ...(isWeekend(date) && { weekend: nextRoundedNumber(maxDataPoint) }),
      }
    })  
  }
  
  const getLineConfig = (dataKey, color) => ({
    type: 'monotone',
    dataKey,
    stroke: color,
    strokeWidth: 2,
    dot: { stroke: color, strokeWidth: 1, fill: color },
  })

  const LineData = () => {
    if (isEdgeDataPro) {
      return {
        averageEdgepetrolCompany: {
          legend: "Average EdgePetrol site",
          color: '#39c258'
        }
      }
    } else {
      return {
        yourPortfolio: {
          legend: "Your portfolio",
          color: '#39c258'
        },
        averageEdgepetrolCompany: {
          legend: "Average EdgePetrol site",
          color: '#7c44d2'
        }
      }
    }
  }
  
  const legendFormatter = (value) => {
    let legend = LineData()[value] ? LineData()[value].legend : value

    return (
      <span style={{ color: '#b2bfca' }}>
        {legend}
      </span>
    )
  }
  
  const maxDataPoint = maxYaxisValue(data?.portfolioBenchmark, LineData())
  const maxAxisYValue = timeResolution === 'Daily' ? nextRoundedNumber(maxDataPoint) : 'auto'
  const minDataPoint = minYaxisValue(data?.portfolioBenchmark, LineData())
  const minAxisYValue = timeResolution === 'Daily' ? previousRoundedNumber(minDataPoint) : 'auto'

  const filteredLineData = Object.keys(LineData()).map(key => ({
    value: key,
    color: LineData()[key].color
  }))
  
  return (
    <div className={cx('container')}>
      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart 
          className={cx('chart')}
          data={formatData()}
          margin={{ left: 45 }}
        >
          <CartesianGrid strokeDasharray="3 3" className={cx('grid')} />
          <XAxis
            dataKey="name"
            tick={{ fill: "#b2bfca" }}
            stroke="#eee"
            fontSize={13}
            background={{ fill: '#7c44d2' }}
          />
          <YAxis tick={<CustomYAxisTick />} stroke="none" domain={[minAxisYValue, maxAxisYValue]}>
            <Label
              value={t(`widgets.analytics.yAxisLabels.${yAxisLabel(metricName)}`)}
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: 'middle', fontSize: '15px' }}
              dx={-44}
            />
          </YAxis>
          <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip/>} />       
          { timeResolution === 'Daily' && 
            <Bar dataKey="weekend" stroke="none" fill="#f6f8fa" barSize={600} legendType="none" />}
          { !isEdgeDataPro && <Line {...getLineConfig('yourPortfolio', LineData().yourPortfolio.color)} /> }
          <Line {...getLineConfig('averageEdgepetrolCompany', LineData().averageEdgepetrolCompany.color)} />
          <Legend formatter={legendFormatter} payload={filteredLineData} />          
        </ComposedChart>
        
      </ResponsiveContainer>
    </div>
  )
}

const FallbackComponent = () => <WidgetFallback />

const ChartWithErrorBoundary = withEdgeErrorBoundary(
PortfolioBenchmarkChart,
  FallbackComponent
)

PortfolioBenchmarkChart.propTypes = {
  metricType: PropTypes.string,
  metricName: PropTypes.string,
  timeResolution: PropTypes.string,
  isEdgeDataPro: PropTypes.bool,
  data: PropTypes.shape({
    portfolioBenchmark: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      yourPortfolio: PropTypes.arrayOf(PropTypes.string),
      companiesSimilarToYou: PropTypes.arrayOf(PropTypes.string),
      averageEdgepetrolCompany: PropTypes.arrayOf(PropTypes.string),
    })
  })
}

export { ChartWithErrorBoundary as PortfolioBenchmarkChart }
