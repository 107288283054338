import React from 'react'
import PropTypes from 'prop-types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useTranslation } from 'react-i18next'
import { StatusInformation } from 'components/common/status-information'
import {
  StationStatus,
  StationDataStatus,
  StationDataTypes,
} from 'utils/constants'
import { upperFirstOnly } from 'utils/format'
import { useFormatStatus } from 'components/common/hooks/useFormatStatus'
import classNames from 'classnames/bind'
import styles from './SingleSiteStatus.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { useFlags } from 'launchdarkly-react-client-sdk'

const cx = classNames.bind(styles)

const { RED, AMBER } = StationDataStatus
const { TRANSACTION, DIP, COST_PRICE } = StationDataTypes

const ParseStatus = (
  stationStatus,
  costPriceStatus,
  dipStatus,
  transactionStatus
) => {
  const { showCostPriceStatus } = useFlags()
  const statusSet = new Set([dipStatus])

  if (showCostPriceStatus) {
    statusSet.add(costPriceStatus)
  }

  if (stationStatus !== StationStatus.CLOSED) {
    statusSet.add(transactionStatus)
  }

  if (statusSet.size === 1) {
    return [...statusSet][0]
  }

  return statusSet.has(RED) ? RED : AMBER
}

const SingleSiteStatus = ({ error, data }) => {
  const { t } = useTranslation()
  const { formatStatus } = useFormatStatus()
  const { showCostPriceStatus } = useFlags()

  if (!data || error) {
    return null
  }

  const { stationsStatus } = data
  const {
    stationStatus,
    costPriceStatus: { status: costPriceStatus, lastCostPriceAt },
    dipStatus: { status: dipStatus, lastDipAt },
    transactionStatus: { status: transactionStatus, lastTransactionAt },
  } = stationsStatus[0]

  const status = ParseStatus(
    stationStatus,
    costPriceStatus,
    dipStatus,
    transactionStatus
  )

  const overlay = (
    <Tooltip className={cx('single-site-status-tooltip')}>
      <div className={cx('content')}>
        <h3>{t('widgets.stationStatus.heading')}</h3>
        <StatusInformation
          type={TRANSACTION}
          stationStatus={stationStatus}
          status={transactionStatus}
          timestamp={lastTransactionAt}
        />
        <StatusInformation
          type={DIP}
          status={dipStatus}
          timestamp={lastDipAt}
        />
        {showCostPriceStatus && (
          <StatusInformation
            type={COST_PRICE}
            status={costPriceStatus}
            timestamp={lastCostPriceAt}
          />
        )}
      </div>
    </Tooltip>
  )

  const onEnter = () => {
    amplitudeEvent(UserEvents.StationStatusIndicator.SINGLE_STATION)
  }

  return (
    <OverlayTrigger placement="bottom" overlay={overlay} onEnter={onEnter}>
      <div className={cx('single-site-status', status)}>
        {upperFirstOnly(formatStatus(status))}
      </div>
    </OverlayTrigger>
  )
}

SingleSiteStatus.propTypes = {
  data: PropTypes.shape(),
  error: PropTypes.shape(),
}

export { SingleSiteStatus }
