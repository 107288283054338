import React from 'react'
import classNames from 'classnames/bind'
import { ReactComponent as IntercomChatIcon } from './intercomChatIcon.svg'
import styles from './intercom-launcher.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const IntercomLauncher = () => {
  return (
    <div
      className={cx('edge-intercom-launcher-container')}
      id="edge-intercom-widget"
      data-testid={'edge-intercom-widget'}
      onClick={() => amplitudeEvent(UserEvents.Sidebar.INTERCOM)}
    >
      <IntercomChatIcon />
    </div>
  )
}

export { IntercomLauncher }
