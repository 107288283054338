import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useQuery, useReactiveVar } from '@apollo/client'
import {
  selectedStationIdsVar,
  fuelGradePriceChangesVar,
  remindersVar,
  actionAtVar,
  stationPriceChangesVar,
} from 'config/apollo/stations-list-pricing/variables'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { useCreatePolePriceChangeRequests } from 'components/stations-list/components/stations-table/components/edit-pricing/hooks/useCreatePolePriceChangeRequests'
import { useErrorHandler } from 'react-error-boundary'
import { ReactComponent as LoadingIcon } from 'images/stations-list/loading.svg'
import { StatusSelector } from './components/status-selector'
import { FuelGradesRow } from './components/fuel-grades-row'
import { TitleRow } from './components/title-row'
import { StationRows } from './components/station-rows'
import { PriceChangeFooter } from './components/price-change-footer'
import { STATION_CHANGE_REQUESTS_QUERY } from '../EditPricingQueries'
import styles from './ConfirmPriceChanges.module.scss'

const cx = classNames.bind(styles)

const { NONE, EDIT, SENT, ERROR } = StationsListPricingStatus

const buildVariables = (
  pricingType,
  stationIds,
  fuelGradePriceChangesValue,
  stationPriceChangesValue
) => {
  const fuelGradePriceChanges = fuelGradePriceChangesValue.filter(
    ({ priceChange }) => priceChange !== 0
  )
  const stationPriceChanges = Object.values(stationPriceChangesValue)
    .flat()
    .filter(Boolean)
    .filter(({ priceChange }) => priceChange !== 0)

  return {
    pricingType,
    stationIds,
    fuelGradePriceChanges,
    stationPriceChanges,
  }
}

const ConfirmPriceChanges = ({ status, selectedPricingType, fuelGrades }) => {
  const stationIds = useReactiveVar(selectedStationIdsVar)
  const reminders = useReactiveVar(remindersVar)
  const actionAt = useReactiveVar(actionAtVar)
  const fuelGradePriceChanges = useReactiveVar(fuelGradePriceChangesVar)
  const stationPriceChanges = useReactiveVar(stationPriceChangesVar)

  const variables = buildVariables(
    selectedPricingType,
    stationIds,
    fuelGradePriceChanges,
    stationPriceChanges
  )
  const { loading, error, data } = useQuery(STATION_CHANGE_REQUESTS_QUERY, {
    variables,
    fetchPolicy: 'network-only',
  })
  useErrorHandler(error)

  const createPolePriceChangeRequests = useCreatePolePriceChangeRequests(
    data?.stationChangeRequests,
    reminders,
    actionAt
  )

  const pricesSent = [SENT, ERROR].includes(status)

  const body = loading ? (
    <div className={cx('loading')}>
      <LoadingIcon height={100} width={100} />
    </div>
  ) : (
    <StationRows
      pricesSent={pricesSent}
      mutationData={createPolePriceChangeRequests.data}
      data={data}
      fuelGrades={fuelGrades}
    />
  )

  return (
    <>
      <div
        className={cx('confirm-price-changes', {
          loading,
          'no-changes': data?.stationChangeRequests.length === 0,
        })}
      >
        {!pricesSent && (
          <>
            <StatusSelector currentStatus={status} status={NONE} />
            <StatusSelector currentStatus={status} status={EDIT} />
          </>
        )}
        <FuelGradesRow fuelGrades={fuelGrades} />
        <TitleRow pricesSent={pricesSent} />
        {body}
      </div>
      <PriceChangeFooter
        status={status}
        stationChangeRequests={data?.stationChangeRequests}
        reminders={reminders}
        actionAt={actionAt}
        createPolePriceChangeRequests={createPolePriceChangeRequests}
      />
    </>
  )
}

ConfirmPriceChanges.propTypes = {
  status: PropTypes.string.isRequired,
  selectedPricingType: PropTypes.string.isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { ConfirmPriceChanges }
