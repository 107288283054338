import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const HeadTitle = ({ children, isEdgeDataProUser }) => {
  const [pathname, setPathname] = useState('')
  const location = useLocation()
  const { t } = useTranslation('headTitle')

  const capitalise = path => {
    return path.charAt(0).toUpperCase() + path.slice(1)
  }

  useEffect(() => {
    const setHeader = () => {
      const path = location.pathname.split('/')
      const title = isEdgeDataProUser ? 'EdgeData Pro' : capitalise(path.reverse()[0])

      switch (title) {
        case 'EdgeData Pro':
          return setPathname('EdgeData Pro')
        case 'Stations':
          return setPathname(`${t('portfolio')} | EdgePetrol`)
        case '':
        case null:
        case undefined:
          return setPathname('EdgePetrol')
        default:
          return setPathname(`${title} | EdgePetrol`)
      }
    }
    setHeader()
  }, [location.pathname, t])

  return (
    <>
      <Helmet>
        <title>{pathname}</title>
      </Helmet>
      {children}
    </>
  )
}

export { HeadTitle }
