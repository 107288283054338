import React, { useState } from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CancelEditIcon } from 'images/stations-list/edit-pricing/edit-cancel.svg'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { PricingTypes } from 'utils/constants'
import classNames from 'classnames/bind'
import { ExitPricingModal } from '../exit-pricing-modal'
import { EditPricesButton } from '../edit-prices-button'
import styles from './EditPricingSelector.module.scss'

const { NONE } = StationsListPricingStatus
const { ALL } = PricingTypes

const cx = classNames.bind(styles)

const EditPricingSelector = ({
  status,
  showStationsListPricing,
  showPricingView,
  selectedPricingType,
  cashCreditPricing,
  fuelGrades,
  stations,
}) => {
  const {
    featurePermissions: { updateEditPricing },
  } = usePermittedAccess()
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)

  if (!updateEditPricing || !showStationsListPricing) {
    return null
  }

  const overlay = (
    <Tooltip className={cx('edit-pricing-tooltip', 'tooltip-left')}>
      <div>{t('widgets.stationsListPricing.deselectCashOrCreditTooltip')}</div>
    </Tooltip>
  )

  const inactive = cashCreditPricing && selectedPricingType === ALL

  if (inactive) {
    return (
      <OverlayTrigger placement="left" overlay={overlay}>
        <div
          className={cx('edit-pricing-selector')}
          data-testid="edit-pricing-selector"
        >
          <EditPricesButton inactive={inactive} />
        </div>
      </OverlayTrigger>
    )
  }

  const handleClick = () => {
    if (status === NONE) {
      amplitudeEvent(UserEvents.GroupEditPricing.OPENED)
      stationsListPricingOperations.setEditStatus(fuelGrades, stations)
    } else {
      setShowModal(true)
    }
  }

  const handleCancel = event => {
    event.stopPropagation()
    setShowModal(false)
  }

  const handleConfirm = event => {
    event.stopPropagation()
    stationsListPricingOperations.setNoneStatus()
    setShowModal(false)
  }

  const icon = showPricingView ? (
    <>
      <CancelEditIcon width={40} height={40} />
      <ExitPricingModal
        show={showModal}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  ) : (
    <EditPricesButton />
  )

  return (
    <div
      className={cx('edit-pricing-selector', {
        pricing: showPricingView,
      })}
      data-testid="edit-pricing-selector"
      onClick={handleClick}
    >
      {icon}
    </div>
  )
}

EditPricingSelector.propTypes = {
  status: PropTypes.string.isRequired,
  showPricingView: PropTypes.bool.isRequired,
  showStationsListPricing: PropTypes.bool.isRequired,
  selectedPricingType: PropTypes.string.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { EditPricingSelector }
