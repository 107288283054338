import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { ImageAsset } from 'components/common/image-asset'
import { images, fallback } from 'images/image-library'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StationStatus } from './components/station-status'
import { StationNavigation } from './components/station-navigation'
import styles from './StationTitle.module.scss'

const cx = classNames.bind(styles)

const StationTitle = ({ stationData }) => {
  const { showStatusWidget, showStationNavigation } = useFlags()
  const { id, brand, name: stationName } = stationData

  return (
    <div className={cx('station-title-container')}>
      <div className={cx('station-title-brand')}>
        <ImageAsset
          height={65}
          width={65}
          src={images.brandImage(brand?.key)}
          alt={`station-title-brand-${brand?.key}`}
          fallbackImage={fallback.defaultBrand}
        />
      </div>
      <div>
        <div className={cx('station-name')}>{stationName}</div>
        {showStationNavigation && <StationNavigation stationId={id} />}
      </div>
      {showStatusWidget && <StationStatus stationId={id} />}
    </div>
  )
}

StationTitle.propTypes = {
  stationData: PropTypes.shape().isRequired,
}

const FallbackComponent = () => <div />

const StationTitleWithErrorBoundary = withEdgeErrorBoundary(
  StationTitle,
  FallbackComponent
)

export { StationTitleWithErrorBoundary as StationTitle }
