import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from 'images/common/close.svg'
import classNames from 'classnames/bind'
import styles from './Header.module.scss'

const cx = classNames.bind(styles)

const Header = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <div className={cx('header')}>
      <span>{t('widgets.notifications.title')}</span>
      <CloseIcon
        height={20}
        width={20}
        onClick={onClick}
        data-testid="close-icon"
      />
    </div>
  )
}

Header.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export { Header }
