import React from 'react'
import PropTypes from 'prop-types'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { useTranslation } from 'react-i18next'
import { upperFirstOnly, yAxisLabel } from 'utils/format'
import classNames from 'classnames/bind'
import styles from './PeriodAnalysisChart.module.scss'
import { ReactComponent as MetricUpArrow } from 'images/performance-report/metric-up.svg'
import { ReactComponent as MetricDownArrow } from 'images/performance-report/metric-down.svg'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { timePeriodFormat } from 'utils/helpers'
import moment from 'moment'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Label
  } from 'recharts'

const cx = classNames.bind(styles)

const COLOR_PALETTES = {
  6: ['#39c258', '#7c44d2', '#4fc4d1', '#c6e9cf','#d8c7f2','#caedf1'],
  4: ['#39c258', '#7c44d2', '#c6e9cf','#d8c7f2'],
  2: ['#39c258', '#c6e9cf']
}
  
const PeriodAnalysisChart = ({ data, metricType, metricName, timeResolution }) => {
  const { t } = useTranslation(['translations'])
  const { formatMetricValue } = useFormatMetricValue()

  const CustomTooltip = ({ active, payload, label }) => {  
    const formattedPayload = () => {
      const simplifiedPayload = payload.map(({ name, value, color }) => ({ name, value, color }))
      const order =
        payload.length === 6 ? [0, 3, 1, 4, 2, 5] :
        payload.length === 4 ? [0, 2, 1, 3] :
        [0, 1]
  
      return order.map(index => simplifiedPayload[index])
    }
  
    const calculateMetricChange = () => {
      const payload = formattedPayload()
  
      return payload.reduce((result, item, index) => {
        if (index % 2 === 0) {
          const { value: newValue } = item
          const { value: oldValue } = payload[index + 1]
  
          const percentageChange =
          oldValue === "0.0" ? '' : (((newValue - oldValue) / oldValue) * 100).toFixed(0)
  
        result.push([percentageChange])
          
        }
        return result
      }, [])
    }
  
    const tooltipSites = () => {
      const payload = formattedPayload()
      return [...new Set(payload.map(item => item.name.split('-')[0]))]
    }
  
    if (active && payload?.length) {
      const timePeriodLabel = () => {
        if (timeResolution === 'Weekly') {
          return 'Week of ' + label
        } else if (timeResolution === 'Daily') {
          const parsedDate = moment(label, "Do MMM")
          const dayOfWeek = parsedDate.format("dddd")
          return `${dayOfWeek} ${label}`
        } else {
          return label
        }
      }

      return (
        <div className={cx('tooltip')}>
          <p className={cx('tooltip-date')}>{timePeriodLabel()}</p>
          <table className={cx('tooltip-periods')}>
            <thead>
              <tr>
                {tooltipSites().map((name) => (
                  <th key={name} className={cx('tooltip-site')} colSpan="2" >{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {calculateMetricChange().map((metric, index) => (
                  <React.Fragment key={index}>
                    <th>{upperFirstOnly(t('widgets.performanceReport.thisPeriod'))}
                      {(metric) > 0 &&
                        <>
                          <MetricUpArrow />
                          <span className={cx('metric-change-up')}>
                            {metric}%
                          </span>
                        </>
                      }
                      {(metric) < 0 &&
                        <>
                          <MetricDownArrow />
                          <span className={cx('metric-change-down')}>
                            {-metric}%
                          </span>
                        </>
                      }
                    </th>
                    <th>{upperFirstOnly(t('widgets.performanceReport.lastPeriod'))}</th>
                  </React.Fragment>
                ))}
              </tr>
              <tr>
                {formattedPayload().map(({ value, color }) =>
                  <td key={color} style={{ color }}>{formatMetricValue(metricType, value, true , true)}</td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  
    return null
  }

  const getLineConfig = (dataKey, index, length) => {
    const selectedPalette = COLOR_PALETTES[length]
    const color = selectedPalette[index]
  
    return {
      type: 'monotone',
      dataKey,
      stroke: color,
      strokeWidth: 2,
      dot: { stroke: color, strokeWidth: 1, fill: color },
    }
  }
  
  const legendFormatter = (value) => (
    <span style={{ color: '#b2bfca'}}>
      {value}
    </span>
  )

  const stationNames = data?.periodAnalysis?.data?.map(item => item.label) || []

  const formatData = () => {
    if (!data?.periodAnalysis || !data.periodAnalysis.datesCurrentPeriod || !data.periodAnalysis.datesPreviousPeriod) {
      return null
    }

    const currentDates = data.periodAnalysis.datesCurrentPeriod
    const previousDates = data.periodAnalysis.datesPreviousPeriod
    const dates = [...currentDates, ...previousDates]
    const longestPeriod = Math.max(currentDates.length, previousDates.length)
  
    return Array.from({ length: longestPeriod }).map((_, index) => {
      const formattedDates = {
        currentDate: currentDates[index] ? timePeriodFormat(currentDates[index], dates, timeResolution) : null,
        previousDate: previousDates[index] ? timePeriodFormat(previousDates[index], dates, timeResolution) : null,
      }
  
      stationNames.forEach((name, i) => {
        formattedDates[name] = parseFloat(data.periodAnalysis.data[i].values[index])
      })
  
      return formattedDates
    })
  }

  const CustomYAxisTick = ({ x, y, payload }) => {    
    return (
      <g transform={`translate(${x},${y})`}>
        <text textAnchor="end" fill="#b2bfca" fontSize="15">
          {formatMetricValue(metricType, payload.value, true, true)}
        </text>
      </g>
    )
  }

  return (
    <>
      {formatData() && formatData().length > 0 ? (

      <div className={cx('container')}>

        <ResponsiveContainer width="100%" height={600}>
            <LineChart
              className={cx('chart')}
              data={formatData()}
              margin={{ left: 45 }}
            >
              <CartesianGrid strokeDasharray="3 3" className={cx('grid')} />
              <XAxis
                dataKey="currentDate"
                tick={{ fill: "#b2bfca", fontSize: 15 }}
                stroke="#eee"
              >
                <Label
                  value="Current"
                  position="left"
                  stroke="#eee"
                  fontSize="14"
                  style={{ textAnchor: 'end', transform: 'translate(-25px, 0)' }}
                />
              </XAxis>
              <XAxis
                dataKey="previousDate"
                axisLine={false}
                tickLine={false}
                xAxisId="previous"
                tick={{ fill: "#b2bfca", dy: 3, fontSize: 13 }}
              >
                <Label
                  value="Previous"
                  position="left"
                  stroke="#eee"
                  fontSize="12"
                  style={{ textAnchor: 'end', transform: 'translate(-25px, 0)' }}
                />
              </XAxis>
              <YAxis tick={<CustomYAxisTick />} stroke="none" domain={['auto', 'auto']}>
                <Label
                  value={t(`widgets.analytics.yAxisLabels.${yAxisLabel(metricName)}`)}
                  angle={-90}
                  position="insideLeft"
                  style={{ textAnchor: 'middle', fontSize: '15px' }}
                  dx={-44}
                />
              </YAxis>
              <Tooltip wrapperStyle={{ outline: "none" }} content={<CustomTooltip metricType={metricType} />} />
              <Legend
                formatter={legendFormatter}
                iconType="circle"
                wrapperStyle={{ paddingTop: '25px' }}
              />
              {stationNames.map((label, index) => (
                <Line key={index} {...getLineConfig(label, index, stationNames.length)} />
              ))}
            </LineChart>
            </ResponsiveContainer>
            </div>

      ) : (
        <div className={cx('no-data-container')} >
          <div className={cx('no-data-label')} >
              {t('widgets.analytics.noData')}
          </div>
        </div>
      )}
    </>
  )
}

const FallbackComponent = () => <WidgetFallback />

const ChartWithErrorBoundary = withEdgeErrorBoundary(
  PeriodAnalysisChart,
  FallbackComponent
)

PeriodAnalysisChart.propTypes = {
  metricType: PropTypes.string,
  metricName: PropTypes.string,
  timeResolution: PropTypes.string,
  data: PropTypes.shape({
    portfolioBenchmark: PropTypes.shape({
      dates: PropTypes.arrayOf(PropTypes.string),
      yourPortfolio: PropTypes.arrayOf(PropTypes.string),
      companiesSimilarToYou: PropTypes.arrayOf(PropTypes.string),
      averageEdgepetrolCompany: PropTypes.arrayOf(PropTypes.string),
    })
  }),
}

export { ChartWithErrorBoundary as PeriodAnalysisChart }
