import React from 'react'
import PropTypes from 'prop-types'
import Img from 'react-image'

const ImageAsset = ({
  src,
  height,
  width,
  alt,
  fallbackImage,
  fallbackElement,
}) => {
  const srcList = fallbackElement ? src : [src, fallbackImage]
  return (
    <div>
      <Img
        width={width}
        height={height}
        src={srcList}
        alt={alt}
        unloader={fallbackElement}
      />
    </div>
  )
}

ImageAsset.propTypes = {
  src: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  alt: PropTypes.string.isRequired,
  fallbackImage: PropTypes.string,
  fallbackElement: PropTypes.element,
}

ImageAsset.defaultProps = {
  fallbackImage: null,
  fallbackElement: null,
}

export { ImageAsset }
