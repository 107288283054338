import React from 'react'
import { useQuery } from '@apollo/client'
import Spinner from 'react-bootstrap/Spinner'
import { PricingTypes, MetricTypes } from 'utils/constants'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { useErrorHandler } from 'react-error-boundary'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import classNames from 'classnames/bind'
import { CURRENT_MARGIN_QUERY } from '../../../EditPricingQueries'
import { MarginFallback } from '../margin-fallback'
import styles from './GrossMargin.module.scss'

const cx = classNames.bind(styles)

const GrossMargin = () => {
  const { formatMetricValue } = useFormatMetricValue()
  const { error, loading, data } = useQuery(CURRENT_MARGIN_QUERY, {
    variables: {
      metricsPricingTypes: [PricingTypes.ALL],
    },
  })
  useErrorHandler(error)

  /* istanbul ignore next */
  if (error) {
    throw new Error(error)
  }

  if (loading) {
    return (
      <div className={cx('spinner')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  }

  const {
    company: {
      metrics: [
        {
          grossMargin: { value: currentGrossMargin },
        },
      ],
    },
  } = data

  return (
    <div data-testid="gross-margin">
      {formatMetricValue(MetricTypes.MARGIN, currentGrossMargin, false)}
    </div>
  )
}

const FallbackComponent = () => (
  <MarginFallback tooltipKey="widgets.stationsList.currentPortfolioMarginErrorTooltip" />
)

const GrossMarginWithErrorBoundary = withEdgeErrorBoundary(
  GrossMargin,
  FallbackComponent
)

export { GrossMarginWithErrorBoundary as GrossMargin }
