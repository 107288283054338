/**
 * Constants related to sending pole price change requests, either on the stations list or competitor pricing widgets.
 */

const ActionAtPeriods = {
  Immediately: 0,
  LaterToday: 1,
  Tomorrow: 2,
  Custom: 3,
}

const defaultReminders = {
  threeHoursAfter: true,
  oneHourAfter: true,
}

const defaultActionAt = {
  selectedPeriod: ActionAtPeriods.Immediately,
  selectedDate: null,
  selectedTime: null,
}

const PriceChangeTimeout = 5000

export {
  defaultReminders,
  defaultActionAt,
  ActionAtPeriods,
  PriceChangeTimeout,
}
