import React from 'react'
import PropTypes from 'prop-types'
import { FuelGradeNames } from 'utils/constants'
import { Slider } from 'components/common/slider'
import classNames from 'classnames/bind'
import { StationsColumn } from './components/stations-column'
import { FuelGradeColumn } from './components/fuel-grade-column'
import styles from './PricesTable.module.scss'

const cx = classNames.bind(styles)

const renderFuelGradeColumns = (
  validFuelGrades,
  station,
  status,
  sliderDispatch
) => {
  return validFuelGrades.map(({ fuelGrade, fuelGrade: { id } }) => {
    return (
      <FuelGradeColumn
        key={id}
        fuelGrade={fuelGrade}
        station={station}
        status={status}
        sliderDispatch={sliderDispatch}
      />
    )
  })
}

const PricesTable = ({ status, station, sliderState, dispatch }) => {
  const { leftCount, rightCount, elementMargin, enabled } = sliderState
  const { fuelGrades } = station

  const validFuelGrades = fuelGrades.filter(
    ({ fuelGrade }) => fuelGrade.title !== FuelGradeNames.TOTAL
  )

  return (
    <div className={cx('prices-table')}>
      <StationsColumn station={station} status={status} />
      <Slider
        leftClick={leftCount}
        rightClick={rightCount}
        handleLeftArrowActive={dispatch}
        handleRightArrowActive={dispatch}
        showArrows={dispatch}
        singleElementWidth={190}
        additionalContainerWidth={30}
        elementCount={validFuelGrades.length}
        elementMargin={elementMargin}
        customClass={cx('slider')}
        enabled={enabled}
      >
        {renderFuelGradeColumns(validFuelGrades, station, status, dispatch)}
      </Slider>
    </div>
  )
}

PricesTable.propTypes = {
  status: PropTypes.string.isRequired,
  station: PropTypes.shape().isRequired,
  sliderState: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
}

export { PricesTable }
