export const enAuth = {
  acceptTermsOfService: 'Accept Terms & Conditions',
  confirmPassword: 'Confirm password',
  done: 'Done!',
  emailAddress: 'Email address',
  finishAccountSetup: 'Finish account setup',
  forgot: 'Forgot?',
  forgotPassword: 'Forgot password',
  forgotPasswordHelpText:
    "If you have forgotten your password, simply enter your email below and we'll send you a link to reset it.",
  invalidLinkHeader: "You have followed an old or invalid link",
  invalidLinkText: 'If you are resetting your password please use the latest link in your inbox or request a new link by clicking "forgot password" below',
  genericErrorMessage: "Oops something's gone wrong. Please refresh the page",
  login: 'Login',
  loginToEdgeData: 'Login to EdgeData',
  loginToEdgePetrol: 'Login to EdgePetrol',
  optedInText:
    'If you have been selected to receive pole price change request messages,\n we will send you these messages via email by default. If you also wish to receive the messages via SMS, please click on the checkbox and enter your phone number below. \n Message and data rates may apply.',
  password: 'Password',
  passwordAgain: 'Password again',
  passwordComplexityErrorText:
    'Your password does not meet the minimum requirements. Passwords must be minimum 8 characters and contain at least 1 number.',
  passwordHelpText:
    'Passwords are required to be a minimum of 8 characters including one uppercase, one lowercase and one number.',
  passwordsDoNotMatch: 'Passwords do not match. Please re-enter.',
  pleaseCheckYourEmailAndPassword: 'Please check your email and/or password',
  privacyPolicy: 'Data Policy',
  providePhoneNumber: 'Please provide a valid phone number',
  resetPassword: 'Reset password',
  resetPasswordHelpText:
    'Please insert your new password into the fields below.',
  sendPasswordResetEmail: 'Send password reset email',
  smsNotificationPolicy: 'SMS Policy and opt out',
  smsCheckboxLabel:
    'I want to opt-in to receive pole price change request messages via SMS',
  startUsingEdgePetrol: 'Start using EdgePetrol',
  startUsingEdgeDataPro: 'Start using EdgeData Pro',
  telephoneNumber: 'Telephone number',
  termsAndConditions: 'Terms and Conditions',
  termsOfService: 'Terms of Service',
  thankYou: 'Thank you!',
  thankYouText:
    'You are now able to start receiving email notification from Edge Petrol, if you have opted in for SMS notifications you will receive those too',
  weveSentALink: "We've sent a link to your email address",
}
