import React from 'react'
import { PropTypes } from 'prop-types'
import { Trans } from 'react-i18next'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Link, useRouteMatch } from 'react-router-dom'
import { ReactComponent as PerformanceIcon } from 'images/sidebar/performance.svg'
import { ReactComponent as StationsIcon } from 'images/sidebar/stations.svg'
import { ReactComponent as AnalyticsIcon } from 'images/sidebar/analytics.svg'
import classNames from 'classnames/bind'
import styles from './SidebarItem.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'

const cx = classNames.bind(styles)

const iconComponents = {
  performance: PerformanceIcon,
  stations: StationsIcon,
  analytics: AnalyticsIcon,
}

const SidebarItem = ({ to, icon, exact, disabled }) => {
  const match = useRouteMatch({
    path: to,
    exact,
  })

  const {
    widgetPermissions: { viewAnalytics },
  } = usePermittedAccess()

  const IconComponent = iconComponents[icon]

  const onClick = () => amplitudeEvent(UserEvents.Sidebar[icon])

  if (disabled) {
    return (
      <div className={cx('sidebar-item', 'disabled')}>
        <IconComponent className={cx('icon')} width={20} height={20} />
      </div>
    )
  }


  if (!viewAnalytics && icon === 'analytics') {
    const overlay = (
      <Tooltip className={cx('analytics-tooltip', 'tooltip-right')}>
        <div className={cx('content')}>
          <Trans i18nKey="widgets.sidebar.comingSoon" components={<span />} />
        </div>
      </Tooltip>
    )

    return (
      <OverlayTrigger placement="right" overlay={overlay}>
        <div
          className={cx('sidebar-item')}
          data-testid="sidebar-item"
          onClick={onClick}
        >
          <IconComponent className={cx('icon')} width={20} height={20} />
        </div>
      </OverlayTrigger>
    )
  }

  return (
    <Link
      to={to}
      className={cx('sidebar-item', { 'sidebar-item-active': match })}
      data-testid="sidebar-item"
      onClick={onClick}
    >
      <IconComponent className={cx('icon')} width={20} height={20} />
    </Link>
  )
}

SidebarItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  disabled: PropTypes.bool,
}

SidebarItem.defaultProps = {
  to: null,
  exact: null,
  disabled: false,
}

export { SidebarItem }
