import { remindersVar, actionAtVar } from '../variables'

const toggleReminder = reminder => {
  const reminders = remindersVar()
  remindersVar({ ...reminders, [reminder]: !reminders[reminder] })
}

const setActionAt = (selectedPeriod, selectedDate, selectedTime) => {
  actionAtVar({
    selectedPeriod,
    selectedDate,
    selectedTime,
  })
}

export { toggleReminder, setActionAt }
