const getFilterAndGroupDropdownName = name =>
  [
    'Contract Type',
    'Fuel Brand',
    'Fuel Supplier',
    'Gross Margin',
    'Margin Target',
  ].includes(name)
    ? name
    : 'Custom'

export { getFilterAndGroupDropdownName }
