import { gql } from '@apollo/client'

const CREATE_CASH_CREDIT_DIFFERENTIAL_MUTATION = gql`
  mutation createCashCreditDifferentialMutation(
    $stationId: ID!
    $fuelGradeId: ID!
    $differential: Float!
  ) {
    createCashCreditDifferential(
      input: {
        stationId: $stationId
        fuelGradeId: $fuelGradeId
        differential: $differential
      }
    ) {
      error
      cashCreditDifferential {
        id
        fuelGradeId
        differential
      }
    }
  }
`

const CREATE_FUEL_GRADE_DIFFERENTIAL_MUTATION = gql`
  mutation createFuelGradeDifferentialMutation(
    $stationId: ID!
    $fuelGradeId: ID!
    $leadGradeId: ID!
    $differential: Float!
  ) {
    createFuelGradeDifferential(
      input: {
        stationId: $stationId
        fuelGradeId: $fuelGradeId
        leadGradeId: $leadGradeId
        differential: $differential
      }
    ) {
      error
      fuelGradeDifferential {
        id
        fuelGradeId
        leadGrade
        leadGradeId
        differential
      }
    }
  }
`

export {
  CREATE_CASH_CREDIT_DIFFERENTIAL_MUTATION,
  CREATE_FUEL_GRADE_DIFFERENTIAL_MUTATION,
}
