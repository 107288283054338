import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import SearchLogo from 'images/header/search.svg'
import styles from './Search.module.scss'

const cx = classNames.bind(styles)

const Search = ({ visible, search, searchInput, onSearchChange, onClose }) => {
  const { t } = useTranslation()
  const handleChange = event => {
    onSearchChange(event.target.value)
  }

  return (
    <div className={cx('search', { visible })} data-testid="search">
      <img
        className={cx('search-logo')}
        src={SearchLogo}
        alt="logo"
        width={25}
        height={25}
      />
      <input
        type="text"
        className={cx('search-text')}
        data-testid="search-text"
        value={search}
        onChange={handleChange}
        placeholder={t('widgets.stationSearch.searchForStation')}
        ref={searchInput}
      />
      <span
        className={cx('cancel', { visible })}
        data-testid="cancel"
        onClick={onClose}
      >
        &#10005;
      </span>
    </div>
  )
}

Search.propTypes = {
  visible: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  searchInput: PropTypes.shape().isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export { Search }
