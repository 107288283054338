import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './MapLegend.module.scss'
import { ReactComponent as Arrow } from 'images/arrows/arrow-down.svg'
import { ReactComponent as StarIcon } from 'images/competitor-pricing/star.svg'

const cx = classNames.bind(styles)

const MapLegendOpen = () => {
  const { t } = useTranslation(['translations'])

  return (
  <div className={cx('map-legend')}>
    <Arrow className={cx('close-arrow')} />
    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
      <div className={cx('legend-row')}>
        <div className={cx('legend-icon', 'cheaper')} />
        <span>{t('widgets.competitorPricing.lowerCompetitorPrice')}</span>
      </div>
      <div className={cx('legend-row')}>
        <div className={cx('legend-icon', 'match')} />
        <span> {t('widgets.competitorPricing.matchedCompetitorPrice')} </span>
      </div>
      <div className={cx('legend-row')}>
        <div className={cx('legend-icon', 'expensive')} />
        <span> {t('widgets.competitorPricing.higherCompetitorPrice')} </span>
      </div>
      <div className={cx('legend-row')}>
        <div className={cx('legend-icon', 'no-price-data')} />
        <span> {t('widgets.competitorPricing.missingPriceData')} </span>
      </div>
      <div className={cx('legend-row')}>
        <div className={cx('legend-icon')}>
          <StarIcon />
        </div>
        <span> {t('widgets.competitorPricing.favourite')} </span>
      </div>
    </div>
  </div>
)}

const MapLegendClose = () => {
  const { t } = useTranslation(['translations'])

  return (
  <div className={cx('map-legend')}>
    <Arrow className={cx('open-arrow')} />
    <div style={{ margin: '10px', textAlign: 'center' }}> {t('widgets.competitorPricing.mapLegend')} </div>
  </div>
)}

const MapLegend = ({ legendView, onToggle }) => (
  <div data-testid="map-legend"
    onClick={() => {
      onToggle(legendView === 'open' ? 'close' : 'open')
    }}
  >
    {legendView === 'open' ? <MapLegendOpen /> : <MapLegendClose />}
  </div>
)

MapLegend.propTypes = {
  legendView: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
}

MapLegend.defaultProps = {
  legendView: 'open',
}

export { MapLegend }
