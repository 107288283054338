import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Overlay, Popover } from 'react-bootstrap'

/**
 * Wraps a react-bootstrap popover so that it remains visible when hovered.
 *
 * https://github.com/react-bootstrap/react-bootstrap/issues/1622
 */
const HoverablePopover = ({
  delay,
  children,
  popoverContent,
  placement,
  customClass,
}) => {
  const [showPopover, setShowPopover] = useState(false)
  const childNode = useRef(null)
  let timeout = null

  useEffect(() => {
    return () => clearTimeout(timeout)
  })

  const handleChildMouseEnter = () => {
    timeout = setTimeout(() => {
      setShowPopover(true)
    }, delay)
  }

  const handleMouseEnter = () => {
    setShowPopover(true)
  }

  const handleChildClick = () => {
    setShowPopover(!showPopover)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeout)
    setShowPopover(false)
  }

  const handleMouseDown = event => {
    event.stopPropagation()
  }

  const handleHide = () => {
    setShowPopover(false)
  }

  // add event listeners/ref to child element so this also triggers opening the popover
  const mapChild = child =>
    React.cloneElement(child, {
      onMouseEnter: handleChildMouseEnter,
      onMouseLeave: handleMouseLeave,
      onClick: handleChildClick,
      ref: node => {
        childNode.current = node
        const { ref } = child
        /* istanbul ignore next */
        if (typeof ref === 'function') {
          ref(node)
        }
      },
    })
  const [mappedChild] = React.Children.map(children, child => mapChild(child))

  return (
    <>
      {mappedChild}
      <Overlay
        show={showPopover}
        placement={placement}
        target={childNode}
        shouldUpdatePosition
        rootClose
        onHide={handleHide}
      >
        <Popover
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleMouseDown}
          id="popover"
          className={customClass}
        >
          {popoverContent}
        </Popover>
      </Overlay>
    </>
  )
}

HoverablePopover.propTypes = {
  placement: PropTypes.string,
  delay: PropTypes.number,
  children: PropTypes.shape().isRequired,
  popoverContent: PropTypes.shape().isRequired,
  customClass: PropTypes.string,
}

HoverablePopover.defaultProps = {
  placement: 'bottom',
  delay: 0,
  customClass: null,
}

export { HoverablePopover }
