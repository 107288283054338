import { gql } from '@apollo/client'

const TANKS_QUERY = gql`
  query buysmartQuery($stationId: ID!) {
    station(id: $stationId) {
      id
      tanks {
        id
        tankNumber
        maxVolume
        fuelGrade {
          id
          title
          blended
        }
        deliveries {
          deliveredVolume
          datetime
          unitPrice
          costOfDelivery
          remainingVolume
          remainingValueOfFuel
        }
      }
    }
  }
`

export { TANKS_QUERY }
