import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { ImageAsset } from 'components/common/image-asset'
import { images, fallback } from 'images/image-library'
import styles from './SearchResults.module.scss'

const cx = classNames.bind(styles)

const renderFilters = (filter, stations, onFilterSelect) => {
  const letters = new Set(
    stations.map(({ name }) => name.charAt(0).toUpperCase()).sort()
  )

  return (
    <div className={cx('filters')}>
      {[...letters].map(letter => {
        const handleClick = () => {
          onFilterSelect(letter)
        }

        return (
          <span
            id={letter}
            key={letter}
            data-testid="filter"
            className={cx('filter', {
              'filter-active': filter === letter,
            })}
            onClick={handleClick}
          >
            {letter}
          </span>
        )
      })}
    </div>
  )
}

const renderStations = (filteredStationIds, stations, onStationSelect) => {
  const filteredStations = stations.filter(({ id }) =>
    filteredStationIds.includes(id)
  )

  return (
    <div className={cx('stations')}>
      {filteredStations.map(({ id, name, brand }) => {
        const handleClick = event => {
          amplitudeEvent(UserEvents.Search.STATION_SELECTED)
          onStationSelect(event, name)
        }

        return (
          <div
            key={id}
            data-testid="station"
            className={cx('station')}
            onClick={handleClick}
          >
            <ImageAsset
              src={images.brandImage(brand?.key)}
              width={40}
              height={40}
              alt={`search-brand-${brand?.key}`}
              fallbackImage={fallback.defaultBrand}
            />
            <span className={cx('name')}>{name}</span>
          </div>
        )
      })}
    </div>
  )
}

const renderMessage = (search, filteredStationIds) => {
  const stationsCount = filteredStationIds.length
  const station = filteredStationIds.length === 1 ? 'station' : 'stations'
  const message =
    search === ''
      ? `${stationsCount} ${station} total`
      : `${stationsCount} ${station} matching '${search}'`
  return <div className={cx('message')}>{message}</div>
}

const SearchResults = ({
  visible,
  search,
  filter,
  stations,
  filteredStationIds,
  onStationSelect,
  onFilterSelect,
}) => {
  return (
    <div className={cx('search-results', { visible })}>
      {renderFilters(filter, stations, onFilterSelect)}
      {renderStations(filteredStationIds, stations, onStationSelect)}
      {renderMessage(search, filteredStationIds)}
    </div>
  )
}

SearchResults.propTypes = {
  visible: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  filter: PropTypes.string,
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filteredStationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onStationSelect: PropTypes.func.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
}

SearchResults.defaultProps = {
  filter: null,
}

export { SearchResults }
