import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Tooltip from 'react-bootstrap/Tooltip'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { calendarDaysDifference } from 'utils/dateTime'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import styles from './StatusIcon.module.scss'

const cx = classNames.bind(styles)

const StatusIcon = ({ priceUpdatedAt }) => {
  const { t } = useTranslation()
  if (isNullOrUndefined(priceUpdatedAt)) {
    return null
  }

  const daysAgo = calendarDaysDifference(
    moment().utc(),
    moment.utc(priceUpdatedAt)
  )
  if (daysAgo <= 7) {
    return null
  }

  const overlay = (
    <Tooltip className={cx('status-icon-tooltip')}>
      {t('widgets.competitorPricing.statusIconTooltip')}
    </Tooltip>
  )

  return (
    <OverlayTrigger placement="top" overlay={overlay}>
      <div className={cx('status-icon')} data-testid="status-icon" />
    </OverlayTrigger>
  )
}

StatusIcon.propTypes = {
  priceUpdatedAt: PropTypes.string,
}

StatusIcon.defaultProps = {
  priceUpdatedAt: null,
}

export { StatusIcon }
