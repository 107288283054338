import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useIntercom } from 'react-use-intercom'
import classNames from 'classnames/bind'
import styles from './NoCompetitors.module.scss'

const cx = classNames.bind(styles)

const NoCompetitors = ({ competitors }) => {
  const { t } = useTranslation()
  const { showNewMessages } = useIntercom()

  if (competitors.length > 0) {
    return null
  }

  const handleButtonClick = () => {
    showNewMessages()
  }

  return (
    <div className={cx('no-competitors')}>
      <div className={cx('content')}>
        <h3>{t('widgets.competitorPricing.noCompetitorsTitle')}</h3>
        <p>{t('widgets.competitorPricing.noCompetitorsBody')}</p>
        <div
          className={cx('button')}
          onClick={handleButtonClick}
          data-testid="button"
        >
          Contact us
        </div>
      </div>
    </div>
  )
}

NoCompetitors.propTypes = {
  competitors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export { NoCompetitors }
