import React from 'react'
import { Trans } from 'react-i18next'
import { ReactComponent as LeadGradeIcon } from 'images/competitor-pricing/fuel-grade-differential/lead-grade.svg'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './LeadGradeIndicator.module.scss'

const cx = classNames.bind(styles)

const LeadGradeIndicator = ({ leadGrade, fuelGrade }) => {
  if (!leadGrade) {
    return null
  }
  const { shortTitle, primary } = fuelGrade

  const overlay = (
    <Tooltip className={cx('lead-grade-tooltip')}>
      <div>
        <Trans
          i18nKey="widgets.leadGradeIndicator.tooltipText"
          components={<span style={{ color: primary }} />}
          values={{ shortTitle }}
        />
      </div>
    </Tooltip>
  )

  return (
    <OverlayTrigger placement="top" overlay={overlay}>
      <div
        className={cx('lead-grade-indicator')}
        style={{ backgroundColor: primary }}
        data-testid="lead-grade-indicator"
      >
        <LeadGradeIcon />
      </div>
    </OverlayTrigger>
  )
}

LeadGradeIndicator.propTypes = {
  leadGrade: PropTypes.bool,
  fuelGrade: PropTypes.shape({
    id: PropTypes.string.isRequired,
    primary: PropTypes.string.isRequired,
    shortTitle: PropTypes.string.isRequired,
  }).isRequired,
}

LeadGradeIndicator.defaultProps = {
  leadGrade: false,
}

export { LeadGradeIndicator }
