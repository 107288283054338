import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from 'config/context/authContext'

const PrivateRoute = ({
  children,
  requiresTermsOfService,
  requiresPassword,
  requiresHasFinishedAccountSetUp,
  ...rest
}) => {
  const {
    loggedIn,
    hasPassword,
    hasFinishedAccountSetup,
    termsOfService,
    termsOfServiceVersion,
  } = useAuth()

  const render = ({ location }) => {
    if (loggedIn) {
      const hasTermsOfService =
        !!termsOfService && termsOfService === termsOfServiceVersion

      if (requiresTermsOfService && !hasTermsOfService) {
        return (
          <Redirect
            to={{ pathname: '/terms-of-service', state: { from: location } }}
          />
        )
      }

      if (requiresPassword && !hasPassword) {
        return (
          <Redirect
            to={{ pathname: '/set-password', state: { from: location } }}
          />
        )
      }

      if (requiresHasFinishedAccountSetUp && !hasFinishedAccountSetup) {
        return (
          <Redirect
            to={{ pathname: '/set-password', state: { from: location } }}
          />
        )
      }

      return children
    }

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    )
  }

  return <Route {...rest} render={render} />
}

PrivateRoute.propTypes = {
  requiresTermsOfService: PropTypes.bool,
  requiresPassword: PropTypes.bool,
}

export { PrivateRoute }
