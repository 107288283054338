import React from 'react'
import PropTypes from 'prop-types'
import { useApolloClient, gql } from '@apollo/client'
import { ReactComponent as CloseIcon } from 'images/stations-list/close.svg'
import { isNullOrUndefined } from 'utils/helpers'
import classNames from 'classnames/bind'
import { SelectedFilter } from './components/selected-filter'
import styles from './SelectedFilters.module.scss'

const cx = classNames.bind(styles)

const SelectedFilters = ({ filters, selectedFilters }) => {
  const client = useApolloClient()

  if (isNullOrUndefined(selectedFilters) || selectedFilters.length === 0) {
    return null
  }

  const handleClick = event => {
    event.stopPropagation()

    const data = {
      stationsList: {
        selectedFilters: [],
      },
    }
    client.writeQuery({
      query: gql`
        {
          stationsList {
            selectedFilters
          }
        }
      `,
      data,
    })
  }

  return (
    <div className={cx('selected-filters')}>
      {selectedFilters.map(selectedFilter => (
        <SelectedFilter
          key={`${selectedFilter.field}-${selectedFilter.operator}-${selectedFilter.value}`}
          filters={filters}
          selectedFilter={selectedFilter}
          selectedFilters={selectedFilters}
        />
      ))}
      <div
        className={cx('remove-all-button')}
        onClick={handleClick}
        data-testid="remove-all-button"
      >
        <CloseIcon />
      </div>
    </div>
  )
}

SelectedFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.shape),
}

SelectedFilters.defaultProps = {
  selectedFilters: [],
}

export { SelectedFilters }
