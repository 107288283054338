import React from 'react'
import PropTypes from 'prop-types'
import { HoverablePopover } from 'components/common/hoverable-popover'
import classNames from 'classnames/bind'
import { PricesInformation } from './components/prices-information'
import { EditPricesForm } from './components/edit-prices-form'
import styles from './PricesPopover.module.scss'

const cx = classNames.bind(styles)

const PricesPopover = ({
  children,
  stationId,
  fuelGrade,
  competitor,
  cashPrice,
  creditPrice,
  editPriceMode,
  setEditPriceMode,
}) => {
  const { id: competitorId, name } = competitor
  const { id: fuelGradeId } = fuelGrade

  const handleClosePopover = () => {
    setEditPriceMode(false)
    document.body.click()
  }

  const handleEditPrices = () => {
    setEditPriceMode(true)
  }

  const content = editPriceMode ? (
    <EditPricesForm
      cashPrice={cashPrice}
      creditPrice={creditPrice}
      stationId={stationId}
      competitorId={competitorId}
      fuelGradeId={fuelGradeId}
      onClosePopover={handleClosePopover}
    />
  ) : (
    <PricesInformation
      fuelGrade={fuelGrade}
      cashPrice={cashPrice}
      creditPrice={creditPrice}
      onEditPrices={handleEditPrices}
    />
  )

  const popoverContent = (
    <div className={cx('pole-prices-popover')}>
      <div className={cx('content')}>
        <div className={cx('title')}>{name}</div>
        {content}
      </div>
    </div>
  )

  return (
    <HoverablePopover popoverContent={popoverContent} delay={200}>
      {children}
    </HoverablePopover>
  )
}

PricesPopover.propTypes = {
  children: PropTypes.shape().isRequired,
  stationId: PropTypes.string.isRequired,
  fuelGrade: PropTypes.shape().isRequired,
  competitor: PropTypes.shape().isRequired,
  cashPrice: PropTypes.shape(),
  creditPrice: PropTypes.shape(),
}

PricesPopover.defaultProps = {
  cashPrice: null,
  creditPrice: null,
}

export { PricesPopover }
