import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { determinePolePriceDate } from 'components/station/components/competitor-pricing/utils/helpers'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { StatusIcon } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/status-icon/'
import { EditPriceButton } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/edit-price-button'
import { PreviousPrice } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/previous-price'
import { FuelGradeIcon } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/fuel-grade-icon'
import { PriceSource } from 'components/station/components/competitor-pricing/components/prices-table/components/fuel-grade-column/components/popovers/components/price-source'
import classNames from 'classnames/bind'
import styles from './PriceInformation.module.scss'

const cx = classNames.bind(styles)

const PriceInformation = ({
  polePrice,
  fuelGrade,
  noCashCreditStation,
  hasSinglePrice,
  onEditPrice,
}) => {
  const { formatPolePrice, formatPolePriceDate } = useFormatPolePrice()
  const { t } = useTranslation()

  const noCashCreditPrices = noCashCreditStation || hasSinglePrice
  const polePriceDate = determinePolePriceDate(polePrice)

  return (
    <>
      <div className={cx('price-information')}>
        <div className={cx('price-info')}>
          <div className={cx('date')}>
            <StatusIcon priceUpdatedAt={polePrice?.priceUpdatedAt} />
            <div>
              {formatPolePriceDate(polePriceDate)}
              {noCashCreditPrices && polePrice && (
                <span className={cx('no-cc-price')}>
                  {` ${t('widgets.competitorPricing.bracketsPrice')}`}
                </span>
              )}
            </div>
          </div>
          <div className={cx('price')}>
            <div>
              {formatPolePrice(
                polePrice?.price,
                t('widgets.competitorPricing.noPrice')
              )}
            </div>
            <EditPriceButton onClick={onEditPrice} />
            <PreviousPrice polePrice={polePrice} />
          </div>
        </div>
        <div
          className={cx('grade-source-info', {
            'no-cc': noCashCreditPrices,
          })}
        >
          <FuelGradeIcon fuelGrade={fuelGrade} />
          <PriceSource polePrice={polePrice} />
        </div>
      </div>
      {noCashCreditPrices && (
        <div className={cx('no-cc-message')}>
          {t('widgets.competitorPricing.noCashCreditPrice')}
        </div>
      )}
    </>
  )
}

PriceInformation.propTypes = {
  polePrice: PropTypes.shape(),
  fuelGrade: PropTypes.shape().isRequired,
  noCashCreditStation: PropTypes.bool.isRequired,
  hasSinglePrice: PropTypes.bool.isRequired,
  onEditPrice: PropTypes.func.isRequired,
}

PriceInformation.defaultProps = {
  polePrice: null,
}

export { PriceInformation }
