import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as CancelEditIcon } from 'images/stations-list/edit-pricing/edit-cancel.svg'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames/bind'
import styles from './ExitPricingModal.module.scss'

const cx = classNames.bind(styles)

const ExitPricingModal = ({ show, onCancel, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Modal
      className={cx('modal')}
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      centered
      backdrop="static"
    >
      <Modal.Body>
        <CancelEditIcon className={cx('icon')} height={42} width={42} />
        <div className={cx('body')}>
          <h4>{t('widgets.stationsListPricing.cancelChangesTitle')}</h4>
          <p>{t('widgets.stationsListPricing.cancelChangesBody')}</p>
        </div>
        <div className={cx('footer')}>
          <div className={cx('cancel')}>
            <p onClick={onCancel}>
              {t('widgets.stationsListPricing.cancelChangesCancel')}
            </p>
          </div>
          <div className={cx('confirm')}>
            <Button onClick={onConfirm}>
              {t('widgets.stationsListPricing.cancelChangesConfirm')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

ExitPricingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export { ExitPricingModal }
