import React from 'react'
import { PropTypes } from 'prop-types'
import EdgeLogo from 'images/edge-logo.svg'
import { usePermittedAccess } from 'components/common/hooks/usePermittedAccess'
import { IntercomLauncher } from 'components/common/intercom-launcher'
import { SidebarItem } from './components/sidebar-item'
import classNames from 'classnames/bind'
import styles from './Sidebar.module.scss'

const cx = classNames.bind(styles)

const Sidebar = ({ mainData }) => {
  const {
    widgetPermissions: { viewPerformanceReportingPortfolio },
  } = usePermittedAccess()

  const {
    widgetPermissions: { viewAnalytics },
  } = usePermittedAccess()

  const {
    me: { isSingleSite },
  } = mainData

  return (
    <nav className={cx('side-bar')}>
      <div className={cx('edge-logo')}>
        <img src={EdgeLogo} alt="logo" width={35} height={35} />
      </div>
      {(!isSingleSite || viewAnalytics) && (
        <SidebarItem to="/stations" icon="stations" exact={false} />
      )}
      {!isSingleSite && (
        <SidebarItem
          to="/performance"
          icon="performance"
          exact
          disabled={!viewPerformanceReportingPortfolio}
        />
      )}
      {viewAnalytics ?
         (
          <SidebarItem
            to="/analytics"
            icon="analytics"
            exact
          />
        ) : <SidebarItem icon="analytics"/>
      }
      <IntercomLauncher />
    </nav>
  )
}

Sidebar.propTypes = {
  mainData: PropTypes.shape().isRequired,
}

export { Sidebar }
