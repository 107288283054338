import React from 'react'
import PropTypes from 'prop-types'
import { Error } from 'components/common/error'
import classNames from 'classnames/bind'
import styles from './MarginFallback.module.scss'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

const MarginFallback = ({ tooltipKey }) => {
  const { t } = useTranslation()

  return (
    <div className={cx('wrapper')} data-testid="margin-fallback">
      <Error tooltipText={t(tooltipKey)} />
    </div>
  )
}

MarginFallback.propTypes = {
  tooltipKey: PropTypes.string.isRequired,
}

export { MarginFallback }
