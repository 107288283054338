import React from 'react'
import { PropTypes } from 'prop-types'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as StationActiveIcon } from 'images/stations-list/edit-pricing/active-station.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import { ReactComponent as InvalidIcon } from 'images/checkbox/invalid.svg'
import { stationsListPricingOperations } from 'config/apollo/stations-list-pricing/operations'
import { InvalidStationPopover } from './components/invalid-station-popover'
import classNames from 'classnames/bind'
import styles from './StationCheckbox.module.scss'

const cx = classNames.bind(styles)

const renderIcon = (isSelected, hasStationPrice) => {
  if (hasStationPrice) {
    return <StationActiveIcon />
  } else if (isSelected) {
    return <ActiveIcon />
  }

  return <InactiveIcon />
}

const StationCheckbox = ({
  showPricingView,
  stationId,
  isSelected,
  hasStationPrice,
  isInvalidStation,
}) => {
  if (!showPricingView) {
    return null
  }

  if (isInvalidStation) {
    const handleInvalidClick = event => {
      event.stopPropagation()
    }

    return (
      <div
        className={cx('station-checkbox', 'invalid')}
        onClick={handleInvalidClick}
      >
        <InvalidStationPopover>
          <InvalidIcon />
        </InvalidStationPopover>
      </div>
    )
  }

  const handleClick = event => {
    event.stopPropagation()
    if (isSelected) {
      stationsListPricingOperations.deselectStations([stationId])
    } else {
      stationsListPricingOperations.selectStations([stationId])
    }
  }

  return (
    <div
      className={cx('station-checkbox')}
      data-testid="station-checkbox"
      onClick={handleClick}
    >
      {renderIcon(isSelected, hasStationPrice)}
    </div>
  )
}

StationCheckbox.propTypes = {
  showPricingView: PropTypes.bool.isRequired,
  stationId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  hasStationPrice: PropTypes.bool,
  isInvalidStation: PropTypes.bool,
}

StationCheckbox.defaultProps = {
  isSelected: false,
  hasStationPrice: false,
  isInvalidStation: false,
}

export { StationCheckbox }
