import { PropTypes } from 'prop-types'

const Permissioned = ({ permission, fallback, children }) => {
  return permission ? children : fallback
}

Permissioned.propTypes = {
  permission: PropTypes.bool.isRequired,
  fallback: PropTypes.element.isRequired,
  children: PropTypes.any,
}

Permissioned.defaultProps = {
  children: null,
}

export { Permissioned }
