import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { formatDate } from '../../../../utils'
import styles from './DateCell.module.scss'

const cx = classNames.bind(styles)

const DateCell = ({ item, previousColumn }) => {
  return (
    <td className={cx('date-cell')}>
      <p>{formatDate(item.date)}</p>
      {previousColumn && <p>{formatDate(item.previousDate)}</p>}
    </td>
  )
}

DateCell.propTypes = {
  item: PropTypes.shape().isRequired,
  previousColumn: PropTypes.bool.isRequired,
}

export { DateCell }
