import React from 'react'
import classNames from 'classnames/bind'

import styles from './Copyright.module.scss'

const cx = classNames.bind(styles)

const Copyright = ({ className }) => {
  const today = new Date()
  const year = today.getFullYear()

  return (
    <div className={cx('container', className)}>
      Copyright {'\u00a9'} EdgePetrol {year}
    </div>
  )
}

export { Copyright }
