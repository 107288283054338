import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ReactComponent as PreviousDarkIcon } from 'images/stations-list/station-overlay/previous-dark.svg'
import { ReactComponent as NextDarkIcon } from 'images/stations-list/station-overlay/next-dark.svg'
import { slugify } from 'utils/slug'
import classNames from 'classnames/bind'
import styles from './StationLink.module.scss'

const cx = classNames.bind(styles)

const StationLink = ({ station, isNext }) => {
  const history = useHistory()
  const { name } = station

  const handleClick = () => {
    const slug = slugify(name)
    history.push(`/stations/${slug}`)
  }

  return (
    <span
      className={cx('station-link', { next: isNext })}
      onClick={handleClick}
    >
      {!isNext && <PreviousDarkIcon />}
      <div>{name}</div>
      {isNext && <NextDarkIcon />}
    </span>
  )
}

StationLink.propTypes = {
  station: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isNext: PropTypes.bool,
}

StationLink.defaultProps = {
  isNext: false,
}

export { StationLink }
