import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as SunShineIcon } from 'images/sun-shine.svg'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames/bind'
import styles from './RefreshModal.module.scss'

const cx = classNames.bind(styles)

const RefreshModal = ({ show, onDismiss }) => {
  const { t } = useTranslation()

  const handleRefresh = () => {
    window.location.reload()
  }

  return (
    <Modal
      className={cx('modal')}
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      centered
      backdrop="static"
    >
      <Modal.Body>
        <SunShineIcon className={cx('icon')} height={42} width={42} />
        <div className={cx('body')}>
          <h4>{t('widgets.versionManagement.refreshTitle')}</h4>
          <p>{t('widgets.versionManagement.refreshBody')}</p>
        </div>
        <div className={cx('buttons')}>
          <Button
            className={cx('button', 'dismiss')}
            variant="light"
            data-testid="button-dismiss"
            onClick={onDismiss}
          >
            {t('widgets.versionManagement.dismiss')}
          </Button>
          <Button
            className={cx('button')}
            data-testid="button-refresh"
            onClick={handleRefresh}
          >
            {t('widgets.versionManagement.refreshNow')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

RefreshModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
}

export { RefreshModal }
