import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { StationCell } from './components/station-cell'
import styles from './StationRow.module.scss'

const cx = classNames.bind(styles)

const StationRow = ({
  children,
  stationId,
  cashCreditPricing,
  brand,
  name,
  error,
}) => {
  return (
    <div
      key={stationId}
      className={cx('station-row', { 'no-cc': !cashCreditPricing })}
    >
      <StationCell brand={brand} name={name} error={error} />
      {children}
    </div>
  )
}

StationRow.propTypes = {
  children: PropTypes.shape().isRequired,
  stationId: PropTypes.string.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
  brand: PropTypes.shape(),
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
}

StationRow.defaultProps = {
  error: false,
}

export { StationRow }
