import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import styles from './AuthDialog.module.scss'
import { ReactComponent as Logo } from 'images/common/edge-logo-dark.svg'
import PropTypes from 'prop-types'
import { Copyright } from 'components/main/components/copyright'

const cx = classNames.bind(styles)

const AuthDialog = ({
  showLogo,
  headerClassName,
  wrapperClassName,
  helpText,
  link,
  linkText,
  buttonText,
  title,
  onSubmit,
  error,
  footerText,
  children,
}) => {
  const handleSubmit = event => {
    event.preventDefault()
    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <div className={cx('container')}>
      <div className={`${cx('wrapper')} ${wrapperClassName ?? ''}`}>
        <form
          className={cx('login-form')}
          onSubmit={handleSubmit}
          data-testid="form"
        >
          <div className={`${cx('header')} ${headerClassName ?? ''}`}>
            {!!showLogo && <Logo />}
            {!!title && <h1>{title}</h1>}
            {!!helpText && <p>{helpText}</p>}
          </div>
          {children}
          <button type="submit">{buttonText}</button>
          {!!link && <Link to={link}>{linkText}</Link>}
        </form>
        {!!error && <div className={cx('error')}>{error}</div>}
        {!!footerText && <div className={cx('footer')}>{footerText}</div>}
      </div>
      <Copyright className={cx('copyright')} />
    </div>
  )
}

AuthDialog.propTypes = {
  showLogo: PropTypes.bool,
  headerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  helpText: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  footerText: PropTypes.string,
}

AuthDialog.defaultProps = {
  showLogo: true,
}

export { AuthDialog }
