import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ActiveMapIcon } from 'images/competitor-pricing/active-map-icon.svg'
import { ReactComponent as InactiveMapIcon } from 'images/competitor-pricing/inactive-map-icon.svg'
import { ReactComponent as ActiveListIcon } from 'images/competitor-pricing/active-list-icon.svg'
import { ReactComponent as InactiveListIcon } from 'images/competitor-pricing/inactive-list-icon.svg'
import classNames from 'classnames/bind'
import styles from './ToggleViewButton.module.scss'

const cx = classNames.bind(styles)

const ToggleViewButton = ({competitorPricingView, onToggle}) => {
  return (
    <div className={cx('toggle-container')}>
      <label data-testid="toggle-button" onClick={() => {
        onToggle(competitorPricingView === 'map' ? 'list' : 'map')
      }} className={cx('toggle', competitorPricingView)}>
        <span className={cx('toggle-button', competitorPricingView === 'list' ? 'list' : 'map')}>
          {competitorPricingView === 'list' ? <ActiveListIcon /> : <ActiveMapIcon />}
        </span>
        <span>
          <InactiveListIcon /> 
        </span>
        <span>
          <InactiveMapIcon />
        </span>
      </label>
    </div>
  )
}

ToggleViewButton.propTypes = {
  competitorPricingView: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export { ToggleViewButton }
