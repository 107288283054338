import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'config/context/authContext'
import ReactMarkdown from 'react-markdown'
import { AuthDialog } from '../components/auth-dialog'
import { TERMS_OF_SERVICE_QUERY } from './TermsOfServiceQueries'
import styles from './TermsOfService.module.scss'

const cx = classNames.bind(styles)

const TermsOfService = () => {
  const { t } = useTranslation('auth')
  const { acceptTermsOfService } = useAuth()
  const { data } = useQuery(TERMS_OF_SERVICE_QUERY)
  const [error, setError] = useState(null)

  const handleSubmit = async () => {
    try {
      await acceptTermsOfService()
    } catch (err) {
      setError(t('genericErrorMessage'))
    }
  }

  return (
    <div className={cx('wrapper')}>
      <AuthDialog
        showLogo={false}
        headerClassName={cx('header')}
        wrapperClassName={cx('dialog-wrapper')}
        title={t('termsOfService')}
        buttonText={t('acceptTermsOfService')}
        onSubmit={handleSubmit}
        error={error}
      >
        <div className={cx('container')}>
          <ReactMarkdown linkTarget="_blank">
            {data?.termsOfService}
          </ReactMarkdown>
        </div>
      </AuthDialog>
    </div>
  )
}

export { TermsOfService }
