import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import styles from './TitleRow.module.scss'

const cx = classNames.bind(styles)

const TitleRow = ({ pricesSent }) => {
  const { t } = useTranslation()

  const content = pricesSent ? (
    <div>
      <div>{t('widgets.stationsListPricing.new')}</div>
      <div>{t('widgets.stationsListPricing.status')}</div>
    </div>
  ) : (
    <div>
      <div>{t('widgets.stationsListPricing.current')}</div>
      <div>{t('widgets.stationsListPricing.new')}</div>
    </div>
  )

  return (
    <div className={cx('title-row', { 'prices-sent': pricesSent })}>
      <div>{t('widgets.stationsListPricing.station')}</div>
      <div>{content}</div>
    </div>
  )
}

TitleRow.propTypes = {
  pricesSent: PropTypes.bool.isRequired,
}

export { TitleRow }
