import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WarningPoint } from 'images/buysmart/warning-point.svg'
import { ReactComponent as Warning } from 'images/buysmart/warning.svg'
import { ReactComponent as StockOutPoint } from 'images/buysmart/stockout-point.svg'

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import styles from './Events.module.scss'

const cx = classNames.bind(styles)

const tooltipWarning = (warnings, date, t) => {
  return (
    <Tooltip className={cx('tooltip-warning')}>
      <div className={cx('tooltip-content')}>
        <div className={cx('tooltip-svg')}>
          <svg width="36px" height="36px">
            <path d="M18.0527182,5.24477568 C18.4617513,5.44684324 18.7956895,5.76178541 19.0099449,6.14755076 L27.7404605,21.8667801 C28.3219884,22.9138168 27.8934234,24.2072126 26.7832338,24.7556604 C26.4583441,24.91616 26.0970467,25 25.7302844,25 L8.26925328,25 C7.0159793,25 6,24.0418145 6,22.8598327 C6,22.5139336 6.08889689,22.1731884 6.25907716,21.8667801 L14.9895927,6.14755076 C15.5711206,5.10051404 16.9425286,4.69632787 18.0527182,5.24477568 Z M16.5136948,8.18253281 C16.3509736,8.27309494 16.2167731,8.40729539 16.126211,8.57001664 L8.92243772,21.5136948 C8.65385877,21.9962752 8.82734119,22.6052101 9.30992154,22.873789 C9.45864126,22.9565586 9.62602579,23 9.79622674,23 L24.2037733,23 C24.756058,23 25.2037733,22.5522847 25.2037733,22 C25.2037733,21.8297991 25.1603319,21.6624145 25.0775623,21.5136948 L17.873789,8.57001664 C17.6052101,8.08743629 16.9962752,7.91395387 16.5136948,8.18253281 Z M17,19 C17.552,19 18,19.448 18,20 C18,20.552 17.552,21 17,21 C16.448,21 16,20.552 16,20 C16,19.448 16.448,19 17,19 Z M17,11 C17.552,11 18,11.448 18,12 L18,17 C18,17.552 17.552,18 17,18 C16.448,18 16,17.552 16,17 L16,12 C16,11.448 16.448,11 17,11 Z"></path>
          </svg>
        </div>
        <div className={cx('tooltip-text-header')}>
          {t('widgets.buysmartAnalysis.lowTankVolume')}
        </div>
        <div className={cx('tooltip-text-tanks')}>
          {warnings.map(({ warning, tankNumber }, i) => {
            return (
              <div className={cx('tooltip-text-tanks-info')} key={i}>
                {`${warning} (T${tankNumber})`}
              </div>
            )
          })}
          {t('widgets.buysmartAnalysis.willBeLowOn')}
        </div>
        <div className={cx('tooltip-text-date')}>{date}</div>
      </div>
    </Tooltip>
  )
}

const tooltipStockout = (stockOuts, date, t) => {
  return (
    <Tooltip className={cx('tooltip-stockout')}>
      <div className={cx('tooltip-content-stockout')}>
        <div className={cx('tooltip-svg')}>
          <svg width="36px" height="36px">
            <path
              d="M18,14 L21,14 L21,16 L18,16 L18,19 L16,19 L16,16 L13,16 L13,14 L16,14 L16,11 L18,11 L18,14 Z M17,23 C12.589,23 9,19.411 9,15 C9,10.589 12.589,7 17,7 C21.411,7 25,10.589 25,15 C25,19.411 21.411,23 17,23 L17,23 Z M17,5 C11.477,5 7,9.477 7,15 C7,20.523 11.477,25 17,25 C22.523,25 27,20.523 27,15 C27,9.477 22.523,5 17,5 L17,5 Z"
              id="plus_circle-[#1441]"
              fill="#FFFFFF"
              transform="translate(17.000000, 15.000000) rotate(-315.000000) translate(-17.000000, -15.000000) "
            ></path>
          </svg>
        </div>
        <div className={cx('tooltip-text-header')}>
          {t('widgets.buysmartAnalysis.stockout')}
        </div>
        <div className={cx('tooltip-text-tanks')}>
          {stockOuts.map(({ stockout, tankNumber }, i) => {
            return (
              <div className={cx('tooltip-text-tanks-info')} key={i}>
                {`${stockout} (T${tankNumber})`}
              </div>
            )
          })}
          {t('widgets.buysmartAnalysis.willRunOutOn')}
        </div>
        <div className={cx('tooltip-text-date')}>{date}</div>
      </div>
    </Tooltip>
  )
}

const Events = ({ warnings, stockOuts, date, setSelectedDay, day }) => {
  const { t } = useTranslation()
  const hasStockOuts = stockOuts.length > 0
  const hasWarnings = warnings.length > 0

  const handleClick = () => {
    setSelectedDay(day)
  }

  const renderEvents = () => {
    switch (true) {
      case hasStockOuts && hasWarnings:
        return (
          <div className={cx('multiple-events')}>
            <OverlayTrigger
              placement={'top'}
              overlay={tooltipStockout(stockOuts, date, t)}
            >
              <StockOutPoint />
            </OverlayTrigger>
            <OverlayTrigger
              placement={'top'}
              overlay={tooltipWarning(warnings, date, t)}
            >
              <Warning className={cx('multiple-with-warning')} />
            </OverlayTrigger>
          </div>
        )
      case hasStockOuts && !hasWarnings:
        return (
          <OverlayTrigger
            placement={'top'}
            overlay={tooltipStockout(stockOuts, date, t)}
          >
            <StockOutPoint />
          </OverlayTrigger>
        )
      case !hasStockOuts && hasWarnings:
        return (
          <OverlayTrigger
            placement={'top'}
            overlay={tooltipWarning(warnings, date, t)}
          >
            <WarningPoint />
          </OverlayTrigger>
        )
      default:
        return
    }
  }

  return (
    <div className={cx('events-container')} onClick={handleClick}>
      {renderEvents()}
    </div>
  )
}

Events.propTypes = {
  warnings: PropTypes.array.isRequired,
  stockOuts: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  day: PropTypes.number.isRequired,
  setSelectedDay: PropTypes.func.isRequired,
}

export { Events }
