import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { MarginRegex } from 'utils/constants'
import styles from './ValueInput.module.scss'

const cx = classNames.bind(styles)

const isValidMargin = value => {
  if (value === '' || value === '.') {
    return false
  }
  return MarginRegex.test(value)
}

const ValueInput = ({ initialValue, onValueSet }) => {
  const [value, setValue] = useState(initialValue || '')

  const handleChange = event => {
    const {
      target: { value },
    } = event
    setValue(value)
    if (isValidMargin(value)) {
      onValueSet(value)
    } else {
      onValueSet(null)
    }
  }

  return (
    <div className={cx('value-input')}>
      <input type="text" value={value} onChange={handleChange} />
    </div>
  )
}

ValueInput.propTypes = {
  initialValue: PropTypes.string,
  onValueSet: PropTypes.func.isRequired,
}

ValueInput.defaultProps = {
  initialValue: '',
}

export { ValueInput }
