import PropTypes from 'prop-types'
import { Dropdown } from 'components/common/dropdown'
import { useTranslation } from 'react-i18next'
import { convert24To12HourTime, getTextFromDate } from 'utils/dateTime'
import { isNullOrUndefined } from 'utils/helpers'
import { Locales } from 'utils/constants'
import { ActionAtPeriods } from 'utils/pricing/constants'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import classNames from 'classnames/bind'
import { ActionAtCalendar } from '../action-at-calendar'
import styles from './ActionAtPopover.module.scss'

const { Immediately, LaterToday, Tomorrow, Custom } = ActionAtPeriods

const cx = classNames.bind(styles)

const isValidActionAt = (period, time) => {
  if (period === Immediately) {
    return true
  }
  return !isNullOrUndefined(period) && !isNullOrUndefined(time)
}

const parseDisplayItem = (language, interval) => {
  if (language === Locales.EN_US) {
    return convert24To12HourTime(interval)
  }

  return interval
}

const ActionAtPopover = ({
  target,
  show,
  options,
  onCalendarDateChange,
  onHide,
  onPeriodSelect,
  onTimeSelect,
  onConfirm,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { period, date, time, timeIntervals } = options
  const isValid = isValidActionAt(period, time)

  const handleClick = () => {
    if (isValid) {
      onConfirm()
    }
  }

  const items = [
    {
      item: Immediately,
      displayItem: t('widgets.competitorPricing.immediately'),
    },
    {
      item: LaterToday,
      displayItem: t('widgets.competitorPricing.laterToday'),
    },
    {
      item: Tomorrow,
      displayItem: t('widgets.competitorPricing.tomorrow'),
    },
    {
      item: Custom,
      displayItem: t('widgets.competitorPricing.custom'),
    },
  ]
  const selectedItemDisplay =
    period === Custom ? getTextFromDate(language, t, date) : null

  const timeItems = timeIntervals?.map(interval => ({
    item: interval,
    displayItem: parseDisplayItem(language, interval),
  }))

  return (
    <Overlay
      target={target.current}
      show={show}
      placement="top"
      onHide={onHide}
      rootClose
    >
      {props => (
        <Popover
          id="action-at-popover"
          className={cx('action-at-popover')}
          {...props}
        >
          <Popover.Content className={cx('content')}>
            <h3>{t('widgets.competitorPricing.selectADayAndTime')}</h3>
            <Dropdown
              selectedItem={period}
              items={items}
              selectedItemDisplay={selectedItemDisplay}
              onValueSelect={onPeriodSelect}
            />
            <Dropdown
              selectedItem={time}
              items={timeItems}
              placeholder={time}
              maxHeight="220px"
              disabled={period === Immediately || timeIntervals.length === 0}
              onValueSelect={onTimeSelect}
            />
            <div className={cx('confirm', { invalid: !isValid })}>
              <span onClick={handleClick}>
                {t('widgets.competitorPricing.confirm')}
              </span>
            </div>
          </Popover.Content>
          <ActionAtCalendar
            period={period}
            value={options.date}
            onValueChange={onCalendarDateChange}
          />
        </Popover>
      )}
    </Overlay>
  )
}

ActionAtPopover.propTypes = {
  target: PropTypes.shape().isRequired,
  show: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    period: PropTypes.number.isRequired,
    date: PropTypes.shape(),
    time: PropTypes.string,
    timeIntervals: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onCalendarDateChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  onPeriodSelect: PropTypes.func.isRequired,
  onTimeSelect: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

ActionAtPopover.defaultProps = {}

export { ActionAtPopover }
