import React from 'react'
import PropTypes from 'prop-types'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import classNames from 'classnames/bind'
import styles from './Cover.module.scss'

const cx = classNames.bind(styles)

const Cover = ({ status }) => {
  if ([PriceChangeStatus.Edit, PriceChangeStatus.Confirm].includes(status)) {
    return null
  }

  return <div className={cx('cover')} />
}

Cover.propTypes = {
  status: PropTypes.string.isRequired,
}

export { Cover }
