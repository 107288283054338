import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormatStatus } from 'components/common/hooks/useFormatStatus'
import classNames from 'classnames/bind'
import { StationStatus, StationDataTypes } from 'utils/constants'
import styles from './StatusInformation.module.scss'

const cx = classNames.bind(styles)

const { CLOSED } = StationStatus
const { COST_PRICE, DIP, TRANSACTION } = StationDataTypes

const StatusInformationTranslations = {
  COST_PRICE: {
    header: 'widgets.stationStatus.costPrice',
    date: 'widgets.stationStatus.costPriceAtMessage',
  },
  DIP: {
    header: 'widgets.stationStatus.dip',
    date: 'widgets.stationStatus.dipAtMessage',
  },
  TRANSACTION: {
    header: 'widgets.stationStatus.transaction',
    date: 'widgets.stationStatus.transactionAtMessage',
  },
}

const StatusInformation = ({ type, status, stationStatus, timestamp }) => {
  const { t } = useTranslation()
  const {
    formatStatusDate,
    formatCostPriceStatusDate,
    formatStatus,
    formatTransactionStatus,
  } = useFormatStatus()

  const isTransaction = type === TRANSACTION
  const isCostPrice = type === COST_PRICE
  const isStationClosed = stationStatus === CLOSED

  const formattedStatus = isTransaction
    ? formatTransactionStatus(isStationClosed, status)
    : formatStatus(status)
  const formattedDate = isCostPrice
    ? formatCostPriceStatusDate(timestamp)
    : formatStatusDate(timestamp)

  const translations = StatusInformationTranslations[type]
  const statusClass = isTransaction && isStationClosed ? 'black' : status

  return (
    <div className={cx('status-information')}>
      <div>
        <h4>{t(translations.header)}</h4>
        <div className={cx('status-badge', statusClass)}>{formattedStatus}</div>
      </div>
      <div>
        {formattedDate &&
          t(translations.date, {
            date: `${formattedDate}`,
          })}
      </div>
    </div>
  )
}

StatusInformation.propTypes = {
  type: PropTypes.oneOf([TRANSACTION, COST_PRICE, DIP]).isRequired,
  status: PropTypes.string,
  stationStatus: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
}

StatusInformation.defaultProps = {
  stationStatus: null,
}

export { StatusInformation }
