import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useFormatPriceDifferential } from 'components/common/hooks/useFormatPriceDifferential'
import classNames from 'classnames/bind'
import { StationPriceButtons } from './components/station-price-buttons'
import { CashDiscountTooltip } from './components/cash-discount-tooltip'
import { NewPrice } from './components/new-price'
import { Price } from './components/price'
import styles from './PolePriceCell.module.scss'

const cx = classNames.bind(styles)

const parseFuelGradeData = (fuelGrade, pricing) => {
  const { id } = fuelGrade
  const {
    priceChangeEnabled,
    polePrices,
    polePriceChangeRequests,
    priceDifferentials: { cashCreditDifferentials, fuelGradeDifferentials },
    priceChanges,
  } = pricing

  const polePrice = polePrices.find(({ fuelGradeId }) => fuelGradeId === id)
  const polePriceChangeRequest = polePriceChangeRequests.find(
    ({ fuelGradeId }) => fuelGradeId === id
  )
  const cashCreditDifferential = cashCreditDifferentials.find(
    ({ fuelGradeId }) => fuelGradeId === id
  )
  const fuelGradeDifferential = fuelGradeDifferentials.find(
    ({ fuelGradeId }) => fuelGradeId === id
  )

  const leadGradeId = fuelGradeDifferentials.find(
    ({ fuelGradeId }) => fuelGradeId === fuelGradeDifferential?.leadGradeId
  )?.fuelGradeId

  const leadGradePolePrice = polePrices.find(
    ({ fuelGradeId }) => fuelGradeId === leadGradeId
  )

  const leadGradePriceChange = priceChanges.find(
    ({ fuelGradeId }) => fuelGradeId === leadGradeId
  )

  const priceChange = priceChanges.find(({ fuelGradeId }) => fuelGradeId === id)

  return {
    priceChangeEnabled,
    polePrice,
    polePriceChangeRequest,
    cashCreditDifferential,
    fuelGradeDifferential,
    priceChange,
    leadGradePolePrice,
    leadGradePriceChange,
  }
}

const PolePriceCell = ({
  fuelGrade,
  pricing,
  handleIncrementClick,
  handleDecrementClick,
}) => {
  const { stationsListIndividualPricing } = useFlags()
  const { formatPriceDifferential } = useFormatPriceDifferential()

  const {
    priceChangeEnabled,
    polePrice,
    polePriceChangeRequest,
    cashCreditDifferential,
    fuelGradeDifferential,
    priceChange,
    leadGradePolePrice,
    leadGradePriceChange,
  } = useMemo(() => {
    return parseFuelGradeData(fuelGrade, pricing)
  }, [fuelGrade, pricing])

  const hasPriceChange = priceChange && priceChange.priceChange !== 0
  const isInvalidStation = !priceChangeEnabled

  const showStationPriceButtons =
    stationsListIndividualPricing &&
    polePrice &&
    !isInvalidStation &&
    (!fuelGradeDifferential || fuelGradeDifferential.leadGrade)

  const differential = cashCreditDifferential ? (
    <CashDiscountTooltip cashCreditDifferential={cashCreditDifferential}>
      <div className={cx('differential')}>
        {formatPriceDifferential(cashCreditDifferential.differential)}
      </div>
    </CashDiscountTooltip>
  ) : (
    <div className={cx('differential')} />
  )

  return (
    <div
      key={fuelGrade.id}
      className={cx('pole-price-cell', { invalid: isInvalidStation })}
      style={{
        color: fuelGrade.primary,
      }}
    >
      <div className={cx('price-and-differential')}>
        <div className={cx('price')}>
          {hasPriceChange || leadGradePriceChange ? (
            <NewPrice
              priceChange={priceChange}
              polePriceChangeRequest={polePriceChangeRequest}
              polePrice={polePrice}
              fuelGradeDifferential={fuelGradeDifferential}
              leadGradePolePrice={leadGradePolePrice}
              leadGradePriceChange={leadGradePriceChange}
            />
          ) : (
            <Price
              polePrice={polePrice}
              polePriceChangeRequest={polePriceChangeRequest}
              hasPriceChange={hasPriceChange}
            />
          )}
        </div>

        <div className={cx('differential-and-buttons')}>
          {differential}
          <div
            className={cx('price-change-and-buttons', {
              'show-buttons': showStationPriceButtons,
            })}
          >
            <StationPriceButtons
              show={showStationPriceButtons}
              fuelGrade={fuelGrade}
              handleIncrementClick={handleIncrementClick}
              handleDecrementClick={handleDecrementClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

PolePriceCell.propTypes = {
  fuelGrade: PropTypes.shape().isRequired,
  pricing: PropTypes.shape().isRequired,
  handleIncrementClick: PropTypes.func.isRequired,
  handleDecrementClick: PropTypes.func.isRequired,
}

export { PolePriceCell }
