import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { ReactComponent as TrendUp } from 'images/common/up.svg'
import { ReactComponent as TrendDown } from 'images/common/down.svg'
import { Locales } from 'utils/constants'
import classNames from 'classnames/bind'
import styles from './PreviousPrice.module.scss'

const cx = classNames.bind(styles)

const getTooltipCopy = t => {
  const priceUnit = t('price.short')
  return `This indicates a ${priceUnit} value change of a current price vs. previous price in the last 48 hours`
}

const calculateDifference = polePrice => {
  const { price, previousPrice } = polePrice

  // US prices are returned in cents
  const differenceValue =
    i18n.language === Locales.EN_US
      ? (price - previousPrice.price) / 100
      : price - previousPrice.price
  return differenceValue.toFixed(2)
}

const PreviousPrice = ({ polePrice }) => {
  const { t } = useTranslation(['translations'])

  if (!polePrice?.previousPrice) {
    return null
  }

  const overlay = (
    <Tooltip className={cx('previous-price-tooltip')}>
      <div>{getTooltipCopy(t)}</div>
    </Tooltip>
  )

  const difference = calculateDifference(polePrice)
  const negative = difference < 0
  const TrendIcon = negative ? TrendDown : TrendUp

  return (
    <OverlayTrigger placement="top" overlay={overlay}>
      <div
        className={cx('previous-price', { negative })}
        data-testid="previous-price"
      >
        <div className={cx('trend-icon')}>
          <TrendIcon />
        </div>
        {Math.abs(difference)}
      </div>
    </OverlayTrigger>
  )
}

PreviousPrice.propTypes = {
  polePrice: PropTypes.shape(),
}

export { PreviousPrice }
