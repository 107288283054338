import React from 'react'
import classNames from 'classnames/bind'
import Tooltip from 'react-bootstrap/Tooltip'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { PropTypes } from 'prop-types'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import styles from './Grade.module.scss'
import infoIcon from 'images/info-icon.svg'
import { MetricTypes } from 'utils/constants'

const cx = classNames.bind(styles)
const { VOLUME } = MetricTypes

const Grade = ({ colour, volume, title, blended, width }) => {
  const { formatMetricValue } = useFormatMetricValue()
  const { t } = useTranslation()

  const gradeVolume = formatMetricValue(VOLUME, volume)
  const volumeString = `${gradeVolume}`

  const gradeStyle = {
    backgroundColor: `${colour}`,
    width: `${width}px`,
  }

  const BlendedTitle = () => {
    const overlay = (
      <Tooltip className={cx('info-icon-tooltip')}>
        <div>{t('widgets.grade.blendedTooltip')}</div>
      </Tooltip>
    )

    return (
      <div className={cx('info-icon-tooltip-overlay')}>
        {title} - <strong>{t('widgets.grade.blended')}</strong>
        <OverlayTrigger
          placement="top"
          overlay={overlay}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                boundariesElement: 'offsetParent',
              },
            },
          }}
        >
          <img src={infoIcon} alt={'info-icon'} className={cx('info-icon')} />
        </OverlayTrigger>
      </div>
    )
  }

  return (
    <div className={cx('grade-container')} style={gradeStyle}>
      <div className={cx('grade-title')}>
        {blended ? <BlendedTitle /> : title}
      </div>
      <div className={cx('grade-volume')}>{blended ? '-' : volumeString}</div>
    </div>
  )
}

Grade.propTypes = {
  title: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
  colour: PropTypes.string.isRequired,
  blended: PropTypes.bool,
  width: PropTypes.number.isRequired,
}

Grade.defaultProps = {
  title: '',
  volume: '',
  colour: '',
  blended: false,
}

export { Grade }
