import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery, NetworkStatus } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LoadingIcon } from 'images/stations-list/loading.svg'
import { ReactComponent as UpIcon } from 'images/stations-list/up.svg'
import { STATIONS_LIST_GROUP_PAGE_QUERY } from 'components/stations-list/StationsListQueries'
import { useStationsListPagination } from 'components/stations-list/hooks/useStationsListPagination'
import { useGroupedPricing } from 'components/stations-list/context/groupedPricingContext'
import { GroupedPricingActions } from 'components/stations-list/utils/constants'
import { GroupStationsCheckbox } from 'components/stations-list/components/stations-table/components/edit-pricing/group-stations-checkbox'
import { StationOverlay } from 'components/stations-list/components/stations-table/components/edit-pricing/station-overlay'
import { StationRows } from 'components/stations-list/components/stations-table/components/station-rows'
import { MetricCells } from 'components/stations-list/components/stations-table/components/metric-cells'
import { ImageAsset } from 'components/common/image-asset'
import { images, fallback } from 'images/image-library'
import classNames from 'classnames/bind'
import styles from './GroupRow.module.scss'

const cx = classNames.bind(styles)

const renderGroupByImage = (selectedGroupBy, name, brands) => {
  if (selectedGroupBy === 'Fuel Brand') {
    const brandKey = brands.find(brand => brand.name === name)?.key
    return (
      <ImageAsset
        src={images.brandImage(brandKey)}
        height={50}
        width={50}
        brand={brandKey}
        alt={`fuel-brand-${brandKey}`}
        fallbackImage={fallback.defaultBrand}
      />
    )
  }
  return null
}

const renderGroup = (selectedGroupBy, open, name, stationsCount, t, brands) => {
  return (
    <>
      {renderGroupByImage(selectedGroupBy, name, brands)}
      <div>
        <div className={cx('group-name')}>{name}</div>
        <div className={cx('station-count', { open })}>
          {stationsCount}{' '}
          {stationsCount > 1
            ? t('widgets.stationsList.stations')
            : t('widgets.stationsList.station')}
          <UpIcon />
        </div>
      </div>
    </>
  )
}

const renderStations = (
  open,
  groupData,
  mainData,
  showPricingView,
  cashCreditPricing
) => {
  if (!open || !groupData) {
    return null
  }

  const {
    stations: { items },
  } = groupData
  const {
    me: {
      stations: { items: stations },
    },
  } = mainData

  return (
    <>
      <StationOverlay items={items} stations={stations} />
      <StationRows
        items={items}
        stations={stations}
        showPricingView={showPricingView}
        cashCreditPricing={cashCreditPricing}
        loopStations={true}
      />
    </>
  )
}

const renderLoadingPageIcon = (open, networkStatus) => {
  if (!open || networkStatus === NetworkStatus.ready) {
    return null
  }
  return (
    <div className={cx('loading')} data-testid="loading">
      <LoadingIcon />
    </div>
  )
}

const renderPaginationTrigger = (
  open,
  loading,
  networkStatus,
  nextPage,
  paginationRef
) => {
  // render the trigger if the group is open, we're not loading a page and there is a next page available
  if (!open || loading || networkStatus !== NetworkStatus.ready || !nextPage) {
    return null
  }
  return <div ref={paginationRef} />
}

const GroupRow = ({
  group: { name, stationsCount, isSelected, pricingStationIds, metrics },
  selectedGroupBy,
  variables,
  mainData,
  showPricingView,
  cashCreditPricing,
}) => {
  const [{ openGroups, loadingGroups }, dispatch] = useGroupedPricing()
  const [
    loadFirstGroupPage,
    { loading, called, error, data, fetchMore, networkStatus },
  ] = useLazyQuery(STATIONS_LIST_GROUP_PAGE_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables,
  })

  useErrorHandler(error)
  const [paginationRef] = useStationsListPagination(
    fetchMore,
    data,
    networkStatus
  )
  const { t } = useTranslation()

  const isOpen = openGroups.includes(name)
  const isLoading = loadingGroups.includes(name)

  useEffect(() => {
    if (data) {
      const {
        stations: { items },
      } = data
      dispatch({
        type: GroupedPricingActions.SET_GROUP_DATA,
        payload: { group: name, data: items },
      })
    }
  }, [data, dispatch, name])

  useEffect(() => {
    if (isLoading !== loading) {
      dispatch({
        type: GroupedPricingActions.SET_LOADING_GROUP,
        payload: { isLoading: loading, group: name },
      })
    }
  }, [isLoading, loading, dispatch, name])

  const handleClick = () => {
    if (!loading) {
      dispatch({
        type: GroupedPricingActions.SET_OPEN_GROUP,
        payload: { isOpen: !isOpen, group: name },
      })
    }

    if (!called) {
      loadFirstGroupPage()
    }
  }

  const nextPage = data?.stations?.pageInfo?.nextPage

  const brands = [...new Set(mainData.me.stations.items.map(i => i.brand))]

  return (
    <>
      <div
        className={cx('group-row')}
        onClick={handleClick}
        data-testid="group-row"
      >
        <div className={cx('group-cell', { 'edit-pricing': showPricingView })}>
          <GroupStationsCheckbox
            groupSelected={isSelected}
            showPricingView={showPricingView}
            groupStationIds={pricingStationIds}
          />
          {renderGroup(selectedGroupBy, isOpen, name, stationsCount, t, brands)}
        </div>
        <MetricCells metrics={metrics} showPricingView={showPricingView} />
      </div>
      {renderStations(
        isOpen,
        data,
        mainData,
        showPricingView,
        cashCreditPricing
      )}
      {renderLoadingPageIcon(isOpen, networkStatus)}
      {renderPaginationTrigger(
        isOpen,
        loading,
        networkStatus,
        nextPage,
        paginationRef
      )}
    </>
  )
}

GroupRow.propTypes = {
  group: PropTypes.shape().isRequired,
  selectedGroupBy: PropTypes.string.isRequired,
  variables: PropTypes.shape().isRequired,
  mainData: PropTypes.shape().isRequired,
  showPricingView: PropTypes.bool.isRequired,
  cashCreditPricing: PropTypes.bool.isRequired,
}

export { GroupRow }
