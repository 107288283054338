import {
  determinePriceStep,
  findPrice,
  isMatchingPendingRequest,
  findPendingRequest,
  findChangedPendingRequest,
} from 'components/station/components/competitor-pricing/utils/helpers'
import { numberToFixed } from 'utils/number'
import { PricingTypes, FuelGradeNames } from 'utils/constants'

const { CASH, CREDIT, ALL } = PricingTypes

const buildPendingRequest = (
  fuelGradePrices,
  cashCreditPricing,
  fuelGradeId,
  pricingType,
  stationId
) => {
  const price = findPrice(fuelGradePrices, pricingType, cashCreditPricing)
    ?.price
  return {
    price,
    newPrice: price,
    fuelGradeId,
    pricingType,
    stationId,
  }
}

const mapFuelGradeToPendingRequests = (fuelGrade, station) => {
  const {
    fuelGrade: { id: fuelGradeId },
  } = fuelGrade
  const { id: stationId, polePrices, cashCreditPricing } = station

  const fuelGradePrices = polePrices.filter(
    polePrice => polePrice.fuelGradeId === fuelGradeId
  )

  // pending requests are either for (CASH/CREDIT) or just (ALL) depending on the station's cash/credit status
  if (cashCreditPricing) {
    return [
      buildPendingRequest(
        fuelGradePrices,
        cashCreditPricing,
        fuelGradeId,
        CASH,
        stationId
      ),
      buildPendingRequest(
        fuelGradePrices,
        cashCreditPricing,
        fuelGradeId,
        CREDIT,
        stationId
      ),
    ]
  }

  return [
    buildPendingRequest(
      fuelGradePrices,
      cashCreditPricing,
      fuelGradeId,
      ALL,
      stationId
    ),
  ]
}

/**
 * Builds the pending requests based off the current station's fuel grades and cash credit pricing status.
 */
const initialisePendingRequests = station => {
  const { fuelGrades } = station

  const validFuelGrades = fuelGrades.filter(
    ({ fuelGrade }) => fuelGrade.title !== FuelGradeNames.TOTAL
  )

  return validFuelGrades
    .map(fuelGrade => mapFuelGradeToPendingRequests(fuelGrade, station))
    .flat()
}

const updatePendingRequest = (
  pendingRequests,
  newPrice,
  fuelGradeId,
  pricingType
) => {
  return pendingRequests.map(pendingRequest => {
    const match = isMatchingPendingRequest(
      pendingRequest,
      fuelGradeId,
      pricingType
    )
    if (match) {
      return { ...pendingRequest, newPrice }
    }
    return pendingRequest
  })
}

const handleCashCreditDifferential = (
  newPendingRequests,
  pricingType,
  cashCreditDifferentials,
  newPrice,
  fuelGradeId
) => {
  const differential = cashCreditDifferentials.find(
    cashCreditDifferential => cashCreditDifferential.fuelGradeId === fuelGradeId
  )?.differential

  if (pricingType !== ALL && differential) {
    const oppositePricingType = pricingType === CASH ? CREDIT : CASH
    const oppositePricingTypeNewPrice = numberToFixed(
      pricingType === CASH ? newPrice + differential : newPrice - differential
    )

    return updatePendingRequest(
      newPendingRequests,
      oppositePricingTypeNewPrice,
      fuelGradeId,
      oppositePricingType
    )
  }

  return newPendingRequests
}

const handleFuelGradeDifferentials = (
  newPendingRequests,
  pricingType,
  fuelGradeDifferentials,
  newPrice,
  fuelGradeId,
  station
) => {
  const leadGrade = fuelGradeDifferentials.find(
    fuelGradeDifferential => fuelGradeDifferential.fuelGradeId === fuelGradeId
  )?.leadGrade

  if (leadGrade) {
    const followerGradeDifferentials = fuelGradeDifferentials.filter(
      fuelGradeDifferential => fuelGradeDifferential.leadGradeId === fuelGradeId
    )

    for (const followerGradeDifferential of followerGradeDifferentials) {
      const {
        fuelGradeId: followerGradeId,
        differential,
      } = followerGradeDifferential
      const followerGradeCashPrice = numberToFixed(newPrice + differential)

      newPendingRequests = updatePendingRequests(
        newPendingRequests,
        pricingType,
        followerGradeId,
        followerGradeCashPrice,
        station
      )
    }
  }
  return newPendingRequests
}

/**
 * Price changes can be made for a station with with either CASH/CREDIT or single (ALL) prices.
 *
 * Two type of differentials need to be considered when changing a pending request's price.
 * 1) cash/credit differential (for CASH/CREDIT stations)
 * 2) fuel grade differentials (for both CASH/CREDIT and ALL stations)
 */
const updatePendingRequests = (
  pendingRequests,
  pricingType,
  fuelGradeId,
  newPrice,
  station
) => {
  const {
    priceDifferentials: { cashCreditDifferentials, fuelGradeDifferentials },
  } = station

  let newPendingRequests = [...pendingRequests]
  newPendingRequests = updatePendingRequest(
    newPendingRequests,
    newPrice,
    fuelGradeId,
    pricingType
  )

  newPendingRequests = handleCashCreditDifferential(
    newPendingRequests,
    pricingType,
    cashCreditDifferentials,
    newPrice,
    fuelGradeId
  )

  newPendingRequests = handleFuelGradeDifferentials(
    newPendingRequests,
    pricingType,
    fuelGradeDifferentials,
    newPrice,
    fuelGradeId,
    station
  )

  return newPendingRequests
}

/**
 * Finds the pending request for the given fuel grade and pricing type then increments/decrements its newPrice.
 *
 * The size of the increment/decrement step is determined by the portfolio locale.
 */
const incrementOrDecrementPrice = (state, payload, increment, station) => {
  const { fuelGradeId, pricingType } = payload
  const { pendingRequests } = state

  const { newPrice } = findPendingRequest(
    pendingRequests,
    pricingType,
    fuelGradeId
  )

  const priceStep = determinePriceStep()
  const updatedPrice = increment
    ? numberToFixed(newPrice + priceStep)
    : numberToFixed(newPrice - priceStep)

  return {
    ...state,
    pendingRequests: updatePendingRequests(
      pendingRequests,
      pricingType,
      fuelGradeId,
      updatedPrice,
      station
    ),
  }
}

/**
 * Finds the pending request for the given fuel grade and pricing type then sets its newPrice based on the given newPrice.
 */
const setPrice = (state, payload, station) => {
  const { pendingRequests } = state
  const { fuelGradeId, pricingType, newPrice } = payload

  return {
    ...state,
    pendingRequests: updatePendingRequests(
      pendingRequests,
      pricingType,
      fuelGradeId,
      newPrice,
      station
    ),
  }
}

/**
 * This function handles the user updating a fuel grade differential while editing their prices.
 *
 * If no change is present for the lead grade then do nothing. If the lead grade has a pending price change then pass this existing change
 * into updatePendingRequests to regenerate all changes for both the lead grade and the other grades in the differential.
 *
 * NOTE: this currently only works for GB or IE customers with a single pricing type.
 */
const recalculateFromLeadGrade = (state, payload, station) => {
  const { pendingRequests } = state
  const { leadGradeId } = payload

  const leadGradePendingRequest = findChangedPendingRequest(
    pendingRequests,
    ALL,
    leadGradeId
  )
  if (!leadGradePendingRequest) {
    return state
  }

  const newPendingRequests = updatePendingRequests(
    pendingRequests,
    ALL,
    leadGradeId,
    leadGradePendingRequest.newPrice,
    station
  )

  return {
    ...state,
    pendingRequests: newPendingRequests,
  }
}

export {
  initialisePendingRequests,
  incrementOrDecrementPrice,
  setPrice,
  recalculateFromLeadGrade,
}
