import { makeVar } from '@apollo/client'
import { StationsListPricingStatus } from 'components/stations-list/utils/constants'
import { defaultActionAt, defaultReminders } from 'utils/pricing/constants'

/**
 * Holds the current pricing status on the stations list widget.
 *
 * Defaults to NONE with no pole prices shown.
 */
const statusVar = makeVar(StationsListPricingStatus.NONE)

/**
 * Holds the visible station in the station overlay.
 *
 * Defaults to null with the overlay hidden.
 */
const overlayStationVar = makeVar(null)

/**
 * Holds the IDs of stations that are currently selected for price changes (either globally or individually).
 */
const selectedStationIdsVar = makeVar([])

/**
 * Fuel grade price changes are global across all selected (non-individually priced) stations.
 *
 * @example
 *  [
 *    { fuelGradeId: "1", priceChange: 1 },
 *    { fuelGradeId: "2", priceChange: 0 }
 *  ]
 */
const fuelGradePriceChangesVar = makeVar([])

/**
 * Price changes for individual stations. Each entry is for a single station.
 *
 * null represents no station level price changes for this station.
 *
 * @example
 *  {
 *    "station-1": [{ fuelGradeId: 1, priceChange: 1 }, { fuelGradeId: 2, priceChange: 0 }],
 *    "station-2": null
 *  }
 */
const stationPriceChangesVar = makeVar({})

/**
 * Reminders to be sent will all price change requests.
 */
const remindersVar = makeVar(defaultReminders)

/**
 * A combination of the period, date and time determines when the price changes should be executed. Sent with all change requests.
 */
const actionAtVar = makeVar(defaultActionAt)

const resetVariables = () => {
  selectedStationIdsVar([])
  fuelGradePriceChangesVar([])
  stationPriceChangesVar({})
  remindersVar(defaultReminders)
  actionAtVar(defaultActionAt)
  overlayStationVar(null)
}

export {
  statusVar,
  overlayStationVar,
  selectedStationIdsVar,
  fuelGradePriceChangesVar,
  stationPriceChangesVar,
  remindersVar,
  actionAtVar,
  resetVariables,
}
