import React from 'react'
import classNames from 'classnames/bind'
import styles from './TabContent.module.scss'
import { PropTypes } from 'prop-types'
import Tab from 'react-bootstrap/Tab'
import { FuelGradeSelector } from './fuel-grade-selector'

const cx = classNames.bind(styles)

const TabContent = ({
  stationId,
  todaysGrossMargin,
  targetMargin,
  activeTab,
  tabContentData,
  selectedGrade,
  selectedProfit,
  hasBunkered,
  handleSelectedGrade,
  handleSelectedProfit,
  metricPermissions,
  isOverlayMode,
}) => {
  return (
    <Tab.Content>
      <Tab.Pane eventKey={1}>
        <div className={cx('station-performance-container')}>
          <FuelGradeSelector
            stationId={stationId}
            todaysGrossMargin={todaysGrossMargin}
            targetMargin={targetMargin}
            activeTab={activeTab}
            tabContentData={tabContentData}
            selectedGrade={selectedGrade}
            hasBunkered={hasBunkered}
            handleSelectedGrade={handleSelectedGrade}
            handleSelectedProfit={handleSelectedProfit}
            selectedProfit={selectedProfit}
            metricPermissions={metricPermissions}
            isOverlayMode={isOverlayMode}
          />
        </div>
      </Tab.Pane>
    </Tab.Content>
  )
}

TabContent.propTypes = {
  stationId: PropTypes.string.isRequired,
  todaysGrossMargin: PropTypes.number.isRequired,
  targetMargin: PropTypes.number,
  activeTab: PropTypes.string.isRequired,
  tabContentData: PropTypes.array.isRequired,
  selectedGrade: PropTypes.string.isRequired,
  selectedProfit: PropTypes.string.isRequired,
  hasBunkered: PropTypes.bool.isRequired,
  handleSelectedGrade: PropTypes.func.isRequired,
  handleSelectedProfit: PropTypes.func.isRequired,
  metricPermissions: PropTypes.shape({
    readMargin: PropTypes.bool,
    readProfit: PropTypes.bool,
    readVolume: PropTypes.bool,
  }).isRequired,
  isOverlayMode: PropTypes.bool,
}

TabContent.defaultProps = { isOverlayMode: false }

export { TabContent }
