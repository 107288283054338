import { competitorSortVar } from 'config/apollo/competitor-pricing/variables'
import {
  CompetitorSorts,
  SortDirections,
} from 'components/station/components/competitor-pricing/utils/constants'
import { PricingTypes } from 'utils/constants'
import { gql } from '@apollo/client'

const { FUEL_GRADE_SORT, DRIVING_DISTANCE_SORT } = CompetitorSorts
const { ASC } = SortDirections
const { ALL, CASH, CREDIT } = PricingTypes
const PRICING_TYPES_QUERY = gql`
  query pricingTypesQuery {
    pricingTypes @client {
      selected
    }
  }
`

const drivingDistanceSort = (competitors, currentSort, readField) => {
  const { direction } = currentSort

  const withoutDistances = competitors.filter(
    competitor =>
      readField('distances', competitor).drivingDistanceMiles === null
  )

  const withDistances = competitors
    .filter(
      competitor =>
        readField('distances', competitor).drivingDistanceMiles !== null
    )
    .sort((stationOne, stationTwo) => {
      const stationOneDistances = readField('distances', stationOne)
      const stationTwoDistances = readField('distances', stationTwo)

      return (
        stationOneDistances.drivingDistanceMiles -
        stationTwoDistances.drivingDistanceMiles
      )
    })

  const sort =
    direction === ASC
      ? [...withDistances, ...withoutDistances]
      : [...withDistances.reverse(), ...withoutDistances]
  return sort
}

const fuelGradeSort = (
  competitors,
  currentSort,
  readField,
  selectedPricingType
) => {
  const { direction, grade } = currentSort

  const withoutPrices = competitors.filter(
    competitor =>
      !readField('polePrices', competitor)
        .map(price => readField('fuelGradeId', price))
        .includes(grade)
  )

  const withPrices = competitors
    .filter(competitor =>
      readField('polePrices', competitor)
        .map(price => readField('fuelGradeId', price))
        .includes(grade)
    )
    .sort((competitorOne, competitorTwo) => {
      const filterPriceForPricingType = (prices, priceType) =>
        readField(
          'price',
          ...prices.filter(
            price =>
              readField('pricingType', price) === priceType &&
              readField('fuelGradeId', price) === grade
          )
        )

      const competitorPrice = competitor => {
        const prices = readField('polePrices', competitor)

        const allPrice = filterPriceForPricingType(prices, ALL)
        const cashPrice = filterPriceForPricingType(prices, CASH)
        const creditPrice = filterPriceForPricingType(prices, CREDIT)

        switch (selectedPricingType) {
          case CASH:
            return cashPrice || creditPrice || allPrice
          case CREDIT:
            return creditPrice || cashPrice || allPrice
          default:
            return creditPrice || allPrice || cashPrice
        }
      }

      const competitorOnePrice = competitorPrice(competitorOne)
      const competitorTwoPrice = competitorPrice(competitorTwo)

      return competitorOnePrice - competitorTwoPrice
    })

  const sort =
    direction === ASC
      ? [...withPrices, ...withoutPrices]
      : [...withPrices.reverse(), ...withoutPrices]
  return sort
}

const sortedCompetitors = {
  read(_, { readField, cache }) {
    const competitors = readField('competitors')
    if (!competitors) {
      return
    }

    const {
      pricingTypes: { selected: selectedPricingType },
    } = cache.read({ query: PRICING_TYPES_QUERY })

    const competitorSort = competitorSortVar()
    const { type } = competitorSort

    switch (type) {
      case DRIVING_DISTANCE_SORT:
        return drivingDistanceSort(competitors, competitorSort, readField)
      case FUEL_GRADE_SORT:
        return fuelGradeSort(
          competitors,
          competitorSort,
          readField,
          selectedPricingType
        )
      default:
        return
    }
  },
}

export { sortedCompetitors, drivingDistanceSort, fuelGradeSort }
