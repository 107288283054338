import { useTranslation } from 'react-i18next'
import { currentDayInZone } from 'utils/dateTime'
import { useCompanyTimeZone } from '../hooks/useCompanyTimeZone'

function useCurrentDayTranslated() {
  const { t } = useTranslation(['translations'])
  const { companyTimeZone } = useCompanyTimeZone()

  const day = currentDayInZone(companyTimeZone)

  function currentDayTranslated() {
    switch (day) {
      case 'Monday':
        return t('widgets.trendIndicator.monday')
      case 'Tuesday':
        return t('widgets.trendIndicator.tuesday')
      case 'Wednesday':
        return t('widgets.trendIndicator.wednesday')
      case 'Thursday':
        return t('widgets.trendIndicator.thursday')
      case 'Friday':
        return t('widgets.trendIndicator.friday')
      case 'Saturday':
        return t('widgets.trendIndicator.saturday')
      case 'Sunday':
        return t('widgets.trendIndicator.sunday')
      /* istanbul ignore next */
      default:
        return '-'
    }
  }

  return { currentDayTranslated }
}

export { useCurrentDayTranslated }
