import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DropdownIcon } from 'images/common/dropdown.svg'
import { ReactComponent as ActiveIcon } from 'images/checkbox/active.svg'
import { ReactComponent as InactiveIcon } from 'images/checkbox/inactive.svg'
import { ReactComponent as SearchIcon } from 'images/stations-selector/search.svg'
import { stationMatches } from 'utils/search'
import classNames from 'classnames/bind'
import styles from './MultiSelect.module.scss'

const cx = classNames.bind(styles)

const renderItems = (
  items,
  value,
  onValueSelect,
  minSelected,
  onLessThanMinSelected,
  maxSelected,
  onMoreThanMaxSelected,
  search
) => {
  if (search) {
    items = items.filter(s => stationMatches(s, search))
  }

  return items.map(item => {
    const handleClick = event => {
      let newValue = value.slice()
      if (newValue.includes(item)) {
        const index = newValue.indexOf(item)
        newValue.splice(index, 1)
      } else {
        newValue = [...newValue, item]
      }
      newValue.sort((a, b) => {
        const ai = items.indexOf(a)
        const bi = items.indexOf(b)
        return ai - bi
      })

      if (newValue.length < minSelected) {
        if (onLessThanMinSelected) {
          onLessThanMinSelected(event)
        }
      } else if (newValue.length > maxSelected) {
        if (onMoreThanMaxSelected) {
          onMoreThanMaxSelected(event)
        }
      } else {
        onValueSelect(newValue.slice())
      }
    }

    return (
      <div
        key={item}
        className={cx('item')}
        onClick={handleClick}
        data-testid="item"
      >
        <span>{item}</span>
        {value.includes(item) ? <ActiveIcon /> : <InactiveIcon />}
      </div>
    )
  })
}

const MultiSelect = ({
  value,
  items,
  onValueSelect,
  customClass,
  placeholder,
  maxHeight,
  disabled,
  allItemsText,
  leftAccessory,
  minSelected,
  maxSelected,
  onLessThanMinSelected,
  onMoreThanMaxSelected,
  onDropdownClick,
  selectButtons,
  viewSearch,
  children,
  customHandleSelectAll,
  customHandleDeselectAll
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const handleDropdownClick = () => {
    if (!disabled) {
      onDropdownClick(open)
      setOpen(!open)
    }
  }

  const handleItemClick = event => {
    event.stopPropagation()
  }

  const handleSelectAll = () => {
    onValueSelect(items)
    viewSearch && setSearch('')
    customHandleSelectAll?.()
  }

  const handleDeselectAll = () => {
    onValueSelect([])
    viewSearch && setSearch('')
    customHandleDeselectAll?.()
  }

  const handleSearchChange = event => {
    const search = event.target.value.replace(/\s+/g, ' ')
    setSearch(search)
  }

  const showPlaceholder = value.length === 0
  const displayedValue = showPlaceholder
    ? placeholder
    : value.length === items.length
    ? allItemsText ?? value.join(', ')
    : value.join(', ')
  return (
    <div
      className={cx('dropdown', customClass, {
        disabled,
        open,
      })}
      onClick={handleDropdownClick}
      data-testid="multi-select"
    >
      <span className={cx({ placeholder: showPlaceholder })}>
        {leftAccessory} {displayedValue}
      </span>
      {!disabled && <DropdownIcon className={cx('dropdown-icon')} />}
      {open && (
        <div className={cx('items')} onClick={handleItemClick}>
          <div className={cx('item')} onClick={handleDropdownClick}>
            <span className={cx({ placeholder: showPlaceholder })}>
              {leftAccessory} {displayedValue}
            </span>
            <DropdownIcon className={cx('dropdown-icon')} />
          </div>
          <div
            className={cx('items-list')}
            data-testid="items-list"
            style={{
              maxHeight,
            }}
          >
            {viewSearch && (
              <div className={cx('search')}>
                <input
                  type="text"
                  value={search}
                  onChange={handleSearchChange}
                  placeholder="Search for a region"
                  data-testid="search"
                />
                <SearchIcon />
              </div>
            )}
            {renderItems(
              items,
              value,
              onValueSelect,
              minSelected,
              onLessThanMinSelected,
              maxSelected,
              onMoreThanMaxSelected,
              search
            )}
          </div>
          {selectButtons && (
            <div className={cx('selection-helpers')}>
              <button
                onClick={handleSelectAll}
                data-testid="select-all"
              >
                {t('widgets.stationSelector.selectAll')}
              </button>
              <button
                onClick={handleDeselectAll}
                data-testid="deselect-all"
              >
                {t('widgets.stationSelector.deselectAll')}
              </button>
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  )
}

MultiSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.string),
  onValueSelect: PropTypes.func,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  maxHeight: PropTypes.string,
  allItemsText: PropTypes.string,
  disabled: PropTypes.bool,
  leftAccessory: PropTypes.node,
  minSelected: PropTypes.number,
  onLessThanMinSelected: PropTypes.func,
  maxSelected: PropTypes.number,
  onMoreThanMaxSelected: PropTypes.func,
  onDropdownClick: PropTypes.func,
  selectButtons: PropTypes.bool,
  viewSearch: PropTypes.bool,
  customHandleSelectAll: PropTypes.func,
  customHandleDeselectAll: PropTypes.func,
}

MultiSelect.defaultProps = {
  value: [],
  items: [],
  onValueSelect: null,
  customClass: null,
  placeholder: null,
  maxHeight: null,
  allItemsText: null,
  disabled: false,
  leftAccessory: null,
  minSelected: 0,
  maxSelected: 100,
  onLessThanMinSelected: null,
  onMoreThanMaxSelected: null,
  onDropdownClick: null,
  selectButtons: false,
  viewSearch: false,
  customHandleSelectAll: null,
  customHandleDeselectAll: null,
}

export { MultiSelect }
