import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './CompetitorMap.module.scss'
import { MapMarkers } from './components/map-markers'
import { MapLegend } from './components/map-legend'
import { MapContainer, TileLayer, AttributionControl } from 'react-leaflet'

const cx = classNames.bind(styles)

const CompetitorMap = ({ station, selectedFuelGrade }) => {
  const [legendView, setLegendView] = useState('open')

  return (
    <MapContainer
      attributionControl={false}
      className={cx('map')}
      center={[station.latitude, station.longitude]}
      zoom={13}
      minZoom={4}
      maxZoom={16}
      scrollWheelZoom={false}
    >
      <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <AttributionControl prefix="&copy; <a target='_BLANK' href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors'" />
      <MapMarkers station={station} selectedFuelGrade={selectedFuelGrade} />
      <MapLegend legendView={legendView} onToggle={setLegendView} />
    </MapContainer>
  )
}

CompetitorMap.propTypes = {
  station: PropTypes.shape().isRequired,
  selectedFuelGrade: PropTypes.string.isRequired,
}

export { CompetitorMap }
