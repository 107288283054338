import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Down } from 'images/arrows/arrow-down.svg'
import { ReactComponent as DownDisabled } from 'images/arrows/arrow-down-light.svg'
import classNames from 'classnames/bind'
import styles from './DownArrow.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const DownArrow = ({ editPricingInUse, showCalendar, setShowCalendar }) => {
  const handleClick = () => {
    if (!showCalendar) {
      amplitudeEvent(UserEvents.CompetitorPricing.DATE)
    }

    setShowCalendar(!showCalendar)
  }
  if (editPricingInUse && !showCalendar) {
    return (
      <div className={cx('down-arrow')}>
        <DownDisabled
          className={cx('disabled')}
          data-testid="down-arrow-disabled"
        />
      </div>
    )
  }

  return (
    <div
      className={cx('down-arrow')}
      onClick={() => handleClick()}
      data-testid="down-arrow"
    >
      <Down />
    </div>
  )
}

DownArrow.propTypes = {
  editPricingInUse: PropTypes.bool.isRequired,
  showCalendar: PropTypes.bool.isRequired,
  setShowCalendar: PropTypes.func.isRequired,
}

export { DownArrow }
