import { gql } from '@apollo/client'

export const AUTH_CONTEXT_QUERY = gql`
  query authContextQuery {
    me {
      id
      email
      isNotificationUser
      hasPassword
      hasFinishedAccountSetup
      locale
      termsOfService
      termsOfServiceVersion
      firstName
      lastName
      company {
        name
        edgeDataPro
      }
    }
  }
`
export const ACCEPT_TOS_MUTATION = gql`
  mutation acceptTermsOfService($version: String!) {
    acceptTermsOfService(version: $version) {
      error
    }
  }
`

export const SET_INITIAL_PASSWORD_MUTATION = gql`
  mutation setInitialPassword(
    $mobileNumber: String!
    $password: String!
    $finishedAccountSetupAt: String!
  ) {
    setInitialPassword(
      input: {
        mobileNumber: $mobileNumber
        password: $password
        finishedAccountSetupAt: $finishedAccountSetupAt
      }
    ) {
      error
    }
  }
`
