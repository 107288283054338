import { ReactComponent as smallProfitIcon } from './icons/pound-symbol.svg'

const translations = {
  petrol: 'Petrol',
  currency: {
    title: 'GBP',
    short: '£',
  },
  volume: {
    title: 'Litres',
    short: 'ℓ',
  },
  margin: {
    title: 'Pence Per Litre',
    short: 'ppl',
  },
  distance: {
    title: 'miles',
    short: 'mi',
  },
  price: {
    short: 'PPL',
  },
  cost: {
    short: 'ppl',
  },
  favourite: 'Favourite',
  ordinals: {
    one: 'st',
    two: 'nd',
    few: 'rd',
    many: 'th',
    zero: 'th',
    other: 'th',
  },
  widgets: {
    performanceReport: {
      yAxisLabels: {
        volume: 'Fuel volume / ℓ',
        margin: 'Margin / ppl',
        profit: 'Profit / £',
        number: 'Transactions',
      },
    },
    settings: {
      customerPortal: 'uk-ireland',
    },
    analytics: {
      yAxisLabels: {
        total_volume: 'Total volume / ℓ',
        retail_volume: 'Retail volume / ℓ',
        bunkered_volume: 'Bunkered volume / ℓ',
        consumer_volume: 'Consumer volume / ℓ',
        fuel_card_volume: 'Fuel card volume / ℓ',
        gross_margin: 'Gross margin / ppl',
        net_margin: 'Net margin / ppl',
        gross_sales: 'Gross sales / £',
        gross_profit: 'Gross profit / £',
        net_profit: 'Net profit / £',
        business_profit: 'Business profit / £',
        bunkering_commissions: 'Bunkering commissions / £',
        replacement_cost: 'Replacement cost / ppl',
        total_fees: 'Total fees / £',
        blended_cost_sold: 'Blended cost sold / £',
        retail_transactions: 'Retail transactions',
        retail_fill_up: 'Retail fill up / ℓ',
        pole_price: 'Pole price / ppl',
      },
    },
  },
}

export { translations, smallProfitIcon }
