import { ReactComponent as smallProfitIcon } from './icons/euro-symbol.svg'

const translations = {
  petrol: 'Petrol',
  currency: {
    title: 'EUR',
    short: '€',
  },
  volume: {
    title: 'Litres',
    short: 'ℓ',
  },
  margin: {
    title: 'Cent Per Litre',
    short: 'cpl',
  },
  distance: {
    title: 'kilometres',
    short: 'km',
  },
  price: {
    short: 'CPL',
  },
  cost: {
    short: 'cpl',
  },
  favourite: 'Favourite',
  ordinals: {
    one: 'st',
    two: 'nd',
    few: 'rd',
    many: 'th',
    zero: 'th',
    other: 'th',
  },
  widgets: {
    performanceReport: {
      yAxisLabels: {
        volume: 'Fuel volume / l',
        margin: 'Margin / cpl',
        profit: 'Profit / €',
        number: 'Transactions',
      },
    },
    settings: {
      customerPortal: 'uk-ireland',
    },
    analytics: {
      yAxisLabels: {
        total_volume: 'Total volume / l',
        retail_volume: 'Retail volume / l',
        bunkered_volume: 'Bunkered volume / l',
        consumer_volume: 'Consumer volume / l',
        fuel_card_volume: 'Fuel card volume / l',
        gross_margin: 'Gross margin / cpl',
        net_margin: 'Net margin / cpl',
        gross_sales: 'Gross sales / €',
        gross_profit: 'Gross profit / €',
        net_profit: 'Net profit / €',
        business_profit: 'Business profit / €',
        bunkering_commissions: 'Bunkering commissions / €',
        replacement_cost: 'Replacement cost / cpl',
        total_fees: 'Total fees / €',
        blended_cost_sold: 'Blended cost sold / €',
        transactions: 'Transactions',
        fill_up: 'Fill up / l',
        pole_price: 'Pole price / cpl',
      },
    },
  },
}

export { translations, smallProfitIcon }
