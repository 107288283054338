import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { getDaysFromDateRange } from 'utils/dateTime'
import moment from 'moment'
import classNames from 'classnames/bind'
import { MetricHeader } from './components/metric-header'
import { DateHeader } from './components/date-header'
import { DateCell } from './components/date-cell'
import { MetricCell } from './components/metric-cell'
import { DateFooter } from './components/date-footer'
import { MetricFooter } from './components/metric-footer'
import { v4 as uuidv4 } from 'uuid'
import styles from './DataTable.module.scss'

const cx = classNames.bind(styles)

export const calculatePeriod = (t, period1, period2) => {
  const days1 = getDaysFromDateRange(period1)
  const days2 = getDaysFromDateRange(period2)

  const isYesterday = days1.to === -1
  if (isYesterday) {
    const fromDays1 = -days1.from
    const fromDays2 = -days2.from
    const toDays2 = -days2.to

    if (period2) {
      if ((fromDays2 - toDays2) >= 28 && (fromDays2 - toDays2) <= 31) {
        return 'month'
      } else if ((fromDays2 - toDays2) >= 90 && (fromDays2 - toDays2) <= 92) {
        return 'quarter'
      } else if ((fromDays2 - toDays2) >= 364 && (fromDays2 - toDays2) <= 366) {
        return 'year'
      }
    }

    if (fromDays1 === 1) {
      return `${t('widgets.performanceReport.day')}`
    } else if (fromDays1 <= 7) {
      return `${fromDays1} ${t('widgets.performanceReport.days')}`
    } else if (fromDays1 % 7 === 0) {
      return `${fromDays1 / 7} ${t('widgets.performanceReport.weeks')}`
    } else {
      return `${fromDays1} ${t('widgets.performanceReport.days')}`
    }
  } else {
    return null
  }
}

const DataTable = ({
  data,
  metrics,
  metricType,
  highlight,
  previousColumn,
  period1,
  period2,
  analytics
}) => {
  const { t } = useTranslation(['translations'])

  const isHighlight = item => {
    if (!highlight) {
      return false
    }
    return moment.utc(highlight.date).isSame(moment.utc(item.date), 'day')
  }

  const isWeekend = date => {
    const weekday = moment.utc(date).weekday()
    if (weekday === 0) {
      return true
    } else if (weekday === 6) {
      return true
    }
    return false
  }

  const periodString = calculatePeriod(t, period1, period2)
  const colour = ['green', 'purple', 'blue']

  return (
    <table className={cx('table')} data-testid="data-table">
      <thead>
        <tr>
          <th className={cx('padding-cell')} />
          <DateHeader />
          {metrics.map((metric, index) => (
            <MetricHeader
              key={metric}
              metric={metric}
              periodString={periodString}
              previousColumn={previousColumn}
              analytics={analytics}
              headerColour={colour[index]}
            />
          ))}
          <th className={cx('padding-cell')} />
        </tr>
      </thead>
      <tbody>
        {data[0]?.map((item, i) => (
          <tr
            key={`${item}_${i}`}
            className={cx({
              highlight: isHighlight(item),
              weekend: isWeekend(item.date),
            })}
          >
            <td className={cx('padding-cell')} />
            <DateCell item={item} previousColumn={previousColumn} />
            {data.map(forDate => {
              const metricData = forDate[i]
              return (
                <MetricCell
                  key={uuidv4()}
                  metricData={metricData}
                  metricType={metricType}
                  previousColumn={previousColumn}
                  value={metricData.value}
                  analytics={analytics}
                />
              )
            })}
            <td className={cx('padding-cell')} />
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th className={cx('padding-cell')} />
          <DateFooter metricType={metricType} />
          {metrics.map((metric, i) => {
            const metricData = data[i] ?? []
            return (
              <MetricFooter
                key={metric}
                metricType={metricType}
                previousColumn={previousColumn}
                metricData={metricData}
              />
            )
          })}
          <th className={cx('padding-cell')} />
        </tr>
      </tfoot>
    </table>
  )
}

DataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        value: PropTypes.number,
        previousValue: PropTypes.number,
      })
    )
  ),
  metrics: PropTypes.arrayOf(PropTypes.string),
  metricType: PropTypes.string,
  highlight: PropTypes.shape({
    date: PropTypes.instanceOf(Date).isRequired,
  }),
  previousColumn: PropTypes.bool,
  period1: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
  period2: PropTypes.shape({
    from: PropTypes.instanceOf(moment),
    to: PropTypes.instanceOf(moment),
  }),
  analytics: PropTypes.bool
}

DataTable.defaultProps = {
  data: null,
  label: null,
  metric: null,
  highlight: null,
  previousLine: false,
  period1: null,
  period2: null,
  analytics: false,
}

export { DataTable }
