import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import Spinner from 'react-bootstrap/Spinner'
import { isNullOrUndefined } from 'utils/helpers'
import { PricingTypes } from 'utils/constants'
import { Dropdown } from 'components/common/dropdown'
import { Input } from 'components/common/input'
import { useCompanyTimeZone } from 'components/common/hooks/useCompanyTimeZone'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { useCreateCompetitorPrices } from 'components/station/components/competitor-pricing/hooks/useCreateCompetitorPrices'
import {
  determineTimeIntervals,
  parsePolePriceTimestamps,
} from 'components/station/components/competitor-pricing/utils/helpers'
import { timestampVar } from 'config/apollo/competitor-pricing/variables'
import { ReactComponent as EditCancelIcon } from 'images/common/cancel.svg'
import classNames from 'classnames/bind'
import styles from './EditPriceForm.module.scss'

const cx = classNames.bind(styles)

const EditPriceForm = ({
  stationId,
  competitorId,
  polePrice,
  fuelGradeId,
  selectedPricingType,
  noCashCreditStation,
  onClosePopover,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { companyTimeZone } = useCompanyTimeZone()
  const { formatPolePrice, validatePolePrice } = useFormatPolePrice()
  const [time, setTime] = useState()
  const [newPrice, setNewPrice] = useState()
  const [createPrices, { loading }] = useCreateCompetitorPrices(
    stationId,
    competitorId,
    onClosePopover
  )
  const timestamp = useReactiveVar(timestampVar)

  const validPrice = () => {
    if (isNullOrUndefined(time)) {
      return false
    }
    return validatePolePrice(newPrice)
  }

  const handleSaveClick = () => {
    if (loading || !validPrice()) {
      return
    }

    const pricingType = noCashCreditStation
      ? PricingTypes.ALL
      : selectedPricingType

    const [priceAt, priceUpdatedAt] = parsePolePriceTimestamps(
      companyTimeZone,
      time,
      timestamp
    )

    const polePrice = {
      pricingType,
      fuelGradeId,
      price: parseFloat(newPrice),
      priceAt,
      priceUpdatedAt,
      stationId: competitorId,
    }

    createPrices([polePrice])
  }

  const items = determineTimeIntervals(timestamp, companyTimeZone, t, language)

  return (
    <div className={cx('price-form')}>
      <EditCancelIcon
        className={cx('cancel-icon')}
        onClick={onClosePopover}
        data-testid="cancel-icon"
      />
      <Dropdown
        selectedItem={time}
        items={items}
        onValueSelect={setTime}
        customClass={cx('time-dropdown')}
        placeholder={t('widgets.competitorPricing.from')}
        maxHeight="310px"
      />
      <Input
        value={newPrice}
        onValueChange={setNewPrice}
        customClass={cx('price-input')}
        placeholder={formatPolePrice(polePrice?.price)}
      />
      <button
        className={cx('btn', { disabled: loading || !validPrice() })}
        onClick={handleSaveClick}
      >
        {loading ? (
          <Spinner animation="border" variant="light" />
        ) : (
          t('widgets.competitorPricing.save')
        )}
      </button>
    </div>
  )
}

EditPriceForm.propTypes = {
  polePrice: PropTypes.shape(),
  stationId: PropTypes.string.isRequired,
  competitorId: PropTypes.string.isRequired,
  fuelGradeId: PropTypes.string.isRequired,
  selectedPricingType: PropTypes.string.isRequired,
  noCashCreditStation: PropTypes.bool.isRequired,
  onClosePopover: PropTypes.func.isRequired,
}

EditPriceForm.defaultProps = {
  polePrice: null,
}

export { EditPriceForm }
