import moment from 'moment'

/**
 * Maps an "All" metric type to the corresponding metric in the performance reporting GraphQL response.
 *
 * If not an "All" type, returns the metric as is.
 */
const getMetric = metric => {
  switch (metric) {
    case 'netMarginAll':
      return 'netMargin'
    case 'netProfitAll':
      return 'netProfit'
    case 'businessProfitAll':
      return 'businessProfit'
    default:
      return metric
  }
}

/**
 * Formats a performance report data date into a readable string.
 */
const formatDate = date => {
  return moment.utc(date).format('dddd Do MMMM YYYY')
}

/**
 * Formats a performance report data date into recognisable date field in excel/spreadsheet client
 * ISO8601 date format
 */
const formatDateToISO = date => {
  return moment.utc(date).toISOString()
}

export { getMetric, formatDate, formatDateToISO }
