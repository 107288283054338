import React from 'react'
import { useQuery } from '@apollo/client'
import { useDebounce } from 'use-debounce'
import { useReactiveVar } from '@apollo/client'
import { useErrorHandler } from 'react-error-boundary'
import Spinner from 'react-bootstrap/Spinner'
import {
  fuelGradePriceChangesVar,
  selectedStationIdsVar,
  stationPriceChangesVar,
} from 'config/apollo/stations-list-pricing/variables'
import { useFormatMetricValue } from 'components/common/hooks/useFormatMetricValue'
import { withEdgeErrorBoundary } from 'components/common/with-edge-error-boundary'
import { EstimatedMarginDebounce, MetricTypes } from 'utils/constants'
import classNames from 'classnames/bind'
import { ESTIMATED_MARGIN_QUERY } from '../../../EditPricingQueries'
import { MarginFallback } from '../margin-fallback'
import styles from './EstimatedMargin.module.scss'
import PropTypes from 'prop-types'

const cx = classNames.bind(styles)

const EstimatedMargin = ({ firstRenderEstimatedMargin }) => {
  const { formatMetricValue } = useFormatMetricValue()
  const fuelGradePriceChangesVariables = useReactiveVar(
    fuelGradePriceChangesVar
  )
  const stationPriceChangesVariables = useReactiveVar(stationPriceChangesVar)
  const stationIds = useReactiveVar(selectedStationIdsVar)

  const _stationPriceChanges = Object.values(stationPriceChangesVariables)
    .flat()
    .filter(Boolean)
    .filter(({ priceChange }) => priceChange !== 0)

  const [fuelGradePriceChanges] = useDebounce(
    fuelGradePriceChangesVariables,
    EstimatedMarginDebounce
  )
  const [stationPriceChanges] = useDebounce(
    _stationPriceChanges,
    EstimatedMarginDebounce
  )
  const { loading, data, error } = useQuery(ESTIMATED_MARGIN_QUERY, {
    variables: {
      stationIds,
      fuelGradePriceChanges,
      stationPriceChanges,
    },
    skip: !stationIds.length,
  })
  useErrorHandler(error)

  /* istanbul ignore next */
  if (error) {
    throw new Error(error)
  }

  if (loading) {
    return (
      <div className={cx('spinner')}>
        <Spinner animation="grow" variant="primary" />
      </div>
    )
  }

  const estimatedMargin = data?.estimatedMargin?.estimatedMargin
    ? data.estimatedMargin.estimatedMargin
    : firstRenderEstimatedMargin

  return (
    <div data-testid="estimated-margin">
      {formatMetricValue(MetricTypes.MARGIN, estimatedMargin, false)}
    </div>
  )
}

EstimatedMargin.propTypes = {
  firstRenderEstimatedMargin: PropTypes.number.isRequired,
}

const FallbackComponent = () => (
  <MarginFallback tooltipKey="widgets.stationsList.tomorrowMiddayEstimateErrorTooltip" />
)

const EstimatedMarginWithErrorBoundary = withEdgeErrorBoundary(
  EstimatedMargin,
  FallbackComponent
)

export { EstimatedMarginWithErrorBoundary as EstimatedMargin }
