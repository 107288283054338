import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'
import { FuelGradeDifferential } from './components/fuel-grade-differential'
import styles from '../../PolePriceRow.module.scss'

const cx = classNames.bind(styles)

const FuelGradeDifferentials = ({
  stationName,
  priceChangeEnabled,
  fuelGrades,
  fuelGradeDifferentials,
}) => {
  const { t } = useTranslation()

  if (!priceChangeEnabled) {
    return null
  }

  const content =
    fuelGradeDifferentials.length > 0 ? (
      <>
        <div>
          {stationName} {t('widgets.stationsListPricing.gradeDifferentials')}
        </div>
        {fuelGradeDifferentials.map(fuelGradeDifferential => {
          return (
            <FuelGradeDifferential
              key={fuelGradeDifferential.id}
              fuelGradeDifferential={fuelGradeDifferential}
              fuelGrades={fuelGrades}
            />
          )
        })}
      </>
    ) : (
      <div>
        {stationName} {t('widgets.stationsListPricing.noGradeDifferentials')}
      </div>
    )

  return <div className={cx('fuel-grade-differentials')}>{content}</div>
}

FuelGradeDifferentials.propTypes = {
  stationName: PropTypes.string.isRequired,
  priceChangeEnabled: PropTypes.bool.isRequired,
  fuelGrades: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fuelGradeDifferentials: PropTypes.arrayOf(PropTypes.shape()),
}

export { FuelGradeDifferentials }
