import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import amplitude from 'amplitude-js'

const parseUserProperties = me => {
  const {
    createdAt,
    email,
    firstName,
    lastName,
    company: { id: companyId, name },
  } = me

  return {
    'Company ID': companyId,
    'Company name': name,
    'Created at': createdAt,
    Email: email,
    'First name': firstName,
    'Last name': lastName,
  }
}

const logScreenViewEvent = (client, pathname) => {
  client.logEvent('Screen viewed', {
    'Screen path': pathname,
  })
}

function useAmplitude(me) {
  const isAmplitudeDisabled =
    process.env.REACT_APP_AMPLITUDE_ENABLED === 'false' ? true : false

  const { listen } = useHistory()

  useEffect(() => {
    if (!me || me.isEdgeSuperuser || isAmplitudeDisabled) {
      return
    }

    const userProperties = parseUserProperties(me)
    const callback = client => {
      client.setUserProperties(userProperties)
      logScreenViewEvent(client, window.location.pathname)
    }

    amplitude
      .getInstance()
      .init(process.env.REACT_APP_AMPLITUDE_API_KEY, me.id, {}, callback)

    /* instanbul ignore next */
    return listen(location => {
      logScreenViewEvent(amplitude.getInstance(), location.pathname)
    })
  }, [me, listen, isAmplitudeDisabled])
}

export { useAmplitude }
