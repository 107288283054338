import React from 'react'
import { PropTypes } from 'prop-types'
import { useFormatTimestamp } from 'components/common/hooks/useFormatTimestamp'
import { TimestampTypes } from 'utils/constants'
import classNames from 'classnames/bind'
import { MonthIcon } from './components/month-icon'
import styles from './LastDelivery.module.scss'
import { useTranslation } from 'react-i18next'

const cx = classNames.bind(styles)

const { TIME, DAY, MONTH, DAY_MONTH_YEAR } = TimestampTypes

const LastDelivery = ({ date }) => {
  const { formatTimestamp } = useFormatTimestamp()
  const { t } = useTranslation()

  return (
    <div className={cx('last-delivery-container')}>
      <div className={cx('last-delivery')}>
        <MonthIcon
          month={formatTimestamp(MONTH, date)}
          day={formatTimestamp(DAY, date)}
        />
        <div className={cx('last-delivery-text')}>
          <div className={cx('title')}>
            {t('widgets.delivery.theLastDetectedDeliveryWasOn')}
          </div>
          <div className={cx('value')}>
            {formatTimestamp(DAY_MONTH_YEAR, date)} @{' '}
            {formatTimestamp(TIME, date)}
          </div>
        </div>
      </div>
    </div>
  )
}

LastDelivery.propTypes = {
  date: PropTypes.string,
}
LastDelivery.defaultProps = {
  date: null,
}

export { LastDelivery }
