import i18n from 'i18next'
import { Locales } from 'utils/constants'
import { isNullOrUndefined } from 'utils/helpers'

function useFormatPriceDifferential() {
  function formatPriceDifferential(value, fallback = null) {
    if (isNullOrUndefined(value) || value === 0) {
      return fallback
    }

    if (i18n.language === Locales.EN_US) {
      // US differentials are returned in cents but displayed in dollars
      const formattedValue = (parseFloat(value) / 100).toFixed(2)
      return formattedValue !== '0.00' ? formattedValue : fallback
    }

    return parseFloat(value).toFixed(1)
  }

  return { formatPriceDifferential }
}

export { useFormatPriceDifferential }
