import { PriceChangeStatus, PriceChangeActions } from './constants'
import { ActionAtPeriods } from 'utils/pricing/constants'
import {
  initialisePendingRequests,
  incrementOrDecrementPrice,
  setPrice,
  recalculateFromLeadGrade,
} from './pendingRequestsService'

const handleSetCurrentStatus = (state, action, station) => {
  const { status } = action.payload
  switch (status) {
    case PriceChangeStatus.Edit:
      // if moving into the editing state for the first time then initialise the state
      if (state.status === PriceChangeStatus.None) {
        const pendingRequests = initialisePendingRequests(station)
        return {
          ...state,
          status,
          pendingRequests,
        }
      }
      return { ...state, status }
    case PriceChangeStatus.None:
      return defaultState
    default:
      return { ...state, status }
  }
}

const handleSetActionAt = (state, action) => {
  return {
    ...state,
    actionAt: action.payload,
  }
}

const handleIncrementPrice = (state, action, station) => {
  const { payload } = action
  return incrementOrDecrementPrice(state, payload, true, station)
}

const handleDecrementPrice = (state, action, station) => {
  const { payload } = action
  return incrementOrDecrementPrice(state, payload, false, station)
}

const handleSetPrice = (state, action, station) => {
  const { payload } = action
  return setPrice(state, payload, station)
}

const handleToggleReminder = (state, action) => {
  const {
    payload: { reminder },
  } = action
  return {
    ...state,
    reminders: {
      ...state.reminders,
      [reminder]: !state.reminders[reminder],
    },
  }
}

const handleChangedFuelGradeDifferential = (state, action, station) => {
  const { payload } = action
  return recalculateFromLeadGrade(state, payload, station)
}

const defaultState = {
  status: PriceChangeStatus.None,
  pendingRequests: [],
  reminders: {
    threeHoursAfter: true,
    oneHourAfter: true,
  },
  actionAt: {
    selectedPeriod: ActionAtPeriods.Immediately,
    selectedDate: null,
    selectedTime: null,
  },
}

/**
 * Handles state related to sending price change requests on the competitor pricing widget.
 */
const priceChangeReducer = station => (state, action) => {
  switch (action.type) {
    case PriceChangeActions.SetCurrentStatus: {
      return handleSetCurrentStatus(state, action, station)
    }
    case PriceChangeActions.SetActionAt: {
      return handleSetActionAt(state, action)
    }
    case PriceChangeActions.IncrementPrice: {
      return handleIncrementPrice(state, action, station)
    }
    case PriceChangeActions.DecrementPrice: {
      return handleDecrementPrice(state, action, station)
    }
    case PriceChangeActions.SetPrice: {
      return handleSetPrice(state, action, station)
    }
    case PriceChangeActions.ToggleReminder: {
      return handleToggleReminder(state, action)
    }
    case PriceChangeActions.ChangedFuelGradeDifferential: {
      return handleChangedFuelGradeDifferential(state, action, station)
    }
    default: {
      throw new Error(`unhandled action type: ${action.type}`)
    }
  }
}

export { priceChangeReducer, defaultState }
