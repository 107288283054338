import { useQuery, gql } from '@apollo/client'
import { isNullOrUndefined } from 'utils/helpers'

const PRICING_TYPES_QUERY = gql`
  query pricingTypesQuery {
    pricingTypes @client {
      types
      selected
    }
  }
`

function usePricingTypes() {
  const { data } = useQuery(PRICING_TYPES_QUERY)

  if (isNullOrUndefined(data)) {
    return {
      selectedPricingType: null,
      supportedPricingTypes: null,
      cashCreditPricing: null,
    }
  }

  const {
    pricingTypes: {
      types: supportedPricingTypes,
      selected: selectedPricingType,
    },
  } = data

  // boolean that holds if the current user has the ability to select different pricing types
  const cashCreditPricing = supportedPricingTypes.length > 1

  return { selectedPricingType, supportedPricingTypes, cashCreditPricing }
}

export { usePricingTypes }
