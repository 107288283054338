import { numberToFixed } from 'utils/number'
import {
  fuelGradePriceChangesVar,
  selectedStationIdsVar,
  stationPriceChangesVar,
} from '../variables'
import { deselectStations, selectStations } from './selectedStations'

const OperationType = {
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
}

const { INCREMENT, DECREMENT } = OperationType

const stationPriceChange = (stationId, fuelGradeId, priceChange) => ({
  stationId,
  fuelGradeId,
  priceChange,
})

/**
 * @returns if the given fuel grade has a differential and is not the lead grade
 */
const isNotLeadGrade = (fuelGradeDifferentials, fuelGradeId) => {
  const fuelGradeDifferential = fuelGradeDifferentials.find(
    fuelGradeDifferential => fuelGradeDifferential.fuelGradeId === fuelGradeId
  )
  return fuelGradeDifferential && !fuelGradeDifferential.leadGrade
}

/**
 * Based off the state of the station's station price changes selects or deselects the station.
 */
const selectOrDeselectStation = (
  stationId,
  isSelected,
  newValues,
  newStationPriceChanges
) => {
  const hasPriceChanges = newValues.some(value => value.priceChange !== 0)
  if (hasPriceChanges) {
    newStationPriceChanges[stationId] = newValues
    stationPriceChangesVar(newStationPriceChanges)

    if (!isSelected) {
      selectStations([stationId])
    }
  } else {
    deselectStations([stationId])
  }
}

/**
 * Given an operation (increment or decrement) updates the station price changes for the given station.
 *
 * The flow is different based off if the station already has station price changes and if the station is selected with existing fuel grade price changes.
 */
const updateStationPriceChanges = (
  updateType,
  stationId,
  fuelGradeId,
  polePrices,
  fuelGradeDifferentials
) => {
  const variation = updateType === INCREMENT ? 1 : -1

  const currentStationPriceChanges = stationPriceChangesVar()
  const fuelGradePriceChanges = fuelGradePriceChangesVar()

  const newStationPriceChanges = { ...currentStationPriceChanges }
  const hasExistingStationPriceChanges = !!newStationPriceChanges[stationId]

  if (hasExistingStationPriceChanges) {
    const newValues = newStationPriceChanges[stationId].map(value => {
      if (value.fuelGradeId === fuelGradeId) {
        return stationPriceChange(
          stationId,
          fuelGradeId,
          numberToFixed(value.priceChange + variation)
        )
      }
      return value
    })

    selectOrDeselectStation(stationId, true, newValues, newStationPriceChanges)
  } else {
    let newValues
    const isSelected = selectedStationIdsVar().includes(stationId)
    if (!isSelected) {
      // if the station is not selected, start the increment/decrement from 0
      newValues = polePrices
        .map(polePrice => {
          if (isNotLeadGrade(fuelGradeDifferentials, polePrice.fuelGradeId)) {
            return null
          }

          const isCurrentFuelGrade = polePrice.fuelGradeId === fuelGradeId
          return stationPriceChange(
            stationId,
            polePrice.fuelGradeId,
            isCurrentFuelGrade ? variation : 0
          )
        })
        .filter(Boolean)
    } else {
      // if the station is already selected, check to see if we should start the increment/decrement from the current fuel grade price change
      newValues = polePrices
        .map(polePrice => {
          if (isNotLeadGrade(fuelGradeDifferentials, polePrice.fuelGradeId)) {
            return null
          }

          const isCurrentFuelGrade = polePrice.fuelGradeId === fuelGradeId
          const fuelGradePriceChange = fuelGradePriceChanges.find(
            fuelGradePriceChange =>
              fuelGradePriceChange.fuelGradeId === polePrice.fuelGradeId &&
              fuelGradePriceChange.priceChange !== 0
          )

          if (!fuelGradePriceChange) {
            return stationPriceChange(
              stationId,
              polePrice.fuelGradeId,
              isCurrentFuelGrade ? variation : 0
            )
          }

          return stationPriceChange(
            stationId,
            polePrice.fuelGradeId,
            isCurrentFuelGrade
              ? numberToFixed(fuelGradePriceChange.priceChange + variation)
              : fuelGradePriceChange.priceChange
          )
        })
        .filter(Boolean)
    }

    selectOrDeselectStation(
      stationId,
      isSelected,
      newValues,
      newStationPriceChanges
    )
  }
}

const incrementStationPriceChange = (
  stationId,
  fuelGradeId,
  polePrices,
  fuelGradeDifferentials
) => {
  updateStationPriceChanges(
    INCREMENT,
    stationId,
    fuelGradeId,
    polePrices,
    fuelGradeDifferentials
  )
}

const decrementStationPriceChange = (
  stationId,
  fuelGradeId,
  polePrices,
  fuelGradeDifferentials
) => {
  updateStationPriceChanges(
    DECREMENT,
    stationId,
    fuelGradeId,
    polePrices,
    fuelGradeDifferentials
  )
}

export { incrementStationPriceChange, decrementStationPriceChange }
