import { makeVar } from '@apollo/client'
import {
  CompetitorSorts,
  SortDirections,
} from 'components/station/components/competitor-pricing/utils/constants'

/**
 * Holds the current timestamp for the competitor pricing widget.
 *
 * Defaults to null which represents today at the current time.
 */
const timestampVar = makeVar(null)

/**
 * Controls the sort on the competitor pricing widget.
 *
 * Defaults to driving distance ascending.
 */
const defaultCompetitorSort = {
  type: CompetitorSorts.DRIVING_DISTANCE_SORT,
  direction: SortDirections.ASC,
}
const competitorSortVar = makeVar(defaultCompetitorSort)

const resetVariables = () => {
  timestampVar(null)
  competitorSortVar(defaultCompetitorSort)
}

export { timestampVar, competitorSortVar, resetVariables }
