import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { CurrentStrategy } from './components/current-strategy'
import { EditStrategy } from './components/edit-strategy'
import styles from './StationStrategy.module.scss'

const cx = classNames.bind(styles)

const StationStrategy = ({ stationId, stationStrategy }) => {
  const [edit, setEdit] = useState(false)

  const handleClose = () => {
    setEdit(false)
  }

  const component = edit ? (
    <EditStrategy
      stationId={stationId}
      stationStrategy={stationStrategy}
      onClose={handleClose}
    />
  ) : (
    <CurrentStrategy stationStrategy={stationStrategy} onClick={setEdit} />
  )
  return <div className={cx('station-strategy')}>{component}</div>
}

StationStrategy.propTypes = {
  stationId: PropTypes.string.isRequired,
  stationStrategy: PropTypes.shape(),
}

StationStrategy.defaultProps = {
  stationStrategy: null,
}

export { StationStrategy }
