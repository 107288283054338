import React from 'react'
import PropTypes from 'prop-types'
import { useFormatPolePrice } from 'components/common/hooks/useFormatPolePrice'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import classNames from 'classnames/bind'
import { PricesPopover } from '../../../popovers'
import styles from './PricesCell.module.scss'
import { PreviousPrice } from '../../../popovers/components/previous-price'

const cx = classNames.bind(styles)

const PricesCell = ({
  status,
  stationId,
  fuelGrade,
  competitor,
  cashPrice,
  creditPrice,
  editPriceMode,
  setEditPriceMode,
}) => {
  const { formatPolePrice } = useFormatPolePrice()

  const showPopover = status === PriceChangeStatus.None
  const { influencer } = competitor
  const pricesCell = (
    <div
      className={cx('prices-cell', { influencer, popover: showPopover })}
      data-testid="prices-cell"
    >
      <span className={cx('cash-price')}>
        <div>
          <div className={cx('price')} data-testid="cash-price">
            {formatPolePrice(cashPrice?.price)}
          </div>
          <PreviousPrice polePrice={cashPrice} />
        </div>
      </span>
      <span className={cx('divider')} />
      <span className={cx('credit-price')}>
        <div>
          <div className={cx('price')} data-testid="credit-price">
            {formatPolePrice(creditPrice?.price)}
          </div>
          <PreviousPrice polePrice={creditPrice} />
        </div>
      </span>
    </div>
  )

  if (!showPopover) {
    return pricesCell
  }

  return (
    <PricesPopover
      stationId={stationId}
      fuelGrade={fuelGrade}
      competitor={competitor}
      cashPrice={cashPrice}
      creditPrice={creditPrice}
      editPriceMode={editPriceMode}
      setEditPriceMode={setEditPriceMode}
    >
      {pricesCell}
    </PricesPopover>
  )
}

PricesCell.propTypes = {
  status: PropTypes.string.isRequired,
  fuelGrade: PropTypes.shape().isRequired,
  competitor: PropTypes.shape().isRequired,
  cashPrice: PropTypes.shape(),
  creditPrice: PropTypes.shape(),
  editPriceMode: PropTypes.bool.isRequired,
  setEditPriceMode: PropTypes.func.isRequired,
}

PricesCell.defaultProps = {
  cashPrice: null,
  creditPrice: null,
}

export { PricesCell }
