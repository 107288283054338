import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { upperFirstOnly } from 'utils/format'
import { Widgets } from 'utils/constants'
import { usePriceChangeState } from 'components/station/components/competitor-pricing/context/priceChangeContext'
import { PriceChangeStatus } from 'components/station/components/competitor-pricing/context/constants'
import { PriceChangeFooter } from 'components/station/components/competitor-pricing/components/price-change-footer'
import { showConfirm } from 'components/station/components/competitor-pricing/utils/helpers'
import { useSlider } from 'components/common/slider/hooks/useSlider'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import classNames from 'classnames/bind'
import { SliderArrows } from '../slider-arrows'
import { ToggleViewButton } from '../toggle-view-button'
import { FuelGradeSelector } from '../competitor-map/components/fuel-grade-selector'
import { CompetitorMap } from '../competitor-map'
import { StationStrategy } from '../station-strategy'
import { DateTimeSelector } from '../date-time-selector'
import { EditPricingButton } from '../edit-pricing-button'
import { EditChangesButton } from '../edit-changes-button'
import { PricesTable } from '../prices-table'
import { NoCompetitors } from '../no-competitors'
import styles from './CompetitorPricingContainer.module.scss'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

const { COMPETITOR_PRICING } = Widgets

const cx = classNames.bind(styles)

const renderTitle = (
  confirm,
  cashCreditPricing,
  selectedPricingType,
  sliderState,
  competitorPricingView,
  setCompetitorPricingView,
  dispatch,
  t,
  showCompetitorPriceMap
) => {
  const content = confirm ? (
    <h2>{t('widgets.competitorPricing.confirmPriceChanges')}</h2>
  ) : (
    <h2>
      {t('widgets.competitorPricing.title')}&nbsp;
      {cashCreditPricing && (
        <span>
          (
          {upperFirstOnly(
            t(`widgets.competitorPricing.${selectedPricingType}`)
          )}
          )
        </span>
      )}
    </h2>
  )

  return (
    <div className={cx('title')}>
      {content}
      <SliderArrows sliderState={sliderState} dispatch={dispatch} />
      {showCompetitorPriceMap && (
        <ToggleViewButton
          competitorPricingView={competitorPricingView}
          onToggle={val => {
            setCompetitorPricingView(val)
            localStorage.setItem('competitorPricingView', val)
          }}
        />
      )}
    </div>
  )
}

const renderOptions = (confirm, station, isOverlayMode) => {
  const { id, stationStrategy } = station

  const content = confirm ? (
    <>
      <EditChangesButton station={station} />
      <EditPricingButton station={station} />
    </>
  ) : (
    <>
      <StationStrategy stationId={id} stationStrategy={stationStrategy} />
      <DateTimeSelector station={station} isOverlayMode={isOverlayMode} />
      <EditPricingButton station={station} />
    </>
  )

  return <div className={cx('options', { confirm: confirm })}>{content}</div>
}

const renderMapOptions = (
  confirm,
  station,
  isOverlayMode,
  selectedFuelGrade,
  setSelectedFuelGrade
) => {
  const { id, stationStrategy, fuelGrades } = station
  const validFuelGrades = fuelGrades.map(({ fuelGrade }) => ({
    item: fuelGrade.id,
    displayItem: fuelGrade.title,
  }))

  const content = confirm ? (
    <EditChangesButton station={station} />
  ) : (
    <>
      <StationStrategy stationId={id} stationStrategy={stationStrategy} />
      <DateTimeSelector station={station} isOverlayMode={isOverlayMode} />
      <FuelGradeSelector
        fuelGrades={validFuelGrades}
        onSelected={setSelectedFuelGrade}
        selectedFuelGrade={selectedFuelGrade}
      />
    </>
  )

  return <div className={cx('options', { confirm: confirm })}>{content}</div>
}

const CompetitorPricingContainer = ({ station, isOverlayMode }) => {
  const { selectedPricingType, cashCreditPricing } = usePricingTypes()
  const [sliderState, dispatch] = useSlider(COMPETITOR_PRICING)
  const { status } = usePriceChangeState()
  const { t } = useTranslation()
  const competitorPricingViewFromLocalStorage = localStorage.getItem(
    'competitorPricingView'
  )
  const [competitorPricingView, setCompetitorPricingView] = useState(
    competitorPricingViewFromLocalStorage || 'list'
  )
  const { showCompetitorPriceMap } = useFlags()
  const [selectedFuelGrade, setSelectedFuelGrade] = useState()

  const {
    id,
    name,
    sortedCompetitors,
    setDateTimeSelectorInUse,
    fuelGrades,
  } = station

  useEffect(() => {
    if (fuelGrades.length > 0) {
      setSelectedFuelGrade(fuelGrades[0].fuelGrade.id);
    }
  }, [fuelGrades])

  const confirm = showConfirm(status)

  return (
    <div className={cx('competitor-pricing-container')}>
      {renderTitle(
        confirm,
        cashCreditPricing,
        selectedPricingType,
        sliderState,
        competitorPricingView,
        setCompetitorPricingView,
        dispatch,
        t,
        showCompetitorPriceMap
      )}
      {!showCompetitorPriceMap || competitorPricingView === 'list' ? (
        <>
          {renderOptions(confirm, station, isOverlayMode)}
          <PricesTable
            status={status}
            station={station}
            sliderState={sliderState}
            dispatch={dispatch}
          />
          <NoCompetitors competitors={sortedCompetitors} />
          {status !== PriceChangeStatus.None && (
            <PriceChangeFooter
              stationId={id}
              stationName={name}
              setDateTimeSelectorInUse={setDateTimeSelectorInUse}
            />
          )}
        </>
      ) : (
        <>
          {renderMapOptions(
            confirm,
            station,
            isOverlayMode,
            selectedFuelGrade,
            setSelectedFuelGrade
          )}
          <CompetitorMap
            station={station}
            selectedFuelGrade={selectedFuelGrade}
          />
        </>
      )}
    </div>
  )
}

CompetitorPricingContainer.propTypes = {
  station: PropTypes.shape().isRequired,
  isOverlayMode: PropTypes.bool.isRequired,
}

export { CompetitorPricingContainer }
