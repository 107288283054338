import React, { createContext, useReducer, useContext } from 'react'
import { defaultState, priceChangeReducer } from './priceChangeReducer'
import { PropTypes } from 'prop-types'

const PriceChangeStateContext = createContext()
const PriceChangeDispatchContext = createContext()

function usePriceChangeState() {
  const context = useContext(PriceChangeStateContext)
  if (context === undefined) {
    throw new Error(
      'usePriceChangeState must be used within a PriceChangeProvider'
    )
  }
  return context
}

function usePriceChangeDispatch() {
  const context = useContext(PriceChangeDispatchContext)
  if (context === undefined) {
    throw new Error(
      'usePriceChangeDispatch must be used within a PriceChangeProvider'
    )
  }
  return context
}

function usePriceChange() {
  return [usePriceChangeState(), usePriceChangeDispatch()]
}

const PriceChangeProvider = ({ children, station }) => {
  const [state, dispatch] = useReducer(
    priceChangeReducer(station),
    defaultState
  )

  return (
    <PriceChangeStateContext.Provider value={state}>
      <PriceChangeDispatchContext.Provider value={dispatch}>
        {children}
      </PriceChangeDispatchContext.Provider>
    </PriceChangeStateContext.Provider>
  )
}

PriceChangeProvider.propTypes = {
  children: PropTypes.object.isRequired,
  station: PropTypes.object.isRequired,
}

export {
  PriceChangeProvider,
  usePriceChange,
  usePriceChangeState,
  usePriceChangeDispatch,
}
