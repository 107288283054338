import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useClearNotifications } from './hooks/useClearNotifications'
import { cache } from 'config/apollo/cache'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'
import { WidgetFallback } from 'components/common/widget-fallback'
import { ReactComponent as LoadingIcon } from 'images/common/loading.svg'
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { Header } from './components/header'
import { Notification } from './components/notification'
import { NoNewNotifications } from './components/no-new-notifications'
import { amplitudeEvent, UserEvents } from 'config/amplitude'
import { NOTIFICATIONS_QUERY } from './NotificationsQueries'
import styles from './Notifications.module.scss'

const cx = classNames.bind(styles)

const PAGE = 1
const PAGE_SIZE = 100

const Notifications = ({ stations, visible, onClick, count, isSingleSite }) => {
  const { t } = useTranslation()
  const [isCalled, setIsCalled] = useState(false)
  const [
    query,
    { error, data, loading, refetch },
  ] = useLazyQuery(NOTIFICATIONS_QUERY, { notifyOnNetworkStatusChange: true })

  const {
    clearNotificationsMutation,
    clearNotificationsLoading,
  } = useClearNotifications()

  useErrorHandler(error)

  useEffect(() => {
    if (!isCalled && visible) {
      const variables = {
        variables: {
          page: PAGE,
          pageSize: PAGE_SIZE,
        },
      }
      refetch ? refetch(variables) : query(variables)
      setIsCalled(true)
    }
  }, [query, refetch, isCalled, visible])

  useEffect(() => {
    if (!visible && isCalled) {
      setIsCalled(false)
    }
  }, [visible, isCalled])

  const handleClearNotifications = async () => {
    amplitudeEvent(UserEvents.Notifications.CLEARED)

    const { data: clearNotificationData } = await clearNotificationsMutation()

    if (!clearNotificationData?.clearNotifications?.error) {
      for (const group of data?.notifications) {
        const id = cache.identify(group)
        cache.evict({ id })
      }
      cache.modify({
        fields: {
          notificationsCount(notificationsCount) {
            return { ...notificationsCount, count: 0 }
          },
        },
      })
      cache.gc()
    }
  }

  const isLoading = loading || !isCalled
  const hasNotifications = data?.notifications?.length

  const renderNotificationSpinner = (
    <div className={cx('notifications-spinner')}>
      <Spinner
        animation="grow"
        variant="primary"
        data-testid="notifications-spinner"
      />
    </div>
  )

  const renderClearNotificationsLoading = (
    <div
      className={cx('clear-notifications-loading')}
      data-testid="clear-notifications-loading"
    >
      <LoadingIcon height={100} width={100} />
    </div>
  )

  const renderNotifications = data?.notifications?.map(notification => (
    <Notification
      key={notification.id}
      notification={notification}
      stations={stations}
      onClick={onClick}
      isSingleSite={isSingleSite}
    />
  ))

  return (
    <div className={cx('notifications', { visible })}>
      <Header onClick={onClick} />

      <div className={cx('body')}>
        {isLoading && renderNotificationSpinner}
        {clearNotificationsLoading && renderClearNotificationsLoading}
        {!isLoading && !clearNotificationsLoading && renderNotifications}
        {!isLoading && !hasNotifications && <NoNewNotifications />}
      </div>

      <div className={cx('clear')}>
        {!isLoading && !!hasNotifications && !clearNotificationsLoading && (
          <span>
            {count} notifications in total.{' '}
            <button
              onClick={handleClearNotifications}
              data-testid="clear-notifications"
            >
              {t('widgets.notifications.clearAll')}
            </button>
          </span>
        )}
      </div>
    </div>
  )
}

Notifications.propTypes = {
  stations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isSingleSite: PropTypes.bool.isRequired,
}

const NotificationsWithErrorBoundary = props => {
  return (
    <ErrorBoundary
      fallbackRender={() => {
        const { visible, onClick } = props
        return (
          <div className={cx('notifications', { visible })}>
            <Header onClick={onClick} />
            <WidgetFallback customClass={cx('fallback')} />
          </div>
        )
      }}
    >
      <Notifications {...props} />
    </ErrorBoundary>
  )
}

export { NotificationsWithErrorBoundary as Notifications }
