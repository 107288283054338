import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { PerformanceProfitTypes } from 'utils/constants'
import { ReactComponent as Active } from 'images/common/select-active.svg'
import { ReactComponent as Inactive } from 'images/common/select-inactive.svg'
import styles from './ProfitSelector.module.scss'

const cx = classNames.bind(styles)
const { GROSS_PROFIT, NET_PROFIT, BUSINESS_PROFIT } = PerformanceProfitTypes

const parseSelectors = (t, hasBunkered) => {
  const selectors = [
    {
      selector: t('widgets.stationPerformance.grossProfit'),
      type: GROSS_PROFIT,
    },
    { selector: t('widgets.stationPerformance.netProfit'), type: NET_PROFIT },
  ]

  if (hasBunkered) {
    selectors.push({
      selector: t('widgets.stationPerformance.businessProfit'),
      type: BUSINESS_PROFIT,
    })
  }

  return selectors
}

const ProfitSelector = ({ hasBunkered, handleSelected, selectedProfit }) => {
  const { t } = useTranslation()

  const title = t(
    `widgets.stationPerformance.${
      hasBunkered ? 'grossNetOrBusiness' : 'grossOrNet'
    }`
  )
  const selectors = parseSelectors(t, hasBunkered)

  return (
    <div className={cx('profit-selector-container')}>
      <div className={cx('profit-selector-title')}>{title}</div>
      {selectors.map(({ selector, type }) => {
        const selected = selectedProfit === type
        return (
          <div className={cx('profit-selector')} key={type}>
            <div className={cx('icon')} onClick={() => handleSelected(type)}>
              {selected ? <Active /> : <Inactive />}
            </div>
            <div className={cx('profit-selector-text')}>{selector}</div>
          </div>
        )
      })}
    </div>
  )
}

ProfitSelector.propTypes = {
  hasBunkered: PropTypes.bool.isRequired,
  handleSelected: PropTypes.func.isRequired,
  selectedProfit: PropTypes.string.isRequired,
}

export { ProfitSelector }
