import { selectedStationIdsVar } from 'config/apollo/stations-list-pricing/variables'

const typePolicy = {
  fields: {
    isSelected(_, { readField }) {
      const pricingStationIds = readField('pricingStationIds')
      if (!pricingStationIds || pricingStationIds.length === 0) {
        return false
      }

      const selectedStationIds = selectedStationIdsVar()
      return pricingStationIds.every(stationId =>
        selectedStationIds.includes(stationId)
      )
    },
  },
}

export { typePolicy }
