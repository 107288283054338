import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'components/common/dropdown'
import { timeResolutionVar } from 'config/apollo/analytics-report/variables'

export const DAILY = 'Daily'
export const WEEKLY = 'Weekly'
export const MONTHLY = 'Monthly'
export const QUARTERLY = 'Quarterly'
export const YEARLY = 'Yearly'

const timeResolutionItems = [
  { item: 0, displayItem: DAILY },
  { item: 1, displayItem: WEEKLY },
  { item: 2, displayItem: MONTHLY },
  { item: 3, displayItem: QUARTERLY },
  { item: 4, displayItem: YEARLY }
]

const TimeResolutionDropdown = ({ activeTab }) => {
  const [selectedItem, setSelectedItem] = useState(timeResolutionVar)

  const handleItemClick = (event) => {
    const target = event.target.querySelector('span')
    const item = timeResolutionItems.find(item => item.displayItem === target?.textContent)

    if (item) {
      timeResolutionVar({
        ...timeResolutionVar(),
        [activeTab]: item.displayItem
      })

      setSelectedItem((prevState) => ({
        ...prevState,
        [activeTab]: item.item
      }))
    }
  }

  const item = selectedItem[activeTab] === 'Daily' ? 0 : selectedItem[activeTab]

  return (
    <Dropdown
      items={timeResolutionItems}
      selectedItem={item}
      onItemClick={handleItemClick}
    />    
  )
}

TimeResolutionDropdown.propTypes = {
  activeTab: PropTypes.string
}

export { TimeResolutionDropdown }
