import React from 'react'
import classNames from 'classnames/bind'
import { PropTypes } from 'prop-types'
import styles from './PaymentFallback.module.scss'

const cx = classNames.bind(styles)

const PaymentFallback = ({ paymentKey }) => {
  return (
    <div>
      <svg className={cx('payment-fallback-svg')}>
        <rect className={cx('payment-fallback-rect')} rx="7" x="1" y="1"></rect>
        <text
          className={cx('payment-fallback-text')}
          x="50%"
          y="50%"
          style={{ fontSize: `${paymentKey.length > 5 && '1rem'}` }}
        >
          {paymentKey.charAt(0).toUpperCase() + paymentKey.slice(1)}
        </text>
      </svg>
    </div>
  )
}

PaymentFallback.propTypes = {
  paymentKey: PropTypes.string.isRequired,
}

export { PaymentFallback }
