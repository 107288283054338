import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import upperFirst from 'lodash/upperFirst'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Spinner from 'react-bootstrap/Spinner'
import { isNullOrUndefined } from 'utils/helpers'
import { PolePriceSources } from 'components/station/components/competitor-pricing/utils/constants'
import { stationCashCreditStatus } from 'utils/pricing'
import { ImageAsset } from 'components/common/image-asset'
import { usePricingTypes } from 'components/common/hooks/usePricingTypes'
import { images, fallback } from 'images/image-library'
import { ReactComponent as EdgeStationIcon } from 'images/competitor-pricing/edge-station.svg'
import { ReactComponent as DrivingDistanceIcon } from 'images/competitor-pricing/driving-distance.svg'
import classNames from 'classnames/bind'
import { useUpdateStationInfluencer } from '../../hooks/useUpdateStationInfluencer'
import styles from './CompetitorStationCell.module.scss'
import { amplitudeEvent, UserEvents } from 'config/amplitude'

const cx = classNames.bind(styles)

const parseDrivingDistance = (t, distances) => {
  const distance =
    distances[`drivingDistance${upperFirst(t('distance.title'))}`]
  if (isNullOrUndefined(distance)) {
    return null
  }
  return `${parseFloat(distance).toFixed(1)} ${t('distance.short')} ${t(
    'widgets.competitorPricing.away'
  )}`
}

const renderEdgeStationIcon = (polePrices, t) => {
  const edgeStation = polePrices.some(
    ({ source }) => source === PolePriceSources.TRANSACTION
  )
  if (edgeStation) {
    const overlay = (
      <Tooltip className={cx('edge-station-tooltip')}>
        {t('widgets.competitorPricing.stationLiveTooltip')}
      </Tooltip>
    )

    return (
      <OverlayTrigger placement="bottom" overlay={overlay}>
        <EdgeStationIcon data-testid="edge-station-icon" />
      </OverlayTrigger>
    )
  }
  return null
}

const CompetitorStationCell = ({ stationId, competitor }) => {
  const { cashCreditPricing } = usePricingTypes()
  const [updateInfluencer, { loading }] = useUpdateStationInfluencer()
  const { t } = useTranslation(['translations'])

  const {
    id: competitorId,
    brand,
    influencer,
    name,
    polePrices,
    distances,
    cashCreditPricing: stationCashCreditPricing,
  } = competitor

  const handleStationClick = () => {
    if (!loading) {
      if (!influencer) {
        amplitudeEvent(UserEvents.CompetitorPricing.FAVOURITED)
      }
      updateInfluencer({
        variables: { stationId, competitorId, influencer: !influencer },
      })
    }
  }

  const drivingDistance = parseDrivingDistance(t, distances)

  const noCashCreditStation = stationCashCreditStatus(
    cashCreditPricing,
    stationCashCreditPricing
  )

  const competitorStationCell = (
    <div
      className={cx('competitor-station-cell', {
        influencer,
        loading,
        'no-cash-credit': noCashCreditStation,
      })}
      onClick={handleStationClick}
      data-testid="competitor-station-cell"
    >
      <ImageAsset
        src={images.brandImage(brand?.key)}
        width={50}
        height={50}
        alt={`brand-${brand?.key}`}
        fallbackImage={fallback.defaultBrand}
      />
      <span className={cx('station-info')}>
        <div className={cx('sort')}>
          <DrivingDistanceIcon />
          <div className={cx('distance')}>{drivingDistance}</div>
          <span className={cx('favourite-indicator', { influencer })}>
            {loading ? (
              <Spinner
                animation="border"
                variant="light"
                data-testid="create-spinner"
                size="sm"
              />
            ) : (
              t('favourite')
            )}
          </span>
        </div>
        <div className={cx('station-name')}>{name}</div>
      </span>
      {renderEdgeStationIcon(polePrices, t)}
    </div>
  )

  if (noCashCreditStation) {
    const overlay = (
      <Tooltip className={cx('no-cash-credit-tooltip')}>
        <div>{t('widgets.competitorPricing.noCashDiscountTooltip')}</div>
      </Tooltip>
    )

    return (
      <OverlayTrigger placement="top" overlay={overlay}>
        {competitorStationCell}
      </OverlayTrigger>
    )
  }

  return competitorStationCell
}

CompetitorStationCell.propTypes = {
  stationId: PropTypes.string.isRequired,
  competitor: PropTypes.shape().isRequired,
}

export { CompetitorStationCell }
